<template>
  <div ref="contentRef">
    <slot :content-visible="contentVisible" />
  </div>
</template>

<script setup>
// LazyLoadContentWrapper
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { debounce } from '@sales-i/utils';

const props = defineProps({
  useDebounce: {
    type: Boolean,
    default: true,
  },
  debounceTime: {
    type: Number,
    default: 1000,
  },
  threshold: {
    type: Number,
    default: 0.1,
  },
});

const visible = ref(false);
const contentVisible = ref(false);
const debouncedLoadContent = props.useDebounce ? debounce(loadContent, props.debounceTime) : loadContent;

function handleIntersection(entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      visible.value = true;
      debouncedLoadContent();
    } else {
      visible.value = false;
    }
  });
}

function loadContent() {
  if (visible.value) contentVisible.value = true;
}

const contentRef = ref(null);

const options = computed(() => ({
  rootMargin: '0px',
  threshold: props.threshold,
}));

onMounted(() => {
  const observer = new IntersectionObserver(handleIntersection, options.value);
  observer.observe(contentRef.value);
});

onUnmounted(() => {
  const observer = new IntersectionObserver(handleIntersection, options.value);
  observer.disconnect();
});
</script>
