import { baseUrl, associationsScope, insightsScope } from '@/intelligence/router/urlBits';
import { REPORT_AREA_PRODUCT } from '@/intelligence/store/data/areas';
import { PRODUCT_ASSOCIATIONS_INSIGHTS } from '@/intelligence/store/data/reportTypes';
import AssociationsReport from '@/intelligence/views/Associations/AssociationsReport.vue';

export default [
  {
    path: `${baseUrl}/${insightsScope}/${REPORT_AREA_PRODUCT}/${associationsScope}`,
    component: AssociationsReport,
    name: PRODUCT_ASSOCIATIONS_INSIGHTS
  }
];
