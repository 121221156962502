<template>
  <CustomModal
    :title="t('Add post')"
    show-modal
    :max-width="600"
    @close-modal="closeModal"
  >
    <Form
      v-if="fields.length > 0"
      :formdata="fields"
      :actions="actions"
      :saved="saved"
      :col-span="12"
      @keypress="handleKeypress"
    >
      <template #savedContent>
        <div
          v-if="saved"
          :class="{
            complete: true,
          }"
        >
          <h2>{{ t('Saved') }}</h2>
        </div>
      </template>
    </Form>
  </CustomModal>
</template>

<script>
import { CustomModal } from '@sales-i/dsv3';
import Form from '@/shared/components/Form/Form.vue';
import store from '@/shared/store';
import { t } from '@sales-i/utils';

import { mapActions, mapState } from 'vuex';
import { GET_FIELDS, CLEAR_FIELDS, POST_BY_ENTITY, SET_ITEMS, CLEAR_BY_ENTITY } from '@/shared/store/actionType';
import { baseUrl } from '@/crm/router/urlBits';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'AddPostView',
  components: {
    CustomModal,
    Form,
  },
  props: {},
  data() {
    return {
      saved: false,
    };
  },
  computed: {
    ...mapState({
      entity: state => state.system.entity,
      post: state => state.posts.selected,
      fields: state => state.posts.fields,
    }),
    actions() {
      return [
        {
          label: t('Add post'),
          small: true,
          onClick: this.save,
          display: !this.saved,
          isSubmit: true,
        },
      ];
    },
    getPostURL() {
      return `${baseUrl}/${this.entity}/${this.$route.params.id}/posts/`;
    },
  },
  mounted() {
    this.clearTags();
    this.getFields();
  },
  unmounted() {
    this.clearTags();
    this.clearFields();
  },
  methods: {
    t,
    ...mapActions({
      addPost: `posts/${POST_BY_ENTITY}`,
      clearFields: `posts/${CLEAR_FIELDS}`,
      getFields: `posts/${GET_FIELDS}`,
      clearTags: `tags/${CLEAR_BY_ENTITY}`,
    }),
    async save(payload, setSubmitting) {
      setSubmitting(true);
      let tags = payload.tags && payload.tags.tagsToAdd ? payload.tags.tagsToAdd : [];
      let postAndTags = {
        post: {
          title: payload.title,
          text: payload.text,
          path: this.getPostURL,
        },
        tags: tags,
      };
      try {
        await this.addPost({
          entity: this.entity,
          id: this.$route.params.id,
          payload: postAndTags,
        });
        store.dispatch(`alerts/${SET_ITEMS}`, {
          type: 'success',
          message: `${payload.title} added`,
          selfDismiss: true,
        });
        setSubmitting(false);
        this.closeModal();
      } catch (e) {
        console.error(e);
        setSubmitting(false);
      }
    },
    handleKeypress(event) {
      if (event.key === 'Enter' && event.target.id === 'Tagcontent') {
        event.preventDefault();
      }
    },
    closeModal() {
      let { id } = this.$route.params;
      navigateToUrl(`${baseUrl}/${this.entity}/${id}/posts/`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
