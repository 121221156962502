<template v-if="authData.status === 'VALID'">
  <h5 class="recent-emails-heading">
    {{
      t(
        `Recent emails with ${name}`,
        'recent_emails_with_name',
        { 
          interpolations: {
            name: name
          }
        }
      )
    }}
  </h5>
  <CustomButton
    class="py-1"
    purpose="text"
    :label="t('New message')"
    small
    @click="openComposeView"
  />
  <template v-if="mode === 'view'">
    <div
      v-for="(mailItem) in items"
      :key="mailItem.id"
      class="mail-item"
      :class="getSender(mailItem).class"
    >
      <div
        class="initials-tag"
        :class="getSender(mailItem).class"
      >
        {{ getSender(mailItem).initials }}
      </div>
      <div class="subject-tag">
        {{ mailItem.snippet ?? mailItem.subject ?? 'No subject' }}
      </div>
    </div>
    <div v-if="!items.length">
      {{ 
        t(
          `You have no recent emails with ${name}`,
          'you_have_no_recent_emails_with_name',
          { 
            interpolations: {
              name: name
            }
          }
        )
      }}
    </div>
    <CustomButton
      v-if="showViewMore && false"
      class="view-more-link"
      purpose="text"
      :label="t('View more')"
      :disabled="loading"
      small
      @click="viewMore"
    />
  </template>
  <template v-if="mode === 'message'">
    <div
      role="button"
      tabIndex="0"
      @click="stop"
      @keypress="stop"
    >
      <Field
        id="subject"
        type="text"
        :label="t('Subject')"
        :max-length="40"
        :value="subject"
        :disabled="isSending"
        twoway
        @valid="update"
      />
      <label for="trixy">Message</label>
      <trix-editor
        id="trixy"
        class="trix-editor"
      />
    </div>
    <div class="actions">
      <CustomButton
        purpose="text"
        :label="t('Cancel')"
        :disabled="isSending"
        @click="closeComposeView"
      />
      <CustomButton
        purpose="action"
        :label="t('Send')"
        :disabled="isSending || message.trim() === ''"
        small
        @click="sendComposedEmail"
      />
    </div>
  </template>
</template>
  
<script setup>
import { ref, onUnmounted, onMounted } from 'vue';
import { CustomButton } from '@sales-i/dsv3';
import { mailState, t, sendMail, /*setAlerts*/ } from '@sales-i/utils';
import Field from '@/shared/components/Form/Field.vue';
// eslint-disable-next-line
import Trix from 'trix';

const authData = ref({});

const rxm = mailState.subscribe(newMailState => {
  authData.value = newMailState.details;
});

const props = defineProps({
  name: {
    type: String,
    default: ''
  },
  emailAddress: {
    type: String,
    required: true
  },
  items: {
    type: Array,
    default: () => []
  },
  mode: {
    type: String,
    default: 'view',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  showViewMore: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(['composing', 'viewMore']);

const subject = ref('');
const message = ref('');
const attachments = ref([]);
const isSending = ref(false);

const openComposeView = (e) => {
  e?.stopPropagation();
  emit('composing', true);
};

const closeComposeView = (e) => {
  e?.stopPropagation();
  emit('composing', false);
};

const update = e => {
  switch (e.id) {
  case 'subject':
    subject.value = e.value;
    break;
  default:
    console.error('Case not implemented');
  }
};

const getSender = (mail) => {
  if (mail.from[0].email === props.emailAddress) {
    let parts = props.name.split(' ');
    return {
      initials: `${parts[0].substring(0,1)}${parts[1].substring(0,1)}`,
      class: ''
    };
  }
  return {
    initials: 'You',
    class: 'alternate'
  };
};

const sendComposedEmail = async () => {
  isSending.value = true;
  const trix = document.querySelector('trix-editor');
  // Disable the Trix editor
  trix.setAttribute('contenteditable', 'false');

  // setTimeout(() => {
  //   setAlerts({
  //     type: 'success',
  //     message: `${subject.value} sent to ${props.name}`,
  //     selfDismiss: true,
  //   });
  //   isSending.value = false;
  //   closeComposeView();
  // }, 1000);
  const body = trix.innerHTML;


  await sendMail({
    to: [
      {
        name: props.name,
        email: props.emailAddress
      }
    ],
    subject: subject.value,
    body,
    attachments: attachments.value
  });
  isSending.value = false;
  // enable editor, although view should be closed anyway
  trix.setAttribute('contenteditable', 'true');
  closeComposeView();
};

const viewMore = () => emit('viewMore');

const stop = e => e.stopPropagation();

// Helper function to convert ArrayBuffer to Base64
function arrayBufferToBase64(buffer) {
  let binary = '';
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function trixContentUpdate() {
  // Change Trix.config if you need
  const trix = document.querySelector('trix-editor');
  const body = trix.editor.getDocument();
  message.value = body.toString();  // is a JavaScript string
}

function trixAttachmentAdd(event) {
  if (event.attachment.file) {
    let file = event.attachment.file;

    // Check if the file is an image
    if (file.type.startsWith('image/')) {
      let reader = new FileReader();

      reader.onload = function(e) {
        let base64 = e.target.result;
        let image = `<img src="${base64}" />`;

        // Get the editor
        let editor = document.querySelector('trix-editor').editor;

        // Find the position of the attachment placeholder
        let attachmentIndex = editor.getPosition();

        // Move to the attachment placeholder's start position
        editor.setSelectedRange([attachmentIndex - 1, attachmentIndex]);

        // Delete the placeholder text
        editor.deleteInDirection('backward');

        // Insert the in-line image
        editor.insertHTML(image);
      };

      reader.readAsDataURL(file);
    } else {
      // Handle other file types (e.g., PDF, DOCX)
      let reader = new FileReader();

      reader.onload = function(e) {
        let content = arrayBufferToBase64(e.target.result);
        attachments.value.push({
          content_type: file.type,
          filename: file.name,
          content
        });
      };

      reader.readAsArrayBuffer(file);
    }
  }
}

function trixAttachmentRemove(event) {
  const filename = event.attachment?.file?.name;
  attachments.value = attachments.value.filter(f => f.filename !== filename);
}

onMounted(() => {
  document.addEventListener('trix-change', trixContentUpdate);
  document.addEventListener('trix-attachment-add', trixAttachmentAdd);
  document.addEventListener('trix-attachment-remove', trixAttachmentRemove);
});

onUnmounted(() => {
  rxm.unsubscribe();
  document.removeEventListener('trix-change', trixContentUpdate);
  document.removeEventListener('trix-attachment-add', trixAttachmentAdd);
  document.removeEventListener('trix-attachment-remove', trixAttachmentRemove);
});
</script>
  
  <style lang="scss" scoped>
  .recent-emails-heading {
    font-weight: var(--font-weight-semibold);
    text-align: left;
  }
  .mail-item {
    display: flex;
    padding: var(--spacing-1);
    padding-left: 0;
    gap: var(--spacing-1);
  }

  .mail-item.alternate {
    flex-direction: row-reverse;
  }
  
  .initials-tag {
    font-size: var(--font-size-small);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: var(--colour-data-jagged-ice);
    color: var(--colour-data-jagged-ice-label);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .initials-tag.alternate {
    background-color: var(--colour-data-deluge-light);
    color: var(--colour-data-deluge-label);
  }
  
  .subject-tag {
    font-size: var(--font-size-small);
    display: flex;
    align-items: center;
    font-style: italic;
    text-align: left;
    // flex: 1;
  }

  .view-more-link {
    font-weight: var(--font-weight-semibold);
  }

  .actions {
    display: flex;
    gap: var(--spacing-2);
    justify-content: flex-end;
    padding-top: var(--spacing-1);
    padding-bottom: var(--spacing-1);
  }

  .trix-editor:deep(ul),
  .trix-editor:deep(ol) {
    list-style-position: inside;
  }

  .trix-editor:deep(blockquote) {
    border: 0 solid #ccc;
    border-left-width: .3em;
    margin-left: .3em;
    padding-left: .6em;
  }

  .trix-editor:deep(pre) {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-family: monospace;
    font-size: .9em;
    padding: .5em;
    white-space: pre;
    background-color: #eee;
    overflow-x: auto;
  }
  </style>
  