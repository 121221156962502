<template>
  <div
    :id="uniqueId"
    ref="container"
    class="trends-chart-container"
  >
    <svg class="chart" />
  </div>
</template>

<script>
import * as d3 from 'd3';
import { mapState, mapGetters } from 'vuex';
import { abbr, currency, uuid, debounce } from '@sales-i/utils';

export default {
  props: {
    reportId: {
      type: Number,
      default: null
    },
    useTestData: {
      type: Boolean,
      default: false,
    },
    testData: {
      type: [Object, Boolean],
      default: false,
    },
  },
  data() {
    return {
      greenChipClass: 'chip-green',
      yellowChipClass: 'chip-yellow',
      redChipClass: 'chip-red',
      greenThreshold: 0,
      yellowThreshold: 0,
      uniqueId: uuid('chart'),
      /** from charts mixin */
      currentParams: '',
      tableData: [],
    };
  },
  computed: {
    ...mapState({
      cs: state => state.userDetails.cs,
    }),
    /** from charts mixin */
    ...mapGetters({
      getReportData: 'intelligence/shared/getReportData',
    }),
    fetchedData() {
      return this.getReportData(this.reportId);
    },
    chartWidth() {
      return this.currentParams.svgWidth - this.currentParams.margin.left - this.currentParams.margin.right;
    },
    chartHeight() {
      return this.currentParams.svgHeight - this.currentParams.margin.top - this.currentParams.margin.bottom;
    },
    containerResizeObserver() {
      return new ResizeObserver(this.debounceChartResize);
    },
    debounceChartResize() {
      return debounce(this.handleChartResize, 250);
    },
    /** from charts mixin end */
    chartData() {
      return (this.useTestData ? this.testData : this.tableData)?.rows?.map(row => {
        return {
          value1: row.value_1,
          value2: row.value_2,
          name: row.period,
        };
      }).sort((a,b) => a.name - b.name);
    },
  },
  /** from charts mixin */
  mounted() {
    this.tableData = Array.isArray(this.fetchedData) ? [...this.fetchedData] : {...this.fetchedData};
    this.containerResizeObserver.observe(this.$refs.container);
  },
  unmounted() {
    this.containerResizeObserver.disconnect();
    this.removeChart();
  },
  methods: {
    handleChartResize() {
      this.removeChart();
      this.generateChart();
    },
    /** from charts mixin end */
    getChartVersion() {
      return this.$refs.container?.clientWidth <= 680 ? 'mobile' : 'desktop';
    },
    getExtremeValue(extreme) {
      if (['min', 'max'].indexOf(extreme) === -1) return;
      return Math[extreme](...this.chartData.map(el => el.value1), ...this.chartData.map(el => el.value2));
    },
    setThresholds() {
      this.greenThreshold = this.getExtremeValue('max') * 0.667;
      this.yellowThreshold = this.getExtremeValue('max') * 0.333;
    },
    removeChart() {
      d3.select(`#${this.uniqueId} svg.chart g`).remove();
    },
    processChartParams(type) {
      // dimensions and margins of the chart (mobile and desktop)
      this.currentParams =
        type === 'mobile'
          ? {
            svgWidth: this.$refs.container?.clientWidth,
            svgHeight: 450,
            margin: {
              top: 10,
              right: -10,
              bottom: 200,
              left: 40,
            },
          }
          : type === 'desktop'
            ? {
              svgWidth: this.$refs.container?.clientWidth,
              svgHeight: 510,
              margin: {
                top: 10,
                right: 0,
                bottom: 150,
                left: 135,
              },
            }
            : '';
    },
    generateChart() {
      this.setThresholds();
      const type = this.getChartVersion();
      this.processChartParams(type);
      const line1Title = this.useTestData ? this.testData.headings[1] : this.tableData.headings[1];
      const line2Title = this.useTestData ? this.testData.headings[2] : this.tableData.headings[2];

      // set parameters to container svg
      const svg = d3
        .select(`#${this.uniqueId} svg.chart`)
        .attr('width', this.currentParams.svgWidth)
        .attr('height', this.currentParams.svgHeight)
        .append('g')
        .attr('transform', `translate(${this.currentParams.margin.left},${this.currentParams.margin.top})`);

      //
      // DATA
      //
      const graphOffsetFromEdge = 20,
        xValuePosition = this.chartWidth / this.chartData.length / 2,
        bgLinesColor = '--colour-panel-g-8',
        linesData = [
          {
            id: 1,
            key: 'value1',
            color: '--colour-brand-viking',
            strokeWidth: 4,
            strokeDasharray: '',
            axisPosition: 10,
            legendPosition: 23,
            legendPositionMobile: -75,
            title: line1Title,
          },
          {
            id: 2,
            key: 'value2',
            color: '--colour-data-viking-label',
            strokeWidth: 2,
            strokeDasharray: '5,5',
            axisPosition: type === 'mobile' ? 78 : 60,
            legendPosition: type === 'mobile' ? 91 : 73,
            legendPositionMobile: 75,
            title: line2Title,
          },
        ];

      //
      // UTILS
      //
      const vue = this;
      function assignChipClass() {
        const valueType = this.parentNode.parentNode.className.baseVal.includes('value1') ? 'value1' : 'value2';
        d3.select(this)
          .classed(vue.greenChipClass, d => vue.generateChipClass(d[valueType], vue.greenChipClass))
          .classed(vue.yellowChipClass, d => vue.generateChipClass(d[valueType], vue.yellowChipClass))
          .classed(vue.redChipClass, d => vue.generateChipClass(d[valueType], vue.redChipClass));
      }
      function setChipWidthPosition() {
        let textLength = d3.select(this.parentNode).select('text').node().getComputedTextLength() + 16;
        if (type === 'mobile') textLength -= 8;
        d3.select(this)
          .attr('width', textLength)
          .attr('transform', `translate(-${textLength / 2},1)`);
      }

      //
      //  DRAWING
      //
      // X axis (with month names)
      const x = d3
        .scalePoint()
        .domain(this.chartData.map((d, i) => i))
        .range([0, this.chartWidth - this.currentParams.margin.left]);
      const bottomOffsetMonths = type === 'mobile' ? 55 : 22;
      svg
        .append('g')
        .attr('class', 'chart-axis')
        .attr('transform', `translate(0, ${this.chartHeight})`)
        .call(d3.axisBottom(x))
        .selectAll('text')
        .data(this.chartData)
        .text(d => d.name)
        .attr('transform', `translate(0, ${this.currentParams.margin.bottom - bottomOffsetMonths - 16})`)
        .attr('dominant-baseline', 'middle')
        .attr('class', 'chart-label-x')
        .style('text-anchor', 'middle');

      // Y axis
      const y = d3
        .scaleLinear()
        .domain([this.getExtremeValue('min'), this.getExtremeValue('max')])
        .range([this.chartHeight - graphOffsetFromEdge, graphOffsetFromEdge]);
      const axisY = d3.axisLeft(y);
      svg
        .append('g')
        .attr('class', 'chart-axis')
        .call(axisY)
        .selectAll('text')
        .attr('class', 'chart-label-y')
        .style('display', 'block')
        .text(d => abbr.float(d));

      // Vertical lines
      this.chartData.forEach((e, i) => {
        svg
          .append('line')
          .attr('x1', x(i))
          .attr('y1', 0)
          .attr('x2', x(i))
          .attr('y2', this.chartHeight + this.currentParams.margin.bottom - bottomOffsetMonths - 20)
          .style('stroke-width', 1)
          .style('stroke', `var(${bgLinesColor})`)
          .style('fill', 'none');
      });

      // Vertical line for Y Axis (only for desktop)
      svg
        .append('line')
        .attr('x1', x(0))
        .attr('y1', 0)
        .attr('x2', x(0))
        .attr('y2', y(0))
        .style('stroke-width', 1)
        .style('stroke', 'var(--colour-panel-g-32)')
        .style('fill', 'none');

      // Horizontal line indicating zero
      if (this.getExtremeValue('min') <= 0) {
        svg
          .append('line')
          .attr('x1', x(0))
          .attr('y1', y(0))
          .attr('x2', x(this.chartData.length - 1))
          .attr('y2', y(0))
          .style('stroke-width', 1)
          .style('stroke', 'var(--colour-panel-g-32)')
          .style('fill', 'none');
      }

      linesData.forEach(line => {
        // X axis with value 1 and value 2
        let axisTranslate = `translate(0, ${this.chartHeight + line.axisPosition})`,
          axisTextTranslate = 'translate(0,0)',
          axisTextAnchor = 'middle',
          axisTextDominant = '';

        if (type === 'mobile') {
          axisTranslate = `translate(-16, ${this.chartHeight + line.axisPosition + 35})`;
          axisTextTranslate = 'translate(0,0)rotate(-90)';
          axisTextAnchor = 'start';
          axisTextDominant = 'middle';
        }

        svg
          .append('g')
          .attr('class', `chart-axis chart-axis-${line.key}`)
          .attr('transform', axisTranslate)
          .call(d3.axisBottom(x))
          .selectAll('text')
          .data(this.chartData)
          .text(d => currency.price(d[line.key], this.cs))
          .attr('transform', axisTextTranslate)
          .attr('class', 'chart-label-values')
          .attr('dominant-baseline', axisTextDominant)
          .each(assignChipClass)
          .style('text-anchor', axisTextAnchor);

        // chips for X values
        const chipsSvg = svg
          .selectAll(`g.chart-axis-value${line.id} g.tick`)
          .data(this.chartData)
          .insert('rect', 'text');
        if (type === 'desktop') {
          chipsSvg.attr('ry', 12).attr('rx', 12).attr('height', 24).each(assignChipClass).each(setChipWidthPosition);
        } else if (type === 'mobile') {
          chipsSvg
            .attr('height', 12)
            .attr('class', 'chip-white')
            .each(setChipWidthPosition)
            .attr('transform', `translate(${xValuePosition / 2}, 3)rotate(-90)`);
        }

        // lines
        const linePath = d3
          .line()
          .curve(d3.curveMonotoneX)
          .x((d, i) => x(i))
          .y(d => y(d[line.key]));
        svg
          .append('path')
          .datum(this.chartData)
          .attr('fill', 'none')
          .attr('stroke', `var(${line.color})`)
          .attr('stroke-width', line.strokeWidth)
          .attr('stroke-dasharray', line.strokeDasharray)
          .attr('d', linePath);

        // legend
        if (type === 'desktop') {
          svg
            .append('line')
            .attr('x1', 25 - this.currentParams.margin.left)
            .attr('y1', this.chartHeight + line.legendPosition)
            .attr('x2', -30)
            .attr('y2', this.chartHeight + line.legendPosition)
            .attr('stroke', `var(${line.color})`)
            .attr('stroke-width', line.strokeWidth)
            .attr('stroke-dasharray', line.strokeDasharray)
            .style('fill', 'none');
          svg
            .append('text')
            .attr('x', 25 - this.currentParams.margin.left)
            .attr('y', this.chartHeight + line.legendPosition - 10)
            .attr('class', 'legend-text')
            .text(line.title) // changed for demo
            // .text(`Date range ${line.id}`)
            .attr('alignment-baseline', 'middle');
        } else if (type === 'mobile') {
          const xLegendPosition = this.chartWidth / 2 + line.legendPositionMobile,
            xLegendOffset = 70,
            yLegendOffset = 30;

          svg
            .append('line')
            .attr('x1', -12)
            .attr('y1', this.chartHeight + line.axisPosition + 5)
            .attr('x2', -12)
            .attr('y2', this.chartHeight + line.axisPosition + 30)
            .attr('stroke', `var(${line.color})`)
            .attr('stroke-width', line.strokeWidth)
            .attr('stroke-dasharray', line.strokeDasharray)
            .style('fill', 'none');

          svg
            .append('line')
            .attr('x1', xLegendPosition - xLegendOffset)
            .attr('y1', this.currentParams.svgHeight - yLegendOffset)
            .attr('x2', xLegendPosition - xLegendOffset + yLegendOffset)
            .attr('y2', this.currentParams.svgHeight - yLegendOffset)
            .attr('stroke', `var(${line.color})`)
            .attr('stroke-width', line.strokeWidth)
            .attr('stroke-dasharray', line.strokeDasharray)
            .style('fill', 'none');
          svg
            .append('text')
            .attr('x', xLegendPosition)
            .attr('y', this.currentParams.svgHeight - yLegendOffset)
            .attr('class', 'legend-text')
            .text(line.title) // changed for demo
            // .text(`Date range ${line.id}`)
            .attr('alignment-baseline', 'middle')
            .style('text-anchor', 'middle');
        }
      });
    },
    generateChipClass(value, chipName) {
      return (
        (value > this.greenThreshold
          ? this.greenChipClass
          : value > this.yellowThreshold
            ? this.yellowChipClass
            : this.redChipClass) === chipName
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.trends-chart-container {
  position: relative;
  display: block;
  overflow-x: auto;
  margin-bottom: var(--spacing-6);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-bottom: var(--spacing-2);
  }

  .chart {
    display: block;
    margin: auto;
  }
}
</style>

<style lang="scss">
.trends-chart-container {
  g.tick line,
  .chart-axis line,
  .chart-axis path {
    display: none;
  }

  .chart-label-x,
  .chart-label-values,
  .chart-label-y,
  .legend-text {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-small);
    font-family: EuclidCircularA;
  }

  .chart-label-y {
    color: var(--colour-utility-base);
  }

  .chart-label-values,
  .legend-text {
    font-weight: var(--font-weight-semibold);
  }

  text.chip-green {
    color: var(--colour-data-de-york-label);
  }

  text.chip-yellow {
    color: var(--colour-data-barberry-label);
  }

  text.chip-red {
    color: var(--colour-data-mandy-label);
  }

  rect.chip-green {
    fill: var(--colour-data-de-york-background);
  }

  rect.chip-yellow {
    fill: var(--colour-data-barberry-background);
  }

  rect.chip-red {
    fill: var(--colour-data-mandy-background);
  }

  rect.chip-white {
    fill: var(--colour-panel-g-0);
  }
}
</style>
