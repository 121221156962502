import { TOGGLE_CUSTOMER_MODE } from '@/admin/store/actionType';

// initial state
const state = () => ({
  isCustomerModeEnabled: false,
});

// getters
const getters = {
};

// mutations
const mutations = {
  [TOGGLE_CUSTOMER_MODE]: (state, value) => {
    state.isCustomerModeEnabled = value;
  },
};

// actions
const actions = {
  [TOGGLE_CUSTOMER_MODE]: ({ commit }, data) => {
    commit(TOGGLE_CUSTOMER_MODE, data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
