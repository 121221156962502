/* eslint-disable no-unused-vars */
import CRMBaseView from '@/crm/views/BaseViews/CRM.vue';
import OpportunityRoute from './Opportunity';
import InteractionsRoute from './Interactions';
import CustomerRoute from './Customer';
import ProspectRoute from './Prospect';
import { baseUrl } from './urlBits';

export default [
  {
    path: baseUrl,
    component: CRMBaseView,
  },
  ...OpportunityRoute,
  ...InteractionsRoute,
  ...CustomerRoute,
  ...ProspectRoute,
];
