import PermissionsView from '@/admin/views/Company/Permissions/PermissionsMain.vue';
import { baseUrl, companyArea as area, permissionsSection as section, usersSection as users } from '@/admin/router/urlBits';
import UserRoutes from './users';
import RoleRoutes from './roles';

export default [
  {
    path: section,
    component: PermissionsView,
    children: [
      {
        path: '',
        redirect: `${baseUrl}/${area}/${section}/${users}`,
      },
      ...UserRoutes,
      ...RoleRoutes,
    ]
  }
];
