<template>
  <CRMCard
    :on-click="openItem"
    :title="replaceSpaceWithNBSP(props.item.name)"
    :links="links"
    @open="openItem"
  >
    <template #info>
      {{ props.item.entity_name }}
      <div>{{ props.item.account_number }}</div>
      <AddressField :addresses="item.addresses" />
      <div>{{ t('Created') }}: {{ dates.format(props.item.created_date) }}</div>
    </template>
    <template #tags>
      <Tag
        :id="props.item.id"
        entity="customer"
      />
    </template>
  </CRMCard>
</template>

<script setup>
// CustomerCard
import { CRMCard } from '@sales-i/dsv3';
import AddressField from '@/crm/components/Common/AddressField.vue';
import Tag from '@/shared/components/Tags/Tag.vue';
import { replaceSpaceWithNBSP } from '@/shared/utils/replaceSpaceWithNBSP';
import { baseUrl, customersArea } from '@/crm/router/urlBits';
import { dates, t } from '@sales-i/utils';
import { scrollIntoView } from '@/shared/directives';
import { computed } from 'vue';
import { navigateToUrl } from 'single-spa';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
});

const links = computed(() => [
  {
    text: t('Open'),
    id: props.item.id,
    emit: 'open',
  },
]);

function openItem() {
  navigateToUrl(`${baseUrl}/${customersArea}/${props.item.id}`);
  scrollIntoView();
}
</script>
