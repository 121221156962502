import {
  POST_BY_ENTITY,
  POST_BY_ENTITY_LOADING,
  POST_BY_ENTITY_ERROR
} from '@/shared/store/actionType';
import { httpRequest, setErrors } from '@sales-i/utils';
import mapContactPayload from '@/crm/store/utils/mapContactPayload';


// mutations
export const postContactMutations = {
  [POST_BY_ENTITY]: (state, data) => {
    state.selected.data = data;
    state.selected.errors = [];
    state.selected.loading = false;
    state.selected.loaded = true;
    state.selected.updated = true;
  },
  [POST_BY_ENTITY_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [POST_BY_ENTITY_ERROR]: (state, errors) => {
    state.selected.errors = errors;
    state.selected.loading = false;
    state.selected.loaded = false;
    state.selected.updated = false;
  },
};

// actions
export const postContactActions = {
  [POST_BY_ENTITY]: async ({ commit, }, params) => {
    const { id, entity, payload, } = params;
    commit(POST_BY_ENTITY_LOADING);
    let contact = mapContactPayload(payload);
    try {
      const data = await httpRequest('post', `${entity}/${id}/contacts`, contact, { version: '2', throwErrors: false, });
      commit(POST_BY_ENTITY, {
        ...data,
        ...contact,
      });
      return data;
    } catch (error) {
      let errorsForForm = error.errors.filter(field => field.code === 'VALIDATION_ERROR');
      commit(POST_BY_ENTITY_ERROR, errorsForForm);
      if (errorsForForm.length === 0) {
        setErrors(error);
      }
    }
  },
};
