import { GET_CUSTOMER_ALERTS_BY_ID } from '@/shared/store/actionType';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default function useSavedQueries({ store = useStore() } = {}) {
  const getCustomerAlerts = id => store.dispatch(`intelligence/savedQuery/${GET_CUSTOMER_ALERTS_BY_ID}`,id);
  const customerAlerts = computed(() => store.state.intelligence.savedQuery.customerAlerts);
  const shouldShowAlerts = computed(() => (
    customerAlerts.value.saved_queries?.length > 0 
    || customerAlerts.value.child_saved_queries?.length > 0));

  return {
    customerAlerts,
    shouldShowAlerts,
    getCustomerAlerts
  };
}