<template>
  <div class="container enquiry-breadcrumbs">
    <CustomButton
      v-show="isResettable"
      class="reset-button"
      purpose="action"
      icon-name="close-line"
      icon-color="var(--colour-utility-white)"
      small
      round
      :icon-width="18"
      :icon-height="18"
      @click="resetReport"
    />

    <div
      v-for="(dim, index) in processedEnquiryParams.dimensions"
      :key="dim"
    >
      <Breadcrumb
        :dim="dim"
        :drill-values="processedEnquiryParams.values"
        :is-first-child="index === 0"
      >
        <CustomButton
          class="text"
          small
          purpose="text"
          @click="goToSelectedDrill(Object.values(enquiryParams)[0])"
        >
          {{ getBubbleName(dim) }}
        </CustomButton>
        <span
          v-if="processedEnquiryParams.values?.length"
          class="drill-number"
        >
          ({{ processedEnquiryParams.values?.length }})
        </span>
      </Breadcrumb>

      <span
        v-if="index + 1 < processedEnquiryParams.dimensions.length"
        class="divider"
      >
        {{ ' / ' }}
      </span>
    </div>

    <div
      v-if="drillParams.length || abandonedDrillParams.length"
      class="drillcrumbs"
    >
      <span class="label">{{ t('Drill') }}:</span>
      
      <template v-for="(drillType, index) in [processedDrillParams, processedAbandonedDrillParams]">
        <div
          v-for="(drillSet, key, i) in drillType"
          :key="i"
        >
          <template
            v-for="(dim, j) in drillSet.dimensions"
            :key="dim"
          >
            <span
              v-if="j"
              class="divider"
            >
              {{ ' / ' }}
            </span>

            <Breadcrumb
              :dim="dim"
              :drill-values="drillSet.values"
            >
              <CustomButton
                class="text"
                :class="{ 'abandoned-drill': !!index }"
                small
                purpose="text"
                @click="goToSelectedDrill(drillSet.dimensions[0])"
              >
                {{ getBubbleName(dim) }}
              </CustomButton>

              <span
                v-if="drillSet.values?.length"
                class="drill-number"
              >
                ({{ drillSet.values?.length }})
              </span>
            </Breadcrumb>
          </template>
          
          <span
            v-if="drillSet.values"
            class="divider"
          >
            {{ ' > ' }}
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { CustomButton } from '@sales-i/dsv3';
import Breadcrumb from '@/intelligence/components/EnquiryReport/EnquiryBreadcrumbs/Breadcrumb.vue';
import { t } from '@sales-i/utils';

const props = defineProps({
  enquiryParams: {
    type: Object,
    default: () => ({}),
  },
  query: {
    type: Object,
    default: () => ({}),
  },
  drillParams: {
    type: Array,
    default: () => [],
  },
  abandonedDrillParams: {
    type: Array,
    default: () => [],
  },
  isMatrixBasedReport: {
    type: Boolean,
    default: false,
  },
  isResettable: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['resetReport', 'goToSelectedDrill']);

const store = useStore();

const processedEnquiryParams = ref({});
const processedDrillParams = ref({});
const processedAbandonedDrillParams = ref({});

const bubbleData = computed(() => store.state.intelligence.enquiry.bubbleData);

const allProps = computed(() => {
  return props.enquiryParams && props.query && props.drillParams && props.abandonedDrillParams;
});

function processParams() {
  clearProcessedParams();

  for (const key in props.query) {
    let dim1, dim2, dim1Values, dim2Values;
    if (key.includes('dim') || key.includes('xaxis')) {
      // assign a number from the key. For 'axis' key it should assign NaN
      const match = key.match(/^\d+/);
      const paramIndex = match ? Number(match[0]) : NaN;

      // if there's already breadcrumb with such paramIndex as a key, just skip the iteration
      if ( 
        processedDrillParams.value[paramIndex] ||
        processedEnquiryParams.value[paramIndex] ||
        processedAbandonedDrillParams.value[paramIndex]
      )
        continue;

      // assign bubble IDs to variables (depending on the type)
      dim1 = props.query[isNaN(paramIndex) ? 'xaxis' : `${paramIndex}-dim1`];
      dim2 = props.query[isNaN(paramIndex) ? 'yaxis' : `${paramIndex}-dim2`];

      // assign picked drills to variables
      dim1Values = isNaN(paramIndex)
        ? props.query['1-dim1values']
        : props.query[`${props.isMatrixBasedReport ? paramIndex + 1 : paramIndex}-dim1values`];
      dim2Values = isNaN(paramIndex)
        ? props.query['1-dim2values']
        : props.query[`${props.isMatrixBasedReport ? paramIndex + 1 : paramIndex}-dim2values`];

      // sometimes the drills from query can be just String, so then wrap it into an Array
      dim1Values = typeof dim1Values === 'string' ? [dim1Values] : dim1Values;
      dim2Values = typeof dim2Values === 'string' ? [dim2Values] : dim2Values;

      // now merge the two values
      dim2Values = dim2Values
        ? dim2Values.map(value => value.split(':')[0])
        : null;
      dim1Values = dim1Values
        ? dim1Values.map((value, i) => value.split(':')[0] + (dim2Values ? `, ${dim2Values[i]}` : ''))
        : null;

      // create an object out of these variables. dimensions with bubble IDs ad values with selected drills
      const breadcrumbObject = {
        dimensions: [dim1, ...(dim2 ? [dim2] : [])],
        values: dim1Values,
      };

      // assign the breadcrumb object to one of the processed params objects.
      if ((paramIndex === 1 && !props.isMatrixBasedReport) || isNaN(paramIndex)) processedEnquiryParams.value = breadcrumbObject;
      else if (props.abandonedDrillParams.flat().find(param => param === dim1)) processedAbandonedDrillParams.value[paramIndex] = breadcrumbObject;
      else processedDrillParams.value[paramIndex] = breadcrumbObject;
    }
  }
}

function clearProcessedParams() {
  processedAbandonedDrillParams.value = {};
  processedDrillParams.value = {};
  processedEnquiryParams.value = {};
}

function resetReport() {
  emit('resetReport');
}

function goToSelectedDrill(drillParam) {
  emit('goToSelectedDrill', drillParam);
}

function getBubbleName(bubbleId) {
  if (!bubbleData.value) return;
  let titleObj = bubbleData.value.find(bubble => bubble.id === bubbleId);
  return titleObj ? titleObj.title : '';
}

watch(
  allProps,
  processParams, 
  { immediate: true }
);
</script>


<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.enquiry-breadcrumbs {
  display: flex;
  align-items: center;
  padding: var(--spacing-4);
  background: var(--colour-panel-alert-base);
  flex-wrap: nowrap;

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    padding: var(--spacing-2);
    overflow-x: auto;
  }

  .reset-button {
    margin-right: var(--spacing-half);
    height: 24px;
    width: 24px;

    :deep(.svg-container) {
      margin: 0 !important;
    }
  }

  div {
    white-space: nowrap;
    display: flex;
  }

  .drillcrumbs {
    display: flex;
    align-items: center;
    flex-basis: 100%;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      flex-basis: auto;
    }

    .abandoned-drill {
      opacity: 48%;
    }
  }

  span {
    font-size: var(--font-size-small);
  }

  .text {
    display: inline-block;
    color: var(--colour-utility-action);
    text-decoration: underline;
    font-weight: var(--font-weight-semibold);
    cursor: pointer;
  }

  .divider {
    margin: 0 var(--spacing-1);
    display: inlnie-block;
  }

  .label {
    margin: 2px var(--spacing-1) 0;
  }

  .drill-number {
    margin-left: var(--spacing-half);
    color: var(--colour-utility-base);
  }
}
</style>