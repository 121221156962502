/* eslint-disable no-unused-vars */
import {
  GET_PRODUCT_GROUPS,
  CLEAR_PRODUCT_GROUPS,
  GET_PRODUCT_GROUPS_LOADING,
} from '@/admin/store/actionType';
import { httpRequest } from '@sales-i/utils';
import querystring from 'querystring';

// getters
export const getters = {
};

// mutations
export const productGroupsMutations = {
  [GET_PRODUCT_GROUPS]: (state, data) => {
    state.productGroups.data = data;
    state.products.loaded = true;
    state.products.loading = false;
    state.products.errors = [];
  },
  [CLEAR_PRODUCT_GROUPS]: (state) => {
    state.productGroups.data = [];
    state.products.loaded = true;
    state.products.loading = false;
    state.products.errors = [];
  },
  [GET_PRODUCT_GROUPS_LOADING]: (state) => {
    state.products.loaded = false;
    state.products.loading = true;
    state.products.errors = [];
  },
};

// actions
export const productGroupsActions = {
  [GET_PRODUCT_GROUPS]: async ({ commit, }, parameters) => {
    commit(GET_PRODUCT_GROUPS_LOADING);
    let { q, limit, offset } = parameters;
    const params = querystring.stringify({ q, limit, offset });
    let data = await httpRequest('get', `products/product-groups?${params}`);
    commit(GET_PRODUCT_GROUPS, data);
  },
  [CLEAR_PRODUCT_GROUPS]: ({ commit }) => {
    commit(CLEAR_PRODUCT_GROUPS);
  },
};
