<template>
  <div>
    <div v-if="hasHeaderSlot">
      <slot name="header" />
    </div>

    <div class="">
      <div
        v-for="(item, index) in timelineData"
        :key="index"
        class="timeline-item"
      >
        <div class="dot-container">
          <div class="half-border" />
          <div class="dot" />
        </div>
        <div class="timeline-item-container">
          <CustomCallout
            conversation="left"
            body-bg-colour="var(--colour-utility-white)"
          >
            <span class="main-content"><Mention :content="item.comment" /></span>
            <hr>
            <div class="author-details">
              <span><strong>{{ item.created_by }}</strong></span>
              <DateTimeSnippet :value="item.created_date" />
              <div class="links">
                <button @click="editComment(item.id)">
                  {{ t('Edit') }}
                </button>
                <button @click="deleteComment(item.post_id, item.id)">
                  {{ t('Delete') }}
                </button>
              </div>
            </div>
          </CustomCallout>
        </div>
      </div>
    </div>

    <div v-if="hasFooterSlot">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { CustomCallout } from '@sales-i/dsv3';
import DateTimeSnippet from '@/shared/components/DateTimeSnippet.vue';
import Mention from '@/shared/components/Mention/Mention.vue';
import store from '@/shared/store';
import { mapState, mapActions } from 'vuex';
import { DELETE_BY_ID } from '@/shared/store/actionType';
import { SET_CONFIRMATION_MODAL } from '@/shared/store/actionType';
import { baseUrl, postsSection } from '@/crm/router/urlBits';
import { t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'Timeline',
  components: {
    CustomCallout,
    DateTimeSnippet,
    Mention,
  },
  props: {
    timelineData: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['deletedComment', 'closeModal'],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      entity: state => state.system.entity,
    }),
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
    hasFooterSlot() {
      return !!this.$slots.footer;
    },
  },
  mounted() {},
  methods: {
    t,
    ...mapActions([`comments/${DELETE_BY_ID}`]),
    editComment(comment_id) {
      let { id, post_id } = this.$route.params;
      navigateToUrl(`${baseUrl}/${this.entity}/${id}/${postsSection}/${post_id}/edit/${comment_id}`);
    },
    deleteComment(post_id, comment_id) {
      store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, {
        message: 'Do you really want to delete this comment?',
        updatedMessage: 'You have deleted this comment',
        updateFunction: () => this.confirmDelete(post_id, comment_id),
        finishFunction: this.finishDelete,
      });
    },
    async confirmDelete(post_id, comment_id) {
      try {
        await this[`comments/${DELETE_BY_ID}`]({
          id: post_id,
          comment_id: comment_id,
        });
        this.$emit('deletedComment');
        return true;
      } catch (e) {
        return false;
      }
    },
    finishDelete() {
      this.$emit('closeModal');
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline-item {
  display: flex;

  hr {
    border: none;
    height: 0.5px;
    background: var(--colour-panel-g-16);
  }

  .dot-container {
    position: relative;
  }
  .dot {
    position: absolute;
    height: 9px;
    width: 9px;
    background-color: var(--colour-panel-g-48);
    border-radius: 50%;
    top: 50%;
    left: -5px;
    transform: translateY(-50%);
  }
  &:not(:last-of-type) .dot-container {
    border-left: 1px solid var(--colour-panel-g-24);
    .half-border {
      display: none;
    }
  }
  &:last-of-type .dot-container {
    border-left: 1px solid transparent;
    .half-border {
      position: absolute;
      top: 50%;
      left: -1px;
      width: 1px;
      height: 50%;
      transform: translateY(-100%);
      background-color: var(--colour-panel-g-24);
    }
  }
  .timeline-item-container {
    min-width: 200px;
    max-width: 600px;
    padding: var(--spacing-2);
    padding-right: 0;
    .main-content {
      font-style: italic;
      font-weight: var(--font-weight-medium);
    }
    hr {
      margin-top: var(--spacing-2);
      margin-bottom: var(--spacing-1);
    }
    .author-details {
      position: relative;
      margin-top: var(--spacing-half);
      font-size: var(--font-size-small);
      span {
        margin-right: var(--spacing-1);
      }
      .more-menu {
        display: inline-block;
        position: relative;
        top: -1px;
        left: calc(var(--spacing-2) * -1);
      }
      .links button:not(:first-of-type) {
        margin-left: var(--spacing-1);
      }
    }
  }
}
</style>
