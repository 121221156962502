<template>
  <div class="container container--fluid bg">
    <div class="padded">
      <p>{{ t('Customer insights go here') }}</p>
    </div>
  </div>
</template>

<script setup>
import { t } from '@sales-i/utils';
</script>
