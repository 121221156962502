<template>
  <div>
    <Field
      v-for="(lang, index) in languages"
      :id="item.name + '-' + lang.value"
      :key="'lang_' + index"
      v-model="item.data[lang.value]"
      :class="'translate-' + lang.value"
      :label="lang.text"
      :icon="{
        name: 'refresh',
        align: 'right',
        outside: true,
        color: 'var(--colour-utility-black)',
        func: () => reset(lang),
      }"
      :disabled="disabled"
      twoway
    />
  </div>
</template>

<script>
import Field from '@/shared/components/Form/Field.vue';

export default {
  name: 'EditTranslationField',
  components: {
    Field,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    languages: {
      type: Array,
      required: true,
      default: () => [],
    },
    defaultReset: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['translationReset'],
  data() {
    return {
      item: {
        name: (this.value || {}).name || '',
        data: { ...(this.value || {}).data },
      },
    };
  },
  computed: {},
  methods: {
    reset(language) {
      this.$emit('translationReset', { language, item: this.item });
      if (this.defaultReset) {
        // if property existed return original value
        if (this.value.data && Object.keys(this.value.data).includes(language.value)) {
          this.item.data[language.value] = this.value.data[language.value];
        }
        // if not - delete the prop from data prop
        else {
          this.$delete(this.item.data, language.value);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
