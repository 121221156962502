// TEMPORARY HIDDEN AS PER SA-6945

// import MarketPenetrationView from '@/intelligence/views/MarketPenetration/MainView';
// import { baseUrl, insightsScope, customerScope } from '@/intelligence/router/urlBits';
// import { MARKET_PENETRATION } from '@/intelligence/store/data/reportTypes';

export default [
  {
    // path: `${baseUrl}/${insightsScope}/${customerScope}/${MARKET_PENETRATION}`,
    // component: MarketPenetrationView,
    // name: MARKET_PENETRATION,
    // props: { reportType: MARKET_PENETRATION }
  },
];
