import UsersView from '@/admin/views/Company/Permissions/Users.vue';
import UserDetailsView from '@/admin/views/Company/Permissions/UserDetailsView.vue';
import UserList from '@/admin/components/Company/Permissions/Users/UserList.vue';
import UserDetailsForm from '@/admin/components/Company/Permissions/Users/UserDetailsForm.vue';
import UserRolesForm from '@/admin/components/Company/Permissions/Users/UserRolesForm.vue';
import DataRestrictionsForm from '@/admin/components/Company/Permissions/Users/DataRestrictionsForm.vue';
import AddUser from '@/admin/views/Company/Permissions/AddUser.vue';
import { usersSection } from '@/admin/router/urlBits';

const userAddEditChildRoutes = [
  {
    path: '',
    component: UserDetailsForm,
  },
  {
    path: 'role',
    component: UserRolesForm,
  },
  {
    path: 'data-restrictions',
    component: DataRestrictionsForm,
  },
];

const UserRoutes = [
  {
    path: usersSection,
    component: UsersView,
    children: [
      {
        path: '',
        component: UserList,
        children: [{
          path: ':id',
          component: UserDetailsView
        }]
      },
      {
        path: ':addedit(add)',
        component: AddUser,
        children: userAddEditChildRoutes,
      },
      {
        path: ':id/:addedit(edit)',
        component: AddUser,
        children: userAddEditChildRoutes,
      }
    ],
  }
];

export default UserRoutes;