<template>
  <div
    class="enquiry"
    :class="{ 'flex-space': !paginationNoResults, 'iframe-height': iframe }"
  >
    <BufferImage
      v-if="dataLoading || !isReportEnabled"
      color="black"
      float="center"
      class="loading-spinner"
    />
    <section class="data-section">
      <SVGTable
        v-show="isReportEnabled && dataRowsLength"
        :report-id="svgId"
        :report-type="reportType"
        :y-sort-direction="ySortDirection"
        @handle-enquiry-drill="handleEnquiryDrill"
        @sort-on-y-axis="sortOnYAxis"
        @set-enquiry-data="setEnquiryData"
      />
      <NoResults v-if="paginationNoResults && isReportEnabled" />
    </section>
    <ReportFooter
      v-if="!paginationNoResults && isReportEnabled && !iframe"
      :dimensions="dimensions"
      :report-type="reportType"
      :sub-title="title"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import {
  APPLY_DATE_RANGE,
  SET_SORTING_DATA,
  FETCH_REPORT_DATA,
  FETCH_DATES
} from '@/intelligence/store/actionType';
import { BufferImage } from '@sales-i/dsv3';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';
import SVGTable from '@/intelligence/components/SalesVsGaps/SVGTable';
import NoResults from '@/intelligence/components/Shared/NoResults';
import {
  REPORTS_SORT_ASCENDING_KEY,
  REPORTS_SORT_DESCENDING_KEY
} from '@/intelligence/store/data/apiInput';
import { customersArea } from '@/crm/router/urlBits';
import { baseUrl } from '@/intelligence/router/urlBits';
import { intelligence_enquiries } from '@/shared/store/data/policies';
import runIfPermittedOrReject from '@/shared/store/utils/runIfPermittedOrReject';
import { SALES_VS_GAPS } from '@/intelligence/store/data/reportTypes';

export default {
  components: {
    BufferImage,
    ReportFooter,
    NoResults,
    SVGTable
  },
  props: {
    title: {
      required: true,
      type: String
    },
    dateParams: {
      type: Object,
      required: true
    },
    reportType: {
      type: String,
      default: SALES_VS_GAPS,
      required: true
    },
    isReportEnabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['handleEnquiryDrill', 'loaded', 'loading'],
  data() {
    return {
      ySortDirection: REPORTS_SORT_DESCENDING_KEY,
      ySortColumn: 'total',
      svgId: 1,
    };
  },
  computed: {
    ...mapState({
      dataLoading: (state) => state.intelligence.shared.loading,
      fetchedDates: (state) => state.intelligence.calendar.fetchedDates,
      permissions: (state) => state.pbac.permissionsGranted,
      iframe: (state) => state.system.iframe
    }),
    ...mapGetters({
      getReportData: 'intelligence/shared/getReportData'
    }),
    enquiryData() {
      return this.getReportData(this.svgId);
    },
    dimensions() {
      return this.enquiryData.axis.y[0].entity;
    },
    paginationNoResults() {
      return (
        (!this.xHeadingsLength || !this.dataRowsLength) && !this.dataLoading
      );
    },
    dataRowsLength() {
      return this.enquiryData ? this.enquiryData.rows.length : 0;
    },
    xHeadingsLength() {
      return this.enquiryData ? this.enquiryData.axis.x.headings.length : 0;
    }
  },
  async mounted() {
    await this.setEnquiryData(true);
  },
  methods: {
    ...mapActions({
      fetchEnquiryData: `intelligence/shared/${FETCH_REPORT_DATA}`,
      applyDate: `intelligence/shared/${APPLY_DATE_RANGE}`,
      applyNewSortDirection: `intelligence/enquiry/${SET_SORTING_DATA}`,
      fetchDates: `intelligence/calendar/${FETCH_DATES}`
    }),
    async setEnquiryData(completeRefresh = false) {
      const permissionName = intelligence_enquiries;
      await runIfPermittedOrReject(
        this.permissions,
        { name: permissionName },
        async () => {
          this.$emit('loading');
          await this.fetchDates(this.dateParams);
          this.applyDate(this.fetchedDates);
          this.applyNewSortDirection([this.ySortColumn, this.ySortDirection]);

          this.svgId = await this.fetchEnquiryData({
            reportType: this.reportType,
            completeRefresh,
          });
          this.$emit('loaded');
        }
      );
    },
    handleEnquiryDrill(payload) {
      this.$emit('handleEnquiryDrill', payload);
    },
    sortOnYAxis(sortOrder, sortCol) {
      this.ySortDirection =
        sortOrder === REPORTS_SORT_ASCENDING_KEY
          ? REPORTS_SORT_ASCENDING_KEY
          : REPORTS_SORT_DESCENDING_KEY;
      this.ySortColumn = sortCol;

      this.setEnquiryData(true);
    },
    toggleGenerateInteractionsModal() {
      this.$router.push(`${baseUrl}/${customersArea}/generate-interactions`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.enquiry {
  width: 100%;
  margin: 0 auto auto;
  border-radius: var(--border-radius-1);
  position: relative;
  background: var(--colour-utility-white);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur)
    var(--shadow-spread) var(--shadow-colour);
  display: flex;
  flex-direction: column;
  min-height: 200px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    min-height: 580px;

    &.iframe-height {
      min-height: 510px;
    }
  }

  .data-section {
    flex: 1;
    display: flex;
    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius-1);
  }

  &.flex-space {
    justify-content: space-between;

    .data-section {
      flex: auto;
      display: block;
      margin-bottom: 56px;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        margin-bottom: 0;
      }
    }
  }
}

.header-section {
  position: relative;
  padding: var(--spacing-4) var(--spacing-4) var(--spacing-1);

  h3 {
    text-align: center;
    padding: 0 var(--spacing-2);
    font-weight: var(--font-weight-semibold);

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      padding: 0 var(--spacing-4);
    }
  }

  span {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-small);
    display: block;
    text-align: center;
    color: var(--colour-utility-base);
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}
</style>
