<template>
  <CRMCard
    :on-click="openItem"
    :title="replaceSpaceWithNBSP(props.item.name)"
    :links="links"
    @open="openItem"
    @edit="editItem"
    @delete="deleteItem"
  >
    <template #info>
      {{ props.item.entity_name }}
      <div>{{ t('Created') }}: {{ dates.format(props.item.created_date) }}</div>
    </template>
    <template #tags>
      <Tag
        :id="props.item.id"
        entity="prospect"
      />
    </template>
  </CRMCard>
</template>

<script setup>
// CustomerCard
import { CRMCard } from '@sales-i/dsv3';
import Tag from '@/shared/components/Tags/Tag.vue';
import { replaceSpaceWithNBSP } from '@/shared/utils/replaceSpaceWithNBSP';
import { useRoute, } from 'vue-router';
import { dates, t } from '@sales-i/utils';
import { baseUrl, prospectsArea } from '@/crm/router/urlBits';
import { useStore } from 'vuex';
import { DELETE_BY_ID, SET_CONFIRMATION_MODAL, SET_ROUTE } from '@/shared/store/actionType';
import { computed } from 'vue';
import usePermissions from '@/shared/composables/usePermissions';
import { scrollIntoView } from '@/shared/directives';
import { prospects } from '@/shared/store/data/policies';
import { navigateToUrl } from 'single-spa';

const { getPermissions } = usePermissions();
const permissions = getPermissions(prospects);

const emit = defineEmits(['closeModal', 'deleted']);

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
});

const store = useStore();
const vroute = useRoute();

const links = computed(() => {
  let links = [];
  if (permissions.delete) {
    links.push({
      text: t('Delete'),
      id: props.item.id,
      name: props.item.name,
      emit: 'delete',
    });
  }
  if (permissions.update) {
    links.push({
      text: t('Edit'),
      id: props.item.id,
      emit: 'edit',
    });
  }
  links.push({
    text: t('Open'),
    id: props.item.id,
    emit: 'open',
  });
  return links;
});

/** PROSPECT ACTIONS */
const selectedLoaded = computed(() => store.state.crm.prospects.selected.loaded);
const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);
const deleteProspect = params => store.dispatch(`crm/prospects/${DELETE_BY_ID}`, params);
const setRoute = params => store.dispatch(`system/${SET_ROUTE}`, params);

function openItem() {
  navigateToUrl(`${baseUrl}/${prospectsArea}/${props.item.id}`);
  scrollIntoView();
}
function editItem() {
  setRoute({
    success: `${baseUrl}/${prospectsArea}/${props.item.id}`,
    cancel: vroute.fullPath,
  });
  navigateToUrl(`${baseUrl}/${prospectsArea}/${props.item.id}/edit`);
}

/** Delete prospect */
function deleteItem() {
  showConfirmationModal({
    message: t(`Do you really want to delete Prospect ${props.item.name}?`, 'do_you_really_want_to_delete_name?', {
      interpolations: {
        variable: props.item.name,
      },
    }),
    updatedMessage: t(`You have deleted Prospect ${props.item.name}`, 'you_have_deleted_prospect_name', {
      interpolations: {
        variable: props.item.name,
      },
    }),
    updateFunction: () => confirmDelete(props.item.id),
    finishFunction: () => finishDelete(props.item.id),
  });
}
function finishDelete() {
  emit('closeModal');
}
async function confirmDelete(id) {
  try {
    await deleteProspect(id);
    emit('deleted');
    return selectedLoaded;
  } catch (e) {
    console.error(e);
    return false;
  }
}
/** PROSPECT ACTIONS END */
</script>
