import Communities from '@/admin/views/Communities/Communities.vue';
import CommunityDetails from '@/admin/views/Communities/CommunityDetails.vue';
import CommunitiesListView from '@/admin/views/Communities/CommunitiesListView.vue';
import { communitiesSection } from '@/admin/router/urlBits';

export default {
  path: communitiesSection,
  component: Communities,
  children: [
    {
      path: '',
      component: CommunitiesListView,
    },
    {
      path: 'add',
      component: CommunityDetails,
    },
    {
      path: ':community_code',
      component: CommunityDetails,
    },
  ],
};
