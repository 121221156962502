import { computed } from 'vue';
import { t } from '@sales-i/utils';
import {
  CLEAR_ALL,
  CLEAR_BY_ID,
  DELETE_BY_ID,
  GET_ALL,
  GET_BY_ID,
  POST_BY_ENTITY,
  PUT_BY_ID,
  SET_CONFIRMATION_MODAL,
  SET_ENTITY,
  SET_ROUTE,
  UPDATE_RECENTLY_VIEWED
} from '@/shared/store/actionType';
import { useRoute } from 'vue-router';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';
import { useStore } from 'vuex';
import { 
  CLEAR_CURRENTLY_EDITING,
  REMOVE_OPPORTUNITY,
  SET_FURTHER_DETAILS,
  SET_OPPORTUNITY,
  SET_DATE,
  SET_RELATED_TO
} from '@/crm/store/actionType';
import { scrollIntoView } from '@/shared/directives';
import { interactions as interactionsPerm } from '@/shared/store/data/policies';
import usePermissions from '@/shared/composables/usePermissions';
import { navigateToUrl } from 'single-spa';

export const useInteraction = ({store = useStore(), emit, vroute = useRoute()} = {}) => {
  const { getPermissions } = usePermissions();
  const permissions = getPermissions(interactionsPerm);
  const interactions = computed(() => store.state.crm.interactions);
  const selected = computed(() => store.state.crm.interactions.selected);
  const item = computed(() => store.getters['crm/interactions/item']);
  const durationHours = computed(() => store.getters['crm/interactions/durationHours']);
  const durationMinutes = computed(() => store.getters['crm/interactions/durationMinutes']);
  const startHours = computed(() => store.getters['crm/interactions/startHours']);
  const startMinutes = computed(() => store.getters['crm/interactions/startMinutes']);
  const currentlyEditing = computed(() => store.state.crm.interactions.currentlyEditing);
  const furtherDetails = computed(() => store.state.crm.interactions.currentlyEditing.furtherDetails);
  const loading = computed(() => store.state.crm.interactions.all.loading);
  const loaded = computed(() => store.state.crm.interactions.all.loaded);
  const openInteractions = computed(() => store.state.crm.interactions.all.data.filter(i => !i.outcome_id || i.is_personal));
  const completedInteractions = computed(() => store.state.crm.interactions.all.data.filter(i => !!i.outcome_id));
  
  const getInteractions = (parameters, append = false) => store.dispatch(`crm/interactions/${GET_ALL}`, { parameters, append });
  const clearInteractions = () => store.dispatch(`crm/interactions/${CLEAR_ALL}`);
  const clearInteractionsById = () => store.dispatch(`crm/interactions/${CLEAR_BY_ID}`);
  const getInteraction = (params) => store.dispatch(`crm/interactions/${GET_BY_ID}`, params);
  const deleteInteraction = params => store.dispatch(`crm/interactions/${DELETE_BY_ID}`, params);
  const setOpportunity = params => store.dispatch(`crm/interactions/${SET_OPPORTUNITY}`, params);
  const removeOpportunity = params => store.dispatch(`crm/interactions/${REMOVE_OPPORTUNITY}`, params);
  const putInteraction = params => store.dispatch(`crm/interactions/${PUT_BY_ID}`, params);
  const postInteraction = params => store.dispatch(`crm/interactions/${POST_BY_ENTITY}`, params);
  const setFurtherDetails = params => store.dispatch(`crm/interactions/${SET_FURTHER_DETAILS}`, params);
  const setEntity = params => store.dispatch(`crm/interactions/${SET_ENTITY}`, params);
  const clearCurrentlyEditing = () => store.dispatch(`crm/interactions/${CLEAR_CURRENTLY_EDITING}`);
  const setDate = params => store.dispatch(`crm/interactions/${SET_DATE}`, params);
  const setRelatedInteraction = params => store.dispatch(`crm/interactions/${SET_RELATED_TO}`, params);
  const updateRecentlyViewedInteraction = interactionId => store.dispatch(`searchBarResults/${UPDATE_RECENTLY_VIEWED}`, {
    entity_type: 'interaction',
    entity_id: typeof interactionId === 'number' ? interactionId : parseInt(interactionId, 10),
  });
  
  // dependencies
  const setRoute = (params) => store.dispatch(`system/${SET_ROUTE}`, params);
  const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);

  function showDeleteInteractionModal(item) {
    showConfirmationModal({
      message: t(
        `Do you really want to delete Interaction #${item.id}`,
        'do_you_really_want_to_delete_interaction_id',
        { interpolations: { id: item.id }}
      ),
      updatedMessage: t(
        `You have deleted Interaction #${item.id}`,
        'you_have_deleted_interaction_id',
        { interpolations: { id: item.id } }
      ),
      updateFunction: () => confirmDelete(item),
      finishFunction: finishDelete,
    });
  }

  async function confirmDelete(item) {
    try {
      await deleteInteraction(item.id);
      emit('interactionDeleted', item);
      return selected.value.loaded;
    }
    catch(e) {
      console.error(e);
      return false;
    }
  }

  function finishDelete() {
    emit('closeModal');
    return true;
  }

  function openInteraction({ is_personal, id }) {
    if (is_personal) {
      navigateToUrl(`${baseUrl}/${interactionsArea}/${id}/personal`);
    } else {
      navigateToUrl(`${baseUrl}/${interactionsArea}/${id}`);
      scrollIntoView();
    }
  }

  function editInteraction({ is_personal, id }, 
    doSetRoute = false, 
    success = `${baseUrl}/${interactionsArea}/${id}`, 
    cancel = vroute.fullPath
  ) 
  {
    if (doSetRoute) setRoute({ success, cancel });
    if (is_personal) {
      navigateToUrl(`${baseUrl}/${interactionsArea}/${id}/edit-personal`);
    } else {
      navigateToUrl(`${baseUrl}/${interactionsArea}/${id}/edit`);
      scrollIntoView();
    }
  }

  return {
    permissions,
    item,
    interactions,
    selected,
    currentlyEditing,
    loading,
    loaded,
    furtherDetails,
    durationHours,
    durationMinutes,
    startHours,
    startMinutes,
    openInteractions,
    completedInteractions,
    showConfirmationModal,
    deleteInteraction,
    showDeleteInteractionModal,
    editInteraction,
    confirmDelete,
    finishDelete,
    getInteraction,
    getInteractions,
    clearInteractions,
    clearInteractionsById,
    setOpportunity,
    removeOpportunity,
    postInteraction,
    putInteraction,
    setFurtherDetails,
    setEntity,
    clearCurrentlyEditing,
    setDate,
    setRelatedInteraction,
    openInteraction,
    updateRecentlyViewedInteraction
  };
};
