import { computed } from 'vue';
import { useStore } from 'vuex';
import { GET_OPPORTUNITY_STAGES } from '@/crm/store/actionType';

export function useOppStages() {
  const store = useStore();

  const stages = computed(() => store.state.crm.opportunities.stages.data || []);

  const fetchStages = async () => {
    await store.dispatch(`crm/opportunities/${GET_OPPORTUNITY_STAGES}`);
  };

  return {
    stages,
    fetchStages
  };
}
