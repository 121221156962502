import {
  POST_BY_ENTITY,
  POST_BY_ENTITY_LOADING,
  POST_BY_ENTITY_ERROR
} from '@/shared/store/actionType';
import mapUserPayload from '@/admin/store/utils/mapUserPayload';
// import { httpRequest } from '@sales-i/utils';

// mutations
export const postMutations = {
  [POST_BY_ENTITY]: (state) => {
    state.selected.loading = false;
    state.selected.loaded = true;
  },
  [POST_BY_ENTITY_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [POST_BY_ENTITY_ERROR]: (state, errors) => {
    state.selected.errors = errors;
    state.selected.loading = false;
    state.selected.loaded = false;
  },
};


// actions
export const postActions = {
  [POST_BY_ENTITY]: async ({state, commit}) => {
    commit(POST_BY_ENTITY_LOADING);
    let userToPost = mapUserPayload(state.currentlyEditing);
    try {
      // const data = await httpRequest('post', 'interactions', userToPost, { version: '2' });
      commit(POST_BY_ENTITY, userToPost);
    } catch (error) {
      commit(POST_BY_ENTITY_ERROR);
    }
  },
};
