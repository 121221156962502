import { getByEntityActions, getByEntityMutations } from './getByEntity';

// initial state
const state = () => ({
  loading: true,
  loaded: false,
  data: [],
  errors: [],
});

// getters
const getters = {
};

// mutations
const mutations = {
  ...getByEntityMutations,
};

// actions
const actions = {
  ...getByEntityActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
