<template>
  <CustomModal
    :title="t('Edit post')"
    show-modal
    :max-width="600"
    @close-modal="closeModal"
  >
    <Form
      v-if="fields.length > 0"
      :formdata="fields"
      :actions="actions"
      :saved="saved"
      :col-span="12"
      @keypress="handleKeypress"
    >
      <template #savedContent>
        <div
          v-if="saved"
          class="complete"
        >
          <h2>{{ t('Saved') }}</h2>
        </div>
      </template>
    </Form>
  </CustomModal>
</template>

<script>
import { CustomModal } from '@sales-i/dsv3';
import Form from '@/shared/components/Form/Form.vue';
import store from '@/shared/store';
import { t } from '@sales-i/utils';
import { mapActions, mapState } from 'vuex';
import { SET_ITEMS, GET_FIELDS, CLEAR_FIELDS, GET_BY_ID, PUT_BY_ID } from '@/shared/store/actionType';
import { baseUrl, postsSection } from '@/crm/router/urlBits';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'EditPostView',
  components: {
    CustomModal,
    Form,
  },
  props: {},
  data() {
    return {
      saved: false,
    };
  },
  computed: {
    ...mapState({
      entity: state => state.system.entity,
      post: state => state.posts.selected,
      fields: state => state.posts.fields,
    }),
    actions() {
      return [
        {
          label: t('Save post'),
          small: true,
          onClick: this.save,
          display: !this.saved,
          isSubmit: true,
        },
      ];
    },
  },
  mounted() {
    if (this.$route.params.post_id) {
      this[`posts/${GET_BY_ID}`]({
        entity: this.entity,
        id: this.$route.params.id,
        post_id: this.$route.params.post_id,
      });
    }
    this.getFields();
  },
  unmounted() {
    this[`posts/${CLEAR_FIELDS}`]();
  },
  methods: {
    t,
    ...mapActions([`posts/${GET_FIELDS}`, `posts/${CLEAR_FIELDS}`, `posts/${GET_BY_ID}`, `posts/${PUT_BY_ID}`]),
    getFields() {
      if (this.post.data.id) {
        this[`posts/${GET_FIELDS}`]({
          post: {
            title: this.post.data.title,
            text: this.post.data.text,
          },
          tagProps: {
            entity: 'post',
            entityId: this.post.data.id,
          },
        });
      } else {
        setTimeout(() => {
          this.getFields();
        }, 100);
      }
    },
    async save(payload, setSubmitting) {
      setSubmitting(true);
      let tags = {
        tagsToAdd: payload.tags && payload.tags.tagsToAdd ? payload.tags.tagsToAdd : [],
        tagsToDelete: payload.tags && payload.tags.tagsToDelete ? payload.tags.tagsToDelete : [],
      };
      let postAndTags = {
        post: {
          title: payload.title,
          text: payload.text,
        },
        tags: tags,
      };
      try {
        await this[`posts/${PUT_BY_ID}`]({
          entity: this.entity,
          id: this.$route.params.id,
          post_id: this.$route.params.post_id,
          payload: postAndTags,
        });
        store.dispatch(`alerts/${SET_ITEMS}`, {
          type: 'success',
          message: `${payload.title} edited`,
          selfDismiss: true,
        });
        setSubmitting(false);
        this.closeModal();
      } catch (e) {
        console.error(e);
        setSubmitting(false);
      }
    },
    handleKeypress(event) {
      if (event.key === 'Enter' && event.target.id === 'Tagcontent') {
        event.preventDefault();
      }
    },
    closeModal() {
      let { id, post_id } = this.$route.params;
      navigateToUrl(`${baseUrl}/${this.entity}/${id}/${postsSection}/${post_id}`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
