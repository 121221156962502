<template>
  <div class="filters-wrapper">
    <div class="filters-content">
      <h2>
        {{
          t(`Filter ${filterNumber + 1}`, 'filter_count', {
            interpolations: {
              count: filterNumber + 1,
            },
          })
        }}
      </h2>
      <BufferImage
        v-if="currentStep === -1"
        color="var(--colour-utility-black)"
        float="center"
        class="loading-spinner"
      />
      <div v-show="currentStep === 1">
        <p class="filters-description">
          {{ t('Select a field to filter and include or exclude its items from the report') }}
        </p>
        <CustomSelect
          :label="t('Filter Type:')"
          class="filters-select"
          :placeholder="t('Select')"
          icon-align="left"
          :value="selectedFilterType"
          :items="filterTypes"
          @on-change="onFilterTypeSelectChange"
        />
        <CustomSelect
          v-if="!isSelectedFilterMultipleChoice"
          :label="t('Field:')"
          class="filters-select"
          :placeholder="t('Select')"
          icon-align="left"
          :value="selectedField"
          :items="fields"
          :disabled="!fields.length"
          @on-change="onFieldSelectChange"
        />
      </div>
    </div>
    <div
      v-show="currentStep === 2"
      class="filters-content list-container"
    >
      <div class="rendered-list-column">
        <div
          v-if="isComputedReportView"
          class="sub-title"
        >
          {{ filterDescription }}
        </div>
        <div class="search-item-container">
          <h4>{{ searchTitle }}</h4>
          <div class="search-item">
            <input
              v-model="searchQuery"
              class="input"
              type="text"
              :aria-label="placeholder"
              :placeholder="placeholder"
              @input="debounceSearchEvent"
            >
            <CustomButton
              class="search-button"
              purpose="transparent"
              icon-name="search"
              icon-only
              :icon-width="22"
              :icon-height="22"
            />
          </div>
        </div>
        <ul class="rendered-list">
          <BufferImage
            v-if="dataLoading"
            color="black"
            float="center"
            class="loading-spinner"
          />
          <template v-if="!dataLoading">
            <ListItem
              v-for="(item, i) in renderedList"
              :key="i"
              :item="item"
              @on-change="handleListItemClick"
            />
          </template>
        </ul>
      </div>
    </div>
    <div
      v-show="currentStep === 3"
      class="filters-content list-container"
    >
      <div class="sub-title">
        {{ filterDescription }}
      </div>
      <div class="selected-records-column">
        <div class="selected-records-radio">
          <CustomRadio
            class="filter-radio"
            :label="t('Include')"
            value="include"
            :name="`filter-type${filterNumber}`"
            :checked="selectedRadioValue === 'include'"
            @on-change="handleRadioChange('include')"
          />
          <CustomRadio
            class="filter-radio"
            :label="t('Exclude')"
            value="exclude"
            :name="`filter-type${filterNumber}`"
            :checked="selectedRadioValue === 'exclude'"
            @on-change="handleRadioChange('exclude')"
          />
        </div>
        <ul class="selected-list">
          <SelectedItem
            v-for="(item, i) in chosenList"
            :key="i"
            :item="item"
            @delete-item="deleteItem"
          />
        </ul>
      </div>
    </div>
    <ShowGapsCheckbox v-if="reportType === SALES_VS_GAPS" />
    <div class="filters-footer">
      <CustomButton
        small
        :label="footerButtonLabel"
        :disabled="!isNextStepEnabled"
        @on-click="onFooterButtonClick"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { CustomRadio, BufferImage, CustomButton, CustomSelect } from '@sales-i/dsv3';
import ListItem from '@/intelligence/components/EnquiryReport/EnquiryFilter/ListItem';
import SelectedItem from '@/intelligence/components/EnquiryReport/EnquiryFilter/SelectedItem';
import ShowGapsCheckbox from '@/intelligence/components/EnquiryReport/EnquiryFilter/ShowGapsCheckbox';
import { SET_FILTERS, FETCH_BUBBLE_VALUES, DELETE_FILTER } from '@/intelligence/store/actionType';
import { GET_BY_ENTITY } from '@/shared/store/actionType';
import { INTERACTIONS_FILTER_OPTIONS, INTERACTION_SPEND_SUMMARY_FILTERS, TEMPLATE_REPORT_CUSTOMER_FILTERS } from '@/intelligence/store/data/filters';
import { isInteractionOutcomesReport, isReportView } from '@/shared/utils/urlCheckers';
import { useUsers } from '@/shared/composables/useUsers';
import { CUSTOMER_ACTIVITY, CUSTOMER_TEMPLATE_REPORT_TYPES, INTERACTION_OUTCOMES, INTERACTION_SPEND_SUMMARY, SALES_VS_GAPS } from '@/intelligence/store/data/reportTypes';
import { REPORT_AREA_CUSTOMER } from '@/intelligence/store/data/areas';
import { debounce, t } from '@sales-i/utils';
import addFilterToUrlPath from '@/intelligence/utils/addFilterToUrlPath';

const props = defineProps({
  filterNumber: {
    type: Number,
    default: 0,
  },
  filters: {
    type: Array,
    default: () => []
  },
  inputData: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['applyFilters']);

const store = useStore();
const vroute = useRoute();

const { usersOptions } = useUsers(store);

const filterOptions = ref([]);
const renderedList = ref([]);
const chosenList = ref([]);
const uneditedFilterObject = ref(null);
const selectedField = ref('');
const selectedFilterType = ref('');
const searchQuery = ref('');
const selectedRadioValue = ref('include');
const currentStep = ref(1);
const isNextStepEnabled = ref(false);

const isComputedReportView = computed(isReportView);

const bubbleAreas = computed(() => store.state.intelligence.enquiry.bubbleAreas);
const bubbleData = computed(() => store.state.intelligence.enquiry.bubbleData);
const bubbleValues = computed(() => store.state.intelligence.enquiry.bubbleValues);
const filtersApplied = computed(() => store.state.intelligence.enquiry.filtersApplied);
const dataLoading = computed(() => store.state.intelligence.enquiry.bubblesLoading);
const customSchema = computed(() => store.state.admin.fields.data);
const interactionsFilters = computed(() => store.state.intelligence.performanceInsight.filters);

const reportType = computed(() => props.inputData.urlCode);
const placeholder = computed(() => t(`Search ${activeField.value}`, 'search_field', {
  interpolations: {
    field: activeField.value,
  },
}));

const fields = computed(() => {
  if (bubbleData.value) {
    switch (selectedFilterType.value) {
    case 'analysis_field_id':
      return bubbleData.value
        .filter(field => field.type === 'analysis_field' && field.area === REPORT_AREA_CUSTOMER)
        .map(field => {
          return {
            text: field.title,
            value: field.id,
          };
        });
    default:
      // rendering the list of bubbles excluding these already in use
      return bubbleData.value.reduce((bubbleFields, bubble) => {
        const { title, id, area } = bubble;
        if (
          area === selectedFilterType.value &&
            (!filtersApplied.value.some(e => e.id === id) || id === (currentFilter?.value?.id ?? ''))
        ) {
          bubbleFields.push({ text: title, value: id, area });
        }
        return bubbleFields;
      }, []);
    }
  } else {
    return [];
  }
});

const currentFilter = computed(() => filtersApplied.value[props.filterNumber]);
const filterTypes = computed(() => {
  switch (reportType.value) {
  case INTERACTION_OUTCOMES:
    return INTERACTIONS_FILTER_OPTIONS;
  case INTERACTION_SPEND_SUMMARY:
    return INTERACTION_SPEND_SUMMARY_FILTERS.filter(filter => filter.value !== 'analysis_field_id');
  case [...CUSTOMER_TEMPLATE_REPORT_TYPES, CUSTOMER_ACTIVITY].find(type => type === reportType.value):
    return TEMPLATE_REPORT_CUSTOMER_FILTERS;
  default:
    return bubbleAreas.value.map(area => {
      return { text: area, value: area };
    });
  }
});
const activeField = computed(() => {
  const field = bubbleData.value ? bubbleData.value.find(el => el.id === selectedField.value) : '';
  return field ? field.title : '';
});
const footerButtonLabel = computed(() => {
  if (isInteractionOutcomesReport()) {
    return t('Apply Filters');
  }
  switch (currentStep.value) {
  case 1:
    return t('Apply Filters');
  case 2:
    return t('Continue');
  case 3:
    return t('Add Selections');
  default:
    return '';
  }
});
const filterDescription = computed(() => reportType.value === INTERACTION_SPEND_SUMMARY ? activeField.value : `${selectedFilterType.value} > ${activeField.value}`);
const debounceSearchEvent = computed(() => {
  const isInteractionFilter = selectedFilterType.value === 'user' || selectedFilterType.value === 'interaction_type';

  if (isInteractionOutcomesReport() || isInteractionFilter) {
    if (searchQuery.value.length) {
      filterList();
    } else {
      resetFilterList();
    }
    return;
  }

  return debounce(getBubbleValues, 500);
});
const getInteractionSummaryFields = computed(() => {
  let area = '';
  switch (selectedFilterType.value) {
  case 'interaction_type':
    area = 'type';
    break;
  case 'interaction_outcome':
    area = 'outcome';
    break;
  case 'next_action':
    area = 'nextAction';
    break;
  }

  switch (selectedFilterType.value) {
  case 'interaction_type':
  case 'interaction_outcome':
  case 'next_action':
    return customSchema.value[area]
      .map(value => ({
        text: value.name,
        value: value.id,
        area: selectedFilterType.value,
        isChecked: interactionsFilters.value[selectedFilterType.value].includes(value.id)
      }));
  case 'user':
    return usersOptions.value.map(user => ({
      text: user.text,
      value: user.value,
      area: selectedFilterType.value,
      isChecked: interactionsFilters.value.user.includes(user.value)
    }));
  default:
    return [];
  }
});
const getInteractionSpendSummaryFields = computed(() => {
  switch (selectedFilterType.value) {
  case 'interaction_type':
    return customSchema.value['type']
      .map(value => ({
        text: value.name,
        value: value.id,
        area: selectedFilterType.value,
        isChecked: interactionsFilters.value[selectedFilterType.value].includes(value.id)
      }));
  case 'user':
    return usersOptions.value.map(user => ({
      text: user.text,
      value: user.value,
      area: selectedFilterType.value,
      isChecked: interactionsFilters.value.user.includes(user.value)
    }));
  case 'analysis_field_id':
    return bubbleValues.value;
  default:
    return [];
  }
});
const searchTitle = computed(() => {
  if (!selectedFilterType.value) return;

  switch (reportType.value) {
  case INTERACTION_OUTCOMES:
    return INTERACTIONS_FILTER_OPTIONS.find(option => option.value === selectedFilterType.value).text;
  case INTERACTION_SPEND_SUMMARY:
    return INTERACTION_SPEND_SUMMARY_FILTERS.find(option => option.value === selectedFilterType.value).text;
  default:
    return selectedFilterType.value;
  }
});
const isSelectedFilterMultipleChoice = computed(() => {
  let isMultiple = false;

  if (selectedFilterType.value) {
    switch (reportType.value) {
    case INTERACTION_OUTCOMES:
      return true;
    case INTERACTION_SPEND_SUMMARY:
      isMultiple = INTERACTION_SPEND_SUMMARY_FILTERS.filter(filter => {
        if (filter.value === selectedFilterType.value) return filter;
      });
      return isMultiple[0].isMultipleChoice;
    default:
      return isMultiple;
    }
  } else {
    return !isMultiple;
  }
});

watch(() => selectedField.value, newField => {
  isNextStepEnabled.value = !!newField;
});

watch(() => chosenList.value, newField => {
  if (currentStep.value === 2) isNextStepEnabled.value = !!newField.length;
});

watch(() => bubbleValues.value, () => {
  renderList();
});

onMounted(async() => {
  if (currentFilter.value) {
    const { selectedFilterType: newFilterType, id, selected_values, filter_type } = currentFilter.value;
    selectedFilterType.value = newFilterType;
    await onFieldSelectChange(id);
    chosenList.value = [...selected_values];
    uneditedFilterObject.value = getFilterObject();
    selectedRadioValue.value = filter_type;
    renderList();
  } else {
    const editedFilter = props.filters[props.filterNumber];
    if (editedFilter) {
      const { filterKey, selected_values, } = editedFilter;
      onFilterTypeSelectChange(filterKey);
      chosenList.value = [...selected_values];
      uneditedFilterObject.value = editedFilter;
      renderList();
    }
  }

  if (isInteractionOutcomesReport() || reportType.value === INTERACTION_SPEND_SUMMARY) getCustomSchemaFields({ entity: 'interactions' });
});

const fetchBubbleValues = params => store.dispatch(`intelligence/enquiry/${FETCH_BUBBLE_VALUES}`, params);
const setFilter = params => store.dispatch(`intelligence/enquiry/${SET_FILTERS}`, params);
const getCustomSchemaFields = params => store.dispatch(`admin/fields/${GET_BY_ENTITY}`, params);
const removeFilter = filter => store.dispatch(`intelligence/performanceInsight/${DELETE_FILTER}`, filter);

const getBubbleValues = async() => {
  await fetchBubbleValues([selectedField.value, searchQuery.value]);
  resetFilterList();
};
const onFilterTypeSelectChange = newFilterType => {
  selectedFilterType.value = newFilterType;
  selectedField.value = '';
  chosenList.value = [];
  filterOptions.value = [];
  isSelectedFilterMultipleChoice.value ? (isNextStepEnabled.value = true) : (isNextStepEnabled.value = false);
  if (isInteractionOutcomesReport()) {
    isNextStepEnabled.value = true;
  }
};
const onFieldSelectChange = async(newField) => {
  selectedField.value = newField;
  chosenList.value = [];
  if (!isInteractionOutcomesReport()) {
    await getBubbleValues();
    renderList();
    resetFilterList();
  }
};
const handleListItemClick = eventObject => {
  const { value, id, checked } = eventObject;
  if (checked) {
    chosenList.value.push({ value, id });
  } else {
    chosenList.value = chosenList.value.filter(el => el.value !== value);
  }

  filterOptions.value.find(e => e.value === value).checked = checked;
};
const handleRadioChange = value => {
  selectedRadioValue.value = value;
};
const deleteItem = item => {
  chosenList.value = chosenList.value.filter(el => el.value !== item.value);
  if (!chosenList.value.length) {
    currentStep.value = 2;
    renderList();
  }
};
const renderList = () => {
  let list = [];
  let valueLabel = '';
  let idLabel = '';

  switch (reportType.value) {
  case INTERACTION_OUTCOMES:
    list = getInteractionSummaryFields.value;
    valueLabel = 'text';
    idLabel = 'value';
    break;
  case INTERACTION_SPEND_SUMMARY:
    list = getInteractionSpendSummaryFields.value;
    valueLabel = selectedFilterType.value !== 'analysis_field_id' ? 'text' : 'value';
    idLabel = selectedFilterType.value !== 'analysis_field_id' ? 'value' : 'id';
    break;
  default:
    list = bubbleValues.value;
    valueLabel = 'value';
    idLabel = 'id';
  }

  renderedList.value = filterOptions.value = list.map(el => {
    return {
      value: el[valueLabel],
      id: el[idLabel],
      checked: el.isChecked || isRowChecked(el[valueLabel]),
    };
  });
};
const isRowChecked = value => {
  const selectedElement = chosenList.value.find(el => el.value === value);
  return !!selectedElement;
};
const getFilterObject = () => {
  const isInteractionFilter = reportType.value === INTERACTION_SPEND_SUMMARY;
  const analysisFieldLabel = INTERACTION_SPEND_SUMMARY_FILTERS.find(filter => filter.value === 'analysis_field_id').text;

  return {
    title: !isInteractionFilter ? `Filter ${props.filterNumber + 1}` : analysisFieldLabel,
    selectedFilterType: selectedFilterType.value,
    selectedFieldTitle: activeField.value,
    description: filterDescription.value,
    id: selectedField.value,
    selected_values: chosenList.value,
    filter_type: selectedRadioValue.value,
  };
};
const onFooterButtonClick = () => {
  switch (currentStep.value) {
  case 1:
    isNextStepEnabled.value = false;
    currentStep.value++;
    if (chosenList.value.length) isNextStepEnabled.value = true;
    if (isInteractionOutcomesReport() || isSelectedFilterMultipleChoice.value) {
      renderList();
    }
    break;
  case 2:
    currentStep.value++;
    if (isInteractionOutcomesReport() || isSelectedFilterMultipleChoice.value) {
      runStepTwo();
    }
    break;
  case 3:
    runStepThree();
    break;
  }
};
const runStepTwo = () => {
  let pathWithNewFilter = '';
  const interactionFilterObject = {
    type: selectedFilterType.value,
    value: [...chosenList.value].map(el => el.id),
  };
  currentStep.value = -1;
  if (uneditedFilterObject.value) {
    let pathWithOldFilter = addFilterToUrlPath(uneditedFilterObject.value, true);
    pathWithNewFilter = vroute.fullPath.replace(pathWithOldFilter, `&${addFilterToUrlPath(interactionFilterObject, true, vroute.query?.length > 0)}`);
    removeFilter(uneditedFilterObject.value);
  } else {
    pathWithNewFilter = `${vroute.fullPath}${addFilterToUrlPath(interactionFilterObject, true, vroute.query?.length > 0)}`;
  }
  history.replaceState({}, '', pathWithNewFilter);
  emit('applyFilters', interactionFilterObject);
};
const runStepThree = () => {
  const filterObject = getFilterObject();
  const isSummaryReport = reportType.value === INTERACTION_SPEND_SUMMARY;
  setFilter([filterObject, isSummaryReport ? 0 : props.filterNumber]);

  const filterPath = addFilterToUrlPath(filterObject);
  const fullPath = vroute.fullPath;

  let pathWithNewFilter = uneditedFilterObject.value
    ? fullPath.replace(addFilterToUrlPath(uneditedFilterObject.value), filterPath)
    : fullPath.includes('?')
      ? `${fullPath}${filterPath}` // this is to set filters properly before bubbles are choosen or report is opened
      : `${fullPath}?${filterPath}`;  // this is for most cases to set filters and save them in the url

  history.replaceState({}, '', pathWithNewFilter);
  emit('applyFilters');
};
const filterList = () => {
  if (!isInteractionOutcomesReport() && reportType.value !== INTERACTION_SPEND_SUMMARY) return;

  if (!searchQuery.value) {
    resetFilterList();
    return;
  }

  renderedList.value = filterOptions.value.filter(option => option.value.toLowerCase().includes(searchQuery.value.toLowerCase()));
};
const resetFilterList = () => {
  renderedList.value = filterOptions.value;
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.filters-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0;

  .sub-title {
    background: var(--colour-panel-alert-base);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-semibold);
    padding: 12px var(--spacing-2);
    line-height: 16px;
    height: 40px;
    width: 100%;
  }

  .filters-content {
    position: relative;
    padding: var(--spacing-3) var(--spacing-2) var(--spacing-1);

    .filters-description {
      font-size: var(--font-size-small);
      padding: var(--spacing-1) 0;
    }

    .filters-select {
      @media #{map-get($display-breakpoints, 'md-and-up')} {
        max-width: 360px;
      }
    }

    .add-filter-button {
      margin-left: auto;
    }
  }

  .list-container {
    margin: 0;
    box-shadow: none;
    padding: 0;

    .rendered-list-column {
      .search-item-container {
        padding: var(--spacing-2) var(--spacing-3) var(--spacing-1);
        background: var(--colour-panel-action);

        h4 {
          margin-bottom: var(--spacing-1);
          font-weight: var(--font-weight-semibold);
        }

        .search-item {
          position: relative;
          margin-bottom: var(--spacing-1);
        }
      }

      .input {
        background: var(--colour-panel-g-0);
        box-shadow: 0 0 var(--spacing-half) var(--shadow-spread) var(--colour-panel-g-16);
        border-radius: var(--spacing-4);
        font-size: var(--font-size-small);
        line-height: var(--spacing-2);
        font-weight: var(--font-weight-regular);
        padding-left: var(--spacing-5);
      }

      .search-button {
        position: absolute;
        top: 50%;
        left: var(--spacing-1);
        transform: translateY(-50%);
      }

      .rendered-list {
        overflow-y: auto;
        height: 30vh;
        max-height: calc(100vh - 400px);

        .loading-spinner {
          position: absolute;
          top: calc(50% + 50px);
          transform: translateY(-50%);
          left: 0;
          right: 0;
          margin: 0 auto;
          z-index: 1;
        }

        :deep(.checkbox) {
          margin-top: 0;
        }
      }
    }

    .selected-records-column {
      background: var(--colour-panel-g-2);
      padding: var(--spacing-3) var(--spacing-4) 0;
      max-height: calc(100vh - 300px);
      height: 300px;
      overflow-y: auto;

      .selected-records-radio {
        display: flex;
        justify-content: space-between;
      }

      .selected-list {
        margin-top: var(--spacing-2);
        overflow-y: auto;
        max-height: calc(100vh - 369px);
        height: 356px;
      }
    }

    .filter-radio {
      cursor: pointer;

      &:first-child {
        margin-right: var(--spacing-3);
      }
    }
  }

  .filters-footer {
    background-color: var(--colour-panel-action);
    padding: var(--spacing-2);
    display: flex;
    justify-content: center;
  }
}
</style>
