/**
 * vue2filters port
 * Converts a string into Capitalize
 * 
 * 'abc' => 'Abc'
 * 
 * @param {Object} options
 */

export const capitalize = (value, options = { onlyFirstLetter : false }) => {
  if (!value && value !== 0) return '';
  if(options.onlyFirstLetter) {
    return value.toString().charAt(0).toUpperCase() + value.toString().slice(1);
  } else {
    value = value.toString().toLowerCase().split(' ');
    return value.map(function(item) {
      return item.charAt(0).toUpperCase() + item.slice(1);
    }).join(' ');
  }
};

const defaultOpts = {
  divider: '_',
  segment: '.',
  dividerReplace: ' ',
  segmentReplace: ' - '
};

export const convertToUserFriendlyStr = (value, options = {}) => {
  let opts = {
    ...defaultOpts,
    ...options,
  };
  let str = value.split(opts.divider).join(opts.dividerReplace);
  str = str.split(opts.segment).join(opts.segmentReplace);
  return capitalize(str);
};

/**
 * vue2filters port
 * Truncate at the given || default length
 *
 * 'lorem ipsum dolor' => 'lorem ipsum dol...'
 */

export const truncate = (value, length) => {
  length = length || 15;
  if( !value || typeof value !== 'string' ) return '';
  if( value.length <= length) return value;
  return value.substring(0, length) + '...';
};

/**
 * replaces special HTML chars with number code representation
 * @param {*} str input string, for example that comes from
 * @returns decoded string
 */
export const parseHtmlEntities = (str) => {
  return (str && str.replace) ? str.replace(
    /&#([0-9]{1,3});/gi, 
    function (match, numStr) {
      var num = parseInt(numStr, 10); // read num as normal number
      return String.fromCharCode(num);
    }
  ) : '';
};