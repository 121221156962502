import {
  PUT_BY_ID,
  PUT_BY_ID_LOADING,
  PUT_BY_ID_ERROR,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const putByIdMutations = {
  [PUT_BY_ID]: (state, data) => {
    state.customSection.data = data;
    state.customSection.loading = false;
    state.customSection.loaded = true;
    state.customSection.updated = true;
  },
  [PUT_BY_ID_LOADING]: (state) => {
    state.customSection.loading = true;
    state.customSection.loaded = false;
  },
  [PUT_BY_ID_ERROR]: (state) => {
    state.customSection.loading = false;
    state.customSection.loaded = false;
    state.customSection.updated = false;
  },
};
// actions
export const putByIdActions = {
  [PUT_BY_ID]: async ({ commit, state }, params) => {
    const { id, section_id, entity, requestBody, options = {version: '2'}} = params;
    commit(PUT_BY_ID_LOADING);
    for (let i = 0, len = requestBody.length; i < len; i++) {
      if (!requestBody[i].list_option_id) {
        // needed else BE errors
        delete requestBody[i].list_option_id;
      }
    }
    try {
      await httpRequest('put', `${entity}/${id}/sections/${section_id}`, requestBody, options);
      commit(PUT_BY_ID, {
        ...state.customSection.data,
        values: requestBody,
      });
    } catch (error) {
      commit(PUT_BY_ID_ERROR);
    }
  },
};
