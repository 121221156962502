<template>
  <CustomForm
    v-if="fieldsToUse.length"
    :formdata="fields"
    :actions="actions"
    :sticky-actions="false"
  />
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import CustomForm from '@/shared/components/Form/Form.vue';
import { SET_USER_DETAILS } from '@/shared/store/actionType';
import { baseUrl, companyArea, permissionsSection, rolesSection } from '@/admin/router/urlBits';
import { navigateToUrl } from 'single-spa';

const emit = defineEmits(['stageUpdate']);

const store = useStore();
const vroute = useRoute();

const roleDetails = computed(() => store.state.admin.pbac.currentlyEditing.details);
const setUserDetails = userDetails => store.dispatch(`admin/users/${SET_USER_DETAILS}`, userDetails);

const fields = ref([
  {
    stage: 'Role Details',
    fields: [
      {
        label: `${t('Role name')}:`,
        id: 'name',
        type: 'text',
        min: 1,
        max: 40,
        placeholder: '',
        required: true,
        disabled: false,
        errorMessage: t('Please enter a valid role name'),
      },
      {
        label: `${t('Role description')}:`,
        id: 'description',
        type: 'textarea',
        minLength: 1,
        placeholder: '',
        required: true,
        disabled: false,
        errorMessage: t('Please give this role a description'),
      },
    ],
  }
]);

const fieldsToUse = computed(() => {
  if (vroute.params.addedit === 'edit') {
    if (roleDetails.value.name) {
      let newFields = [ ...fields.value ];
      newFields[0].fields.forEach(field => {
        field.value = roleDetails.value[field.id] || '';
      });
      return newFields;
    }
    return [];
  }
  return fields.value;
});

const actions = ref([
  {
    label: t('Cancel'),
    purpose: 'reversed',
    small: true,
    onClick: () => {
      navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${rolesSection}`);
    },
    display: true,
  },
  {
    label: t('Continue'),
    small: true,
    onClick: (data) => {
      setUserDetails(data);
      if (vroute.params.id && vroute.params.addedit === 'edit') {
        navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${rolesSection}/${vroute.params.id}/edit/restrictions`);
      } else {
        navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${rolesSection}/add/restrictions`);
      }
    },
    display: true,
    isSubmit: true,
  },
]);

onMounted(() => {
  emit('stageUpdate', 'Role details');
});
</script>

<style lang="scss" scoped>
</style>
