<template>
  <div
    :id="id"
    class="insights-wrapper"
    :class="classes"
  >
    <div class="container container--narrow px-2">
      <header>
        <IconBase
          :icon-color="iconColor"
          :icon-name="iconName"
          :height="80"
          :width="80"
        />
        <h2 ref="pageTitle">
          {{ heading }}
        </h2>
        <p>{{ description }}</p>
      </header>
      <BufferImage
        v-if="dataLoading && isHomePage"
        color="var(--colour-utility-black)"
        float="center"
      />
      <div
        v-else
        class="o-grid"
      >
        <template
          v-for="item in insightLinks"
          :key="item.title"
        >
          <!-- Loop through insightLinks and hide items that have a matching false value in the 'reportsPolicies' object -->
          <CardInsights
            v-if="permissions.read && !item.disabled"
            icon
            :title="item.title"
            :description="item.description"
            @open="goToReport(item)"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
// InsightSignPost
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import CardInsights from '@/dsv3/Card/CardInsights';
import { IconBase, BufferImage } from '@sales-i/dsv3';
import {
  REPORT_AREA_PEOPLE,
  REPORT_AREA_PRODUCT,
  REPORT_AREA_CUSTOMER,
  REPORT_AREA_PERFORMANCE,
} from '@/intelligence/store/data/areas';

import usePermissions from '@/shared/composables/usePermissions';
import { intelligence_insights } from '@/shared/store/data/policies';
import { navigateToUrl } from 'single-spa';

// Variables
const store = useStore();
const vroute = useRoute();
const vrouter = useRouter();
const { getPermissions } = usePermissions();

const props = defineProps({
  description: {
    type: String,
    default: '',
  },
  heading: {
    type: String,
    default: '',
  },
  iconColor: {
    type: String,
    default: '',
  },
  iconName: {
    type: String,
    default: '',
  },
  insightLinks: {
    type: Array,
    default: () => [],
  },
  section: {
    type: String,
    default: '',
    validator: function (value) {
      // Use global names
      return [
        REPORT_AREA_PEOPLE.toLowerCase(),
        REPORT_AREA_PERFORMANCE.toLowerCase(),
        REPORT_AREA_PRODUCT.toLowerCase(),
        REPORT_AREA_CUSTOMER.toLowerCase(),
      ].includes(value);
    },
  },
});

const dataLoading = computed(() => store.state.intelligence.shared.loading);

const permissions = computed(() => getPermissions(intelligence_insights));

const isHomePage = computed(() => vroute.path === '/');
const classes = computed(() => `insights-wrapper--${props.section}`);
const id = computed(() => props.section);

function goToReport(item) {
  const route = vrouter.resolve({ name: item.urlCode });
  navigateToUrl(route.href);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.insights-wrapper {
  padding-top: var(--spacing-8);
  padding-bottom: var(--spacing-12);
}

// Customer
.insights-wrapper--customer {
  background-color: var(--colour-panel-customer);

  h2,
  header :deep(svg) {
    color: var(--colour-data-turmeric-label);
  }

  p {
    font-size: var(--font-size-4);
  }

  .c-card :deep(svg) {
    background-color: var(--colour-data-turmeric-label);
  }
}

// Performance / People
.insights-wrapper--performance,
.insights-wrapper--people {
  background-color: var(--colour-panel-performance);

  h2,
  header :deep(svg) {
    color: var(--colour-data-puerto-rico-dark);
  }

  p {
    font-size: var(--font-size-4);
  }

  .c-card :deep(svg) {
    background-color: var(--colour-data-puerto-rico-dark);
  }
}

// Product
.insights-wrapper--products,
.insights-wrapper--communities,
.insights-wrapper--associations {
  background-color: var(--colour-panel-product);

  h2,
  header :deep(svg) {
    color: var(--colour-data-viking-label);
  }

  p {
    font-size: var(--font-size-4);
  }

  .c-card :deep(svg) {
    background-color: var(--colour-data-viking-label);
  }
}

.o-grid {
  display: grid;
  gap: var(--spacing-3);

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    display: grid;
    gap: var(--spacing-3);
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  }
}

header {
  display: grid;
  place-content: center;
  justify-items: center;
  text-align: center;
  padding-bottom: var(--spacing-4);

  :deep(svg) {
    height: 80px;
    width: 80px;
  }

  h2 {
    font-size: var(--font-size-1);
    font-weight: var(--font-weight-semibold);
    line-height: 1.2;
  }
}
</style>
