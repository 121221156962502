import { customerScope, insightsScope, reportScope } from '@/intelligence/router/urlBits';
import { INTERACTION_OUTCOMES } from '@/intelligence/store/data/reportTypes';
export const isInteractionOutcomesReport = () => {
  return window.location.pathname.includes(`/${INTERACTION_OUTCOMES}`);
};

export const isReportView = () => {
  return window.location.pathname.includes(`/${reportScope}`);
};

export const isCustomerInsightsReportView = () => {
  return window.location.pathname.includes(`/${insightsScope}/${customerScope}`);
};
