<template>
  <div class="card-stack">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'CardStack',
};
</script>

<style lang="scss" scoped>
.card-stack {
  display: flex;
  flex-flow: column nowrap;

  :deep(.b-radius-0) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  :deep(.t-radius-0) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>
