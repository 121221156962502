<template>
  <CRMCard
    :title="title"
    :currency="currency"
    :chips="getChips(item)"
    class="opportunity-card"
  >
    <template #info>
      <slot name="info" />
    </template>
  </CRMCard>
</template>

<script>
import { CRMCard } from '@sales-i/dsv3';
import { mapState } from 'vuex';

export default {
  components: {
    CRMCard,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState({
      currency: state => state.userDetails.currencyName
    })
  },
  methods: {
    getChips(item) {
      return [
        {
          value: this.getRolledUpValue(item),
          prefix: this.valueSymbol,
          abbr: true,
          green: 20,
          amber: 10,
        },
        {
          value: this.getHighestProbability(item),
          suffix: '%',
          green: 67,
          amber: 33,
        },
      ];
    },
    getRolledUpValue(item) {
      let value = 0;
      const productsLength = item.products.length;

      for (let x = 0; x < productsLength; x++) {
        value += item.products[x].value;
      }

      return value;
    },
    getHighestProbability(item) {
      let value = 0;

      item.products.forEach(_ref => {
        if (_ref.confidence > value) {
          value = _ref.confidence;
        }
      });

      return value;
    },
  },
};
</script>
