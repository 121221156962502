<template>
  <div class="sales-opportunity-report">
    <section
      ref="headerSection"
      class="header-section"
    >
      <h3>{{ t('Sales Opportunity') }}</h3>
      <p>{{ filterTitle }}</p>
      <br>
      <div class="filters">
        <MenuFilter
          tabindex="0"
          :selected-value="opportunityType"
          :items="OPPORTUNITY_FILTERS"
          menu-position="right"
          @on-change="emit('update:opportunityType', $event.value)"
        />
        <span
          v-if="!loading"
          class="totals"
        >{{
          t(`${currency.number(stages.total_count)} ${opportunityType} opportunities`, 'total_type_opportunities', {
            interpolations: {
              total: stages.total_count,
              type: opportunityType,
            },
          })
        }}</span>
      </div>
    </section>
    <OpportunityReport
      :key="componentKey"
      :opportunity-type="opportunityType"
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { MenuFilter } from '@sales-i/dsv3';
import OpportunityReport from './OpportunityReport';
import { OPPORTUNITY_FILTERS } from '@/intelligence/store/data/filters';
import { currency, t } from '@sales-i/utils';

const store = useStore();
const componentKey = ref(0);

const emit = defineEmits(['update:opportunityType']);
defineProps({
  opportunityType: {
    type: String,
    default: 'open',
  },
});

const loading = computed(() => store.state.intelligence.performanceInsight.loading);
const stages = computed(() => store.state.intelligence.performanceInsight.stages);
const filterTitle = computed(() => store.state.intelligence.performanceInsight.filterTitle);
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.header-section {
  position: relative;
  padding-bottom: var(--spacing-4);

  h3 {
    text-align: center;
    font-weight: var(--font-weight-semibold);
  }

  p {
    text-align: center;
    font-size: var(--font-size-small);
    line-height: var(--font-size-body);
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: var(--spacing-1) 0 0;

    h3,
    p {
      display: none;
    }
  }

  .filters {
    display: flex;
    justify-content: space-between;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      align-items: center;
      font-size: var(--font-size-small);
    }
  }

  .totals {
    text-transform: capitalize;
  }
}

:deep(.accordion-container.big .heading) {
  padding-left: var(--spacing-1);
}
</style>
