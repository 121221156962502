<template>
  <div class="form-grid flow interaction-opportunity-update">
    <h1 class="text-3 fw-sbld flex">
      {{ t('Do you need to update attached opportunities?') }}
      <CustomButton
        v-if="permissions.create"
        class="ml-1"
        icon-name="plus"
        icon-color="var(--colour-utility-white)"
        icon-only
        :icon-height="24"
        :icon-width="24"
        @click="onAddClick"
      />
    </h1>

    <div
      v-if="currentlyEditing.opportunities.length"
      class="three-col-equal-height"
    >
      <OpportunityCard
        v-for="(item, index) in currentlyEditing.opportunities"
        :key="index"
        class="opp-card"
        :item="item"
        :links-enabled="['open', 'edit', 'delete', 'remove']"
        :custom-handlers="['edit']"
        open-in-new-tab
        @edit="onEdit"
        @opportunity-deleted="onOpportunityDeleted"
        @remove="onOppRemove"
      />
    </div>
    <p v-else>
      {{ t('No opportunities attached to this interaction') }}
    </p>
    <Teleport to="#modal-teleport-target">
      <AddEditOpportunityModal
        v-if="isAddEditOpportunityModal"
        :id="selectedOpportunityId"
        :key="opportunityModalKey"
        :entity-id="currentlyEditing.entity.id"
        :entity-type="currentlyEditing.entityType"
        :entity-name="currentlyEditing.entity.name"
        :entity-preselected="!!currentlyEditing.entity.id"
        @close="onCloseOpportunityModal"
        @saved="onOpportunitySaved"
      />
    </Teleport>
  </div>
</template>

<script setup>
// InteractionOpportunityUpdateForm
import OpportunityCard from '@/crm/components/Opportunity/OpportunityCard.vue';
import AddEditOpportunityModal from '@/crm/components/Opportunity/AddEditOpportunityModal.vue';
import { useInteraction } from '@/crm/composables/useInteraction';
import { useOpportunity } from '@/crm/composables/useOpportunity';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import { CustomButton } from '@sales-i/dsv3';

const isAddEditOpportunityModal = ref(false);
const selectedOpportunityId = ref(0);

const vroute = useRoute();
const store = useStore();

const { permissions, selected, getOpportunities } = useOpportunity({ store, vroute });
const opportunityModalKey = computed(
  () => `${vroute.params.id || 0}_${currentlyEditing.value.entity.name}_${selectedOpportunityId.value || 0}`
);

const { setOpportunity, removeOpportunity, currentlyEditing } = useInteraction({ store, vroute });

function loadEntityOpportunities() {
  getOpportunities({
    q: currentlyEditing.value.entity.name,
    sort: 'decision_date:desc,id:asc',
  });
}
async function onOpportunitySaved() {
  let opportunity = { ...selected.value };
  await removeOpportunity({ opportunity });
  await setOpportunity({ opportunity });
  onCloseOpportunityModal();
}
function onCloseOpportunityModal() {
  isAddEditOpportunityModal.value = false;
  selectedOpportunityId.value = 0;
}
function onEdit(item) {
  selectedOpportunityId.value = item.id;
  isAddEditOpportunityModal.value = true;
}
async function onOpportunityDeleted(opportunity) {
  loadEntityOpportunities();
  removeOpportunity({ opportunity });
}
function onAddClick() {
  isAddEditOpportunityModal.value = true;
}
function onOppRemove(item) {
  removeOpportunity({ opportunity: item });
}
</script>
