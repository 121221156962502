import { DATE_FILTER_OPTIONS } from './dateFilters';
import { t } from '@sales-i/utils';

// Template reports filter values
export const PERCENT_FROM_FILTER_OPTIONS = [
  { value: 0, text: '0%' },
  { value: 10, text: '10%' },
  { value: 20, text: '20%' },
  { value: 30, text: '30%' },
  { value: 40, text: '40%' },
  { value: 50, text: '50%' },
  { value: 60, text: '60%' },
  { value: 70, text: '70%' },
  { value: 80, text: '80%' },
  { value: 90, text: '90%' },
  { value: 100, text: '100%' }
];
export const LIMIT_FILTER_OPTIONS = [
  { value: 10, text: t('10 Customers', 'count_customers', { interpolations: { count: 10 }}) },
  { value: 20, text: t('20 Customers', 'count_customers', { interpolations: { count: 20 }}) },
  { value: 50, text: t('50 Customers', 'count_customers', { interpolations: { count: 50 }}) },
  { value: 100, text: t('100 Customers', 'count_customers', { interpolations: { count: 100 }}) },
];
export const PERIODS_FILTER_OPTIONS = [
  { value: 2, text: t('2 Months', 'number_months', { interpolations: { number: 2 }}) },
  { value: 3, text: t('3 Months', 'number_months', { interpolations: { number: 3 }}) },
  { value: 4, text: t('4 Months', 'number_months', { interpolations: { number: 4 }}) },
  { value: 5, text: t('5 Months', 'number_months', { interpolations: { number: 5 }}) },
  { value: 6, text: t('6 Months', 'number_months', { interpolations: { number: 6 }}) },
  { value: 7, text: t('7 Months', 'number_months', { interpolations: { number: 7 }}) },
  { value: 8, text: t('8 Months', 'number_months', { interpolations: { number: 7 }}) },
  { value: 9, text: t('9 Months', 'number_months', { interpolations: { number: 8 }}) },
  { value: 10, text: t('10 Months', 'number_months', { interpolations: { number: 10 }}) },
  { value: 11, text: t('11 Months', 'number_months', { interpolations: { number: 11 }}) },
  { value: 12, text: t('12 Months', 'number_months', { interpolations: { number: 12 }}) },
  { value: 13, text: t('13 Months', 'number_months', { interpolations: { number: 13 }}) }
];

// Template reports filter sets
export const REPORT_DATE_FILTER = {
  queryParamKey: 'dateType',
  values: DATE_FILTER_OPTIONS,
  isNumber: false,
  // TODO: check if this is correct or should be 'today/y'
  default: 'ytd',
  type: 'date'
};

export const REPORT_PERCENT_FROM_FILTER = {
  queryParamKey: 'percent_from',
  values: PERCENT_FROM_FILTER_OPTIONS,
  isNumber: true,
  default: 40,
  type: 'select'
};

export const REPORT_LIMIT_FILTER = {
  queryParamKey: 'limit',
  values: LIMIT_FILTER_OPTIONS,
  isNumber: true,
  default: 50,
  type: 'select'
};

export const REPORT_PERIODS_FILTER = {
  queryParamKey: 'periods',
  values: PERIODS_FILTER_OPTIONS,
  isNumber: true,
  default: 6,
  type: 'select'
};

export const REPORT_REVENUE_RANGE_FILTER_FROM = {
  queryParamKey: 'prev_year_from',
  label: 'Revenue from:',
  values: null,
  isNumber: true,
  default: 1000,
  type: 'input'
};

export const REPORT_REVENUE_RANGE_FILTER_TO = {
  queryParamKey: 'prev_year_to',
  label: 'Revenue to:',
  values: null,
  isNumber: true,
  default: 2000,
  type: 'input'
};

// Sales Opportunities filter sets
export const OPPORTUNITY_FILTERS = [
  {
    text: t('Open Opportunities'),
    value: 'open',
  },
  {
    text: t('Won Opportunities'),
    value: 'won',
  },
  {
    text: t('Lost Opportunities'),
    value: 'lost',
  },
];

export const SALES_OPORTUNITY_ENTITY = [
  { text: t('Customer'), value: 'cust' },
  { text: t('Representative'), value: 'rep' }
];

export const SALES_OPORTUNITY_ORDER_FILTER = [
  { text: t('Highest'), value: 'high' },
  { text: t('Lowest'), value: 'low' }
];

// Interactions filter
export const INTERACTIONS_FILTER_OPTIONS = [
  {
    text: 'Interaction type',
    value: 'interaction_type',
  },
  {
    text: 'Interaction outcome',
    value: 'interaction_outcome',
  },
  {
    text: 'Next action',
    value: 'next_action',
  },
  {
    text: 'User',
    value: 'user',
  },
];

export const INTERACTION_SPEND_SUMMARY_FILTERS = [
  {
    text: t('Interaction type'),
    value: 'interaction_type',
    isMultipleChoice: true
  },
  {
    text: t('User'),
    value: 'user',
    isMultipleChoice: true
  },
  {
    text: t('Analysis field'),
    value: 'analysis_field_id',
    isMultipleChoice: false
  },
];

export const INTERACTION_SPEND_SUMMARY_REPORTS = [
  {
    text: t('Customer view'),
    value: 'customer',
  },
  {
    text: t('Sales Rep'),
    value: 'rep',
  },
  {
    text: t('Parent account'),
    value: 'parent',
  },
];

export const TEMPLATE_REPORT_CUSTOMER_FILTERS = [
  { text: t('Customer'), value: 'Customer' },
  { text: t('Product'), value: 'Product' }
];

// AND/OR clause options
export const AND_OR_OPTIONS = [
  {
    title: t('AND'),
    value: 'AND',
  },
  {
    title: t('OR'),
    value: 'OR',
  },
];