export default function mapUserPayload(payload, id = false, selectedUser = false) {
  let { details, roles, status } = payload;
  let userDetails = {};
  if (selectedUser) {
    userDetails = { ...selectedUser };
  }
  userDetails = {
    ...userDetails,
    first_name: details.first_name ?? userDetails.first_name,
    last_name: details.last_name ?? userDetails.last_name,
    email: details.email ?? userDetails.email,
    telephone_1: details.telephone_1 ?? userDetails.telephone_1,
    telephone_2: details.telephone_2 ?? userDetails.telephone_2,
    roles: roles.length ? roles.map(x => x.id) : userDetails.roles.map(x => x.id),
    // checking roles length as mandatory, but could have valid empty sales rep array
    sales_rep_codes: roles.length ?
      details.sales_rep_codes?.map(x => ({ code: x.value, description: x.description })) ?? [] :
      userDetails.sales_rep_codes,
    status
  };
  if(id) {
    userDetails.id = id;
    delete userDetails.email;
  }
  return userDetails;
}