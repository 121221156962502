<template>
  <div class="interaction-history container container--fluid py-3">
    <div class="padded">
      <h3 class="fw-sbld mb-2">
        {{ t('Review historic interactions') }}
      </h3>

      <div
        v-if="loaded"
        class="cards-wrapper"
      >
        <div
          v-for="(item, index) in items.filter(x => !x.disabled)"
          :key="`wrap-${index}`"
        >
          <CRMCard
            :title="item.title"
            :links="[
              {
                text: 'Coming Soon',
                /* href: item.path, */
                action: 'open',
                emit: 'open',
              },
            ]"
            :on-click="() => {}"
            @open="() => {}"
          >
            <template #info>
              {{ item.subtitle }}
            </template>
          </CRMCard>
        </div>
      </div>
      <BufferImage
        v-else
        class="buffer"
        color="var(--colour-utility-black)"
        float="center"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import breakpoints from '@/shared/utils/breakpoints';
import { CRMCard, BufferImage } from '@sales-i/dsv3';
import { t } from '@sales-i/utils';

export default {
  name: 'InteractionHistory',
  components: {
    CRMCard,
    BufferImage,
  },
  data() {
    return {
      breakpoints,
    };
  },
  computed: {
    ...mapState({
      loading: state => state.crm.interactions.selected.loading,
      loaded: state => state.crm.interactions.selected.loaded,
      platform: state => state.platform,
    }),
    ...mapGetters({
      item: 'crm/interactions/item',
    }),
    customer() {
      return this.item && this.item.relationships && Array.isArray(this.item.relationships.customers)
        ? this.item.relationships.customers[0] || {}
        : {};
    },
    contact() {
      return this.item && this.item.relationships && Array.isArray(this.item.relationships.contacts) ? this.item.relationships.contacts[0] || {} : {};
    },
    items() {
      return [
        {
          title: t('Account History'),
          subtitle: t(`Review all calls with ${this.customer.name || 'customer'}`, 'review_all_calls_with_entity', {
            interpolations: {
              entity: this.customer.name || 'customer',
            },
          }),
          path: '/tobeplumbed',
          disabled: !this.customer || !this.customer.name,
        },
        {
          title: t('Contact History'),
          subtitle: t(`Review calls with ${this.contact.full_name || 'contact'}`, 'review_calls_with_name', {
            interpolations: {
              name: this.contact.full_name || 'contact',
            },
          }),
          path: '/tobeplumbed',
          disabled: !this.contact || !this.contact.full_name,
        },
        {
          title: t('Allocated History'),
          subtitle: t('Review calls that were allocated to other colleagues'),
          path: '/tobeplumbed',
          disabled: false,
        },
      ];
    },
  },
  watch: {
    $route() {},
  },
  methods: { t },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.interaction-history {
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--colour-panel-g-2);

  .cards-wrapper {
    display: flex;
    flex-flow: row nowrap;

    > div {
      flex: 1;
      max-width: 560px;
      margin: 0 calc(var(--spacing-4) * 0.5) var(--spacing-4) calc(var(--spacing-4) * 0.5);
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
      height: auto;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      flex-flow: column nowrap;
      > div {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.buffer {
  margin-bottom: var(--spacing-4);
}
</style>
