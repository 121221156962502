import MainView from '@/crm/views/Main/Main.vue';
import Dashboard from '@/crm/views/Dashboard/Dashboard.vue';
import SalesComparisonTab from '@/crm/views/Dashboard/SalesComparisonTab.vue';
import {
  setPlatform,
  getPlatform
} from '@/shared/utils/platform';
import { dashboardArea } from '@/crm/router/urlBits';


export default
[
  {
    path: '',
    component: MainView,
    beforeEnter: (to, from, next) => {
      const platform = getPlatform();
      if(!platform) {
        setPlatform('desktop');
      }
      next();
    },
  },
  {
    path: '/desktop',
    component: MainView,
    beforeEnter: (to, from, next) => {
      setPlatform('desktop');
      next();
    },
  },
  {
    path: '/mobile',
    component: MainView,
    beforeEnter: (to, from, next) => {
      setPlatform('mobile');
      next();
    },
  },
  {
    path: `/${dashboardArea}`,
    component: Dashboard
  },
  {
    path: '/comparison',
    component: SalesComparisonTab
  },
];
