<template>
  <CustomModal
    show-modal
    position="full"
    content-position="centre"
    @close-modal="emit('closeModal')"
  >
    <div class="title">
      <h1 class="fw-sbld text-2">
        *{{ customerName }}
      </h1>
      <h4> {{ alertName.split('|~~|')[0] }} </h4>
      <p> {{ dateRangeTitle }} </p>
    </div>

    <BufferImage
      v-show="isLoading"
      :size="40"
      float="center"
    />

    <FilterSummary
      :selected-query-id="selectedQueryId"
      class="mt-3 mb-3"
    />

    <div
      v-for="(row, rowIndex) in rows"
      :key="`row-${rowIndex}`"
      class="chips-container"
    >
      <h3 class="row-title">
        <a
          :href="getLink(row.dimensions[0].entity_id)"
          target="_blank"
        >
          {{ row.dimensions[0].dimensions[0] }}
        </a>
      </h3>

      <div class="chips-wrapper">
        <div
          v-for="(kpi, kpiIndex) in kpisTemplate"
          :key="`kpi-${rowIndex}-${kpiIndex}`"
          class="chip-container"
        >
          <p class="chip-title">
            {{ kpi.title }}
          </p>
          <CustomChip class="chip">
            {{ getChipValue(row[kpi.key], kpi.title) }}
          </CustomChip>
        </div>
      </div>
    </div>

    <div class="container footer">
      <!-- Promotion Material -->
      <CustomButton
        v-if="getPromoLink()"
        :label="t('See Promo material')"
        :href="getPromoLink()"
        target="_blank"
        purpose="text"
      />
      <CustomButton
        :small="true"
        @on-click="goToQuery(selectedQueryId)"
      >
        {{ t('View all customers in alert') }}
      </CustomButton>
    </div>
  </CustomModal>
</template>

<script setup>
import { onMounted, computed, ref, watchEffect } from 'vue';
import { t, gqlRequest, dates, abbr } from '@sales-i/utils';
import { CustomModal, CustomButton, BufferImage, CustomChip } from '@sales-i/dsv3';
import useCurrency from '@/shared/composables/useCurrency';
import { useStore } from 'vuex';
import { GET_BY_ID } from '@/shared/store/actionType';
import { parseQuery } from '@/intelligence/utils/parseGraphQl';
import { baseUrl, customersArea } from '@/crm/router/urlBits';
import {
  REPORTS_KPI_SALES_VALUE_TITLE,
  REPORTS_KPI_SALES_VALUE,
  REPORTS_KPI_INVOICES_TITLE,
  REPORTS_KPI_INVOICES,
  REPORTS_KPI_COST,
  REPORTS_KPI_COST_TITLE,
  REPORTS_KPI_PROFIT,
  REPORTS_KPI_PROFIT_TITLE,
  REPORTS_KPI_GROSS_PROFIT,
  REPORTS_KPI_COUNT,
  REPORTS_KPI_COUNT_TITLE,
  REPORTS_KPI_QUANTITY,
  REPORTS_KPI_QUANTITY_TITLE,
  REPORTS_KPI_AVERAGE_PRICE,
  REPORTS_KPI_GROSS_PROFIT_TITLE,
  REPORTS_KPI_INVOICE_ITEMS,
  REPORTS_KPI_INVOICE_ITEMS_TITLE
} from '@/intelligence/store/data/kpis';
import { alertsScope } from '@/intelligence/router/urlBits';
import FilterSummary from '@/intelligence/components/AlertBuilder/AlertWizard/FilterSummary.vue';
import { useAlertBuilder } from '@/intelligence/composables/useAlertBuilder'; 
import { navigateToUrl } from 'single-spa';

const store = useStore();

const {
  alertBuilderBaseUrl,
  addQueryArgsToStore,
  clearAllFilters,
} = useAlertBuilder( { store } );

const { cPrice, cNumber } = useCurrency();

const props = defineProps({
  selectedQueryId: {
    type: Number,
    default: 0,
  },
  customerDetails: {
    type: Object,
    default: () => ({}),
  },
  childrenId: {
    type: Array,
    default: () => []
  },
  hasChildAlerts: {
    type: Boolean,
    default: false
  },
  childrenNames : {
    type: Array,
    default: () => []
  },
  alertName: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['closeModal']);

const savedAlert = computed(() => store.state.intelligence.savedQuery.data);
const savedAlertErrors = computed(() => store.state.intelligence.savedQuery.errors);
const customerName = computed(() => props.customerDetails.name);
const rows = computed(() => data.value?.querybuilder.rows || []);

const query = ref('');
const data = ref(null);
const isLoading = ref(false);
const dateRangeTitle = ref('');

const getPromoLink = () => {
  const [, link] = props.alertName.split('|~~|');
  if (link !== undefined && link.length) {
    return link;
  }
  return false;
};

const getSavedAlert = (id) => store.dispatch(`intelligence/savedQuery/${GET_BY_ID}`, id);

const addCustomerNumbersToQuery = (query_arguments) => {
  let customerNos = [`${props.customerDetails?.id}`];
  if (props.childrenId?.length > 0 && props.hasChildAlerts) {
    customerNos = customerNos.concat(props.childrenId.map(id => id.toString()));
  }
  query_arguments = query_arguments.replace(/drills:/, `customer_numbers: ${JSON.stringify(customerNos)}, drills:`);
  return query_arguments;
};

const kpisTemplate = [
  {
    key: REPORTS_KPI_SALES_VALUE,
    title: REPORTS_KPI_SALES_VALUE_TITLE,
    prefix: true,
  },
  {
    key: REPORTS_KPI_COST,
    title: REPORTS_KPI_COST_TITLE,
    prefix: true,
  },
  {
    key: REPORTS_KPI_PROFIT,
    title: REPORTS_KPI_PROFIT_TITLE,
    prefix: true,
  },
  {
    key: REPORTS_KPI_GROSS_PROFIT,
    title: REPORTS_KPI_GROSS_PROFIT_TITLE,
    prefix: false,
    suffix: '%',
  },
  {
    key: REPORTS_KPI_AVERAGE_PRICE,
    title: 'AVG Price',
    prefix: true,
  },
  {
    key: REPORTS_KPI_QUANTITY,
    title: REPORTS_KPI_QUANTITY_TITLE,
    prefix: false,
  },
  {
    key: REPORTS_KPI_COUNT,
    title: REPORTS_KPI_COUNT_TITLE,
    prefix: false,
  },
  {
    key: REPORTS_KPI_INVOICES,
    title: REPORTS_KPI_INVOICES_TITLE,
    prefix: false,
  },
  {
    key: REPORTS_KPI_INVOICE_ITEMS,
    title: REPORTS_KPI_INVOICE_ITEMS_TITLE,
    prefix: false,
  }
];

const goToQuery = id => navigateToUrl(`${alertBuilderBaseUrl.value}/${alertsScope}/${id}`);

const determineDateRange = (startDate, endDate) => {
  const formattedStartDate = dates.formatWithOrdinals(startDate);
  const formattedEndDate = dates.formatWithOrdinals(endDate);
  return `${formattedStartDate} - ${formattedEndDate}`;
};

onMounted(async () => {
  clearAllFilters();

  isLoading.value = true;
  await getSavedAlert(props.selectedQueryId);

  if (savedAlertErrors.value.length > 0) {
    console.error('Error, no saved alerts');
    return;
  }
  const latestResults = savedAlert.value.results[savedAlert.value.results.length - 1];
  query.value = parseQuery(`{${latestResults.query_arguments.split('||||')[0]}}`);
  const args = addCustomerNumbersToQuery(latestResults.query_arguments.split('||||')[0]);
  
  data.value = await gqlRequest(`{ ${args} {${latestResults.query_fields} }}`);
  isLoading.value = false;

  const { from, to } = query.value.enquiry.date_range;
  dateRangeTitle.value = determineDateRange(from, to);
});

watchEffect(
  () => addQueryArgsToStore(),
);

function getLink(id) {
  return `${baseUrl}/${customersArea}/${id}`;
}
function getChipValue(value, heading) {
  switch (heading) {
  case REPORTS_KPI_GROSS_PROFIT_TITLE:
    return `${abbr.float(value)}%`;
  case REPORTS_KPI_QUANTITY_TITLE:
  case REPORTS_KPI_COUNT_TITLE:
  case REPORTS_KPI_INVOICES_TITLE:
  case REPORTS_KPI_INVOICE_ITEMS_TITLE:
    return `${cNumber(value, 0)}`;
  case 'AVG Price':
  case REPORTS_KPI_SALES_VALUE_TITLE:
  case REPORTS_KPI_COST_TITLE:
  case REPORTS_KPI_PROFIT_TITLE:
    return cPrice(value);
  default:
    return value;
  }
}
</script>

<style lang="scss" scoped>
.rectangle.container {
  background-color: var(--colour-panel-alert-base);
  padding: var(--spacing-2) var(--spacing-4);

  p {
    font-size: var(--font-size-small);
  }
}

.footer.container {
  background-color: var(--colour-panel-action);
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  padding: var(--spacing-2) 0;

  :deep(.text) {
    padding: var(--spacing-1) var(--spacing-2);
  }
}

.chip-title {
  font-size: var(--font-size-small);
  text-align: center;
}

.chip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: var(--spacing-3);

  .chip {
      margin: var(--spacing-1);
      text-align: center;
      box-shadow: 0 0 var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
      background: var(--colour-utility-white);
      height: var(--spacing-5);
      font-size: var(--font-size-5);
      white-space: nowrap;
      padding: var(--spacing-half) var(--spacing-2);
  }
}

.row-title {
  margin-left: var(--spacing-4);
}

.chips-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: var(--spacing-2);
}

.chips-container {
  margin-bottom: var(--spacing-3);

  > *:first-child {
    margin-top: var(--spacing-3);
  }
}

.title > * {
  margin-top: var(--spacing-half);
  margin-inline: var(--spacing-3);
}
</style>