import Enquiries from '@/admin/views/IntelligenceSetup/Enquiries.vue';
import CustomerView from '@/admin/views/IntelligenceSetup/CustomerView.vue';
import { enquiriesSection as section } from '@/admin/router/urlBits';

export default {
  path: section,
  component: Enquiries,
  children: [
    {
      path: '',
      component: CustomerView,
    },
  ]
};
