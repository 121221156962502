<template>
  <div class="report-view-toggler">
    <MenuFilter
      :items="INTERACTION_SPEND_SUMMARY_REPORTS"
      :selected-value="reportType || 'customer'"
      menu-position="right"
      @on-change="changeView"
    />
  </div>
</template>
<script>
import { MenuFilter } from '@sales-i/dsv3';
import { APPLY_REPORT_TYPE, FETCH_REPORT_DATA } from '@/intelligence/store/actionType';
import { mapActions, mapState } from 'vuex';
import { INTERACTION_SPEND_SUMMARY_REPORTS } from '@/intelligence/store/data/filters';
import { INTERACTION_SPEND_SUMMARY } from '@/intelligence/store/data/reportTypes';

export default {
  name: 'ToggleView',
  components: { MenuFilter },
  data() {
    return {
      INTERACTION_SPEND_SUMMARY_REPORTS,
    };
  },
  computed: {
    ...mapState({
      reportType: state => state.intelligence.performanceInsight.report_type,
    }),
  },
  methods: {
    ...mapActions({
      applyReportType: `intelligence/performanceInsight/${APPLY_REPORT_TYPE}`,
      fetchEnquiryData: `intelligence/shared/${FETCH_REPORT_DATA}`,
    }),
    async changeView(view) {
      this.applyReportType(view.value);
      await this.fetchEnquiryData({ reportType: INTERACTION_SPEND_SUMMARY });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.report-view-toggler {
  position: absolute;
  left: 70px;
  top: 12px;

  &:deep(.menu-filter .list) {
    top: auto;
    bottom: var(--spacing-6);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      top: var(--spacing-5);
      bottom: auto;
    }
  }

  &:deep(.filter-select__selection) {
    white-space: break-spaces;
    max-width: var(--spacing-8);

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      white-space: nowrap;
      max-width: none;
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: relative;
    left: auto;
    top: var(--spacing-1);
  }
}
</style>
