import {
  GET_BY_ID,
  GET_BY_ID_LOADING,
  GET_BY_ID_ERROR,
  CLEAR_BY_ID,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

const defaultOptions = {
  area: 'files',
  version: '2'
};

// mutations
export const getByIdMutations = {
  [GET_BY_ID]: (state, data) => {
    state.selected.data = data;
    state.selected.loaded = true;
    state.selected.loading = false;
    state.selected.downloading = false;
    state.selected.errors = [];
  },
  [GET_BY_ID_LOADING]: (state) => {
    state.selected.loaded = false;
    state.selected.loading = true;
    state.selected.downloading = false;
  },
  [GET_BY_ID_ERROR]: (state, errors) => {
    state.selected.errors = errors;
    state.selected.loaded = false;
    state.selected.loading = false;
    state.selected.downloading = false;
  },
  [CLEAR_BY_ID]: (state) => {
    state.selected.data = {};
    state.selected.loaded = false;
    state.selected.loading = false;
    state.selected.downloading = false;
    state.selected.errors = [];
  },
};

// actions
export const getByIdActions = {
  [GET_BY_ID]: async ({ commit, }, parameters = {}) => {
    try {
      let { id, options, entity_type, entity_id} = parameters;
      options = {...defaultOptions, ...options};
      commit(CLEAR_BY_ID);
      commit(GET_BY_ID_LOADING);
      let data = await httpRequest('get', `${entity_type}/${entity_id}/${id}`, {}, options);
      commit(GET_BY_ID, data);
      return data;
    } catch (error) {
      console.error(error);
      commit(GET_BY_ID_ERROR, error);
      return false;
    }
  },
  [CLEAR_BY_ID]: ({ commit, }) => {
    commit(CLEAR_BY_ID);
  },
};

