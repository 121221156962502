import {
  GET_ALL,
  GET_ALL_LOADING,
  GET_ALL_ERROR,
  CLEAR_VISIBLE,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import runIfPermittedOrReject from '@/shared/store/utils/runIfPermittedOrReject';
import querystring from 'querystring';

// mutations
export const getAllMutations = {
  [GET_ALL]: (state, data) => {
    state.all.data = data;
    state.all.loaded = true;
    state.all.loading = false;
  },
  [GET_ALL_LOADING]: (state) => {
    state.all.loaded = false;
    state.all.loading = true;
  },
  [GET_ALL_ERROR]: (state) => {
    state.all.loaded = false;
    state.all.loading = false;
    state.all.errors = [];
  },
};

// actions
// files use same dictionary as crm
export const getAllActions = {
  [GET_ALL]: ({ commit, state, rootState, dispatch, }, parameters) => {
    runIfPermittedOrReject(
      rootState.pbac.permissionsGranted,
      { name: state.permissionSet },
      async () => {
        try {
          commit(GET_ALL_LOADING);
          const params = querystring.stringify(parameters);
          const data = await httpRequest('get', `tags?${params}`, {}, { version: '2'});
          commit(GET_ALL, data);
        } catch (error) {
          console.error(error);
          commit(GET_ALL_ERROR);
        }
      },
      () => {
        dispatch(CLEAR_VISIBLE);
      }
    );
    
  },
};
