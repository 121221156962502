<template>
  <div>
    <CustomModal
      show-modal
      :max-width="750"
      @close-modal="closeModal"
    >
      <template #title>
        <h3 class="fw-sbld text-2">
          {{ t('Edit translations') }}
        </h3>
      </template>

      <main class="flow">
        <p class="text-base">
          {{
            t(`Edit translations for '${translations[0].name}' in`, 'edit_translations_for_translation_in', {
              interpolations: {
                translation: translations[0].name,
              },
            })
          }}
          <span
            v-for="(item, fieldIndex) in selectedLanguages"
            :key="fieldIndex"
          >
            {{ item.text }}
          </span>
        </p>

        <EditTranslationField
          v-for="(fieldToTranslate, fieldIndex) in translations"
          :key="fieldIndex"
          :languages="selectedLanguages"
          :value="fieldToTranslate"
        />
      </main>
      <template #footer>
        <CustomButton
          small
          @click="update"
        >
          {{ t('Update') }}
        </CustomButton>
      </template>
    </CustomModal>
  </div>
</template>
<script>
import { t } from '@sales-i/utils';
import { CustomModal, CustomButton } from '@sales-i/dsv3';
import EditTranslationField from '@/admin/components/Company/Languages/EditTranslationField.vue';
import { mapActions, mapGetters } from 'vuex';
import { GET_ALL } from '@/shared/store/actionType';

export default {
  name: 'EditTranslationModal',
  components: {
    CustomButton,
    EditTranslationField,
    CustomModal,
  },
  props: {},
  emits: ['closeModal'],
  data() {
    return {
      selectedLanguages: [],
    };
  },
  computed: {
    ...mapGetters({
      languageOptions: 'admin/languages/languageOptions',
    }),
    translations() {
      /** @todo remove dummy demo data */
      return [
        {
          name: 'field1',
          data: {
            'en-gb': 'Field one',
            'en-us': 'Field 1',
          },
        },
      ];
    },
  },
  async mounted() {
    await this.fetchLanguages();
    /** @todo remove dummy demo data */
    this.selectedLanguages = [this.languageOptions[0], this.languageOptions[1]];
    this.defaultLanguage = { ...this.languageOptions[0] };
  },
  methods: {
    t,
    ...mapActions({
      fetchLanguages: `admin/languages/${GET_ALL}`,
    }),
    closeModal() {
      this.$emit('closeModal');
    },
    update(value, index) {
      this.$emit('closeModal');
      this.translations[index] = value;
      console.error('NEEDS PLUMBING update translation', value, index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

main {
  padding-right: var(--spacing-3);
  padding-left: var(--spacing-3);
}
</style>
