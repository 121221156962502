import { DISMISS_OPPORTUNITY } from '@/intelligence/store/actionType';
import { httpRequest } from '@sales-i/utils';

function buildProductCodeList(products) {
  let productList = [];
  for (let n in products) {
    if (products[n] === true) {
      productList.push({
        product_code: n
      });
    }
  }

  return productList;
}

export const dismissActions = {
  // eslint-disable-next-line
  [DISMISS_OPPORTUNITY]: async ({ commit }, payload) => {
    try {
      // We need to corrale the object into something the BE can use
      let requestObject = {
        id: payload.opportunity.id,
        dismissed_for: payload.options.dismiss_for,
        dismissed_reason: payload.options.dismiss_reason,
        products: buildProductCodeList(payload.products),
      };

      await httpRequest('put', `opportunities/${payload.opportunity.id}/with-products`, requestObject, {
        version: 2
      });
      return Promise.resolve(true);
    } catch (e) {
      return Promise.reject(false);
    }
  },
};
