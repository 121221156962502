<template>
  <CustomCard
    purpose="reversed"
    :class="['details-container', { expanded: expanded }]"
    footer-color="grey"
  >
    <LoadingOverlay
      v-if="communities.loading"
      style="border-radius: var(--border-radius-1)"
    />
    <template #header>
      <div class="header">
        <h3 class="fw-sbld">
          {{ getCommunityName }}
        </h3>
        <div class="btn-container">
          <FullScreenButton
            id="community-details-expand-community-button"
            :visible-data-type="expandedDataView"
            @full-screen-data-type-change="switchDataView"
          />
          <IconButton
            v-if="hasAccess(product_communities_admin, 'update')"
            id="community-details-edit-community-button"
            :icon-width="32"
            :icon-height="32"
            :right="true"
            :width="180"
            purpose="reversed"
            icon-name="more"
            icon-color="black"
            @click="event => openModal(true)"
          />
        </div>
      </div>
    </template>

    <CommunityChart
      v-if="communities.loaded === true"
      :data-changed="hasNewCommunityData"
      :expanded="expanded"
    />

    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th v-if="getProductHubs.length">
              {{ t('Product Hub') }}
            </th>
            <th v-if="getProductInfluencers.length">
              {{ t('Product Influencer') }}
            </th>
            <th v-if="getAssociatedProducts.length">
              {{ t('Associated Products') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(listItem, index) in getLargestList()"
            :key="listItem.code"
          >
            <td
              v-if="getProductHubs.length"
              :class="{ selectable: manageProducts === true }"
            >
              <div>
                <CustomCheckbox
                  v-if="manageProducts === true && getHubAtIndex(index) !== null"
                  @input="handleProductCheckbox({ product_code: getHubAtIndex(index, 'product_code') }, $event)"
                />
                <span>
                  {{ getHubAtIndex(index) }}
                </span>
              </div>
            </td>
            <td
              v-if="getProductInfluencers.length"
              :class="{ selectable: manageProducts === true }"
            >
              <div>
                <CustomCheckbox
                  v-if="manageProducts === true && getInfluencerAtIndex(index) !== null"
                  @input="handleProductCheckbox({ product_code: getInfluencerAtIndex(index, 'product_code') }, $event)"
                />
                <span>
                  {{ getInfluencerAtIndex(index) }}
                </span>
              </div>
            </td>
            <td
              v-if="getAssociatedProducts.length"
              :class="{ selectable: manageProducts === true }"
            >
              <div>
                <CustomCheckbox
                  v-if="manageProducts === true && getAssociationAtIndex(index) !== null"
                  @input="handleProductCheckbox({ product_code: getAssociationAtIndex(index, 'code') }, $event)"
                />
                <span>
                  {{ getAssociationAtIndex(index) }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      id="communities-management-toggle-switch"
      class="switch-wrapper"
    >
      <Field
        type="switch"
        :label="t('Select Individual Products')"
        :value="manageProducts"
        twoway
        @input="handleManageSwitch"
      />
    </div>

    <template #footer>
      <ButtonGroup>
        <CustomButton
          v-if="hasAccess(product_communities_admin, 'delete')"
          small
          class="dismiss-button"
          @on-click="() => dismissCommunity()"
        >
          {{ t('Dismiss') }}
        </CustomButton>

        <CustomButton
          v-if="hasAccess(product_communities_admin, 'update')"
          class="add"
          icon-only
          icon-name="plus"
          icon-color="var(--colour-utility-white)"
          :icon-width="32"
          :icon-height="32"
          @on-click.stop="openAddProductModal"
        />
      </ButtonGroup>
    </template>

    <CustomModal
      :title="t('Add Product to Community')"
      :show-modal="addProductModalOpen"
      @close-modal="closeAddProductModal"
    >
      <div class="forms-wrapper">
        <form :class="['form-grid', { fullWidth: item.hasAssociation === false }]">
          <h4 :class="{ enabled: item.hasAssociation }">
            {{ t('A customer purchases...') }}
          </h4>
          <div class="row product-search">
            <Field
              v-bind="addFields.product_code"
              :value="item.productCode || ''"
              @valid="validField"
              @input="onFieldInput($event, 'productCode')"
            />

            <div class="product-search-select">
              <SearchSelect
                tabindex="0"
                :items="filters"
                :selected-value="selectedAddProductFilter"
                @on-change="filterAddSelected"
              />
            </div>

            <div
              v-if="currentResultTarget === 'antecedent' && productSearchResults.length > 0"
              class="row product-search-results"
            >
              <CustomButton
                v-for="product in productSearchResults"
                :key="product.product_code"
                purpose="text"
                @on-click="selectAddProduct(product)"
              >
                {{ product.product_code }} - {{ product.product_name }}
              </CustomButton>
            </div>
          </div>
          <div class="row">
            <Field
              v-bind="addFields.product_name"
              :value="item.productName || ''"
              @valid="validField"
              @input="onFieldInput($event, 'productName')"
            />
          </div>
          <div
            v-if="mode !== 'add'"
            class="row"
          >
            <Field
              v-bind="addFields.has_association"
              :value="item.hasAssociation"
              @valid="validField"
              @input="onFieldInput($event, 'hasAssociation')"
            />
          </div>
        </form>
        <div :class="['row', 'arrow', { enabled: item.hasAssociation }]">
          <div class="link">
            <div />
            <div />
            <div />
          </div>
        </div>
        <form :class="['form-grid', 'association-details', { enabled: item.hasAssociation }]">
          <h4 class="enabled">
            {{ t('That customer also purchases...') }}
          </h4>
          <div class="row product-search">
            <Field
              v-bind="addFields.connected_to"
              :value="item.connectedTo"
              @valid="validField"
              @input="onFieldInput($event, 'connectedTo')"
            />

            <div class="product-search-select">
              <SearchSelect
                ref="select"
                tabindex="0"
                :items="filters"
                :selected-value="selectedLinkedProductFilter"
                @on-change="filterLinkedSelected"
              />
            </div>

            <div
              v-if="currentResultTarget === 'consequent' && productSearchResults.length > 0"
              class="row product-search-results"
            >
              <CustomButton
                v-for="product in productSearchResults"
                :key="product.product_code"
                purpose="text"
                @on-click="selectProduct(product)"
              >
                {{ product.product_code }} - {{ product.product_name }}
              </CustomButton>
            </div>
          </div>
          <div class="row">
            <Field
              v-bind="addFields.connected_to_name"
              :value="item.connectedToName"
              @valid="validField"
              @input="onFieldInput($event, 'connectedToName')"
            />
          </div>
          <div class="row">
            <Field
              v-bind="addFields.strength"
              :value="item.strength"
              @valid="validField"
              @input="onFieldInput($event, 'strength')"
            />
          </div>
        </form>
      </div>

      <template #footer>
        <ButtonGroup>
          <CustomButton
            :disabled="validSet.size !== 0"
            @click="() => (mode === 'add' ? addNewCommunity() : addItem())"
          >
            {{ t('Add') }}
          </CustomButton>
        </ButtonGroup>
      </template>
    </CustomModal>

    <CustomModal
      :title="mode === 'add' ? t('Add Community') : t('Editing Community')"
      :show-modal="modalOpen"
      @close-modal="closeModal"
    >
      <form class="form-grid">
        <div class="row">
          <Field
            v-bind="editFields.community_name"
            :value="item.communityName"
            @valid="validField"
            @input="onFieldInput($event, 'communityName')"
          />
        </div>
      </form>

      <template #footer>
        <ButtonGroup>
          <CustomButton
            :disabled="validSet.size !== 0"
            @click="() => (mode === 'add' ? addCommunity() : updateCommunity())"
          >
            {{ mode === 'add' ? t('Add') : t('Update') }}
          </CustomButton>
        </ButtonGroup>
      </template>
    </CustomModal>
  </CustomCard>
</template>

<script type="text/javascript">
import { GET_CRM_PRODUCTS, CLEAR_CRM_PRODUCTS } from '@/admin/store/actionType';
import { GET_BY_ID, PUT_BY_ID, SET_ITEMS, POST_BY_ENTITY, PUSH_ROUTE } from '@/shared/store/actionType';
import { mapActions, mapState } from 'vuex';
import store from '@/shared/store';
import { CustomCheckbox, CustomCard, LoadingOverlay, CustomModal, CustomButton, IconButton } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field.vue';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import SearchSelect from '@/shared/components/Form/SearchSelect.vue';
import CommunityChart from '@/admin/views/Communities/CommunityChart.vue';
import FullScreenButton from '@/intelligence/components/Shared/FullScreenButton';
import { baseUrl, intelligenceArea, communitiesSection } from '@/admin/router/urlBits';
import usePermissions from '@/shared/composables/usePermissions';
import { product_communities_admin } from '@/shared/store/data/policies';
import { t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

const errors = {
  community_code: val => !val,
  community_name: val => !val,
  product_code: val => !val,
  product_name: val => !val,
  connected_to: val => !val,
  connected_to_name: val => !val,
  has_association: () => false,
  strength: val => !(val >= 0 && val <= 100),
};

export default {
  name: 'CommunityDetails',
  components: {
    CommunityChart,
    CustomCheckbox,
    CustomButton,
    ButtonGroup,
    CustomCard,
    Field,
    LoadingOverlay,
    CustomModal,
    SearchSelect,
    FullScreenButton,
    IconButton,
  },
  props: {
    dataChanged: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['communityUpdated', 'dismissCommunity'],
  data() {
    return {
      product_communities_admin,
      // dimensions and margins of the graph
      hasNewCommunityData: false,
      margin: {
        top: 0,
        right: 10,
        bottom: 100,
        left: 30,
      },
      iconColour: '#7360A4',
      modalOpen: false,
      addProductModalOpen: false,
      validSet: new Set(),
      filters: [
        {
          text: 'In Community',
          value: 'in_community',
          path: 'in_community',
        },
        {
          text: 'All Products',
          value: 'all_products',
          path: 'all_products',
        },
      ],
      selectedAddProductFilter: 'all_products',
      selectedLinkedProductFilter: 'all_products',
      communityUpdates: {
        communityCode: '',
        communityName: '',
        productCode: '',
        productName: '',
        connectedTo: '',
        connectedToName: '',
        hasAssociation: false,
        strength: 0,
      },
      searchTimeout: null,
      productSearchResults: [],
      currentResultTarget: 'antecedent',
      mode: 'edit',
      manageProducts: false,
      selectedProducts: {},
    };
  },
  computed: {
    icon() {
      return this.expanded ? 'close-line' : 'carets';
    },
    item: {
      get() {
        return {
          ...this.communityUpdates,
        };
      },
      set(value) {
        this.communityUpdates = value;
      },
    },
    ...mapState({
      communities: state => state.admin.communities,
      crmProducts: state => state.admin.associations.crmProducts,
      expanded: state => state.intelligence.shared.isReportExpanded,
    }),
    hasAccess() {
      const { hasAccess } = usePermissions();
      return hasAccess;
    },
    getCommunityName() {
      if (this.communities.currentlyLoaded.length === 0) {
        return '';
      }

      return this.communities.currentlyLoaded[0].community_name;
    },
    addFields() {
      return {
        product_code: {
          id: 'product_code',
          type: 'text',
          label: t('Product Code'),
          placeholder: `${t('Start typing to search')}`,
          error: errors.product_code(this.item.productCode),
          errorMessage: t('Product Code cannot be blank', 'label_cannot_be_blank', {
            interpolations: {
              label: t('Product Code'),
            },
          }),
          required: true,
          twoway: true,
        },
        product_name: {
          id: 'product_name',
          type: 'text',
          label: t('Product Name'),
          placeholder: `${t('Product Name')}...`,
          error: errors.product_name(this.item.productName),
          errorMessage: t('Product Name cannot be blank', 'label_cannot_be_blank', {
            interpolations: {
              label: t('Product Name'),
            },
          }),
          required: true,
          twoway: true,
        },
        has_association: {
          id: 'has_association',
          type: 'checkbox',
          label: t('Influences the sale of...'),
        },
        connected_to: {
          id: 'connected_to',
          type: 'text',
          label: t('Influenced Product Code'),
          placeholder: `${t('Start typing to search')}`,
          error: errors.connected_to(this.item.connectedTo),
          errorMessage: t('Connected To cannot be blank', 'label_cannot_be_blank', {
            interpolations: {
              label: t('Connected To'),
            },
          }),
          required: false,
          twoway: true,
        },
        connected_to_name: {
          id: 'connected_to_name',
          type: 'text',
          label: t('Influenced Product Name'),
          placeholder: `${t('Connected To Name')}...`,
          error: errors.connected_to_name(this.item.connectedToName),
          errorMessage: t('Connected To cannot be blank', 'label_cannot_be_blank', {
            interpolations: {
              label: t('Connected To'),
            },
          }),
          required: false,
          twoway: true,
        },
        strength: {
          id: 'strength',
          type: 'number',
          label: t('% probability'),
          placeholder: `${t('Association Confidence')}...`,
          error: errors.strength(this.item.strength),
          errorMessage: t('Association Confidence must be between 0 and 100', 'label_must_be_between_lowval_and_highval', {
            interpolations: {
              label: t('Association Confidence'),
              lowval: 0,
              highval: 0,
            },
          }),
          required: false,
          min: 0,
          max: 100,
          twoway: true,
        },
      };
    },
    editFields() {
      return {
        community_name: {
          id: 'community_name',
          type: 'text',
          label: t('Community Name'),
          placeholder: `${t('Community Name')}...`,
          error: errors.community_name(this.item.communityName),
          errorMessage: t('Community Name cannot be blank', 'label_cannot_be_blank', {
            interpolations: {
              label: t('Community Name'),
            },
          }),
          required: true,
          twoway: true,
        },
      };
    },
    getProductHubs() {
      let products = this.communities.currentlyLoaded.filter(o => o.is_hub === true);

      if (products.length === 0) {
        return products;
      }

      products = this.deDupe(products);

      return products;
    },
    getProductInfluencers() {
      let products = this.communities.currentlyLoaded.filter(o => o.is_influencer === true);

      if (products.length === 0) {
        return products;
      }

      products = this.deDupe(products);

      return products;
    },
    getAssociatedProducts() {
      const products = [];

      for (let x = 0; x < this.communities.currentlyLoaded.length; x++) {
        const product = this.communities.currentlyLoaded[x];

        const found = products.find(o => {
          return o.code === product.product_code;
        });

        if (found !== undefined) {
          continue;
        }

        if (product.is_hub === false && product.is_influencer === false) {
          products.push({
            hub: product.is_hub,
            influencer: product.is_influencer,
            code: product.product_code,
            name: product.product_name,
            target: product.connected_to,
            strength: product.strength,
          });
        }
      }

      return products;
    },
  },
  watch: {
    dataChanged() {
      if (this.dataChanged === true) {
        const { community_code } = this.$route.params;

        this.manageProducts = false;
        this.selectedProducts = {};

        this.loadCommunity(community_code);
      }
    },
    expanded() {},
    item(val, oldVal) {
      if (val.productCode !== oldVal.productCode) {
        this.currentResultTarget = 'antecedent';
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(this.searchAddProduct, 500);
      }
      if (val.connectedTo !== oldVal.connectedTo) {
        this.currentResultTarget = 'consequent';
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(this.searchLinkedProduct, 500);
      }
    },
    addProductModalOpen() {
      if (this.addProductModalOpen === false) {
        this.onFieldInput('', 'productCode');
        this.onFieldInput('', 'productName');
        this.onFieldInput('', 'connectedTo');
        this.onFieldInput(false, 'hasAssociation');
        this.onFieldInput(0, 'strength');
      }
    },
  },
  mounted() {
    const { community_code } = this.$route.params;

    if (community_code === undefined) {
      this.mode = 'add';
      this.modalOpen = true;
      this.selectedAddProductFilter = 'all_products';
    } else {
      this.loadCommunity(community_code);
    }
  },
  methods: {
    t,
    ...mapActions({
      getCommunity: `admin/communities/${GET_BY_ID}`,
      putCommunity: `admin/communities/${PUT_BY_ID}`,
      addItemToCommunity: `admin/communities/${POST_BY_ENTITY}`,
      getProducts: `admin/associations/${GET_CRM_PRODUCTS}`,
      clearCrmProducts: `admin/associations/${CLEAR_CRM_PRODUCTS}`,
      pushRoute: `system/${PUSH_ROUTE}`,
    }),
    handleManageSwitch($event) {
      if ($event === false) {
        this.selectedProducts = {};
      }
      this.manageProducts = $event;
    },
    handleProductCheckbox(product, $event) {
      this.selectedProducts[product.product_code] = $event;
    },
    filterAddSelected(filter) {
      const { value } = filter;
      this.selectedAddProductFilter = value;
    },
    filterLinkedSelected(filter) {
      const { value } = filter;
      this.selectedLinkedProductFilter = value;
    },
    searchAddProduct() {
      switch (this.selectedAddProductFilter) {
      case 'in_community':
        this.searchLocalProducts(this.item.productCode);
        break;
      default:
        this.searchRemoteProducts(this.item.productCode);
        break;
      }
    },
    searchLinkedProduct() {
      switch (this.selectedLinkedProductFilter) {
      case 'in_community':
        this.searchLocalProducts(this.item.connectedTo);
        break;
      default:
        this.searchRemoteProducts(this.item.connectedTo);
        break;
      }
    },
    searchLocalProducts(value) {
      const reg = new RegExp(value, 'gi');
      const foundProducts = this.deDupe(
        this.communities.currentlyLoaded.filter(o => {
          return o.product_name.search(reg) !== -1 || o.product_code.search(reg) !== -1;
        })
      );

      this.productSearchResults = foundProducts;
    },
    async searchRemoteProducts(value) {
      if (value === '') {
        this.clearCrmProducts();
        return;
      }

      await this.getProducts({
        q: value,
      });

      this.productSearchResults = this.crmProducts.data;
    },
    addCommunity() {
      this.modalOpen = false;
      this.addProductModalOpen = true;
    },
    async updateCommunity() {
      const originalCommunityCode = this.communities.currentlyLoaded[0].community_code;

      try {
        await this.putCommunity({
          id: originalCommunityCode,
          details: {
            community_code: this.item.communityCode,
            community_name: this.item.communityName,
          },
        });

        if (this.communities.error !== null) {
          throw new Error('Error updating community');
        }

        this.closeModal();

        store.dispatch(`alerts/${SET_ITEMS}`, {
          type: 'success',
          message: t(`Successfully updated ${this.item.communityName}`, 'successfully_updated_variable', {
            interpolations: {
              variable: this.item.communityName,
            },
          }),
          selfDismiss: true,
        });

        this.$emit('communityUpdated');
        navigateToUrl.push(`${baseUrl}/${intelligenceArea}/${communitiesSection}/${this.item.communityCode}`);
      } catch (e) {
        store.dispatch(`alerts/${SET_ITEMS}`, {
          type: 'error',
          message: t(`An error occurred updating ${this.item.communityName}`, 'an_error_occurred_updating_variable', {
            interpolations: {
              variable: this.item.communityName,
            },
          }),
          selfDismiss: true,
        });
      }
    },
    async loadCommunity(community_code) {
      await this.getCommunity(community_code);

      this.communityUpdates.communityName = this.communities.currentlyLoaded[0].community_name;
      this.communityUpdates.communityCode = this.communities.currentlyLoaded[0].community_code;

      this.hasNewCommunityData = true;
    },
    getLargestList() {
      const hubs = this.getProductHubs.length;
      const influencers = this.getProductInfluencers.length;
      const associated = this.getAssociatedProducts.length;

      if (hubs > influencers && hubs > associated) {
        return hubs;
      }

      if (influencers > associated) {
        return influencers;
      }

      return associated;
    },
    getHubAtIndex(index, key = undefined) {
      const hubs = this.getProductHubs;

      if (hubs[index] !== undefined) {
        const hub = hubs[index];
        if (key === undefined) {
          return `(${hub.product_code}) - ${hub.product_name}`;
        }
        return hub[key];
      }

      return null;
    },
    getInfluencerAtIndex(index, key = undefined) {
      const influencers = this.getProductInfluencers;

      if (influencers[index] !== undefined) {
        const influencer = influencers[index];
        if (key === undefined) {
          return `(${influencer.product_code}) - ${influencer.product_name}`;
        }
        return influencer[key];
      }

      return null;
    },
    getAssociationAtIndex(index, key = undefined) {
      const products = this.getAssociatedProducts;

      if (products[index] !== undefined) {
        const product = products[index];
        if (key === undefined) {
          return `(${product.code}) - ${product.name}`;
        }
        return product[key];
      }

      return null;
    },
    openCommunity() {
      const {
        params: { community_code },
      } = this.$route;
      let extraParams = '';
      if (Object.keys(this.selectedProducts).length > 0) {
        const paramsArray = [];
        for (let n in this.selectedProducts) {
          const _ref = this.selectedProducts[n];
          if (_ref === true) {
            paramsArray.push(n);
          }
        }

        if (paramsArray.length > 0) {
          extraParams = `?products=${encodeURIComponent(JSON.stringify(paramsArray))}`;
        }
      }
      navigateToUrl(`/${intelligenceArea}/${communitiesSection}/${community_code}${extraParams}`);
    },
    dismissCommunity() {
      let products = [];
      if (Object.keys(this.selectedProducts).length > 0) {
        for (let n in this.selectedProducts) {
          const _ref = this.selectedProducts[n];
          if (_ref === true) {
            products.push(n);
          }
        }
      }
      this.$emit('dismissCommunity', this.communities.currentlyLoaded, products);
    },
    deDupe(products) {
      if (products.length === 1) {
        return products;
      }
      return products.reduce((unique, item) => {
        if (unique.constructor === Object) {
          return [unique];
        }
        const found = unique.find(o => o.product_code === item.product_code);
        if (found === undefined) {
          return [...unique, item];
        } else {
          return unique;
        }
      });
    },
    openModal() {
      this.modalOpen = true;
    },
    openAddProductModal() {
      this.addProductModalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;

      if (this.mode === 'add') {
        // redirect the user back to the community list or previously viewed community
        this.pushRoute({
          defaultUrl: `${baseUrl}/${intelligenceArea}/${communitiesSection}`,
          cancel: true,
        });
      }
    },
    closeAddProductModal() {
      this.addProductModalOpen = false;
      if (this.mode === 'add') {
        // redirect the user back to the community list or previously viewed community
        this.pushRoute({
          defaultUrl: `${baseUrl}/${intelligenceArea}/${communitiesSection}`,
          cancel: true,
        });
      }
    },
    switchDataView() {
      this.expanded = !this.expanded;
    },
    validField(checkResult) {
      let error = errors[checkResult.id](checkResult.value);
      if (error) {
        this.validSet.add(checkResult.id || 'unknown');
      } else this.validSet.delete(checkResult.id || 'unknown');
    },
    onFieldInput(value, fieldName) {
      if (fieldName === 'hasAssociation') {
        if (!value) {
          /* if hasAssociation is false, reset values */
          ['connected_to', 'connected_to_name', 'strength'].forEach(item => this.validSet.delete(item || 'unknown'));
          ['connectedTo', 'connectedToName'].forEach(item => (this.communityUpdates[item] = ''));
          this.communityUpdates.strength = 0;
        }
      }
      this.item = {
        ...this.item,
        ...{ [fieldName]: value },
      };
    },
    selectAddProduct(product) {
      this.onFieldInput(product.product_code, 'productCode');
      this.onFieldInput(product.product_name, 'productName');

      this.productSearchResults = [];
    },
    selectProduct(product) {
      this.onFieldInput(product.product_code, 'connectedTo');
      this.onFieldInput(product.product_name, 'connectedToName');

      this.productSearchResults = [];
    },
    async addNewCommunity() {
      if (!this.communityUpdates.productCode || !this.communityUpdates.productName) return;
      await this.addItemToCommunity({
        details: [
          {
            product_id: this.communityUpdates.productCode,
            product_code: this.communityUpdates.productCode,
            product_name: this.communityUpdates.productName,
            community_name: this.communityUpdates.communityName,
            connected_to: this.communityUpdates.connectedTo,
            connected_to_product_name: this.communityUpdates.connectedToName,
          },
        ],
      });

      if (this.communities.error === null) {
        this.$emit('communityUpdated');

        store.dispatch(`alerts/${SET_ITEMS}`, {
          type: 'success',
          message: t(`Successfully added product to ${this.communityUpdates.communityCode}`, 'successfully_added_product_to_variable', {
            interpolations: {
              variable: this.communityUpdates.communityCode,
            },
          }),
          selfDismiss: true,
        });

        this.closeAddProductModal();
        navigateToUrl(`${baseUrl}/${intelligenceArea}/${communitiesSection}/${this.communityUpdates.communityCode}`);
      }
    },
    async addItem() {
      if (!this.communityUpdates.productCode || !this.communityUpdates.productName) return;
      const communityCode = this.communities.currentlyLoaded[0].community_code;
      const communityName = this.communities.currentlyLoaded[0].community_name;
      await this.addItemToCommunity({
        id: communityCode,
        details: [
          {
            product_id: this.communityUpdates.productCode,
            product_code: this.communityUpdates.productCode,
            product_name: this.communityUpdates.productName,
            strength: parseInt(this.communityUpdates.strength, 10) / 100,
            community_code: communityCode,
            community_name: communityName,
            connected_to: this.communityUpdates.connectedTo,
            connected_to_product_name: this.communityUpdates.connectedToName,
          },
        ],
      });

      if (this.communities.error === null) {
        this.$emit('communityUpdated');

        store.dispatch(`alerts/${SET_ITEMS}`, {
          type: 'success',
          message: t(`Successfully added product to ${communityCode}`, 'successfully_added_product_to_variable', {
            interpolations: {
              variable: communityCode,
            },
          }),
          selfDismiss: true,
        });

        this.closeAddProductModal();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.switch-wrapper {
  text-align: right;
}

.product-search {
  position: relative;

  .product-search-select {
    position: absolute;
    top: 34px;
    right: 3px;
  }

  .product-search-results {
    position: absolute;
    top: 85px;
    margin: 0 4px;
    background: var(--colour-panel-action);
    z-index: 1;

    button {
      padding: 10px;
    }
  }
}

.details-container {
  max-width: 900px;

  &.expanded {
    position: fixed;
    overflow-y: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: white;
    max-width: 100%;
    height: 100%;
  }
}

.btn-container {
  display: flex;
  flex-direction: row;
  margin-bottom: var(--spacing-half);
}

.expand-btn {
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--colour-utility-action);
  border-radius: 50%;
  margin-right: var(--spacing-1);

  &:hover,
  &:focus,
  &:focus-within {
    background: var(--colour-utility-focus);
  }

  .expand-button {
    transform: rotate(45deg);
    display: flex;
    justify-content: center;
    padding: 0;
    box-shadow: none;
    background: transparent;

    &.no-rotate {
      transform: none;
    }
  }
}

.add {
  right: 20px;
  position: absolute;
  top: -30px;
}

.arrow {
  display: flex;
}

.header {
  position: relative;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;

  h3 {
    word-break: break-all;
  }

  .manage-button {
    border-radius: 100px;
    padding: 2px;
    background-color: var(--colour-panel-action);
  }
}

.table-wrapper {
  background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0.2)), linear-gradient(to right, rgba(255, 255, 255, 0.2), #fff 100%) 0 100%,
    linear-gradient(to right, rgba(0, 0, 0, 0.2), transparent), linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2)) 0 100%;

  background-repeat: no-repeat;
  background-color: var(--colour-utility-white);
  background-size: 250px 100%, 250px 100%, 16px 100%, 16px 100%;
  background-position: 0 0, 100%, 0 0, 100%;
  background-attachment: local, local, scroll, scroll;
  overflow: auto;
  transition: all 0.2s;
  position: relative;
  // scroll-snap-type: x mandatory;
}

table {
  position: relative;
  width: 100%;

  th,
  td {
    font-size: inherit;
  }
}

tbody tr:first-child td {
  padding-top: var(--spacing-4);
}

tr {
  &:nth-child(even) {
    // I have to use a version of the colour-panel-g-2 variable with opacity to allow for the gradient at the end of a scrollable table to show through.
    background: rgba(210, 210, 210, 0.1);
  }
}

th {
  text-align: center;
}

th,
td {
  color: var(--colour-utility-black);
  padding: var(--spacing-2);
  border: none;
}

th {
  background: var(--colour-panel-action);
  color: var(--colour-utility-action);
  padding: var(--spacing-3) var(--spacing-2);
  vertical-align: middle;
  text-decoration: underline;
  cursor: pointer;

  &.contains-chip-header {
    padding-bottom: var(--spacing-3);
  }

  &.sorting-header {
    background: var(--colour-panel-g-2);
    color: var(--colour-utility-black);
    font-weight: var(--font-weight-medium);
    text-decoration: none;
  }
}

td {
  padding: var(--spacing-2) var(--spacing-2);
  line-height: var(--spacing-2);

  &.selectable {
    div {
      display: flex;
      justify-content: left;
      align-items: center;

      span {
        margin: 0 0 var(--spacing-1) var(--spacing-2);
      }
    }
  }

  &.first-column {
    background: rgba(210, 210, 210, 0.1);
    border-right: 1px solid var(--colour-panel-g-8);
    text-align: left;
  }
}

.sort-icon {
  display: inline-block;
  position: absolute;
}

form.fullWidth {
  width: 100%;
}

form.form-grid {
  flex-grow: 1;

  h4 {
    display: none;
    margin: var(--spacing-1) var(--spacing-2);

    &.enabled {
      display: block;
    }
  }
}

.forms-wrapper {
  display: flex;
  flex-direction: row;
}

.row {
  &.arrow {
    display: none;
  }

  &.enabled {
    display: flex;
  }

  .link {
    width: 100%;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    height: 36px;
    div:first-child,
    div:last-child {
      height: 9px;
      width: 9px;
      border-radius: 50%;
      background-color: var(--colour-panel-g-16);
    }
    div:nth-child(2) {
      height: 2px;
      width: 26px;
      background-color: var(--colour-panel-g-16);
    }
  }

  .field {
    width: 100%;
  }
}

.association-details {
  display: none;

  &.enabled {
    display: block;
  }
}
</style>

<style lang="scss">
#communities-management-toggle-switch {
  .row {
    justify-content: flex-end;
    flex-direction: row;

    .col {
      flex: 0;
    }
    .col:first-child {
      flex: 1;
      margin-right: var(--spacing-2);
    }
  }

  .toggle-switch {
    height: 24px;
    width: 58px;

    span.slider.round:before {
      width: 16px;
      height: 16px;
      left: 5px;
      // bottom: 3px;
    }
  }
}
</style>

<style lang="scss">
#community-details-edit-community-button {
  .button-container {
    background-color: var(--colour-panel-action);
    border-radius: 100px;

    &:focus,
    &:hover {
      background: var(--colour-utility-focus) !important;

      .svg-container svg {
        fill: var(--colour-utility-white);
      }
    }
  }
}

#community-details-expand-community-button {
  position: static;
}
</style>
