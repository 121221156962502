<template>
  <div
    :id="id"
    class="signpost-wrapper"
    :class="classes"
    :style="{ backgroundColor }"
  >
    <div class="container container--narrow px-2">
      <header :style="{ color }">
        <h2 ref="pageTitle">
          {{ heading }}
        </h2>
        <p>{{ description }}</p>
      </header>
      <BufferImage
        v-if="dataLoading && isHomePage"
        color="var(--colour-utility-black)"
        float="center"
      />
      <div
        v-else
        class="o-grid"
      >
        <template
          v-for="item in links"
          :key="item.title"
        >
          <SignPostCards
            :title="item.title"
            :description="item.description"
            :section-links="item.sectionLinks"
            @open="goToItem(item)"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, } from 'vue-router';
import SignPostCards from './SignPostCards.vue';
import { BufferImage } from '@sales-i/dsv3';
import { navigateToUrl } from 'single-spa';

// Variables
const store = useStore();
const vroute = useRoute();

const props = defineProps({
  heading: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  links: {
    type: Array,
    default: () => [],
  },
  color: {
    type: String,
    default: '',
  },
  backgroundColor: {
    type: String,
    default: '',
  }
});

const dataLoading = computed(() => store.state.intelligence.shared.loading);

const isHomePage = computed(() => vroute.path === '/');
const classes = computed(() => `insights-wrapper--${props.section}`);
const id = computed(() => props.section);

function goToItem(item) {
  navigateToUrl(item.href);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.signpost-wrapper {
  padding: var(--spacing-2);
}

.o-grid {
  display: grid;
  gap: var(--spacing-3);

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(260px, 300px));
  }
}

header {
  display: grid;
  place-content: center;
  justify-items: center;
  text-align: center;
  padding-bottom: var(--spacing-4);

  :deep(svg) {
    height: 80px;
    width: 80px;
  }

  h2 {
    font-size: var(--font-size-1);
    font-weight: var(--font-weight-semibold);
    line-height: 1.2;
  }
}
</style>
