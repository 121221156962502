import {
  PUT_BY_ID,
  PUT_BY_ID_LOADING,
  PUT_BY_ID_ERROR
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import mapInteractionPayload from '@/crm/store/utils/mapInteractionPayload';

// mutations
export const putByIdMutations = {
  [PUT_BY_ID]: (state, data) => {
    state.selected.data = data;
    state.selected.updated = true;
    state.selected.loading = false;
  },
  [PUT_BY_ID_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.updated = false;
  },
  [PUT_BY_ID_ERROR]: (state) => {
    state.selected.updated = false;
    state.selected.loading = false;
  },
};

// actions
export const putByIdActions = {
  [PUT_BY_ID]: async ({ commit, state }, payload) => {
    commit(PUT_BY_ID_LOADING);
    const { id, data, isPersonal } = payload;
    let interactionToPost = mapInteractionPayload(state.currentlyEditing, id, isPersonal, data);
    try {
      const data = await httpRequest('put', `interactions/${id}`, interactionToPost, { version: '2', });
      commit(PUT_BY_ID, data);
      return true;
    } catch (error) {
      commit(PUT_BY_ID_ERROR);
      return false;
    }
  },
};
