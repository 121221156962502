<template>
  <div class="carousel">
    <CustomButton
      class="carousel-prev"
      icon-name="chevron-left"
      purpose="reversed"
      icon-only
      round
      :icon-size="32"
      icon-color="var(--colour-data-deluge)"
      @click="prevSlide"
    />
    <CustomButton
      class="carousel-next"
      icon-name="chevron-right"
      purpose="reversed"
      variant="round"
      icon-only
      round
      :icon-size="32"
      icon-color="var(--colour-data-deluge)"
      @click="nextSlide"
    />

    <div
      ref="slides"
      class="carousel-slides"
    >
      <slot />
    </div>
    
    <CarouselDots
      v-if="slidesAmount > 1 && showDots"
      class="carousel-dots"
      :slides-amount="slidesAmount"
      :active-slide-index="activeSlideIndex"
      @update:active-slide-index="updateActiveSlideIndex"
    />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { CustomButton } from '@sales-i/dsv3';
import CarouselDots from '@/shared/components/Carousel/CarouselDots.vue';

const props = defineProps({
  slidesAmount: {
    type: Number,
    default: 0,
  },
  showDots: {
    type: Boolean,
    default: false,
  },
  activeSlideIndex: {
    type: Number,
    default: 0,
  }
});

const emit = defineEmits(['update:active-slide-index']);

const activeSlideIndex = ref(props.activeSlideIndex);

watch(activeSlideIndex, (newIndex) => {
  emit('update:active-slide-index', newIndex);
});

const nextSlide = () => {
  if (activeSlideIndex.value + 1 === props.slidesAmount) {
    activeSlideIndex.value = 0;
  } else {
    activeSlideIndex.value++;
  }
  emit('update:active-slide-index', activeSlideIndex.value);
};

const prevSlide = () => {
  if (activeSlideIndex.value === 0) {
    activeSlideIndex.value = props.slidesAmount - 1;
  } else {
    activeSlideIndex.value--;
  }
  emit('update:active-slide-index', activeSlideIndex.value);
};

const updateActiveSlideIndex = (newIndex) => {
  activeSlideIndex.value = newIndex;
};
</script>

<style lang="scss">
@import '@/shared/assets/scss/_variables';

.carousel {
  position: relative;
  background: transparent;
}

.carousel-prev,
.carousel-next {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 40px;

  position: absolute;
  top: calc(50% - 20px);
  z-index: 2;
}

.carousel-prev {
  left: 10px;

  .svg-container {
    margin-left: -4px;
  }
}

.carousel-next {
  right: 10px;
}

.carousel-slides {
  display: block;

  .carousel-slide {
    opacity: 0;
  }

  .carousel-slide.active {
    opacity: 1;
    z-index: 1;
  }
}
</style>
