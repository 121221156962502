<template>
  <TableWrapper
    table-height="unset"
    disable-shades
  >
    <thead>
      <tr>
        <th
          v-for="colType in visualInput"
          :key="colType.key"
          :class="{ 'contains-chip-header': colType.includesChip }"
        >
          {{ colType.name }}
          <p
            v-if="colType.includesChip"
            class="total-chip"
          >
            {{ formatValue(getTotal(colType.key), colType.prefix, colType.suffix) }}
          </p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(unit, i) in reportData"
        :key="i"
      >
        <td
          v-for="(colType, col) in visualInput"
          :key="colType.key"
          :class="{
            'first-column': col === 0,
            negative: unit[colType.key] < 0,
            centered: colType.key === REPORTS_KPI_CODE,
          }"
          :title="unit[colType.key]"
        >
          <a
            v-if="unit?.id && col === 0"
            :href="getEntityLink(unit)"
            target="_blank"
          >
            {{ unit?.customer || unit?.name }}
          </a>
          <div v-else-if="col === 0">
            {{ unit?.name }}
          </div>
          <div v-else>
            {{ formatValue(unit[colType.key], colType.prefix, colType.suffix) }}
          </div>
        </td>
      </tr>
    </tbody>
  </TableWrapper>
</template>

<script setup>
// ReportTable
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import { toRefs } from 'vue';
import { REPORTS_KPI_CODE } from '@/intelligence/store/data/kpis';
import { baseUrl, customersArea } from '@/crm/router/urlBits';
import useCurrency from '@/shared/composables/useCurrency';

const props = defineProps({
  reportData: {
    type: Array,
    default: () => [],
  },
  visualInput: {
    type: Array,
    default: () => [],
    required: true,
  },
});

const { cNumber, cPrice } = useCurrency();
const { reportData, visualInput } = toRefs(props);

function formatValue(value, prefix = '', suffix = '') {
  return `${prefix ? cPrice(value) : cNumber(value)} ${suffix}`;
}

function getTotal(key) {
  const total = (reportData.value || []).reduce((sum, unit) => sum + unit[key], 0.0);
  return Math.round(total * 100) / 100;
}

function getEntityLink({ id }) {
  return `${baseUrl}/${customersArea}/${id}`;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.table-wrapper {
  overflow: auto;
  height: 330px;
  position: relative;
}

table {
  position: relative;
  width: 100%;
}

tbody tr:first-child {
  height: var(--spacing-8);

  th,
  td {
    vertical-align: bottom;
  }
}

tr {
  background: var(--colour-panel-g-0);

  &:nth-child(even) {
    background: var(--colour-panel-g-2);
  }
}

th,
td {
  color: var(--colour-utility-black);
  padding: var(--spacing-2);
  border: none;
}

th {
  font-size: var(--font-size-5);
  background: var(--colour-panel-action);
  position: sticky;
  top: 0;
  text-align: center;
  min-height: 70px;
  vertical-align: middle;

  &:first-child {
    text-align: left;
    padding-left: var(--spacing-1);
  }

  &.contains-chip-header {
    padding-bottom: var(--spacing-2);
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: var(--spacing-2) var(--spacing-1);
    min-width: 100px;
  }
}

td {
  font-size: var(--font-size-small);
  padding: var(--spacing-1);
  line-height: var(--spacing-2);
  text-align: center;

  &:first-child {
    background: var(--colour-panel-g-2);
    border-right: 1px solid var(--colour-panel-g-8);
    text-align: left;
    padding-left: var(--spacing-1);

    @media #{map-get($display-breakpoints, 'xs-only')} {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 140px;
    }

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

tr:nth-child(even) td.first-column {
  background: var(--colour-panel-g-4);
}

td.centered {
  text-align: center;
  padding-left: var(--spacing-1);
}

.total-chip {
  position: absolute;
  background: var(--colour-panel-g-0);
  box-shadow: 0 0 var(--border-radius-half) var(--shadow-spread) var(--shadow-colour);
  font-size: var(--font-size-5);
  font-weight: 500;
  letter-spacing: 0;
  line-height: var(--spacing-3);
  padding: var(--spacing-half) var(--spacing-1);
  border-radius: var(--spacing-6);
  margin: auto;
  margin-top: var(--spacing-1);
  right: 50%;
  transform: translateX(50%);
  white-space: nowrap;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: auto;
    min-width: 80px;
  }
}

.negative {
  color: var(--colour-data-mandy-label);
}
</style>
