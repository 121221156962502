export const countryList = [{
  'text': 'Albania',
  'value': 'Albania',
  'official': 'Republic of Albania',
}, {
  'text': 'North Macedonia',
  'value': 'North Macedonia',
  'official': 'Republic of North Macedonia',
}, {
  'text': 'Panama',
  'value': 'Panama',
  'official': 'Republic of Panama',
}, {
  'text': 'Hungary',
  'value': 'Hungary',
  'official': 'Hungary',
}, {
  'text': 'Bermuda',
  'value': 'Bermuda',
  'official': 'Bermuda',
}, {
  'text': 'Oman',
  'value': 'Oman',
  'official': 'Sultanate of Oman',
}, {
  'text': 'Grenada',
  'value': 'Grenada',
  'official': 'Grenada',
}, {
  'text': 'Liechtenstein',
  'value': 'Liechtenstein',
  'official': 'Principality of Liechtenstein',
}, {
  'text': 'Azerbaijan',
  'value': 'Azerbaijan',
  'official': 'Republic of Azerbaijan',
}, {
  'text': 'Turks and Caicos Islands',
  'value': 'Turks and Caicos Islands',
  'official': 'Turks and Caicos Islands',
}, {
  'text': 'Yemen',
  'value': 'Yemen',
  'official': 'Republic of Yemen',
}, {
  'text': 'Turkmenistan',
  'value': 'Turkmenistan',
  'official': 'Turkmenistan',
}, {
  'text': 'Samoa',
  'value': 'Samoa',
  'official': 'Independent State of Samoa',
}, {
  'text': 'Republic of the Congo',
  'value': 'Republic of the Congo',
  'official': 'Republic of the Congo',
}, {
  'text': 'Malawi',
  'value': 'Malawi',
  'official': 'Republic of Malawi',
}, {
  'text': 'Norfolk Island',
  'value': 'Norfolk Island',
  'official': 'Territory of Norfolk Island',
}, {
  'text': 'Anguilla',
  'value': 'Anguilla',
  'official': 'Anguilla',
}, {
  'text': 'Chile',
  'value': 'Chile',
  'official': 'Republic of Chile',
}, {
  'text': 'Guam',
  'value': 'Guam',
  'official': 'Guam',
}, {
  'text': 'Poland',
  'value': 'Poland',
  'official': 'Republic of Poland',
}, {
  'text': 'Slovakia',
  'value': 'Slovakia',
  'official': 'Slovak Republic',
}, {
  'text': 'Saudi Arabia',
  'value': 'Saudi Arabia',
  'official': 'Kingdom of Saudi Arabia',
}, {
  'text': 'Bolivia',
  'value': 'Bolivia',
  'official': 'Plurinational State of Bolivia',
}, {
  'text': 'Libya',
  'value': 'Libya',
  'official': 'State of Libya',
}, {
  'text': 'Fiji',
  'value': 'Fiji',
  'official': 'Republic of Fiji',
}, {
  'text': 'Honduras',
  'value': 'Honduras',
  'official': 'Republic of Honduras',
}, {
  'text': 'United Arab Emirates',
  'value': 'United Arab Emirates',
  'official': 'United Arab Emirates',
}, {
  'text': 'Benin',
  'value': 'Benin',
  'official': 'Republic of Benin',
}, {
  'text': 'North Korea',
  'value': 'North Korea',
  'official': "Democratic People's Republic of Korea",
}, {
  'text': 'Jamaica',
  'value': 'Jamaica',
  'official': 'Jamaica',
}, {
  'text': 'Zambia',
  'value': 'Zambia',
  'official': 'Republic of Zambia',
}, {
  'text': 'Åland Islands',
  'value': 'Åland Islands',
  'official': 'Åland Islands',
}, {
  'text': 'British Virgin Islands',
  'value': 'British Virgin Islands',
  'official': 'Virgin Islands',
}, {
  'text': 'Cocos (Keeling) Islands',
  'value': 'Cocos (Keeling) Islands',
  'official': 'Territory of the Cocos (Keeling) Islands',
}, {
  'text': 'Mayotte',
  'value': 'Mayotte',
  'official': 'Department of Mayotte',
}, {
  'text': 'Kenya',
  'value': 'Kenya',
  'official': 'Republic of Kenya',
}, {
  'text': 'Montenegro',
  'value': 'Montenegro',
  'official': 'Montenegro',
}, {
  'text': 'Morocco',
  'value': 'Morocco',
  'official': 'Kingdom of Morocco',
}, {
  'text': 'Tunisia',
  'value': 'Tunisia',
  'official': 'Tunisian Republic',
}, {
  'text': 'Argentina',
  'value': 'Argentina',
  'official': 'Argentine Republic',
}, {
  'text': 'Andorra',
  'value': 'Andorra',
  'official': 'Principality of Andorra',
}, {
  'text': 'Barbados',
  'value': 'Barbados',
  'official': 'Barbados',
}, {
  'text': 'Equatorial Guinea',
  'value': 'Equatorial Guinea',
  'official': 'Republic of Equatorial Guinea',
}, {
  'text': 'Haiti',
  'value': 'Haiti',
  'official': 'Republic of Haiti',
}, {
  'text': 'Pitcairn Islands',
  'value': 'Pitcairn Islands',
  'official': 'Pitcairn Group of Islands',
}, {
  'text': 'Maldives',
  'value': 'Maldives',
  'official': 'Republic of the Maldives',
}, {
  'text': 'Bahrain',
  'value': 'Bahrain',
  'official': 'Kingdom of Bahrain',
}, {
  'text': 'Niger',
  'value': 'Niger',
  'official': 'Republic of Niger',
}, {
  'text': 'Mexico',
  'value': 'Mexico',
  'official': 'United Mexican States',
}, {
  'text': 'Luxembourg',
  'value': 'Luxembourg',
  'official': 'Grand Duchy of Luxembourg',
}, {
  'text': 'Nauru',
  'value': 'Nauru',
  'official': 'Republic of Nauru',
}, {
  'text': 'Saint Martin',
  'value': 'Saint Martin',
  'official': 'Saint Martin',
}, {
  'text': 'Croatia',
  'value': 'Croatia',
  'official': 'Republic of Croatia',
}, {
  'text': 'Eswatini',
  'value': 'Eswatini',
  'official': 'Kingdom of Eswatini',
}, {
  'text': 'Antigua and Barbuda',
  'value': 'Antigua and Barbuda',
  'official': 'Antigua and Barbuda',
}, {
  'text': 'El Salvador',
  'value': 'El Salvador',
  'official': 'Republic of El Salvador',
}, {
  'text': 'Jersey',
  'value': 'Jersey',
  'official': 'Bailiwick of Jersey',
}, {
  'text': 'Egypt',
  'value': 'Egypt',
  'official': 'Arab Republic of Egypt',
}, {
  'text': 'Nepal',
  'value': 'Nepal',
  'official': 'Federal Democratic Republic of Nepal',
}, {
  'text': 'Malta',
  'value': 'Malta',
  'official': 'Republic of Malta',
}, {
  'text': 'South Sudan',
  'value': 'South Sudan',
  'official': 'Republic of South Sudan',
}, {
  'text': 'South Georgia',
  'value': 'South Georgia',
  'official': 'South Georgia and the South Sandwich Islands',
}, {
  'text': 'Gabon',
  'value': 'Gabon',
  'official': 'Gabonese Republic',
}, {
  'text': 'Malaysia',
  'value': 'Malaysia',
  'official': 'Malaysia',
}, {
  'text': 'Dominica',
  'value': 'Dominica',
  'official': 'Commonwealth of Dominica',
}, {
  'text': 'New Zealand',
  'value': 'New Zealand',
  'official': 'New Zealand',
}, {
  'text': 'Belgium',
  'value': 'Belgium',
  'official': 'Kingdom of Belgium',
}, {
  'text': 'Bosnia and Herzegovina',
  'value': 'Bosnia and Herzegovina',
  'official': 'Bosnia and Herzegovina',
}, {
  'text': 'Ukraine',
  'value': 'Ukraine',
  'official': 'Ukraine',
}, {
  'text': 'Bhutan',
  'value': 'Bhutan',
  'official': 'Kingdom of Bhutan',
}, {
  'text': 'Somalia',
  'value': 'Somalia',
  'official': 'Federal Republic of Somalia',
}, {
  'text': 'Micronesia',
  'value': 'Micronesia',
  'official': 'Federated States of Micronesia',
}, {
  'text': 'Ghana',
  'value': 'Ghana',
  'official': 'Republic of Ghana',
}, {
  'text': 'Macau',
  'value': 'Macau',
  'official': "Macao Special Administrative Region of the People's Republic of China",
}, {
  'text': 'Colombia',
  'value': 'Colombia',
  'official': 'Republic of Colombia',
}, {
  'text': 'Djibouti',
  'value': 'Djibouti',
  'official': 'Republic of Djibouti',
}, {
  'text': 'Saint Barthélemy',
  'value': 'Saint Barthélemy',
  'official': 'Collectivity of Saint Barthélemy',
}, {
  'text': 'United States',
  'value': 'United States',
  'official': 'United States of America',
}, {
  'text': 'Cyprus',
  'value': 'Cyprus',
  'official': 'Republic of Cyprus',
}, {
  'text': 'Singapore',
  'value': 'Singapore',
  'official': 'Republic of Singapore',
}, {
  'text': 'Iran',
  'value': 'Iran',
  'official': 'Islamic Republic of Iran',
}, {
  'text': 'Suriname',
  'value': 'Suriname',
  'official': 'Republic of Suriname',
}, {
  'text': 'Denmark',
  'value': 'Denmark',
  'official': 'Kingdom of Denmark',
}, {
  'text': 'Lithuania',
  'value': 'Lithuania',
  'official': 'Republic of Lithuania',
}, {
  'text': 'Solomon Islands',
  'value': 'Solomon Islands',
  'official': 'Solomon Islands',
}, {
  'text': 'Cayman Islands',
  'value': 'Cayman Islands',
  'official': 'Cayman Islands',
}, {
  'text': 'Syria',
  'value': 'Syria',
  'official': 'Syrian Arab Republic',
}, {
  'text': 'Lesotho',
  'value': 'Lesotho',
  'official': 'Kingdom of Lesotho',
}, {
  'text': 'Ivory Coast',
  'value': 'Ivory Coast',
  'official': "Republic of Côte d'Ivoire",
}, {
  'text': 'Kuwait',
  'value': 'Kuwait',
  'official': 'State of Kuwait',
}, {
  'text': 'Greece',
  'value': 'Greece',
  'official': 'Hellenic Republic',
}, {
  'text': 'Netherlands',
  'value': 'Netherlands',
  'official': 'Kingdom of the Netherlands',
}, {
  'text': 'Bouvet Island',
  'value': 'Bouvet Island',
  'official': 'Bouvet Island',
}, {
  'text': 'Gambia',
  'value': 'Gambia',
  'official': 'Republic of the Gambia',
}, {
  'text': 'Guatemala',
  'value': 'Guatemala',
  'official': 'Republic of Guatemala',
}, {
  'text': 'Central African Republic',
  'value': 'Central African Republic',
  'official': 'Central African Republic',
}, {
  'text': 'Dominican Republic',
  'value': 'Dominican Republic',
  'official': 'Dominican Republic',
}, {
  'text': 'Guinea-Bissau',
  'value': 'Guinea-Bissau',
  'official': 'Republic of Guinea-Bissau',
}, {
  'text': 'British Indian Ocean Territory',
  'value': 'British Indian Ocean Territory',
  'official': 'British Indian Ocean Territory',
}, {
  'text': 'Burundi',
  'value': 'Burundi',
  'official': 'Republic of Burundi',
}, {
  'text': 'Uzbekistan',
  'value': 'Uzbekistan',
  'official': 'Republic of Uzbekistan',
}, {
  'text': 'Latvia',
  'value': 'Latvia',
  'official': 'Republic of Latvia',
}, {
  'text': 'Turkey',
  'value': 'Turkey',
  'official': 'Republic of Turkey',
}, {
  'text': 'Costa Rica',
  'value': 'Costa Rica',
  'official': 'Republic of Costa Rica',
}, {
  'text': 'Palestine',
  'value': 'Palestine',
  'official': 'State of Palestine',
}, {
  'text': 'Armenia',
  'value': 'Armenia',
  'official': 'Republic of Armenia',
}, {
  'text': 'Mongolia',
  'value': 'Mongolia',
  'official': 'Mongolia',
}, {
  'text': 'Romania',
  'value': 'Romania',
  'official': 'Romania',
}, {
  'text': 'Iraq',
  'value': 'Iraq',
  'official': 'Republic of Iraq',
}, {
  'text': 'Philippines',
  'value': 'Philippines',
  'official': 'Republic of the Philippines',
}, {
  'text': 'Brazil',
  'value': 'Brazil',
  'official': 'Federative Republic of Brazil',
}, {
  'text': 'Nicaragua',
  'value': 'Nicaragua',
  'official': 'Republic of Nicaragua',
}, {
  'text': 'Bulgaria',
  'value': 'Bulgaria',
  'official': 'Republic of Bulgaria',
}, {
  'text': 'Saint Lucia',
  'value': 'Saint Lucia',
  'official': 'Saint Lucia',
}, {
  'text': 'Norway',
  'value': 'Norway',
  'official': 'Kingdom of Norway',
}, {
  'text': 'Wallis and Futuna',
  'value': 'Wallis and Futuna',
  'official': 'Territory of the Wallis and Futuna Islands',
}, {
  'text': 'Israel',
  'value': 'Israel',
  'official': 'State of Israel',
}, {
  'text': 'Rwanda',
  'value': 'Rwanda',
  'official': 'Republic of Rwanda',
}, {
  'text': 'Eritrea',
  'value': 'Eritrea',
  'official': 'State of Eritrea',
}, {
  'text': 'Chad',
  'value': 'Chad',
  'official': 'Republic of Chad',
}, {
  'text': 'Sri Lanka',
  'value': 'Sri Lanka',
  'official': 'Democratic Socialist Republic of Sri Lanka',
}, {
  'text': 'Belarus',
  'value': 'Belarus',
  'official': 'Republic of Belarus',
}, {
  'text': 'Saint Vincent and the Grenadines',
  'value': 'Saint Vincent and the Grenadines',
  'official': 'Saint Vincent and the Grenadines',
}, {
  'text': 'Tajikistan',
  'value': 'Tajikistan',
  'official': 'Republic of Tajikistan',
}, {
  'text': 'Guyana',
  'value': 'Guyana',
  'official': 'Co-operative Republic of Guyana',
}, {
  'text': 'Tokelau',
  'value': 'Tokelau',
  'official': 'Tokelau',
}, {
  'text': 'Falkland Islands',
  'value': 'Falkland Islands',
  'official': 'Falkland Islands',
}, {
  'text': 'Botswana',
  'value': 'Botswana',
  'official': 'Republic of Botswana',
}, {
  'text': 'Canada',
  'value': 'Canada',
  'official': 'Canada',
}, {
  'text': 'Indonesia',
  'value': 'Indonesia',
  'official': 'Republic of Indonesia',
}, {
  'text': 'Antarctica',
  'value': 'Antarctica',
  'official': 'Antarctica',
}, {
  'text': 'Ireland',
  'value': 'Ireland',
  'official': 'Republic of Ireland',
}, {
  'text': 'South Africa',
  'value': 'South Africa',
  'official': 'Republic of South Africa',
}, {
  'text': 'Timor-Leste',
  'value': 'Timor-Leste',
  'official': 'Democratic Republic of Timor-Leste',
}, {
  'text': 'Palau',
  'value': 'Palau',
  'official': 'Republic of Palau',
}, {
  'text': 'Japan',
  'value': 'Japan',
  'official': 'Japan',
}, {
  'text': 'Moldova',
  'value': 'Moldova',
  'official': 'Republic of Moldova',
}, {
  'text': 'Pakistan',
  'value': 'Pakistan',
  'official': 'Islamic Republic of Pakistan',
}, {
  'text': 'Saint Pierre and Miquelon',
  'value': 'Saint Pierre and Miquelon',
  'official': 'Saint Pierre and Miquelon',
}, {
  'text': 'Angola',
  'value': 'Angola',
  'official': 'Republic of Angola',
}, {
  'text': 'Bahamas',
  'value': 'Bahamas',
  'official': 'Commonwealth of the Bahamas',
}, {
  'text': 'Christmas Island',
  'value': 'Christmas Island',
  'official': 'Territory of Christmas Island',
}, {
  'text': 'Cuba',
  'value': 'Cuba',
  'official': 'Republic of Cuba',
}, {
  'text': 'Nigeria',
  'value': 'Nigeria',
  'official': 'Federal Republic of Nigeria',
}, {
  'text': 'Isle of Man',
  'value': 'Isle of Man',
  'official': 'Isle of Man',
}, {
  'text': 'Portugal',
  'value': 'Portugal',
  'official': 'Portuguese Republic',
}, {
  'text': 'Saint Kitts and Nevis',
  'value': 'Saint Kitts and Nevis',
  'official': 'Federation of Saint Christopher and Nevis',
}, {
  'text': 'Thailand',
  'value': 'Thailand',
  'official': 'Kingdom of Thailand',
}, {
  'text': 'Monaco',
  'value': 'Monaco',
  'official': 'Principality of Monaco',
}, {
  'text': 'Martinique',
  'value': 'Martinique',
  'official': 'Martinique',
}, {
  'text': 'Venezuela',
  'value': 'Venezuela',
  'official': 'Bolivarian Republic of Venezuela',
}, {
  'text': 'New Caledonia',
  'value': 'New Caledonia',
  'official': 'New Caledonia',
}, {
  'text': 'Peru',
  'value': 'Peru',
  'official': 'Republic of Peru',
}, {
  'text': 'Laos',
  'value': 'Laos',
  'official': "Lao People's Democratic Republic",
}, {
  'text': 'DR Congo',
  'value': 'DR Congo',
  'official': 'Democratic Republic of the Congo',
}, {
  'text': 'France',
  'value': 'France',
  'official': 'French Republic',
}, {
  'text': 'Sint Maarten',
  'value': 'Sint Maarten',
  'official': 'Sint Maarten',
}, {
  'text': 'Togo',
  'value': 'Togo',
  'official': 'Togolese Republic',
}, {
  'text': 'Kyrgyzstan',
  'value': 'Kyrgyzstan',
  'official': 'Kyrgyz Republic',
}, {
  'text': 'Zimbabwe',
  'value': 'Zimbabwe',
  'official': 'Republic of Zimbabwe',
}, {
  'text': 'Mozambique',
  'value': 'Mozambique',
  'official': 'Republic of Mozambique',
}, {
  'text': 'Vietnam',
  'value': 'Vietnam',
  'official': 'Socialist Republic of Vietnam',
}, {
  'text': 'Mauritania',
  'value': 'Mauritania',
  'official': 'Islamic Republic of Mauritania',
}, {
  'text': 'Cape Verde',
  'value': 'Cape Verde',
  'official': 'Republic of Cabo Verde',
}, {
  'text': 'Qatar',
  'value': 'Qatar',
  'official': 'State of Qatar',
}, {
  'text': 'Cameroon',
  'value': 'Cameroon',
  'official': 'Republic of Cameroon',
}, {
  'text': 'Uganda',
  'value': 'Uganda',
  'official': 'Republic of Uganda',
}, {
  'text': 'Vanuatu',
  'value': 'Vanuatu',
  'official': 'Republic of Vanuatu',
}, {
  'text': 'Lebanon',
  'value': 'Lebanon',
  'official': 'Lebanese Republic',
}, {
  'text': 'French Guiana',
  'value': 'French Guiana',
  'official': 'Guiana',
}, {
  'text': 'Vatican City',
  'value': 'Vatican City',
  'official': 'Vatican City State',
}, {
  'text': 'Liberia',
  'value': 'Liberia',
  'official': 'Republic of Liberia',
}, {
  'text': 'Niue',
  'value': 'Niue',
  'official': 'Niue',
}, {
  'text': 'South Korea',
  'value': 'South Korea',
  'official': 'Republic of Korea',
}, {
  'text': 'Burkina Faso',
  'value': 'Burkina Faso',
  'official': 'Burkina Faso',
}, {
  'text': 'Tanzania',
  'value': 'Tanzania',
  'official': 'United Republic of Tanzania',
}, {
  'text': 'Iceland',
  'value': 'Iceland',
  'official': 'Iceland',
}, {
  'text': 'Afghanistan',
  'value': 'Afghanistan',
  'official': 'Islamic Republic of Afghanistan',
}, {
  'text': 'Austria',
  'value': 'Austria',
  'official': 'Republic of Austria',
}, {
  'text': 'Finland',
  'value': 'Finland',
  'official': 'Republic of Finland',
}, {
  'text': 'Taiwan',
  'value': 'Taiwan',
  'official': 'Republic of China (Taiwan)',
}, {
  'text': 'French Southern and Antarctic Lands',
  'value': 'French Southern and Antarctic Lands',
  'official': 'Territory of the French Southern and Antarctic Lands',
}, {
  'text': 'Kosovo',
  'value': 'Kosovo',
  'official': 'Republic of Kosovo',
}, {
  'text': 'São Tomé and Príncipe',
  'value': 'São Tomé and Príncipe',
  'official': 'Democratic Republic of São Tomé and Príncipe',
}, {
  'text': 'Australia',
  'value': 'Australia',
  'official': 'Commonwealth of Australia',
}, {
  'text': 'Seychelles',
  'value': 'Seychelles',
  'official': 'Republic of Seychelles',
}, {
  'text': 'Slovenia',
  'value': 'Slovenia',
  'official': 'Republic of Slovenia',
}, {
  'text': 'Mali',
  'value': 'Mali',
  'official': 'Republic of Mali',
}, {
  'text': 'United States Minor Outlying Islands',
  'value': 'United States Minor Outlying Islands',
  'official': 'United States Minor Outlying Islands',
}, {
  'text': 'Western Sahara',
  'value': 'Western Sahara',
  'official': 'Sahrawi Arab Democratic Republic',
}, {
  'text': 'Comoros',
  'value': 'Comoros',
  'official': 'Union of the Comoros',
}, {
  'text': 'Switzerland',
  'value': 'Switzerland',
  'official': 'Swiss Confederation',
}, {
  'text': 'Estonia',
  'value': 'Estonia',
  'official': 'Republic of Estonia',
}, {
  'text': 'Svalbard and Jan Mayen',
  'value': 'Svalbard and Jan Mayen',
  'official': 'Svalbard og Jan Mayen',
}, {
  'text': 'Saint Helena, Ascension and Tristan da Cunha',
  'value': 'Saint Helena, Ascension and Tristan da Cunha',
  'official': 'Saint Helena, Ascension and Tristan da Cunha',
}, {
  'text': 'Belize',
  'value': 'Belize',
  'official': 'Belize',
}, {
  'text': 'Germany',
  'value': 'Germany',
  'official': 'Federal Republic of Germany',
}, {
  'text': 'Faroe Islands',
  'value': 'Faroe Islands',
  'official': 'Faroe Islands',
}, {
  'text': 'Heard Island and McDonald Islands',
  'value': 'Heard Island and McDonald Islands',
  'official': 'Heard Island and McDonald Islands',
}, {
  'text': 'China',
  'value': 'China',
  'official': "People's Republic of China",
}, {
  'text': 'Puerto Rico',
  'value': 'Puerto Rico',
  'official': 'Commonwealth of Puerto Rico',
}, {
  'text': 'French Polynesia',
  'value': 'French Polynesia',
  'official': 'French Polynesia',
}, {
  'text': 'Mauritius',
  'value': 'Mauritius',
  'official': 'Republic of Mauritius',
}, {
  'text': 'Russia',
  'value': 'Russia',
  'official': 'Russian Federation',
}, {
  'text': 'Kiribati',
  'value': 'Kiribati',
  'official': 'Independent and Sovereign Republic of Kiribati',
}, {
  'text': 'Gibraltar',
  'value': 'Gibraltar',
  'official': 'Gibraltar',
}, {
  'text': 'Spain',
  'value': 'Spain',
  'official': 'Kingdom of Spain',
}, {
  'text': 'Namibia',
  'value': 'Namibia',
  'official': 'Republic of Namibia',
}, {
  'text': 'Trinidad and Tobago',
  'value': 'Trinidad and Tobago',
  'official': 'Republic of Trinidad and Tobago',
}, {
  'text': 'Italy',
  'value': 'Italy',
  'official': 'Italian Republic',
}, {
  'text': 'Uruguay',
  'value': 'Uruguay',
  'official': 'Oriental Republic of Uruguay',
}, {
  'text': 'Aruba',
  'value': 'Aruba',
  'official': 'Aruba',
}, {
  'text': 'Marshall Islands',
  'value': 'Marshall Islands',
  'official': 'Republic of the Marshall Islands',
}, {
  'text': 'Serbia',
  'value': 'Serbia',
  'official': 'Republic of Serbia',
}, {
  'text': 'Guadeloupe',
  'value': 'Guadeloupe',
  'official': 'Guadeloupe',
}, {
  'text': 'Ethiopia',
  'value': 'Ethiopia',
  'official': 'Federal Democratic Republic of Ethiopia',
}, {
  'text': 'San Marino',
  'value': 'San Marino',
  'official': 'Republic of San Marino',
}, {
  'text': 'Sierra Leone',
  'value': 'Sierra Leone',
  'official': 'Republic of Sierra Leone',
}, {
  'text': 'Réunion',
  'value': 'Réunion',
  'official': 'Réunion Island',
}, {
  'text': 'Madagascar',
  'value': 'Madagascar',
  'official': 'Republic of Madagascar',
}, {
  'text': 'Guinea',
  'value': 'Guinea',
  'official': 'Republic of Guinea',
}, {
  'text': 'India',
  'value': 'India',
  'official': 'Republic of India',
}, {
  'text': 'Bangladesh',
  'value': 'Bangladesh',
  'official': "People's Republic of Bangladesh",
}, {
  'text': 'Brunei',
  'value': 'Brunei',
  'official': 'Nation of Brunei, Abode of Peace',
}, {
  'text': 'American Samoa',
  'value': 'American Samoa',
  'official': 'American Samoa',
}, {
  'text': 'Northern Mariana Islands',
  'value': 'Northern Mariana Islands',
  'official': 'Commonwealth of the Northern Mariana Islands',
}, {
  'text': 'Algeria',
  'value': 'Algeria',
  'official': "People's Democratic Republic of Algeria",
}, {
  'text': 'Sudan',
  'value': 'Sudan',
  'official': 'Republic of the Sudan',
}, {
  'text': 'Montserrat',
  'value': 'Montserrat',
  'official': 'Montserrat',
}, {
  'text': 'Greenland',
  'value': 'Greenland',
  'official': 'Greenland',
}, {
  'text': 'Jordan',
  'value': 'Jordan',
  'official': 'Hashemite Kingdom of Jordan',
}, {
  'text': 'Czechia',
  'value': 'Czechia',
  'official': 'Czech Republic',
}, {
  'text': 'United Kingdom',
  'value': 'United Kingdom',
  'official': 'United Kingdom of Great Britain and Northern Ireland',
}, {
  'text': 'Tonga',
  'value': 'Tonga',
  'official': 'Kingdom of Tonga',
}, {
  'text': 'Cook Islands',
  'value': 'Cook Islands',
  'official': 'Cook Islands',
}, {
  'text': 'Caribbean Netherlands',
  'value': 'Caribbean Netherlands',
  'official': 'Bonaire, Sint Eustatius and Saba',
}, {
  'text': 'Tuvalu',
  'value': 'Tuvalu',
  'official': 'Tuvalu',
}, {
  'text': 'Kazakhstan',
  'value': 'Kazakhstan',
  'official': 'Republic of Kazakhstan',
}, {
  'text': 'Sweden',
  'value': 'Sweden',
  'official': 'Kingdom of Sweden',
}, {
  'text': 'Georgia',
  'value': 'Georgia',
  'official': 'Georgia',
}, {
  'text': 'Paraguay',
  'value': 'Paraguay',
  'official': 'Republic of Paraguay',
}, {
  'text': 'Curaçao',
  'value': 'Curaçao',
  'official': 'Country of Curaçao',
}, {
  'text': 'Myanmar',
  'value': 'Myanmar',
  'official': 'Republic of the Union of Myanmar',
}, {
  'text': 'Papua New Guinea',
  'value': 'Papua New Guinea',
  'official': 'Independent State of Papua New Guinea',
}, {
  'text': 'Cambodia',
  'value': 'Cambodia',
  'official': 'Kingdom of Cambodia',
}, {
  'text': 'Senegal',
  'value': 'Senegal',
  'official': 'Republic of Senegal',
}, {
  'text': 'Ecuador',
  'value': 'Ecuador',
  'official': 'Republic of Ecuador',
}, {
  'text': 'Guernsey',
  'value': 'Guernsey',
  'official': 'Bailiwick of Guernsey',
}, {
  'text': 'United States Virgin Islands',
  'value': 'United States Virgin Islands',
  'official': 'Virgin Islands of the United States',
}, {
  'text': 'Hong Kong',
  'value': 'Hong Kong',
  'official': "Hong Kong Special Administrative Region of the People's Republic of China",
},].sort(compare);

function compare( a, b ) {
  if ( a.text < b.text ){
    return -1;
  }
  if ( a.text > b.text ){
    return 1;
  }
  return 0;
}