export const getResponseParameters = () => type => {
  switch (type) {
  case 'get':
    return `
      header
      targets {
        id
        name
        description
        measurement {
          id
          metric
          unit
        }
        periodValues {
          id
          interval_start
          interval_end
          value
        }
        dimensions {
          id
          values
        }   
      }
    `;
  case 'add':
  case 'update':
  case 'delete':
    return `
      id
      name
      description
      dimensions {
        id
        values
      }
      measurement {
        id
        metric
        unit
      }
      periodValues {
        id
        interval_start
        interval_end
        value
      }
    `;
  case 'deleteTargetsByBubbleId':
    return '';
  }
};