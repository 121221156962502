<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    :fill="fill"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :viewBox="viewBox"
    :style="`enable-background:new ${viewBox};`"
    :width="width"
    xml:space="preserve"
    :aria-labelledby="iconName"
  >
    <g>
      <path d="M23,7.7c0,1.1-0.9,2-2,2c-1.1,0-1.9-0.9-1.9-2c0-1.1,0.8-1.9,1.9-1.9C22.2,5.8,23,6.6,23,7.7" />
      <path d="M14.5,6.9v1.8c0,0.6-0.5,1.1-1.1,1.1H3.1c-0.5,0-1-0.4-1-1V7.7c0-1,0.8-1.9,1.9-1.9h9.4 C14,5.8,14.5,6.3,14.5,6.9" />
      <path d="M2.1,24.8c0-1.1,0.9-2,2-2c1.1,0,1.9,0.9,1.9,2c0,1.1-0.8,1.9-1.9,1.9C3,26.7,2.1,25.9,2.1,24.8" />
      <path d="M10.6,25.7v-1.8c0-0.6,0.5-1.1,1.1-1.1h10.4c0.5,0,1,0.4,1,1v1.2c0,1-0.8,1.8-1.8,1.8h-9.6 C11.1,26.7,10.6,26.2,10.6,25.7" />
      <path d="M22.1,18.2H4c-1,0-1.9-0.8-1.9-1.9v-1.1c0-0.5,0.4-1,1-1h18.1c1,0,1.8,0.8,1.8,1.8v1.1 C23,17.8,22.6,18.2,22.1,18.2" />
      <template v-if="isLong">
        <path d="M61.1,23.5c-3.5,0-5.9-2.8-5.9-6.3c0-3.5,2.4-6.3,5.9-6.3c3.5,0,5.9,2.8,5.9,6.3 C66.9,20.7,64.5,23.5,61.1,23.5 M61.1,7.3c-5.5,0-9.6,4.3-9.6,9.9c0,5.7,4.1,9.9,9.6,9.9c2,0,3.8-0.6,5.3-1.6 c0.5-0.3,0.5-0.5,0.5-0.5v0.2v0.3c0,0.6,0.5,1.1,1.1,1.1h1.5c0.6,0,1.1-0.5,1.1-1.1v-8.4C70.7,11.5,66.6,7.3,61.1,7.3" />
        <path d="M35.7,22.8c0.4-0.6,1.2-0.8,1.8-0.4c1.5,0.9,3,1.4,4.6,1.4c1.7,0,3.3-0.9,3.3-2.4c0-2.2-3.2-2.3-6-3.2 c-2.3-0.8-4.4-2.2-4.4-4.9c0-4.1,3.7-6,7.1-6c2.1,0,3.7,0.5,5.1,1.3c0.7,0.4,0.9,1.3,0.4,2l-0.4,0.6c-0.4,0.6-1.2,0.7-1.8,0.4 c-0.9-0.5-1.9-0.8-3-0.8c-1.9,0-3.4,0.9-3.4,2.4c0,1.3,1.5,1.7,3.4,2.2c3,0.8,6.9,2.1,6.9,6c0,3.6-3.4,5.8-7.1,5.8 c-2.5,0-4.7-0.6-6.5-1.8c-0.7-0.4-0.8-1.3-0.4-2L35.7,22.8z" />
        <path d="M103.6,22.6c0.3-0.5,1-0.6,1.5-0.3c1.5,1,3.2,1.5,4.8,1.5c1.7,0,3.3-0.9,3.3-2.4c0-2.2-3.2-2.3-6-3.2 c-2.3-0.8-4.4-2.2-4.4-4.9c0-4.1,3.7-6,7.1-6c2.2,0,4,0.6,5.4,1.4c0.5,0.3,0.7,1.1,0.3,1.6l-0.7,1c-0.3,0.5-1,0.6-1.5,0.3 c-1-0.6-2.1-0.9-3.2-0.9c-1.9,0-3.4,0.9-3.4,2.4c0,1.3,1.5,1.7,3.4,2.2c3,0.8,6.9,2.1,6.9,6c0,3.6-3.4,5.8-7.1,5.8 c-2.6,0-4.8-0.7-6.8-1.9c-0.5-0.4-0.7-1.1-0.3-1.6L103.6,22.6z" />
        <path d="M75.6,1.7h1.6c0.6,0,1.1,0.5,1.1,1.1v22.7c0,0.6-0.5,1.1-1.1,1.1h-1.6c-0.6,0-1.1-0.5-1.1-1.1V2.9 C74.4,2.2,75,1.7,75.6,1.7" />
        <path d="M138.5,5.8c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2c-1.1,0-2-0.9-2-2C136.5,6.7,137.4,5.8,138.5,5.8" />
        <path d="M137.7,12.7h1.6c0.6,0,1.2,0.5,1.2,1.2v11.7c0,0.6-0.5,1.2-1.2,1.2h-1.6c-0.6,0-1.2-0.5-1.2-1.2V13.9 C136.5,13.3,137,12.7,137.7,12.7" />
        <path d="M131.9,15.7v1.2c0,0.6-0.5,1.2-1.2,1.2h-8.1c-0.6,0-1.2-0.5-1.2-1.2v-1.2c0-0.6,0.5-1.2,1.2-1.2h8.1 C131.4,14.5,131.9,15,131.9,15.7" />
        <path d="M91.2,10.7c2.3,0,4.5,1.7,5,4.1H85.9C86.5,12.3,88.8,10.7,91.2,10.7 M98,22c-0.4-0.4-1.1-0.4-1.5,0 c-1,1-2.5,1.9-4.8,1.9c-3.3,0-6-2.6-6.2-5.8h13.6c0.6,0,1.1-0.5,1.1-1.1c-0.1-5.5-3.9-9.6-9.2-9.6c-5.4,0-9.3,4.2-9.3,9.9 c0,5.7,4.3,9.9,9.9,9.9c3.3,0,5.7-1.2,7.3-2.7c0.5-0.4,0.4-1.2,0-1.6L98,22z" />
      </template>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String,],
      default: 300,
    },
    fill: {
      type: String,
      default: '#EB5F5E',
    },
    isLong: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    viewBox() {
      return this.isLong
        ? '0 0 143.4 29'
        : '0 0 29 29';
    }
  }
};
</script>
