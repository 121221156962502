export const DEFAULT_WIDGET_SETTINGS = {
  chartTypes: {},
  limit: 5,
  isTableShown: false,
  isChartShown: true,
};

export const DEFAULT_WIDGET = {
  fixed: false,
  order: 10000,
  width: 1,
  height: 1,
};

export const OTHER_DASHBOARD_CATEGORY = 'other';
export const DEFAULT_DASHBOARD_CATEGORY = 'custom';

export const BAR_CHART = 'bar';
export const PIE_CHART = 'pie';
export const MULTIBAR_CHART = 'multibar';
export const LINE_CHART = 'line';
