<template>
  <div class="interaction-history-container">
    <h2>{{ t('Interaction history') }}</h2>

    <div class="section-name">
      <IconBase
        :width="24"
        :height="24"
        icon-name="organisation"
        class="icon"
      />
      <h3>{{ customerObject.name }}</h3>
    </div>
    <p class="account-number">
      {{ customerObject.account_number }}
    </p>

    <div class="section-name">
      <IconBase
        :width="24"
        :height="24"
        icon-name="calendar"
        class="icon"
      />
      <h3>{{ t('Last interaction') }}:</h3>
    </div>

    <p class="last-interaction-date">
      {{ lastInteraction.interaction_type_name }}
    </p>
    <p class="last-interaction-date">
      {{ formatDate(lastInteraction.date_start, 'shortwtime') }}
    </p>
    <p
      v-if="!Object.keys(lastInteraction).length"
      class="last-interaction-date"
    >
      {{ t('There are no previous interactions for this customer') }}
    </p>
    <div
      v-if="lastInteraction.description"
      class="interaction-note-container"
    >
      <p class="section-name">
        <IconBase
          :width="24"
          :height="24"
          icon-name="notification"
          class="icon"
        />
        {{ t('Interaction note') }}:
      </p>
      <p class="interaction-note">
        {{ lastInteraction.description }}
      </p>
    </div>
    <div
      v-else
      class="interaction-note-container"
    >
      <p class="section-name">
        {{ t('No interaction notes') }}
      </p>
    </div>

    <div class="last-invoice-container">
      <div class="section-name section-name--latest-invoice">
        <div class="section-name__title">
          <IconBase
            :width="24"
            :height="24"
            icon-name="product"
            class="icon"
          />
          <h3>
            {{ t('Latest invoice') }} <span>({{ invoiceItems.length }})</span>
          </h3>
        </div>
        <RagChip
          v-if="invoiceItems.length"
          class="last-invoice-chip"
          :currency="currency"
          :value="totalInvoiceValue"
          :prefix="cs"
          :green="67"
          :amber="33"
        />
      </div>
      <ul>
        <li
          v-for="(item, index) in invoiceItems"
          v-show="index < numberOfItems"
          :key="index"
        >
          <div>
            <span>{{ item.quantity }} X {{ item.product_name }}</span>
            <span class="value">{{ cPrice(getItemValue(item.value)) }}</span>
          </div>
        </li>
      </ul>
      <CustomButton
        v-if="invoiceItems.length > 4"
        class="show-all-items-button"
        purpose="text"
        small
        :label="itemsLabel"
        @on-click="toggleAllItems"
      />
    </div>

    <div class="missed-opportunities-container">
      <div class="section-name missed-opportunities-title">
        <IconBase
          :width="24"
          :height="24"
          icon-name="dollar"
          class="icon"
        />
        <h3>
          {{ t('Missed opportunities') }} <span>({{ missedOpportunitiesNumber }})</span>
        </h3>
      </div>
      <SummaryOpportunityCard
        v-for="(item, index) in missedOpportunities"
        :key="index"
        :item="item"
        :title="item.entity_name"
        class="opportunity-card"
      >
        <template #info>
          {{ t('Missed Opportunity') }}
          <div>{{ formatDate(item.decision_date) }}</div>
          <p class="opportunity-p">
            {{ t('You sold') }}&nbsp;
            <TogglableProductsLabel
              :opportunity-item="item"
              :opportunity-type="'Antecedent'"
            />
            <br>
            {{ t('but did not sell') }}&nbsp;
            <TogglableProductsLabel
              :opportunity-item="item"
              :opportunity-type="'Consequent'"
            />
          </p>
        </template>
      </SummaryOpportunityCard>
    </div>
  </div>
</template>

<script>
import SummaryOpportunityCard from '@/intelligence/components/SnapshotReport/Summary/SummaryOpportunityCard.vue';
import { IconBase, CustomButton, RagChip } from '@sales-i/dsv3';
import { mapState } from 'vuex';
import { dates, t } from '@sales-i/utils';
import TogglableProductsLabel from '@/intelligence/components/SnapshotReport/TogglableProductsLabel.vue';
import useCurrency from '@/shared/composables/useCurrency';

export default {
  components: {
    IconBase,
    CustomButton,
    RagChip,
    SummaryOpportunityCard,
    TogglableProductsLabel,
  },
  props: {
    customerObject: {
      type: Object,
      default: () => ({}),
    },
    lastInteraction: {
      type: Object,
      default: () => ({}),
    },
    missedOpportunities: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    let { cs, currency, cPrice } = useCurrency();
    return { cs, currency, cPrice };
  },
  data() {
    return {
      numberOfItems: 4,
      itemsLabel: t('Show all items'),
      itemsExpanded: false,
    };
  },
  computed: {
    ...mapState({
      lastInvoice: state => state.intelligence.lastInvoice.lastInvoice,
    }),
    missedOpportunitiesNumber() {
      return this.missedOpportunities.length;
    },
    totalInvoiceValue() {
      return this.invoiceItems.reduce((total, item) => (total += item.value), 0);
    },
    invoiceItems() {
      return this.lastInvoice ? this.lastInvoice.items : [];
    },
  },
  watch: {
    itemsExpanded(expanded) {
      this.numberOfItems = expanded ? this.invoiceItems.length : 4;
      this.itemsLabel = expanded ? t('Hide items') : t('Show all items');
    },
  },
  methods: {
    t,
    formatDate: dates.format,
    toggleAllItems() {
      this.itemsExpanded = !this.itemsExpanded;
    },
    getItemValue(value) {
      return value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  padding: var(--spacing-3) var(--spacing-2);
  background-color: var(--colour-panel-g-2);
}

h2 {
  text-align: center;
  font-size: var(--font-size-3);
}

p {
  text-align: center;
  font-size: var(--font-size-small);
  margin-bottom: var(--spacing-2);
}

.interaction-history-container {
  width: 100%;
  border-radius: var(--border-radius-1);
  position: relative;
  background: var(--colour-panel-g-0);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
  padding: var(--spacing-2) var(--spacing-1);

  h2 {
    margin-bottom: var(--spacing-2);
  }
}

.interaction-history-container {
  padding: var(--spacing-3) 0 0;

  p {
    text-align: left;

    &.last-interaction-date {
      color: var(--colour-utility-base);
      margin-inline: 56px;
      font-size: var(--font-size-5);
    }

    &.account-number {
      margin-bottom: var(--spacing-2);
      margin-left: 56px;
    }
  }

  .section-name {
    display: flex;
    align-items: center;
    gap: var(--spacing-2);

    font-size: var(--font-size-4);
    font-weight: var(--font-weight-semibold);
    margin-inline-start: var(--spacing-2);
    margin-inline-end: var(--spacing-3);
    text-align: left;

    h3 {
      font-size: var(--font-size-4);
      font-weight: var(--font-weight-semibold);
    }
    span {
      color: var(--colour-utility-base);
      font-weight: var(--font-weight-medium);
    }
  }
  .section-name--latest-invoice {
    justify-content: space-between;
  }

  .section-name__title {
    align-items: center;
    display: flex;
    gap: var(--spacing-2);
  }

  .interaction-note-container {
    padding: var(--spacing-3) var(--spacing-5) 28px 0;
    background-color: var(--colour-panel-base);
    margin-top: var(--spacing-2);

    p.interaction-note {
      line-height: 16px;
      margin-top: var(--spacing-1);
      font-style: italic;
      margin-left: 52px;
    }
  }
  .last-invoice-container {
    margin-top: var(--spacing-2);

    .last-invoice-chip {
      font-size: var(--font-size-5);
      padding: var(--spacing-1) var(--spacing-2);
    }

    ul {
      margin-left: var(--spacing-5);

      li > div {
        display: flex;
        padding-left: var(--spacing-2);
        padding-top: var(--spacing-2);
        padding-right: var(--spacing-5);
        justify-content: space-between;

        .value {
          margin-left: var(--spacing-1);
          white-space: nowrap;
        }
      }
    }
    .show-all-items-button {
      margin: var(--spacing-2) auto var(--spacing-1);
      font-weight: var(--font-weight-semibold);
    }
  }

  .missed-opportunities-container {
    margin-top: var(--spacing-2);

    .missed-opportunities-title {
      padding-bottom: var(--spacing-2);
    }

    .opportunity-card {
      border-radius: 0;
      box-shadow: none;
    }

    .opportunity-p {
      margin-top: 6px;
    }
  }
}
</style>
