<template>
  <div class="container flow px-4">
    <div>
      <CustomCard purpose="reversed">
        <h2>{{ t('Languages') }}</h2>
        <Field
          v-bind="fields.languages"
          v-model="selectedLanguages"
        />
        <Field
          v-bind="fields.defaultLanguage"
          v-model="defaultLanguage"
          :emit-key="false"
        />
      </CustomCard>
      <CustomCard
        purpose="reversed"
        class="mt-3"
      >
        <h2>{{ t('Translations') }}</h2>
        <Field
          v-bind="fields.area"
          v-model="area"
        />
        <SearchField
          class="mt-half"
          :search-func="onSearch"
          icon-color="var(--colour-utility-action)"
          @input="onSearchInput"
        />

        <p v-if="!selectedLanguages || !selectedLanguages.length">
          {{ t('Please select at least one available language to edit translations') }}
        </p>
        <template v-else>
          <EditTranslationCard
            v-for="(fieldToTranslate, fieldIndex) in translations"
            :key="fieldIndex"
            :value="fieldToTranslate"
            :languages="selectedLanguages"
            @update="update($event, fieldIndex)"
          />
        </template>
      </CustomCard>
    </div>
  </div>
</template>

<script>
import { t } from '@sales-i/utils';
import Field from '@/shared/components/Form/Field.vue';
import { CustomCard } from '@sales-i/dsv3';
import SearchField from '@/shared/components/Search/SearchField.vue';
import { mapActions, mapGetters } from 'vuex';
import { GET_ALL } from '@/shared/store/actionType';
import EditTranslationCard from '@/admin/components/Company/Languages/EditTranslationCard.vue';

export default {
  name: 'LanguagesAndTranslations',
  components: {
    CustomCard,
    Field,
    SearchField,
    EditTranslationCard,
  },
  data() {
    return {
      /** @todo remove dummy demo data */
      selectedLanguages: [],
      defaultLanguage: undefined,
      area: 'global',
    };
  },
  computed: {
    ...mapGetters({
      languageOptions: 'admin/languages/languageOptions',
    }),
    areaOptions() {
      return [
        { text: 'Global', value: 'global' },
        { text: 'CRM', value: 'crm' },
        { text: 'Intelligence', value: 'intelligence' },
      ];
    },
    fields() {
      return {
        languages: {
          id: 'languages',
          label: 'Select available languages',
          type: 'multiselect',
          showSelected: true,
          options: this.languageOptions,
          errorMessage: 'Please select language',
        },
        defaultLanguage: {
          id: 'defaultLanguage',
          label: 'Default language',
          type: 'select',
          showSelected: true,
          options: this.languageOptions,
          errorMessage: 'Please select default language',
          twoway: true,
        },
        area: {
          id: 'area',
          label: 'Area',
          type: 'select',
          showSelected: true,
          options: this.areaOptions,
          errorMessage: 'Please select area',
          twoway: true,
        },
      };
    },
    translations() {
      /** @todo remove dummy demo data */
      return [
        {
          name: 'field1',
          data: {
            'en-gb': 'Field one',
            'en-us': 'Field 1',
          },
        },
        {
          name: 'field2',
          data: {
            'en-gb': 'Field two',
            'en-us': 'Field 2',
          },
        },
      ];
    },
  },
  async mounted() {
    await this.fetchLanguages();
    /** @todo remove dummy demo data */
    this.selectedLanguages = [this.languageOptions[0], this.languageOptions[1]];
    this.defaultLanguage = { ...this.languageOptions[0] };
  },
  methods: {
    t,
    ...mapActions({
      fetchLanguages: `admin/languages/${GET_ALL}`,
    }),
    update(value, index) {
      console.error('NEEDS PLUMBING update translation', value, index);
      this.translations[index] = value;
    },
    onSearch() {
      console.error('OnSearch NOT IMPLEMENTED');
    },
    onSearchInput() {
      console.error('OnSearch NOT IMPLEMENTED');
    },
  },
};
</script>

<style lang="scss" scoped>
.translation-row {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 0;

  .translation-input {
    flex-grow: 1;
    flex-basis: 100%;
  }

  .reverse-btn-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 50px;
  }
}

.btn-group-bg {
  background-color: var(--colour-panel-action);
  padding: var(--spacing-2);

  .button {
    max-width: 200px;
  }
}
</style>
