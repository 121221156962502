<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="relative">
    <div
      ref="textareaCover"
      :class="`textarea-cover ${!!label ? 'has-label' : ''}`"
      v-html="commentMention"
    />
    <mentionable
      :keys="['@', '＠']"
      :items="usersForMentionable"
      insert-space
      omit-key
    >
      <CustomTextarea
        :id="id"
        ref="commentRef"
        v-model="comment"
        :for="id"
        class="form-control comment"
        :label="label"
        :placeholder="placeholder"
        :value="controlledValue"
        :rows="rows"
        :max-length="maxLength"
        :required="required"
        :disabled="disabled"
        :error="error ? true : null"
        :error-message="errorMessage"
        :icon-name="icon.name"
        :icon-color="icon.color"
        :icon-align="icon.align"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @keyup="commentScroll"
        @scroll="commentScroll"
      />
      <template #item="{ item }">
        <div
          class="item"
          role="button"
        >
          <span class="font-weight-bold">
            {{ item.value }}
          </span>
        </div>
      </template>
    </mentionable>
  </div>
</template>

<script setup>
import { Mentionable } from 'vue-mention';
import { CustomTextarea } from '@sales-i/dsv3';
import { mentionRegex } from '@/shared/regex/form';
import { useStore } from 'vuex';
import { ref, computed, onBeforeUnmount, onMounted, onUnmounted } from 'vue';
import { useUsers } from '@/shared/composables/useUsers';

const props = defineProps({
  id: {
    type: [String, Number],
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  rows: {
    type: Number,
    default: 4,
  },
  maxLength: {
    type: Number,
    default: 1000,
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: '',
  },
  regex: {
    type: [RegExp, String, Boolean],
    default: false,
  },
  icon: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['input', 'focus', 'blur']);

const store = useStore();

const { usersForMentionable } = useUsers(store);

const initValue = (value = '') => {
  value = value.replace(mentionRegex, match => {
    let name = match.substr(1).split(']')[0]; // FIX ME YEAH?
    return name;
  });
  return value;
};

const controlledValue = ref(props.value);
const comment = ref(initValue(props.value));

const textareaCover = ref(null);
const commentRef = ref(null);

const commentMention = computed(() => {
  if (typeof comment.value.replaceAll !== 'function') {
    return comment;
  }
  const replaced = comment.value.replaceAll('&', '&amp;').replaceAll('>', '&gt;').replaceAll('<', '&lt;') + '\n\n';
  const search = new RegExp(
    usersForMentionable.value
      .slice()
      .sort((a, b) => b.value.length - a.value.length)
      .map(item => item.value)
      .join('|'),
    'g'
  );
  return replaced.replace(search, (match /*, offset*/) => {
    return `<span class="mention-str">${match}</span>`;
  });
});

onMounted(() => {
  if (props.label) {
    let stylesheet = document.styleSheets[0];
    if (stylesheet) {
      stylesheet.insertRule('[id^=popover_].tooltip[x-placement^="top"] { top: 30px !important; }');
    }
  }
  setTimeout(() => {
    resize();
    window.addEventListener('resize', resize);
  }, 100);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', resize);
});

onUnmounted(() => {
  if (props.label) {
    let stylesheet = document.styleSheets[0];
    if (stylesheet) {
      stylesheet.deleteRule('[id^=popover_].tooltip[x-placement^="top"] { top: 30px !important; }');
    }
  }
});

const getCommentArea = () => {
  if (!commentRef.value) return undefined;
  const el = commentRef.value.$el.children[0];
  // input element is the last in the collection (with or without label)
  return el.children[el.children.length - 1].children[0];
};

const resize = () => {
  const commentArea = getCommentArea();
  if (commentArea && commentArea.children && textareaCover.value) {
    const barWidth = commentArea.getBoundingClientRect().width - commentArea.clientWidth - 2; // border
    textareaCover.value.style.paddingRight = `calc(12px + ${barWidth}px)`;
    textareaCover.value.style.height = `${commentArea.offsetHeight}px`;
  }
};

const commentScroll = () => {
  let commentArea = getCommentArea();
  textareaCover.value.scrollTop = commentArea.scrollTop;
  resize();
};

const handleInput = value => {
  let updatedValue = replaceMentionsWithFullIds(value);
  emit('input', updatedValue);
};

const handleFocus = () => {
  emit('focus');
};

const handleBlur = () => {
  emit('blur');
};

const replaceMentionsWithFullIds = value => {
  let newValue = value;
  usersForMentionable.value.forEach(item => {
    newValue = newValue.replace(new RegExp(item.value, 'ig'), `[${item.value}](user::${item.id})`);
  });
  return newValue;
};
</script>

<style lang="scss">
[id^='popover_'].tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: #00abe7;
  color: white;
}

.textarea-cover {
  z-index: 1;
  position: absolute;
  color: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  overflow-y: hidden;
  padding: var(--spacing-1) var(--spacing-2);
  font-weight: var(--font-weight-medium);
  border: 2px solid transparent;
  &.has-label {
    top: 32px;
  }
}

.comment {
  width: 100%;
}

.mention-str {
  color: var(--colour-data-puerto-rico);
  background-color: var(--colour-utility-white);
}
</style>
