import ContactsView from '@/crm/views/Contacts/Contacts.vue';
import ViewContact from '@/crm/views/Contacts/ViewContact.vue';
import AddContact from '@/crm/views/Contacts/AddContact.vue';
import EditContact from '@/crm/views/Contacts/EditContact.vue';
import {
  contactsSection as section,
} from '@/crm/router/urlBits';

export default {
  path: section,
  component: ContactsView,
  props: { twoWayPagination: false },
  children: [
    {
      path: 'add',
      component: AddContact,
    },
    {
      path: ':contact_id',
      component: ViewContact,
      children: [
        {
          path: 'edit',
          component: EditContact,
        },
      ],
    },
  ],
};
