<template>
  <li class="selected-item">
    <IconBase
      icon-name="check"
      :height="16"
      :width="16"
    />
    <span>{{ item.value }}</span>
    <IconButton
      class="close-button"
      icon-name="close-line"
      purpose="transparent"
      icon-only
      :icon-size="16"
      @click="deleteItem"
    />
  </li>
</template>

<script>
import { IconButton, IconBase } from '@sales-i/dsv3';
export default {
  components: {
    IconBase,
    IconButton,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['deleteItem'],
  methods: {
    deleteItem() {
      this.$emit('deleteItem', this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-item {
  display: flex;
  border-top: 1px solid var(--colour-panel-g-24);
  justify-content: space-between;
  align-items: center;

  &:first-child {
    border-top: none;
  }

  span {
    font-size: var(--font-size-small);
    width: 100%;
    margin-left: var(--spacing-1);
  }
}
</style>
