/* eslint-disable */
import { SET_ITEMS, SET_UPDATED_FLAG, } from '@/shared/store/actionType';
import {
  ADD_CUSTOM_LIST_ITEM,
  GET_CUSTOM_SCHEMA,
  LOADING_CUSTOM_SCHEMA,
  UPDATE_CUSTOM_SCHEMA,
  UPDATE_CUSTOM_SCHEMA_ERROR,
  UPDATE_CUSTOM_LIST_SCHEMA,
  SAVE_CUSTOM_SCHEMA,
} from '@/admin/store/actionType';
import { httpRequest } from '@sales-i/utils';
import getSchemaId from '@/admin/store/utils/getSchemaId';
import getEntityStr from '@/admin/store/utils/getEntityStr';


export const putByEntityMutations = {
  [SET_UPDATED_FLAG]: (state, updating) => {
    state.updatesNotSaved = updating;
  },
  [UPDATE_CUSTOM_SCHEMA](state) {
    state.loading = false;
  },

  [ADD_CUSTOM_LIST_ITEM](state, payload) {
    let selectedField = payload.item;
    // Find the largest order position in the list and add the new one after that
    const orderPos = Math.max(...selectedField.list_options.map(listOption => listOption.order_pos), 0) + 1;
    selectedField.list_options.push({
      name: `Item ${orderPos}`,
      value: `Item ${orderPos}`,
      editable: true,
      deletable: true,
      order_pos: orderPos,
    });
  },

  [UPDATE_CUSTOM_LIST_SCHEMA](state, payload) {
    // Identify the section and field, then update it
    const { item, option, param, value, section } = payload;
    const { schema } = state;

    const schemaSection = schema.sections[section];

    
    // Find the field we want and update it
    item.list_options = item.list_options.map(listOption => {
      let updatedListOption = { ...listOption };

      if (listOption.name === option.name) {
        updatedListOption[param] = value;
      }

      // Set updatesNotSaved to true
      state.updatesNotSaved = true;

      return updatedListOption;
    });

    schema.sections[section] = schemaSection;

    state.schema = schema;
  },
  [SAVE_CUSTOM_SCHEMA]: () => {
  },
  [UPDATE_CUSTOM_SCHEMA_ERROR]: (state) => {
    state.loading = false;
    state.loading_menu = false;
    state.loading_section = false;
  }
};

export const putByEntityActions = {
  [SET_UPDATED_FLAG]: ({ commit, }, updating = true) => {
    commit(SET_UPDATED_FLAG, updating);
  },
  [ADD_CUSTOM_LIST_ITEM]: ({ commit }, payload) => {
    commit(ADD_CUSTOM_LIST_ITEM, payload);
  },
  [UPDATE_CUSTOM_SCHEMA]: async ({ commit, dispatch }, { entity, payload, immediateRequest }) => {
    if (!immediateRequest) return;
    try {
      const entityStr = getEntityStr(entity);
      const fullPayload = { entity: entityStr, locale: 'en_GB', sections: payload };
      await httpRequest('patch', `config/schema/${entity}`, fullPayload, { version: '2' });
      commit(UPDATE_CUSTOM_SCHEMA);
      dispatch(GET_CUSTOM_SCHEMA, { entity, fetchOnUpdate: true, refreshCall: true });
    } catch (error) {
      console.error('Error updating custom schema:', error);
      commit(UPDATE_CUSTOM_SCHEMA_ERROR);
    }
  },
  [UPDATE_CUSTOM_LIST_SCHEMA]({ commit }, payload) {
    commit(UPDATE_CUSTOM_LIST_SCHEMA, payload);
  },
  [SAVE_CUSTOM_SCHEMA]: ({ commit, state, dispatch }, { entity, name, description = '', section_id, updatedFields = [] }) => {
    commit(LOADING_CUSTOM_SCHEMA, true);
    const currentSection = state.schema.sections.find(section => section.id === section_id);
    const sectionToUpdate = {
      ...currentSection,
      fields: updatedFields.map(field => ({
        ...field,
        id: getSchemaId(field.id),
        type: field.original_type.toUpperCase(),
        name: field.system_flag === 'default' ? field.name : field.value,
      })),
      name: name || currentSection.name,
      description: description || currentSection.description
    };
    
    commit(SAVE_CUSTOM_SCHEMA, sectionToUpdate);
    dispatch(UPDATE_CUSTOM_SCHEMA, {
      entity,
      payload: [sectionToUpdate],
      immediateRequest: true
    });
  }
};
