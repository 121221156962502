<template>
  <div class="carousel-dots flex">
    <CustomButton
      v-for="index in slidesAmount"
      :key="index"
      icon-only
      purpose="neutral"
      :class="['dot', { selected: index - 1 === activeSlideIndex }]"
      tabindex="0"
      @click="selectSlide(index - 1)"
      @keydown.enter="selectSlide(index - 1)"
      @keydown.space="selectSlide(index - 1)"
    />
  </div>
</template>
  
<script setup>
import { CustomButton, } from '@sales-i/dsv3';

defineProps({
  slidesAmount: {
    type: Number,
    default: 0,
  },
  activeSlideIndex: {
    type: Number,
    default: 0,
  },
});
  
const emit = defineEmits(['update:activeSlideIndex']);
  
const selectSlide = (index) => { emit('update:activeSlideIndex', index); };
</script>

<style lang="scss" scoped>
.carousel-dots {
  justify-content: center;
  min-height: var(--spacing-2);
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;

  button.selected.dot {
    &:focus {
        background-color: var(--colour-utility-black);
    } 
  }

  .dot {
    width: var(--spacing-2);
    height: var(--spacing-2);
    border-radius: 50%;
    background-color: lightgray;
    margin: 0 var(--spacing-1);
    cursor: pointer;
    outline: none;
    
    &.selected {
      background-color: var(--colour-utility-black);
    }
  }
}
</style>
  