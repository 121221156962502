import { fieldsMutations, fieldsActions } from './fields';
import { getAllMutations, getAllActions } from './getAll';
import { getByIdMutations, getByIdActions } from './getById';
import { getSetUpdatedMutations, getSetUpdatedActions } from './getSetUpdated';
import { postMutations, postActions } from './post';
import { putByIdMutations, putByIdActions } from './putById';
import { deleteByIdMutations, deleteByIdActions } from './deleteById';

// initial state
const state = () => ({
  all: {
    data: [],
    loaded: false,
    loading: false,
  },
  selected: {
    data: {},
    updated: false,
    loaded: false,
    loading: false,
  },
  fields: [],
});

const getters = {};

// mutations
const mutations = {
  ...fieldsMutations,
  ...getAllMutations,
  ...getByIdMutations,
  ...getSetUpdatedMutations,
  ...postMutations,
  ...putByIdMutations,
  ...deleteByIdMutations,
};

// actions
const actions = {
  ...fieldsActions,
  ...getAllActions,
  ...getByIdActions,
  ...getSetUpdatedActions,
  ...postActions,
  ...putByIdActions,
  ...deleteByIdActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
