<template>
  <div class="body-wrapper">
    <div class="date-picker-header">
      <p
        v-if="isToday"
        class="text-base"
      >
        {{ dates.format(todaysDate, 'medium') }}
      </p>
    </div>
    <div class="date-time-wrapper">
      <div class="cal-wrapper">
        <div class="time-input flow">
          <div class="start-time">
            <h3 class="text-3 fw-sbld">
              {{ t('Start time') }}
            </h3>
            <div class="flex align-center">
              <div class="hour">
                <label
                  class="text-base"
                  for="start_hours"
                >
                  {{ t('Hour') }}
                  <select
                    id="start_hours"
                    v-model="startHours"
                    class="without-value icon-right"
                    @blur="updateDate"
                  >
                    <option
                      v-for="option in hourOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="px-2 pt-1">
                <strong class="text-2">:</strong>
              </div>
              <div class="min">
                <label
                  class="text-base"
                  for="start_minutes"
                >
                  {{ t('Minute') }}
                  <select
                    id="start_minutes"
                    v-model="startMinutes"
                    class="without-value icon-right"
                    @blur="updateDate"
                  >
                    <option
                      v-for="option in minuteOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
          </div>
          <div
            v-if="!isGenerate"
            class="duration"
          >
            <h3 class="text-3 fw-sbld">
              {{ t('Duration') }}
            </h3>
            <div class="flex align-center">
              <div class="hour">
                <label
                  class="text-base"
                  for="dur_hours"
                >
                  {{ t('Hour') }}
                  <select
                    id="dur_hours"
                    v-model="durationHours"
                    class="without-value icon-right"
                  >
                    <option
                      v-for="option in durationHourOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="px-2 pt-1">
                <strong class="text-2">:</strong>
              </div>
              <div class="min">
                <label
                  class="text-base"
                  for="dur_minutes"
                >
                  {{ t('Minute') }}
                  <select
                    id="dur_minutes"
                    v-model="durationMinutes"
                    :disabled="is24Hours"
                    class="without-value icon-right"
                  >
                    <option
                      v-for="option in durationMinuteOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- Date picker -->
        <div
          v-if="!isToday"
          class="cal-picker"
        >
          <vue-cal
            style="height: 260px"
            class="vuecal--date-picker"
            hide-view-selector
            :time="false"
            :transitions="false"
            active-view="month"
            xsmall
            :disable-views="['week', 'day']"
            :selected-date="interactionDate"
            :max-date="maxDate"
            :min-date="minDate"
            @cell-click="selectedDate = $event"
          />
        </div>
      </div>
      <div
        v-if="schedule === 'personal'"
        class="description-field"
      >
        <Field
          id="description"
          :value="description"
          type="textarea"
          :label="t('Description')"
          :max-length="280"
          @valid="updateDescription"
        />
      </div>
    </div>
  </div>
  <div class="form-actions">
    <CustomButton
      v-scroll-to-top="scrollToTop ? true : undefined"
      :disabled="!isValid"
      small
      @click="selectDateTime"
    >
      {{ t('Continue') }}
    </CustomButton>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import VueCal from 'vue-cal';
import { CustomButton } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field';
import { dates, t } from '@sales-i/utils';
import { SET_DATE } from '@/crm/store/actionType';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';
import { timeOptions } from '@/shared/constants/timeOptions/timeOptions';
import { navigateToUrl } from 'single-spa';

const store = useStore();

const props = defineProps({
  isEditInteraction: {
    type: Boolean,
    default: false,
  },
  isGenerate: {
    type: Boolean,
    default: false,
  },
  interactionId: {
    type: String,
    default: '',
  },
  duration: {
    type: Object,
    default: () => ({}),
  },
  descProp: {
    type: String,
    default: '',
  },
  scrollToTop: {
    type: Boolean,
    default: true,
  },
  startTime: {
    type: String,
    default: '',
  },
  dateTime: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['select', 'stageUpdate']);

const description = ref(props.descProp);
const hourOptions = ref(timeOptions.hourOptions);
const minuteOptions = ref(timeOptions.minuteOptions);
const durationHourOptions = ref(timeOptions.durationHourOptions);
const durationMinuteOptions = ref(timeOptions.durationMinuteOptions);
const selectedDate = ref(new Date());
const durationHours = ref(props.duration.hours || '');
const durationMinutes = ref(props.duration.minutes || '');
const startHours = ref('');
const startMinutes = ref('');
const schedule = ref('schedule');
const todaysDate = ref(dates.format(new Date(), 'D MMMM YYYY'));

// const currentlyEditing = computed(() => store.state.crm.interactions.currentlyEditing);

const startHoursCurrent = computed(() => store.getters['crm/interactions/startHours']);
const startMinutesCurrent = computed(() => store.getters['crm/interactions/startMinutes']);
const durationHoursCurrent = computed(() => store.getters['crm/interactions/durationHours']);
const durationMinutesCurrent = computed(() => store.getters['crm/interactions/durationMinutes']);

const interactionDate = computed(() => {
  // If startTime is false (i.e. a new interaction) then use the selectedDate
  let hours = startHours.value;
  let minutes = startMinutes.value;

  // Update the interactionDate time using the currently selected startHours and startMinutes
  if (props.isEditInteraction) {
    setSelectedDate(props.dateTime);
  }
  return props.isEditInteraction ? props.dateTime : new Date(selectedDate.value.setHours(hours, minutes));
});
const setSelectedDate = date => selectedDate.value = date;
const isToday = computed(() => schedule.value === 'today');
const durationIsZero = computed(() => (
  (durationHours.value === hourOptions.value[0].value && durationMinutes.value === minuteOptions.value[0].value) || // both values can't be 0
  (durationHours.value === hourOptions.value[0].value && !durationMinutes.value) ||
  (durationMinutes.value === minuteOptions.value[0].value && !durationHours.value) || // one value can't be 0 and the other undefined
  (!durationHours.value && !durationMinutes.value)
  // both values must be defined
));
const endDate = computed(() => {
  const start = interactionDate.value;
  const end = new Date(start);
  let durationHours = durationHours.value ? parseInt(durationHours.value, 10) : 0;
  let durationMinutes = durationMinutes.value ? parseInt(durationMinutes.value, 10) : 0;
  end.setHours(end.getHours() + durationHours);
  end.setMinutes(end.getMinutes() + durationMinutes);
  return endDate;
});
const isValid = computed(() => {
  if (durationIsZero.value) {
    return false;
  }

  return selectedDate.value ? true : false;
});
const is24Hours = computed(() => {
  return durationHours.value === '24';
});

watch(() => durationHours.value, newVal => {
  if (newVal === '24') {
    durationMinutes.value = '';
  }
});

watch(() => startHoursCurrent.value, newVal => {
  if (props.isEditInteraction) {
    startHours.value = newVal;
  }
});

// Watch for changes to the startHoursCurrent but only if it's an existing interaction
watch(() => startHoursCurrent.value, newVal => {
  if (props.isEditInteraction) {
    startHours.value = newVal;
  }
});

watch(() => startMinutesCurrent.value, newVal => {
  if (props.isEditInteraction) {
    startMinutes.value = newVal;
  }
});
    
watch(() => durationHoursCurrent.value, newVal => {
  if (props.isEditInteraction) {
    durationHours.value = newVal;
  }
});

watch(() => durationMinutesCurrent.value, newVal => {
  if (props.isEditInteraction) {
    durationMinutes.value = newVal;
  }
});

onMounted(() => {
  emit('stageUpdate', 'Timescale');
  let dt, hrs, mins;

  // if it's a new interaction, set hours and minutes to the next 5 minute interval
  if (!props.isEditInteraction) {
    dt = new Date();
    hrs = dt.getHours();
    mins = dt.getMinutes();
    minuteOptions.value.forEach((opt, i) => {
      if (mins >= parseInt(opt.value, 10)) {
        startMinutes.value = i === 11 ? minuteOptions.value[0].value : minuteOptions.value[i + 1].value;
      }
    });
    hourOptions.value.forEach((opt, i) => {
      if (startMinutes.value !== minuteOptions.value[0].value && hrs > parseInt(opt.value, 10)) {
        startHours.value = i === 23 ? hourOptions.value[0].value : hourOptions.value[i + 1].value;
      }
      if (startMinutes.value === minuteOptions.value[0].value && hrs === parseInt(opt.value, 10)) {
        startHours.value = i === 23 ? hourOptions.value[0].value : hourOptions.value[i + 1].value;
      }
    });
  }

  // If it's an existing interaction, set the dates to the values form the store
  if (props.isEditInteraction) {
    dt = new Date(props.startTime);
    startHours.value = startHoursCurrent.value;
    startMinutes.value = startMinutesCurrent.value;
  }
});

const setDate = params => store.dispatch(`crm/interactions/${SET_DATE}`, params);

const updateDate = () => {
  let startHrs = startHours.value ? parseInt(startHours.value, 10) : 0;
  let startMins = startMinutes.value ? parseInt(startMinutes.value, 10) : 0;
  let newDate = new Date(selectedDate.value);
  newDate.setHours(startHrs);
  newDate.setMinutes(startMins);
  selectedDate.value = newDate;
};

const selectDateTime = () => {
  let date = selectedDate.value;
  date.setHours(startHours.value);
  date.setMinutes(startMinutes.value);

  /*
  If the user leaves either of the duration fields blank, then the value is set to 0.
  The form validation checks that the the user has selected something for at least one of these values.
  */
  durationHours.value = durationHours.value || 0;
  durationMinutes.value = durationMinutes.value || 0;

  // If date is in the past then set the schedule to 'backdated'
  if (date < new Date()) {
    schedule.value = 'backdated';
  } else {
    schedule.value = 'schedule';
  }

  let result = {
    date: date,
    duration: {
      hours: durationHours.value,
      minutes: durationMinutes.value,
    },
    schedule: schedule.value,
    backdated: schedule.value === 'backdated',
  };

  // Add the value of result to the setDate action
  setDate(result);

  // If there's no interactionID then it's a new interaction
  if (!props.interactionId) {
    navigateToUrl(`${baseUrl}/${interactionsArea}/add/details`);
  }
  if (props.interactionId) {
    navigateToUrl(`${baseUrl}/${interactionsArea}/${props.interactionId}/edit/details`);
  }
};

const updateDescription = (field) => {
  description.value = field.value;
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.body-wrapper {
  flex: 1 1 0%;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
}
.date-time-wrapper {
  padding-inline: clamp(var(--spacing-2), 2.5vw, var(--spacing-4));
  .description-field {
    margin-top: var(--spacing-2);
  }
}
.cal-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-5);
  max-width: 1190px;
  position: relative;
}

.time-input {
  min-width: 16rem;
}

.cal-picker {
  display: block;
  margin-left: 0;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-left: var(--spacing-4);
    max-width: 15rem;
    flex-basis: 240px;
  }
}

.form-actions {
  background-color: var(--colour-panel-action);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding: var(--spacing-3);
}
</style>
