import { customSectionsSection as section } from '@/crm/router/urlBits';
import CustomSectionsList from '@/shared/components/CustomSchema/CustomSectionsList.vue';
import CustomSection from '@/shared/components/CustomSchema/CustomSection.vue';

export default {
  path: section,
  component: CustomSectionsList,
  children: [
    {
      path: ':sectionId(\\d+)',
      component: CustomSection,
    }
  ]
};
