<template>
  <div
    v-if="filter"
    class="flow"
  >
    <h3 class="flex align-center">
      {{ t('Show all customers who have been contacted in the following ways') }}
    </h3>

    <Field
      type="switch"
      class="not-switch m-0"
      :value="notContactedAtAll"
      :label="t('Not contacted at all')"
      @input="toggleNotContacted"
    />
    
    <div
      class="interaction-type-selector"
      :class="{ hideCheck: notContactedAtAll }"
    >
      <CustomCheckbox
        v-for="option in interactionTypeOptions"
        :key="option.id"
        :label="option.name"
        :value="filter.value?.includes(option.id.toString())"
        :disabled="notContactedAtAll"
        @input="toggleCheckbox(option.id.toString())"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import Field from '@/shared/components/Form/Field.vue';
import { CustomCheckbox } from '@sales-i/dsv3';

const store = useStore();

const props = defineProps({
  filter: {
    type: Object,
    default: undefined
  }
});

const emit = defineEmits(['update:filter']);

const notContactedAtAll = computed(() => Array.isArray(props.filter.value) && props.filter.value.length == 0);
const interactionTypeOptions = computed(() => store.state.admin.fields.data.type.filter(o => o.name !== 'Personal'));

// we set for not contacted is = '' and value = '' to distinguish from other selections
function toggleNotContacted(value) {
  emit('update:filter',  { 
    ...props.filter, 
    is: value ? '=' : '',
    value: value ? [] : '',
    clause: 'AND',
  });
}

function toggleCheckbox(checkboxValue) {
  const updatedValue = [...props.filter.value];
  const index = updatedValue.indexOf(checkboxValue);

  if (index === -1) {
    updatedValue.push(checkboxValue);
  } else {
    updatedValue.splice(index, 1);
  }

  emit('update:filter', { 
    ...props.filter, 
    is: '=',
    value: updatedValue?.length ? updatedValue : '', 
  });
}
</script>

<style lang="scss" scoped>
.hideCheck {
  opacity: 0.2;
}
.interaction-type-selector {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-4);
}
.not-switch {
  :deep(.form-group) {
    margin: 0;
  }

  :deep(.form-group .toggle-switch) {
    transform: scale(0.7);
    margin: 0!important;
  }

  :deep(label) {
    display: flex;
    height: 29px;
    gap: var(--spacing-1);
    align-items: center;
  }
}
</style>