import AddProspect from '@/crm/views/Prospects/AddProspect';
import EditProspect from '@/crm/views/Prospects/EditProspect';
import ProspectsDetailsView from '@/crm/views/Prospects/ProspectDetails.vue';

export default {
  path: '',
  component: ProspectsDetailsView,
  children: [
    {
      path: 'add',
      component: AddProspect,
    },
    {
      path: 'edit',
      component: EditProspect,
    },
  ],
};
