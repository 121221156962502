<template>
  <div
    ref="mapRef"
    class="map"
  />
</template>

<script setup>
// Map
import { computed, onMounted, ref } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import { t } from '@sales-i/utils';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { truncate } from '@/shared/utils/strings';

const DEFAULT_ZOOM = 10;
const myPosition = ref(undefined);

const emit = defineEmits(['markerClick', 'marker-reset']);

const props = defineProps({
  center: {
    type: Object,
    default: () => ({ lat: 0, lng: 0 }),
  },
  zoom: {
    type: Number,
    default: 10,
  },
  markers: {
    type: Array,
    default: () => [],
  },
  showInfo: {
    type: Boolean,
    default: true,
  },
  funcInfo: {
    type: Function,
    default: marker => marker.title,
  },
});

const loader = new Loader({
  apiKey: process.env.VUE_APP_GOOGLEMAPS_KEY,
  version: 'weekly',
  libraries: ['marker', 'maps', 'core'],
});

const mapOptions = computed(() => ({
  center: props.center || { lat: 0, lng: 0 },
  zoom: props.zoom || DEFAULT_ZOOM,
  mapId: 'DEMO_MAP_ID',
}));

const mapRef = ref(null);
const mapObj = ref({});
const bounds = ref(undefined);

onMounted(async () => {
  loader
    .importLibrary('maps')
    .then(({ Map, InfoWindow }) => {
      const map = new Map(mapRef.value, mapOptions.value);
      mapObj.value = map;
      let markersArr = [];

      loader.importLibrary('marker').then(({ AdvancedMarkerElement }) => {
        // Create an info window to share between markers.
        const infoWindow = props.showInfo ? new InfoWindow() : undefined;
        (props.markers || []).forEach(marker => {
          const { position, title } = marker;
          const content = document.createElement('div');
          content.className = 'map-pin';
          content.textContent = truncate(title);

          const markerElement = new AdvancedMarkerElement({ position, map, title, content });
          markersArr.push(markerElement);

          markerElement.addListener('click', () => {
            emit('markerClick', marker);

            if (props.showInfo && props.funcInfo) {
              mapObj.value.setCenter(position);
              infoWindow.close();
              infoWindow.setContent(props.funcInfo(marker));
              infoWindow.open(markerElement.map, markerElement);
            }
          });
        });

        // set pin to where you are
        setCurrentPosition(position => new AdvancedMarkerElement({ position, map }));
        CreateCustomMarkerClusterer(map, markersArr);
        CreateMapButtons();
        // set map bounds for show all
        setMapBounds(markersArr);
      });
    })
    .catch(e => {
      console.error(e);
    });
});

function CreateMapButtons() {
  // set map buttons
  const resetBtn = createMapBtn(t('Reset selected'), t('Clear current selection'), () => emit('marker-reset'));
  mapObj.value.controls[window.google.maps.ControlPosition.TOP_CENTER].push(resetBtn);
  const showAllBtn = createMapBtn(`${t('Show all')} (${props.markers.length})`, t('Click to show all pins'), showAllPins);
  mapObj.value.controls[window.google.maps.ControlPosition.TOP_CENTER].push(showAllBtn);
  if (navigator.geolocation) {
    const centerBtn = createMapBtn(t('My location'), t('Click to re-centre map on you'), setCurrentPosition);
    mapObj.value.controls[window.google.maps.ControlPosition.TOP_CENTER].push(centerBtn);
  }
}

function CreateCustomMarkerClusterer(map, markers) {
  const palette = coeff => {
    const adj = 1.0 - 0.4 * coeff;
    return `rgb(${100 * adj},${100 * adj},${255 * adj})`;
  };
  const getClusterEl = (text, colour) => {
    const parser = new DOMParser();
    // A marker with a custom inline SVG.
    const pinSvgString = `<svg fill="${colour}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
<circle cx="120" cy="120" opacity=".6" r="40" />
<circle cx="120" cy="120" opacity=".3" r="60" />
<circle cx="120" cy="120" opacity=".2" r="80" />
</svg>`;
    const pinSvg = parser.parseFromString(pinSvgString, 'image/svg+xml').documentElement;
    const content = document.createElement('div');
    content.append(pinSvg);
    content.className = 'map-cluster';
    const clusterLabel = document.createElement('div');
    clusterLabel.textContent = text;
    content.append(clusterLabel);
    return content;
  };

  // custom render function
  const renderer = {
    render: function ({ count, position }, stats) {
      const colour = palette(count / stats.clusters.markers.max);
      const content = getClusterEl(String(count), colour);
      // create marker using svg icon
      return new window.google.maps.marker.AdvancedMarkerElement({ position, content });
    },
  };
  new MarkerClusterer({ map, markers, renderer });
}

/**
 * set bounds and show all clusters by default
 */
function setMapBounds(markersArr) {
  loader.importLibrary('core').then(({ LatLngBounds }) => {
    bounds.value = new LatLngBounds();
    markersArr.forEach(m => bounds.value.extend(m.position));
    showAllPins();
  });
}

/**
 * re-fit the map
 */
function showAllPins() {
  if (bounds.value) mapObj.value.fitBounds(bounds.value, 32);
}

/**
 * sets location to user's location if it's not restricted by browser
 */
function setCurrentPosition(callbackFunc) {
  if (myPosition.value) {
    mapObj.value.setCenter(myPosition.value);
    mapObj.value.setZoom(DEFAULT_ZOOM);
  } else if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      position => {
        myPosition.value = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        mapObj.value.setCenter(myPosition.value);
        mapObj.value.setZoom(DEFAULT_ZOOM);
        if (callbackFunc) callbackFunc(myPosition.value);
      },
      () => console.error('Cannot get location from browser')
    );
  } else console.error('Cannot get location from browser');
}

/**
 * creates map button with
 * @param {*} textContent label
 * @param {*} title hint
 * @param {*} clickFunc click handler
 */
function createMapBtn(textContent, title, clickFunc) {
  const controlButton = document.createElement('button');
  controlButton.style.backgroundColor = 'var(--colour-utility-action)';
  controlButton.style.borderRadius = 'var(--border-radius-half)';
  controlButton.style.color = 'var(--colour-utility-white)';
  controlButton.style.cursor = 'pointer';
  controlButton.style.fontSize = 'var(--font-size-6)';
  controlButton.style.lineHeight = 'var(--font-size-5)';
  controlButton.style.margin = 'var(--spacing-half)';
  controlButton.style.padding = 'var(--spacing-half) var(--spacing-1)';
  controlButton.style.textAlign = 'center';
  controlButton.style.opacity = '0.85';
  controlButton.textContent = textContent;
  controlButton.title = title;
  controlButton.type = 'button';
  if (clickFunc) controlButton.addEventListener('click', clickFunc);
  return controlButton;
}
</script>

<style lang="scss">
/** global style */
.map-pin {
  border: 1px solid var(--colour-data-viking-dark);
  background-color: var(--colour-data-viking-light);
  color: var(--colour-data-viking-label);
  border-radius: var(--border-radius-half);
  padding: var(--spacing-half);
}

.map-cluster {
  position: relative;

  div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    color: var(--colour-utility-white);
    line-height: 1;
  }
}
</style>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 500px;
}
</style>
