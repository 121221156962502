<template>
  <div class="outcomes">
    <div class="heading">
      <h1>{{ detailsInfo.name }}</h1>
      <h4>{{ detailsInfo.shortTitle }}</h4>
    </div>
    <div class="wrapper">
      <BufferImage
        v-if="!reportData || (loading && !lazyLoading)"
        color="var(--colour-utility-black)"
        float="center"
        class="loading-spinner"
      />
      <TableWrapper
        v-else
        table-height="510px"
        :offset-y="offset"
        container="div"
        enable-lazy-load
        disable-shades
        :no-more-results-available="noMoreResultsAvailable"
        @set-offset="loadMoreData"
      >
        <thead>
          <tr>
            <th
              v-for="(column, i) in columns"
              :key="i"
              :class="{
                active: sortHeader === column.responseName,
                expanded: isReadMoreActive && isInteractionNote(column.responseName),
              }"
            >
              <CustomButton
                purpose="transparent"
                icon-name="arrow-down"
                icon-color="var(--colour-utility-black)"
                :icon-width="16"
                :icon-height="16"
                class="sort-btn"
                :class="sortOption"
                @click="sort(column.responseName)"
              >
                {{ t(column.title) }}
              </CustomButton>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, i) in reportData"
            :key="i"
          >
            <td
              v-for="(cell, index) in columns"
              :key="index"
            >
              <div
                v-if="!index && row.customer_id > 0"
                class="redirection"
              >
                <component
                  :is="index === 0 && ['C', 'P'].includes(row.customer_type) ? 'a' : 'span'"
                  :href="getLink(row)"
                  target="_blank"
                >
                  {{ row.customer }}
                </component>
              </div>
              <div
                v-if="isInteractionNote(cell.responseName)"
                class="expandable"
              >
                <div :class="{ short: !isReadMoreActive }">
                  {{ row[cell.responseName] }}
                </div>
                <CustomButton
                  v-if="row[cell.responseName] && row[cell.responseName].length > 25"
                  class="note-btn"
                  purpose="transparent"
                  @click="toggleNotes"
                >
                  {{ t(noteButtonText) }}
                </CustomButton>
              </div>
              <div v-else-if="cell.responseName === 'linked_opportunities'">
                <span
                  v-for="(opportunity, k) in row[cell.responseName]"
                  :key="k"
                >
                  <a
                    :href="getOpportunityLink(opportunity.id)"
                    target="_blank"
                  >
                    {{ opportunity.id }}
                  </a>
                  {{ row[cell.responseName].length - 1 > k ? ', ' : '' }}
                </span>
              </div>
              <div
                v-else
                :class="[cell.responseName, { negative: parseInt(row[cell.responseName]) < 0 }]"
              >
                {{ getCellValue(row, cell) }}
              </div>
            </td>
          </tr>
        </tbody>
      </TableWrapper>
    </div>
    <ReportFooter
      class="footer"
      :report-type="reportType"
      :sub-title="`${detailsInfo.shortTitle} - ${detailsInfo.name}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';
import { CustomButton, BufferImage } from '@sales-i/dsv3';
import { INTERACTION_OUTCOMES_DRILL } from '@/intelligence/store/data/reportTypes';
import {
  APPLY_USER,
  APPLY_OUTCOME_IDS,
  APPLY_SORT,
  APPLY_DATE_RANGE,
  FETCH_REPORT_DATA,
  CLEAR_INTERACTION_DRILL_DATA,
  APPLY_OFFSET,
  APPLY_DRILL_TYPE,
} from '@/intelligence/store/actionType';
import { GET_BY_ENTITY } from '@/shared/store/actionType';
import { baseUrl as crmBaseUrl, opportunitiesArea, customersArea, prospectsArea } from '@/crm/router/urlBits';
import { currency, dates, t } from '@sales-i/utils';
import TableWrapper from '@/shared/components/Tables/TableWrapper';

export default {
  components: {
    ReportFooter,
    CustomButton,
    BufferImage,
    TableWrapper,
  },
  props: {
    detailsInfo: {
      required: true,
      type: Object,
    },
    reportIndex: {
      required: true,
      type: Number,
    },
  },
  emits: ['handleReportIndex'],
  data() {
    return {
      reportType: INTERACTION_OUTCOMES_DRILL,
      sortDescending: true,
      isReadMoreActive: false,
      noteButtonText: 'Show',
      sortHeader: 'customer',
      pageSize: 100,
      reportData: [],
      offset: 0,
      noMoreResultsAvailable: false,
      lazyLoading: false,
      columns: [
        {
          title: 'Customer name',
          responseName: 'customer',
        },
        {
          title: 'Contact',
          responseName: 'contact',
        },
        {
          title: 'Interaction made',
          responseName: 'interaction_date_start',
        },
        {
          title: 'Notes from the interaction',
          responseName: 'interaction_note',
        },
        {
          title: 'Interaction type',
          responseName: 'interaction_type',
        },
        {
          title: 'Interaction outcome',
          responseName: 'interaction_outcome',
        },
        {
          title: 'Next action',
          responseName: 'next_action',
        },
        {
          title: 'Interaction Purpose',
          responseName: 'interaction_purpose',
        },
        {
          title: 'Account Number',
          responseName: 'customer_account_number',
        },
        {
          title: 'Customer Type',
          responseName: 'customer_type',
        },
        {
          title: 'Follow up allocated to',
          responseName: 'follow_up_assigned_to',
        },
        {
          title: 'Follow up date',
          responseName: 'follow_up_date',
        },
        {
          title: 'Call duration (minutes)',
          responseName: 'interaction_duration',
        },
        {
          title: 'Order Value',
          responseName: 'order_value',
        },
        {
          title: 'Linked Opportunities',
          responseName: 'linked_opportunities',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      cs: state => state.userDetails.cs,
      loading: state => state.intelligence.shared.loading,
      customSchema: state => state.admin.fields.data,
      filters: state => state.intelligence.performanceInsight.filters,
    }),
    ...mapGetters({
      getInputData: 'intelligence/shared/getInputData',
      getReportData: 'intelligence/shared/getReportData',
    }),
    inputData() {
      return this.getInputData(this.reportType);
    },
    sortOption() {
      return this.sortDescending ? 'desc' : 'asc';
    },
  },
  beforeUnmount() {
    this.clearDrillData();
  },
  async mounted() {
    this.sortColumn();
    this.applyDate({
      date_from: this.detailsInfo.date_from,
      date_to: this.detailsInfo.date_to,
    });
    this.applyUser(this.detailsInfo.rep_id);

    let drill_type = 'made';
    switch (this.detailsInfo.value) {
    case 'interactions_allocated':
      drill_type = 'allocated';
      break;
    }

    this.applyDrillType(drill_type);

    if (this.detailsInfo.value === 'interactions_made' && !this.filters.interaction_outcome.length) {
      await this.getFields({ entity: 'interactions' });
      this.applyOutcomeIds(this.customSchema.outcome);
    }
    await this.fetchReportData({ reportType: this.reportType });
    this.reportData = this.getReportData(this.reportIndex);
    this.checkForMoreResults();
  },
  methods: {
    t,
    ...mapActions({
      fetchReportData: `intelligence/shared/${FETCH_REPORT_DATA}`,
      clearDrillData: `intelligence/performanceInsight/${CLEAR_INTERACTION_DRILL_DATA}`,
      applySort: `intelligence/performanceInsight/${APPLY_SORT}`,
      applyDate: `intelligence/performanceInsight/${APPLY_DATE_RANGE}`,
      applyUser: `intelligence/performanceInsight/${APPLY_USER}`,
      applyOutcomeIds: `intelligence/performanceInsight/${APPLY_OUTCOME_IDS}`,
      applyDrillType: `intelligence/performanceInsight/${APPLY_DRILL_TYPE}`,
      getFields: `admin/fields/${GET_BY_ENTITY}`,
      applyOffset: `intelligence/shared/${APPLY_OFFSET}`,
    }),
    sortColumn() {
      this.applySort({
        header: this.sortHeader,
        option: this.sortOption,
      });
    },
    async sort(header) {
      if (header === this.sortHeader) {
        this.sortDescending = !this.sortDescending;
      } else {
        this.sortHeader = header;
        this.sortDescending = true;
      }

      this.offset = 0;
      this.applyOffset(this.offset);
      this.sortColumn();
      await this.fetchReportData({ reportType: this.reportType });
      this.$emit('handleReportIndex');
      this.reportData = [...this.getReportData()];
      this.checkForMoreResults();
    },
    getCellValue(row, cell) {
      if (!cell.responseName || !row[cell.responseName]) {
        return '-';
      }

      switch (cell.responseName) {
      case 'order_value':
        return `${currency.price(row[cell.responseName], this.cs)}`;
      case 'follow_up_date':
        return this.getFormattedDate(row[cell.responseName].split(' ')[0]);
      case 'interaction_date_start':
        return this.getFormattedDate(row[cell.responseName].split('T')[0]);
      case 'interaction_duration':
        return this.getFormattedNumber(parseInt(row[cell.responseName]) / 60);
      default:
        return row[cell.responseName];
      }
    },
    toggleNotes() {
      this.isReadMoreActive = !this.isReadMoreActive;
      const showText = 'Show';
      const hideText = 'Hide';

      this.noteButtonText = this.noteButtonText === showText ? hideText : showText;
    },
    isInteractionNote(columnType) {
      return columnType === 'interaction_note';
    },
    getOpportunityLink(id) {
      return `${crmBaseUrl}/${opportunitiesArea}/${id}`;
    },
    getLink(row) {
      const area = row.customer_type === 'P' ? prospectsArea : customersArea;
      return `${crmBaseUrl}/${area}/${row.customer_id}`;
    },
    getFormattedNumber(sum) {
      return Number(sum).toLocaleString('en-US', {
        maximumFractionDigits: 2,
      });
    },
    getFormattedDate(date) {
      return dates.format(date, 'DD-MM-YY');
    },
    checkForMoreResults() {
      this.noMoreResultsAvailable = this.reportData.length < this.offset + this.pageSize;
    },
    async loadMoreData() {
      this.lazyLoading = true;
      this.offset += this.pageSize;
      this.applyOffset(this.offset);
      await this.fetchReportData({ reportType: this.reportType });
      this.reportData = [...this.reportData, ...this.getReportData(this.reportIndex + 1)];
      this.$emit('handleReportIndex');
      this.checkForMoreResults();
      this.lazyLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

h1 {
  font-size: var(--font-size-5);
  line-height: initial;
  text-align: center;
  flex-basis: 100%;
  padding: var(--spacing-3) var(--spacing-3) 0;
  padding: var(--spacing-1) var(--spacing-1) 0;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: var(--font-size-2);
    padding: var(--spacing-3) var(--spacing-3) 0;
  }
}

h4 {
  font-size: var(--font-size-5);
  text-align: center;
  font-weight: var(--font-weight-regular);
  margin-bottom: var(--spacing-1);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: var(--font-size-3);
    margin-bottom: var(--spacing-2);
  }
}

table {
  width: 100%;
}

th {
  position: relative;
  border: 0;
  background: var(--colour-panel-action);
  font-size: var(--font-size-small);
  min-width: initial;
  padding: 0;
  position: sticky;
  top: 0;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: var(--font-size-5);
  }

  &.expanded {
    min-width: auto;
  }

  .sort-btn {
    &:deep(.icon) {
      display: none;
    }
  }

  &.active {
    .button {
      text-decoration: none;
      color: var(--colour-utility-black);
    }

    .sort-btn {
      &.asc {
        &:deep(.icon) {
          transform: rotate(180deg);
        }
      }

      &:deep(.icon) {
        display: block;
        position: absolute;
        right: var(--spacing-half);
        top: var(--spacing-half);
        cursor: pointer;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
          right: var(--spacing-1);
          top: var(--spacing-1);
        }
      }
    }

    .sort-arrow {
      display: block;
    }
  }

  .button,
  span {
    color: var(--colour-utility-action);
    font-weight: var(--font-weight-semibold);
    text-decoration: underline;
    text-align: left;
    padding-block: var(--spacing-2);
    padding-inline: var(--spacing-1);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding: var(--spacing-4) var(--spacing-2);
    }
  }

  :deep(.button-container) {
    margin-left: 0;
  }
}

td {
  border: 0;
  font-size: var(--font-size-5);
  padding: var(--spacing-1);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: var(--spacing-1) var(--spacing-2);
  }
}

tbody {
  tr {
    &:nth-of-type(2n) {
      // This is a rendition of the global --colour-panel-g-2 style that allows the table shadow to be visible.
      background: rgba(200, 200, 200, 0.1);
    }
  }
}

.no-results {
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.fixed {
  position: fixed;
  left: 0;
  right: 0;

  &.heading {
    top: 0;
  }

  &.footer {
    bottom: 0;
  }
}

.outcomes {
  max-height: 100%;
  overflow: hidden;
}

.footer {
  right: 0;
}

.note-btn {
  padding: 0;
  text-decoration: underline;
}

.expandable {
  display: flex;
  justify-content: space-between;
  gap: 5px;

  .short {
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

td {
  .redirection + div {
    display: none;
  }
}

.negative {
  color: var(--colour-data-mandy-label);
}
</style>
