<template>
  <div class="card-preview-container">
    <CRMCard
      :title="title"
      :chips="previewChip"
      :links="previewLinks"
      :currency="currency"
    >
      <template #info>
        {{ info }}
      </template>
      <template #tags>
        <Tag
          :id="102"
          entity="contact"
          disable-open
        />
      </template>
    </CRMCard>
    <div class="help-text-container">
      <div class="highlight-box" />
      <div class="highlight-line" />
      <span class="help-text">
        {{ helpText }}
      </span>
    </div>
  </div>
</template>

<script type="text/javascript">
import { t } from '@sales-i/utils';
import { CRMCard } from '@sales-i/dsv3';
import Tag from '@/shared/components/Tags/Tag.vue';
import useCurrency from '@/shared/composables/useCurrency';

export default {
  name: 'CardSettings',
  components: {
    CRMCard,
    Tag,
  },
  props: {
    title: {
      type: String,
      default: 'Customer',
    },
    info: {
      type: String,
      default: 'Account Number',
    },
    helpText: {
      type: String,
      default: 'Display the percentage completion of a customer profile.',
    },
  },
  setup() {
    let { cs, currency } = useCurrency();
    return { cs, currency };
  },
  data() {
    return {
      previewLinks: [{ text: t('Edit') }, { text: t('Call') }, { text: t('Email') }],
    };
  },
  computed: {
    previewChip() {
      const baseChip = {
        value: 100,
        suffix: '%',
        amber: 33,
        green: 67,
      };
      // title of Opportunity
      return this.title === 'Eric Clapton'
        ? [
          {
            value: 100000,
            prefix: this.cs,
            abbr: true,
            green: 66666,
            amber: 33333,
          },
          baseChip,
        ]
        : [baseChip];
    },
  },
};
</script>

<style lang="scss" scoped>
.card-preview-container {
  text-align: left;
  display: flex;
  :first-child {
    // CRMCard
    max-width: 360px;
  }
  .help-text-container {
    display: flex;
    position: relative;
    left: -70px;
    top: 13px;
    .highlight-box {
      height: 26px;
      width: 60px;
      border: 1px solid var(--colour-utility-black);
    }
    .highlight-line {
      position: relative;
      top: 13px;
      height: 1px;
      width: 70px;
      background-color: var(--colour-utility-black);
    }
    .help-text {
      flex: 1;
      margin-left: var(--spacing-1);
    }
  }

  :deep(.links > button) {
    cursor: default;
  }
}
</style>
