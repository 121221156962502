import { deleteByIdMutations, deleteByIdActions } from './deleteById';
import { fieldsMutations, fieldsActions } from './fields';
import { getByIdMutations, getByIdActions } from './getById';
import { getSetDefaultMutations, getSetDefaultActions } from './getSetDefault';
import { getSetUpdatedMutations, getSetUpdatedActions } from './getSetUpdated';
import { postContactMutations, postContactActions } from './post';
import { putByIdMutations, putByIdActions } from './putById';
import { getSectionMutations, getSectionActions  } from '@/shared/store/modules/customSections/getCustomSection';

// initial state
const state = () => ({
  selected: {
    data: {},
    errors: [],
    loaded: false,
    loading: false,
    updated: false,
  },
  customSection: {
    data: {},
    loaded: false,
    loading: false,
  },
  default: {
    data: {},
    loaded: false,
    loading: false,
  },
  loaded: false,
  loading: false,
  fields: [],
  entity: 'contacts',
});

// getters
const getters = {
};

// mutations
const mutations = {
  ...deleteByIdMutations,
  ...fieldsMutations,
  ...getByIdMutations,
  ...getSetDefaultMutations,
  ...getSetUpdatedMutations,
  ...postContactMutations,
  ...putByIdMutations,
  ...getSectionMutations
};

// actions
const actions = {
  ...deleteByIdActions,
  ...fieldsActions,
  ...getByIdActions,
  ...getSetDefaultActions,
  ...getSetUpdatedActions,
  ...postContactActions,
  ...putByIdActions,
  ...getSectionActions
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
