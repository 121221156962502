// Shared
export const FETCH_REPORT_DATA = 'FETCH_REPORT_DATA';
export const SAVE_REPORT_DATA = 'SAVE_REPORT_DATA';
export const CLEAR_REPORT_DATA = 'CLEAR_REPORT_DATA';
export const CLEAR_REQUEST_PARAMETERS = 'CLEAR_REQUEST_PARAMETERS';
export const PROCESS_REQUEST_PARAMETERS = 'PROCESS_REQUEST_PARAMETERS';
export const SET_LOADING = 'SET_LOADING';
export const SET_EXPANDING_REPORT = 'SET_EXPANDING_REPORT';
export const REFRESH_REPORT = 'REFRESH_REPORT';
export const GENERATE_NEW_REPORT_ID = 'GENERATE_NEW_REPORT_ID';
export const SET_ACTIVE_COLUMNS = 'SET_ACTIVE_COLUMNS';

// Calendar Module
export const FETCH_DATES = 'FETCH_DATES';
export const FETCH_DATES_WO_STORE = 'FETCH_DATES_WO_STORE';
export const SAVE_DATES = 'SAVE_DATES';
export const FETCH_CALENDAR = 'FETCH_CALENDAR';
export const CLEAR_DATES = 'CLEAR_DATES';

// Last Invoice
export const FETCH_LAST_INVOICE = 'FETCH_LAST_INVOICE';

// Template Reports Module
export const APPLY_NEW_SORT = 'APPLY_NEW_SORT';
export const APPLY_DATE_RANGE = 'APPLY_DATE_RANGE';
export const SET_REQUEST_PARAM = 'SET_REQUEST_PARAM';
export const APPLY_NEW_FILTER = 'APPLY_NEW_FILTER';
export const EXPORT_REPORT = 'EXPORT_REPORT';
export const EXPORT_REPORT_FULL_PICTURE = 'EXPORT_REPORT_FULL_PICTURE';
export const EXPORT_REPORT_SALES_VS_GAPS = 'EXPORT_REPORT_SALES_VS_GAPS';
export const EXPORT_REPORT_MATRIX = 'EXPORT_REPORT_MATRIX';
export const EXPORT_REPORT_VARIANCE = 'EXPORT_REPORT_VARIANCE';
export const GENERATE_FILE_ID = 'GENERATE_FILE_ID';
export const GENERATE_FILE_ID_FULL_PICTURE = 'GENERATE_FILE_ID_FULL_PICTURE';
export const GENERATE_FILE_ID_SALES_VS_GAPS = 'GENERATE_FILE_ID_SALES_VS_GAPS';
export const GENERATE_FILE_ID_MATRIX = 'GENERATE_FILE_ID_MATRIX';
export const GENERATE_FILE_ID_VARIANCE = 'GENERATE_FILE_ID_VARIANCE';

// Enquiry Module
export const APPLY_AXIS = 'APPLY_AXIS';
export const APPLY_DIMS = 'APPLY_DIMS';
export const TOGGLE_DRILL = 'TOGGLE_DRILL';

export const TOGGLE_SELECT_BUBBLES_POPUP = 'TOGGLE_SELECT_BUBBLES_POPUP';
export const DISABLE_SELECT_BUBBLES_POPUP = 'DISABLE_SELECT_BUBBLES_POPUP';

export const SET_SORTING_DATA = 'SET_SORTING_DATA';
export const APPLY_OFFSET = 'APPLY_OFFSET';
export const APPLY_OFFSET_X = 'APPLY_OFFSET_X';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_CRM_FILTERS = 'SET_CRM_FILTERS';
export const CLEAR_APPLIED_CRM_FILTERS = 'CLEAR_APPLIED_CRM_FILTERS';
export const SET_SALES_FILTERS = 'SET_SALES_FILTERS';
export const CLEAR_APPLIED_SALES_FILTERS = 'CLEAR_APPLIED_SALES_FILTERS';
export const RESET_ALL_DATA_CLAUSES = 'RESET_ALL_DATA_CLAUSES';
export const DELETE_FILTER = 'DELETE_FILTER';
export const SET_ADVANCED_FILTERS = 'SET_ADVANCED_FILTERS';
export const DELETE_ADVANCED_FILTER = 'DELETE_ADVANCED_FILTER';
export const APPLY_FILTERS_TO_QUERY = 'APPLY_FILTERS_TO_QUERY';
export const APPLY_ADVANCED_FILTERS_TO_QUERY = 'APPLY_ADVANCED_FILTERS_TO_QUERY';
export const APPLY_CRM_FILTERS_TO_QUERY = 'APPLY_CRM_FILTERS_TO_QUERY';
export const APPLY_SALES_FILTERS_TO_QUERY = 'APPLY_SALES_FILTERS_TO_QUERY';
export const APPLY_LIMIT = 'APPLY_LIMIT';
export const SET_PERIOD_FILTER = 'SET_PERIOD_FILTER';
export const CLEAR_ENQUIRY_FILTERS = 'CLEAR_ENQUIRY_FILTERS';
export const APPLY_MEASURE = 'APPLY_MEASURE';
export const TOGGLE_VARIANCE_PLUS = 'TOGGLE_VARIANCE_PLUS';
export const SET_SHOW_GAPS = 'SET_SHOW_GAPS';

export const SALES_DATA_CLAUSE = 'SALES_DATA_CLAUSE';
export const CRM_DATA_CLAUSE = 'CRM_DATA_CLAUSE';
export const PRODUCT_DATA_CLAUSE = 'PRODUCT_DATA_CLAUSE';

// Bubbles Module
export const FETCH_BUBBLE_DATA = 'FETCH_BUBBLE_DATA';
export const FETCH_THROWAWAY_BUBBLE_DATA = 'FETCH_THROWAWAY_BUBBLE_DATA';
export const SAVE_BUBBLE_DATA = 'SAVE_BUBBLE_DATA';
export const SAVE_BUBBLE_AREAS = 'SAVE_BUBBLE_AREAS';
export const CLEAR_BUBBLE_AREAS = 'CLEAR_BUBBLE_AREAS';
export const SET_DIMENSIONS_NUMBER = 'SET_DIMENSIONS_NUMBER';
export const FETCH_BUBBLE_VALUES = 'FETCH_BUBBLE_VALUES';
export const SAVE_BUBBLE_VALUES = 'SAVE_BUBBLE_VALUES';

// Opportunities module
export const DISMISS_OPPORTUNITY = 'DISMISS_OPPORTUNITY';

// Performance Insight Module
export const FETCH_STAGES_DATA = 'FETCH_STAGES_DATA';
export const FETCH_CHART_DATA = 'FETCH_CHART_DATA';
export const FETCH_TABLE_DATA = 'FETCH_TABLE_DATA';
export const APPLY_NEW_OPPORTUNITY = 'APPLY_NEW_OPPORTUNITY';
export const APPLY_NEW_DATE_RANGE = 'APPLY_NEW_DATE_RANGE';
export const SET_FILTER_TITLE = 'SET_FILTER_TITLE';
export const APPLY_SORT = 'APPLY_SORT';
export const APPLY_USER = 'APPLY_USER';
export const APPLY_OUTCOME_IDS = 'APPLY_OUTCOME_IDS';
export const CLEAR_INTERACTION_DRILL_DATA = 'CLEAR_INTERACTION_DRILL_DATA';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const APPLY_REPORT_TYPE = 'APPLY_REPORT_TYPE';
export const SET_ANALYSIS_FIELD = 'SET_ANALYSIS_FIELD';

// Report Lazy Load
export const SAVE_REPORT_PARAMETERS = 'SAVE_REPORT_PARAMETERS';
export const RESET_REPORT_PARAMETERS = 'RESET_REPORT_PARAMETERS';

// Metadata Module
export const FETCH_METADATA = 'FETCH_METADATA';
export const SET_METADATA = 'SET_METADATA';

// Drill types
export const APPLY_DRILL_TYPE = 'APPLY_DRILL_TYPE';

// Targets module
export const FETCH_TARGET_PERIODS = 'FETCH_TARGET_PERIODS';
export const SAVE_TARGET_PERIODS = 'SAVE_TARGET_PERIODS';
export const FETCH_MEASUREMENTS = 'FETCH_MEASUREMENTS';
export const SAVE_MEASUREMENTS = 'SAVE_MEASUREMENTS';
export const TOGGLE_TARGETS_SWITCH = 'TOGGLE_TARGETS_SWITCH';
export const ENABLE_TARGETS = 'ENABLE_TARGETS';
export const SAVE_CURRENT_VALUES = 'SAVE_CURRENT_VALUES';
export const SAVE_CURRENT_DIMENSIONS = 'SAVE_CURRENT_DIMENSIONS';
export const CLEAR_CURRENT_TARGET = 'CLEAR_CURRENT_TARGET';
export const SAVE_WHOLE_DIM = 'SAVE_WHOLE_DIM';

// Query Builder
export const ADD_MANY_PRODUCTS_TO_QUERY = 'ADD_MANY_PRODUCTS_TO_QUERY';
export const ADD_PRODUCT_TO_QUERY = 'ADD_PRODUCT_TO_QUERY';
export const ADD_PRODUCT_TO_FILTER = 'ADD_PRODUCT_TO_FILTER';
export const EDIT_PRODUCT_IN_FILTER = 'EDIT_PRODUCT_IN_FILTER';
export const REMOVE_COMMUNITY_FROM_QUERY = 'REMOVE_COMMUNITY_FROM_QUERY';
export const REMOVE_PRODUCT_FROM_QUERY = 'REMOVE_PRODUCT_FROM_QUERY';
export const REPLACE_PRODUCTS_IN_QUERY = 'REPLACE_PRODUCTS_IN_QUERY';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export const CLEAR_BUYING_PRODUCTS = 'CLEAR_BUYING_PRODUCTS';
export const CLEAR_NOT_BUYING_PRODUCTS = 'CLEAR_NOT_BUYING_PRODUCTS';

export const CHANGE_PRODUCT_STATUS = 'CHANGE_PRODUCT_STATUS';
export const UPDATE_PRODUCT_FILTER = 'UPDATE_PRODUCT_FILTER';
export const UPDATE_PRODUCT_FILTER_IN_QUERY = 'UPDATE_PRODUCT_FILTER_IN_QUERY';

export const ADD_SALES_FILTER_TO_QUERY = 'ADD_SALES_FILTER_TO_QUERY';
export const REMOVE_SALES_FILTER_FROM_QUERY = 'REMOVE_SALES_FILTER_FROM_QUERY';
export const UPDATE_SALES_FILTER_IN_QUERY = 'UPDATE_SALES_FILTER_IN_QUERY';
export const REMOVE_ALL_SALES_FILTER_FROM_QUERY = 'REMOVE_ALL_SALES_FILTER_FROM_QUERY';
export const GET_SALES_FILTER_OPTIONS = 'GET_SALES_FILTER_OPTIONS';

export const ADD_CUSTOMER_FILTER_TO_QUERY = 'ADD_CUSTOMER_FILTER_TO_QUERY';
export const REMOVE_CUSTOMER_FILTER_FROM_QUERY = 'REMOVE_CUSTOMER_FILTER_FROM_QUERY';
export const UPDATE_CUSTOMER_FILTER_IN_QUERY = 'UPDATE_CUSTOMER_FILTER_IN_QUERY';
export const REMOVE_ALL_CUSTOMER_FILTER_FROM_QUERY = 'REMOVE_ALL_CUSTOMER_FILTER_FROM_QUERY';
export const REMOVE_ALL_FILTERS_FROM_QUERY = 'REMOVE_ALL_FILTERS_FROM_QUERY';
export const UPDATE_CUSTOMER_FILTER_TYPE = 'UPDATE_CUSTOMER_FILTER_TYPE';
export const UPDATE_OPERATION_STATUS = 'UPDATE_OPERATION_STATUS';

export const ADD_BUBBLE = 'ADD_BUBBLE';
export const REMOVE_BUBBLE = 'REMOVE_BUBBLE';

export const ADD_FILTER_ENTITY = 'ADD_FILTER_ENTITY';
export const UPDATE_FILTER_ENTITY = 'UPDATE_FILTER_ENTITY';
export const REMOVE_FILTER_ENTITY = 'REMOVE_FILTER_ENTITY';
export const UPDATE_DATE_RANGE = 'UPDATE_DATE_RANGE';
export const QUERY_BUILDER_VARIANCE_OUTPUT = 'QUERY_BUILDER_VARIANCE_OUTPUT';