import {
  DELETE_BY_ENTITY,
  DELETE_BY_ENTITY_LOADING,
  DELETE_BY_ENTITY_ERROR
} from '@/shared/store/actionType';
import { httpRequest, pluralise } from '@sales-i/utils';

// mutations
export const deleteByEntityMutations = {
  [DELETE_BY_ENTITY](state, payload) {
    state.entity.data = payload;
    state.entity.loaded = true;
    state.entity.loading = false;
  },
  [DELETE_BY_ENTITY_LOADING]: (state) => {
    state.entity.loaded = false;
    state.entity.loading = true;
  },
  [DELETE_BY_ENTITY_ERROR]: (state) => {
    state.entity.loaded = true;
    state.entity.loading = false;
  },
};

// actions
export const deleteByEntityActions = {
  [DELETE_BY_ENTITY]: async ({ commit, state, }, { id, tags, entity, }) => {
    const options = entity === 'file' ? { area: 'files' } : {};
    try {// Build the URL for the tags call
      const requestUrl = `${pluralise.string(entity)}/${id}/tags`;
      // Fetch the list of tags for a customer, and return the promise
      commit(DELETE_BY_ENTITY_LOADING);
      await httpRequest('delete', requestUrl, tags, {
        version: '2', ...options
      });
      let keys = tags.map(item => (item.tag));
      let existing = state.entity.data.filter(item => !keys.includes(item.tag));
      commit(DELETE_BY_ENTITY, existing);
    } catch (error) {
      commit(DELETE_BY_ENTITY_ERROR, error);
    }
  },
};
