import {
  GET_ALL,
  GET_ALL_LOADING,
  GET_ALL_ERROR,
  CLEAR_ALL,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import querystring from 'querystring';

// FOR DEMO PURPOSES, REMOVE AFTER
import useJwt from '@/shared/composables/useJwt';
const { isNapa } = useJwt();

const falseResponse = (params) => {
  const responseOptions = {
    'MISSED': [
      {
        'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
        'id': null,
        'entity_id': 164727,
        'entity_type': 'customer',
        'entity_name': 'Auto Doc',
        'contact_id': null,
        'contact_name': '',
        'allocated_users': [],
        'value': 735.01,
        'business_area_id': 963,
        'probability': 0,
        'decision_date': '2022-11-15T00:00:00Z',
        'last_step': '',
        'next_step': '',
        'stage_id': 966,
        'status': 'open',
        'products': [
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 1466,
            'prod_code': 'NBO.NB.48880279',
            'product_name': 'BRAKE ROTOR',
            'prod_category': 'Antecedent',
            'confidence': 75,
            'value': 39.3,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 1466,
            'prod_code': 'SRB.SR.SR914',
            'product_name': 'DISC PAD',
            'prod_category': 'Consequent',
            'confidence': 81,
            'value': 27.8,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
        ],
        'type': params.type,
        'dismissed': false,
        'dismissed_for': '',
        'dismissed_reason': '',
        'editable': true,
        'created_date': '2022-10-18T15:38:06.695+01:00',
        'updated_date': '2023-03-30T14:54:57.925984+01:00',
        'created_by': 'intelligence_user',
        'updated_by': 'Andrew Lowther',
        'created_by_id': '00000000-0000-0000-0000-000000000000',
        'updated_by_id': '8ff32e94-4648-4de2-bab8-13ea3eeae32a',
        'percent_complete': 20
      },
      {
        'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
        'id': null,
        'entity_id': 3443236,
        'entity_type': 'customer',
        'entity_name': 'Hold It Tight Transport',
        'contact_id': null,
        'contact_name': '',
        'allocated_users': [],
        'value': 735.01,
        'business_area_id': 963,
        'probability': 0,
        'decision_date': '2022-11-15T00:00:00Z',
        'last_step': '',
        'next_step': '',
        'stage_id': 966,
        'status': 'open',
        'products': [
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 1466,
            'prod_code': 'MOT.MOB.124315',
            'product_name': 'MOBIL 1 5W30 QT',
            'prod_category': 'Antecedent',
            'confidence': 79,
            'value': 20.0,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 1466,
            'prod_code': 'STN.SHE.550045126',
            'product_name': 'ROT T4 15W40 _1GL',
            'prod_category': 'Consequent',
            'confidence': 76,
            'value': 64.7,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
        ],
        'type': params.type,
        'dismissed': false,
        'dismissed_for': '',
        'dismissed_reason': '',
        'editable': true,
        'created_date': '2022-10-18T15:38:06.695+01:00',
        'updated_date': '2023-03-30T14:54:57.925984+01:00',
        'created_by': 'intelligence_user',
        'updated_by': 'Andrew Lowther',
        'created_by_id': '00000000-0000-0000-0000-000000000000',
        'updated_by_id': '8ff32e94-4648-4de2-bab8-13ea3eeae32a',
        'percent_complete': 20
      },
      {
        'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
        'id': null,
        'entity_id': 164727,
        'entity_type': 'customer',
        'entity_name': 'Auto Doc',
        'contact_id': null,
        'contact_name': '',
        'allocated_users': [],
        'value': 735.01,
        'business_area_id': 963,
        'probability': 0,
        'decision_date': '2022-11-15T00:00:00Z',
        'last_step': '',
        'next_step': '',
        'stage_id': 966,
        'status': 'open',
        'products': [
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 1466,
            'prod_code': 'RQR.RNR.NR2370B',
            'product_name': 'RADIATOR',
            'prod_category': 'Antecedent',
            'confidence': 85,
            'value': 40.3,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 1466,
            'prod_code': 'NAF.NAF.1GAL',
            'product_name': 'NAPA ANTIFREEZE GAL',
            'prod_category': 'Consequent',
            'confidence': 70,
            'value': 80.0,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
        ],
        'type': params.type,
        'dismissed': false,
        'dismissed_for': '',
        'dismissed_reason': '',
        'editable': true,
        'created_date': '2022-10-18T15:38:06.695+01:00',
        'updated_date': '2023-03-30T14:54:57.925984+01:00',
        'created_by': 'intelligence_user',
        'updated_by': 'Andrew Lowther',
        'created_by_id': '00000000-0000-0000-0000-000000000000',
        'updated_by_id': '8ff32e94-4648-4de2-bab8-13ea3eeae32a',
        'percent_complete': 20
      },
      {
        'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
        'id': null,
        'entity_id': 164727,
        'entity_type': 'customer',
        'entity_name': 'Auto Doc',
        'contact_id': null,
        'contact_name': '',
        'allocated_users': [],
        'value': 735.01,
        'business_area_id': 963,
        'probability': 0,
        'decision_date': '2022-11-15T00:00:00Z',
        'last_step': '',
        'next_step': '',
        'stage_id': 966,
        'status': 'open',
        'products': [
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 1466,
            'prod_code': 'RQR.RNR.NR2370B',
            'product_name': 'RADIATOR',
            'prod_category': 'Antecedent',
            'confidence': 87,
            'value': 56.8,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 1466,
            'prod_code': 'NAF.NAF.RTU1EXT',
            'product_name': 'RTU EXT/LIFE GAL',
            'prod_category': 'Consequent',
            'confidence': 82,
            'value': 25.2,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
        ],
        'type': params.type,
        'dismissed': false,
        'dismissed_for': '',
        'dismissed_reason': '',
        'editable': true,
        'created_date': '2022-10-18T15:38:06.695+01:00',
        'updated_date': '2023-03-30T14:54:57.925984+01:00',
        'created_by': 'intelligence_user',
        'updated_by': 'Andrew Lowther',
        'created_by_id': '00000000-0000-0000-0000-000000000000',
        'updated_by_id': '8ff32e94-4648-4de2-bab8-13ea3eeae32a',
        'percent_complete': 20
      },
    ],
    'MISSEDGRP': [
      {
        'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
        'id': null,
        'entity_id': 164727,
        'entity_type': 'customer',
        'entity_name': 'Auto Doc',
        'contact_id': null,
        'contact_name': '',
        'allocated_users': [],
        'value': 597.45,
        'business_area_id': 963,
        'probability': 0,
        'decision_date': '2022-11-15T00:00:00Z',
        'last_step': '',
        'next_step': '',
        'stage_id': 966,
        'status': 'open',
        'products': [
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 1466,
            'prod_code': '997',
            'product_name': 'Napa Brake Rotor',
            'prod_category': 'Antecedent',
            'confidence': 65,
            'value': 103,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 368,
            'prod_code': '780',
            'product_name': 'Napa Brake Pads',
            'prod_category': 'Consequent',
            'confidence': 74,
            'value': 203.6,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
        ],
        'type': params.type,
        'dismissed': false,
        'dismissed_for': '',
        'dismissed_reason': '',
        'editable': true,
        'created_date': '2022-10-18T15:38:06.695+01:00',
        'updated_date': '2022-10-18T15:38:06.695+01:00',
        'created_by': 'intelligence_user',
        'updated_by': 'intelligence_user',
        'created_by_id': '00000000-0000-0000-0000-000000000000',
        'updated_by_id': '00000000-0000-0000-0000-000000000000',
        'percent_complete': 0
      },
      {
        'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
        'id': null,
        'entity_id': 3443236,
        'entity_type': 'customer',
        'entity_name': 'Hold It Tight Transport',
        'contact_id': null,
        'contact_name': '',
        'allocated_users': [],
        'value': 597.45,
        'business_area_id': 963,
        'probability': 0,
        'decision_date': '2022-11-15T00:00:00Z',
        'last_step': '',
        'next_step': '',
        'stage_id': 966,
        'status': 'open',
        'products': [
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 1466,
            'prod_code': '998',
            'product_name': 'Motor Oil',
            'prod_category': 'Antecedent',
            'confidence': 80,
            'value': 76.0,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 368,
            'prod_code': '779',
            'product_name': 'HD Oil Filters and LD Oil Filters',
            'prod_category': 'Consequent',
            'confidence': 84,
            'value': 205.1,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
        ],
        'type': params.type,
        'dismissed': false,
        'dismissed_for': '',
        'dismissed_reason': '',
        'editable': true,
        'created_date': '2022-10-18T15:38:06.695+01:00',
        'updated_date': '2022-10-18T15:38:06.695+01:00',
        'created_by': 'intelligence_user',
        'updated_by': 'intelligence_user',
        'created_by_id': '00000000-0000-0000-0000-000000000000',
        'updated_by_id': '00000000-0000-0000-0000-000000000000',
        'percent_complete': 0
      },
      {
        'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
        'id': null,
        'entity_id': 116602,
        'entity_type': 'customer',
        'entity_name': 'Purdy\'s Auto Parts & Salvage',
        'contact_id': null,
        'contact_name': '',
        'allocated_users': [],
        'value': 597.45,
        'business_area_id': 963,
        'probability': 0,
        'decision_date': '2022-11-15T00:00:00Z',
        'last_step': '',
        'next_step': '',
        'stage_id': 966,
        'status': 'open',
        'products': [
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 1466,
            'prod_code': '998',
            'product_name': 'Motor Oil',
            'prod_category': 'Antecedent',
            'confidence': 78,
            'value': 56.9,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 368,
            'prod_code': '778',
            'product_name': 'LD Oil Filters',
            'prod_category': 'Consequent',
            'confidence': 87,
            'value': 306.4,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
        ],
        'type': params.type,
        'dismissed': false,
        'dismissed_for': '',
        'dismissed_reason': '',
        'editable': true,
        'created_date': '2022-10-18T15:38:06.695+01:00',
        'updated_date': '2022-10-18T15:38:06.695+01:00',
        'created_by': 'intelligence_user',
        'updated_by': 'intelligence_user',
        'created_by_id': '00000000-0000-0000-0000-000000000000',
        'updated_by_id': '00000000-0000-0000-0000-000000000000',
        'percent_complete': 0
      },
      {
        'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
        'id': null,
        'entity_id': 116602,
        'entity_type': 'customer',
        'entity_name': 'Purdy\'s Auto Parts & Salvage',
        'contact_id': null,
        'contact_name': '',
        'allocated_users': [],
        'value': 597.45,
        'business_area_id': 963,
        'probability': 0,
        'decision_date': '2022-11-15T00:00:00Z',
        'last_step': '',
        'next_step': '',
        'stage_id': 966,
        'status': 'open',
        'products': [
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 1466,
            'prod_code': '999',
            'product_name': 'Napa Radiators',
            'prod_category': 'Antecedent',
            'confidence': 70,
            'value': 29.4,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 368,
            'prod_code': '777',
            'product_name': 'Napa Antifreeze',
            'prod_category': 'Consequent',
            'confidence': 73,
            'value': 176.7,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
        ],
        'type': params.type,
        'dismissed': false,
        'dismissed_for': '',
        'dismissed_reason': '',
        'editable': true,
        'created_date': '2022-10-18T15:38:06.695+01:00',
        'updated_date': '2022-10-18T15:38:06.695+01:00',
        'created_by': 'intelligence_user',
        'updated_by': 'intelligence_user',
        'created_by_id': '00000000-0000-0000-0000-000000000000',
        'updated_by_id': '00000000-0000-0000-0000-000000000000',
        'percent_complete': 0
      },
      {
        'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
        'id': null,
        'entity_id': 119900,
        'entity_type': 'customer',
        'entity_name': 'P & W Service & Repair',
        'contact_id': null,
        'contact_name': '',
        'allocated_users': [],
        'value': 597.45,
        'business_area_id': 963,
        'probability': 0,
        'decision_date': '2022-11-15T00:00:00Z',
        'last_step': '',
        'next_step': '',
        'stage_id': 966,
        'status': 'open',
        'products': [
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 1466,
            'prod_code': '1000',
            'product_name': 'Brake Calipers',
            'prod_category': 'Antecedent',
            'confidence': 65,
            'value': 107.4,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
          {
            'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
            'id': 368,
            'prod_code': '776',
            'product_name': 'Brake Pads',
            'prod_category': 'Consequent',
            'confidence': 65,
            'value': 374.2,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2022-10-18T15:38:30.436+01:00',
            'updated_date': '2022-10-18T15:38:30.436+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          },
        ],
        'type': params.type,
        'dismissed': false,
        'dismissed_for': '',
        'dismissed_reason': '',
        'editable': true,
        'created_date': '2022-10-18T15:38:06.695+01:00',
        'updated_date': '2022-10-18T15:38:06.695+01:00',
        'created_by': 'intelligence_user',
        'updated_by': 'intelligence_user',
        'created_by_id': '00000000-0000-0000-0000-000000000000',
        'updated_by_id': '00000000-0000-0000-0000-000000000000',
        'percent_complete': 0
      },
    ],
    'POTENTIALGRP': [],
    'POTENTIAL': [
      {
        'tenant_id': '00902939-d9b1-4bbd-80f5-9d2cc8e586f7',
        'id': 1275,
        'entity_id': 3217357,
        'entity_type': 'customer',
        'entity_name': 'MARRS LOGGING',
        'contact_id': null,
        'contact_name': '',
        'allocated_users': [],
        'value': 14.49,
        'business_area_id': 963,
        'probability': 0,
        'decision_date': null,
        'last_step': '',
        'next_step': '',
        'stage_id': 966,
        'status': 'open',
        'products': [
          {
            'tenant_id': '00902939-d9b1-4bbd-80f5-9d2cc8e586f7',
            'id': 5860,
            'prod_code': 'GAT.GAT.G251700606',
            'product_name': 'HYD/HOSE FITTINGS',
            'prod_category': 'POTENTIAL',
            'confidence': 0,
            'value': 14.49,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2023-06-07T10:58:14.479+01:00',
            'updated_date': '2023-06-07T10:58:14.479+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          }
        ],
        'type': 'POTENTIAL',
        'dismissed': false,
        'dismissed_for': '',
        'dismissed_reason': '',
        'editable': true,
        'created_date': '2023-06-07T10:57:37.69+01:00',
        'updated_date': '2023-06-07T10:57:37.69+01:00',
        'created_by': 'intelligence_user',
        'updated_by': 'intelligence_user',
        'created_by_id': '00000000-0000-0000-0000-000000000000',
        'updated_by_id': '00000000-0000-0000-0000-000000000000',
        'percent_complete': 0
      },
      {
        'tenant_id': '00902939-d9b1-4bbd-80f5-9d2cc8e586f7',
        'id': 1276,
        'entity_id': 164014,
        'entity_type': 'customer',
        'entity_name': 'East Tn Farms',
        'contact_id': null,
        'contact_name': '',
        'allocated_users': [],
        'value': 14.49,
        'business_area_id': 963,
        'probability': 0,
        'decision_date': null,
        'last_step': '',
        'next_step': '',
        'stage_id': 966,
        'status': 'open',
        'products': [
          {
            'tenant_id': '00902939-d9b1-4bbd-80f5-9d2cc8e586f7',
            'id': 5855,
            'prod_code': 'GAT.GAT.G251700606',
            'product_name': 'HYD/HOSE FITTINGS',
            'prod_category': 'POTENTIAL',
            'confidence': 0,
            'value': 14.49,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2023-06-07T10:58:14.479+01:00',
            'updated_date': '2023-06-07T10:58:14.479+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          }
        ],
        'type': 'POTENTIAL',
        'dismissed': false,
        'dismissed_for': '',
        'dismissed_reason': '',
        'editable': true,
        'created_date': '2023-06-07T10:57:37.69+01:00',
        'updated_date': '2023-06-07T10:57:37.69+01:00',
        'created_by': 'intelligence_user',
        'updated_by': 'intelligence_user',
        'created_by_id': '00000000-0000-0000-0000-000000000000',
        'updated_by_id': '00000000-0000-0000-0000-000000000000',
        'percent_complete': 0
      },
      {
        'tenant_id': '00902939-d9b1-4bbd-80f5-9d2cc8e586f7',
        'id': 1277,
        'entity_id': 176339,
        'entity_type': 'customer',
        'entity_name': '0432DCF6324FC6A34A6AD7E5CD5F4E9F',
        'contact_id': null,
        'contact_name': '',
        'allocated_users': [],
        'value': 14.49,
        'business_area_id': 963,
        'probability': 0,
        'decision_date': null,
        'last_step': '',
        'next_step': '',
        'stage_id': 966,
        'status': 'open',
        'products': [
          {
            'tenant_id': '00902939-d9b1-4bbd-80f5-9d2cc8e586f7',
            'id': 5859,
            'prod_code': 'GAT.GAT.G251700606',
            'product_name': 'HYD/HOSE FITTINGS',
            'prod_category': 'POTENTIAL',
            'confidence': 0,
            'value': 14.49,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2023-06-07T10:58:14.479+01:00',
            'updated_date': '2023-06-07T10:58:14.479+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          }
        ],
        'type': 'POTENTIAL',
        'dismissed': false,
        'dismissed_for': '',
        'dismissed_reason': '',
        'editable': true,
        'created_date': '2023-06-07T10:57:37.69+01:00',
        'updated_date': '2023-06-07T10:57:37.69+01:00',
        'created_by': 'intelligence_user',
        'updated_by': 'intelligence_user',
        'created_by_id': '00000000-0000-0000-0000-000000000000',
        'updated_by_id': '00000000-0000-0000-0000-000000000000',
        'percent_complete': 0
      },
      {
        'tenant_id': '00902939-d9b1-4bbd-80f5-9d2cc8e586f7',
        'id': 1278,
        'entity_id': 176494,
        'entity_type': 'customer',
        'entity_name': 'FALL BRANCH AUTO SERVICE',
        'contact_id': null,
        'contact_name': '',
        'allocated_users': [],
        'value': 14.49,
        'business_area_id': 963,
        'probability': 0,
        'decision_date': null,
        'last_step': '',
        'next_step': '',
        'stage_id': 966,
        'status': 'open',
        'products': [
          {
            'tenant_id': '00902939-d9b1-4bbd-80f5-9d2cc8e586f7',
            'id': 5864,
            'prod_code': 'GAT.GAT.G251700606',
            'product_name': 'HYD/HOSE FITTINGS',
            'prod_category': 'POTENTIAL',
            'confidence': 0,
            'value': 14.49,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2023-06-07T10:58:14.479+01:00',
            'updated_date': '2023-06-07T10:58:14.479+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          }
        ],
        'type': 'POTENTIAL',
        'dismissed': false,
        'dismissed_for': '',
        'dismissed_reason': '',
        'editable': true,
        'created_date': '2023-06-07T10:57:37.69+01:00',
        'updated_date': '2023-06-07T10:57:37.69+01:00',
        'created_by': 'intelligence_user',
        'updated_by': 'intelligence_user',
        'created_by_id': '00000000-0000-0000-0000-000000000000',
        'updated_by_id': '00000000-0000-0000-0000-000000000000',
        'percent_complete': 0
      },
      {
        'tenant_id': '00902939-d9b1-4bbd-80f5-9d2cc8e586f7',
        'id': 1279,
        'entity_id': 150643,
        'entity_type': 'customer',
        'entity_name': '3 GEN, LLC',
        'contact_id': null,
        'contact_name': '',
        'allocated_users': [],
        'value': 14.49,
        'business_area_id': 963,
        'probability': 0,
        'decision_date': null,
        'last_step': '',
        'next_step': '',
        'stage_id': 966,
        'status': 'open',
        'products': [
          {
            'tenant_id': '00902939-d9b1-4bbd-80f5-9d2cc8e586f7',
            'id': 5879,
            'prod_code': 'GAT.GAT.G251700606',
            'product_name': 'HYD/HOSE FITTINGS',
            'prod_category': 'POTENTIAL',
            'confidence': 0,
            'value': 14.49,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2023-06-07T10:58:14.479+01:00',
            'updated_date': '2023-06-07T10:58:14.479+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          }
        ],
        'type': 'POTENTIAL',
        'dismissed': false,
        'dismissed_for': '',
        'dismissed_reason': '',
        'editable': true,
        'created_date': '2023-06-07T10:57:37.69+01:00',
        'updated_date': '2023-06-07T10:57:37.69+01:00',
        'created_by': 'intelligence_user',
        'updated_by': 'intelligence_user',
        'created_by_id': '00000000-0000-0000-0000-000000000000',
        'updated_by_id': '00000000-0000-0000-0000-000000000000',
        'percent_complete': 0
      },
      {
        'tenant_id': '00902939-d9b1-4bbd-80f5-9d2cc8e586f7',
        'id': 1274,
        'entity_id': 158278,
        'entity_type': 'customer',
        'entity_name': 'S \u0026 M GARAGE',
        'contact_id': null,
        'contact_name': '',
        'allocated_users': [],
        'value': 14.49,
        'business_area_id': 963,
        'probability': 0,
        'decision_date': null,
        'last_step': '',
        'next_step': '',
        'stage_id': 966,
        'status': 'open',
        'products': [
          {
            'tenant_id': '00902939-d9b1-4bbd-80f5-9d2cc8e586f7',
            'id': 5826,
            'prod_code': 'GAT.GAT.G251700606',
            'product_name': 'HYD/HOSE FITTINGS',
            'prod_category': 'POTENTIAL',
            'confidence': 0,
            'value': 14.49,
            'dismissed': false,
            'dismissed_for': '',
            'dismissed_reason': '',
            'created_date': '2023-06-07T10:58:14.479+01:00',
            'updated_date': '2023-06-07T10:58:14.479+01:00',
            'created_by': 'intelligence_user',
            'updated_by': 'intelligence_user',
            'created_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e',
            'updated_by_id': 'bccd122d-7967-4f8e-8865-7fafe6504d6e'
          }
        ],
        'type': 'POTENTIAL',
        'dismissed': false,
        'dismissed_for': '',
        'dismissed_reason': '',
        'editable': true,
        'created_date': '2023-06-07T10:57:37.69+01:00',
        'updated_date': '2023-06-07T10:57:37.69+01:00',
        'created_by': 'intelligence_user',
        'updated_by': 'intelligence_user',
        'created_by_id': '00000000-0000-0000-0000-000000000000',
        'updated_by_id': '00000000-0000-0000-0000-000000000000',
        'percent_complete': 0
      }
    ]
  };
  return responseOptions[params.type];
};
// END

const getRolledUpValue = (item) => {
  let value = 0;
  const productsLength = item.products.length;

  for (let x = 0; x < productsLength; x++) {
    value += item.products[x].value;
  }

  return value;
};

export const getAllGetters = {
  getSortedOpportunities: (state) => {
    const opportunities = [];

    // Add the rolled up value to each opportunity
    (state.data || []).forEach(i => {
      if ('products' in i) {
        const valueAddedOpp = {
          ...i,
          value: getRolledUpValue(i),
        };
        opportunities.push(valueAddedOpp);
      }
    });

    return opportunities.sort((a, b) => {
      if (a.value > b.value) {
        return -1;
      } else if (b.value > a.value) {
        return 1;
      }
      return 0;
    });
  },
  getSortedOpportunitiesByDecisionDate: (state) => {
    const opportunities = [];

    // Add the rolled up value to each opportunity
    const data = Array.isArray(state.data) ? state.data : [];
    data.forEach(i => {
      if ('products' in i) {
        const valueAddedOpp = {
          ...i,
          value: getRolledUpValue(i),
        };
        opportunities.push(valueAddedOpp);
      }
    });

    return opportunities.sort((a, b) => {
      if (a.decision_date > b.decision_date) {
        return -1;
      } else if (b.decision_date > a.decision_date) {
        return 1;
      }
      return 0;
    });
  },
};

export const getAllMutations = {
  [GET_ALL]: (state, data) => {
    state.data = data || [];
    state.loaded = true;
    state.loading = false;
  },
  [GET_ALL_LOADING]: (state) => {
    state.loaded = false;
    state.loading = true;
  },
  [GET_ALL_ERROR]: (state) => {
    state.loaded = false;
    state.loading = false;
  },
  [CLEAR_ALL]: (state) => {
    state.data = [];
    state.loaded = false;
    state.loading = false;
  },
};

export const getAllActions = {
  [GET_ALL]: async ({ commit }, params) => {
    try {
      let response = [];
      commit(GET_ALL_LOADING);
      const replaceFirstPage = await isNapa();
      if (replaceFirstPage) {
        if (params.offset === 0) {
          response = falseResponse(params);
        } else {
          params.offset = params.offset - params.limit;
          const stringParams = querystring.stringify(params);
          response = await httpRequest('get', `opportunities/with-products?${stringParams}`, {}, {
            version: 2
          });
        }
      } else {
        const stringParams = querystring.stringify(params);
        response = await httpRequest('get', `opportunities/with-products?${stringParams}`, {}, {
          version: 2
        });
      }
      commit(GET_ALL, response);
    } catch (e) {
      commit(GET_ALL_ERROR);
    }
  },
  [CLEAR_ALL]: ({ commit }) => {
    commit(CLEAR_ALL);
  }
};
