import {
  RESET_REPORT_PARAMETERS,
  SAVE_REPORT_PARAMETERS,
} from '@/intelligence/store/actionType';

export default function useLazyLoading({ store } = {}) {
  const saveReportParameters = (params) => store.dispatch(`intelligence/reportLazyLoad/${SAVE_REPORT_PARAMETERS}`, params);
  const resetReportParameters = (params) => store.dispatch(`intelligence/reportLazyLoad/${RESET_REPORT_PARAMETERS}`, params);
  
  return {
    saveReportParameters,
    resetReportParameters,
  };
}