<template>
  <div>
    <CustomCard
      v-for="item in items"
      :key="item.setting"
      purpose="alert"
      class="item"
    >
      <div class="header">
        <h4>{{ item.setting }}</h4>

        <div class="grow" />

        <div class="controls">
          <Field
            id="display"
            type="checkbox"
            :label="t('Display')"
            @input="() => {}"
          />
        </div>
      </div>
      <div class="details">
        <CardSettings
          :title="item.card.title"
          :info="item.card.info"
          :help-text="item.card.helpText"
        />
      </div>
    </CustomCard>
  </div>
</template>

<script type="text/javascript">
// import { mapActions, mapState, mapGetters } from 'vuex';
import { t } from '@sales-i/utils';
import { CustomCard } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field.vue';
import CardSettings from '@/admin/components/Settings/CardSettings.vue';

export default {
  name: 'CardSettingsList',
  components: {
    CustomCard,
    Field,
    CardSettings,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: { t },
};
</script>

<style lang="scss" scoped>
.item {
  padding: 0;
  margin-bottom: var(--spacing-4);
  .header {
    padding: var(--spacing-2);
    display: flex;

    .grow {
      flex-grow: 1;
    }

    .controls {
      display: flex;
      align-items: center;
      .field {
        margin-right: var(--spacing-2);
      }
    }
  }
  .details {
    margin-top: 0;
    padding: var(--spacing-3) var(--spacing-2);
    background-color: var(--colour-panel-g-2);
  }
}
</style>
