<template>
  <CustomCard
    purpose="reversed"
    class="stages"
  >
    <LoadingOverlay
      v-show="loading"
      style="border-radius: var(--border-radius-1)"
    />
    <Stages
      :value="stages"
      :loading="loading"
      :name="t('Stages')"
      @add="add"
      @move="move"
      @edit="edit"
      @delete="del"
      @save="patch"
    >
      <template #top />
    </Stages>
    <ConfirmationModal
      v-if="isDeleteModal"
      :heading="deleteModalProps.heading"
      :message="deleteModalProps.message"
      :updated-heading="deleteModalProps.updatedHeading"
      :updated-message="deleteModalProps.updatedMessage"
      :save-label="deleteModalProps.saveLabel"
      :cancel-label="deleteModalProps.cancelLabel"
      :updated-label="deleteModalProps.updatedLabel"
      :error-save-label="deleteModalProps.errorSaveLabel"
      :error-cancel-label="deleteModalProps.errorCancelLabel"
      :icon-name="deleteModalProps.iconName"
      :icon-color="deleteModalProps.iconColor"
      :close-on-update="deleteModalProps.closeOnUpdate"
      :update-function="deleteModalProps.updateFunction"
      :finish-function="deleteModalProps.finishFunction"
      :close-function="deleteModalProps.closeFunction"
      :cancel-function="deleteModalProps.cancelFunction"
      :error-heading="deleteModalProps.errorHeading"
      :error-message="deleteModalProps.errorMessage"
      :save-button-options="saveButtonOptions"
    >
      <Field
        v-model="to_stage_id"
        :label="t('Select a stage for existing opportunities')"
        type="select"
        :options="stageOptions"
        required
        twoway
      />
    </ConfirmationModal>
  </CustomCard>
</template>

<script type="text/javascript">
import { mapActions, mapGetters, mapState } from 'vuex';
import { CustomCard, LoadingOverlay } from '@sales-i/dsv3';
import { t } from '@sales-i/utils';
import Stages from '@/admin/components/Opportunities/Stages.vue';
import { GET_STAGES, CLEAR_STAGES, PATCH_STAGES, SET_STAGES, ADD_STAGE, MOVE_STAGE, EDIT_STAGE, DELETE_STAGE } from '@/admin/store/actionType';
import Field from '@/shared/components/Form/Field.vue';
import ConfirmationModal from '@/shared/components/Modal/ConfirmationModal.vue';

export default {
  name: 'OpportunitiesStagesView',
  components: {
    Stages,
    CustomCard,
    LoadingOverlay,
    Field,
    ConfirmationModal,
  },
  emits: ['closeModal'],
  data() {
    return {
      deleteModalProps: {},
      isDeleteModal: false,
      idToDelete: undefined,
      to_stage_id: undefined,
    };
  },
  computed: {
    ...mapState({
      stages: state => state.admin.opportunities.stages.data || [],
      loading: state => !!state.admin.opportunities.stages.loading,
    }),
    ...mapGetters({
      allStageOptions: 'admin/opportunities/stageOptions',
    }),
    stageOptions() {
      return this.allStageOptions.filter(s => s.value != this.idToDelete);
    },
    saveButtonOptions() {
      return {
        disabled: !(this.idToDelete && this.to_stage_id),
      };
    },
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    t,
    ...mapActions({
      fetch: `admin/opportunities/${GET_STAGES}`,
      clear: `admin/opportunities/${CLEAR_STAGES}`,
      set: `admin/opportunities/${SET_STAGES}`,
      patch: `admin/opportunities/${PATCH_STAGES}`,
      add: `admin/opportunities/${ADD_STAGE}`,
      move: `admin/opportunities/${MOVE_STAGE}`,
      edit: `admin/opportunities/${EDIT_STAGE}`,
      deleteStage: `admin/opportunities/${DELETE_STAGE}`,
    }),
    del(payload) {
      this.idToDelete = payload.item.id;
      this.deleteModalProps = {
        message:
          t(`Do you really want to delete Stage ${payload.index + 1} - ${payload.item.name}?`, '', {
            interpolations: {
              number: payload.index + 1,
              name: payload.item.name,
            },
          }) || t('No label'),
        updatedMessage: `You have deleted Stage ${payload.index + 1}`,
        updateFunction: () => this.confirmDelete(),
        finishFunction: () => {
          this.$emit('closeModal');
          this.fetch();
          return true;
        },
        closeFunction: () => {
          this.deleteModalProps = {};
          this.idToDelete = undefined;
          this.to_stage_id = undefined;
          this.isDeleteModal = false;
        },
      };
      this.isDeleteModal = true;
    },
    async confirmDelete() {
      try {
        const result = await this.deleteStage({
          id: +this.idToDelete,
          to_stage_id: +this.to_stage_id,
        });
        this.$emit('closeModal');
        return result;
      } catch (error) {
        console.error('Error confirming delete:', error);
        throw error;
      }
    },
    async input(items) {
      await this.set(items);
    },
  },
};
</script>

<style lang="scss" scoped>
.stages {
  position: relative;
}
</style>
