<template>
  <div
    class="accordion-container"
    :class="accordionSize"
  >
    <div
      class="heading"
      role="button"
      tabindex="0"
      @click="toggleAccordion"
      @keydown.enter="toggleAccordion"
    >
      <IconBase
        :class="isAccordionOpened ? 'chevron-up' : 'chevron-down'"
        :height="iconSize"
        :width="iconSize"
        :icon-color="isAccordionOpened ? 'var(--colour-utility-black)' : 'var(--colour-data-deluge)'"
        icon-name="chevron-down"
      />
      <h3 :class="{ active: isAccordionOpened }">
        {{ title }}
      </h3>
      <slot name="header" />
    </div>

    <div
      v-if="isAccordionOpened"
      :class="{ expanded: isAccordionOpened }"
      class="content"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import { IconBase } from '@sales-i/dsv3';
import breakpoints from '@/shared/utils/breakpoints';

export default {
  components: { IconBase },
  inject: ['mq'],
  props: {
    accordionSize: {
      type: String,
      default: 'big',
    },
    title: {
      type: String,
      required: true,
    },
    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggleAccordion'],
  data() {
    return {
      isAccordionOpened: false,
    };
  },
  computed: {
    iconSize() {
      return breakpoints.mdAndDown.includes(this.mq) ? 32 : this.desktopIconSize;
    },
    desktopIconSize() {
      switch (this.accordionSize) {
      case 'small':
        return 32;
      case 'big':
        return 48;
      default:
        return 16;
      }
    },
  },
  watch: {
    isOpened(value) {
      this.isAccordionOpened = value;
    },
  },
  mounted() {
    this.isAccordionOpened = this.isOpened;
  },
  methods: {
    toggleAccordion() {
      this.isAccordionOpened = !this.isAccordionOpened;
      this.$emit('toggleAccordion', this.isAccordionOpened);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.accordion-container {
  width: 100%;
  margin: auto;
  border-radius: var(--border-radius-1);
  position: relative;
  margin-bottom: var(--spacing-2);
  background: var(--colour-panel-g-0);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);

  &.big {
    .heading {
      padding: var(--spacing-1);

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        padding: var(--spacing-1) var(--spacing-4);
      }

      h3 {
        font-size: var(--font-size-small);
        line-height: var(--font-size-small);

        @media #{map-get($display-breakpoints, 'md-and-up')} {
          font-size: var(--font-size-3);
          line-height: var(--font-size-2);
        }
      }
    }
  }

  &.small {
    .heading {
      padding: var(--spacing-1) var(--spacing-half);

      h3 {
        font-size: var(--font-size-4);
        line-height: var(--font-size-3);
      }
    }
  }
}

.heading {
  display: flex;
  align-items: center;
  cursor: pointer;

  h3 {
    color: var(--colour-utility-action);
    text-decoration: underline;

    &.active {
      color: var(--colour-utility-black);
      text-decoration: none;
    }
  }
}

.svg-container {
  transition-duration: 0.5s;
  transition-property: transform;
  align-self: center;

  &.chevron-up {
    transform: rotate(180deg);
  }

  &.chevron-down {
    transform: rotate(0deg);
  }
}

.content {
  &.expanded {
    position: relative;

    &:before {
      content: '';
      display: block;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, var(--colour-utility-black) 100%);
      height: var(--spacing-1);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
}
</style>
