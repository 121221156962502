// Data visualisation types
export const DATA_VISUALISATION_PIE_CHART = 'PieChart';
export const DATA_VISUALISATION_MULTI_BAR = 'MultiBarChart';
export const DATA_VISUALISATION_LINE_BAR = 'LineBarChart';
export const DATA_VISUALISATION_TABLE_ONLY = '';

// Table types
export const TABLE_TYPE_TEMPLATE_REPORT = 'TemplateReportTable';
export const TABLE_TYPE_TRENDS = 'TrendsTable';
export const TABLE_TYPE_VARIANCE = 'VarianceTable';
export const TABLE_TYPE_MATRIX = 'MatrixTable';

// Template Report chart colors
export const TEMPLATE_REPORT_CHART_COLOR_1 = '--colour-data-de-york';
export const TEMPLATE_REPORT_CHART_COLOR_2 = '--colour-data-deluge';
export const TEMPLATE_REPORT_CHART_COLOR_3 = '--colour-data-barberry';

export const TEMPLATE_REPORT_CHART_COLOR_1_FADED = '--colour-data-de-york-light';
export const TEMPLATE_REPORT_CHART_COLOR_2_FADED = '--colour-data-deluge-light';
export const TEMPLATE_REPORT_CHART_COLOR_3_FADED = '--colour-data-barberry-light';

export const TEMPLATE_REPORT_CHART_COLOR_1_CLICKED = '--colour-data-de-york-dark';
export const TEMPLATE_REPORT_CHART_COLOR_2_CLICKED = '--colour-data-deluge-dark';
export const TEMPLATE_REPORT_CHART_COLOR_3_CLICKED = '--colour-data-barberry-dark';

export const TEMPLATE_REPORT_CHART_COLOR_SETS = {
  1: [
    TEMPLATE_REPORT_CHART_COLOR_1,
    TEMPLATE_REPORT_CHART_COLOR_1_FADED,
    TEMPLATE_REPORT_CHART_COLOR_1_CLICKED
  ],
  2: [
    TEMPLATE_REPORT_CHART_COLOR_2,
    TEMPLATE_REPORT_CHART_COLOR_2_FADED,
    TEMPLATE_REPORT_CHART_COLOR_2_CLICKED
  ],
  3: [
    TEMPLATE_REPORT_CHART_COLOR_3,
    TEMPLATE_REPORT_CHART_COLOR_3_FADED,
    TEMPLATE_REPORT_CHART_COLOR_3_CLICKED
  ]
};
