import {
  GET_CUSTOMER_SEGMENTS,
  GET_CUSTOMER_SEGMENTS_LOADING,
  GET_CUSTOMER_SEGMENTS_ERROR,
} from '@/aiml/store/actionType';
import { httpRequest } from '@sales-i/utils';

export const getCustomerSegmentsMutations = {
  [GET_CUSTOMER_SEGMENTS]: (state, data) => {
    state.data = data;
    state.errors = [];
    state.loading = false;
    state.loaded = true;
  },
  [GET_CUSTOMER_SEGMENTS_LOADING]: (state, e) => {
    state.data = [];
    state.errors = [e];
    state.loading = false;
    state.loaded = true;
  },
  [GET_CUSTOMER_SEGMENTS_ERROR]: (state, e) => {
    state.data = [];
    state.errors = [e];
    state.loading = false;
    state.loaded = true;
  }
};

export const getCustomerSegmentsActions = {
  [GET_CUSTOMER_SEGMENTS]: async ({ commit }, id) => {
    commit(GET_CUSTOMER_SEGMENTS_LOADING);

    try {
      const response = await httpRequest('get', `segmentation/${id}`, {}, {
        version: '2',
        area: 'churn',
      });

      commit(GET_CUSTOMER_SEGMENTS, response);
    } catch (e) {
      commit(GET_CUSTOMER_SEGMENTS_ERROR, e);
    }
  },
};