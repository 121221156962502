// chartsMixin
import { debounce } from '@sales-i/utils';
import { mapGetters } from 'vuex';

export default {
  props: {
    reportId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      currentParams: '',
      tableData: '',
    };
  },
  computed: {
    ...mapGetters({
      getReportData: 'intelligence/shared/getReportData',
    }),
    fetchedData() {
      return this.getReportData(this.reportId);
    },
    chartWidth() {
      return this.currentParams.svgWidth - this.currentParams.margin.left - this.currentParams.margin.right;
    },
    chartHeight() {
      return this.currentParams.svgHeight - this.currentParams.margin.top - this.currentParams.margin.bottom;
    },
    containerResizeObserver() {
      return new ResizeObserver(this.debounceChartResize);
    },
    debounceChartResize() {
      return debounce(this.handleChartResize, 250);
    }
  },
  mounted() {
    this.tableData = Array.isArray(this.fetchedData) ? [...this.fetchedData] : {...this.fetchedData};
    this.containerResizeObserver.observe(this.$refs.container);
  },
  unmounted() {
    this.containerResizeObserver.disconnect();
    this.removeChart();
  },
  methods: {
    handleChartResize() {
      this.removeChart();
      this.generateChart();
    },
  },
};