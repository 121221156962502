import {
  GET_MENTIONS,
  DISMISS_MENTION,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

// initial state
const state = () => ({
  data: [],
});

// getters
const getters = {};

// mutations
const mutations = {
  [GET_MENTIONS]: (state, data) => {
    state.data = data;
  },
  [DISMISS_MENTION]: (state, index) => {
    state.data.splice(index, 1);
  }
};

// actions
const actions = {
  [GET_MENTIONS]: async ({ commit, }, parameters) => {
    try {
      const response = await httpRequest('get', `${process.env.VUE_APP_API_V2_ENDPOINT}/alerts/v2/`, parameters, { throwErrors: false });
      const data = response;
      commit(GET_MENTIONS, data);
    } catch (error) {
      commit(GET_MENTIONS, []);
    }
  },
  [DISMISS_MENTION]: async ({ commit, }, payload) => {
    try {
      const response = await httpRequest('put', `${process.env.VUE_APP_API_V2_ENDPOINT}/alerts/v2/alerts`, payload);
      const data = response;
      commit(DISMISS_MENTION, data);
    } catch (error) {
      commit(DISMISS_MENTION, []);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
