import {
  DELETE_BY_ID,
  DELETE_BY_ID_LOADING,
  DELETE_BY_ID_ERROR,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const deleteByIdMutations = {
  [DELETE_BY_ID]: (state) => {
    state.loaded = true;
    state.loading = false;
  },
  [DELETE_BY_ID_LOADING]: (state) => {
    state.loaded = false;
    state.loading = true;
  },
  [DELETE_BY_ID_ERROR]: (state) => {
    state.loaded = true;
    state.loading = false;
  },
};

// actions
export const deleteByIdActions = {
  [DELETE_BY_ID]: async ({ commit, }, idArr) => {
    try {
      commit(DELETE_BY_ID_LOADING);
      for (let i = 0, len = idArr.length; i < len; i++) {
        await httpRequest('delete', `subjects/${idArr[i]}`, {}, { area: 'iam' });
      }
      commit(DELETE_BY_ID);
    } catch (error) {
      console.error(error);
      commit(DELETE_BY_ID_ERROR);
      return false;
    }
  },
};
