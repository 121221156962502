<template>
  <li 
    v-if="customerList.length > 1"
    id="selectAll"
    :class="{ highlighted: isAllSelected }"
  >
    <CustomCheckbox
      class="checkbox"
      :value="isAllSelected"
      @input="selectAllCustomers"
    >
      {{ t('All') }}
    </CustomCheckbox>
  </li>
  <li
    v-for="customer in customerList"
    :key="customer.id"
    :class="{ highlighted: customer.checked }"
  >
    <CustomCheckbox
      :id="customer.id"
      :value="customer.checked"
      class="checkbox"
      :disabled="customer.disabled"
      @input="handleCustomerSelection(customer, $event)"
    >
      {{ customer.text }}
    </CustomCheckbox>
  </li>
</template>

<script>
import { CustomCheckbox } from '@sales-i/dsv3';
import { t } from '@sales-i/utils';

export default {
  name: 'GenerateInteractionsForm',
  components: {
    CustomCheckbox,
  },
  props: {
    customerList: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['input', 'valid', 'customerSelection'],
  data() {
    return {
      selectedCustomers: [],
      selectAll: false,
    };
  },
  computed: {
    isAllSelected() {
      return this.customerList.every(customer => customer.checked);
    },
  },
  methods: {
    t,
    selectAllCustomers(event) {
      this.customerList.forEach(customer => { customer.checked = event; });
      this.selectedCustomers = event ? this.customerList.map(customer => customer.id) : [];
      this.$emit('customerSelection', this.selectedCustomers);
    },
    handleCustomerSelection(customer, event) {
      customer.checked = event;
      if (event) {
        if (!this.selectedCustomers.includes(customer.id)) {
          this.selectedCustomers.push(customer.id);
        }
      } else {
        this.selectedCustomers = this.selectedCustomers.filter(id => id !== customer.id);
      }
      this.$emit('customerSelection', this.selectedCustomers);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

li {
  position: relative;
  height: 36px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:nth-child(even) {
    background-color: var(--colour-panel-g-4);
  }

  &.highlighted {
    background: var(--colour-utility-black);
    color: var(--colour-utility-white);

    label {
      color: var(--colour-utility-white);
    }
  }
}

label {
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
}

:deep(.checkbox-container) {
  background: var(--colour-data-puerto-rico);
  padding: var(--spacing-half) var(--spacing-1);
}
</style>
