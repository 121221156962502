<template>
  <div class="intro-wrapper flow">
    <h2>
      {{ t(`${actionText} CRM data`, 'action_crm_data', { interpolations: { action: actionText } }) }}
    </h2>
    <p>{{ dateFilter }}</p>
  </div>
</template>

<script setup>
import { t } from '@sales-i/utils';

defineProps({
  actionText: {
    type: String,
    default: ''
  },
  dateFilter: {
    type: String,
    default: ''
  }
});
</script>