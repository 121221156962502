import { baseUrl, insightsScope, performanceScope, peopleScope } from '@/intelligence/router/urlBits';
import InsightsView from '@/intelligence/views/Insights/InsightsView.vue';
import InteractionOutcomesView from '@/intelligence/views/Insights/InteractionOutcomes/InteractionOutcomesView.vue';
import PeopleView from '@/intelligence/views/Insights/People/PeopleView.vue';
import InteractionSpendSummaryView from '@/intelligence/views/Insights/InteractionSpendSummary/InteractionSpendSummaryView.vue';
import TreeMapView from '@/intelligence/views/TreeMap/TreeMap.vue';
import { INTERACTION_OUTCOMES, INTERACTION_SPEND_SUMMARY } from '@/intelligence/store/data/reportTypes';
import { REPORT_AREA_PERFORMANCE } from '@/intelligence/store/data/areas';

export default [
  {
    path: `${baseUrl}/${insightsScope}`,
    redirect: `${baseUrl}/${insightsScope}/${REPORT_AREA_PERFORMANCE}`,
  },
  {
    path: `${baseUrl}/${insightsScope}/:insight`,
    component: InsightsView,
    name: insightsScope
  },
  {
    path: `${baseUrl}/${insightsScope}/tree-map`,
    component: TreeMapView,
    name: 'tree-map'
  },
  {
    path: `${baseUrl}/${insightsScope}/${performanceScope}/${INTERACTION_OUTCOMES}`,
    component: InteractionOutcomesView,
    name: INTERACTION_OUTCOMES,
  },
  {
    path: `${baseUrl}/${insightsScope}/${peopleScope}`,
    component: PeopleView,
    name: peopleScope
  },
  {
    path: `${baseUrl}/${insightsScope}/${performanceScope}/${INTERACTION_SPEND_SUMMARY}`,
    component: InteractionSpendSummaryView,
    name: INTERACTION_SPEND_SUMMARY
  },
];
