<template>
  <CustomButton
    v-if="isValid"
    href="javascript:;"
    @click.prevent="addrFuncs.showOnMap(props.value)"
  >
    <template v-if="addressAsString.length > 0">
      <div
        v-for="(part, index) in address"
        :key="index"
      >
        {{ part }}
      </div>
    </template>
    <div v-else>
      {{ t('Show map') }}
    </div>
  </CustomButton>
</template>

<script setup>
// Address
import { computed } from 'vue';
import { CustomButton } from '@sales-i/dsv3';
import { address as addrFuncs, t } from '@sales-i/utils';

const props = defineProps({
  value: {
    type: Object,
    default: () => ({}),
  },
});

const address = computed(() => {
  const { address_line_1, address_line_2, address_line_3, address_line_4, town, county, post_code, country } = props.value;
  return { address_line_1, address_line_2, address_line_3, address_line_4, town, county, post_code, country };
});

const isValid = computed(() => addrFuncs.isValid(props.value));
const addressAsString = computed(() => addrFuncs.asString(props.value));
</script>
