<template>
  <vue-cal
    active-view="month"
    style="height: 260px"
    class="vuecal--date-picker"
    hide-view-selector
    xsmall
    :disable-views="['week', 'day']"
    :locale="locale"
    :selected-date="selectedDate"
    :time="false"
    :transitions="false"
    :events="summary"
    :min-date="minDate"
    :max-date="maxDate"
    @cell-click="setSelectedDate"
    @view-change="updateMonth"
  >
    <template #cell-content="{ cell, view, events }">
      {{ cell.content }}
      <span
        v-if="view.id === 'month' && events.length"
        class="vuecal__cell-events-count"
        :class="{ 'past-event': isPastEvent(events) }"
      >
        {{ events.length }}
      </span>
    </template>
  </vue-cal>
</template>

<script setup>
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';

defineProps({
  locale: {
    type: String,
    default: 'en',
  },
  summary: {
    type: Array,
    default: () => [],
  },
  selectedDate: {
    type: Date,
    default: () => new Date(),
  },
  minDate: {
    type: Date,
    default: () => null,
  },
  maxDate: {
    type: Date,
    default: () => null,
  }
});

const emit = defineEmits(['setSelectedDate', 'updateMonth']);

function setSelectedDate(event) {
  emit('setSelectedDate', event);
}

function updateMonth(view) {
  emit('updateMonth', {
    dateFrom: view.startDate,
    dateTo: view.endDate,
  });
}

function isPastEvent(events) {
  let missed = true;
  events.forEach(event => {
    if (event.open) missed = false;
  });
  return missed;
}
</script>

<style lang="scss">
@import '@/shared/assets/scss/_variables';
.calendar-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-2);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    justify-content: flex-end;
    margin-bottom: initial;
  }
}
.calendar-controls__show-calendar {
  display: flex;
  align-items: center;
  justify-content: center;

  .button {
    height: 50px !important;
    width: 50px !important;
  }

  &:hover > .button {
    background-color: var(--colour-utility-focus);
  }
}

// Override for card component to add a horizontal scroll bar for the week view.
.interaction-calendar {
  & > .c-card {
    overflow-x: auto;
  }
}

.three-col-equal-height {
  --gap-space: var(--spacing-2);

  display: grid;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  }
  gap: var(--gap-space);

  .c-card--body {
    height: 100%;
  }

  .flow {
    display: flex;
    flex-direction: column;
  }
}

.cal-wrapper {
  position: relative;
}

// Styles for the day 'discs' for the date-picker calendar
.vuecal--date-picker:not(.vuecal--day-view) .vuecal__cell--today .vuecal__cell-content {
  border-color: var(--colour-utility-black);
}

.vuecal--date-picker:not(.vuecal--day-view) .vuecal__cell--selected .vuecal__cell-content {
  background-color: var(--colour-utility-black);
}

// Remove the border from the calendar
.vuecal {
  box-shadow: none;
  .vuecal__weekdays-headings {
    border: none;
    padding-right: 0;
  }
  .vuecal__title-bar {
    background-color: inherit;
  }
  .vuecal__heading {
    opacity: 1;
  }
}

// There's a bit of nesting styles going on here but it helps make the styles a bit more specific in order to override Vue Cal's defaults.
// Small date picker calendar styles
.cal-picker {
  // Hide on small displays...
  display: none;

  // ... show on everything larger than small displays
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: block;
  }

  // Apply this class when the show calendar button is clicked
  &.show {
    display: block;
  }

  // Set the default font-size for datepicker in this class. It would be more code to set the default higher up the DOM than override this.
  .vuecal--xsmall {
    font-size: var(--font-size-small);
  }

  // Includes the date and the days of the week.
  // Nest the selectors for the header to override the quite specific defaults.
  .vuecal__header {
    .weekday-label {
      color: var(--colour-utility-black);
      font-weight: bold;
    }
  }

  // Includes all the dates in the calendar.
  .vuecal__body {
    .vuecal__cell {
      font-size: clamp(var(--font-size-small), 2vw, var(--font-size-body));

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        font-size: var(--font-size-small);
      }
    }

    // Styles for days outside of the current month
    .vuecal__cell--out-of-scope {
      color: var(--colour-panel-g-24);
    }
    .vuecal__cell--disabled {
      color: rgba(0, 0, 0, 0.1);
      text-decoration: line-through;
    }
  }

  // Styles for the cell count to style them simply as dots
  .vuecal__cell-events-count {
    width: var(--spacing-half);
    height: var(--spacing-half);
    min-width: 0;
    padding: 0;
    margin-top: 2px;
    color: transparent;
  }

  .past-event {
    background-color: var(--colour-brand-mandy) !important;
  }
}

.vuecal__cell--has-events .vuecal__cell-events-count {
  background-color: var(--colour-utility-success);
}
</style>
