import {
  PUT_BY_ID,
  PUT_BY_ID_LOADING,
  PUT_BY_ID_ERROR,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

export const putByIdCommunitiesMutations = {
  [PUT_BY_ID]: (state, data) => {
    state.currentlyLoaded = data;
    state.loaded = true;
    state.loading = false;
    state.error = null;
  },
  [PUT_BY_ID_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.error = null;
  },
  [PUT_BY_ID_ERROR]: (state, error) => {
    state.loading = false;
    state.loaded = true;
    state.error = error;
  }
};

export const putByIdCommunitiesActions = {
  [PUT_BY_ID]: async ({ commit }, { id, details }) => {
    commit(PUT_BY_ID_LOADING);

    try {
      const response = await httpRequest('put', `communities/${id}`, details, {
        version: 2,
        area: 'associations'
      });

      commit(PUT_BY_ID, response);
    } catch (e) {
      commit(PUT_BY_ID_ERROR, e);
    }
  }
};
