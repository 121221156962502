const sortByOrderPos = (one, two) => {
  if (one.order_pos < two.order_pos) {
    return -1;
  }
  if (one.order_pos > two.order_pos) {
    return 1;
  }
  // a must be equal to b
  return 0;
};

export default sortByOrderPos;
