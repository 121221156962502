<template>
  <CustomCard
    purpose="reversed"
    class="details-container content"
  >
    <LoadingOverlay v-if="blacklist.loaded === false" />
    <h2>{{ t('Product Exception list') }}</h2>
    <p>
      {{ t('Tell sales-ai which products not to consider when forming product associations') }}
    </p>

    <CustomCard
      id="blacklist-actions"
      purpose="action"
    >
      <div
        class="search"
        tabindex="-1"
      >
        <h3>{{ t('Add product to exception list') }}</h3>
        <div
          class="input product-search"
          tabindex="0"
        >
          <IconBase
            icon-name="search"
            class="icon"
            tabindex="-1"
            @click="focus"
          />
          <input
            ref="searchinput"
            v-model="searchInputRaw"
            type="text"
            class="search-input"
            :placeholder="t('Search for products')"
            :aria-label="t('Search for products')"
            @keyup="onSearch"
          >
          <div class="product-search-select">
            <SearchSelect
              ref="select"
              tabindex="0"
              :items="filters"
              :selected-value="selectedValue"
              @on-change="setSearchFilter"
            />
          </div>
          <IconButton
            icon-name="close-line"
            purpose="reversed"
            :class="['close', { wide: hasWideFilter }]"
            tabindex="0"
            @click="clearSearch"
          />
        </div>
        <div
          v-if="crmProducts.data.length > 0"
        >
          <ul>
            <li
              v-for="product in crmProducts.data"
              :key="product.product_code"
              class="crm-product"
            >
              <CustomCheckbox
                id="product_name"
                :label="product.product_name"
                :value="!!Object.keys(productsToAdd).includes(product.product_code)"
                @input="
                  handleInput(
                    {
                      product_code: product.product_code,
                      product_name: product.product_name,
                    },
                    $event
                  )
                "
              />
            </li>
          </ul>

          <CustomButton @click="updateBlacklist">
            {{ t('Update') }}
          </CustomButton>
        </div>
        <div
          v-else-if="crmProducts.loaded && crmProducts.data.length === 0 && searchInputRaw.length > 0"
        >
          <p
            class="no-results"
          >
            {{ t('No results were found') }}
          </p>
        </div>
      </div>

      <div class="blacklist-table table-wrapper">
        <h3>{{ t('Exception list items') }}</h3>
        <TableWrapper
          v-if="blacklist.loaded"
          :table-height="'auto'"
        >
          <thead>
            <tr>
              <th
                :class="{
                  'sorting-header': currentSort === 'product_name',
                }"
                @click="sort('product_name')"
              >
                <div>
                  {{ t('Product Name') }}
                  <IconBase
                    v-if="currentSort === 'product_name'"
                    class="sort-icon"
                    :icon-name="currentSortDir === 'desc' ? 'arrow-down' : 'arrow-up'"
                    :height="24"
                    :width="24"
                  />
                </div>
              </th>
              <th
                :class="{
                  'sorting-header': currentSort === 'product_code',
                }"
                @click="sort('product_code')"
              >
                <div>
                  {{ t('Product Code') }}
                  <IconBase
                    v-if="currentSort === 'product_code'"
                    class="sort-icon"
                    :icon-name="currentSortDir === 'desc' ? 'arrow-down' : 'arrow-up'"
                    :height="24"
                    :width="24"
                  />
                </div>
              </th>
              <th
                :class="{
                  'sorting-header': currentSort === 'blacklist_type',
                }"
                @click="sort('blacklist_type')"
              >
                <div>
                  {{ t('Exception list type') }}
                  <IconBase
                    v-if="currentSort === 'blacklist_type'"
                    class="sort-icon"
                    :icon-name="currentSortDir === 'desc' ? 'arrow-down' : 'arrow-up'"
                    :height="24"
                    :width="24"
                  />
                </div>
              </th>
              <th
                :class="{
                  'sorting-header': currentSort === 'ai',
                }"
                @click="sort('ai')"
              >
                <div>
                  AI
                  <IconBase
                    v-if="currentSort === 'ai'"
                    class="sort-icon"
                    :icon-name="currentSortDir === 'desc' ? 'arrow-down' : 'arrow-up'"
                    :height="24"
                    :width="24"
                  />
                </div>
              </th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in sortedBlacklist"
              :key="item.product_code"
            >
              <td>{{ item.product_name }}</td>
              <td>{{ item.product_code }}</td>
              <td>
                {{ item.black_list_type }}
              </td>
              <td>
                <IconBase
                  v-if="item.created_by_id === '20a32592-2714-455b-a456-081196dc5b97'"
                  icon-name="check"
                  :height="24"
                  :width="24"
                />
              </td>
              <td>
                <CustomButton
                  purpose="text"
                  @click="removeItemFromBlacklist(item.product_code)"
                >
                  {{ t('Delete') }}
                </CustomButton>
              </td>
            </tr>
          </tbody>
        </TableWrapper>
      </div>
    </CustomCard>
  </CustomCard>
</template>

<script>
import {
  GET_ALL_BLACKLIST,
  ADD_TO_BLACKLIST,
  REMOVE_FROM_BLACKLIST,
  GET_CRM_PRODUCTS,
  CLEAR_CRM_PRODUCTS,
  CLEAR_PRODUCTS,
} from '@/admin/store/actionType';
import { SET_ITEMS } from '@/shared/store/actionType';
import { mapActions, mapState } from 'vuex';
import { CustomCheckbox, CustomCard, LoadingOverlay, IconBase, IconButton, CustomButton } from '@sales-i/dsv3';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import SearchSelect from '@/shared/components/Form/SearchSelect.vue';
import { productSearchFilters } from '@/shared/utils/productSearchFilters';
import { debounce, t } from '@sales-i/utils';

export default {
  name: 'SetupAssociationsBlacklistView',
  components: {
    CustomCard,
    IconBase,
    IconButton,
    CustomCheckbox,
    CustomButton,
    TableWrapper,
    SearchSelect,
    LoadingOverlay,
  },
  data() {
    return {
      productsToAdd: {},
      currentSort: '',
      currentSortDir: '',
      searchInputRaw: '',
      hasWideFilter: false,
      selectedValue: 'product_name',
      filters: productSearchFilters,
    };
  },
  computed: {
    ...mapState({
      blacklist: state => state.admin.associations.blacklist,
      products: state => state.admin.associations.products,
      crmProducts: state => state.admin.associations.crmProducts,
    }),
    onSearch() {
      return debounce(this.handleKeyup, 500);
    },
    sortedBlacklist() {
      return [...this.blacklist.data].sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },
  mounted() {
    this.getBlacklist();
  },
  methods: {
    t,
    ...mapActions({
      getBlacklist: `admin/associations/${GET_ALL_BLACKLIST}`,
      addToBlacklist: `admin/associations/${ADD_TO_BLACKLIST}`,
      removeFromBlacklist: `admin/associations/${REMOVE_FROM_BLACKLIST}`,
      getProducts: `admin/associations/${GET_CRM_PRODUCTS}`,
      clearCrmProducts: `admin/associations/${CLEAR_CRM_PRODUCTS}`,
      clearProducts: `admin/associations/${CLEAR_PRODUCTS}`,
    }),
    setSearchFilter(value) {
      switch (value.value) {
      case 'product_name':
      case 'product_code':
        this.hasWideFilter = false;
        break;
      default:
        this.hasWideFilter = true;
        break;
      }
      this.selectedValue = value.value;
      if (this.searchInputRaw.length > 0) {
        this.getProducts({
          q: `${this.selectedValue}:${this.searchInputRaw}`,
        });
      }
    },
    focus() {
      this.$refs.searchinput.focus();
    },
    async handleKeyup() {
      if (this.searchInputRaw === '') {
        this.clearCrmProducts();
        return;
      }

      await this.getProducts({
        q: `${this.selectedValue}:${this.searchInputRaw}`,
      });

      return this.products.data;
    },
    handleInput(product, enabled) {
      this.productsToAdd[product.product_code] = {
        enabled,
        product_name: product.product_name,
      };
    },
    async updateBlacklist() {
      const promises = [];
      for (let n in this.productsToAdd) {
        if (this.productsToAdd[n].enabled === true) {
          promises.push(
            this.addToBlacklist({
              product_code: n,
              product_name: this.productsToAdd[n].product_name,
            })
          );
        }
      }

      await Promise.all(promises);

      this.$store.dispatch(`alerts/${SET_ITEMS}`, {
        type: 'success',
        message: t('Product added to the exception list'),
        selfDismiss: true,
      });

      this.clearProducts();
      this.getBlacklist();
    },
    clearSearch() {
      this.searchInputRaw = '';
      this.clearCrmProducts();
    },
    async removeItemFromBlacklist(product_code) {
      await this.removeFromBlacklist(product_code);

      this.$store.dispatch(`alerts/${SET_ITEMS}`, {
        type: 'success',
        message: t('Product removed from the exception list'),
        selfDismiss: true,
      });

      this.getBlacklist();
    },
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.content {
  display: flex;
  width: 100%;
  transition: width 0.5s ease-in-out;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: 70%;

    .full-width & {
      width: 100%;
    }
  }
}

.product-search {
  position: relative;

  .product-search-select {
    position: absolute;
    top: 0px;
    right: 3px;
  }

  .close {
    height: 40px;
    width: 40px;
    position: absolute;
    top: 0;
    right: 135px;
    padding: 0;
    margin: 0;

    &.wide {
      right: 245px;
    }
  }
}

#blacklist-actions {
  padding: 0;

  .blacklist-table {
    background-color: white;
    width: 100%;
    max-width: 960px;
    // padding: var(--spacing-3);

    h3 {
      padding: var(--spacing-3);
      margin: var(--spacing-2) 0;
    }

    table {
      th,
      td {
        font-size: inherit;
      }
    }

    tbody tr:first-child td {
      padding-top: var(--spacing-4);
    }

    tr {
      padding: var(--spacing-1);

      &:nth-child(even) {
        background: var(--colour-panel-g-2);
      }
      &:nth-child(odd) {
        background: var(--colour-utility-white);
      }
    }

    th,
    td {
      color: var(--colour-utility-black);
      padding: var(--spacing-2);
      border: none;
    }

    th {
      background: var(--colour-panel-action);
      color: var(--colour-utility-action);
      padding: var(--spacing-3) var(--spacing-2);
      vertical-align: middle;
      text-decoration: underline;
      cursor: pointer;

      div {
        display: flex;
      }

      &.contains-chip-header {
        padding-bottom: var(--spacing-3);
      }

      &.sorting-header {
        background: var(--colour-panel-g-2);
        color: var(--colour-utility-black);
        font-weight: var(--font-weight-medium);
        text-decoration: none;
      }
    }

    td {
      padding: var(--spacing-1) var(--spacing-2);
      line-height: var(--spacing-2);

      &.first-column {
        background: var(--colour-panel-g-2);
        border-right: 1px solid var(--colour-panel-g-8);
        text-align: left;
      }
    }

    tr:nth-child(even) td.first-column {
      background: var(--colour-panel-g-4);
    }

    td.first-column {
      background: var(--colour-panel-g-2);
      border-right: 1px solid var(--colour-panel-g-8);
      text-align: left;
    }
  }
}

.search {
  display: flex;
  flex-flow: column nowrap;
  padding: var(--spacing-3);
  // align-items: center;

  ul {
    padding: var(--spacing-3);
    list-style-type: none;
  }

  button {
    margin: 0 var(--spacing-3);
  }

  h3 {
    margin: var(--spacing-2) 0;
  }

  .input {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    border-radius: var(--border-radius-half);
    width: 100%;
    border: 2px solid transparent;
    padding: 0 var(--spacing-1);
    background-color: var(--colour-panel-g-0);
    font-weight: var(--font-weight-medium);
    box-shadow: 0px 0px 0px 1px var(--border-utility-base);
    margin-bottom: var(--spacing-1);

    &:focus-within:not([disabled]) {
      box-shadow: 0px 0px 0px 4px var(--colour-utility-focus);
      border: 2px solid var(--colour-utility-checked);
      outline: 2px solid transparent;
    }

    &.input-error:not([disabled]) {
      box-shadow: 0px 0px 0px 4px var(--colour-utility-error);
      border: 2px solid var(--colour-utility-checked);
      outline: 2px solid transparent;
    }

    .close {
      cursor: pointer;
    }

    input,
    input[type='text']:focus {
      border: none;
      box-shadow: none;
      padding-left: var(--spacing-1);
    }
  }
}

.crm-product {
  margin: var(--spacing-2) 0;
}

.no-results {
  margin: var(--spacing-2);
}
</style>
