export default [{
  stage: 'Add a comment',
  fields: [
    {
      label: '',
      id: 'comment',
      type: 'mentionable',
      maxLength: 280,
      placeholder: '',
      value: '',
      required: true,
      disabled: false,
      errorMessage: 'Please enter a comment',
    },
  ],
},];
