<template>
  <TableWrapper
    table-height="510px"
    :offset-y="offset"
    enable-lazy-load
    :no-more-results-available="!canLoadMore"
    :loading="loading"
    @set-offset="loadMoreData"
  >
    <table>
      <thead>
        <tr>
          <th>{{ t('Interaction Type') }}</th>
          <th>{{ t('Date') }}</th>
          <th>{{ t('Contact') }}</th>
          <th>{{ t('Assigned to') }} </th>
          <th>{{ t('Note') }}</th>
          <th>{{ t('Purpose') }}</th>
          <th>{{ t('Outcome') }}</th>
          <th>{{ t('Link') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="interaction in interactionsToShow"
          :key="interaction.id"
        >
          <td>{{ interaction.interaction_type_name }}</td>
          <td>{{ dates.format(interaction.date_start) }}</td>
          <td>{{ getContactName(interaction) }}</td>
          <td>{{ interaction.user_name }}</td>
          <td>{{ interaction.description }}</td>
          <td>{{ interaction.purpose_name }}</td>
          <td>{{ interaction.outcome_name }}</td>
          <td>
            <a
              :href="getInteractionLink(interaction)"
              target="_blank"
            >{{ t('Open') }}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </TableWrapper>
</template>

<script setup>
import { ref } from 'vue';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import { dates, t } from '@sales-i/utils';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';

const emit = defineEmits(['loadMoreData']);

const offset = ref(0);

const props = defineProps({
  interactionsToShow: {
    type: Array,
    required: true,
    default: () => [],
  },
  canLoadMore: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false
  }
});

function loadMoreData() {
  if (props.canLoadMore) emit('loadMoreData');
}
function getContactName(interaction) {
  let opps = interaction?.relationships?.opportunities || [];

  let contacts = interaction?.relationships?.contacts || [];

  if (contacts.length) return contacts[0].full_name || '';
  if (opps.length) return opps[0].contact_name;
  return '';
}
function getInteractionLink(item) {
  return `${baseUrl}/${interactionsArea}/${item.id}`;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

table {
  position: relative;
  width: 100%;
}

tr {
  background: var(--colour-panel-g-0);
  &:nth-child(even) {
    background: var(--colour-panel-g-2);
  }
}

th,
td {
  color: var(--colour-utility-black);
  padding: var(--spacing-2);
  border: none;
}

th {
  font-size: var(--font-size-body);
  background: var(--colour-panel-action);
  color: var(--colour-utility-action);
  position: sticky;
  top: 0;
  text-align: left;
  min-height: 70px;
  min-width: 180px;
  vertical-align: middle;
  padding: var(--spacing-3);
}

td {
  font-size: var(--font-size-small);
  padding: var(--spacing-1) var(--spacing-3);
  line-height: var(--spacing-2);
  text-align: left;
}
</style>