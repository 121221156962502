<template>
  <div class="heading-wrapper">
    <slot name="icon" />
    <slot />
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
  .heading-wrapper {
    position: relative;
    padding-right: var(--spacing-2);
    padding-top: var(--spacing-1);
    width: fit-content;
    text-align: center;
    margin-inline: auto;
  }
</style>