<template>
  <LeftHandNavigation
    :loading="menu.loading"
    :items="menu.data"
    :active-section="activeSection"
    @item-clicked="itemClicked"
    @active-section-clicked="setActiveSection"
  >
    <router-view />
  </LeftHandNavigation>
</template>

<script type="text/javascript">
import { mapActions, mapState } from 'vuex';
import LeftHandNavigation from '@/admin/components/Layout/LeftHandNavigation.vue';
import { GET_MENU_OPTIONS, CLEAR_MENU_OPTIONS } from '@/admin/store/actionType';
import { navigateToUrl } from 'single-spa';
import { associationsSection, baseUrl, intelligenceArea } from '@/admin/router/urlBits';

export default {
  name: 'SetupAssociationsView',
  components: {
    LeftHandNavigation,
  },
  data() {
    return {
      activeSection: 0,
    };
  },
  computed: {
    ...mapState({
      menu: state => state.admin.associations.menu,
    }),
  },
  watch: {
    $route() {},
  },
  mounted() {
    this[`admin/associations/${GET_MENU_OPTIONS}`]();
    this.setActiveSection();
  },
  methods: {
    ...mapActions([`admin/associations/${GET_MENU_OPTIONS}`, `admin/associations/${CLEAR_MENU_OPTIONS}`]),
    itemClicked(item) {
      let route = 'path' in item ? item.path : item.name.toLowerCase();
      let directTo = route !== 'products' ? route : '';
      navigateToUrl(`${baseUrl}/${intelligenceArea}/${associationsSection}/${directTo}`);
    },
    setActiveSection(index) {
      if (index === undefined) {
        index = 0;
        let ignore = ['', 'admin', 'intelligence', 'associations'];
        let parts = this.$route.fullPath.split('/');
        parts.forEach(part => {
          if (!ignore.includes(part)) {
            index = this.menu.data.findIndex(item => item.name.toLowerCase() === part);
          }
        });
      }
      this.activeSection = index;
    },
  },
};
</script>

<style lang="scss" scoped></style>
