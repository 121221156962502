<template>
  <ContactCard
    :contact="defaultContact.data"
    :loaded="defaultContact.loaded"
    :loading="defaultContact.loading"
    :entity-type="entityType"
    @add="addContact"
    @update="updateContact"
  />
</template>

<script>
import ContactCard from '@/crm/components/Contacts/ContactCard.vue';
import { mapState, mapActions } from 'vuex';
import { baseUrl, contactsSection } from '@/crm/router/urlBits.js';
import { SET_ROUTE, GET_DEFAULT, CLEAR_DEFAULT } from '@/shared/store/actionType';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'DefaultContact',
  components: {
    ContactCard,
  },
  props: {
    entityType: {
      type: [String, Number],
      default: '',
    },
    entityId: {
      type: [String, Number],
      default: '',
    },
    entityName: {
      type: String,
      default: '',
    },
    addRouteOverride: {
      type: [Object, Boolean],
      default: false,
    },
    updateRouteOverride: {
      type: [Object, Boolean],
      default: false,
    },
  },
  computed: {
    ...mapState({
      defaultContact: state => state.crm.contacts.default || {},
      platform: state => state.platform,
    }),
    entity() {
      return {
        entity: this.entityType === 'customer' ? 'customers' : 'prospects',
        id: this.entityId,
        name: this.entityName,
        record_type: this.entityType,
      };
    },
  },
  watch: {
    entityType() {
      this.getDefaultContact();
    },
    entityId() {
      this.getDefaultContact();
    },
  },
  mounted() {
    this.getDefaultContact();
  },
  unmounted() {
    this.clearDefault();
  },
  methods: {
    ...mapActions({
      getDefault: `crm/contacts/${GET_DEFAULT}`,
      clearDefault: `crm/contacts/${CLEAR_DEFAULT}`,
      setRoute: `system/${SET_ROUTE}`,
    }),
    getDefaultContact() {
      if (this.entity.id) {
        this.getDefault(this.entity);
      }
    },
    addContact() {
      this.setRoute(
        this.addRouteOverride || {
          success: `${baseUrl}/${this.entity.entity}/${this.entity.id}/${contactsSection}`,
          cancel: this.$route.fullPath,
        }
      );
      navigateToUrl(`${baseUrl}/${this.entity.entity}/${this.entity.id}/${contactsSection}/add?isDefault=true`);
    },
    updateContact() {
      this.setRoute(
        this.updateRouteOverride || {
          success: this.$route.fullPath,
          cancel: this.$route.fullPath,
        }
      );
      navigateToUrl(`${baseUrl}/${this.entity.entity}/${this.entity.id}/${contactsSection}/${this.defaultContact.data.id}/edit`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
