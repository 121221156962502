import {
  SET_ITEMS,
  REMOVE_BY_INDEX
} from '@/shared/store/actionType';

// initial state
const state = () => ({
  data: [],
});

// getters
const getters = {
};

// mutations
const mutations = {
  [SET_ITEMS]: (state, data) => {
    state.data = state.data.concat(data);
  },
  [REMOVE_BY_INDEX]: (state, index) => {
    state.data.splice(index, 1);
  },
};

// actions
const actions = {
  [SET_ITEMS] ({ commit, }, payload) {
    commit(SET_ITEMS, payload);
  },
  [REMOVE_BY_INDEX] ({ commit, }, index) {
    commit(REMOVE_BY_INDEX, index);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
