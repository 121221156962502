<template>
  <CustomModal
    v-if="showModal"
    position="center"
    class="flow view-contact"
    show-modal
    @close-modal="closeModal"
  >
    <div
      v-if="contact.loaded"
      class="contacts-view pb-6"
    >
      <div>
        <div class="flex align-center">
          <icon-base
            height="48"
            width="48"
            icon-name="user-circle"
            icon-color="black"
          />
          <CustomRibbon
            v-if="contact.data.is_default_contact"
            type="action"
            rounded
          >
            {{ t('Default contact') }}
          </CustomRibbon>
        </div>
        <h3 class="fw-sbld">
          {{ contact.data.full_name || `${contact.data.first_name} ${contact.data.surname}` }}
        </h3>

        <p class="text-sm">
          {{ contact.data.customer ? contact.data.customer.name : contact.data.company }}
        </p>
        <div class="row contact-icons">
          <div class="p-0">
            <CustomButton
              v-if="contact.data.mobile_phone"
              icon
              :href="getTelephoneLink(contact.data.mobile_phone)"
              :width="40"
              :height="40"
            >
              <span class="visually-hidden">{{ t('Telephone') }}</span>
              <IconBase
                icon-name="phone"
                :width="24"
                :height="24"
              />
            </CustomButton>
            <CustomButton
              v-if="contact.data.email"
              icon
              :href="getMailtoLink(contact.data.email)"
              :width="40"
              :height="40"
            >
              <span class="visually-hidden">{{ t('Email') }}</span>
              <IconBase
                icon-name="email"
                :width="24"
                :height="24"
              />
            </CustomButton>
          </div>
          <RagChip
            v-if="false"
            :value="contact.data.percent_complete"
            suffix="%"
            :green="67"
            :amber="33"
            small
          />
        </div>
      </div>

      <div class="contacts-view__body">
        <div
          v-if="contact.data.addresses && contact.data.addresses.length > 0"
          class="contact-info-address"
        >
          <dl
            v-for="(item, index) in contact.data.addresses"
            :key="index"
            class="dl-wrap flow"
          >
            <dd>
              <Address :value="item" />
            </dd>
          </dl>
        </div>
        <div class="contact-info-other">
          <dl class="dl-wrap flow">
            <div
              v-if="contact.data.email"
              class="mb-2"
            >
              <dt>{{ t('Email') }}</dt>
              <dd>
                <a :href="getMailtoLink(contact.data.email)">{{ contact.data.email }}</a>
              </dd>
            </div>

            <div class="contact-info-other__numbers">
              <div v-if="contact.data.office_phone">
                <dt>{{ t('Office phone') }}</dt>
                <dd>
                  <a :href="getTelephoneLink(contact.data.office_phone)">{{ contact.data.office_phone }}</a>
                </dd>
              </div>

              <div v-if="contact.data.business_phone">
                <dt>{{ t('Business phone') }}</dt>
                <dd>
                  <a :href="getTelephoneLink(contact.data.business_phone)">{{ contact.data.business_phone }}</a>
                </dd>
              </div>

              <div v-if="contact.data.home_phone">
                <dt>{{ t('Home phone') }}</dt>
                <dd>
                  <a :href="getTelephoneLink(contact.data.home_phone)">{{ contact.data.home_phone }}</a>
                </dd>
              </div>

              <div v-if="contact.data.mobile_phone">
                <dt>{{ t('Mobile phone') }}</dt>
                <dd>
                  <a :href="getTelephoneLink(contact.data.mobile_phone)">{{ contact.data.mobile_phone }}</a>
                </dd>
              </div>
            </div>

            <div v-if="contact.data.fax">
              <dt>{{ t('Fax') }}</dt>
              <dd>{{ contact.data.fax }}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>

    <template
      v-if="showfooter"
      #footer
    >
      <ButtonGroup>
        <template v-if="!contact.loading">
          <CustomButton
            v-if="canDelete"
            purpose="reversed"
            :label="t('Delete contact')"
            small
            class="delete"
            @click="deleteContact"
          />
          <CustomButton
            v-if="!contact.data.is_default_contact"
            purpose="reversed"
            :label="t('Make default contact')"
            small
            class="default-contact"
            @click="makeDefaultContact"
          />
          <CustomButton
            v-if="canUpdate"
            :label="t('Edit details')"
            small
            class="edit-details"
            @on-click="editContact"
          />
        </template>
        <template v-if="contact.loading">
          <BufferImage :size="40" />
        </template>
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script>
import { IconBase, CustomModal, CustomButton, BufferImage, CustomRibbon, RagChip } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import store from '@/shared/store';
import { mapState, mapActions } from 'vuex';
import { SET_ROUTE, SET_DEFAULT, CLEAR_BY_ID, DELETE_BY_ID, SET_CONFIRMATION_MODAL } from '@/shared/store/actionType';
import { baseUrl, contactsSection } from '@/crm/router/urlBits.js';
import usePermissions from '@/shared/composables/usePermissions';
import { contacts } from '@/shared/store/data/policies';
import Address from '@/shared/components/Map/Address.vue';
import { t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

export default {
  components: {
    ButtonGroup,
    CustomButton,
    IconBase,
    CustomModal,
    BufferImage,
    CustomRibbon,
    RagChip,
    Address,
  },
  props: {
    entityId: {
      type: [String, Number],
      default: '',
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
    defaultContact: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['closeModal', 'deleted'],
  data() {
    return {
      showSelf: true,
    };
  },
  computed: {
    ...mapState({
      entity: state => state.system.entity,
      platform: state => state.platform,
    }),
    contactIcons() {
      return this.contact.data.items.filter(function (item) {
        return item.label === 'Telephone' || item.label === 'Email';
      });
    },
    hasAccess() {
      const { hasAccess } = usePermissions();
      return hasAccess;
    },
    canUpdate() {
      return this.hasAccess(contacts, 'update');
    },
    canDelete() {
      return this.hasAccess(contacts, 'delete');
    },
    showfooter() {
      return this.canUpdate || this.canDelete;
    },
    // Hide this modal when displaying the child modals
    showModal() {
      let path = this.$route.path;
      return path.indexOf('edit') < 0 && this.showSelf;
    },
  },
  unmounted() {
    this.clearContact();
  },
  methods: {
    t,
    ...mapActions({
      clearById: `crm/contacts/${CLEAR_BY_ID}`,
      deleteById: `crm/contacts/${DELETE_BY_ID}`,
      setDefault: `crm/contacts/${SET_DEFAULT}`,
      setRoute: `system/${SET_ROUTE}`,
    }),
    deleteContact() {
      this.showSelf = false;
      store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, {
        message: t(`Do you really want to delete ${this.contact.data.full_name}?`, 'do_you_really_want_to_delete_variable', {
          interpolations: { variable: this.contact.data.full_name },
        }),
        updatedMessage: t(`You have deleted ${this.contact.data.full_name}`, 'you_have_deleted_variable', {
          interpolations: { variable: this.contact.data.full_name },
        }),
        updateFunction: async () => {
          try {
            await this.deleteById({
              id: this.contact.data.id,
            });
            this.$emit('deleted');
            return true;
          } catch (e) {
            return false;
          }
        },
        finishFunction: () => {
          this.showSelf = true;
          this.closeModal();
          return true;
        },
        closeFunction: () => {
          this.showSelf = true;
          return true;
        },
      });
    },
    async makeDefaultContact() {
      let {
        entityId,
        contact: { data },
        defaultContact,
      } = this;
      this.showSelf = false;
      navigateToUrl(`${baseUrl}/${this.entity}/${entityId}/${contactsSection}/${data.id}/?default`);
      store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, {
        heading: t('Update default contact'),
        message: defaultContact.data.id
          ? t(
            `Are you sure you want to change the default contact from <strong>${defaultContact.data.full_name}</strong> to <strong>${data.full_name}</strong>?`,
            'are_you_sure_you_want_to_change_the_default_contact_from_name1_to_name2',
            {
              interpolations: {
                name1: defaultContact.data.full_name,
                name2: data.full_name,
              },
            }
          )
          : t(
            `Are you sure you want to make <strong>${data.full_name}</strong> the default contact?`,
            'are_you_sure_you_want_to_make_name_the_default_contact',
            { interpolations: { name: data.full_name } }
          ),
        updatedHeading: t('Default contact updated'),
        updatedMessage: t(`<strong>${data.full_name}</strong> is now the default contact`, 'name_is_now_the_default_contact', {
          interpolations: { name: data.full_name },
        }),
        updateFunction: async () => {
          try {
            await this.setDefault({
              id: this.entityId,
              entity: this.entity,
              contact_id: this.contact.data.id,
            });
            return true;
          } catch (e) {
            return false;
          }
        },
        finishFunction: () => {
          navigateToUrl(`${baseUrl}/${this.entity}/${entityId}/${contactsSection}/${data.id}/`);
          this.showSelf = true;
          return true;
        },
        closeFunction: () => {
          this.showSelf = true;
          return true;
        },
      });
    },
    closeModal() {
      this.$emit('closeModal');
    },
    editContact() {
      let { id, contact_id } = this.$route.params;
      this.setRoute({
        success: this.$route.fullPath,
        cancel: this.$route.fullPath,
      });
      navigateToUrl(`${baseUrl}/${this.entity}/${id}/${contactsSection}/${contact_id}/edit`);
    },
    clearContact() {
      this.clearById();
    },

    getTelephoneLink(value) {
      return `tel:${value}`;
    },

    getMailtoLink(value) {
      return `mailto:${value}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
// Applies correct horizontal padding for this modal
.contacts-view {
  padding-right: var(--spacing-2);
  padding-left: var(--spacing-2);

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding-right: var(--spacing-8);
    padding-left: var(--spacing-8);
  }
}

.contacts-view__body {
  display: block;
  margin-top: var(--spacing-4);
  padding-bottom: var(--spacing-16);

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    display: flex;
    padding-bottom: 0;
  }
}

.dl-wrap {
  margin-bottom: var(--spacing-2);

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    margin-bottom: inherit;
  }
}

.contact-info-address {
  margin-right: var(--spacing-6);
}

.contact-info-other__numbers {
  display: flex;
  flex-wrap: wrap;
  max-width: 304px;
  max-width: calc(var(--spacing-16) * 2.4);

  > *:not(:last-child) {
    margin-bottom: var(--spacing-1);
    margin-right: var(--spacing-3);
  }
}

.contact-icons {
  align-items: center;
  //line-height: 0;
  margin-left: calc(var(--spacing-1) * -1);
}

.svg-container {
  z-index: 1;
}

.ribbon {
  margin-left: calc(var(--spacing-1) * -1);
}
</style>
<style lang="scss">
.view-contact .content-footer {
  height: auto;
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-2);
  @media (min-width: 768px) {
    height: 90px;
  }
}
</style>
