<template>
  <TableWrapper
    table-height="460px"
    :offset-y="offset"
    enable-lazy-load
    :no-more-results-available="!canLoadMore"
    :loading="loading"
    @set-offset="loadMoreData"
  >
    <table>
      <thead>
        <tr>
          <th
            v-for="(heading, index) in tableHeadings"
            :key="heading.text"
            :class="{ sortable: heading.sortable,
                      'sorting-header': currentSort === heading.value && index !== 0, }"
            @click="heading.sortable ? sortData(heading.value) : null"
          >
            {{ heading.text }}
            <IconBase
              v-if="currentSort === heading.value && index !== 0"
              class="sort-icon"
              :icon-name="sortDirection === REPORTS_SORT_DESCENDING_KEY ? 'arrow-down' : 'arrow-up'"
              :height="24"
              :width="24"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="data in filteredTableData"
          :key="data.customer_id"
        >
          <td>
            <a
              :href="getLink(data.customer_id)"
              target="_blank"
            >
              {{ data.customer_name }}
            </a>
          </td>
          <td>{{ data.latest_invoice ? formatDate(data.latest_invoice, 'short') : '' }}</td>
          <td>{{ cPrice(data.opportunity_value) }}</td>
          <td>{{ cNumber(data.opportunity_count, 0) }}</td>
          <td>{{ data.last_interaction ? formatDate(data.last_interaction, 'short') : '' }}</td>
          <td>
            <a
              :href="getLink(data.customer_id, data.customer_name)"
              target="_blank"
            >
              {{ t('See Opportunities') }}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </TableWrapper>
</template>
<script setup>
import { defineProps, computed } from 'vue';
import { IconBase } from '@sales-i/dsv3';
import { t, dates } from '@sales-i/utils';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import { baseUrl, customersArea, opportunitiesArea } from '@/crm/router/urlBits';
import useCurrency from '@/shared/composables/useCurrency';
import { REPORTS_SORT_DESCENDING_KEY } from '@/intelligence/store/data/apiInput';

const props = defineProps({
  currentSort: {
    type: String,
    default: '',
  },
  sortDirection: {
    type: String,
    default: '',
  },
  searchQuery: {
    type: String,
    default: '',
  },
  tableData: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['sort']);

const { cPrice, cNumber } = useCurrency();
const formatDate = dates.format;

const filteredTableData = computed(() => {
  if (!props.searchQuery) return props.tableData;

  return props.tableData.filter(el => el.customer_name.toLowerCase().includes(props.searchQuery.toLowerCase()));
});
const tableHeadings = computed(() => [
  { text: t('Customer Name'), sortable: false },
  { text: t('Last Invoice'), sortable: false },
  { text: t('Total Value'), sortable: true, value: 'opportunity_value' },
  { text: t('Number of Opportunities'), sortable: true, value: 'opportunity_count' },
  { text: t('Last Interaction'), sortable: false },
  { text: '', sortable: false },
]);
function getLink(id, customer = null) {
  if (id && customer) {
    const encodedCustomer = encodeURIComponent(customer);
    return `${baseUrl}/${opportunitiesArea}/?status=open&type=missed&filter=customer&search=${encodedCustomer}`;
  } else {
    return `${baseUrl}/${customersArea}/${id}`;
  }
}
function sortData(headerValue) {
  emit('sort', headerValue);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

table {
  position: relative;
  width: 100%;
}

tr {
  background: var(--colour-panel-g-0);
  &:nth-child(even) {
    background: var(--colour-panel-g-2);
  }
}

th,
td {
  color: var(--colour-utility-black);
  padding: var(--spacing-2);
  border: none;
}

th {
  font-size: var(--font-size-body);
  background: var(--colour-panel-action);
  color: var(--colour-utility-action);
  position: sticky;
  top: 0;
  text-align: left;
  min-height: 70px;
  min-width: 180px;
  vertical-align: middle;
  padding: var(--spacing-3);
  text-align: center;

  &:first-of-type {
    text-align: left;
  }

  &.sorting-header {
    background: var(--colour-panel-g-2);
    color: var(--colour-utility-black);
    font-weight: var(--font-weight-medium);
    text-decoration: none;
  }
}

td {
  font-size: var(--font-size-small);
  padding: var(--spacing-1) var(--spacing-3);
  line-height: var(--spacing-2);
  text-align: center;

  &:first-of-type {
    text-align: left;
  }
}

.sortable {
  text-decoration: underline;
  cursor: pointer;
}

.sort-icon {
  display: inline-block;
  position: absolute;
}
</style>
