<template>
  <EntityList
    v-if="permissions.read"
    :filters="sortOptions"
    :selected-filter="sort"
    show-pagination
    show-tab-switch
    :page-limit="limit"
    :page-offset="offset"
    :records-count="itemsCount"
    :loading="loading"
    :searchable="true"
    :search-placeholder="searchPlaceholder"
    :search-value="searchQuery"
    :current-view-mode="currentViewMode"
    @filter-selected="handleSortChanged"
    @page-changed="handlePageChanged"
    @search="handleSearch"
    @switch-view-mode="switchMode"
  >
    <div
      v-if="loaded && currentViewMode ==='grid-view'"
      role="list"
      class="list list-card"
    >
      <template v-if="props.entity === 'prospect'">
        <ProspectCard
          v-for="(item, index) in items"
          :key="index"
          :item="item"
          @deleted="loadPageData"
        />
      </template>

      <template v-if="props.entity === 'customer'">
        <CustomerCard
          v-for="(item, index) in items"
          :key="index"
          :item="item"
        />
      </template>
    </div>
    <EntityListViewTable
      v-if="loaded && currentViewMode === 'list'"
      :entity="props.entity"
      :items="items"
      :headings="headings"
    />
  </EntityList>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import EntityList from '@/crm/components/EntityList/EntityList.vue';
import { CLEAR_ALL, CLEAR_UPDATED_FLAG, GET_ALL } from '@/shared/store/actionType';
import { paginationProps, usePagination } from '@/shared/composables/usePagination';
import { prospects, customers } from '@/shared/store/data/policies';
import ProspectCard from '@/crm/components/Prospect/ProspectCard.vue';
import CustomerCard from '@/crm/components/Customer/CustomerCard.vue';
import usePermissions from '@/shared/composables/usePermissions';
import { t } from '@sales-i/utils';
import EntityListViewTable from '@/shared/components/Tables/EntityListViewTable';
import breakpoints from '@/shared/utils/breakpoints';
import { customerHeadings, prospectHeadings } from '@/shared/constants/headings/headings';

let searchQuery = ref('');
const { getPermissions } = usePermissions();
const permissions = props.entity === 'prospect' ? getPermissions(prospects) : getPermissions(customers);

const emit = defineEmits(['filterChanged', 'search']);
const props = defineProps({
  ...paginationProps,
  entity: {
    type: String,
    default: '',
  },
});

const store = useStore();
const sort = ref('name:asc');
const currentViewMode = ref('list');
const isMobileView = ref(window.screen.width < breakpoints.breakpoints.md);

const prospectUpdated = computed(() => store.state.crm.prospects.selected.updated);
const headings = computed(() => props.entity === 'prospect' ? prospectHeadings : customerHeadings );
const entity = computed(() => (props.entity === 'prospect' ? 'prospects' : 'customers'));
const items = computed(() => store.state.crm[entity.value].all.data);
const loading = computed(() => store.state.crm[entity.value].all.loading);
const loaded = computed(() => store.state.crm[entity.value].all.loaded);
const sortOptions = computed(() => store.state.crm[entity.value].sorting);
const searchPlaceholder = computed(() => t(`Search ${props.entity}s`));
const itemsCount = computed(() => items.value.length);

const clearProspectUpdated = () => store.dispatch(`crm/prospects/${CLEAR_UPDATED_FLAG}`);

const { limit, offset, handlePageChanged } = usePagination(props, loadPageData, emit);

const queryParams = computed(() => ({
  sort: sort.value,
  limit: limit.value,
  offset: offset.value,
  q: searchQuery.value,
}));

onBeforeMount(() => {
  setDefaultView();
});
onMounted(() => {
  loadPageData();
});
onUnmounted(() => {
  clearItems();
});

// If the route changes, reset the page and the search query
watch(
  () => props.entity,
  () => {
    handlePageChanged(0);
  }
);

watch(
  () => ({ ...queryParams.value }),
  (newV, oldV) => {
    if (newV.offset == oldV.offset) {
      handlePageChanged(0); //reset page if filter has changed
    } else {
      loadPageData();
    }
  },
  { deep: true }
);

watch(() => prospectUpdated.value, isUpdated => {
  if (isUpdated) {
    loadPageData();
    clearProspectUpdated();
  }
});
const getItems = params => store.dispatch(`crm/${entity.value}/${GET_ALL}`, params);
const clearItems = () => store.dispatch(`crm/${entity.value}/${CLEAR_ALL}`);

function handleSortChanged({ value }) {
  sort.value = value;
}
function loadPageData() {
  getItems(queryParams.value);
}
function handleSearch(value) {
  searchQuery.value = value;
}
function setDefaultView() {
  currentViewMode.value = isMobileView.value ? 'grid-view' : 'list';
}
function switchMode(view) {
  currentViewMode.value = view.value;
}
</script>
