<template>
  <CustomModal
    position="center"
    class="flow view-contact"
    show-modal
    @close-modal="closeModal"
  >
    <template #title>
      <div class="modal-title">
        <h3 style="flex: 1; min-width: 100%">
          {{ item.purpose }}
        </h3>
        <p v-if="item.time !== 'NaN:NaNam'">
          {{ item.type }} - {{ item.date }}{{ item.time }}
        </p>
      </div>
    </template>
    <div class="form-grid">
      <div class="row">
        <div class="col col-12 col-md-6 mb-2">
          <AssigneesList :items="assignees" />
        </div>
        <div class="col col-12 col-md-6 outcome-action">
          <CRMCard
            v-if="item.idealOutcome"
            class="mb-2"
            title="Ideal outcome"
          >
            <template #info>
              {{ item.idealOutcome }}
            </template>
          </CRMCard>

          <CRMCard
            v-if="item.outcome"
            class="mb-2"
            title="Outcome"
          >
            <template #info>
              {{ item.outcome }}
            </template>
          </CRMCard>

          <CRMCard
            v-if="item.nextAction"
            title="Next Action"
          >
            <template #info>
              {{ item.nextAction }}
            </template>
          </CRMCard>
        </div>
      </div>
    </div>
    <template
      v-if="canUpdate"
      #footer
    >
      <CustomButton
        v-if="item.outcome === ''"
        small
        class="edit-details"
        @on-click="editInteraction"
      >
        {{ t('Edit details') }}
      </CustomButton>
    </template>
  </CustomModal>
</template>

<script setup>
import { computed } from 'vue';
import { CRMCard, CustomModal, CustomButton } from '@sales-i/dsv3';
import AssigneesList from '@/crm/components/Common/AssigneesList.vue';
import { useUsers } from '@/shared/composables/useUsers';
import { useStore } from 'vuex';
import usePermissions from '@/shared/composables/usePermissions';
import { contacts } from '@/shared/store/data/policies';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits.js';
import { t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

const store = useStore();
const { getUserById } = useUsers(store, false);
const emit = defineEmits(['closeModal']);

const { hasAccess } = usePermissions();
const canUpdate = computed(() => hasAccess(contacts, 'update'));

const item = computed(() => store.getters['crm/interactions/item']);
const assignees = computed(() => {
  let arr = [];
  let pushUser = getUserById(item.value.assignee);
  if (pushUser) {
    arr.push(pushUser);
  }
  return arr;
});

function editInteraction() {
  navigateToUrl(`${baseUrl}/${interactionsArea}/${item.value.id}/edit/details`);
}

function closeModal() {
  emit('closeModal');
}
</script>

<style lang="scss" scoped>
.modal-title {
  display: flex;
  flex-flow: column nowrap;
}

.outcome-action {
  flex: 1;
  display: flex;
  flex-flow: column;
}
</style>
