import {
  POST_BY_ENTITY,
  POST_BY_ENTITY_LOADING,
  POST_BY_ENTITY_ERROR
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const postMutations = {
  [POST_BY_ENTITY]: (state, data) => {
    state.selected.data = data;
    state.selected.loading = false;
    state.selected.loaded = true;
    state.selected.updated = true;
  },
  [POST_BY_ENTITY_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [POST_BY_ENTITY_ERROR]: (state) => {
    state.selected.loading = false;
    state.selected.loaded = false;
    state.selected.updated = false;
  },
};

// actions
export const postActions = {
  [POST_BY_ENTITY]: async ({ dispatch, commit, }, params) => {
    const { id, entity, payload, } = params;
    const { post, tags } = payload;
    commit(POST_BY_ENTITY_LOADING);
    try {
      const data = await httpRequest('post', `${entity}/${id}/posts?${params}`, post, {version: '2',});

      if (tags.length > 0) {
        await dispatch(`tags/${POST_BY_ENTITY}`,{
          entity: 'posts',
          id: data.id,
          tags: tags
        },{
          root:true
        });
      }

      commit(POST_BY_ENTITY, {
        ...post,
        ...data,
      });
    } catch (error) {
      commit(POST_BY_ENTITY_ERROR);
    }
  },
};
