import { computed } from 'vue';
import { useStore } from 'vuex';

import { currency } from '@sales-i/utils';

export default function useCurrency() {
  const store = useStore();
  const currencyName = computed(() => store.state.userDetails.currencyName);
  const cs = computed(() => store.state.userDetails.cs);
  
  return {
    currency: currencyName.value,
    cs: cs.value,
    cLeftFormat: currency.leftFormat,
    cNumber: currency.number,
    cPrice: (value) => currency.price(value, cs.value),
  };
}