<template>
  <template v-if="isReportDataLoaded">
    <h4>{{ subtitle }}</h4>
    <p class="flex justify-center my-1">
      <StageTotals
        :stage="stage"
        class="mx-1"
      />
    </p>
    <template v-if="chartData?.length">
      <MultiChart
        v-if="isChart"
        :chart-data="chartData"
        :chart-type="currentChartType"
        column-key="potential_value"
        :format-func="(value) => abbr.int(value)"
      />
      <ReportTable
        v-if="settings.isTableShown"
        class="report-table"
        :report-data="tableData"
        :visual-input="inputData?.visualInput"
      />
    </template>
    <p v-else>
      {{ t('No results were found') }}
    </p>
  </template>
  <BufferImage
    v-if="!isReportDataLoaded"
    class="loading-spinner mt-4"
    color="var(--colour-utility-black)"
    float="center"
  />
</template>

<script setup>
// SalesOpsReportContent
import { computed, onMounted, ref, watch } from 'vue';
import { BufferImage } from '@sales-i/dsv3';
import { t, abbr } from '@sales-i/utils';
import ReportTable from '@/intelligence/components/Dashboard/Elements/ReportTable.vue';
import { useStore } from 'vuex';
import {
  FETCH_STAGES_DATA,
  FETCH_TABLE_DATA,
  FETCH_CHART_DATA,
} from '@/intelligence/store/actionType';
import StageTotals from '@/intelligence/components/SalesOpportunity/StageTotals.vue';
import MultiChart from '@/intelligence/components/Dashboard/Elements/MultiChart.vue';
import { useWidget } from '@/intelligence/components/Dashboard/composables/useWidget';
import { BAR_CHART, PIE_CHART } from '@/intelligence/components/Dashboard/constants';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  settings: {
    type: Object,
    default: () => ({}),
  },
});

const store = useStore();
const emit = defineEmits(['update:menuOptions']);

onMounted(() => {
  loadReport();
  emit('update:menuOptions', menuOptions.value);
});

watch(
  () => props.settings,
  () => {
    emit('update:menuOptions', menuOptions.value);
  },
  { deep: true }
);

const { 
  fetchDates, 
  getDateFilterTitle, 
  getDateParams, 
  chartAndTableMenuOptions, 
  currentChartType,
  getSortedAndSlicedData,
  DEFAULT_WIDGET_SETTINGS 
} = useWidget({ store, props, options: { chartTypes: [BAR_CHART, PIE_CHART] }});

const dateParams = computed(() => getDateParams(filters.value));
const dateFilterTitle = computed(() => getDateFilterTitle(dateParams.value));

const stageName = computed(() => stage.value.stage_name);
const subtitle = computed(() =>
  chartData.value?.length 
    ? `${stageName.value}, ${filters.value.entity} - ${filters.value.opportunityType}, ${dateFilterTitle.value} ${reportDateBoundaries.value}` 
    : ''
);

const isReportDataLoaded = ref(false);
const noResults = ref(false);

const inputData = computed(() => store.getters['intelligence/shared/getInputData'](props.item?.reportType));

const menuOptions = computed(() => chartAndTableMenuOptions.value);
const isChart = computed(() => 
  inputData.value && props.settings.isChartShown
);

const filters = computed(() => 
  props.item?.requestParams || {}
);

const stages = ref([]);

const stage = computed(() => 
  stages.value.stages?.find(s => s.stage_id == filters.value.stageId) || {}
);

const chartDataFull = ref([]);
const chartData = computed(() => getSortedAndSlicedData({ 
  arr: chartDataFull.value, 
  sort: 'desc', 
  limit: props.settings.limit || DEFAULT_WIDGET_SETTINGS.limit, 
  key: 'potential_value'
})
);

const tableDataFull = ref([]);
const tableData = computed(() => getSortedAndSlicedData({ 
  arr: tableDataFull.value?.customers, 
  sort: 'desc', 
  limit: props.settings.limit || DEFAULT_WIDGET_SETTINGS.limit, 
  key: 'potential_value'
}));

const reportDateBoundaries = computed(() => {
  if (!chartData.value) return '';

  const reportDataKeys = Object.keys(chartData);
  if (
    !reportDataKeys.length ||
    !reportDataKeys.includes('date_range') ||
    (reportDataKeys.includes('customers') && !chartData.value.customers.length)
  )
    return '';
  const dateBoundaries = chartData.value.date_range;
  return `${new Date(dateBoundaries.start).toLocaleDateString('en-GB')} - ${new Date(
    dateBoundaries.end
  ).toLocaleDateString('en-GB')}`;
});

const fetchStagesData = params => store.dispatch(`intelligence/performanceInsight/${FETCH_STAGES_DATA}`, params);
const fetchTableData = params => store.dispatch(`intelligence/performanceInsight/${FETCH_TABLE_DATA}`, params);
const fetchChartData = params => store.dispatch(`intelligence/performanceInsight/${FETCH_CHART_DATA}`, params);

async function loadReport() {
  if (!inputData.value) return;

  isReportDataLoaded.value = false;

  const {
    stageId,
    opportunityType,
    entity = inputData.value.defaultChartSortOption1,
    order = inputData.value.defaultChartSortOption2,
  } = filters.value;

  const { date_to, date_from } = await fetchDates(dateParams.value);
  const payload = { entity, order, stageId, opportunityType, date_from, date_to };
  
  /** stages check */
  stages.value = await fetchStagesData(payload);
  noResults.value = false;
  // the stage does not exist anymore or there is no stages
  if (!stage.value.stage_id) {
    noResults.value = true;
    isReportDataLoaded.value = true;
    return;
  }

  chartDataFull.value = await fetchChartData(payload);
  tableDataFull.value = await fetchTableData(payload);

  isReportDataLoaded.value = true;
}
</script>

<style lang="scss" scoped>
h4 {
  text-align: center;
}
.report-table {
  margin-bottom: var(--spacing-1);
  max-height: 260px;
  /** todo: improve this later - it's temporary solution to not let chart be on top of the table */
  z-index: 1;
}
</style>
