import InteractionsSettingsView from '@/admin/views/CRMSetup/InteractionsSettingsView';
import Fields from '@/admin/views/CRMSetup/Fields.vue';
import { interactionsSection as section } from '@/admin/router/urlBits';

export default {
  path: `:entity(${section})`,
  component: InteractionsSettingsView,
  children: [
    {
      path: '',
      component: Fields,
    },
  ],
};
