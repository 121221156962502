export default {
  breakpoints: {
    xs: 0,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1440,
    xxl: 1920
  },
  defaultBreakpoint: 'xs',
  smAndDown: ['xs', 'sm',],
  mdAndDown: ['xs', 'sm', 'md',],
  lgAndDown: ['xs', 'sm', 'md', 'lg',],
  xlAndDown: ['xs', 'sm', 'md', 'lg', 'xl'],
  mdAndUp: ['md', 'lg', 'xl', 'xxl'],
  lgAndUp: ['lg', 'xl', 'xxl'],
  xlAndUp: ['xl', 'xxl'],
};
