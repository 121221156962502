<template>
  <!-- eslint-disable vue/no-v-html -->
  <span v-html="computedText" />
</template>

<script>
import { replaceSpaceWithNBSP } from '@/shared/utils/replaceSpaceWithNBSP';

export default {
  name: 'ReplaceSpaceWithNBSPComponent',
  components: {},
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    computedText() {
      return replaceSpaceWithNBSP(this.text);
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
