import { checkPermissionsActions, checkPermissionsGetters, checkPermissionsMutations, } from './checkPermissions';

import {
  SET_ROLE,
  SET_REJECTION_ACTION,
  REJECT_ACCESS,
  CHECK_PERMISSIONS,
} from '@/shared/store/actionType';

// initial state
const state = () => ({
  permissionsGranted: {},
  featureFlags: [],
  rejection_action: false
});

const getters = {
  ...checkPermissionsGetters,
};

// mutations
const mutations = {
  ...checkPermissionsMutations,
  [SET_ROLE]: (state, role) => {
    state.role = role;
  },
  [SET_REJECTION_ACTION]: () => {},
  [REJECT_ACCESS]: (state) => {
    state.rejection_action = false;
  },
};

// actions
const actions = {
  ...checkPermissionsActions,
  // this is probably just for demo purposes, remove when API comes in?
  [SET_ROLE]: ({ commit, dispatch, }, role) => {
    commit(SET_ROLE, role);
    dispatch(CHECK_PERMISSIONS);
  },
  [SET_REJECTION_ACTION]: ({ commit }) => {
    commit(SET_REJECTION_ACTION);
  },
  [REJECT_ACCESS]: ({ commit }) => {
    commit(REJECT_ACCESS);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
