// This code isn't great, improve if you like
// but it works
import gql from 'graphql-tag';

const buildTree = (branch, tree) => {
  let x = 0;
  for (x in branch) {
    const _ref = branch[x];
    tree[_ref.name.value] = {};
    if ('values' in _ref.value && _ref.value.values.length > 0) {
      tree[_ref.name.value] = [];
      let p = 0;
      for (p in _ref.value.values) {
        let n = 0;
        tree[_ref.name.value][p] = {};
        for (n in _ref.value.values[p].fields) {
          const _refn = _ref.value.values[p].fields[n];
          if ('values' in _refn.value) {
            if (_refn.name.value === 'dimensions') {
              tree[_ref.name.value][p] = buildTree([_refn], tree[_ref.name.value][p]);
            } else {
              let q = 0;
              tree[_ref.name.value][p][_refn.name.value] = [];
              for (q in _refn.value.values) {
                tree[_ref.name.value][p][_refn.name.value].push(_refn.value.values[q].value);
              }
            }
          } else {
            tree[_ref.name.value][p][_refn.name.value] = _refn.value.value;
          }
        }
      }
    } else if ('fields' in _ref.value) {
      tree[_ref.name.value] = buildTree(_ref.value.fields, tree[_ref.name.value]);
    } else {
      tree[_ref.name.value] = _ref.value.value;
    }
  }
  return tree;
};

const discernTree = (rawData) => {
  // Check raw data for the values we need
  const treeData = rawData?.definitions[0]?.selectionSet?.selections[0]?.arguments;
  let tree = {};

  if (treeData.length > 0) {
    tree = buildTree(treeData, tree);
  }
  return tree;
};

const parseQuery = (query) => {
  return discernTree(gql(query));
};

export {
  parseQuery,
};