<template>
  <div v-if="!navigationData.disabled">
    <EnquiryNav
      :active-area-prop="navigationData.area"
      :report-type="navigationData.urlCode"
      :dates="dateParams"
      :is-date-filter-active="isDateFilterActive"
      hide-drill
      hide-filters
      is-snapshot
      @toggle-date-filter="toggleDateFilter"
    />
    <DatePickerView
      v-if="isDateFilterActive"
      :key="reportKey"
      class="container"
      :report-type="reportType"
      @set-active-date="setActiveDate"
    />
    <TwoColumnLayout
      v-show="!isDateFilterActive"
      :title="navigationData.area"
      :description="navigationData.description"
    >
      <template #beforeHeader>
        <SnapshotSearch
          v-if="predefinedSearchQuery"
          class="search-customers"
          :selected-value="selectedValue"
          :predefined-query="predefinedSearchQuery"
          search-type="contact"
          @item-click="onSearchClick"
        />
      </template>
      <template #sidebar>
        <div
          ref="sidebar"
          class="sidebar-menu"
        >
          <CustomSelect
            class="snapshot-select"
            :label="t('Snapshot page')"
            :placeholder="t('Select')"
            icon-align="right"
            :value="reportType"
            :items="snapshotList"
            @on-change="goToReport"
          />
          <CustomButton
            class="export-button"
            purpose="action"
            small
            @click="exportSnapshot"
          >
            {{ t('Export Snapshot') }}
          </CustomButton>
        </div>
      </template>
      <template #content>
        <router-view
          v-if="isSnapshotVisible"
          :key="reportKey"
          :report-type="reportType"
          snapshot-type="performance"
        />
        <BufferImage
          v-else
          color="black"
          float="center"
          class="loading-spinner"
        />
      </template>
    </TwoColumnLayout>
  </div>
</template>

<script>
import { BufferImage, CustomSelect, CustomButton } from '@sales-i/dsv3';
import { SNAPSHOT_REPORT_LIST, PRODUCT_GROUP_PURCHASE_ANALYSIS, SNAPSHOT_SUMMARY, PRODUCT_GROUP_PURCHASE_ANALYSIS_PIECHART } from '@/intelligence/store/data/reportTypes';
import { GET_SEARCH_BAR_RESULTS } from '@/shared/store/actionType';
import { APPLY_DATE_RANGE, APPLY_NEW_FILTER, CLEAR_REPORT_DATA, FETCH_DATES, REFRESH_REPORT, SET_LOADING } from '@/intelligence/store/actionType';
import { mapActions, mapState } from 'vuex';
import TwoColumnLayout from '@/shared/components/Layout/TwoColumnLayout';
import SnapshotSearch from '@/intelligence/components/SnapshotReport/SnapshotSearch';
import DatePickerView from '@/intelligence/components/Date/DatePickerView';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import { inputSets, snapshotReportSet } from '@/intelligence/store/data/reportsInput';
import useReportPolicies from '@/shared/composables/useReportsPolicies';
import isRolldate from '@/intelligence/store/utils/isRolldate';
import { t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'PerformanceSnapshotView',
  components: {
    TwoColumnLayout,
    DatePickerView,
    BufferImage,
    EnquiryNav,
    CustomSelect,
    CustomButton,
    SnapshotSearch,
  },
  inject: ['mq'],
  props: {
    snapshotType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isDateFilterActive: false,
      isSnapshotVisible: false,
      salesRep: '',
      dateParams: {},
      predefinedSearchQuery: '',
    };
  },
  computed: {
    ...mapState({
      reportKey: state => state.intelligence.shared.reportRefreshCounter,
      fetchedDates: state => state.intelligence.calendar.fetchedDates,
      searchBarResultsInStore: state => state.searchBarResults.data,
      selectedValue: state => state.intelligence.shared.requestParameters.snapshotBubbleValue,
    }),
    navigationData() {
      return inputSets[this.snapshotType];
    },
    reportsPolicies() {
      return useReportPolicies();
    },
    reportType() {
      // Set the value of reportType to the first item in snapshotList
      return this.snapshotList[0].value;
    },
    snapshotList() {
      return SNAPSHOT_REPORT_LIST.reduce((result,report) => {
        if (report === SNAPSHOT_SUMMARY || report === PRODUCT_GROUP_PURCHASE_ANALYSIS || report === PRODUCT_GROUP_PURCHASE_ANALYSIS_PIECHART) return result;
        const text = this.getSnapshotTitle(report);
        const policy = snapshotReportSet[report].policy;

        if (this.reportsPolicies.permissions.some(permission => permission[policy] === true
        || policy === undefined)) {        
          result.push({
            value: report,
            text
          });
        }
        return result;
      }, []);
    }
  },
  async mounted() {
    window.scrollTo(0,0);
    this.reportType = this.$route.name;

    await this.getSearchBarResults({
      url: 'contacts',
      type: 'contact',
      parameters: {
        q: '',
        limit: 1,
        offset: 0,
      },
      moreResults: false
    });
    this.onSearchClick(this.searchBarResultsInStore[0]);
    this.predefinedSearchQuery = this.salesRep;

    if (!Object.values(this.dateParams).length) {
      await this.processDates({
        date_from: 'today/y',
        date_to: 'today',
      });
    }
    this.isSnapshotVisible = true;
  },
  beforeUnmount() {
    this.clearReportData();
  },
  methods: {
    t,
    ...mapActions({
      refreshReport: `intelligence/shared/${REFRESH_REPORT}`,
      applyDateRanges: `intelligence/shared/${APPLY_DATE_RANGE}`,
      fetchDates: `intelligence/calendar/${FETCH_DATES}`,
      setLoading: `intelligence/shared/${SET_LOADING}`,
      getSearchBarResults: `searchBarResults/${GET_SEARCH_BAR_RESULTS}`,
      clearReportData: `intelligence/shared/${CLEAR_REPORT_DATA}`,
      applyRequestParam: `intelligence/templateReport/${APPLY_NEW_FILTER}`,
    }),
    async goToReport(reportType) {
      this.setLoading(true);
      this.reportType = reportType;
      const route = await this.$router.resolve({ name: reportType });
      navigateToUrl(route.href);
      this.refreshReport();
    },
    getSnapshotTitle(reportType) {
      return snapshotReportSet[reportType].title;
    },
    async setActiveDate(dateObject) {
      this.setLoading(true);

      await this.processDates(dateObject);

      if (['xs', 'sm'].includes(this.mq.current)) {
        window.scrollTo(0,this.$refs.sidebar.getBoundingClientRect().bottom - 65);
      }
      
      this.refreshReport();
      this.isDateFilterActive = false;
    },
    async processDates(dateObject) {
      if (isRolldate(dateObject)) {
        dateObject.date_from2 = `${dateObject.date_from}-1y`;
        dateObject.date_to2 = `${dateObject.date_to}-1y`;
        await this.fetchDates(dateObject);
        this.dateParams = this.fetchedDates;
      } else {
        this.dateParams = dateObject;
      }

      this.applyDateRanges(this.dateParams);
    },
    onSearchClick(newValue) {
      if (newValue.header === this.salesRep) return;
      this.salesRep = newValue.header;
      this.applyRequestParam(['snapshotBubbleValue', this.salesRep]);
      this.refreshReport();
    },
    exportSnapshot() {
      console.error('NOT IMPLEMENTED Exporting snapshot...');
    },
    toggleDateFilter() {
      this.isDateFilterActive = !this.isDateFilterActive;
    },
  }
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.demo-export-btn {
  display: flex;
  width: 100px;
  margin-top: var(--spacing-4);
  background-color: var(--colour-utility-action);
  color: var(--colour-utility-white);
  height: 40px;
  border-radius: var(--border-radius-half);
  text-decoration: none;
  justify-content: center;
  align-items: center;
}

.search-customers {
  margin-top: var(--spacing-3);

  :deep(.search-container-outer) {
    margin-bottom: 0;

    .search-container {
      top: 0;
    }
  }
  :deep(.search-button) {
    position: relative;
    bottom: var(--spacing-2);
  }
  :deep(.search-bar-result) {
    top: 60px;
  }
}

:deep(.sidebar-before-header) {
  display: flex;
  flex-direction: column;
}

:deep(.area.customer .container) {
  padding-bottom: 100px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-bottom: var(--spacing-2);
  }
}

:deep(.date-filter.container) {
  margin-bottom: 88px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-bottom: var(--spacing-3);
  }
}

.container {
  margin-top: var(--spacing-3);
  margin-bottom: var(--spacing-3);
}

.sidebar-menu {
  .snapshot-select {
    margin-bottom: var(--spacing-2);
  }
  .export-button {
    font-weight: var(--font-weight-semibold);
    width: 100%;
  }
}
</style>
