<template>
  <Tabs
    twoway
    :items="tabs"
    :selected-index="activeIndex"
    @nav-clicked="navClicked"
  />
  <section class="px-3">
    <router-view />
  </section>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useHead } from '@unhead/vue';
import { t } from '@sales-i/utils';
import Tabs from '@/shared/components/Tabs/Tabs';
import { navigateToUrl } from 'single-spa';
import { iframeArea, overviewSection } from '@/crm/router/urlBits';
import { useRoute } from 'vue-router';

// constants
const tabs = computed(() => {
  return [
    { title: t('Global Insights'), query: 'insights' },
    { title: t('Enquiries'), query: 'enquiries' },
    { title: t('Sales Comparison'), query: 'comparison' },
    { title: t('Opportunities'), query: 'opportunities' },
    { title: t('Alerts'), query: 'alerts' },
  ];
});

const vroute = useRoute();
// data
const activeIndex = ref(0);

onMounted(() => {
  activeIndex.value = vroute.meta.activeIndex || 0;
  const currentItem = tabs.value[activeIndex.value];
  useHead({ title: currentItem.title });
});

function navClicked({ index, item }) {
  activeIndex.value = index;
  useHead({ title: item.title });
  navigateToUrl(`/${iframeArea}/${overviewSection}/${item.query}`);
}
</script>

<style lang="scss" scoped>
</style>
