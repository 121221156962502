<template>
  <div class="checkbox-filter">
    <MqResponsive :target="breakpoints.mdAndUp">
      <div class="checkbox-filter-desktop">
        <slot name="title">
          <span>{{ props.title }}</span>
        </slot>
        <Field
          v-for="(field, index) in checkboxFields"
          :key="`${refreshKey}_${index}`"
          v-bind="field"
          :value="props.modelValue.includes(field.id)"
          @input="value => handleCheckboxInput(value, field.id)"
        />
        <Field
          :id="ALL"
          :label="t('All')"
          type="checkbox"
          :value="all"
          @input="handleAllToggle"
        />
      </div>
    </MqResponsive>
    <MqResponsive :target="breakpoints.smAndDown">
      <Field
        type="multiselect"
        :label="title"
        :options="multiSelectOptions"
        :value="props.modelValue"
        :emit-key="true"
        @input="handleMultiselectInput"
      />
    </MqResponsive>
  </div>
</template>

<script setup>
// CheckboxFilter
import Field from '@/shared/components/Form/Field.vue';
import breakpoints from '@/shared/utils/breakpoints';
import { t } from '@sales-i/utils';
import { ref, computed } from 'vue';
import { MqResponsive } from 'vue3-mq';
const ALL = 'all';

const refreshKey = ref(0);
const all = computed(() => props.modelValue.length === props.options.length);

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
  /**
   * format:
   * { id: 'customer', label: 'Customer profile', default: true },
   * { id: 'opportunity', label: 'Opportunities' },
   * { id: 'interaction', label: 'Interactions' },
   */
  options: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['update:modelValue']);

const checkboxFields = computed(() =>
  props.options.map(f => ({
    id: f.id,
    label: f.label,
    type: 'checkbox',
    required: false,
    disabled: false,
  }))
);

const multiSelectOptions = computed(() => [
  ...props.options.map(f => ({
    value: f.id,
    text: f.label,
  })),
]);

const onlyDefaultOptionToEmit = computed(() => props.options.filter(o => o.default).map(o => o.id));

const allOptionsToEmit = computed(() => props.options.map(o => o.id));

function handleAllToggle() {
  if (all.value) emit('update:modelValue', onlyDefaultOptionToEmit.value);
  else emit('update:modelValue', allOptionsToEmit.value);
}

function handleCheckboxInput(value, fieldId) {
  if (value) emit('update:modelValue', [...(props.modelValue || []), fieldId]);
  else {
    let valuesToEmit = props.modelValue.filter(f => f != fieldId);
    emit('update:modelValue', valuesToEmit.length ? valuesToEmit : onlyDefaultOptionToEmit.value);
    refreshKey.value = refreshKey.value + 1;
  }
}

function handleMultiselectInput(selectedValues) {
  let valuesToEmit = selectedValues.map(sv => sv.value);
  // if everything deselected - set default option
  if (!valuesToEmit.length) {
    valuesToEmit = onlyDefaultOptionToEmit.value;
  }
  emit('update:modelValue', valuesToEmit);
}
</script>

<style lang="scss" scoped>
.checkbox-filter {
  padding-top: var(--spacing-half);
  .checkbox-filter-desktop {
    display: flex;
    align-items: center;
    .field {
      margin-left: var(--spacing-1);
    }
    span {
      margin-bottom: var(--spacing-1);
    }
  }
}
</style>
