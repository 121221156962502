<template>
  <div>
    <!-- Form fields -->
    <div
      v-for="(section, sectionIndex) in formData"
      :key="sectionIndex"
    >
      <div class="flow">
        <h3 class="fw-sbld">
          {{ t(section.sectionTitle) }}
        </h3>
        <div class="opportunities-form-wrapper">
          <div
            v-for="(field, fieldIndex) in section.fields"
            :key="field.id"
            class="field"
          >
            <Field
              v-bind="field"
              :ref="field.id"
              v-model="formData[sectionIndex].fields[fieldIndex].value"
              :placeholder="field.placeholder === shouldUseCurrency ? cs : field.placeholder"
              :class="field.id"
              @valid="handleFieldValid"
              @keydown="handleKeydown"
              @keypress="handleKeypress"
              @keyup="handleKeyup"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-actions">
      <CustomButton
        v-scroll-to-top
        :label="t('Continue')"
        :class="`action ${valid ? '' : 'disabled'}`"
        @on-click="goNextStep"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { USE_CURRENCY } from '@/crm/store/data/interaction';
import { baseUrl as crmBaseUrl, interactionsArea } from '@/crm/router/urlBits';
import useCurrency from '@/shared/composables/useCurrency';
import { useGenerateInteractionValidation } from './composables/useGenerateInteractionValidation';
import { pluralise, t } from '@sales-i/utils';
import { CustomButton } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field.vue';

const emit = defineEmits(['stageUpdate']);
const vroute = useRoute();

const { cs } = useCurrency();

const shouldUseCurrency = ref(USE_CURRENCY);
const selectedIndex = ref(1);
const nextStep = ref('');

const greaterThan = computed(() => formData?.value[0]?.fields[0]?.value);
const lessThan = computed(() => formData?.value[0]?.fields[1]?.value);
const discardOpps = computed(() => formData?.value[0]?.fields[2]?.value);

const valid = computed(() => {
  if (!formData?.value?.length) return false;
  return greaterThan.value < lessThan.value || !discardOpps.value;
});

const {
  entityType,
  formData,
  handleKeydown,
  handleKeypress,
  handleKeyup,
  handleFieldValid,
  goNextStep,
} = useGenerateInteractionValidation(emit, selectedIndex, nextStep, { valid });

onMounted(() => {
  emit('stageUpdate', 'Adding opportunities');
  setNextStep();
});

const setNextStep = () => {
  nextStep.value = `${crmBaseUrl}/${interactionsArea}/${vroute.params.entityPath}/location-of-${pluralise.string(
    entityType.value
  )}`;
};
</script>

<style lang="scss" scoped>
.opportunities-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  max-width: 36rem;

  @media (min-width: 650px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: var(--spacing-4);
    grid-row-gap: var(--spacing-2);
    max-width: 40rem;
  }

  :deep(.form-group) {
    align-items: center;
    gap: var(--spacing-2);

    label {
      margin-bottom: 0;
    }

    .error-text {
      grid-area: 2 / 2 / 3 / 3;
    }

    @media (min-width: 650px) {
      display: grid;
      grid-template-columns: auto 1fr;
    }
  }
}
// Make the third field span the whole width of the grid
.field:nth-child(3) {
  grid-area: 2 / 1 / 3 / 3;
}
</style>
