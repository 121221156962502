import {
  GET_ALL,
  CLEAR_ALL,
} from '@/shared/store/actionType';
import { computed, onMounted } from 'vue';

export function useUsers(store, loadUsersOnMount = true, forceUsersReload = false) {
  const users = computed(() => store.state.users.data);
  const usersLoaded = computed(() => store.state.users.loaded);
  const usersLoading = computed(() => store.state.users.loading);
  const usersOptions = computed(() => store.getters['users/getUsersOptions']);
  const usersForMentionable = computed(() => store.getters['users/getUsersForMentionable']);

  onMounted(() => {
    if (loadUsersOnMount) {
      getUsers();
    }
  });

  const getUsers = () => {
    // do not allow multiple user loading from different components using this module
    // check if users were loaded and if no force reload do not load them again
    if (!usersLoading.value && (!usersLoaded.value || forceUsersReload)) 
      store.dispatch(`users/${GET_ALL}`);
  };
  const clearUsers = () => store.dispatch(`users/${CLEAR_ALL}`);
    
  const getUserById = (userId) => {
    if (!userId || !users.value) return {};
    return users.value.find(u => u.id == userId) || {};
  };
  
  return {
    users,
    usersOptions,
    usersForMentionable,
    getUsers,
    clearUsers,
    getUserById
  };
}