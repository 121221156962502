import CustomerSnapshotView from '@/intelligence/views/SnapshotReport/CustomerSnapshotView.vue';
import PerformanceSnapshotView from '@/intelligence/views/SnapshotReport/PerformanceSnapshotView.vue';
import { baseUrl, branchScope, customerScope, insightsScope, performanceScope, snapshotReportScope } from '@/intelligence/router/urlBits';
import { BRANCH_SNAPSHOT, CUSTOMER_SNAPSHOT, PERFORMANCE_SNAPSHOT, SNAPSHOT_REPORT_LIST, SNAPSHOT_SUMMARY } from '@/intelligence/store/data/reportTypes';
import SnapshotReport from '@/intelligence/components/SnapshotReport/SnapshotReport';
import SnapshotSummary from '@/intelligence/components/SnapshotReport/SnapshotSummary';

const printSnapshotReports = () => SNAPSHOT_REPORT_LIST
  .filter(report => report !== SNAPSHOT_SUMMARY)
  .map(report => ({
    name: report,
    path: report,
    component: SnapshotReport,
  }));

export default [
  {
    path: `${baseUrl}/${insightsScope}/${customerScope}/${snapshotReportScope}`,
    component: CustomerSnapshotView,
    name: CUSTOMER_SNAPSHOT,
    children: [
      ...printSnapshotReports(),
      {
        name: SNAPSHOT_SUMMARY,
        path: SNAPSHOT_SUMMARY,
        component: SnapshotSummary
      }
    ]
  },
  {
    path: `${baseUrl}/${insightsScope}/${performanceScope}/${snapshotReportScope}`,
    component: PerformanceSnapshotView,
    props: { snapshotType: PERFORMANCE_SNAPSHOT },
  },
  {
    path: `${baseUrl}/${insightsScope}/${branchScope}/${snapshotReportScope}`,
    component: PerformanceSnapshotView,
    props: { snapshotType: BRANCH_SNAPSHOT },
  },
];
