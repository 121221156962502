import { menuMutations, menuActions } from './menu';
import { featuresMutations, featuresActions } from './features';
import { deleteByIdMutations, deleteByIdActions } from './deleteById';
import { getByIdMutations, getByIdActions } from './getById';
import { getRolesGetters, getRolesMutations, getRolesActions } from './getRoles';
import { getSummaryGetters, getSummaryMutations, getSummaryActions } from './getSummary';
import { postMutations, postActions } from './post';

// initial state
const state = () => ({
  menu: {
    data: [],
    loaded: false,
    loading: false,
    errors: undefined,
  },
  roles: {
    data: [
      {
        id: 3,
        name: 'Admin user',
        description: '',
        user_count: 1,
      },
      {
        id: 2,
        name: 'Sales user',
        description: '',
        user_count: 4,
      },
      {
        id: 1,
        name: 'Power user',
        description: '',
        user_count: 1,
      },
    ],
    loading: false,
    loaded: false,
    errors: undefined,
  },
  currentlyEditing: {
    details: {},
    features: [],
  },
  features: {
    data: [],
    loading: false,
    loaded: false,
    errors: [],
  },
  selected: {
    data: {},
    loading: false,
    loaded: false,
  },
  summary: {
    data: {},
    loaded: false,
    loading: false,
    error: undefined,
  },
});

// getters
const getters = {
  ...getRolesGetters,
  ...getSummaryGetters,
};

// mutations
const mutations = {
  ...menuMutations,
  ...featuresMutations,
  ...deleteByIdMutations,
  ...getByIdMutations,
  ...getRolesMutations,
  ...getSummaryMutations,
  ...postMutations,
};

// actions
const actions = {
  ...menuActions,
  ...featuresActions,
  ...deleteByIdActions,
  ...getByIdActions,
  ...getRolesActions,
  ...getSummaryActions,
  ...postActions
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
