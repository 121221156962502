<template>
  <div
    class="area"
    :class="[{ 'full-width': fullWidthColumns }, title.toLowerCase(), description ? '' : 'no-description']"
  >
    <div class="container flow">
      <div
        v-if="description"
        class="description"
        :class="title.toLowerCase()"
      >
        <i>{{ description }}</i>
      </div>

      <CustomCard purpose="reversed">
        <div
          class="wrapper"
          :style="cssVars"
        >
          <div class="sidebar">
            <div
              v-if="hasBeforeHeader"
              class="sidebar-before-header"
            >
              <slot name="beforeHeader" />
            </div>
            <div class="sidebar-content flow">
              <slot name="sidebar" />
            </div>
          </div>
          <div class="main">
            <slot name="content" />
          </div>
        </div>
      </CustomCard>
    </div>
  </div>
</template>

<script>
import { CustomCard } from '@sales-i/dsv3';

export default {
  name: 'TwoColumnLayout',
  components: {
    CustomCard,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    fullWidthColumns: {
      type: Boolean,
      default: false,
    },
    sidebarWidth: {
      type: String,
      default: '340px',
    },
  },
  data() {
    return {
      sidebarVisible: true,
    };
  },
  computed: {
    hasBeforeHeader() {
      return !!this.$slots.beforeHeader;
    },
    cssVars() {
      return {
        '--sidebar-width': this.sidebarWidth
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.container {
  padding: var(--spacing-2);
}

.area {
  --panel-colour: var(--colour-panel-g-2);

  background: linear-gradient(to bottom, transparent 0 var(--spacing-5), var(--panel-colour) var(--spacing-5));
  width: 100%;
  position: relative;
  margin-top: calc(var(--spacing-2) * -1);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-top: calc(var(--spacing-3) * -1);
  }

  &.performance {
    --panel-colour: var(--colour-panel-performance);
  }

  &.products {
    --panel-colour: var(--colour-panel-product);
  }

  &.customer {
    --panel-colour: var(--colour-panel-customer);
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    flex-wrap: nowrap;
    flex-direction: row;

    &.full-width {
      flex-wrap: wrap;
    }
  }

  .c-card {
    box-shadow: none;
    padding: 0;
  }
}

.no-description {
  padding-top: var(--spacing-6);
}

.description {
  border-radius: var(--border-radius-1);
  display: grid;
  font-weight: var(--font-weight-semibold);
  place-content: center;
  padding: calc(var(--spacing-1) * 1.5);

  &.performance {
    background: var(--colour-data-puerto-rico-light);
    color: var(--colour-data-puerto-rico-label);
  }

  &.products {
    background-color: var(--colour-data-viking-light);
    color: var(--colour-data-viking-label);
  }

  &.customer {
    background: var(--colour-data-turmeric-light);
    color: var(--colour-data-turmeric-label);
  }

  &.trends {
    background: var(--colour-panel-g-8);
    color: var(--colour-panel-g-72);
  }
}

.wrapper {
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    display: grid;
    grid-template-columns: var(--sidebar-width) 3fr;
  }
}

.sidebar {
  padding: var(--spacing-3);
  transition: width 0.5s ease-in-out;
  position: relative;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    .full-width & {
      padding: var(--spacing-2) var(--spacing-2) var(--spacing-2) var(--spacing-4);
      width: 100%;
    }
  }

  .sidebar-content {
    width: 100%;
  }

  .sidebar-before-header {
    margin-bottom: var(--spacing-2);
  }

  .sidebar-header {
    display: flex;
    margin-bottom: var(--spacing-1);

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      margin-bottom: var(--spacing-3);
    }

    .heading-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: var(--spacing-1);
      overflow: hidden;

      h2 {
        font-size: var(--font-size-3);
      }
    }
  }
}

.main {
  border-left: 2px solid var(--colour-panel-base);
  overflow: hidden;
  padding: var(--spacing-3) 0;
  min-height: 300px;
}

.content {
  padding: 0 var(--spacing-2);
  display: flex;
  width: 100%;
  transition: width 0.5s ease-in-out;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    .full-width & {
      width: 100%;
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: var(--spacing-2);
  }
}
</style>
