<template>
  <div>
    <div
      v-if="permissions.read"
      class="form-content"
    >
      <BufferImage
        v-if="loading"
        class="buffer"
        color="var(--colour-utility-black)"
        float="center"
      />
      <div
        v-if="loaded"
        class="form-grid flow py-4"
      >
        <h1 class="text-3 fw-sbld">
          {{
            t(`Recent opportunities with ${currentlyEditing.entity.name}`, 'recent_opportunities_with_name', {
              interpolations: {
                name: currentlyEditing.entity.name,
              },
            })
          }}
        </h1>

        <div class="filter-container">
          <div class="primary-filter">
            <div class="mr-1">
              <MenuFilter
                tabindex="0"
                :items="[
                  {
                    text: t('Open'),
                    value: 'open',
                  },
                  {
                    text: t('Won'),
                    value: 'won',
                  },
                  {
                    text: t('Lost'),
                    value: 'lost',
                  },
                  {
                    text: t('Missed'),
                    value: 'missed',
                  },
                  {
                    text: t('Potential'),
                    value: 'potential',
                  },
                  {
                    text: t('Missed Group'),
                    value: 'missedgrp',
                  },
                  {
                    text: t('Potential Group'),
                    value: 'potentialgrp',
                  },
                ]"
                :selected-value="props.status"
                menu-position="right"
                @on-change="filterSelected"
              />
            </div>
          </div>
        </div>

        <div class="three-col-equal-height">
          <SelectableItem
            v-for="(item, index) in itemsFilteredByStatus"
            :id="parseInt(item.id, 10)"
            :key="index"
            :is-selected="isSelected(item)"
          >
            <OpportunityCard
              :links-enabled="getOpportunityCardLinks(item)"
              :item="item"
              :clickable="false"
              @add="onItemClick"
              @remove="onItemClick"
            />
          </SelectableItem>
        </div>
      </div>

      <div
        v-if="permissions.create"
        class="add-icon-wrapper"
      >
        <CustomButton
          icon-name="plus"
          icon-color="white"
          icon-only
          :icon-height="40"
          :icon-width="40"
          @click="addNewOpportunity"
        />
      </div>
    </div>
    <div class="button-group form-actions">
      <CustomButton
        v-if="props.isEditInteraction"
        v-scroll-to-top
        purpose="reversed"
        @on-click="saveAndClose"
      >
        {{ t('Save') }}
      </CustomButton>
      <CustomButton
        v-if="!selectedItems.length"
        v-scroll-to-top
        purpose="action"
        class="continue-without-btn"
        @on-click="nextStep"
      >
        {{ t('Continue without opportunities') }}
      </CustomButton>

      <CustomButton
        v-if="selectedItems.length && permissions.read"
        v-scroll-to-top
        purpose="action"
        @on-click="nextStep"
      >
        {{ t('Continue') }}
      </CustomButton>
    </div>

    <AddEditOpportunityModal
      v-if="isAddEditOpportunityModal && permissions.create"
      :key="opportunityModalKey"
      :entity-id="currentlyEditing.entity.id"
      :entity-type="currentlyEditing.entityType"
      :entity-name="currentlyEditing.entity.name"
      :entity-preselected="!!currentlyEditing.entity.id"
      @close="onCloseOpportunityModal"
      @saved="onOpportunitySaved"
    />
  </div>
</template>

<script setup>
// AddInteractionOpportunity
import { t } from '@sales-i/utils';
import { computed, ref, onMounted, watch } from 'vue';
import { BufferImage, MenuFilter, CustomButton } from '@sales-i/dsv3';
import SelectableItem from '@/crm/components/Calendar/SelectableItem.vue';
import OpportunityCard from '@/crm/components/Opportunity/OpportunityCard.vue';
import AddEditOpportunityModal from '@/crm/components/Opportunity/AddEditOpportunityModal.vue';
import { useStore } from 'vuex';
import { useRoute, } from 'vue-router';
import usePermissions from '@/shared/composables/usePermissions';
import { opportunities as opps } from '@/shared/store/data/policies';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';
import { useInteraction } from '@/crm/composables/useInteraction';
import { useOpportunity } from '@/crm/composables/useOpportunity';
import { navigateToUrl } from 'single-spa';

const { getPermissions } = usePermissions();
const permissions = getPermissions(opps);

const props = defineProps({
  interactionId: {
    type: String,
    default: '',
  },
  isBackdated: {
    type: Boolean,
    default: false,
  },
  isEditInteraction: {
    type: Boolean,
    default: false,
  },
  status: {
    type: String,
    default: 'open',
  },
});

const aimlOpportunityTypes = ['missed', 'potential', 'missedgrp', 'potentialgrp'];

const emit = defineEmits(['stageUpdate', 'filterSelected']);
const isAddEditOpportunityModal = ref(false);

const store = useStore();
const vroute = useRoute();

const selectedOpportunity = computed(() => store.state.crm.opportunities.selected.data);
const loaded = computed(() => store.state.crm.opportunities.all.loaded);
const loading = computed(() => store.state.crm.opportunities.all.loading);

const items = computed(() => opportunities.value.data);
const itemsFilteredByStatus = computed(() => {
  if (aimlOpportunityTypes.indexOf(props.status) !== -1) {
    return items.value.filter(item => item.status === 'open' && item.type.toLowerCase() === props.status);
  }
  return items.value.filter(item => item.status === props.status);
});
const selectedItems = computed(() => currentlyEditing.value.opportunities.map(opportunity => opportunity.id));
const opportunityModalKey = computed(() => `${vroute.params.id || 0}_${currentlyEditing.value.entity.name}`);

onMounted(() => {
  emit('stageUpdate', 'Opportunity');
  getOpportunitiesOnFirstLoad();
  // If we have opportunities already, set them as selected
  // i.e. if people use the "back" functionality
  // selectedItems.value = currentlyEditing.value.opportunities.map(o => o.id);
});

const { opportunities, getOpportunities } = useOpportunity({ store, emit, vroute });
const { currentlyEditing, setOpportunity, removeOpportunity, putInteraction } = useInteraction({
  store,
  emit,
  vroute,
});

watch(
  () => props.status,
  (current, old) => {
    if (current !== old) return loadEntityOpportunitiesByType();
  }
);

function isSelected(item) {
  return selectedItems.value.includes(parseInt(item.id, 10));
}
function onCloseOpportunityModal() {
  isAddEditOpportunityModal.value = false;
}
function getOpportunityCardLinks(item) {
  return isSelected(item) ? ['remove'] : ['add'];
}

function loadEntityOpportunitiesByType() {
  let type = 'manual';

  // Check if there is an opportunity loaded
  // default to that type
  if (currentlyEditing.value.opportunities.length > 0) {
    type = currentlyEditing.value.opportunities[0].type;
  }

  if (aimlOpportunityTypes.indexOf(props.status) !== -1) {
    type = props.status;
  }

  getOpportunities({
    entity_id: currentlyEditing.value.entity.id,
    type,
  });
}

function getOpportunitiesOnFirstLoad() {
  if (props.interactionId) {
    setTimeout(() => {
      if (currentlyEditing.value.entity.name) {
        loadEntityOpportunitiesByType();
      } else {
        getOpportunitiesOnFirstLoad();
      }
    }, 100);
  } else {
    loadEntityOpportunitiesByType();
  }
}
function onItemClick(item) {
  if (isSelected(item)) removeOpportunity({ opportunity: item });
  else setOpportunity({ opportunity: item });
}
function addNewOpportunity() {
  isAddEditOpportunityModal.value = true;
}
function onOpportunitySaved() {
  loadEntityOpportunitiesByType();
  setOpportunity({
    opportunity: { ...selectedOpportunity.value },
  });
  onCloseOpportunityModal();
}
async function saveAndClose() {
  await putInteraction({ id: vroute.params.id });
  navigateToUrl(`${baseUrl}/${interactionsArea}/`);
}

function filterSelected(value) {
  emit('filterSelected', value);
}
function nextStep() {
  if (selectedItems.value.length && props.isBackdated) {
    navigateToUrl(`${baseUrl}/${interactionsArea}/add/update-opportunity`);
  } else if (props.interactionId) {
    navigateToUrl(`${baseUrl}/${interactionsArea}/${props.interactionId}/edit/further-details`);
  } else {
    navigateToUrl(`${baseUrl}/${interactionsArea}/add/further-details`);
  }
}
</script>
<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.menu-filter {
  min-width: 100px;
}

.add-icon-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -56px;
  position: relative;
  z-index: 1;
}
.continue-without-btn {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    margin-top: var(--spacing-2);
  }
}
</style>
