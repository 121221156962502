<template>
  <div>
    <CustomButton
      class="export-button"
      purpose="action"
      small
      @click="modalTrigger"
    >
      {{ title }}
    </CustomButton>
    <CustomModal
      id="export-modal"
      :title="title"
      :show-modal="isModalVisible"
      @close-modal="closeModal"
    >
      <p class="subtitle">
        {{ t('Export as') }}
      </p>
      <CustomSelect
        :value="selectedFileFormat"
        :items="fileFormats"
        @on-change="onFileFormatChange"
      />
      <div>
        <CustomButton
          v-if="!downloading"
          purpose="action"
          @click="exportHandler"
        >
          {{ t('Export') }}
        </CustomButton>
        <BufferImage
          v-else
          color="black"
          float="center"
          class="loading-spinner"
        />
      </div>
    </CustomModal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { CustomModal, CustomButton, CustomSelect, BufferImage } from '@sales-i/dsv3';
import { EXPORT_REPORT } from '@/intelligence/store/actionType';
import { GET_BY_ID } from '@/shared/store/actionType';
import useCurrency from '@/shared/composables/useCurrency';
import { t } from '@sales-i/utils';
import { address as addr } from '@sales-i/utils';

export default {
  components: {
    CustomButton,
    CustomModal,
    CustomSelect,
    BufferImage,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    dateParams: {
      type: Object,
      default: () => ({}),
    },
    customerObject: {
      type: Object,
      default: () => ({}),
    },
    exportComponentKey: {
      type: String || Number,
      default: '',
    },
    isSnapShot: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    let { cs } = useCurrency();
    return { cs };
  },
  data() {
    return {
      selectedFileFormat: 'PDF',
      isModalVisible: false,
      downloading: false,
      address: '',
      fileFormats: [
        {
          text: 'PDF',
          value: 'PDF',
        },
        {
          text: 'PPTX',
          value: 'PPTX',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      userDetails: state => state.userDetails,
      platform: state => state.platform,
      customer: state => state.crm.customers.selected.data,
    }),
  },
  watch: {
    exportComponentKey: {
      handler() {
        if(this.isSnapShot) this.getCustomerAddress();
      },
      immediate: true,
    },
  },
  methods: {
    t,
    ...mapActions({
      exportReport: `intelligence/templateReport/${EXPORT_REPORT}`,
      getById: `crm/customers/${GET_BY_ID}`,
    }),
    modalTrigger() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    async exportHandler() {
      const reporter = `${this.userDetails.data.first_name} ${this.userDetails.data.last_name}`;
      const { account_number, name } = this.customerObject;
      const address = this.isSnapShot ? this.address : addr.asString(this.customer.addresses[0]);
      const { date_from, date_to, date_from2, date_to2 } = this.dateParams;

      if (this.platform.data === 'mobile') {
        name.trim();

        const mobileData = {
          reporter,
          account_number,
          address,
          date_from,
          date_to,
          date_from2,
          date_to2,
          fileExt: this.selectedFileFormat,
          customerName: name,
          completePeriodsOnly: false,
          currency: this.cs,
        };
        try {
          window.webkit.messageHandlers.exportSnapshotCallbackHandler.postMessage(JSON.stringify(mobileData));
        } catch (err) {
          // onErr
        }
      } else {
        this.downloading = true;
        await this.exportReport({
          account_number,
          reporter,
          address,
          date_from,
          date_to,
          date_from2,
          date_to2,
          name,
          fileExt: this.selectedFileFormat,
          currency: this.cs,
        });
        this.downloading = false;
        this.closeModal();
      }
    },
    onFileFormatChange(format) {
      this.selectedFileFormat = format;
    },
    async getCustomerAddress() {
      let addressString = '';
      await this.getById({
        id: this.customerObject.id,
      });
      if (this?.customer?.addresses?.length) {
        return this.address = addr.asString(this.customer.addresses[0]);
      }
      // regex removes last comma, from string, if exists
      return (this.address = addressString.replace(/,\s*$/, ''));
    },
  },
};
</script>
<style lang="scss">
@import '@/shared/assets/scss/_variables';

#export-modal {
  .subtitle {
    margin-bottom: var(--spacing-1);
    color: var(--colour-utility-base);
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    min-width: initial;
    max-width: 360px;
  }

  .content-wrapper .content {
    padding: 0 var(--spacing-3);
  }
}

.export-button {
  width: 100%;
}
</style>
