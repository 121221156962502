<template>
  <TabsAndTagsLayout
    v-if="permissions.read"
    entity="opportunity"
    :entity-id="item.id"
    :title="title"
    :tabs="tabs"
    :tab-selected-index="tabIndex"
    :show-cta="showCta"
    :cta-title="t('Edit opportunity')"
    @cta-click="handleEditClick"
    @tab-selected="handleTabSelected"
  >
    <template #subtitle>
      <!-- eslint-disable-next-line vuejs-accessibility/heading-has-content -->
      <h4 class="subtitle">
        <ReplaceSpaceWithNBSPComponent :text="item.entity_name" />
      </h4>

      <div class="values-head">
        <div class="value-col left">
          <div>
            <h5 class="value-col-caption">
              {{ t('Value') }}:
            </h5>
            <div class="value-col-value">
              <RagChip
                :currency="currency"
                :value="item.value"
                :green="67"
                :amber="33"
                :prefix="cs"
                abbr
                :small="false"
                class="chip m-half"
              />
            </div>
          </div>
        </div>
        <div class="value-col centre">
          <div>
            <h5 class="value-col-caption">
              {{ t('Weighted Value') }}:
            </h5>
            <div class="value-col-value separators">
              <RagChip
                :currency="currency"
                :value="(item.value / 100) * item.probability"
                :value-percent="item.probability"
                :prefix="cs"
                :green="67000"
                :amber="33000"
                abbr
                :small="false"
                class="chip m-half"
              />
            </div>
          </div>
        </div>
        <div class="value-col right">
          <div>
            <h5 class="value-col-caption">
              {{ t('Probability') }}:
            </h5>
            <div class="value-col-value">
              <RagChip
                :value="item.probability"
                suffix="%"
                :green="67"
                :amber="33"
                :small="false"
                class="chip m-half"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <router-view
        :id="$route.params.id"
        :key="$route.params.id"
        :permissions="permissionsSet"
        :sections-titles="sectionsTitles"
      />
    </template>
  </TabsAndTagsLayout>
</template>

<script setup>
// 'OpportunityView'
import { t } from '@sales-i/utils';
import { ref, onMounted, onUnmounted, watch, computed } from 'vue';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';
import { useUsers } from '@/shared/composables/useUsers';
import usePermissions from '@/shared/composables/usePermissions';
import useCurrency from '@/shared/composables/useCurrency';
import TabsAndTagsLayout from '@/shared/components/Layout/TabsAndTagsLayout.vue';
import ReplaceSpaceWithNBSPComponent from '@/shared/components/ReplaceSpaceWithNBSP.vue';
import { RagChip } from '@sales-i/dsv3';
import { CLEAR_BY_ID, GET_BY_ID, SET_ROUTE, UPDATE_RECENTLY_VIEWED } from '@/shared/store/actionType';
import { baseUrl, opportunitiesArea, customSectionsSection } from '@/crm/router/urlBits';
import { opportunities, posts_comments } from '@/shared/store/data/policies';
import { root as adminArea } from '@/admin/router/urlBits';
import { navigateToUrl } from 'single-spa';

const store = useStore();
const vroute = useRoute();
const { getUserById } = useUsers(store, true);
const { cs, currency } = useCurrency();

const { getPermissions } = usePermissions();
const permissions = getPermissions(opportunities);
const tabIndex = ref(0);

const loading = computed(() => store.state.crm.opportunities.selected.loading);
const item = computed(() => store.getters['crm/opportunities/item']);
const sectionsTitles = computed(() => getSchemaSections.value.customSections);

// permissions for customSections
const permissionsGranted = computed(() => store.state.pbac.permissionsGranted);
const getSchemaSections = computed(() => store.getters[`${adminArea}/schema/getSections`]);
const permissionsSet = computed(() => ({
  entity: permissionsGranted.value[opportunities] || {},
  schema: permissionsGranted.value[opportunities] || {},
}));

const tabs = computed(() => {
  let arr = [
    {
      title: t('Details'),
      path: `${baseUrl}/${opportunitiesArea}/${vroute.params.id}`,
      key: 'details',
    }
  ];
  if (permissionsGranted.value[posts_comments]?.read) {
    arr.push({
      title: t('Posts'),
      path: `${baseUrl}/${opportunitiesArea}/${vroute.params.id}/posts`,
      key: 'posts',
    });
  }
  if (permissionsGranted.value[opportunities]?.read) {
    arr.push({
      title: t('Custom sections'),
      path: `${baseUrl}/${opportunitiesArea}/${vroute.params.id}/${customSectionsSection}`,
      key: customSectionsSection,
    });
  }
  return arr;
});

const title = computed(() => {
  if (loading.value) return t('Loading');
  if (!Array.isArray(item.value.allocated_users) || !item.value.allocated_users.length) return t('Not allocated');
  const firstUserName = getUserById(item.value.allocated_users[0].allocated_user_id).display_name || t('Unknown user');
  return (
    firstUserName + (item.value.allocated_users.length === 1 ? '' : ` (+${item.value.allocated_users.length - 1})`)
  );
});

const showCta = computed(() => {
  return item.value.status == 'open' && !loading.value && permissions.update;
});

watch(
  () => ({ path: vroute.path, id: vroute.params.id }),
  (to, from) => {
    setTabIndex(to.path);
    if (to.id && to.path.includes(opportunitiesArea) && to.id !== from.id) {
      initialLoad();
    }
  }
);

onMounted(async () => {
  setTabIndex(vroute.path);
  await initialLoad();
  await updateRecentlyViewedOpportunity(vroute.params.id);
});

onUnmounted(() => {
  clearItem();
});

const fetchItem = params => store.dispatch(`crm/opportunities/${GET_BY_ID}`, params);
const clearItem = params => store.dispatch(`crm/opportunities/${CLEAR_BY_ID}`, params);
const setRoute = params => store.dispatch(`system/${SET_ROUTE}`, params);
const updateRecentlyViewedOpportunity = opportunityId => store.dispatch(`searchBarResults/${UPDATE_RECENTLY_VIEWED}`, {
  entity_type: 'opportunity',
  entity_id: typeof accountId === 'number' ? opportunityId : parseInt(opportunityId, 10),
});

async function initialLoad() {
  await fetchItem({ id: +vroute.params.id });
}

function handleTabSelected(data) {
  const { index, item } = data;
  tabIndex.value = index;
  if (decodeURI(vroute.path) !== item.path) {
    navigateToUrl(item.path);
  }
}

function setTabIndex(path) {
  tabIndex.value = Math.max(
    0,
    tabs.value.findIndex(e => {
      const keys = path.split('/');
      return keys.includes(e.key);
    })
  );
}

function handleEditClick() {
  if (!permissions.update) return;
  setRoute({
    success: vroute.fullPath,
    cancel: vroute.fullPath,
  });
  navigateToUrl(`${baseUrl}/${opportunitiesArea}/${vroute.params.id}/edit`);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.values-head {
  display: flex;
  gap: var(--spacing-1);
  margin: var(--spacing-3) auto;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    max-width: 400px;
  }
}

.value-col {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.left {
  align-items: flex-end;
}
.centre {
  align-items: center;
  flex: inherit;
}
.right {
  align-items: flex-start;
}

.value-col-caption {
  margin-bottom: var(--spacing-1);
  color: var(--colour-utility-base);
  font-weight: var(---colour-weight-normal);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: var(--font-size-small);
  }
}
.chip {
  font-size: var(--font-size-2);
  line-height: var(--spacing-5);
  padding: var(--spacing-1) var(--spacing-2);
  white-space: nowrap;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: var(--font-size-small);
    line-height: var(--spacing-2);
  }
}
.separators {
  border-right: 1px solid var(--colour-panel-g-16);
  border-left: 1px solid var(--colour-panel-g-16);
  padding-inline: var(--spacing-1);
}
</style>
