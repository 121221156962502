import { httpRequest } from '@sales-i/utils';

export default function useJwt() {
  // Hard coded for a demo to NAPA
  const isNapa = async () => {
    const data = await httpRequest('get', `${process.env.VUE_APP_BUREAU_URI}/v1/user`);
    const { user: { hubs } } = data;
    return hubs[0].id === '00902939-d9b1-4bbd-80f5-9d2cc8e586f7';
  };
  // Local development
  // const isNapa = async () => {
  //   const data = await httpRequest('get', `${process.env.VUE_APP_BUREAU_URI}/v1/user`);
  //   const { user: { hubs } } = data;
  //   return hubs[0].id === '00101581-3dd4-40b8-a2e3-175624586f85';
  // };

  return {
    isNapa,
  };
}