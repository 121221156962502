import {
  GET_MENU_OPTIONS,
  CLEAR_MENU_OPTIONS,
} from '@/admin/store/actionType';
import { t } from '@sales-i/utils';

// getters
export const getters = {
};

// mutations
export const menuMutations = {
  [GET_MENU_OPTIONS]: (state, data) => {
    state.menu.data = data;
    state.menu.loaded = true;
    state.menu.loading = false;
    state.menu.errors = undefined;
  },
  [CLEAR_MENU_OPTIONS]: (state) => {
    state.menu.data = [];
    state.menu.loaded = false;
    state.menu.loading = true;
    state.menu.errors = undefined;
  },
};

// actions
export const menuActions = {
  [GET_MENU_OPTIONS]: async ({ commit, }) => {
    const data = [
      {
        id: 1,
        name: t('Users'),
      },
      {
        id: 2,
        name: t('Roles'),
      },
    ];
    commit(GET_MENU_OPTIONS, data);
  },
  [CLEAR_MENU_OPTIONS]: ({ commit, }) => {
    commit(CLEAR_MENU_OPTIONS);
  },
};
