import { getCustomerSegmentsMutations, getCustomerSegmentsActions } from './getCustomerSegments';

const state = {
  data: [],
  loading: true,
  loaded: false,
  errors: [],
};

const getters = {};

const actions = {
  ...getCustomerSegmentsActions,
};

const mutations = {
  ...getCustomerSegmentsMutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};