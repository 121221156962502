<template>
  <CustomCard
    purpose="reversed"
    :title="title"
    :disabled="disabled"
  >
    <template #header>
      <h3 class="fw-sbld">
        {{ title }}
      </h3>
    </template>
    <EditTranslationField
      :languages="languages"
      :value="value"
    />
    <template #footer>
      <ButtonGroup class="btn-group-bg">
        <CustomButton
          small
          purpose="action"
          :disabled="disabled"
          @click="$emit('update', item)"
        >
          {{ t('Update') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomCard>
</template>

<script>
import { t } from '@sales-i/utils';
import { CustomCard, CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import EditTranslationField from '@/admin/components/Company/Languages/EditTranslationField.vue';
export default {
  name: 'EditTranslationCard',
  components: {
    CustomCard,
    CustomButton,
    ButtonGroup,
    EditTranslationField,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    languages: {
      type: Array,
      required: true,
      default: () => [],
    },
    defaultReset: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update', 'translationReset'],
  data() {
    return {
      item: {
        name: (this.value || {}).name || '',
        data: { ...(this.value || {}).data },
      },
    };
  },
  computed: {
    title() {
      return this.item.name;
    },
    translations() {
      /** @todo remove dummy demo data */
      return [
        {
          name: 'field1',
          data: {
            'en-gb': 'Field one',
            'en-us': "Yeeha! Field one y'all",
          },
        },
        {
          name: 'field2',
          data: {
            'en-gb': 'Field two',
            'en-us': 'Field 2',
          },
        },
      ];
    },
  },
  methods: {
    t,
    reset(language) {
      this.$emit('translationReset', { language, item: this.item });
      if (this.defaultReset) {
        // if property existed return original value
        if (this.value.data && Object.keys(this.value.data).includes(language.value)) {
          this.item.data[language.value] = this.value.data[language.value];
        }
        // if not - delete the prop from data prop
        else {
          this.$delete(this.item.data, language.value);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.c-card .c-card--footer) {
  margin-top: var(--spacing-1);
}
</style>
