import {
  phoneRegex,
  nameRegex,
  emailRegex,
  addressLineRegex,
  townRegex,
  postCodeRegex,
  wwwRegex,
} from '@/shared/regex/form';
import { countryList } from '@/shared/constants/countries/countries';
export const prospectData = [
  {
    section: 'Customer',
    fields: [
      {
        label: 'Company name:',
        id: 'customer',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        errorMessage: 'Please enter a valid customer name',
      },
      {
        label: 'Telephone:',
        id: 'tel_1',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: phoneRegex,
        errorMessage: 'Please enter a valid phone number',
      },
      {
        'label': 'Alt Telephone:',
        'id': 'tel_2',
        'type': 'text',
        'min': 1,
        'max': 40,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'regex': phoneRegex,
        'errorMessage': 'Please enter a valid phone number'
      },
      {
        'label': 'Email:',
        'id': 'email',
        'type': 'email',
        'min': 3,
        'max': 50,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'regex': emailRegex,
        'errorMessage': 'Please enter a valid email'
      },
      {
        'label': 'Fax:',
        'id': 'fax',
        'type': 'text',
        'minLength': 1,
        'maxLength': 40,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'regex': phoneRegex,
        'errorMessage': 'Please enter a valid fax number'
      },
      {
        'label': 'Address line 1:',
        'id': 'address_line_1',
        'type': 'text',
        'minLength': 1,
        'maxLength': 80,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'isAddress': true,
        'regex': addressLineRegex,
        'errorMessage': 'Please enter a valid Address line 1'
      },
      {
        'label': 'Address line 2:',
        'id': 'address_line_2',
        'type': 'text',
        'minLength': 1,
        'maxLength': 80,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'isAddress': true,
        'regex': addressLineRegex,
        'errorMessage': 'Please enter a valid Address line 2'
      },
      {
        'label': 'Address line 3:',
        'id': 'address_line_3',
        'type': 'text',
        'minLength': 1,
        'maxLength': 80,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'isAddress': true,
        'regex': addressLineRegex,
        'errorMessage': 'Please enter a valid Address line 3'
      },
      {
        'label': 'Address line 4:',
        'id': 'address_line_4',
        'type': 'text',
        'minLength': 1,
        'maxLength': 80,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'isAddress': true,
        'regex': addressLineRegex,
        'errorMessage': 'Please enter a valid Address line 4'
      },
      {
        'label': 'Town:',
        'id': 'town',
        'type': 'text',
        'minLength': 1,
        'maxLength': 80,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'isAddress': true,
        'regex': townRegex,
        'errorMessage': 'Please enter a valid town'
      },
      {
        'label': 'County:',
        'id': 'county',
        'type': 'text',
        'minLength': 1,
        'maxLength': 40,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'isAddress': true,
        'regex': townRegex,
        'errorMessage': 'Please enter a valid county'
      },
      {
        'label': 'Postcode:',
        'id': 'post_code',
        'type': 'text',
        'minLength': 1,
        'maxLength': 12,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'isAddress': true,
        'regex': postCodeRegex,
        'errorMessage': 'Please enter a valid post code'
      },
      {
        'label': 'Country:',
        'id': 'country',
        'type': 'select',
        'options': countryList,
        'required': false,
        'disabled': false,
        'isAddress': true
      },
      {
        label: 'Web address:',
        id: 'www',
        type: 'text',
        maxLength: 50,
        required: false,
        disabled: false,
        regex: wwwRegex,
        errorMessage: 'A valid URL requires a name and domain, e.g. "example.com"',
      },
    ],
  },
  {
    section: 'Contact',
    fields: [
      {
        'label': 'Default contact first name:',
        'id': 'first_name',
        'type': 'text',
        'minLength': 1,
        'maxLength': 40,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'regex': nameRegex,
        'errorMessage': 'Please enter a valid first name',
      },
      {
        label: 'Default contact surname:',
        id: 'surname',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: nameRegex,
        errorMessage: 'Please enter a valid surname',
      },
      {
        label: 'Mobile Phone:',
        id: 'mobile_phone',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: phoneRegex,
        errorMessage: 'Please enter a valid mobile number',
      },
      {
        label: 'Business Phone:',
        id: 'business_phone',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: phoneRegex,
        errorMessage: 'Please enter a valid business number',
      },
      {
        label: 'Office Phone:',
        id: 'office_phone',
        type: 'text',
        min: 1,
        max: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: phoneRegex,
        errorMessage: 'Please enter a valid office number',
      },
      {
        label: 'Home Phone:',
        id: 'home_phone',
        type: 'text',
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        regex: phoneRegex,
        errorMessage: 'Please enter a valid home number',
      },
      {
        'label': 'Email:',
        'id': 'email',
        'type': 'email',
        'min': 3,
        'max': 50,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'regex': emailRegex,
        'errorMessage': 'Please enter a valid email'
      },
      {
        'label': 'Fax:',
        'id': 'fax',
        'type': 'text',
        'minLength': 1,
        'maxLength': 40,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'regex': phoneRegex,
        'errorMessage': 'Please enter a valid fax number'
      },
      {
        'label': 'Address line 1:',
        'id': 'address_line_1',
        'type': 'text',
        'minLength': 1,
        'maxLength': 80,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'isAddress': true,
        'regex': addressLineRegex,
        'errorMessage': 'Please enter a valid Address line 1'
      },
      {
        'label': 'Address line 2:',
        'id': 'address_line_2',
        'type': 'text',
        'minLength': 1,
        'maxLength': 80,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'isAddress': true,
        'regex': addressLineRegex,
        'errorMessage': 'Please enter a valid Address line 2'
      },
      {
        'label': 'Address line 3:',
        'id': 'address_line_3',
        'type': 'text',
        'minLength': 1,
        'maxLength': 80,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'isAddress': true,
        'regex': addressLineRegex,
        'errorMessage': 'Please enter a valid Address line 3'
      },
      {
        'label': 'Address line 4:',
        'id': 'address_line_4',
        'type': 'text',
        'minLength': 1,
        'maxLength': 80,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'isAddress': true,
        'regex': addressLineRegex,
        'errorMessage': 'Please enter a valid Address line 4'
      },
      {
        'label': 'Town:',
        'id': 'town',
        'type': 'text',
        'minLength': 1,
        'maxLength': 80,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'isAddress': true,
        'regex': townRegex,
        'errorMessage': 'Please enter a valid town'
      },
      {
        'label': 'County:',
        'id': 'county',
        'type': 'text',
        'minLength': 1,
        'maxLength': 40,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'isAddress': true,
        'regex': townRegex,
        'errorMessage': 'Please enter a valid county'
      },
      {
        'label': 'Postcode:',
        'id': 'post_code',
        'type': 'text',
        'minLength': 1,
        'maxLength': 12,
        'placeholder': '',
        'required': false,
        'disabled': false,
        'isAddress': true,
        'regex': postCodeRegex,
        'errorMessage': 'Please enter a valid post code'
      },
      {
        'label': 'Country:',
        'id': 'country',
        'type': 'select',
        'options': countryList,
        'required': false,
        'disabled': false,
        'isAddress': true
      },
    ],
  },
  {
    section: 'Details',
    fields: [
      {
        label: 'Explain what this prospect relates to:',
        id: 'description',
        type: 'textarea',
        rows: 5,
        minLength: 1,
        maxLength: 40,
        placeholder: '',
        required: false,
        disabled: false,
        'regex': nameRegex,
        'errorMessage': 'Please enter a valid description (only words are allowed)',
      },
    ],
  },
];
