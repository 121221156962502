import {
  GET_FIELDS,
  GET_FIELDS_LOADING,
  GET_FIELDS_ERROR,
  CLEAR_FIELDS,
} from '@/shared/store/actionType';
import {
  GET_RESTRICTIONS_BY_ID,
  GET_RESTRICTIONS_BY_ID_LOADING,
  GET_RESTRICTIONS_BY_ID_ERROR,
  UPDATE_RESTRICTIONS_BY_ID,
  UPDATE_RESTRICTIONS_BY_ID_LOADING,
  UPDATE_RESTRICTIONS_BY_ID_ERROR
} from '@/admin/store/actionType';
import { httpRequest } from '@sales-i/utils';
import { arrayFromRuleset, rulesetFromArray } from '@/admin/store/utils/dataRestrictionFormatting';

// mutations
export const restrictionsMutations = {
  [GET_FIELDS]: (state, data) => {
    state.fields.data = {
      ...state.fields.data,
      ...data,
    };
    state.fields.loading = false;
    state.fields.loaded = true;
  },
  [GET_FIELDS_LOADING]: (state) => {
    state.fields.loading = true;
    state.fields.loaded = false;
  },
  [GET_FIELDS_ERROR]: (state) => {
    state.fields.loading = false;
    state.fields.loaded = false;
  },
  [CLEAR_FIELDS]: (state) => {
    state.fields.loading = false;
    state.fields.loaded = false;
  },
  [GET_RESTRICTIONS_BY_ID]: (state, restrictions) => {
    state.currentlyEditing.restrictions = arrayFromRuleset(restrictions.expression);
    state.restrictionsExist = true;
    state.loading = false;
    state.loaded = true;
  },
  [GET_RESTRICTIONS_BY_ID_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
  },
  [GET_RESTRICTIONS_BY_ID_ERROR]: (state) => {
    state.loading = false;
    state.loaded = false;
    state.restrictionsExist = false;
  },
  [UPDATE_RESTRICTIONS_BY_ID]: (state) => {
    state.loading = false;
    state.loaded = true;
  },
  [UPDATE_RESTRICTIONS_BY_ID_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
  },
  [UPDATE_RESTRICTIONS_BY_ID_ERROR]: (state) => {
    state.loading = false;
    state.loaded = false;
  },
};

// actions
export const restrictionsActions = {
  [GET_FIELDS]: async ({ commit, }, { type, entity } = {}) => {
    commit(GET_FIELDS_LOADING);
    try {
      if (type && entity) {
        const fields = await httpRequest('get', `fields/types/${type}/entities/${entity}/fields`, {}, { area: 'iam', version: '2', });
        commit(GET_FIELDS, {
          [entity]: fields,
        });
      } else {
        const analysis = await httpRequest('get', 'fields/types/analysis/entities', {}, { area: 'iam', version: '2', });
        const crm = await httpRequest('get', 'fields/types/crm/entities', {}, { area: 'iam', version: '2', });
        const operators = await httpRequest('get', 'fields/operators', {}, { area: 'iam', version: '2', });
        commit(GET_FIELDS, {
          crm,
          analysis,
          operators,
        });
      }
      return true;
    } catch (error) {
      commit(GET_FIELDS_ERROR);
      return false;
    }
  },
  [GET_RESTRICTIONS_BY_ID]: async ({ commit, }, { id } = {}) => {
    commit(GET_RESTRICTIONS_BY_ID_LOADING);
    try {
      const userRestrictions = await httpRequest('get', `subjects/${id}/datarestrictions`, {}, { area: 'iam', throwErrors: false });
      commit(GET_RESTRICTIONS_BY_ID, userRestrictions);
    } catch (error) {
      commit(GET_RESTRICTIONS_BY_ID_ERROR);
      return false;
    }
  },
  [UPDATE_RESTRICTIONS_BY_ID]: async ({ commit, state }, { id, } = {}) => {
    const { currentlyEditing: { restrictions }, restrictionsExist } = state;
    commit(UPDATE_RESTRICTIONS_BY_ID_LOADING);
    try {
      if (restrictions.length) {
        await httpRequest(restrictionsExist ? 'put' : 'post', `subjects/${id}/datarestrictions`, rulesetFromArray(restrictions), { area: 'iam', });
      } else if (restrictionsExist) {
        await httpRequest('delete', `subjects/${id}/datarestrictions`, {}, { area: 'iam', });
      }
      return true;
    } catch (error) {
      commit(UPDATE_RESTRICTIONS_BY_ID_ERROR);
      return false;
    }
  },
};
