export const fileTypeCsv         = 'csv';
export const fileTypeDoc         = 'doc';
export const fileTypeDocX        = 'docx';
export const fileTypeGif         = 'gif';
export const fileTypeJPeg        = 'jpeg';
export const fileTypeJpg         = 'jpg';
export const fileTypePdf         = 'pdf';
export const fileTypePng         = 'png';
export const fileTypePowerPoint  = 'ppt';
export const fileTypePowerPointX = 'pptx';
export const fileTypeText        = 'txt';
export const fileTypeExcel       = 'xls';
export const fileTypeExcelX      = 'xlsx';
export const allowedFileExtensions = [
  fileTypeCsv        ,
  fileTypeDoc        ,
  fileTypeDocX       ,
  fileTypeGif        ,
  fileTypeJPeg       ,
  fileTypeJpg        ,
  fileTypePdf        ,
  fileTypePng        ,
  fileTypePowerPoint ,
  fileTypePowerPointX,
  fileTypeText       ,
  fileTypeExcel      ,
  fileTypeExcelX     ,
];