/* The interaction prop in this function is purely for a PERSONAL interaction */
export default function mapInteractionPayload(currentlyEditing, id = false, isPersonal = false, interaction) {
  let {
    dateTime,
    duration,
    entityIDs,
    entityType,
    entity,
    contact,
    opportunities,
    furtherDetails
  } = currentlyEditing;
  let interactionNote = furtherDetails.interactionNote;
  let interactionType = furtherDetails.interactionType;
  let interactionNextAction = furtherDetails.nextAction;
  duration = duration || {};
  if (isPersonal) {
    dateTime = interaction.dateTime;
    interactionNote = interaction.interactionNote;
    duration = {
      hours: interaction.durationHours,
      minutes: interaction.durationMinutes,
    };
  }
  let endDate = new Date(dateTime);
  
  endDate.setHours(endDate.getHours() + parseInt(duration.hours, 10));

  endDate.setMinutes(endDate.getMinutes() + parseInt(duration.minutes, 10));
  let interactionToPost = {
    date_start: dateTime.toISOString(),
    date_end: endDate.toISOString(),
    entity_ids: entityIDs,
    next_action_id: parseInt(interactionNextAction, 10),
    description: interactionNote,
    is_personal: isPersonal,
    interaction_type: parseInt(interactionType, 10),
  };
  if (!isPersonal) {
    let relationships = {};
    if (entityType === 'customer') {
      relationships.customers = [entity.id];
      relationships.prospects = [];
    } else {
      relationships.customers = [];
      relationships.prospects = [entity.id];
    }
    relationships.contacts = [];
    if (contact.id) {
      relationships.contacts = [contact.id];
    }
    relationships.opportunities = opportunities.map(opp => opp.id);
    interactionToPost.relationships = relationships;

    // ADD FURTHER DETAILS
    if (furtherDetails.type) {
      interactionToPost.interaction_type = parseInt(furtherDetails.type, 10);
    }
    if (furtherDetails.purpose) {
      interactionToPost.purpose_id = parseInt(furtherDetails.purpose, 10);
    }
    if (furtherDetails.idealOutcome) {
      interactionToPost.ideal_outcome_id = parseInt(furtherDetails.idealOutcome, 10);
    }
    if (furtherDetails.outcome) {
      interactionToPost.outcome_id = parseInt(furtherDetails.outcome, 10);
    }
    if (furtherDetails.assignee) {
      interactionToPost.user_id = furtherDetails.assignee;
    }
    // interactionToPost.ideal_outcome_id = 863; // hardcoding as endpoint not present, needs discussion
  }
  if (id) {
    interactionToPost.id = parseInt(id, 10);
  }
  return interactionToPost;
}
