/* eslint-disable no-unused-vars */
import {
  GET_ALL,
  GET_ALL_LOADING,
  GET_ALL_ERROR,
  CLEAR_ALL,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import { FIXED_DASHBOARDS } from './fixedWidgets';

// mutations
export const getAllMutations = {
  [GET_ALL]: (state, data) => {
    state.all.data = data || [];
    state.all.loaded = true;
    state.all.loading = false;
  },
  [GET_ALL_LOADING]: (state) => {
    state.all.loaded = false;
    state.all.loading = true;
    state.selected.deleted = false;
  },
  [GET_ALL_ERROR]: (state) => {
    state.all.loaded = false;
    state.all.loading = false;
  },
  [CLEAR_ALL]: (state) => {
    state.all.data = [];
    state.all.loaded = false;
    state.all.loading = false;
  },
};

// actions
export const getAllActions = {
  [GET_ALL]: async ({ commit }) => {
    try {
      commit(GET_ALL_LOADING);
      const data = await httpRequest(
        'get',
        'dashboards',
        {},
        { area: 'dashboard' }
      );
      commit(GET_ALL, [...FIXED_DASHBOARDS, ...data]);
      return true;
    } catch (error) {
      console.error('error', error);
      commit(GET_ALL_ERROR);
      return false;
    }
  },
  [CLEAR_ALL]: ({ commit }) => {
    commit(CLEAR_ALL);
  },
};
