<template>
  <CustomModal
    class="dismiss-modal"
    :show-modal="dismissModalOpen"
    :max-width="600"
    @close-modal="closeDismissModal"
  >
    <div class="container modal-content">
      <h3>
        <IconBase icon-name="warning" />
        {{
          t(`Dismiss ${objectType}`, 'dismiss_this_variable', {
            interpolations: {
              variable: objectType,
            },
          })
        }}
      </h3>

      <p>
        {{ t('Tell sales-ai why this community is not relevant to help provide more informed and effective suggestions in the future.') }}
      </p>

      <Field
        v-bind="dismissFields()['review_type']"
        @input="onFieldInput($event, 'review_type')"
      />
      <Field
        v-bind="dismissFields()['feedback_information']"
        @input="onFieldInput($event, 'feedback_information')"
      />
    </div>
    <template #footer>
      <ButtonGroup
        name="opportunity-actions"
        class="btn-group opportunity-actions"
      >
        <CustomButton
          purpose="reversed"
          small
          @on-click="() => closeDismissModal()"
        >
          {{ t('Cancel') }}
        </CustomButton>
        <CustomButton
          purpose="action"
          small
          @on-click="() => dismissItems()"
        >
          {{ t('Dismiss') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script type="text/javascript">
import { t } from '@sales-i/utils';
import { POST_BY_ENTITY } from '@/shared/store/actionType';
import { CustomModal, IconBase, CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import Field from '@/shared/components/Form/Field.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'DismissModal',
  components: {
    CustomButton,
    ButtonGroup,
    Field,
    IconBase,
    CustomModal,
  },
  props: {
    dismissModalOpen: {
      type: Boolean,
      default: false,
    },
    closeDismissModal: {
      type: Function,
      default: () => {},
    },
    dismissOptions: {
      type: [Object, Array],
      default: () => {},
    },
    objectType: {
      type: String,
      default: 'opportunity',
    },
  },
  emits: ['postFeedback'],
  data() {
    return {
      dismissInput: {
        review_type: 'SINGLE_CUSTOMER',
        feedback_information: '',
      },
    };
  },
  computed: {
    ...mapState({
      feedback: state => state.admin.aiFeedback,
    }),
  },
  watch: {
    dismissModalOpen() {
      this.dismissInput = {
        review_type: 'SINGLE_CUSTOMER',
        feedback_information: '',
      };
    },
  },
  methods: {
    t,
    ...mapActions({
      addFeedback: `admin/aiFeedback/${POST_BY_ENTITY}`,
    }),
    dismissFields() {
      return {
        review_type: {
          id: 'review_type',
          label: t('Who are you dismissing this association for?'),
          type: 'select',
          options: [
            {
              value: 'SINGLE_CUSTOMER',
              text: t('This Customer'),
            },
            {
              value: 'ALL_CUSTOMERS',
              text: t('All Customers'),
            },
          ],
          error: this.dismissInput.review_type === '',
          errorMessage: t('Please select who you are dismissing this association for'),
          placeholder: t('Select'),
          value: this.dismissInput.review_type,
          twoway: true,
          required: true,
        },
        feedback_information: {
          id: 'feedback_information',
          type: 'textarea',
          label: t('Why are you dismissing this association?'),
          value: this.dismissInput.feedback_information,
          twoway: true,
          maxLength: 4000,
        },
      };
    },
    onFieldInput(value, key) {
      this.dismissInput[key] = value;
    },
    async dismissItems() {
      let requestObject = [
        {
          ...this.dismissOptions,
          ...this.dismissInput,
        },
      ];
      if (this.dismissOptions.constructor === Array) {
        requestObject = this.dismissOptions.map(o => ({
          object: o.object,
          object_id: o.object_id,
          feedback_information: this.dismissInput.feedback_information,
          review_type: this.dismissInput.review_type,
        }));
      }

      await this.addFeedback(requestObject);

      if (this.feedback.errors.length === 0) {
        this.$emit('postFeedback');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dismiss-modal {
  h3 {
    text-align: center;
    font-size: 1.5rem;
    margin: 0 0 var(--spacing-2) 0;
  }
  p,
  label {
    font-size: 1rem;
    margin: var(--spacing-2) 0;
  }
  div.svg-container {
    margin: auto;
  }
}
.opportunity-actions {
  display: flex;
  flex-direction: row;

  button {
    margin: 0 10px 0 0;
  }
}
.modal-content {
  padding: var(--spacing-2);

  table {
    width: 100%;
    margin: var(--spacing-2) 0;

    th,
    td {
      padding: var(--spacing-2);
    }
  }

  .association-selection {
    margin-bottom: 0;
  }
}
</style>
