<template>
  <div class="container">
    <Tabs
      :items="navigation"
      :selected-index="selectedIndex"
      @nav-clicked="navClicked"
    />
    <router-view />
  </div>
</template>

<script setup>
// CompanyTabsView
import { t } from '@sales-i/utils';
import { computed, ref, watch, onMounted } from 'vue';
import Tabs from '@/shared/components/Tabs/Tabs.vue';
import { baseUrl, companyArea as area, permissionsSection, tagsSection, targetsSection, logoSection } from '@/admin/router/urlBits';
import { useRoute, } from 'vue-router';
import usePermissions from '@/shared/composables/usePermissions';
import { tags } from '@/shared/store/data/policies';
import { useHead } from '@unhead/vue';
import { navigateToUrl } from 'single-spa';

const { getPermissions } = usePermissions();
const tagsPerm = getPermissions(tags);

const vroute = useRoute();

const selectedIndex = ref(0);
const navigation = computed(() => {
  return [
    tagsPerm.read
      ? {
        title: t('Tags'),
        path: `${baseUrl}/${area}/${tagsSection}`,
        key: 'tags',
      }
      : undefined,
    {
      title: t('Email Sync'),
      path: `${baseUrl}/${area}/email-sync`,
      key: 'email-sync',
    },
    // {
    //   title: t('Languages'),
    //   path: `${baseUrl}/${area}/languages`,
    //   key: 'languages',
    // },
    {
      title: t('Storage'),
      path: `${baseUrl}/${area}/storage`,
      key: 'storage',
    },
    {
      title: t('Permissions'),
      path: `${baseUrl}/${area}/${permissionsSection}`,
      key: 'permissions',
    },
    {
      title: t('Targets'),
      path: `${baseUrl}/${area}/${targetsSection}`,
      key: 'targets',
    },
    {
      title: t('Logo'),
      path: `${baseUrl}/${area}/${logoSection}`,
      key: 'logo',
    },
  ].filter(x => !!x);
});

watch(
  () => vroute.path,
  to => setSelectedIndex(to)
);

onMounted(() => {
  setSelectedIndex(vroute.path);
  // set initial tab
  const currentItem = navigation.value[selectedIndex.value];
  useHead({
    title: currentItem.title,
  });
});

function navClicked(data) {
  const { index, item } = data;
  selectedIndex.value = index;
  if (decodeURI(vroute.path) !== item.path) {
    navigateToUrl(item.path);
  }
  useHead({
    title: item.title,
  });
}

function setSelectedIndex(path) {
  selectedIndex.value = Math.max(
    0,
    navigation.value.findIndex(e => {
      const keys = path.split('/');
      return keys.includes(e.key);
    })
  );
}
</script>

<style lang="scss" scoped></style>
