import {
  POST_BY_ENTITY,
  POST_BY_ENTITY_LOADING,
  POST_BY_ENTITY_ERROR
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const postMutations = {
  [POST_BY_ENTITY]: (state, dashboard) => {
    state.all.data.push(dashboard);
    state.all.loading = false;
    state.all.loaded = true;
  },
  [POST_BY_ENTITY_LOADING]: (state) => {
    state.all.loading = true;
    state.all.loaded = false;
    state.selected.deleted = false;
  },
  [POST_BY_ENTITY_ERROR]: (state) => {
    state.all.loading = false;
    state.all.loaded = false;
    state.all.updated = false;
  },
};

// actions
export const postActions = {
  [POST_BY_ENTITY]: async ({ commit, }, dashboard) => {
    commit(POST_BY_ENTITY_LOADING);
    try {
      const { id } = await httpRequest('post', 'dashboards', dashboard, { area: 'dashboard' });
      commit(POST_BY_ENTITY, { ...dashboard, id });
      return { ...dashboard, id };
    } catch (error) {
      commit(POST_BY_ENTITY_ERROR);
      return false;
    }
  },
};
