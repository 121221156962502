<template>
  <div>
    <div class="container">
      <div
        v-if="!gaps.loading && gaps.loaded && dataRows?.length"
        class="list list-card"
        role="list"
      >
        <Gap
          v-for="item in dataRows"
          :key="item.id"
          :item="item"
        />
      </div>

      <div
        v-if="!gaps.loading && !dataRows?.length"
        class="no-results"
      >
        <h3>{{ t('No product group gaps to follow up on.') }}</h3>
      </div>
      <div
        v-else-if="gaps.loading"
      >
        <BufferImage
          v-if="gaps.loading"
          color="black"
          float="center"
          class="loading-spinner"
        />
      </div>

      <div class="pt-4 flex justify-centre">
        <div class="pagination">
          <Pagination
            :offset="offset"
            :limit="limit"
            :records-count="dataRows?.length"
            @page-changed="handlePageChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Gaps
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import {
  GET_BY_ID,
  GET_SUMMARY,
} from '@/shared/store/actionType';
import { t } from '@sales-i/utils';
import { BufferImage } from '@sales-i/dsv3';
import Gap from '@/intelligence/components/ProductGroups/Gap.vue';
import Pagination from '@/shared/components/Pagination/Pagination.vue';

const store = useStore();
const gaps = computed(() => store.state.intelligence.productGroups);
const dataRows = computed(() => props.entityId ? gaps.value.drilled : gaps.value.data);
const offset = ref(0);
const limit = ref(6);

const props = defineProps({
  entityId: {
    type: Number,
    default: undefined
  } 
});

const getAllGaps = () => {
  if (props.entityId) {
    store.dispatch(`intelligence/productGroups/${GET_BY_ID}`, {
      id: props.entityId,
      offset: offset.value,
      limit: limit.value,
    });
  } else {
    store.dispatch(`intelligence/productGroups/${GET_SUMMARY}`, {
      offset: offset.value,
      limit: limit.value,
    });
  }
};

const handlePageChanged = (offsetValue) => {
  offset.value = offsetValue;
  getAllGaps();
};

onMounted(() => {
  getAllGaps();
});
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

:deep(.list-card.list) {
  gap: var(--spacing-4);
}

.application p {
  margin: 0;
}

header {
  margin:var(--spacing-2) 0 var(--spacing-3);
  display: flex;
  flex-direction: column;
  text-align: center;

  h2 {
    display: flex;
    align-self: center;
  }
}

.no-results {
  h3 {
    margin: var(--spacing-16) auto;
    text-align: center;
  }
}
</style>