import BubbleSelection from '@/intelligence/components/Bubble/BubbleSelection.vue';
import EnquiryReportView from '@/intelligence/components/EnquiryReport/EnquiryReportView';
import { baseUrl, enquiryScope, reportScope } from '@/intelligence/router/urlBits';
import { VARIANCE } from '@/intelligence/store/data/reportTypes';

export default [
  {
    path: `${baseUrl}/${enquiryScope}/${VARIANCE}`,
    component: BubbleSelection,
    name: VARIANCE,
    props: { reportType: VARIANCE }
  },
  {
    path: `${baseUrl}/${enquiryScope}/${VARIANCE}/${reportScope}`,
    component: EnquiryReportView,
    name: `${VARIANCE}-${reportScope}`,
    props: { reportType: VARIANCE }
  }
];
