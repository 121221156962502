<template>
  <div class="container padded">
    <CustomCard
      class="generate-interaction-form"
      purpose="reversed"
    >
      <div class="flow">
        <div class="header">
          <h1 class="fw-bld text-3">
            <slot name="title">
              {{ title }}
            </slot>
          </h1>
          <div>
            <Stage
              :items="stages"
              :selected-index="stage"
              @stage="changeStage"
            />
          </div>
        </div>

        <router-view
          :is-personal="false"
          :is-edit-interaction="false"
          :entity-type="entityType"
          @stage-update="stageUpdate"
        />
      </div>
    </CustomCard>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, } from 'vue-router';
import { CustomCard } from '@sales-i/dsv3';
import Stage from '@/shared/components/Stage/Stage.vue';
import { CLEAR_ROUTE, SET_ENTITY } from '@/shared/store/actionType';
import { CLEAR_CURRENTLY_EDITING, CLEAR_GENERATE_INTERACTIONS } from '@/crm/store/actionType';
import { baseUrl as crmBaseUrl, interactionsArea } from '@/crm/router/urlBits';
import { pluralise, t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';


let stagesUrlBits = {
  Timescale: '',
  'Customer & Contact': 'entity',
  Opportunity: 'opportunity',
  'Update opportunity': 'update-opportunity',
  'Further details': 'further-details',
  'Schedule follow up': 'follow-up',
  Settings: 'settings',
  'Adding opportunities': 'adding-opportunities',
  // 'Profile completion status': 'profile-completion-status',
  'Location of prospects': 'location-of-prospects',
  'Location of customers': 'location-of-customers',
  'Add an interaction note': 'interaction-note',
};

const store = useStore();
const vroute = useRoute();

const stage = ref(0);
const stageName = ref('');

const entityIDs = computed(() => store.state.crm.interactions.currentlyEditing.entityIDs);

const entityType = computed(() => vroute.path.includes('prospect') ? 'prospect' : 'customer');
const title = computed(() => t(`Generate ${entityType.value} interactions`));
const stages = computed(() => {
  let computedStages = [
    'Timescale',
    'Settings',
    'Adding opportunities',
    // 'Profile completion status',
    `Location of ${pluralise.string(entityType.value)}`,
    'Add an interaction note',
  ];

  // If generating interactions for prospects, return the stages above
  if (entityType.value !== 'customer') return computedStages;

  // If it's a customer interaction, remove the 'Profile completion status' stage
  // stages.splice(stages.indexOf('Profile completion status'), 1);

  // If the customer interactions are generated with insights/enquiries, remove the opportunities and location stages
  if (entityIDs.value?.length) {
    computedStages.splice(computedStages.indexOf('Adding opportunities'), 1);
    computedStages.splice(computedStages.indexOf(`Location of ${pluralise.string(entityType.value)}`), 1);
  }

  return computedStages;
});

onMounted(() => initFunc());
onUnmounted(() => {
  clearCurrentlyEditing();
  clearRoute();
});

const clearGenerateInteractionsRequest = () => store.dispatch(`crm/interactions/${CLEAR_GENERATE_INTERACTIONS}`);
const setEntity = entity => store.dispatch(`crm/interactions/${SET_ENTITY}`, entity);
const clearCurrentlyEditing = () => store.dispatch(`crm/interactions/${CLEAR_CURRENTLY_EDITING}`);
const clearRoute = () => store.dispatch(`system/${CLEAR_ROUTE}`);

const initFunc = () => {
  // clear request data if it's initial load not coming back from next stage
  if (vroute.query.clear) {
    clearGenerateInteractionsRequest();
  }
  setEntity({
    entityType: entityType.value,
  });
};
const changeStage = (newStage) => {
  let changeStage = stages.value[newStage];
  let newIndex = stages.value.findIndex(stage => stage === newStage);
  if (!changeStage || newIndex === stage.value) return;
  navigateToUrl(`${crmBaseUrl}/${interactionsArea}/generate-${entityType.value}-interaction/${stagesUrlBits[changeStage]}`);
};
const stageUpdate = (newStage) => {
  stageName.value = newStage;
  let newIndex = stages.value.findIndex(stage => stage === newStage);
  stage.value = newIndex;
};
</script>

<style lang="scss">
@import '@/shared/assets/scss/_variables';

.generate-interaction-form {
  overflow: hidden;
}

.generate-interaction-form .form-actions {
  display: flex;
  align-items: center;
  justify-content: center;

  // Allow the footer to overflow at the sides rather than use minus margin values
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  margin-right: -50vw;
  margin-left: -50vw;

  margin-top: var(--spacing-4);
  padding: var(--spacing-2) 0 var(--spacing-2);
  background-color: var(--colour-panel-action);
}

.c-card.generate-interaction-form {
  padding: clamp(var(--spacing-2), 2.5vw, var(--spacing-4));
  padding-bottom: 0;
}
.generate-interaction-form {
  .stages {
    max-width: 36rem;
  }

  .title {
    text-align: center;
  }
}
</style>

<style lang="scss" scoped>
.stages {
  margin-top: var(--spacing-3);
  margin-bottom: var(--spacing-6);
}
</style>
