import {
  GET_SUMMARY,
  GET_SUMMARY_LOADING,
  GET_SUMMARY_ERROR,

  GET_ALL,
  GET_ALL_LOADING,
  GET_ALL_ERROR,

  GET_BY_ID,
  GET_BY_ID_LOADING,
  GET_BY_ID_ERROR,

  DELETE_BY_ID,
  DELETE_BY_ID_LOADING,
  DELETE_BY_ID_ERROR,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import querystring from 'querystring';

export const getters = {
  getDrilledSortedByLevelOne (state) {
    const { drilled } = state;
    const sorted = {};
    let n = 0;

    for (n; n < drilled.length; n++) {
      const _ref = drilled[n];
      if (_ref.prod_group_level_1 in sorted) {
        sorted[_ref.prod_group_level_1].push(_ref);
      } else {
        sorted[_ref.prod_group_level_1] = [_ref];
      }
    }

    return sorted;
  },
  getErrors (state) {
    return state.errors;
  }
};

export const mutations = {
  [GET_SUMMARY]: (state, results) => {
    state.data = results;
    state.loading = false;
    state.loaded = true;
    state.errors = [];
  },
  [GET_SUMMARY_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.errors = [];
  },
  [GET_SUMMARY_ERROR]: (state, err) => {
    state.loading = true;
    state.loaded = false;
    state.errors = [err];
  },

  [GET_ALL]: (state, results) => {
    state.data = results;
    state.loading = false;
    state.loaded = true;
    state.errors = [];
  },
  [GET_ALL_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.errors = [];
  },
  [GET_ALL_ERROR]: (state, err) => {
    state.loading = false;
    state.loaded = true;
    state.errors = [err];
  },

  [GET_BY_ID]: (state, results) => {
    state.drilled = results;
    state.loading = false;
    state.loaded = true;
    state.errors = [];
  },
  [GET_BY_ID_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.errors = [];
  },
  [GET_BY_ID_ERROR]: (state, err) => {
    state.loading = false;
    state.loaded = true;
    state.errors = [err];
  },

  [DELETE_BY_ID]: (state) => {
    state.loading = false;
    state.loaded = true;
    state.errors = [];
  },
  [DELETE_BY_ID_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.errors = [];
  },
  [DELETE_BY_ID_ERROR]: (state, err) => {
    state.loading = false;
    state.loaded = true;
    state.errors = [err];
  },
};

export const actions = {
  [GET_SUMMARY]: async ({ commit }, params) => {
    commit(GET_SUMMARY_LOADING);
    try {
      const queryArgs = querystring.stringify(params);
      const response = await httpRequest('get', `product-group-gaps/summary?${queryArgs}`, {}, {
        area: 'associations',
      });
      commit(GET_SUMMARY, response);
    } catch (e) {
      commit(GET_SUMMARY_ERROR, e);
    }
  },
  [GET_ALL]: async ({ commit }, params) => {
    commit(GET_ALL_LOADING);
    try {
      const queryArgs = querystring.stringify(params);
      const response = await httpRequest('get', `product-group-gaps?${queryArgs}`, {}, {
        area: 'associations',
      });
      commit(GET_ALL, response);
    } catch (e) {
      commit(GET_ALL_ERROR, e);
    }
  },
  [GET_BY_ID]: async ({ commit }, { id, ...params }) => {
    commit(GET_BY_ID_LOADING);
    try {
      const queryArgs = querystring.stringify(params);
      const response = await httpRequest('get', `product-group-gaps/entity/${id}?${queryArgs}`, {}, {
        area: 'associations'
      });
      commit(GET_BY_ID, response);
    } catch (e) {
      commit(GET_BY_ID_ERROR, e);
    }
  },

  [DELETE_BY_ID]: async ({ commit }, id) => {
    commit(DELETE_BY_ID_LOADING);
    try {
      const response = await httpRequest('delete', `product-group-gaps/${id}`, {}, {
        area: 'associations',
        version: '2',
      });

      commit(DELETE_BY_ID, response);
    } catch (e) {
      commit(DELETE_BY_ID_ERROR, e);
    }
  },
};