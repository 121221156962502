<template>
  <div class="date-range">
    <div class="description">
      <h3>{{ t('Yearly comparison') }}</h3>
      <div>
        <IconBase
          icon-name="calendar"
          :height="48"
          :width="48"
        />
      </div>
      <h4>{{ t('Set years') }}</h4>
    </div>
    <div class="yearly-comparison">
      <div class="year1-filter">
        <p class="year-text">
          {{ t('Year') }} 1
        </p>
        <MenuFilter
          :items="options1"
          :selected-value="subtractedYears1"
          menu-position="right"
          @on-change="applyYear($event, 1)"
        />
      </div>
      <div class="year2-filter">
        <p class="year-text">
          {{ t('Year') }} 2
        </p>
        <MenuFilter
          :items="options2"
          :selected-value="subtractedYears2"
          @on-change="applyYear($event, 2)"
        />
      </div>
      <div class="submit-container">
        <CustomButton
          class="submit"
          action="submit"
          label="Submit"
          @click="submitDate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { MenuFilter, CustomButton, IconBase } from '@sales-i/dsv3';
import { mapState } from 'vuex';
import { t } from '@sales-i/utils';

export default {
  components: {
    MenuFilter,
    CustomButton,
    IconBase,
  },
  emits: ['setActiveDate'],
  data() {
    return {
      subtractedYears1: 0,
      subtractedYears2: 1,
    };
  },
  computed: {
    ...mapState({
      calendar: state => state.intelligence.calendar.calendar,
    }),
    options1() {
      const yearsToDate = [];
      for (let i = 0; i < 10; i++) {
        yearsToDate.push({ text: this.getYearToDate(i), value: i });
      }
      return yearsToDate;
    },
    options2() {
      const yearsToDate = [];
      for (let i = 1; i < 11; i++) {
        yearsToDate.push({ text: this.getYearToDate(i), value: i });
      }
      return yearsToDate;
    },
  },
  watch: {
    subtractedYears1(newValue) {
      if (newValue >= this.subtractedYears2) this.subtractedYears2 = newValue + 1;
    },
    subtractedYears2(newValue) {
      if (newValue <= this.subtractedYears1) this.subtractedYears1 = newValue - 1;
    },
  },
  methods: {
    t,
    getFormattedDateRangesObject() {
      const { start, end } = this.calendar.current_fiscal_year;
      // get years after subtraction
      const yearFrom1 = new Number(start.slice(0, 4)) - this.subtractedYears1;
      const yearTo1 = new Number(end.slice(0, 4)) - this.subtractedYears1;
      const yearFrom2 = new Number(start.slice(0, 4)) - this.subtractedYears2;
      const yearTo2 = new Number(end.slice(0, 4)) - this.subtractedYears2;
      // get dates with subtracted years
      const date_from = yearFrom1 + start.slice(4);
      const date_to = yearTo1 + end.slice(4);
      const date_from2 = yearFrom2 + start.slice(4);
      const date_to2 = yearTo2 + end.slice(4);
      return { date_from, date_to, date_from2, date_to2 };
    },
    getYearToDate(subtractedYears = 0) {
      return new Date().getFullYear() - subtractedYears;
    },
    applyYear(year, number) {
      this[`subtractedYears${number}`] = year.value;
    },
    submitDate() {
      this.$emit('setActiveDate', this.getFormattedDateRangesObject());
    },
  },
};
</script>

<style lang="scss" scoped>
.yearly-comparison {
  display: flex;
  flex-wrap: wrap;
  .year1-filter,
  .year2-filter {
    flex-grow: 1;
  }
}
.submit-container {
  background: var(--colour-panel-action);
  padding: var(--spacing-2);
  margin-left: calc(-1 * var(--spacing-2));
  margin-right: calc(-1 * var(--spacing-2));
  flex-basis: 360px;
  margin-top: var(--spacing-2);
}
.submit {
  padding: var(--spacing-1) 0;
  width: 100%;
}
.date-range {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.description {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: var(--spacing-4) 0;
  h3,
  h4 {
    font-weight: var(--font-weight-semibold);
    flex-basis: 100%;
  }
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
      font-size: var(--font-size-3);
      font-weight: var(--font-weight-semibold);
      color: var(--colour-utility-black);
      margin-bottom: 0;
    }
  }
}
</style>
