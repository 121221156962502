<template>
  <div>
    <EnquiryNav
      :active-area-prop="reportArea"
      :report-type="reportType"
      hide-enquiry-buttons
    />
    <TwoColumnLayout
      title="Associations Report"
      :description="inputData.description"
      @sidebar-menu-button-click="showMenu"
    >
      <template #sidebar>
        <div
          v-if="associations.data.length"
          id="community-report-details-view-association-select"
          class="add-section"
        >
          <h3>{{ t('Product Associations') }}</h3>

          <CustomButton
            purpose="text"
            @click="toggleAssociationList"
          >
            {{ t('Choose a different association') }}
          </CustomButton>
        </div>
      </template>
      <template #content>
        <div v-if="associationSelection">
          <div class="card-container">
            <CustomCard
              v-for="item in associationList"
              :key="item.value"
              purpose="reversed"
              @click="changeAssociation(item.value)"
            >
              <div class="main-area">
                <header>
                  <h3>{{ item.text }}</h3>
                </header>
              </div>

              <template #footer>
                <CustomButton
                  purpose="text"
                  @click="changeAssociation(item.value)"
                >
                  {{ t('View Insights') }}
                </CustomButton>
              </template>
            </CustomCard>
          </div>

          <Pagination
            :offset="offset"
            :limit="limit"
            :records-count="associationList.length"
            @page-changed="changePage"
          />
        </div>
        <div
          v-if="associationSelection === false"
          class="container"
        >
          <LoadingOverlay
            v-if="loading || loadingSpinner"
          />

          <section
            v-if="!loading && products.length"
            class="custom-header-section"
          >
            <header>
              <h2>
                {{ products[0].name }}
              </h2>
              <div
                class="link"
              >
                <div />
                <div />
                <div />
              </div>
              <h2>
                {{ products[1].name }}
              </h2>
            </header>
          </section>

          <div class="chart">
            <AssociationChart
              v-if="!loading"
              :headings="headings"
            />
          </div>

          <div class="table">
            <AssociationsTable
              v-if="!loading"
              :current-sort="currentSort"
              @apply-new-sort="applyNewSort"
              @sort-on-y-axis="sortOnYAxis"
              @loading="handleLoadingSpinner"
            />
          </div>
        </div>
      </template>
    </TwoColumnLayout>
  </div>
</template>

<script>
import { AIML_PRODUCT_TRENDS } from '@/intelligence/store/data/reportTypes';
import { GET_PRODUCTS } from '@/admin/store/actionType';
import {
  CLEAR_REPORT_DATA,
  FETCH_REPORT_DATA,
  PROCESS_REQUEST_PARAMETERS,
  APPLY_DATE_RANGE,
  APPLY_AXIS,
  FETCH_DATES,
  FETCH_CALENDAR,
  APPLY_NEW_SORT,
} from '@/intelligence/store/actionType';
import { mapActions, mapGetters, mapState } from 'vuex';
import { LoadingOverlay, CustomCard, CustomButton } from '@sales-i/dsv3';
import TwoColumnLayout from '@/shared/components/Layout/TwoColumnLayout';
import AssociationChart from '@/admin/components/Associations/AssociationChart.vue';
import AssociationsTable from '@/intelligence/components/Associations/AssociationsTable.vue';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import Pagination from '@/shared/components/Pagination/Pagination.vue';
import paginationMixin from '@/shared/components/Pagination/mixins/paginationMixin';
import { scrollIntoView } from '@/shared/directives';

import {
  baseUrl,
  associationsScope,
  insightsScope,
  productsScope
} from '@/intelligence/router/urlBits';
import { REPORT_AREA_PRODUCT } from '@/intelligence/store/data/areas';
import { t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'AssociationsReport',
  components: {
    TwoColumnLayout,
    AssociationChart,
    AssociationsTable,
    LoadingOverlay,
    EnquiryNav,
    CustomCard,
    Pagination,
    CustomButton,
  },
  mixins: [paginationMixin],
  data() {
    return {
      offset: 0,
      limit: 50,
      reportType: AIML_PRODUCT_TRENDS,
      currentSort: '',
      type_filter: 'item_level',
      showMenu: true,
      headings: {},
      loading: true,
      loadingSpinner: false,
      reportArea: REPORT_AREA_PRODUCT,
      associationSelection: false,
    };
  },
  computed: {
    ...mapState({
      fetchedDates: state => state.intelligence.calendar.fetchedDates,
      associations: state => state.admin.associations.products,
    }),
    ...mapGetters({
      getReportData: 'intelligence/shared/getReportData',
      getInputData: 'intelligence/shared/getInputData',
    }),
    inputData() {
      return this.getInputData(AIML_PRODUCT_TRENDS);
    },
    reportData() {
      return this.getReportData();
    },
    products() {
      if (this.reportData === undefined) {
        return [];
      }
      const { products } = this.reportData;
      return products;
    },
    associationList() {
      return [
        ...this.associations.data.map(item => ({ text: `${item.antecedent_name} to ${item.consequent_name}`, value: `${item.antecedent_code}|||${item.consequent_code}` }))
      ];
    },
    selectedAssociation() {
      const { query } = this.$route;
      let found = false;
      if (Object.keys(query).length === 0) {
        found = this.associations.data[0];
      } else {
        found = this.associations.data.find(item => {
          return item.antecedent_code === query.product_1 && item.consequent_code === query.product_2;
        });
      }
      if (found !== undefined && found !== false) {
        return `${found.antecedent_code}|||${found.consequent_code}`;
      }
      return 1;
    },
  },
  watch: {
    async $route() {
      const { path } = this.$route;
      if (path.indexOf(`${baseUrl}/${insightsScope}/${productsScope}/${associationsScope}`) !== -1) {
        await this.loadReportData();
      }
    },
    reportData() {
      if (this.reportData !== undefined) {
        const { products } = this.reportData;
        const productOneName = products[0].name.length >= 10 ? products[0].name.substr(0, 10) + '...' : products[0].name;
        const productTwoName = products[1].name.length >= 10 ? products[1].name.substr(0, 10) + '...' : products[1].name;

        this.headings = {
          1: {
            title: productOneName,
            subTitle: products[0].code,
          },
          2: {
            title: productTwoName,
            subTitle: products[1].code,
          },
          3: {
            title: t('BOTH'),
          },
        };
      }
    },
  },
  async mounted() {
    await this.fetchCalendar();
    await this.getAllAssociations(this.type_filter);
    await this.loadReportData();
  },
  beforeUnmount() {
    this.clearEnquiryData();
  },
  methods: {
    t,
    ...mapActions({
      clearEnquiryData: `intelligence/shared/${CLEAR_REPORT_DATA}`,
      fetchReportData: `intelligence/shared/${FETCH_REPORT_DATA}`,
      processRequestParams: `intelligence/calendar/${PROCESS_REQUEST_PARAMETERS}`,
      setDateRange: `intelligence/shared/${APPLY_DATE_RANGE}`,
      setAxes: `intelligence/enquiry/${APPLY_AXIS}`,
      fetchDates: `intelligence/calendar/${FETCH_DATES}`,
      fetchCalendar: `intelligence/calendar/${FETCH_CALENDAR}`,
      applyNewSort: `intelligence/templateReport/${APPLY_NEW_SORT}`,
      getAllAssociations: `admin/associations/${GET_PRODUCTS}`,
    }),
    toggleAssociationList() {
      this.associationSelection = !this.associationSelection;
    },
    async changePage(page) {
      this.offset = page;
      this.getAllAssociations({
        type: this.type_filter,
        limit: this.limit,
        offset: this.offset,
      });
      scrollIntoView();
    },
    handleLoadingSpinner(loading) {
      this.loadingSpinner = loading;
    },
    async loadReportData(completeRefresh = false) {
      this.loading = true;

      await this.processFilterParams();
      await this.fetchReportData({ reportType: AIML_PRODUCT_TRENDS, completeRefresh });

      this.loading = false;
    },
    async processFilterParams() {
      this.processRequestParams();

      const now = new Date();
      const then = new Date();
      then.setFullYear(then.getFullYear() - 1);
      then.setMonth(then.getMonth() + 1);
      then.setDate(1);

      await this.fetchDates({
        date_from: then.toISOString(),
        date_to: now.toISOString(),
      });

      this.setDateRange(this.fetchedDates);

      const { query } = this.$route;

      if (Object.keys(query).length === 0) {
        const { data } = this.associations;
        this.setAxes({
          xAxis: data[0].antecedent_code,
          yAxis: data[0].consequent_code,
        });
      } else {
        this.setAxes({
          xAxis: query.product_1,
          yAxis: query.product_2,
        });
      }
    },
    changeAssociation($event) {
      const [ product_1, product_2 ] = $event.split('|||');

      this.associationSelection = false;

      navigateToUrl(`${baseUrl}/${insightsScope}/${productsScope}/${associationsScope}?product_1=${product_1}&product_2=${product_2}`);
    },
  },
};

</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.custom-header-section {
  header {
    display: flex;

    h2 {
      width: 45%;
      text-align: center;
    }
  }
}
.chart {
  width: 100%;
}
.table {
  width: 100%;
}
.link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);
  height: 36px;
  div:first-child, div:last-child {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: var(--colour-panel-g-16);
  }
  div:nth-child(2) {
    height: 2px;
    width: 26px;
    background-color: var(--colour-panel-g-16);
  }
}
.container-wrapper {
  position: absolute;
  top: var(--spacing-8);
  right: var(--spacing-3);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    top: var(--spacing-3);
  }
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  flex-wrap: wrap;
  padding-block-start: var(--spacing-3);
  padding: var(--spacing-3);
  gap: var(--spacing-2);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: repeat(3, 1fr);
  }

  h3 {
    font-size: var(--font-size-4);
  }

  :deep(.c-card) {
    width: 100%;
    margin: 0 0 var(--spacing-2) 0;
  }
}

.product-associations-selection {
  max-height: 250px;
  overflow-y: scroll;
}

</style>