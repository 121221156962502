import { getByLocaleMutations, getByLocaleActions } from './getByLocale';

// initial state
const state = () => ({
  data: {},
  locale: localStorage.getItem('locale') || 'en-gb', // Get the value from localStorage. If there is no value, set it to 'en-gb'
  loaded: false,
  loading: false,
});

const getters = {};

// mutations
const mutations = {
  ...getByLocaleMutations,
};

// actions
const actions = {
  ...getByLocaleActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
