<template>
  <div class="template-report-chart-container">
    <h3 v-if="chartInput.name">
      {{ chartInput.name }}
    </h3>
    <div
      :id="id"
      :class="`chart-item chart-${chartInput.key}`"
    >
      <svg class="chart" />
      <div
        v-show="showTooltip"
        class="bar-tooltip tooltip"
      />
      <div class="customer-tooltip tooltip" />
    </div>
  </div>
</template>

<script>
// TemplateReportChart
import * as d3 from 'd3';
import { TEMPLATE_REPORT_CHART_COLOR_SETS } from '@/intelligence/store/data/charts';
import { abbr, currency } from '@sales-i/utils';
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    chartInput: {
      type: Object,
      default: () => ({}),
    },
    defaultSortOption: {
      type: String,
      default: 'desc',
    },
    id: {
      type: String,
      required: true,
      default: null,
    },
    reportId: {
      type: Number,
      default: null,
    },
    sortedChartData: {
      type: Array,
      default: () => [],
    },
    currentSort: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // dimensions and margins of the graph
      margin: {
        top: 0,
        right: 10,
        bottom: 100,
        left: 30,
      },
      svgWidth: 220,
      svgHeight: 410,
      showTooltip: false,
    };
  },
  computed: {
    ...mapState({
      cs: state => state.userDetails.cs,
    }),
    ...mapGetters({
      getReportData: 'intelligence/shared/getReportData',
    }),
    reportData() {
      return this.getReportData(this.reportId);
    },
    chartWidth() {
      return this.svgWidth - this.margin.left - this.margin.right;
    },
    chartHeight() {
      return this.svgHeight - this.margin.top - this.margin.bottom;
    },
  },
  watch: {
    sortedChartData(data) {
      this.updateChartSorting(data);
      this.resetSelectedBars();
    },
  },
  mounted() {
    this.generateChart(this.chartInput);
  },
  methods: {
    resetSelectedBars() {
      const { colorSet, key } = this.chartInput;
      const [colorStandard] = TEMPLATE_REPORT_CHART_COLOR_SETS[colorSet];
      this.showTooltip = false;
      d3.select(`#${this.id}.chart-${key} svg`).selectAll('rect').attr('fill', `var(${colorStandard})`);
    },
    updateChartSorting(data) {
      if (this.chartInput.key !== this.currentSort) return;

      const { key } = this.chartInput;
      const svg = d3.select(`#${this.id}.chart-${key} svg`);

      let maxNumericValue = Math.max.apply(
        null,
        data.map(a => a[key])
      );
      if (maxNumericValue < 0) {
        maxNumericValue = -1 * Math.max.apply(null, data.map(a => a[key]).map(Math.abs));
      }

      const yScale = d3
        .scaleLinear()
        .domain([0, 1.1 * maxNumericValue])
        .range([this.chartHeight, 5]);
      const yAxe = d3.axisLeft(yScale).tickFormat(d => abbr.float(d));

      d3.scaleBand()
        .domain(data.map((d, i) => i))
        .range([0, this.chartWidth])
        .padding(0.4);

      svg.select('.y').transition().duration(1000).call(yAxe);

      svg
        .selectAll('.x text')
        .data(data)
        .transition()
        .duration(1000)
        .text(d => d.name.substring(0, 10) + '...');

      svg
        .selectAll('rect')
        .data(data)
        .transition()
        .duration(1000)
        .attr('y', d => yScale(d[key]))
        .attr('height', d => this.chartHeight - yScale(d[key]));
    },

    generateChart({ key, prefix = '', suffix = '', colorSet }) {
      const that = this;
      const [colorStandard] = TEMPLATE_REPORT_CHART_COLOR_SETS[colorSet];

      // append the svg object to the body of the page
      const svg = d3
        .select(`#${this.id}.chart-${key} svg`)
        .attr('width', this.svgWidth)
        .attr('height', this.svgHeight)
        .append('g')
        .attr('transform', `translate(${this.margin.left + this.margin.right},${this.margin.top})`);

      // Prepare the Data
      const data = this.prepareReportData(this.reportData, key);
      const customerTooltip = d3.select(`#${this.id}.chart-${key} .customer-tooltip`);
      const barTooltip = d3.select(`#${this.id}.chart-${key} .bar-tooltip`);

      function customerMouseoverHandler(event, d) {
        customerTooltip
          .style('display', 'block')
          .style('opacity', 1)
          .style('left', d3.pointer(event, this)[0] + that.chartWidth / 2 + 'px')
          .style('top', that.chartHeight + 'px')
          .html(d.name);
      }

      // X axis
      const x = d3
        .scaleBand()
        .domain(data.map((d, i) => i))
        .range([0, this.chartWidth])
        .padding(0.4);
      svg
        .append('g')
        .attr('class', 'chart-axis x')
        .attr('transform', `translate(0, ${this.chartHeight})`)
        .call(d3.axisBottom(x))
        .selectAll('text')
        .data(data)
        .text(d => d.name)
        .attr('transform', 'translate(-10,0)rotate(-60)')
        .attr('class', 'chart-label-x')
        .style('text-anchor', 'end')
        .each(truncateNames)
        .on('mouseover', customerMouseoverHandler)
        .on('mouseout', () => customerTooltip.style('display', 'none'));

      // truncate too long names
      function truncateNames() {
        const self = d3.select(this);
        let text = self.text();

        if (text.length > 12) {
          text = text.slice(0, 10);
          self.text(text + '...');
        }
      }

      // in case of negative values in axis Y
      let maxNumericValue = Math.max.apply(
        null,
        data.map(a => a[key])
      );
      if (maxNumericValue < 0) {
        maxNumericValue = -1 * Math.max.apply(null, data.map(a => a[key]).map(Math.abs));
      }

      // Y axis
      const y = d3
        .scaleLinear()
        .domain([0, 1.1 * maxNumericValue])
        .range([this.chartHeight, 5]);

      const axisY = d3.axisLeft(y).tickFormat(d => abbr.float(d));

      svg.append('g').attr('class', 'chart-axis y').call(axisY).selectAll('text').attr('class', 'chart-label-y');

      // Bars
      svg
        .selectAll('mybar')
        .data(data)
        .join('rect')
        .attr('x', (d, i) => x(i))
        .attr('y', d => y(d[key]))
        .attr('rx', '8')
        .attr('ry', '8')
        .attr('width', 16)
        .attr('height', d => Math.max(0, this.chartHeight - y(d[key])))
        .attr('fill', `var(${colorStandard})`)
        .attr('class', 'bar')
        .style('cursor', 'pointer')
        .on('mouseover', barMouseoverHandler)
        .on('mouseout', () => (that.showTooltip = false));

      function barMouseoverHandler(event, d) {
        barTooltip
          .html(`${prefix ? currency.price(d[key]) : d[key]} ${suffix}`, this.cs)
          .style('opacity', 1)
          .style('left', d3.pointer(event, this)[0] + 'px')
          .style('top', d3.pointer(event, this)[1] - 20 + 'px');
        d3.select(`#${that.id}.chart-${key}`).selectAll('rect');
        that.showTooltip = true;
      }
    },
    prepareReportData(data, key) {
      const isAscending = this.defaultSortOption === 'asc';
      return [...data].sort((a, b) => (isAscending ? a[key] - b[key] : b[key] - a[key])).slice(0, 5);
    },
  },
};
</script>
<style lang="scss" scoped>
.template-report-chart-container {
  position: relative;
}

h3 {
  font-weight: var(--font-weight-semibold);
  text-align: center;
}

.chart-item {
  display: flex;
  justify-content: center;
  width: 220px;
  margin: 0 auto;
  position: relative;
}
</style>

<style lang="scss">
.template-report-chart-container {
  .chart-axis {
    line,
    path {
      display: none;
    }
  }

  .chart-label-x,
  .chart-label-y {
    font-size: var(--font-size-small);
    font-family: var(--font-family-primary);
  }

  .chart-label-x {
    font-weight: var(--font-weight-semibold);
  }

  .bar-tooltip {
    width: 80px;

    &::after {
      content: '';
      height: var(--spacing-1);
      width: var(--spacing-1);
      transform: rotate(-45deg);
      background-color: var(--colour-utility-black);
      position: absolute;
      top: 100%;
      right: 50%;
      transform: translateX(50%) translateY(-50%) rotate(45deg);
    }
  }

  .customer-tooltip {
    width: auto;
    z-index: 10;
  }

  .tooltip {
    position: absolute;
    opacity: 0;
    padding: var(--spacing-half) var(--spacing-1);
    border-radius: var(--spacing-2);
    text-align: center;
    color: var(--colour-utility-white);
    font-family: var(--font-family-primary);
    font-size: var(--font-size-small);
    letter-spacing: 0;
    line-height: var(--spacing-2);
    background-color: var(--colour-utility-black);
    font-weight: var(--font-weight-semibold);
    pointer-events: none;
  }
}
</style>
