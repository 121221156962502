import { CLEAR_ALL, GET_ALL, GET_ALL_ERROR, GET_ALL_LOADING } from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import querystring from 'querystring';

const defaultOptions = {
  area: 'files',
  version: '2'
};

// getters
export const getAllGetters = {
};

// mutations
export const getAllMutations = {
  [GET_ALL]: (state, data) => {
    state.all.data = data;
    state.all.loaded = true;
    state.all.loading = false;
    state.all.errors = [];
  },
  [GET_ALL_LOADING]: (state) => {
    state.all.loaded = false;
    state.all.loading = true;
  },
  [GET_ALL_ERROR]: (state, errors) => {
    state.all.loaded = false;
    state.all.loading = false;
    state.all.errors = errors;
  },
  [CLEAR_ALL]: (state) => {
    state.all.data = [];
    state.all.loaded = false;
    state.all.loading = false;
    state.all.errors = [];
  },
};

// actions
export const getAllActions = {
  [GET_ALL]: async ({ commit, }, parameters = {}) => {
    try {
      let { id, options, entity_type, sort, limit, offset } = parameters;
      const params = querystring.stringify({ sort, limit, offset });
      options = {...defaultOptions, ...options};
      commit(CLEAR_ALL);
      commit(GET_ALL_LOADING);
      let data = await httpRequest('get', `${entity_type}/${id}?${params}`, {}, options);
      commit(GET_ALL, data);
      return data;
    } catch (error) {
      console.error(error);
      commit(GET_ALL_ERROR, error);
      return false;
    }
  },
  [CLEAR_ALL]: ({ commit, }) => {
    commit(CLEAR_ALL);
  },
};

