<template>
  <SearchField
    v-bind="props"
    :search-func="getSearchResults"
    @input="handleInput"
    @search="handleSearch"
    @blur="handleBlur"
    @focus="handleFocus"
    @valid="handleValid"
  />
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { baseProps, baseEmits } from './composables/searchInputPropsEmits';
import useSearchInput from './composables/useSearchInput';
import { useUsers } from '@/shared/composables/useUsers';

const store = useStore();
const { usersOptions } = useUsers(store);

const props = defineProps({
  ...baseProps,
  label: {
    type: String,
    default: '',
  },
  preloadUsers: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(baseEmits);

const url = ref('users');
const type = ref('user');
// const loadUsersOnMount = ref(props.preloadUsers);

const {
  SearchField,
  handleBlur,
  handleFocus,
  handleValid
} = useSearchInput({emit, url, type});

const getSearchResults = async(query) => usersOptions.value.filter(x => x.text.search(new RegExp(query, 'gi')) >= 0);
const handleInput = value => { emit('input', value); };
</script>
