<template>
  <CustomModal
    title="Personal Interaction"
    position="center"
    show-modal
    @close-modal="closeModal"
  >
    <div class="form-grid">
      <div class="row">
        <div
          v-if="item.time !== 'NaN:NaNam'"
          class="col col-12 mb-2"
        >
          {{ item.date }}{{ item.time }} - {{ item.durationStr }}
        </div>
        <div class="col col-12 mb-2">
          <CRMCard :title="t('Details')">
            <template #info>
              {{ item.description }}
            </template>
          </CRMCard>
        </div>
      </div>
    </div>
    <template
      v-if="canUpdate"
      #footer
    >
      <CustomButton
        :label="t('Edit details')"
        small
        @on-click="editInteraction"
      />
    </template>
  </CustomModal>
</template>

<script>
import { CRMCard, CustomModal, CustomButton } from '@sales-i/dsv3';
import { mapGetters, mapActions } from 'vuex';
import { GET_BY_ID, CLEAR_BY_ID } from '@/shared/store/actionType';
import usePermissions from '@/shared/composables/usePermissions';
import { interactions } from '@/shared/store/data/policies';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits.js';
import { t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'ViewPersonal',
  components: {
    CustomButton,
    CustomModal,
    CRMCard,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      item: 'crm/interactions/item',
    }),
    hasAccess() {
      const { hasAccess } = usePermissions();
      return hasAccess;
    },
    canUpdate() {
      return this.hasAccess(interactions, 'update');
    },
  },
  mounted() {
    let { params } = this.$route;
    this.getById({ id: params.id });
  },
  methods: {
    t,
    ...mapActions({
      getById: `crm/interactions/${GET_BY_ID}`,
      clearById: `crm/interactions/${CLEAR_BY_ID}`,
    }),
    editInteraction() {
      navigateToUrl(`${baseUrl}/${interactionsArea}/${this.item.id}/edit-personal`);
    },
    closeModal() {
      this.clearById();
      navigateToUrl(`${baseUrl}/${interactionsArea}/`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
