<template>
  <article>
    <CustomCard
      purpose="reversed"
      footer-color="grey"
      class="interaction-card"
    >
      <header>
        <h2>{{ t('Sales Comparison') }}</h2>
        <p>{{ t('Analysing your performance') }}</p>
      </header>
      <BufferImage
        v-if="!isChartEnabled"
        class="loading-spinner"
        color="var(--colour-utility-black)"
        float="center"
      />
      <TrendsChart
        v-if="isChartEnabled"
        :report-id="reportId"
        class="trends-chart-container"
      />
      <template #footer>
        <ButtonGroup>
          <CustomButton
            small
            purpose="action"
            @click="goSalesComparison"
          >
            {{ t('View more') }}
          </CustomButton>
        </ButtonGroup>
      </template>
    </CustomCard>
  </article>
</template>

<script>
import { BufferImage, CustomCard, CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import TrendsChart from '@/intelligence/components/Trends/TrendsChart';
import { t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'TodayPerformance',
  components: {
    ButtonGroup,
    CustomCard,
    CustomButton,
    TrendsChart,
    BufferImage,
  },
  props: {
    isChartEnabled: {
      type: Boolean,
      default: false,
    },
    reportId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    t,
    goSalesComparison() {
      navigateToUrl('/comparison');
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.interaction-card) > .c-card--body {
  min-height: 600px;

  .flow {
    display: flex;
    flex-direction: column;
  }
}

.trends-chart-container {
  margin: 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.interaction-card {
  position: relative;
}

.loading-spinner {
  position: absolute;
  top: calc(50% - 50px);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}
</style>
