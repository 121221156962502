import { FETCH_METADATA, SET_METADATA } from '@/intelligence/store/actionType';
import { gqlRequest } from '@sales-i/utils';
import { gql } from 'graphql-request';

// initial state
const state = () => ({
  date_last_loaded: null,
  last_transaction_date: null,
});

// getters
const getters = {};

// mutations
const mutations = {
  [SET_METADATA]: (state, data) => {
    const { date_last_loaded, last_transaction_date } = data;
    state.date_last_loaded = date_last_loaded;
    state.last_transaction_date = last_transaction_date;
  },
};

// actions
const actions = {
  [FETCH_METADATA]: async ({commit}) => {
    try {
      const query = gql`{ 
        meta { 
          last_transaction_date
          date_last_loaded
        }
      }`,
        response = await gqlRequest(query, {}, { debug: true });

      commit(SET_METADATA, response['meta']);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
