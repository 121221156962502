<template>
  <div class="container container--fluid">
    <slot name="before" />
    <div
      v-if="!loading"
      class="content-wrap padded"
    >
      <div class="highlight">
        <slot name="highlight" />
      </div>
      <slot />
    </div>
    <slot name="buttons">
      <ButtonGroup
        v-if="!loading && Array.isArray(buttons) && buttons.length"
        class="btn-group-bg px-3"
      >
        <template v-for="(btn, btnIndex) in buttons">
          <CustomButton
            v-if="btn.visible !== false"
            v-bind="btn"
            :key="'btn' + btnIndex"
            @click="btn.clickFunc ? btn.clickFunc(btn) : $emit('btnClick', btn)"
          />
        </template>
      </ButtonGroup>
    </slot>

    <slot name="buffer">
      <div
        v-if="loading"
        class="buffer-wrap"
      >
        <BufferImage
          class="buffer"
          color="var(--colour-utility-black)"
          float="center"
        />
      </div>
    </slot>

    <slot name="after" />
  </div>
</template>

<script>
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { CustomButton, BufferImage } from '@sales-i/dsv3';

export default {
  name: 'ColumnsWithActionsLayout',
  components: {
    ButtonGroup,
    CustomButton,
    BufferImage,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['btnClick'],
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.container {
  background-color: var(--colour-panel-g-2);
}

h3 {
  font-weight: var(--font-weight-semibold);
}

.more-btn {
  font-size: var(--font-size-small);
  text-decoration: underline;
  font-weight: var(--font-weight-medium);
}

.content-wrap {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  box-shadow: none;
  padding-bottom: 0;

  .highlight {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    :deep > * {
      max-width: 560px;
      flex: 1;
      margin: 0 calc(var(--spacing-2) * 0.5) var(--spacing-2) calc(var(--spacing-2) * 0.5);
      @media #{map-get($display-breakpoints, 'lg-only')} {
        margin: 0 calc(var(--spacing-3) * 0.5) var(--spacing-3) calc(var(--spacing-3) * 0.5);
      }
      @media #{map-get($display-breakpoints, 'xl-only')} {
        margin: 0 calc(var(--spacing-4) * 0.5) var(--spacing-4) calc(var(--spacing-4) * 0.5);
      }
      @media #{map-get($display-breakpoints, 'xxl-only')} {
        margin: 0 calc(var(--spacing-5) * 0.5) var(--spacing-5) calc(var(--spacing-5) * 0.5);
      }

      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
      height: auto;
    }

    @media #{map-get($display-breakpoints, 'md-and-down')} {
      flex-flow: column nowrap;
      align-items: center;
      :deep > * {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
.buffer-wrap {
  display: flex;
  justify-content: center;
}

.buffer {
  margin: var(--spacing-4);
}
</style>
