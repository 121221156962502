import {
  SET_ROUTE,
  CLEAR_ROUTE,
} from '@/shared/store/actionType';

const defaultJourney = {
  entity: false, // main entity of URL e.g. ['customers','prospects','opportunities','interactions'],
  relationEntity: false, // related entity ['contacts', 'posts', 'files'],
  action: false, // possible actions ['add', 'edit']
};

// initial state
const state = () => ({
  data: {
    ...defaultJourney,
  }
});

// getters
const getters = {
};

// mutations
const mutations = {
  [SET_ROUTE]: (state, journey) => {
    state.data = {
      ...state.data,
      ...journey,
    };
  },
  [CLEAR_ROUTE]: (state) => {
    state.data = {
      ...defaultJourney,
    };
  },
};

// actions
const actions = {
  [SET_ROUTE] ({ commit, }, journey) {
    commit(SET_ROUTE, journey);
  },
  [CLEAR_ROUTE] ({ commit, }) {
    commit(CLEAR_ROUTE);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
