<template>
  <div
    v-if="!loading" 
    class="fields-analysis"
  >
    <div
      v-for="(bubble, index) in bubbles"
      :key="bubble.id"
      class="main-container"
      :class="{'right-col': index}"
    >
      <div class="bubble-section">
        <Bubble
          :bubble-data="bubble"
          class="target-bubble"
        />
        <SearchInput
          class="search-container"
          :placeholder="`Search ${bubble.title}`"
          :disabled="bubbles[index].checkedAll && !bubbles[index].query.length"
          @search="handleSearch($event, index, bubble.id)"
        />
      </div>
      <div
        v-if="!fetching"
        class="values-section"
      >
        <div class="values">
          <CustomCheckbox
            v-show="!bubbles[index].query.length"
            class="single-value"
            :label="t('All')"
            :value="bubble.checkedAll"
            @input="handleAllCheckbox(bubble.id, $event, index)"
          />
          <CustomCheckbox
            v-for="value in bubble.values"
            v-show="!bubbles[index].checkedAll"
            :key="value.id"
            class="single-value"
            v-bind="value"
            @input="handleValueCheckbox(value, $event, bubble.id, index)"
          />
        </div>
      </div>
      <BufferImage
        v-else
        class="loading-spinner"
        color="var(--colour-utility-black)"
        float="center"
      />
    </div>
  </div>
  <BufferImage
    v-else
    class="loading-spinner"
    color="var(--colour-utility-black)"
    float="center"
  />
  <div class="form-actions">
    <CustomButton
      purpose="action"
      small
      :disabled="!valid"
      @on-click="completeStage"
    >
      {{ t('Select dimensions') }}
    </CustomButton>
  </div>
</template>

<script setup>
import { computed, onActivated, ref } from 'vue';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import { CustomButton, CustomCheckbox, BufferImage } from '@sales-i/dsv3';
import Bubble from '@/intelligence/components/Bubble/Bubble';
import { FETCH_BUBBLE_DATA, FETCH_BUBBLE_VALUES, SAVE_CURRENT_VALUES, SAVE_WHOLE_DIM } from '@/intelligence/store/actionType';
import SearchInput from '@/dsv3/Forms/SearchInput.vue';

const store = useStore();
const emit = defineEmits(['completeStage']);
const props = defineProps({
  targetData: {
    type: Object,
    default: () => ({}),
  },
});

// data
const bubbles = ref([]);
const loading = ref(true);
const fetching = ref(false);

// store
const bubbleData = computed(() => store.state.intelligence.enquiry.bubbleData);

// store actions
const fetchBubbleData = () => store.dispatch(`intelligence/enquiry/${FETCH_BUBBLE_DATA}`);
const fetchBubbleValues = array => store.dispatch(`intelligence/enquiry/${FETCH_BUBBLE_VALUES}`, array);
const saveCurrentValues = payload => store.dispatch(`intelligence/targets/${SAVE_CURRENT_VALUES}`, payload);
const saveWholeDim = payload => store.dispatch(`intelligence/targets/${SAVE_WHOLE_DIM}`, payload);

// computed
const valid = computed(() => currentValues.value.length && currentValues.value.every(item => item.values.length || item.checkedAll));
const currentValues = computed(() => store.state.intelligence.targets.currentTargetValues);

onActivated(async () => {
  loading.value = true;
  if (!bubbleData.value) await fetchBubbleData();

  bubbles.value = await Promise.all(props.targetData.dimensions.map(async (dim,i) => {
    const { id, title, area } = bubbleData.value.find(bubble => bubble.id == dim.id);
    const preselectedBubble = currentValues.value[i].values;
    const bubbleValues = await fetchBubbleValues([id]);

    if (preselectedBubble.length === bubbleValues.length) {
      const payload = {
        checked: true,
        dimIndex: i,
        bubbleId: id
      };
      saveWholeDim(payload);
    }

    const processedValues = bubbleValues.map(value => ({
      value: preselectedBubble && preselectedBubble.some(el => el == value.id) || currentValues.value[i].checkedAll,
      label: value.value,
      id: value.id,
    }));
    return {
      id,
      title: `${area} / ${title}`,
      area,
      disabled: false,
      clickable: false,
      description: false,
      xButtonEvent: false,
      checked: false,
      checkedAll: currentValues.value[i].checkedAll ? true : false,
      values: processedValues,
      query: ''
    };
  }));
  loading.value = false;
});

// methods
function handleValueCheckbox(selectedBubble, checked, bubbleId, dimIndex) {
  const { id } = selectedBubble;
  const bubble = bubbles.value.find(bubble => bubble.id == bubbleId);
  bubble.values.find(value => value.id == id).value = checked;
  const selectedValues = { selectedBubble, dimIndex };
  saveCurrentValues({...selectedValues, bubbleId});
  
  const isAllValuesChecked = currentValues.value[dimIndex].values.length === bubble.values.length;
  const isBubbleValuesLessThan50 = bubble.values.length < 50;
  const isSearchQueryEmpty = !bubble.query.length;

  if (isAllValuesChecked && isBubbleValuesLessThan50 && isSearchQueryEmpty) {
    saveWholeDim({ checked: true, dimIndex, bubbleId });
    bubble.checkedAll = true;
  }
}

function handleAllCheckbox(bubbleId, checked, dimIndex) {
  const bubble = bubbles.value.find(bubble => bubble.id == bubbleId);
  bubble.values.forEach(value => value.value = checked);
  bubble.checkedAll = checked;
  const selectedDim = { checked, dimIndex };
  saveWholeDim({...selectedDim, bubbleId});
}

function completeStage() {
  const dimensions = bubbles.value.map((bubble, i) => {
    const { id, checkedAll } = bubble;
    return {
      id,
      values: checkedAll ? [] : currentValues.value[i].values,
    };
  });
  const targetData = { dimensions };
  emit('completeStage', { targetData, newStage: 3 });
}

async function handleSearch(searchQuery, index, bubbleId) {
  fetching.value = true;
  const filteredBubbles = await fetchBubbleValues([bubbleId, searchQuery]);
  fetching.value = false;
  const isAllChecked = bubbles.value[index].checkedAll;

  bubbles.value[index].query = searchQuery;
  const processedValues = filteredBubbles.map(value => ({
    value: filteredBubbles && currentValues.value[index].values.some(el => el === value.id) || isAllChecked,
    label: value.value,
    id: value.id,
  }));

  bubbles.value[index].values = processedValues;
}
</script>

<style lang="scss" scoped>
.fields-analysis {
  display: flex;
  flex-grow: 1;
  margin-bottom: var(--spacing-10);

  .main-container {
    min-width: 50%;
    
    &.right-col {
      border-left: 1px solid var(--colour-panel-g-24);
    }

    .bubble-section {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: var(--spacing-2) var(--spacing-3);

      .target-bubble {
        width: fit-content;
      }
    }

    .values-section {
      margin: var(--spacing-4);
      flex-grow: 1;

      .values {
        display: flex;
        gap: var(--spacing-3);
        flex-wrap: wrap;

        .single-value {
          font-size: var(--font-size-4);
        }
      }
    }
  }
}
.form-actions {
  display: flex;
  gap: var(--spacing-2);
  align-items: center;
  justify-content: center;
  position: fixed;
  padding: var(--spacing-2) 0 var(--spacing-2);
  background-color: var(--colour-panel-action);
  bottom: 0;
  width: 100%;
}
.loading-spinner {
  position: absolute;
  top: calc(50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

.search-container {
  margin-top: var(--spacing-2);
  :deep(.svg-container.icon-search) {
    top: 50% !important;
    transform: translateY(-50%);
  }
  :deep(.form-group) {
    margin-bottom: 0;
  }
  :deep(input) {
    border-radius: var(--spacing-4);
    font-size: var(--font-size-small);
  }
}
</style>
