import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { dates, t } from '@sales-i/utils';

import {
  alertBuilderScope,
  alertsScope
} from '@/intelligence/router/urlBits';
import useCurrency from '@/shared/composables/useCurrency';
import { useAlertBuilder } from './useAlertBuilder';

export default function useFilterSummary({ store = useStore(), vroute = useRoute() } = {}) {
  // Setup
  const formatDate = dates.format;

  const { alertBuilderBaseUrl } = useAlertBuilder({ store });

  let { cs } = useCurrency();

  const selectedQueryId = computed(() => vroute.params.queryId);
  const salesFilters = computed(() => store.state.intelligence.queryBuilder.salesFilters);
  const customerFilters = computed(() => store.state.intelligence.queryBuilder.customerFilters.filterClauses);
  const products = computed(() => store.state.intelligence.queryBuilder.products);
  const interactionTypeOptions = computed(() => store.state.admin.fields.data.type.filter(o => o.name !== 'Personal'));
  
  // Only show the selectedQueryId if it exists
  const queryId = selectedQueryId.value ? `/${selectedQueryId.value}` : '';

  const isSalesLink = computed(() => vroute.path !== salesLink.value);
  const isCRMLink = computed(() => vroute.path !== CRMLink.value);
  const isProductLink = computed(() => vroute.path !== productLink.value);

  // If the route path includes 'customers', the we assume this filter appears on the customer contact card. If so, the links should go to results for clikced table
  const salesLink = computed(() => {
    return vroute.path.includes('customers') ? 
      `${alertBuilderBaseUrl.value}/${alertsScope}${queryId}` :
      `${alertBuilderBaseUrl.value}/${alertsScope}${queryId}/${alertBuilderScope}/sales-data`;
  });
  
  const CRMLink = computed(() => {
    return vroute.path.includes('customers') ? 
      `${alertBuilderBaseUrl.value}/${alertsScope}${queryId}` :
      `${alertBuilderBaseUrl.value}/${alertsScope}${queryId}/${alertBuilderScope}/crm-data`;
  });
  
  const productLink = computed(() => {
    return vroute.path.includes('customers') ? 
      `${alertBuilderBaseUrl.value}/${alertsScope}${queryId}` :
      `${alertBuilderBaseUrl.value}/${alertsScope}${queryId}/${alertBuilderScope}/product-data`;
  });
  
  const getSalesFilterOptions = computed(() => store.getters['intelligence/queryBuilder/getSalesFilterOptions']);

  const formattedSalesFilter = computed(() => {
    const currencyFields = ['sales_value', 'profit', 'gross_profit', 'average_price', 'cost'];
    const percentageFields = ['percent_up', 'percent_down'];

    function formatValue(item) {
      // If the field is one of the currencyFields and the operator is NOT one of the percentageFields then format the value as a currency.
      // If the operator is one of the percentageFields then format the value as as a percentage.
      // Otherwise, return the value as is
      const isCurrency = currencyFields.includes(item.field);
      const isPercentage = percentageFields.includes(item.operator);

      if (isCurrency && !isPercentage) {
        return `${cs}${item.value}`;
      } else if (isPercentage) {
        return `${item.value}%`;
      } else {
        return item.value;
      }
    }

    function formatOperator(item) {
      const operatorFormats = {
        'percent_up': 'up',
        'percent_down': 'down',
      };
      
      return operatorFormats[item.operator] || item.operator;
    }
          
    return salesFilters.value.map(item => ({
      field: getSalesFilterOptions.value.find(option => option.value === item.field)?.text,
      id: item.id,
      operator: item.operator? formatOperator(item): '',
      type: item.type,
      value: item.value !== '' ? formatValue(item) : '',
    }));
  });

  const formattedCRMFilters = computed(() => {
    // Return all but the filters that have 'date_end' as the 'field
    return customerFilters.value.filter(item => item.field !== 'date_end').map((item) => {      
      if (item.field === 'interaction_type') {
        const dateFilter = customerFilters.value.find(item => item.field === 'date_end');
        let formattedField = '';
        let interactionTypeNames = '';
        if (item.value === '') {
          formattedField = 'Have been contacted by any method';
        } else if (item.value?.length === 0) {
          formattedField = 'Have NOT been contacted ';
        } else {
          formattedField = 'Have been contacted by ';
          interactionTypeNames = interactionTypeOptions.value
            .filter(option => item.value?.includes(option.id.toString()))
            .map(option => option.name)
            .join(', ');
        }
        
        let dateFilterText = '';
        if (dateFilter?.is === 'BETWEEN' && dateFilter?.value?.length >= 2) {
          let dateFrom = formatDate(dateFilter?.value?.at(-2), 'DD/MM/YYYY') || null;
          let dateTo = formatDate(dateFilter?.value?.at(-1), 'DD/MM/YYYY') || null;
          dateFilterText = dateFrom && dateTo ? ` between ${dateFrom} and ${dateTo}` : '';
        } else if (dateFilter?.is && dateFilter?.value?.length === 1) {
          const labels = { 
            '=': t('on'), 
            '>': t('after'),
            '<': t('before'),
          };
          dateFilterText = ` ${labels[dateFilter?.is]} ${formatDate(dateFilter?.value[0], 'DD/MM/YYYY')}`;
        }            
        const formattedValuesString = interactionTypeNames + dateFilterText;
        return {
          clause: item.clause,
          entity: item.entity,
          field: formattedField,
          id: item.id,
          is: item.is,
          value: formattedValuesString,
        };
      }

      if (item.entity === 'contact') {
        const formattedField = 'Job title is ';
        return {
          clause: item.clause,
          entity: item.entity,
          field: formattedField,
          id: item.id,
          is: item.is,
          value: item.value,
        };
      }

      if (item.entity === 'address') {
        let addressClause = item.is === '=' ? 'is' : 'is not';
        let formattedField = `${item.field.charAt(0).toUpperCase() + item.field.slice(1).replace(/_/g, ' ')} ${addressClause}`;
  
        return {
          clause: item.clause,
          entity: item.entity,
          field: formattedField,
          id: item.id,
          is: item.is,
          value: item.value,
        };
      }
  
      return {
        clause: item.clause,
        entity: item.entity,
        field: item.field,
        id: item.id,
        is: item.is,
        value: item.value,
      };
    });
  });
   
  const salesFilterData = computed(() => {
    return formattedSalesFilter.value.some((item) => item.field !== '' || item.operator !== '' || item.value !== '');
  });

  const CRMFilterData = computed(() => {
    // If there are any empty fields, set it to true. As soon as any empty fields are found, return false
    return formattedCRMFilters.value.some((item) => item.entity !== '' || item.field !== '' || item.value !== '');
  });
  
  return {
    formattedSalesFilter,
    formattedCRMFilters,
    salesFilterData,
    CRMFilterData,
    isSalesLink,
    isCRMLink,
    isProductLink,
    salesLink,
    CRMLink,
    productLink,
    products,
    selectedQueryId
  };
}