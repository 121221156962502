<template>
  <div v-if="!loading">
    <div
      v-for="(dim, i) in dimensions"
      :key="dim.id"
      class="modal-container"
    >
      <h4>{{ getTitle(dim.id) }}</h4>
      <p
        v-for="(bubble, index) in bubbles(i)"
        :key="index"
      >
        {{ bubble }}
      </p>
    </div>
  </div>
  
  <BufferImage
    v-else
    class="loading-spinner"
    color="var(--colour-utility-black)"
    float="center"
  />
</template>
  
<script setup>
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import { FETCH_BUBBLE_VALUES } from '@/intelligence/store/actionType';
import { BufferImage } from '@sales-i/dsv3';
  
const store = useStore();
const loading = ref(true);
const bubbleValues = ref([]);

const props = defineProps({
  targetDetails: {
    type: Object,
    default: () => ({}),
  }
});

onMounted(async () => {
  loading.value = true;
  bubbleValues.value = await Promise.all(dimensions.value.map(fetchAndProcessBubbleValues));
  loading.value = false;
});

// computed
const dimensions = computed(() => props.targetDetails.value.dimensions);
const bubbleData = computed(() => store.state.intelligence.enquiry.bubbleData);
// store actions
const fetchBubbleValues = array => store.dispatch(`intelligence/enquiry/${FETCH_BUBBLE_VALUES}`, array);

const bubbles = (i) => bubbleValues.value[i] ? bubbleValues.value[i].title : [];
const getTitle = (id) => bubbleData.value.find(bubble => bubble.id == id).title;
// methods
const fetchAndProcessBubbleValues = async (dim) => {
  const { id, values } = dim;
  const bubbles = await fetchBubbleValues([id]);
  let title;
  if (!values.length) {
    title = ['All'];
  } else {
    const filteredBubbles = bubbles.filter(bubble => values.includes(bubble.id));
    title = filteredBubbles.map(bubble => bubble.value);
  }
  return { title };
};
</script>
<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.modal-container {
    padding: var(--spacing-2);
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding: var(--spacing-3);
    }
}
</style>
