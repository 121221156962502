import {
  POST_BY_ENTITY,
  POST_BY_ENTITY_LOADING,
  POST_BY_ENTITY_ERROR
} from '@/shared/store/actionType';

import { httpRequest } from '@sales-i/utils';

// mutations
export const postProspectMutations = {
  [POST_BY_ENTITY]: (state, data) => {
    state.selected.data = data;
    state.selected.loading = false;
    state.selected.loaded = true;
    state.selected.updated = true;
  },
  [POST_BY_ENTITY_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [POST_BY_ENTITY_ERROR]: (state, errors) => {
    state.selected.errors = errors;
    state.selected.loading = false;
    state.selected.loaded = false;
    state.selected.updated = false;
  },
};

// actions
export const postProspectActions = {
  [POST_BY_ENTITY]: async ({ dispatch, commit }, params) => {
    const { entity, payload, } = params;
    commit(POST_BY_ENTITY_LOADING);
    let customer = {
      record_type: 'prospect',
      name: payload.name,
      tel_1: payload.customer_tel_1,
      tel_2: payload.customer_tel_2,
      email: payload.customer_email,
      fax: payload.customer_fax,
      description: payload.details_description,
      www: payload.customer_www,
      addresses: [{
        address_line_1: payload.customer_address_line_1,
        address_line_2: payload.customer_address_line_2,
        address_line_3: payload.customer_address_line_3,
        address_line_4: payload.customer_address_line_4,
        town: payload.customer_town,
        county: payload.customer_county,
        post_code: payload.customer_post_code,
        country: payload.customer_country
      }],
    };
    try {
      const data = await httpRequest('post', `${entity}`, customer, { version: '2' });

      let saveContact = false;
      let contact = {
        first_name: payload.contact_first_name,
        surname: payload.contact_surname,
        mobile_phone: payload.contact_mobile_phone,
        business_phone: payload.contact_business_phone,
        office_phone: payload.contact_office_phone,
        home_phone: payload.contact_home_phone,
        email: payload.contact_email,
        fax: payload.contact_fax,
        is_default_contact: true,
        address_line_1: payload.contact_address_line_1,
        address_line_2: payload.contact_address_line_2,
        address_line_3: payload.contact_address_line_3,
        address_line_4: payload.contact_address_line_4,
        town: payload.contact_town,
        county: payload.contact_county,
        post_code: payload.contact_post_code,
        country: payload.contact_country
      };

      Object.keys(contact).forEach(key => {
        if (contact[key] && !['is_default_contact'].includes(key)) {
          saveContact = true;
        }
      });
      
      let contactData = {};
      if (saveContact) {
        contactData = await dispatch(`crm/contacts/${POST_BY_ENTITY}`,{
          entity: 'prospects',
          id: data.id,
          payload: contact
        },{
          root:true
        });
      }

      commit(POST_BY_ENTITY, {
        ...data,
        ...customer,
        default_contact_id: contactData.id,
      });
    } catch (error) {
      commit(POST_BY_ENTITY_ERROR);
    }
  },
};
