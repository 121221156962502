<template>
  <CustomCard
    v-if="entityId && (mergeItems?.length || loading) && isEnabledMergeProspects()"
    purpose="action"
    footer-color="grey"
    class="action"
  >
    <BufferImage
      v-if="loading"
      :size="40"
      data-testid="loading"
    />
    <template v-else>
      <h3 class="mt-2">
        <IconBase
          class="mr-3"
          icon-name="merge"
          icon-color="var(--colour-brand-mandy)"
          height="24"
          width="24"
        />
        {{ t('Search for a customer account to merge with or select a suggestion') }}
      </h3>
      <CustomOverlay
        v-if="showOverlay"
        @clicked="closeOverlay"
      />
      <div class="search-field-container">
        <SearchField
          ref="searchFieldRef"
          class="prospect-search-input"
          :class="{ 'active': showOverlay }"
          :search-func="searchResultsHandler"
          :close-after-selecting="false"
          :search-on-load="false"
          :placeholder="t('Name or customer account number')"
          :value="currentSearchInput"
          @close="handleClose"
          @on-close="handleCancel"
        >
          <template #result="{ result }">
            <div class="search-prospects">
              <CustomCard
                class="crm-card-result"
                :title="result.full_name"
                :class="{ selected: selectedItem == result }"
                @click="() => handleRadioCheck(result)"
              >
                <div class="mergeable-prospect search-item">
                  <CustomRadio
                    :disabled="merging"
                    :checked="selectedId == result.id"
                    :value="result"
                  />
                  <div>
                    <TextHighlighter
                      class="fw-bld text-5"
                      :text="result.name"
                      :highlight="currentSearchInput"
                    />
                    <TextHighlighter
                      :text="result.account_number"
                      :highlight="currentSearchInput"
                    />
                    <span>{{ addr.asString(result.addresses[0]) }}</span>
                  </div>
                </div>
              </CustomCard>
            </div>
          </template>
          <template #buttons>
            <div
              v-if="selectedItem"
              class="merge-actions"
            >
              <ButtonGroup>
                <CustomButton
                  class="m-1 cancel-button"
                  small
                  purpose="reversed"
                  :label="t('Cancel')"
                  @on-click="handleCancel"
                />
                <CustomButton
                  class="m-1"
                  small
                  :label="t('Merge')"
                  :disabled="!selectedItem || merging"
                  @on-click="handleMerge"
                />
              </ButtonGroup>
            </div>
          </template>
        </SearchField>
      </div>

      <div class="mergeable-prospects">
        <CustomCard
          v-for="(item, index) in mergeItems"
          :key="index"
          purpose="reversed"
          :class="{ selected: selectedItem == item }"
          @on-click="() => enableMerge(item)"
        >
          <div class="mergeable-prospect">
            <CustomRadio
              :disabled="merging"
              :checked="selectedId == item.id"
              @on-change="() => enableMerge(item)"
            />
            <div>
              <CustomButton
                purpose="text"
                class="customer-link fw-bld text-5"
                icon-name="new-tab"
                :label="item.name"
                icon-align="right"
                icon-height="16"
                icon-width="16"
                icon-color="var(--colour-utility-action)"
                @click="() => openInNewTabHandler(item.id)"
              />
              <span class="acc-number">{{ item.account_number }}</span>
            </div>
          </div>
        </CustomCard>
      </div>

      <div class="merge-actions">
        <p v-if="selectedItem">
          <template v-if="merged == undefined">
            {{ t(`Merge this prospect with "${ selectedItem?.name }"?`) }}
          </template>
          <template v-if="merged == true">
            {{ t(`This prospect was successfully merged with "${ selectedItem?.name }"`) }}
          </template>
          <template v-if="merged === false">
            {{ t(`This prospect failed to merge with "${ selectedItem?.name }"`) }}
          </template>
        </p>
        
        <ButtonGroup>
          <CustomButton
            class="m-1"
            small
            :label="t('Yes! Merge')"
            :disabled="!selectedItem || merging"
            @on-click="handleMerge"
          />
        </ButtonGroup>
      </div>
    </template>
  </CustomCard>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { t, address as addr } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';
import { CustomCard, CustomButton, IconBase, CustomRadio, BufferImage, CustomOverlay, TextHighlighter } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import store from '@/shared/store';
import { SET_CONFIRMATION_MODAL } from '@/shared/store/actionType';
import { GET_MERGE_ITEMS, MERGE_PROSPECT } from '@/crm/store/actionType';
import { baseUrl, customersArea } from '@/crm/router/urlBits';
import SearchField from '@/shared/components/Search/SearchField.vue';
import useSearchInput from '@/shared/components/Search/composables/useSearchInput';
import useFeatureFlags from '@/shared/composables/useFeatureFlags';

const selectedItem = ref(null);
const mergeItems = ref(undefined);
const loading = ref(true);
const merging = ref(false);
const merged = ref(undefined);
const currentSearchInput = ref('');
const selectedId = ref(null);
const showOverlay = ref(false);
const searchFieldRef = ref(null);
const url = ref('customers');
const type = ref('customer');
const customerId = ref(null);

const vroute = useRoute();

const props = defineProps({
  entityId: {
    type: Number,
    default: undefined
  }
});

const { getSearchResults } = useSearchInput({ url, type });
const { featureIsEnabled } = useFeatureFlags();

const mergeProspect = ({ id, customer_id }) => store.dispatch(`crm/prospects/${MERGE_PROSPECT}`, { id, customer_id });
const getMergeItems = ({ id }) => store.dispatch(`crm/prospects/${GET_MERGE_ITEMS}`, { id });

onMounted(() => { 
  loadItems();
});

const isEnabledMergeProspects = () => {
  return featureIsEnabled('MERGE_PROSPECTS');
};

const loadItems = async () => {
  if (!props.entityId) return;
  loading.value = true;
  mergeItems.value = await getMergeItems({ id: props.entityId });
  loading.value = false;
};

const openInNewTabHandler = (id) => {
  const path = `${baseUrl}/${customersArea}/${id}`;
  const query = new URLSearchParams(vroute.query);
  window.open(`${path}?${query.toString()}`, '_blank');
};

const handleMerge = async () => {
  await store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, {
    message: t(`Do you want to merge the prospect into ${selectedItem.value.name}?`),
    updatedMessage: t('Merge successful'),
    updateFunction: async () => {
      merged.value = undefined;
      merging.value = true;
      const customer_id = selectedItem.value?.id || customerId.value;
      const res = await mergeProspect({ id: props.entityId, customer_id });
      merging.value = false;
      merged.value = res;
      return res;
    },
    finishFunction: () => {
      if (merged.value && selectedItem.value?.id) {
        navigateToUrl(`${baseUrl}/${customersArea}/${selectedItem.value.id}`);
      } else if (merged.value && customerId.value) {
        navigateToUrl(`${baseUrl}/${customersArea}/${customerId.value}`);
      }
    },
    closeFunction: () => true,
  });
};

const enableMerge = (item) => {
  merging.value = false;
  merged.value = undefined;
  selectedItem.value = item;
  selectedId.value = item.id;
};

const handleRadioCheck = (result) => {
  selectedId.value = result.id;
  selectedItem.value = result;
  customerId.value = result.id;
};

const searchResultsHandler = async (query) => {
  customerId.value = null;
  currentSearchInput.value = query;
  showOverlay.value = true;
  const queryResults = await getSearchResults(query, 5);
  return queryResults.filter(customer => 
    customer.name.toLowerCase().includes(query.toLowerCase()) || 
    customer.account_number.toLowerCase().includes(query.toLowerCase())
  );
};

const handleClose = () => {
  if (showOverlay.value) showOverlay.value = false;
  selectedId.value = null;
  selectedItem.value = null;
};

const handleCancel = () => {
  if (searchFieldRef.value && searchFieldRef.value.closeDropdown) {
    searchFieldRef.value.closeDropdown(); // Call closeDropdown method in SearchField component
    showOverlay.value = false;
  }
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.action {
  background-color: var(--colour-data-mandy-background);
  padding: 0;
  height: auto;
  margin-bottom: var(--spacing-2);

  h3,
  .text-sm {
    padding: 0 var(--spacing-3);
  }
  .mergeable-prospects,
  .search-prospects {
    padding: 0 var(--spacing-3);
    margin-bottom: var(--spacing-3);
    width: 100%;

    .selected {
      box-shadow: 0 0 0 4px var(--colour-utility-focus);
      .mergeable-prospect {
        :deep(.radio-container) {
          background-color: var(--colour-utility-focus);
        }
      }
    }

    .c-card {
      padding: 0;
      margin-bottom: var(--spacing-1);
    }

    .mergeable-prospect {
      display: flex;
      align-items: center;
      
      :deep(.radio) {
        background-color: var(--colour-panel-base);
        display: inline-flex;
        align-self: stretch;
        margin-right: 0;
      }

      :deep(.radio-container) {
        padding: var(--spacing-2) 0 var(--spacing-2) var(--spacing-1);
      }

      div {
        display: flex;
        flex-direction: column;
        margin: 0 var(--spacing-1);
        gap: var(--spacing-1);
        padding: var(--spacing-1) 0;

        .customer-link {
          display: inline-flex;
          :deep(span) {
            text-align: left;
          }
        }

        span {
          font-size: 0.8rem;
         
          &.acc-number {
            font-size: var(--font-size-small);
          }
        }
      }
    }
  }

}
h3 {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: var(--font-weight-regular);
}
.merge-actions {
  background-color: var(--colour-panel-action);
  padding: var(--spacing-1) var(--spacing-3);

  p {
    margin-bottom: var(--spacing-1);
  }
}

.merge-actions {
  .button-group {
    .button {

      &:not(:last-child) {
        margin-right: 0;
      }

      .button-container {
        justify-content: center;
      }
    }
  }

  .cancel-button {
    background-color: var(--colour-utility-white);
  }
}

.selected {
  .radio-container {
    border-radius: var(--border-radius-1) 0 0 var(--border-radius-1);
    background-color: black;
  }
}

.prospect-search-input {
  padding: 0 var(--spacing-3);

  &.active {
    z-index: 100;
  }

  .search-prospects {
    padding: var(--spacing-2);
    margin-bottom: 0;

    &:deep(.c-card) {
      margin-bottom: 0;
    }

    div {
      background-color: var(--colour-utility-white);
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    &:deep(.results) {
      overflow: initial;
      max-height: initial
    }
  }

    &:deep(.input) {
      border-radius: var(--border-radius-half);

      input {
        font-weight: var(--font-weight-regular);
      }
  }
}
</style>
