import {
  GET_TOTALS,
  GET_TOTALS_LOADING,
  GET_TOTALS_ERROR,
} from '@/admin/store/actionType';
import { httpRequest } from '@sales-i/utils';

// getters
export const getTotalsGetters = {
};

// mutations
export const getTotalsMutations = {
  [GET_TOTALS]: (state, data) => {
    state.data = data;
    state.loaded = true;
    state.loading = false;
  },
  [GET_TOTALS_LOADING]: (state) => {
    state.loaded = false;
    state.loading = true;
  },
  [GET_TOTALS_ERROR]: (state, data) => {
    state.data = [];
    state.loaded = false;
    state.loading = false;
    state.errors = data;
  },
};

// actions
export const getTotalsActions = {
  [GET_TOTALS]: async ({ commit, }) => {
    commit(GET_TOTALS_LOADING);
    try {
      const data = await httpRequest('get', 'totals', {}, {version: '2', area: 'files'});
      commit(GET_TOTALS, data);
    } catch(e) {
      commit(GET_TOTALS_ERROR, []);
    }
  },
};
