import { useStore } from 'vuex';
import { GET_BY_ENTITY } from '@/shared/store/actionType';
import { computed } from 'vue';

export default function useFields({ store = useStore() } = {}) {
  const loading = computed(() => store.state.admin.fields.loading);
  const fields = computed(() => store.state.admin.fields.data);
  const interactionTypeOptions = computed(() => store.state.admin.fields.data.type);
  const interactionOutcomeOptions = computed(() => store.state.admin.fields.data.outcome);
  
  const interactionOptionsFields = computed(() => store.getters['admin/fields/getInteractionOptionsFields']);
  const fieldsOptions = computed(() => store.getters['admin/fields/getFieldsOptions']);
  
  const getFields = (params) => store.dispatch(`admin/fields/${GET_BY_ENTITY}`, params);

  return {
    loading,
    fields,
    fieldsOptions,
    interactionOptionsFields,
    interactionTypeOptions,
    interactionOutcomeOptions,
    getFields
  };
}