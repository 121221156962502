import { stagesGetters, stagesMutations, stagesActions } from './stages';
// initial state
const state = () => ({
  stages: {
    data: [],
    loaded: false,
    loading: false,
    error: undefined,
    minId: 0
  },
});

// getters
const getters = {
  ...stagesGetters
};

// mutations
const mutations = {
  ...stagesMutations
};

// actions
const actions = {
  ...stagesActions
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
