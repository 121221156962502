<template>
  <div class="roles-list">
    <CRMCard
      v-for="role in roles"
      :id="role.id"
      :key="role.id"
      :on-click="() => {}"
      :title="role.name"
      :links="links(role)"
      @edit="editRole(role.id)"
      @delete="deleteRole(role)"
    >
      <template #info>
        {{ role.description }}
        <br>
        <CustomButton
          purpose="text"
          @on-click="showRoleDetailsModal(role.id)"
        >
          {{
            role.member_count === 1 ?
              t(
                `${role.member_count} user`,
                'count_user',
                {
                  interpolations: {
                    count: role.member_count
                  }
                }
              ) :
              t(
                `${role.member_count} users`,
                'count_users',
                {
                  interpolations: {
                    count: role.member_count
                  }
                }
              )
          }}
        </CustomButton>
      </template>
    </CRMCard>
  </div>
  <router-view />
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import { CustomButton, CRMCard, } from '@sales-i/dsv3';
import { GET_ALL, DELETE_BY_ID, SET_CONFIRMATION_MODAL } from '@/shared/store/actionType';
import { baseUrl, companyArea, permissionsSection, rolesSection } from '@/admin/router/urlBits';
import useFeatures from '@/shared/composables/useFeatures';
import { navigateToUrl } from 'single-spa';

const store = useStore();

const { fullPbac } = useFeatures();
const roles = computed(() => store.state.admin.pbac.roles.data);
const getRoles = params => store.dispatch(`admin/pbac/${GET_ALL}`, params);
const deleteRoles = params => store.dispatch(`admin/pbac/${DELETE_BY_ID}`, params);
const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);

const editRole = (id) => {
  navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${rolesSection}/${id}/edit`);
};

const showRoleDetailsModal = (id) => {
  navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${rolesSection}/${id}`);
};

const links = (role) => {
  return role.editable && fullPbac.value ? [
    { text: t('Edit'), emit: 'edit' },
    { text: t('Delete'), emit: 'delete' },
  ] : [];
};

const deleteRole = role => {
  showConfirmationModal({
    message: t(
      `Do you really want to delete ${role.name}?`,
      'do_you_really_want_to_delete_variable',
      { interpolations: { variable: `${role.name}` }, }
    ),
    updatedMessage: t(
      `You have deleted ${role.name}`,
      'you_have_deleted_variable',
      { interpolations: { variable: role.name }, }
    ),
    updateFunction: async () => {
      try {
        await deleteRoles([role.id]);
        return true;
      } catch (e) {
        return false;
      }
    },
    finishFunction: () => {
      return true;
    },
    closeFunction: () => {
      return true;
    },
  });
};

onMounted(() => {
  getRoles({
    offset: 0,
    limit: 20,
    sort: 'name:asc'
  });
  
});
</script>

<style lang="scss" scoped>
.roles-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-2);
}
</style>
