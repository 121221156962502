<template>
  <AddEditProspect
    :title="t('Edit a prospect')"
    :saved="saved"
    :fields="fields"
    @submit="save"
    @edit="edit"
    @close="close"
  />
</template>

<script>
import AddEditProspect from '@/crm/components/Prospect/AddEditProspect.vue';
import { mapState, mapActions } from 'vuex';
import { GET_FIELDS, GET_BY_ID, CLEAR_FIELDS, CLEAR_BY_ID, PUT_BY_ID, PUSH_ROUTE } from '@/shared/store/actionType';
import { t } from '@sales-i/utils';
import { baseUrl, prospectsArea } from '@/crm/router/urlBits';

export default {
  name: 'EditProspectView',
  components: {
    AddEditProspect,
  },
  props: {},
  emits: ['closeModal', 'submit'],
  data() {
    return {
      prospectId: this.$route.params.id,
      saved: false,
    };
  },
  computed: {
    ...mapState({
      entity: state => state.system.entity,
      fields: state => state.crm.prospects.fields,
      prospect: state => state.crm.prospects.selected,
      contact: state => state.crm.contacts.selected,
    }),
  },
  mounted() {
    this.getFields();
  },
  unmounted() {
    this.clearFields();
    this.clearById();
  },
  methods: {
    t,
    ...mapActions({
      getProspectFields: `crm/prospects/${GET_FIELDS}`,
      clearFields: `crm/prospects/${CLEAR_FIELDS}`,
      getById: `crm/prospects/${GET_BY_ID}`,
      getContactById: `crm/contacts/${GET_BY_ID}`,
      clearById: `crm/contacts/${CLEAR_BY_ID}`,
      putProspect: `crm/prospects/${PUT_BY_ID}`,
      pushRoute: `system/${PUSH_ROUTE}`,
    }),
    async getFields() {
      let { id } = this.$route.params;
      if (!this.prospect.data.id) {
        await this.getById({ id });
      }
      if (this.prospect.data.id && this.prospect.data.default_contact_id) {
        await this.getContactById({ id: this.prospect.data.default_contact_id });
      }
      if (this.prospect.data.id) {
        this.getProspectFields(id);
      }
    },
    async save(payload, setSubmitting) {
      setSubmitting(true);
      let { id } = this.$route.params;
      await this.putProspect({
        prospect_id: id,
        entity: this.entity,
        payload: payload,
      });
      setSubmitting(false);
      if (this.prospect.loaded) {
        this.getProspectFields(id);
        this.saved = true;
      }
      this.$emit('submit');
    },
    close() {
      this.pushRoute({
        defaultUrl: `${baseUrl}/${prospectsArea}/${this.prospect.data.id}`,
        success: this.saved,
      });
    },
    edit() {
      // let { id } = this.$route.params;
      this.saved = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
