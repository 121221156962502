import {
  GET_BY_ENTITY,
  GET_BY_ENTITY_LOADING,
  GET_BY_ENTITY_ERROR,
  CLEAR_BY_ENTITY,
  CHANGE_LOCALE
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const getByLocaleMutations = {
  [GET_BY_ENTITY]: (state, translations) => {
    state.data = {
      ...state.data,
      ...translations,
    };
    state.loaded = true;
    state.loading = false;
  },
  [GET_BY_ENTITY_LOADING]: (state) => {
    state.loaded = false;
    state.loading = true;
  },
  [GET_BY_ENTITY_ERROR]: (state) => {
    state.loaded = false;
    state.loading = false;
  },
  [CLEAR_BY_ENTITY]: (state, {area}) => {
    state[area] = [];
    state.loaded = false;
    state.loading = false;
  },
  [CHANGE_LOCALE]: (state, locale = state.data) => {
    state.locale = locale;
  }
};

// actions
export const getByLocaleActions = {
  [GET_BY_ENTITY]: async ({ commit, }, parameters) => {
    try {
      const { locale } = parameters;
      commit(GET_BY_ENTITY_LOADING);
      const data = await httpRequest('get', `config/lang/${locale}`, {}, {version: '2', throwErrors: false, });
      let mappedTranslations = {};
      data.forEach(area => {
        mappedTranslations = {
          ...mappedTranslations,
          ...area.data,
        };
      });
      commit(GET_BY_ENTITY, mappedTranslations);
    } catch (error) {
      commit(GET_BY_ENTITY_ERROR, error);
    }
  },
  [CLEAR_BY_ENTITY]: ({ commit, }) => {
    commit(CLEAR_BY_ENTITY, {area: 'crm'});
  },
};
