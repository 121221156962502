import {
  EXPORT_REPORT,
  GENERATE_FILE_ID,
} from '@/intelligence/store/actionType';
import { gqlRequest, httpFileRequest } from '@sales-i/utils';
import { gql } from 'graphql-request';
import { saveAs } from 'file-saver';

export const exportGetters = {};

export const exportMutations = {};

export const exportActions = {
  [GENERATE_FILE_ID]: async (store, [report, requester]) => {
    const { fileExt, currency, reporter, address, name } = requester;
    const getRequestParameters = store.rootGetters['intelligence/shared/getRequestParameters'];
    let requestParameters = '';
    if (requester.requestParameterOverride !== '') {
      requestParameters = requester.requestParameterOverride;
    } else {
      requestParameters = getRequestParameters([report, { limit: 1000 }]);
    }
    try {
      const query = gql`
        {exportquerybuilder(
          ${requestParameters},
          export_details: {
              file_type: ${fileExt},
              reporter: "${reporter}",
              customer: "${name}",
              customer_address: "${address}",
              currency_symbol: "${currency}",
            }
        ) {
          id
        }}
      `;
      const { exportquerybuilder: { id } }  = await gqlRequest(query, {}, { debug: true });
      return {id, extension: fileExt};
    } catch (error) {
      console.error(error);
    }
  },
  [EXPORT_REPORT]: async ({ dispatch }, [report, requester]) => {
    const file = await dispatch(GENERATE_FILE_ID, [report, requester]);
    const { id, extension } = file;
    try {
      const crmEndpoint = `${process.env.VUE_APP_API_V2_ENDPOINT}/exports/v2/download`;
      const response = await httpFileRequest(`${crmEndpoint}/${id}`, {});
      const blob = new Blob(
        [response.data],
        {type: 'text/plain;charset=utf-8'}
      );
      saveAs(blob, `${id}.${extension}`);
      return response;
    } catch (error) {
      console.error(error);
    }
  },
};