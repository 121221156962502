<template>
  <div
    v-if="id"
    class="posts container container--fluid"
  >
    <div class="padded">
      <router-view :id="id" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostsMain',
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.posts {
  background-color: var(--colour-panel-g-2);
  flex: 1;
}
</style>
