<template>
  <SearchField
    v-bind="props"
    :search-func="getSearchResults"
    @input="handleInput"
    @search="handleSearch"
    @blur="handleBlur"
    @focus="handleFocus"
    @valid="handleValid"
  />
</template>

<script setup>
import { ref } from 'vue';
import { pluralise } from '@sales-i/utils';
import useSearchInput from './composables/useSearchInput';
import { baseProps, baseEmits } from './composables/searchInputPropsEmits';

const props = defineProps({
  ...baseProps,
  label: {
    type: String,
    default: 'Contact:',
  },
  entity: {
    type: [Boolean, String],
    default: false,
  },
  entityId: {
    type: [Boolean, String, Number],
    default: false,
  },
});
const emit = defineEmits(baseEmits);

const type = ref('contact');
const url = ref('contacts');

const mapResult = x => ({ ...x, value: x.id, text: `${x.header} (${x.relationship.name})` });
const getUrl = () => props.entity && props.entityId ? `${pluralise.string(props.entity)}/${props.entityId}/contacts` : 'contacts';

const {
  SearchField,
  getSearchResults,
  handleInput,
  handleSearch,
  handleBlur,
  handleFocus,
  handleValid
} = useSearchInput({ type, url, emit, mapResult, getUrl });
</script>
