export default function addFilterToUrlPath(filter, interactionReport = false, hasExistingQuery = true) {
  if (interactionReport) {
    let pathWithNewFilter = `filter=${filter.type ?? filter.filterKey}`;
    if (filter.filterKey) {
      filter.selected_values.forEach(v => pathWithNewFilter += `,${encodeURIComponent(v.id)}`);
    } else {
      filter.value.forEach(v => pathWithNewFilter += `,${encodeURIComponent(v)}`);
    }
    return `${hasExistingQuery ? '&' : '?' }${encodeURI(pathWithNewFilter)}`;
  }
  let pathWithNewFilter = `filter=${filter.id},${filter.filter_type},${filter.selectedFilterType}`;
  filter.selected_values.forEach(v => pathWithNewFilter += `,${encodeURIComponent(v.id)}`);
  return `&${encodeURI(pathWithNewFilter)}`;
}