import { addItemMutators, addItemActions } from './addItem';

// initial state
const state = () => ({
  loaded: true,
  loading: false,
  success: false,
  errors: []
});

// getters
const getters = {
};

// mutations
const mutations = {
  ...addItemMutators,
};

// actions
const actions = {
  ...addItemActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
