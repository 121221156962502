import {
  REMOVE_BY_INDEX,
  GET_BY_ENTITY,
  DELETE_BY_ID,
  DELETE_BY_ID_LOADING,
  DELETE_BY_ID_ERROR,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import namesakes from '@/admin/store/data/interactionFieldNamesakes';

// mutations
export const deleteByEntityMutations = {
  [REMOVE_BY_INDEX]: (state, { index, type }) => {
    let currentOptions = state.data[type];
    currentOptions.splice(index, 1)[0];
    state.data = {
      ...state.data,
      [type]: currentOptions,
    };
    state.loaded = true;
    state.loading = false;
  },
  [DELETE_BY_ID]: (state) => {
    state.loaded = true;
    state.loading = false;
  },
  [DELETE_BY_ID_LOADING]: (state) => {
    state.loaded = false;
    state.loading = true;
  },
  [DELETE_BY_ID_ERROR]: (state) => {
    state.loaded = false;
    state.loading = false;
    state.errors = [];
  },
};

// actions
export const deleteByEntityActions = {
  [REMOVE_BY_INDEX]: async ({ commit }, deleteObj) => {
    commit(REMOVE_BY_INDEX, deleteObj);
  },
  [DELETE_BY_ID]: async ({ commit, dispatch }, deleteObj) => {
    let { item, type } = deleteObj;
    commit(DELETE_BY_ID_LOADING);
    try {
      const data = await httpRequest('delete', `config/schema/interactions/lists/${namesakes[type]}/${item.id}`, {}, {version: '2',});
      commit(DELETE_BY_ID, data);
      dispatch(GET_BY_ENTITY, { entity: 'interactions', list_type: [namesakes[type]] });
    } catch(e) {
      commit(DELETE_BY_ID_ERROR, e);
    }
  },
};
