<template>
  <div
    class="edit-widget"
  >
    <CustomModal
      :title="t('Share with colleagues')"
      show-modal
      :min-width="300"
      @close-modal="handleClose"
    >
      <template #default>
        <div class="share-field">
          <Field
            :label="t('Title')"
            type="text"
            :value="title.value"
            :min-length="1"
            :max-length="100"
            :error-message="t('Title length should be between 1 and 100 characters')"
            required
            @valid="updateTitle"
          />
          <Field
            :label="t('Description')"
            :value="description.value"
            type="textarea"
            :min-length="3"
            :max-length="200"
            :error-message="t('Description length should be between 1 and 200 characters')"
            required
            @valid="updateDescription"
          />
          <Field
            v-bind="usersField"
            id="fldallocatedUsers"
            :value="assignedUsersComputed"
            @input="assignUsers"
          />
        </div>
      </template>
      <template #footer>
        <CustomButton
          :disabled="!buttonIsEnabled"
          small
          @click.stop="handleShare"
        >
          {{ t('Share with colleagues') }}
        </CustomButton>
      </template>
    </CustomModal>
  </div>
</template>

<script setup>
import { CustomModal, CustomButton, } from '@sales-i/dsv3';
import { computed, ref } from 'vue';
import { t } from '@sales-i/utils';
import Field from '@/shared/components/Form/Field.vue';
import { POST_BY_ENTITY } from '@/shared/store/actionType';
import { useStore } from 'vuex';
import { useUsers } from '@/shared/composables/useUsers';

const store = useStore();

const props = defineProps({
  enquiryUrl: {
    type: String,
    required: true
  }
});

const emit = defineEmits(['close', 'save']);

const { usersOptions } = useUsers(store);

const title = ref({ value: '' });
const description = ref({ value: '' });

const assignedUsers = ref([]);
const assignedUsersComputed = computed(() => assignedUsers.value.map(x => x.allocated_user_id));
const usersField = computed(() => ({
  id: 'allocated_users',
  label: t('Selected colleagues'),
  type: 'multiselect',
  showSelected: true,
  options: usersOptions.value,
  twoway: true,
}));

const buttonIsEnabled = computed(() => title.value.isValid && description.value.isValid && assignedUsersComputed.value.length > 0);

const shareEnquiry = params => store.dispatch(`notifications/${POST_BY_ENTITY}`, params);

function updateTitle(value) {
  title.value = value;
}

function updateDescription(value) {
  description.value = value;
}

function assignUsers(value) {
  const valueToSave = Array.isArray(value) ? value.map(x => ({ allocated_user_id: x.value })) : [];
  assignedUsers.value = valueToSave;
}

function handleClose() {
  emit('close');
}

function handleShare() {
  /* eslint-disable */
  console.log('shareEnquiry', {
    type: 'SharedEnquiry',
    title: title.value,
    description: description.value,
    path: props.enquiryUrl,
    subjects: assignedUsersComputed.value
  });
  shareEnquiry({
    type: 'SharedEnquiry',
    title: title.value.value,
    description: description.value.value,
    path: props.enquiryUrl,
    subjects: assignedUsersComputed.value
  });
  emit('save');
}
</script>

<style lang="scss" scoped>
.share-field {
  padding: var(--spacing-2);
}
</style>
