<template>
  <div class="enquiries-baseview-container">
    <SignPost
      v-if="permissionsComputed[intelligence_enquiries]?.read"
      :heading="t('CRM')"
      :description="t('Sell smarter with our suite of CRM solutions')"
      color="var(--colour-utility-action)"
      background-color="var(--colour-panel-action)"
      :links="enquiriesLinks"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { t } from '@sales-i/utils';
import SignPost from '@/admin/views/SignPost.vue';
import usePermissions from '@/shared/composables/usePermissions';
import { intelligence_enquiries } from '@/shared/store/data/policies';
import { baseUrl, customersArea, prospectsArea, interactionsArea, opportunitiesArea } from '@/crm/router/urlBits';

const {
  permissions: permissionsComputed,
} = usePermissions();

const enquiriesLinks = computed(() => {
  const arr = [];
  arr.push({
    title: t('Customer'),
    description: t('View and manage all of your customers'),
    href: `${baseUrl}/${customersArea}`,
  });
  arr.push({
    title: t('Prospect'),
    description: t('View and manage all of your prospects'),
    href: `${baseUrl}/${prospectsArea}`,
  });
  arr.push({
    title: t('Interactions'),
    description: t('View and manage all of your interactions'),
    href: `${baseUrl}/${interactionsArea}`,
  });
  arr.push({
    title: t('Opportunities'),
    description: t('View and manage all of your opportunities'),
    href: `${baseUrl}/${opportunitiesArea}`,
  });
  return arr;
});
</script>

<style lang="scss" scoped>
.enquiries-baseview-container {
  padding: var(--spacing-4);
  > div {
    border-radius: var(--border-radius-1);
  }
}
</style>
