import {
  SET_USER_ROLES,
  SET_USER_RESTRICTIONS,
  SET_USER_STATUS
} from '@/admin/store/actionType';
import { CLEAR_CURRENTLY_EDITING } from '@/crm/store/actionType';
import { SET_USER_DETAILS } from '@/shared/store/actionType';


// getters
export const currentlyEditingGetters = {
};

// mutations
export const currentlyEditingMutations = {
  [SET_USER_DETAILS]: (state, data) => {
    state.currentlyEditing.details = data;
  },
  [SET_USER_ROLES]: (state, data) => {
    state.currentlyEditing.roles = data;
  },
  [SET_USER_RESTRICTIONS]: (state, data) => {
    state.currentlyEditing.restrictions = data;
  },
  [SET_USER_STATUS]: (state, data) => {
    state.currentlyEditing.status = data;
  },
  [CLEAR_CURRENTLY_EDITING]: (state) => {
    state.currentlyEditing = {
      details: {},
      roles: [],
      restrictions: [],
      status: ''
    };
  },
};

// actions
export const currentlyEditingActions = {
  [SET_USER_DETAILS]: async ({ commit, }, details) => {
    commit(SET_USER_DETAILS, details);
  },
  [SET_USER_ROLES]: async ({ commit, }, roles) => {
    commit(SET_USER_ROLES, roles);
  },
  [SET_USER_RESTRICTIONS]: async ({ commit, }, data) => {
    commit(SET_USER_RESTRICTIONS, data);
  },
  [SET_USER_STATUS]: async ({ commit, }, data) => {
    commit(SET_USER_STATUS, data);
  },
  [CLEAR_CURRENTLY_EDITING]: ({ commit, }) => {
    commit(CLEAR_CURRENTLY_EDITING);
  },
};
