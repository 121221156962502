<template>
  <div
    :id="uniqueId"
    ref="container"
    class="chart-container"
  >
    <svg class="chart" />
    <div class="tooltip">
      <slot 
        name="tooltip" 
        :tooltip-data="tooltipData"
      >
        <p>{{ tooltipData?.data?.name }}</p>
        <p>{{ t('Value') }}: {{ formatValue(tooltipData?.value) }}</p>
      </slot>
    </div>
  </div>
</template>

<script setup>
// Treemap
import * as d3 from 'd3';
import { ref, onMounted, computed, onUnmounted, watch } from 'vue';
import { uuid, debounce, t } from '@sales-i/utils';

const uniqueId = uuid('chart');
const container = ref(null);

const props = defineProps({
  chartData: {
    type: Object,
    default: () => ({}),
  },
  depth: {
    type: Number,
    default: 3,
  },
  formatFunc: {
    type: Function,
    default: (value) => value
  },
  /**
   * returns colours for node d
   * (d) => 
   * {
   *    area: fill colour value,
   *    border: border colour,
   *    text: text colour
   * } 
   */
  colorFunc: {
    type: Function,
    default: undefined
  },
  svgWidth: {
    type: Number,
    default: 0
  },
  svgHeight: {
    type: Number,
    default: 500
  },
  showValues: {
    type: Boolean,
    default: true,
  },
});

// 
const width = () => props.svgWidth || container.value?.clientWidth - 48;
const debounceChartResize = debounce(handleChartResize, 250);
const containerResizeObserver = new ResizeObserver(debounceChartResize);

onMounted(() => {
  containerResizeObserver.observe(container.value);
});
onUnmounted(() => {
  containerResizeObserver.disconnect();
});

function handleChartResize() {
  generateTreemap(dataToDisplay.value);
}

onMounted(() => {
  tooltip.value = d3.select(`#${uniqueId} .tooltip`);
  handleChartResize();
});

onUnmounted(() => {
  drillStack.value = undefined;
});

watch(() => props.chartData, () => { 
  handleChartResize(); 
}, { deep: true });

const drillStack = ref([props.chartData]);
const dataToDisplay = computed(() => drillStack.value[drillStack.value.length - 1]);
const formatValue = props.formatFunc || (value => value);
const tooltip = ref(null);
const tooltipData = ref({});

// Function to build and render the treemap
function renderTreemap(svg, data) {
  const group = svg.selectAll('g')
    .data(data.descendants().filter(i => i.depth <= props.depth))
    .join('g')
    .attr('transform', d => `translate(${d.x0},${d.y0})`);

  const getColor = props.colorFunc || ((d) => ({
    area: [
      'var(--colour-panel-g-8)',
      'var(--colour-panel-g-56)', 
      'var(--colour-panel-g-48)', 
      'var(--colour-panel-g-32)', 
      'var(--colour-panel-g-24)',
      'var(--colour-panel-g-16)',
    ][d.depth],
    border: 'var(--colour-panel-g-4)',
    text: 'var(--colour-utility-black)'
  }));

  group.append('rect')
    .attr('width', d => d.x1 - d.x0)
    .attr('height', d => d.y1 - d.y0)
    .style('fill', d => getColor(d).area)
    .attr('stroke', d => getColor(d).border)
    .attr('stroke-width', '1px')
    .attr('rx', d => d.parent ? 2 : 4)
    .attr('ry', d => d.parent ? 2 : 4)
    .attr('cursor', d => d.children?.length ? 'pointer' : 'initial')
    .on('click', drill)
    .on('mouseover', function(event, d) {
      const [xPosition, yPosition] = d3.pointer(event, d3.select(`#${uniqueId} svg`).node());
      const svgTopLeft = d3.select(`#${uniqueId} svg`).node().getBoundingClientRect();
      tooltipData.value = d;
      d3.select(`#${uniqueId} .tooltip`)
        .style('visibility', 'visible')
        .style('left', (svgTopLeft.left + xPosition) + 'px')
        .style('top', (svgTopLeft.top + yPosition - 30) + 'px');
    })
    .on('mouseout', function() {
      d3.select(`#${uniqueId} .tooltip`).style('visibility', 'hidden');
      tooltipData.value = null;
    });

  const itFits = (d, xlimit = 30, ylimit = 20) => (d.x1 - d.x0 > xlimit && d.y1 - d.y0 > ylimit);

  // Filter children and apply texts only for big enough areas
  group.filter(d => !d.children?.length && itFits(d, 10, 10)) 
    .append('text')
    .attr('text-anchor', 'middle') // Center the text horizontally
    .attr('dominant-baseline', 'central') // Center the text vertically
    .attr('fill', d => getColor(d).text)
    .style('font-size', d => `${Math.max(8, 14 - 1.5 * d.depth)}px`) // Adjust font size dynamically
    .text(d => d.data.name) 
    .each(function(d) {
      const self = d3.select(this);
      const rectWidth = d.x1 - d.x0;
      const rectHeight = d.y1 - d.y0;
      let textLength = self.node().getComputedTextLength();
      let text = self.text();
      while (textLength > rectWidth - 4 && text.length > 0) { // Loop to decrease text length until it fits
        text = text.slice(0, -1);
        self.text(`${text}...`); // Update text with ellipsis
        textLength = self.node().getComputedTextLength();
      }
      // Position the text in the center of the rectangle
      const xPosition = rectWidth / 2;
      const yPosition = rectHeight / 2;
      self.attr('x', xPosition)
        .attr('y', yPosition - (props.showValues && itFits(d) ? 6 : 0));
    });
  
  // Show values for tall and wide enough areas
  if (props.showValues) {
    group.filter(d => !d.children?.length && itFits(d)) 
      .append('text')
      .attr('text-anchor', 'middle') // Center the text horizontally
      .attr('dominant-baseline', 'central') // Center the text vertically
      .attr('fill', d => getColor(d).text)
      .style('font-size', d => `${Math.max(8, 14 - 1.5 * d.depth)}px`) // Adjust font size dynamically
      .text(d => `${formatValue(d.value)}`) 
      .attr('x', d => (d.x1 - d.x0) / 2)
      .attr('y', d => (d.y1 - d.y0) / 2 + 6);
  }
    
  // Text for headers
  group.filter(d => d.children?.length && itFits(d, 30, 10))
    .append('text')
    .attr('dx', 4)
    .attr('dy', 16)
    .attr('cursor', d => d.children?.length ? 'pointer' : 'initial')
    .on('click', drill)
    .attr('fill', d => getColor(d).text)
    .text(d => `${d.data.name} ${props.showValues ? ` (${formatValue(d.value)})` : ''}`) 
    .each(function(d) {
      const self = d3.select(this);
      const rectWidth = d.x1 - d.x0;
      let textLength = self.node().getComputedTextLength();
      let text = self.text();
      while (textLength > rectWidth && text.length > 0) { // Loop to decrease text length until it fits
        text = text.slice(0, -1);
        self.text(`${text}...`); // Update text with ellipsis
        textLength = self.node().getComputedTextLength();
      }
    })
    .style('font-size', d => `${14 - 1.5 * d.depth}px`)
    .style('font-weight', d => (Math.max(100, 600 - 100 * d.depth)).toString());
}

const drill = (event, d) => {
  if (d.depth === 0 && drillStack.value.length > 1) { // drill root clicked
    drillStack.value.pop();
  }
  else if (d.children?.length && d.depth > 0) {
    drillStack.value.push(d.data);
  }
  else return;
  handleChartResize();
};

// Main function to setup the treemap
function generateTreemap(data) {
  const hierarchy = d3.hierarchy(data)
    .sum(d => d.value)
    .sort((a, b) => b.value - a.value);

  d3.select(`#${uniqueId} .chart`).selectAll('*').remove();
  const svg = d3.select(`#${uniqueId} .chart`)
    .attr('width', width())
    .attr('height', props.svgHeight);

  d3.treemap()
    .size([width(), props.svgHeight])
    .padding(d => d.parent ? 2 : 4)
    .paddingTop(d => d.children?.length ? 24 : 0) // Padding for group headers
    .paddingInner(d => Math.max(1, 3 - (drillStack.value?.length || 0) + props.depth - d.depth)) // Padding between tiles
    .round(true)(hierarchy);

  renderTreemap(svg, hierarchy);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
  
.tooltip {
  position: fixed;
  pointer-events: none;
  max-width: 180px;
  min-width: 100px;
  opacity: 0.8;
  transition: opacity 0.3s; 
  padding: var(--spacing-half) var(--spacing-1);
  border-radius: var(--spacing-2);
  color: var(--colour-utility-white);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-small);
  letter-spacing: 0;
  line-height: var(--spacing-2);
  background-color: var(--colour-utility-black);
  font-weight: var(--font-weight-semibold);
  pointer-events: none;
  visibility: hidden;
  z-index:$popupZIndex; 
}
</style>