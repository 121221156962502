<template>
  <div class="rule-tree">
    <div
      v-for="(branch, i) in branches"
      :key="branch.objectLabel"
    >
      <div class="branch-heading">
        <div
          v-if="i === 0"
          class="hand"
        />
        <h4>{{ branch.label }}</h4>
        <div class="branch" />
        <div class="hand" />
      </div>
      <MenuFilter
        :items="branch.options"
        :selected-value="selectedValues[branch.objectLabel]"
        menu-position="right"
        @on-change="selectOption($event, branch)"
      />
    </div>
    <div>
      <div class="branch-heading">
        <h4>{{ t('Field value') }}</h4>
      </div>
      <CustomInput
        :value="fieldValue"
        :required="true"
        :error="null"
        error-message="hello"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script setup>
// RuleSelectionTree
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { CustomInput, MenuFilter } from '@sales-i/dsv3';
import { t } from '@sales-i/utils';
import { GET_FIELDS } from '@/shared/store/actionType';
import { convertToUserFriendlyStr } from '@/shared/utils/strings';

const store = useStore();

const props = defineProps({
  rule: {
    type: [Object],
    default: null
  }
});

const emit = defineEmits(['isValid']);

const fields = computed(() => store.state.admin.users.fields.data);
const getFields = (params) => store.dispatch(`admin/users/${GET_FIELDS}`, params);

// translations ignored here as likely to change
const branches = ref([
  {
    label: t('Field type'),
    objectLabel: 'type',
    options: [{
      text: 'Analysis field',
      value: 'analysis'
    }, {
      text: 'CRM field',
      value: 'crm'
    }],
  },
  {
    label: t('Field category'),
    objectLabel: 'entity',
    options: [],
  },
  {
    label: t('Field name'),
    objectLabel: 'id',
    options: [],
  },
  {
    label: t('Operator'),
    objectLabel: 'operator',
    options: [],
  },
]);

const selectedValues = ref({ 'type': 'analysis' });
const fieldValue = ref('');

const selectOption = async (option, branch) => {
  if (branch.objectLabel === 'type') {
    const options = fields.value[option.value].map(opt => ({
      text: convertToUserFriendlyStr(opt.name),
      value: opt.name,
    }));
    if (selectedValues.value[branch.objectLabel] !== option.value) {
      selectedValues.value = {};
    }
    branches.value[1].options = options;
  }
  if (branch.objectLabel === 'entity') {
    await getFields({
      type: selectedValues.value.type,
      entity: option.value,
    });
    const options = fields.value[option.value].map(opt => ({
      text: convertToUserFriendlyStr(opt.name),
      value: opt.id,
      type: opt.type
    }));
    branches.value[2].options = options;
  }
  if (branch.objectLabel === 'id') {
    selectedValues.value['name'] = option.text;  
  }
  selectedValues.value[branch.objectLabel] = option.value;
  checkValidity();
};

const setUpDefaultOptions = async () => {
  if (fields.value.operators) {
    const analysisOptions = fields.value.analysis.map(opt => ({
      text: convertToUserFriendlyStr(opt.name),
      value: opt.name,
    }));
    branches.value[1].options = analysisOptions;
    const optionText = {
      equals: 'Equals',
      not_equals: 'Does not equal'
    };
    const operatorOptions = fields.value.operators.map(opt => ({
      text: optionText[opt],
      value: opt,
    }));
    branches.value[3].options = operatorOptions;
    if (props.rule) {
      await getFields({
        type: props.rule.type,
        entity: props.rule.entity,
      });
      const options = fields.value[props.rule.entity].map(opt => ({
        text: convertToUserFriendlyStr(opt.name),
        value: opt.id,
        type: opt.type
      }));
      branches.value[2].options = options;
      selectedValues.value.type = props.rule.type;
      selectedValues.value.name = props.rule.name;
      selectedValues.value.entity = props.rule.entity;
      selectedValues.value.id = props.rule.id;
      selectedValues.value.operator = props.rule.operator;
      handleInput(props.rule.value);
    }
  } else {
    setTimeout(setUpDefaultOptions, 100);
  }
};

const checkValidity = () => {
  let isValid = true;
  if (!selectedValues.value.type) {
    isValid = false;
  }
  if (!selectedValues.value.entity) {
    isValid = false;
  }
  if (!selectedValues.value.id) {
    isValid = false;
  }
  if (!selectedValues.value.operator) {
    isValid = false;
  }
  if (!fieldValue.value) {
    isValid = false;
  }
  emit('isValid', {
    valid: isValid,
    values: selectedValues.value
  });
};

const handleInput = (input) => {
  fieldValue.value = input;
  selectedValues.value.value = input;
  checkValidity();
};

onMounted(async () => {
  setUpDefaultOptions();
});
</script>

<style lang="scss" scoped>
.rule-tree {
  display: flex;
  > div {
    flex: 1;
  }
}
.branch-heading {
  display: flex;
  align-items: center;
  .branch {
    height: 1px;
    border: 1px solid black;
    flex: 1;
  }
  .hand {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: black;
    margin-right: var(--spacing-1);
  }
}
</style>
