<template>
  <div
    :class="[getMainClass(valueObject.variance), { small }]"
    class="value-set"
  >
    <span
      v-for="(value, i) in mobileValues"
      :key="i"
      class="mobile-name"
      :class="[`mobile-name-${i}`, { 'mobile-name-only-one': mobileValues.length === 1 }]"
    >
      <template v-if="i === 0">
        {{ orderNumber + 1 }}.
      </template>
      
      <template v-if="value.entity_id">
        <a
          :href="getCustomerLink(value.entity_id)"
          target="_blank"
        >
          {{ value.dimensions[0] }}
        </a>
      </template>
      <template v-else>
        {{ value.dimensions[0] }}
      </template>
    </span>

    <span
      :class="{ negative: parseInt(valueObject.values[0], 10) < 0 }"
      class="single-value-1"
    >
      {{ getCompleteValue(valueObject.values[0]) }}
    </span>
    <CustomChip
      :small="small"
      class="value-chip"
      :title="valueObject.values[1]"
    >
      <IconBase
        v-if="valueObject.variance != 0"
        icon-name="arrow-up"
        icon-color="var(--colour-utility-white)"
        :height="iconSize"
        :width="iconSize"
        class="chip-arrow-icon"
      />
      <span v-if="!isPercentValueActive">{{ getCompleteValue(valueObject.variance) }}</span>
      <span v-else>{{ abbrFloat(valueObject.variance_percent, 2) }}%</span>      
    </CustomChip>
    <span
      :class="{ negative: parseInt(valueObject.values[1], 10) < 0 }"
      class="single-value-2"
    >
      {{ getCompleteValue(valueObject.values[1]) }}
    </span>
  </div>
</template>

<script>
import { CustomChip, IconBase } from '@sales-i/dsv3';
import { baseUrl as crmBaseUrl, customersArea } from '@/crm/router/urlBits';
import {
  REPORTS_KPI_COUNT,
  REPORTS_KPI_GROSS_PROFIT,
  REPORTS_KPI_INVOICES,
  REPORTS_KPI_QUANTITY,
  REPORTS_KPI_INVOICE_ITEMS,
} from '@/intelligence/store/data/kpis';
import { mapState } from 'vuex';
import { abbr, currency } from '@sales-i/utils';

export default {
  name: 'ValueSet',
  components: {
    CustomChip,
    IconBase,
  },
  props: {
    valueObject: {
      type: [Object, String],
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    mobileValues: {
      type: Array,
      default: () => [],
    },
    kpiName: {
      type: String,
      default: '',
    },
    orderNumber: {
      type: String || Number,
      default: '',
    },
    isPercentValueActive: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      hasPercent: false,
    };
  },
  computed: {
    ...mapState({
      cs: state => state.userDetails.cs,
    }),
    iconSize() {
      return this.small ? 14 : 16;
    },
  },
  methods: {
    abbrFloat: abbr.float,
    getMainClass(value) {
      return [value > 0 ? 'green' : value < 0 ? 'red' : ''];
    },
    getCustomerLink(id) {
      return `${crmBaseUrl}/${customersArea}/${id}`;
    },
    getCompleteValue(value) {
      switch (this.kpiName) {
      case REPORTS_KPI_GROSS_PROFIT:
        return `${currency.number(Number(value))}%`;
      case REPORTS_KPI_QUANTITY:
      case REPORTS_KPI_COUNT:
      case REPORTS_KPI_INVOICES:
      case REPORTS_KPI_INVOICE_ITEMS:
        return currency.number(Number(value));
      default:
        return currency.price(value, this.cs);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.value-set {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;

  > span {
    align-self: end;
    position: relative;
    bottom: 6px;
  }

  .single-value-1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .single-value-2 {
    grid-area: 1 / 2 / 2 / 3;
    border-left: 1px solid var(--colour-panel-g-16);
  }

  .value-chip {
    position: absolute;
    top: 5px;
    left: 50%;
    height: 36px;
    transform: translateX(-50%);
    font-size: var(--font-size-body);
    box-shadow: 0 0 var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
    background: none;
    white-space: nowrap;

    .chip-arrow-icon {
      background: var(--colour-data-de-york-label);
      border-radius: 50%;
      margin-right: var(--spacing-half);
    }
  }

  &.green .value-chip {
    color: var(--colour-data-de-york-label);
  }

  &.small {
    > span {
      line-height: 24px;
      bottom: 3px;
    }

    > .value-chip {
      top: var(--spacing-1);
      height: 20px;
      font-size: var(--font-size-small);

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        top: 46px;
      }
    }
  }

  &.red {
    .value-chip {
      top: unset;
      bottom: var(--spacing-1);
      color: var(--colour-data-mandy-label);
    }

    > span {
      align-self: start;
      bottom: unset;
      top: 3px;
    }

    .chip-arrow-icon {
      transform: rotate(180deg);
      background-color: var(--colour-data-mandy-label);
    }
  }

  &.small.red {
    .value-chip {
      top: unset;
      bottom: var(--spacing-1);
    }

    > span:not(.mobile-name) {
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        top: 42px;
      }
    }
  }

  > .mobile-name {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    line-height: 16px;
    width: 100%;
    top: 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 var(--spacing-2);

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      display: block;
    }

    &.mobile-name-1 {
      top: 22px;
    }
    &.mobile-name-0 {
      top: 6px;
      font-weight: var(--font-weight-semibold);
    }

    &.mobile-name-only-one {
      top: 14px;
    }
  }
}

.negative {
  color: var(--colour-data-mandy-label);
}
</style>
