export default function getEntityStr(entity) {
  let entityStr = '';
  switch (entity) {
  case 'customers':
    entityStr = 'customer';
    break;
  case 'prospects':
    entityStr = 'prospect';
    break;
  case 'contacts':
    entityStr = 'contact';
    break;
  case 'opportunities':
    entityStr = 'opportunity';
    break;
  case 'interactions':
    entityStr = 'interaction';
    break;
  case 'files':
    entityStr = 'file';
    break;
  }
  return entityStr;
}
