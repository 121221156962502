import { getAllMutations, getAllActions } from './getAll';
import { deleteByIdMutations, deleteByIdActions } from './deleteById';

const state = () => ({
  all: {
    data: [],
    fields: [],
    loaded: false,
    loading: false,
    error: undefined,
  },
  selected: {
    data: {},
    loaded: false,
    loading: false,
    updated: false,
    error: undefined,
  },
  sorting: [
    {
      text: 'Customer/Prospect ASC - DES',
      value: 'entity_name:asc',
    },
    {
      text: 'Customer/Prospect DES - ASC',
      value: 'entity_name:desc',
    },
    {
      text: 'Value ASC - DES',
      value: 'value:asc',
    },
    {
      text: 'Value DES - ASC',
      value: 'value:desc',
    },
    {
      text: 'Decision Date ASC - DES',
      value: 'decision_date:asc',
    },
    {
      text: 'Decision Date DES - ASC',
      value: 'decision_date:desc',
    },
    {
      text: '% Probability ASC - DES',
      value: 'probability:asc',
    },
    {
      text: '% Probability DES - ASC',
      value: 'probability:desc',
    },
  ],
});

// getters
const getters = {};

// mutations
const mutations = {
  ...getAllMutations,
  ...deleteByIdMutations,
};

// actions
const actions = {
  ...getAllActions,
  ...deleteByIdActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
