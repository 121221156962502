<template>
  <ColumnsWithActionsLayout
    :loading="loading"
    :buttons="buttons"
  >
    <OpportunityStagesMini
      v-show="breakpoints.smAndDown.includes(mq.current)"
      :opportunity="opportunity"
      :stage-index="stageIndex"
      :stage-text="stageText"
      class="stages-mini"
    />

    <div
      v-if="loading === false"
      class="opportunity"
    >
      <OpportunityStages
        :stage-index="stageIndex"
        :opportunity="opportunity" 
      />

      <OpportunityTimeline
        :opportunity="opportunity"
        :loading="historyLoading"
        :history="history"
        :stage-index="stageIndex"
        :stage-text="stageText"
      />

      <div class="contact-wrap">
        <Contact
          v-if="opportunity.contact_id"
          :contact-id="opportunity.contact_id"
          :entity-type="opportunity.entity_type"
          :entity-name="opportunity.entity_name"
          :entity-id="opportunity.entity_id"
        />
        <DefaultContact
          v-else
          :entity-type="opportunity.entity_type"
          :entity-name="opportunity.entity_name"
          :entity-id="opportunity.entity_id"
          :add-route-override="addUpdateRouteOverride"
          :update-route-override="addUpdateRouteOverride"
        />
        <AssigneesList
          class="my-2"
          :items="allocatedUsers"
        />
      </div>
    </div>
  </ColumnsWithActionsLayout>
</template>

<script setup>
// OpportunityViewDetails
import { t } from '@sales-i/utils';
import { onMounted, computed } from 'vue';
import OpportunityTimeline from '@/crm/components/Opportunity/OpportunityTimeline.vue';
import OpportunityStages from '@/crm/components/Opportunity/OpportunityStages.vue';
import OpportunityStagesMini from '@/crm/components/Opportunity/OpportunityStagesMini.vue';
import ColumnsWithActionsLayout from '@/shared/components/Layout/ColumnsWithActionsLayout.vue';
import AssigneesList from '@/crm/components/Common/AssigneesList.vue';
import Contact from '@/crm/components/Contacts/Contact.vue';
import DefaultContact from '@/crm/components/Contacts/DefaultContact.vue';
import { baseUrl, opportunitiesArea } from '@/crm/router/urlBits.js';
import { useStore } from 'vuex';
import { useUsers } from '@/shared/composables/useUsers';
import { useRoute, } from 'vue-router';
import { useSearchBar } from '@/shared/composables/useSearchBar';
import usePermissions from '@/shared/composables/usePermissions';
import breakpoints from '@/shared/utils/breakpoints';
import { GET_HISTORY_BY_ID } from '@/crm/store/actionType';
import { SET_ROUTE } from '@/shared/store/actionType';
import { useMq } from 'vue3-mq';
import { opportunities } from '@/shared/store/data/policies';
import { navigateToUrl } from 'single-spa';

const mq = useMq();
const store = useStore();
const vroute = useRoute();
const { getUserById } = useUsers(store, false);
const { getPermissions } = usePermissions();
const permissions = getPermissions(opportunities);
const { search } = useSearchBar();

const loading = computed(() => store.state.crm.opportunities.selected.loading);
const history = computed(() => store.state.crm.opportunities.selectedHistory.data);
const historyLoading = computed(() => store.state.crm.opportunities.selectedHistory.loading);

const opportunity = computed(() => store.getters['crm/opportunities/item']);
const stageIndex = computed(() => store.getters['crm/opportunities/stageIndex']);
const stageText = computed(() => store.getters['crm/opportunities/stageText']);

const allocatedUsers = computed(() => (opportunity.value.allocated_users || []).map(u => getUserById(u.allocated_user_id)));

const addUpdateRouteOverride = computed(() => ({
  success: `${baseUrl}/${opportunitiesArea}/${vroute.params.id}`,
  cancel: vroute.fullPath,
}));

const buttons = computed(() => [
  {
    visible: !!permissions.create,
    label: t('Add opportunity'),
    small: true,
    purpose: 'reversed',
    clickFunc: add,
  },
  {
    visible: !!permissions.read,
    label: t('Search'),
    small: true,
    purpose: 'reversed',
    clickFunc: () =>
      search({
        category: 'opportunity',
        paramsJSON: {},
      }),
  },
]);

onMounted(() => getHistory({ id: vroute.params.id }));

const getHistory = params => store.dispatch(`crm/opportunities/${GET_HISTORY_BY_ID}`, params);
const setRoute = params => store.dispatch(`system/${SET_ROUTE}`, params);

function add() {
  setRoute({ cancel: vroute.fullPath });
  navigateToUrl(`${baseUrl}/${opportunitiesArea}/add`);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.stages-mini {
  position: relative;
  bottom: -0.95rem;
  z-index: 1;
}

.opportunity-container {
  width: 100%;
  padding: 0;
  margin: var(--spacing-4) auto;
  background-color: transparent;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: 90%;
    background-color: var(--colour-panel-base);
  }
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    box-shadow: none;
  }
}

.opportunity {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-3);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    flex-direction: row;
    margin: var(--spacing-2) 0;
  }

  .opportunity-timeline {
    flex: 3;
  }

  .opportunity-stages {
    display: none;
    flex-direction: column;
    justify-content: right;
    flex: 1;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      display: flex;
    }
  }

  .contact-wrap {
    flex: 2;
    padding: 0;
    margin-top: var(--spacing-2);
    margin-bottom: var(--spacing-2);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding-left: var(--spacing-3);
      margin-top: 0;
    }
  }

  .opportunity-audit-trail {
    border-radius: var(--border-radius-1);
    border-width: 0px;
    box-shadow: none;
    background-color: var(--colour-utility-white);
    flex-grow: 1;
    padding: 0 0 var(--spacing-3) 0;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-colour);
    }
  }
}
</style>
