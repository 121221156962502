import {
  GET_REPORT,
  GET_REPORT_LOADING,
  GET_REPORT_ERROR,
  CLEAR_REPORT
} from '@/admin/store/actionType';
import { httpRequest } from '@sales-i/utils';
import querystring from 'querystring';

// mutations
export const getReportMutations = {
  [CLEAR_REPORT]: (state) => {
    state.report.data = [];
    state.report.loaded = false;
    state.report.loading = false;
  },
  [GET_REPORT]: (state, data) => {
    state.report.data = data;
    state.report.loaded = true;
    state.report.loading = false;
  },
  [GET_REPORT_LOADING]: (state) => {
    state.report.data = [];
    state.report.loaded = false;
    state.report.loading = true;
  },
  [GET_REPORT_ERROR]: (state) => {
    state.report.loaded = false;
    state.report.loading = false;
    state.report.errors = [];
  },
};

// actions
export const getReportActions = {
  [GET_REPORT]: async ({ commit, }, parameters) => {
    try {
      commit(GET_REPORT_LOADING);
      const params = querystring.stringify(parameters);
      const data = await httpRequest('get', `tags/report?${params}`, {}, {version: '2',});
      commit(GET_REPORT, data);
      return data;
    } catch (error) {
      console.error(error);
      commit(GET_REPORT_ERROR);
      return false;
    }
  },
  [CLEAR_REPORT]: async ({ commit, }) => {
    commit(CLEAR_REPORT);
  },
};
