<template>
  <CRMCard
    v-bind="cardAttrs"
    :title="title"
    :currency="currency"
    :chips="chips"
    :links="links"
    @open="onOpen"
    @edit="onEdit"
    @delete="onDelete"
    @add="onAdd"
    @remove="onRemove"
  >
    <template #info>
      <ReplaceSpaceWithNBSPComponent 
        :text="item.next_step"
        class="next-step"
      />
      <div class="decission-date">
        {{
          t(`DD: ${dates.format(item.decision_date)}`, 'dd_date', {
            interpolations: {
              date: dates.format(item.decision_date),
            },
          })
        }}
      </div>
    </template>
    <template
      v-if="props.showTags"
      #tags
    >
      <Tag
        :id="props.item.id"
        entity="opportunity"
      />
    </template>
  </CRMCard>
</template>

<script setup>
// OpportunityCard
import { CRMCard } from '@sales-i/dsv3';
import Tag from '@/shared/components/Tags/Tag.vue';
import ReplaceSpaceWithNBSPComponent from '@/shared/components/ReplaceSpaceWithNBSP.vue';
import { useOpportunity } from '@/crm/composables/useOpportunity';
import { useUsers } from '@/shared/composables/useUsers';
import { computed } from 'vue';
import useCurrency from '@/shared/composables/useCurrency';

import { useStore } from 'vuex';
import { dates, t } from '@sales-i/utils';

const store = useStore();

const emit = defineEmits(['click', 'open', 'edit', 'add', 'remove', 'delete', 'opportunityDeleted']);

const { getUserById } = useUsers(store);
const { showDeleteOpportunityModal, openOpportunityDetails, editOpportunity } = useOpportunity({ store, emit });

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  linksEnabled: {
    type: Array,
    default: () => ['open', 'edit', 'delete', 'add', 'remove'],
    validator: value => value.every(link => ['open', 'edit', 'delete', 'add', 'remove'].includes(link)),
  },
  customHandlers: {
    type: Array,
    default: () => [],
    validator: value => value.every(link => ['open', 'edit', 'delete', 'add', 'remove'].includes(link)),
  },
  openInNewTab: {
    type: Boolean,
    default: false,
  },
  showTags: {
    type: Boolean,
    default: true,
  },
  showChips: {
    type: Boolean,
    default: true,
  },
  clickable: {
    type: Boolean,
    default: true,
  },
});

const title = computed(() => {
  if (!props.item?.allocated_users?.length) return t('Not allocated');
  const firstUser = getUserById(props.item.allocated_users[0].allocated_user_id);
  return firstUser.display_name || t('Unknown user') + 
    (props.item.allocated_users.length === 1 ? '' : ` (+${props.item.allocated_users.length - 1})`);
});

const { cs, currency } = useCurrency();

const cardAttrs = props.clickable ? { onClick } : {};

const chips = computed(() => {
  if (!props.showChips) return [];
  return [
    {
      value: props.item.value,
      prefix: cs,
      abbr: true,
      green: 150000,
      amber: 75000,
    },
    {
      value: props.item.probability,
      suffix: '%',
      green: 67,
      amber: 33,
    },
  ];
});

const links = computed(() => {
  let deleteLink = {
    text: t('Delete'),
    emit: 'delete',
    id: props.item.id,
  };
  let editLink = {
    text: t('Edit'),
    emit: 'edit',
    id: props.item.id,
  };
  let openLink = {
    text: t('Open'),
    emit: 'open',
    id: props.item.id,
  };
  let addLink = {
    text: t('Add'),
    emit: 'add',
    item: props.item,
  };
  let removeLink = {
    text: t('Remove'),
    emit: 'remove',
    item: props.item,
  };
  let links = [];
  if (props.linksEnabled.includes('delete') && props.item.status === 'open') links.push(deleteLink);
  if (props.linksEnabled.includes('edit') && props.item.status === 'open') links.push(editLink);
  if (props.linksEnabled.includes('open')) links.push(openLink);
  if (props.linksEnabled.includes('add')) links.push(addLink);
  if (props.linksEnabled.includes('remove')) links.push(removeLink);
  return links;
});

function onClick() {
  if (!props.clickable) return;
  if (props.customHandlers.includes('click')) {
    emit('click', props.item);
  } else {
    onOpen();
  }
}

function onOpen() {
  if (props.customHandlers.includes('open')) emit('open', props.item);
  else openOpportunityDetails(props.item, props.openInNewTab);
}
function onEdit() {
  if (props.customHandlers.includes('edit')) emit('edit', props.item);
  else editOpportunity(props.item);
}
function onDelete() {
  if (props.customHandlers.includes('delete')) emit('delete', props.item);
  else showDeleteOpportunityModal(props.item);
}
function onAdd() {
  emit('add', props.item);
}
function onRemove() {
  emit('remove', props.item);
}
</script>

<style lang="scss" scoped>

.next-step {
  color: var(--colour-utility-black);
}

.decission-date {
  margin-top: var(--spacing-1);
}

:deep(.title-chip-container) {
  margin-bottom: var(--spacing-1);
}
</style>