import store from '@/shared/store';
import { SET_ITEMS } from '@/shared/store/actionType';

let runBack = true;

const reject = (rejectedCallback, storeDispatch) => {
  // needed to prevent duplicate calls where route watchers cause issues etc.
  if (runBack) {
    rejectedCallback();
    storeDispatch();
    runBack = false;
    setTimeout(() => runBack = true, 500);
  }
};

const defaultStoreCall = () => {
  store.dispatch(`modalQueue/${SET_ITEMS}`, {
    title: 'Permission Denied',
    message: 'You don\'t have permission to view the requested resource and have been returned to your previous page',
  });
};

const defaultPermissionSet = {
  crud: ['read']
};

export default function runIfPermittedOrReject(
  permissionsGranted,
  permissionSet = {},
  grantedCallback = () => {},
  rejectedCallback = () => history.back(),
  storeDispatch = defaultStoreCall,
) {
  let shouldReject = false;
  permissionSet = {
    ...defaultPermissionSet,
    ...permissionSet,
  };
  let { name, crud } = permissionSet;
  if (!crud || crud.length < 1) shouldReject = true;
  crud.forEach(permission => {
    if (permissionsGranted[name] && !permissionsGranted[name][permission]) {
      shouldReject = true;
    }
  });
  if (shouldReject) {
    reject(rejectedCallback, storeDispatch);
  } else {
    grantedCallback();
  }
}