/* eslint-disable no-unused-vars */
import {
  GET_SEARCH_BAR_RESULTS,
  SET_SEARCH_BAR_RESULTS,
  SET_SEARCH_BAR_RESULTS_LOADING,
  SET_SEARCH_BAR_RESULTS_ERROR,
  CLEAR_SEARCH_BAR_RESULTS,
  UPDATE_RECENTLY_VIEWED
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import querystring from 'querystring';
import mapSearchResults from '@/shared/store/utils/mapSearchResults';

const results = [];



// initial state
const state = () => ({
  data: results,
  metadata: undefined,
  loaded: false,
  loading: false,
  errors: [],
});

// getters
const getters = {
};

// mutations
const mutations = {
  [SET_SEARCH_BAR_RESULTS]: (state, results) => {
    if (results.moreResults) {
      state.data = [
        ...state.data,
        ...results.data,
      ];
    } else {
      state.data = results.data;
    }
    state.metadata = results.metadata;
    state.loaded = true;
    state.loading = false;
  },
  [SET_SEARCH_BAR_RESULTS_LOADING]: (state) => {
    state.loaded = false;
    state.loading = true;
  },
  [SET_SEARCH_BAR_RESULTS_ERROR]: (state) => {
    state.loaded = false;
    state.loading = false;
    state.errors = [];
  },
  [CLEAR_SEARCH_BAR_RESULTS]: (state) => {
    state.data = [];
    state.loaded = false;
    state.loading = false;
    state.errors = [];
  },
};

// actions
const actions = {
  [GET_SEARCH_BAR_RESULTS]: async ({ commit, }, payload) => {
    const { url, type, parameters, moreResults } = payload;
    const { q, } = parameters;
    const params = querystring.stringify(parameters);
    try {
      commit(SET_SEARCH_BAR_RESULTS_LOADING);
      let data = await httpRequest('get', `${url}?${params}`, {}, {version: '2',});
      data = mapSearchResults(data, q, type);
      commit(SET_SEARCH_BAR_RESULTS, { data, moreResults });
    } catch (error) {
      console.error(error);
      commit(SET_SEARCH_BAR_RESULTS_ERROR);
    }
  },
  [CLEAR_SEARCH_BAR_RESULTS]: ({ commit, }) => {
    commit(CLEAR_SEARCH_BAR_RESULTS);
  },
  [UPDATE_RECENTLY_VIEWED]: async ({ commit }, payload) => {
    try {
      await httpRequest('post', 'recently_viewed', payload, {
        version: '2', 
        throwErrors: false,
      });
      commit(UPDATE_RECENTLY_VIEWED);
    } catch (error) {
      console.error(error);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
