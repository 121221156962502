import AlertWizardModal from '@/intelligence/views/Communities/AlertWizard/AlertWizardModal.vue';
import FilterSelection from '@/intelligence/components/AlertBuilder/AlertWizard/FilterSelection.vue';
import SalesData from '@/intelligence/components/AlertBuilder/AlertWizard/SalesData.vue';
import CRMData from '@/intelligence/components/AlertBuilder/AlertWizard/CRMData/CRMDataFilter.vue';
import ProductData from '@/intelligence/components/AlertBuilder/AlertWizard/ProductData.vue';
import AddData from '@/intelligence/components/AlertBuilder/AlertWizard/AddData.vue';
import NameAlert from '@/intelligence/components/AlertBuilder/AlertWizard/NameAlert.vue';

export default [
  {
    path: 'alert-builder',
    component: AlertWizardModal,
    children: [
      {
        path: '',
        component: FilterSelection,
      },
      {
        path: 'sales-data',
        component: SalesData,
      },
      {
        path: 'crm-data',
        component: CRMData,
      },
      {
        path: 'product-data',
        component: ProductData,
      },
      {
        path: 'add-data',
        component: AddData,
      },
      {
        path: 'name-alert',
        component: NameAlert,
      },
    ],
  }
];
