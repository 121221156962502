<template>
  <TableWrapper
    table-height="unset"
    :offset-y="offset"
    enable-lazy-load
    disable-shades
    :no-more-results-available="noMoreResultsAvailable"
    @set-offset="loadMoreData"
  >
    <thead>
      <tr>
        <th
          v-for="(dataType, index) in inputData.visualInput"
          :key="dataType.key"
          :class="{
            'sorting-header': currentSort === dataType.key && index !== 0,
            'contains-chip-header': dataType.includesChip,
            'left-alignment': dataType.key === REPORTS_KPI_NAME,
          }"
          @click="applySort(dataType.key, index)"
        >
          {{ dataType.name }}
          <IconBase
            v-if="currentSort === dataType.key && index !== 0"
            class="sort-icon"
            :icon-name="sortDirection === REPORTS_SORT_DESCENDING_KEY ? 'arrow-down' : 'arrow-up'"
            :height="24"
            :width="24"
          />
          <p
            v-if="dataType.includesChip"
            class="total-chip"
          >
            {{ dataType.prefix ? cPrice(getTotal(dataType.key), cs) : cNumber(getTotal(dataType.key))
            }}{{ dataType.suffix }}
          </p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(unit, i) in reportDataArray"
        :key="i"
      >
        <td
          v-for="(dataType, index) in inputData.visualInput"
          :key="dataType.key"
          :class="{
            'first-column': index === 0,
            negative: unit[dataType.key] < 0,
            'left-alignment': dataType.key === REPORTS_KPI_NAME,
            centered: dataType.key === REPORTS_KPI_CODE,
          }"
          :title="unit[dataType.key]"
        >
          <EntityDisplay 
            v-if="index === 0"
            :entity-id="unit.id"
            :entity-name="unit.customer || unit.name"
            :entity-info="unit.code"
            @cta-click="$emit('ctaClick', unit)"
          />
          <div v-if="index > 0">
            {{ dataType.prefix ? cPrice(unit[dataType.key], cs) : cNumber(unit[dataType.key]) }} {{ dataType.suffix }}
          </div>
        </td>
      </tr>
    </tbody>
  </TableWrapper>
</template>

<script>
// TemplateReportTable
import { IconBase } from '@sales-i/dsv3';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import { mapGetters, mapState, mapActions } from 'vuex';
import { APPLY_OFFSET, RESET_REPORT_PARAMETERS, SET_SORTING_DATA } from '@/intelligence/store/actionType';
import { REPORTS_SORT_ASCENDING_KEY, REPORTS_SORT_DESCENDING_KEY } from '@/intelligence/store/data/apiInput';
import { REPORTS_KPI_NAME, REPORTS_KPI_CODE } from '@/intelligence/store/data/kpis';
import { currency } from '@sales-i/utils';
import EntityDisplay from '@/intelligence/components/Shared/EntityDisplay.vue';
import { navigateToUrl } from 'single-spa';

export default {
  components: {
    IconBase,
    TableWrapper,
    EntityDisplay
  },
  props: {
    currentSort: {
      type: String,
      default: '',
    },
    isSnapshot: {
      type: Boolean,
      default: false,
    },
    reportId: {
      type: Number,
      default: null,
    },
    reportType: {
      type: String,
      default: '',
      required: true,
    },
  },
  emits: ['applyNewSort', 'handleChartSorting', 'loadMoreData', 'ctaClick'],
  data() {
    return {
      REPORTS_KPI_NAME,
      REPORTS_KPI_CODE,
      reportDataArray: [],
      noMoreResultsAvailable: false,
      offset: 0,
      pageSize: 100,
    };
  },
  computed: {
    ...mapState({
      cs: state => state.userDetails.cs,
      limit: state => state.intelligence.shared.requestParameters.limit,
      dataLoading: state => state.intelligence.shared.loading,
      rows: state => state.intelligence.reportLazyLoad.rows,
      sortDirection: state => state.intelligence.shared.requestParameters.currentSortOption,
    }),
    ...mapGetters({
      getReportData: 'intelligence/shared/getReportData',
      getInputData: 'intelligence/shared/getInputData',
    }),
    inputData() {
      return this.getInputData(this.reportType);
    },
    reportData() {
      const reportData = this.getReportData(this.reportId);
      if (!reportData) return [];
      return Array.isArray(reportData) ? reportData : reportData.customers;
    },
  },
  watch: {
    rows(newRows) {
      this.reportDataArray = newRows.map(row => ({ ...row }));
      this.checkForMoreResults();
    },
  },
  mounted() {
    if (Array.isArray(this.reportData)) this.reportDataArray = [...this.reportData];
  },
  methods: {
    cPrice: currency.price,
    cNumber: currency.number,
    ...mapActions({
      applyOffset: `intelligence/shared/${APPLY_OFFSET}`,
      resetReportParameters: `intelligence/reportLazyLoad/${RESET_REPORT_PARAMETERS}`,
      applyNewSortDirection: `intelligence/enquiry/${SET_SORTING_DATA}`,
    }),
    checkForMoreResults() {
      const newData = this.getReportData() || [];
      const newDataSize = newData?.customers?.length || newData?.length || 0;
      if (newDataSize < this.limit) this.noMoreResultsAvailable = true;
    },
    getTotal(key) {
      return (this.reportDataArray || []).reduce((total, curr) => total + curr[key], 0.0);
    },
    applySort(headerKey, index) {
      // check if clicked table header is an active sort
      if (this.currentSort === headerKey && index !== 0) {
        // if yes, then switch sort direction
        this.applyNewSortDirection([
          '',
          this.sortDirection === REPORTS_SORT_DESCENDING_KEY ? REPORTS_SORT_ASCENDING_KEY : REPORTS_SORT_DESCENDING_KEY,
        ]);
      }

      this.resetReportParameters();
      this.applyOffset(0);
      const route = this.$router.resolve({
        query: { ...this.$route.query, sort: headerKey },
      });
      navigateToUrl(route.href);
      this.$emit('applyNewSort', headerKey);
      this.$emit('handleChartSorting');
    },
    loadMoreData() {
      if (!this.dataLoading) {
        this.offset += this.limit;
        this.applyOffset(this.offset);
        this.$emit('loadMoreData');
      }
    }
  },
};
</script>

<style lang="scss">
.template-report .table-wrapper {
  max-height: 70vh;
}
</style>
<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.table-wrapper {
  overflow: auto;
  height: 330px;
  position: relative;
}

table {
  position: relative;
  width: 100%;
}

tbody tr:first-child {
  height: var(--spacing-8);

  th,
  td {
    vertical-align: bottom;
  }
}

tr {
  background: var(--colour-panel-g-0);

  &:nth-child(even) {
    background: var(--colour-panel-g-2);
  }
}

th,
td {
  color: var(--colour-utility-black);
  padding: var(--spacing-2);
  border: none;
}

th {
  font-size: var(--font-size-4);
  background: var(--colour-panel-action);
  color: var(--colour-utility-action);
  position: sticky;
  top: 0;
  text-align: center;
  min-height: 70px;
  vertical-align: middle;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;

  &:first-child {
    cursor: auto;
    text-decoration: none;
    color: var(--colour-utility-black);
    z-index: $reportTableHeaderZIndex;
  }

  &.contains-chip-header {
    padding-bottom: var(--spacing-3);
  }

  &.sorting-header {
    background: var(--colour-panel-g-2);
    color: var(--colour-utility-black);
    font-weight: var(--font-weight-medium);
    text-decoration: none;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: var(--font-size-5);
    padding: var(--spacing-2) var(--spacing-1);
    min-width: 100px;
  }
}

td {
  font-size: var(--font-size-small);
  padding: var(--spacing-1);
  line-height: var(--spacing-2);
  text-align: center;

  &.first-column {
    background: var(--colour-panel-g-2);
    border-right: 1px solid var(--colour-panel-g-8);
    text-align: left;
    padding-left: var(--spacing-1);

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding-left: var(--spacing-1);
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 140px;
    }

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

tr:nth-child(even) td.first-column {
  background: var(--colour-panel-g-4);
}

.left-alignment {
  text-align: left;
  padding-left: var(--spacing-1);
}

td.centered {
  text-align: center;
  padding-left: var(--spacing-1);
}

.sort-icon {
  display: inline-block;
  position: absolute;
}

.total-chip {
  position: absolute;
  width: 120px;
  color: var(--colour-utility-black);
  background: var(--colour-panel-g-0);
  box-shadow: 0 0 var(--border-radius-half) var(--shadow-spread) var(--shadow-colour);
  font-size: var(--font-size-5);
  font-weight: 500;
  letter-spacing: 0;
  line-height: var(--spacing-3);
  padding: var(--spacing-1);
  border-radius: var(--spacing-6);
  margin: auto;
  margin-top: var(--spacing-1);
  right: 50%;
  transform: translateX(50%);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: auto;
    min-width: 80px;
  }
}

.negative {
  color: var(--colour-data-mandy-label);
}
</style>
