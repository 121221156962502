import CustomersProspectsSettingsView from '@/admin/views/CRMSetup/CustomersProspectsSettingsView.vue';
import Sections from '@/admin/views/CRMSetup/Sections.vue';
import Settings from '@/admin/views/CRMSetup/Settings.vue';
import { customersPropspectsSection as section } from '@/admin/router/urlBits';

export default {
  path: `:entity(${section})`,
  component: CustomersProspectsSettingsView,
  children: [
    {
      path: '',
      component: Sections,
    },
    {
      path: 'custom-sections',
      component: Sections,
    },
    {
      path: 'settings',
      component: Settings,
    },
  ],
};
