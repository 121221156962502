import {
  GET_ALL,
  GET_ALL_LOADING,
  GET_ALL_ERROR,
  CLEAR_ALL,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import querystring from 'querystring';

// getters
export const getRolesGetters = {
};

// mutations
export const getRolesMutations = {
  [GET_ALL]: (state, data) => {
    state.roles.data = data;
    state.roles.loaded = true;
    state.roles.loading = false;
  },
  [GET_ALL_LOADING]: (state) => {
    state.roles.loaded = false;
    state.roles.loading = true;
  },
  [GET_ALL_ERROR]: (state) => {
    state.roles.loaded = false;
    state.roles.loading = false;
  },
  [CLEAR_ALL]: (state) => {
    state.roles.all = [];
    state.roles.loaded = false;
    state.roles.loading = false;
  },
};

// actions
export const getRolesActions = {
  [GET_ALL]: async ({ commit, }, parameters = {}) => {
    const params = querystring.stringify(parameters);
    try {
      commit(GET_ALL_LOADING);
      const data = await httpRequest('get', `tenants/current/roles?${params}`, {}, { area: 'iam', version: '2', }) || [];
      commit(GET_ALL, data);
    } catch (error) {
      console.error(error);
      commit(GET_ALL_ERROR);
    }
  },
  [CLEAR_ALL]: ({ commit, }) => {
    commit(CLEAR_ALL);
  },
};
