<template>
  <div
    ref="container"
    class="line-bar-chart-container"
  >
    <svg class="chart" />
  </div>
</template>

<script>
// LineBarChart
import * as d3 from 'd3';
import chartsMixin from '@/shared/mixins/chartsMixin';
import { mapState } from 'vuex';

export default {
  mixins: [chartsMixin],
  computed: {
    ...mapState({
      customer: state => state.crm.customers.selected.data,
    }),
    chartData() {
      return this.tableData.map(row => {
        return {
          line: row.average_customer_spend,
          bar: row.purchase_share,
          average: row.average_industry_spend,
          name: row.description,
        };
      });
    },
    isIndustryAvailable() {
      return this.customer.industry_type;
    },
  },
  methods: {
    getChartVersion() {
      return this.$refs.container?.clientWidth <= 680 ? 'mobile' : 'desktop';
    },
    getMaximumValue() {
      return Math.max(...this.chartData.map(el => el.line), ...this.chartData.map(el => el.bar), ...this.chartData.map(el => el.average));
    },
    removeChart() {
      d3.select('.line-bar-chart-container svg.chart g').remove();
    },
    processChartParams(type) {
      // dimensions and margins of the chart (mobile and desktop)
      this.currentParams =
        type === 'mobile'
          ? {
            svgWidth: this.$refs.container?.clientWidth,
            svgHeight: this.isIndustryAvailable ? 500 : 450,
            margin: {
              top: 10,
              right: 0,
              bottom: this.isIndustryAvailable ? 290 : 240,
              left: 35,
            },
          }
          : type === 'desktop'
            ? {
              svgWidth: this.$refs.container?.clientWidth,
              svgHeight: this.isIndustryAvailable ? 550 : 510,
              margin: {
                top: 10,
                right: 0,
                bottom: this.isIndustryAvailable ? 260 : 220,
                left: 150,
              },
            }
            : '';
    },
    generateChart() {
      this.processChartParams(this.getChartVersion());

      // set parameters to container svg
      const svg = d3
        .select('.line-bar-chart-container svg.chart')
        .attr('width', this.currentParams.svgWidth)
        .attr('height', this.currentParams.svgHeight)
        .append('g')
        .attr('transform', `translate(${this.currentParams.margin.left},${this.currentParams.margin.top})`);

      //
      // DATA
      //
      const isMobile = this.getChartVersion() === 'mobile',
        isDesktop = this.getChartVersion() === 'desktop',
        bottomOffsetMonths = isMobile ? (this.isIndustryAvailable ? 120 : 110) : 95,
        // standardOffset is basically a break between Y axis and first value
        standardOffset = 16,
        // xValuePosition - it's half the length between ticks in X axis, minus standard offset
        xValuePosition = Math.abs(this.chartWidth / this.chartData.length / 2 - standardOffset),
        bgLinesColor = '--colour-panel-g-8',
        elementsData = [
          {
            key: 'bar',
            color: '--colour-brand-viking',
            axisPosition: 20,
            legendPosition: 33,
            legendPositionMobileX: 10,
            legendPositionMobileY: 0,
            title: 'This Customer',
          },
          {
            key: 'line',
            color: '--colour-data-puerto-rico-label',
            axisPosition: 60,
            legendPosition: 73,
            legendPositionMobileX: -110,
            legendPositionMobileY: 0,
            title: 'Average',
          },
          ...(this.isIndustryAvailable
            ? [
              {
                key: 'average',
                color: '--colour-brand-deluge',
                axisPosition: 100,
                legendPosition: 113,
                legendPositionMobileX: -110,
                legendPositionMobileY: 30,
                title: this.customer.industry_type,
              },
            ]
            : []),
        ];

      //
      // UTILS
      //
      const vue = this;
      function setChipWidthPosition() {
        const textLength = d3.select(this.parentNode).select('text').node().getComputedTextLength() + 24;
        d3.select(this)
          .attr('width', textLength)
          .attr('transform', `translate(-${textLength / 2},1)`);
      }
      function adjustLeftMargin() {
        if (this.getComputedTextLength() + 40 > vue.currentParams.margin.left) {
          vue.currentParams.margin.left = this.getComputedTextLength() + 40;
          d3.select('.line-bar-chart-container svg.chart g').attr(
            'transform',
            `translate(${vue.currentParams.margin.left},${vue.currentParams.margin.top})`
          );
        }
      }

      //
      //  DRAWING
      //

      // X axis (with product group names)
      const x = d3
        .scaleBand()
        .domain(this.chartData.map((d, i) => i))
        .range([0, this.chartWidth]);
      svg
        .append('g')
        .attr('class', 'chart-axis chart-axis-x')
        .attr('transform', `translate(0, ${this.chartHeight})`)
        .call(d3.axisBottom(x))
        .selectAll('text')
        .data(this.chartData)
        .text(d => (d.name.length <= 10 ? d.name : `${d.name.substring(0, 10)}...`))
        .attr(
          'transform',
          `translate(-${xValuePosition + standardOffset},${this.currentParams.margin.bottom - bottomOffsetMonths - standardOffset})rotate(-90)`
        )
        .attr('dominant-baseline', 'middle')
        .attr('class', 'chart-label-x')
        .style('text-anchor', 'end');

      // Y axis
      const y = d3
        .scaleLinear()
        .domain([0, 1.1 * this.getMaximumValue()])
        .range([this.chartHeight, 0]);
      const axisY = d3.axisLeft(y);
      svg.append('g').attr('class', 'chart-axis chart-axis-y').call(axisY).selectAll('text').attr('class', 'chart-label-y');

      // horizontal lines
      svg
        .selectAll('g.chart-axis-y g.tick line')
        .attr('x1', x(0))
        .attr('x2', x(this.chartData.length - 1) + 2 * standardOffset)
        .style('stroke-width', 1)
        .style('stroke', `var(${bgLinesColor})`)
        .style('fill', 'none');

      // vertical lines
      svg
        .selectAll('.chart-axis-x g.tick')
        .append('line')
        .attr('x1', -xValuePosition)
        .attr('y1', 0)
        .attr('x2', -xValuePosition)
        .attr('y2', this.currentParams.margin.bottom - bottomOffsetMonths - 25)
        .attr('class', 'vertical-line')
        .style('stroke-width', 1)
        .style('stroke', `var(${bgLinesColor})`)
        .style('fill', 'none');

      elementsData.forEach(element => {
        let axisTranslate = `translate(-${xValuePosition}, ${this.chartHeight + element.axisPosition})`,
          axisTextTranslate = 'translate(0,0)',
          axisTextAnchor = 'middle',
          axisTextDominant = '';

        if (isMobile) {
          axisTranslate = `translate(-${xValuePosition + standardOffset}, ${this.chartHeight + element.axisPosition + 30})`;
          axisTextTranslate = 'translate(0,0)rotate(-90)';
          axisTextAnchor = 'start';
          axisTextDominant = 'middle';
        }

        // legend
        if (isDesktop) {
          // line between chips
          svg
            .append('line')
            .attr('x1', -20)
            .attr('y1', this.chartHeight + element.legendPosition)
            .attr('x2', x(this.chartData.length - 1) + 24)
            .attr('y2', this.chartHeight + element.legendPosition)
            .attr('stroke', `var(${element.color})`)
            .attr('stroke-width', 1)
            .style('fill', 'none');
          // legend text
          svg
            .append('text')
            .attr('x', -32)
            .attr('y', this.chartHeight + element.legendPosition)
            .attr('class', 'legend-text')
            .text(element.title)
            .attr('alignment-baseline', 'middle')
            .attr('text-anchor', 'end')
            .each(adjustLeftMargin);

          // dot
          svg
            .append('circle')
            .attr('cx', -20)
            .attr('cy', this.chartHeight + element.legendPosition)
            .attr('r', 5)
            .attr('stroke', `var(${element.color})`)
            .attr('fill', `var(${element.color})`);
        } else if (isMobile) {
          const xLegendPosition = this.chartWidth / 2 + element.legendPositionMobileX,
            xLegendOffset = 35,
            yLegendOffset = 20 + element.legendPositionMobileY;
          // small lines near values
          svg
            .append('line')
            .attr('x1', 0)
            .attr('y1', this.chartHeight + element.axisPosition + 5)
            .attr('x2', 0)
            .attr('y2', this.chartHeight + element.axisPosition + 30)
            .attr('stroke', `var(${element.color})`)
            .attr('stroke-width', 4)
            .style('fill', 'none');
          // lines of the legend
          svg
            .append('line')
            .attr('x1', xLegendPosition - xLegendOffset)
            .attr('y1', this.currentParams.svgHeight - yLegendOffset)
            .attr('x2', xLegendPosition - xLegendOffset + 30)
            .attr('y2', this.currentParams.svgHeight - yLegendOffset)
            .attr('stroke', `var(${element.color})`)
            .attr('stroke-width', 4)
            .style('fill', 'none');
          // text of the legend
          svg
            .append('text')
            .attr('x', xLegendPosition)
            .attr('y', this.currentParams.svgHeight - yLegendOffset)
            .attr('class', `legend-text ${element.key === 'average' ? 'average-text' : ''}`)
            .text(element.title)
            .attr('alignment-baseline', 'middle')
            .style('text-anchor', 'start');
        }

        // X axis with value 1 and value 2
        svg
          .append('g')
          .attr('class', `chart-axis chart-axis-${element.key}`)
          .attr('transform', axisTranslate)
          .call(d3.axisBottom(x))
          .selectAll('text')
          .data(this.chartData)
          .text(d => {
            if (typeof d[element.key] === 'number' && d[element.key] != null) {
              if (d[element.key] % 1 === 0) return d[element.key];
              return d[element.key].toFixed(2);
            }
            return d[element.key];
          })
          .attr('transform', axisTextTranslate)
          .attr('class', 'chart-label-values')
          .attr('dominant-baseline', axisTextDominant)
          .style('text-anchor', axisTextAnchor);

        // chips for X values
        if (isDesktop) {
          svg
            .selectAll(`g.chart-axis-${element.key} g.tick`)
            .data(this.chartData)
            .insert('rect', 'text')
            .attr('class', 'chip-white')
            .attr('ry', 12)
            .attr('rx', 12)
            .attr('height', 24)
            .each(setChipWidthPosition);
        }

        // Bars and lines
        if (element.key === 'bar') {
          svg
            .selectAll('mybar')
            .data(this.chartData)
            .join('rect')
            .attr('x', (d, i) => x(i) + (isMobile ? 14 : 12))
            .attr('y', d => y(d[element.key]))
            .attr('rx', '4')
            .attr('ry', '4')
            .attr('width', isMobile ? 4 : 8)
            .attr('height', d => this.chartHeight - y(d[element.key]))
            .attr('fill', `var(${element.color})`)
            .attr('class', 'bar');
        } else if (element.key === 'line' || element.key === 'average') {
          const linePath = d3
            .line()
            .x((d, i) => x(i) + standardOffset)
            .y(d => y(d[element.key]));
          svg
            .append('path')
            .datum(this.chartData)
            .attr('class', 'line')
            .attr('stroke', `var(${element.color})`)
            .attr('stroke-width', 2)
            .attr('fill', 'transparent')
            .attr('d', linePath);

          svg
            .selectAll('dot')
            .data(this.chartData)
            .enter()
            .append('circle')
            .attr('r', 3)
            .attr('cx', (d, i) => x(i) + standardOffset)
            .attr('cy', d => y(d[element.key]))
            .attr('stroke', `var(${element.color})`)
            .attr('stroke-width', 1.5)
            .attr('fill', `var(${element.color})`);
        }
      });

      // centering the chart
      const getChartOffset = elementWidth => (this.currentParams.svgWidth - elementWidth) / 2,
        chartYWidth = svg.select('.chart-axis-y')?.node()?.getBBox()?.width,
        chartOffset = Math.max(getChartOffset(chartYWidth), this.currentParams.margin.left);
      d3.select('.line-bar-chart-container svg.chart g').attr('transform', `translate(${chartOffset},${this.currentParams.margin.top})`);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.line-bar-chart-container {
  position: relative;
  display: block;
  overflow-x: auto;
  margin-bottom: var(--spacing-6);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-bottom: var(--spacing-2);
  }

  .chart {
    display: block;
    margin: auto;
  }
}
</style>

<style lang="scss">
.line-bar-chart-container {
  .chart-label-x,
  .chart-label-values,
  .legend-text {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-small);
    font-family: EuclidCircularA;
  }

  .legend-text {
    font-size: var(--font-size-small);
  }

  .chart-axis-x,
  .chart-axis-line,
  .chart-axis-average,
  .chart-axis-bar {
    line {
      display: none;
    }
  }
  .chart-axis-x line.vertical-line {
    display: block;
  }

  .chart-label-values,
  .legend-text {
    font-weight: var(--font-weight-semibold);
  }

  .average-text {
    font-weight: var(--font-weight-medium);
  }

  rect.chip-white {
    fill: var(--colour-panel-g-0);
    stroke: var(--colour-panel-g-16);
    stroke-width: 1;
  }

  path.domain {
    display: none;
  }
}
</style>
