import {
  GET_ALL,
  GET_ALL_LOADING,
  GET_ALL_ERROR,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

export const getAllMutations = {
  [GET_ALL]: (state, data) => {
    state.data = data;
    state.loaded = true;
    state.loading = false;
    state.error = null;
  },
  [GET_ALL_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.error = null;
  },
  [GET_ALL_ERROR]: (state, error) => {
    state.loading = false;
    state.loaded = false;
    state.error = error;
  }
};

export const getAllActions = {
  [GET_ALL]: async ({ commit }) => {
    commit(GET_ALL_LOADING);

    try {
      const response = await httpRequest('get', 'industry-types', {}, {
        version: 2,
        area: 'config'
      });

      commit(GET_ALL, response);
    } catch (e) {
      commit(GET_ALL_ERROR, e);
    }
  }
};