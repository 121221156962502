<template>
  <div class="add-button">
    <CustomDropdown
      v-bind="propsForMenu"
      close-on-selection
      :items="clauseOptions"
      @on-click="handleAddButtonClick"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { CustomDropdown } from '@sales-i/dsv3';
import { AND_OR_OPTIONS } from '@/intelligence/store/data/filters';

const emit = defineEmits(['add-filter']);

const propsForMenu = ref({
  iconWidth: 32,
  iconHeight: 32,
  iconColor: 'var(--colour-utility-white)',
  toggleIcons: ['plus', 'close-line']
});

const clauseOptions = AND_OR_OPTIONS;

function handleAddButtonClick(event) {
  emit('add-filter', event.value);
}
</script>