import { ref, computed, } from 'vue';
import { useStore } from 'vuex';
import { CLEAR_REPORT_DATA, FETCH_REPORT_DATA } from '@/intelligence/store/actionType';
import { GET_ALL } from '@/shared/store/actionType';
import { dates } from '@sales-i/utils';
import { DateTime } from 'luxon';

export default function useTodaysInteractions() {
  const todaysInteractions = ref([]);
  const store = useStore();

  const interactions = computed(() => store.state.crm.interactions.all.data);
  const customerList = computed(() => {
    let list = todaysInteractions.value.length
      ? todaysInteractions.value.reduce((list, el) => {
        const customerDateMs = new Date(el.date_start).getTime();
        el.relationships ? el.relationships.customers.forEach(customer => {
          if (!list.some(e => e.name === customer.name) && customerDateMs > new Date().getTime() && !el.outcome_id) {
            const { name, id, account_number } = customer;
            list.push({
              name,
              value: name.replace(/\s/g, ''),
              id,
              date: customerDateMs,
              account_number,
              updatedDate: dates.format(el.updated_date),
              note: el.description,
              interactionId: el.id
            });
          }
        }) : '';
        return list;
      }, [])
      : [];
    list.sort((a,b) => a.date - b.date);
    return list;
  });

  const userDetails = computed(() => store.state.userDetails.data);

  async function fetchTodaysInteractions() {
    const limit = 500;
    const date_from = DateTime.local().startOf('day').toISO();
    const date_to = DateTime.local().endOf('day').toISO();
    const user_id = userDetails.value.id;
    await getInteractions({ date_from, date_to, limit, user_id });
    todaysInteractions.value = [...interactions.value];
  }

  const fetchReportData = reportName => store.dispatch(`intelligence/shared/${FETCH_REPORT_DATA}`, reportName);
  const getInteractions = parameters => store.dispatch(`crm/interactions/${GET_ALL}`, { parameters });
  const clearReportData = () => store.dispatch(`intelligence/shared/${CLEAR_REPORT_DATA}`);

  return {
    // data
    todaysInteractions,
    // computed - vuex
    interactions,
    // computed - other
    customerList,
    // methods - vuex
    fetchReportData,
    getInteractions,
    clearReportData,
    // methods - other
    fetchTodaysInteractions,
  };
}