
import { computed } from 'vue';
import { CLEAR_DATES, FETCH_DATES, FETCH_DATES_WO_STORE } from '@/intelligence/store/actionType';
import { useStore } from 'vuex';

export default function useCalendar({ store = useStore() } = {}) {
  const fetchedDates = computed(() => store.state.intelligence.calendar.fetchedDates);
  const fetchDates = (params, saveInState = true) => store.dispatch(`intelligence/calendar/${saveInState ? FETCH_DATES : FETCH_DATES_WO_STORE}`, params);
  const clearDates = params => store.dispatch(`intelligence/calendar/${CLEAR_DATES}`, params);
  const dateLoading = computed(() => store.state.intelligence.calendar.dateLoading);

  return {
    dateLoading,
    fetchedDates,
    fetchDates,
    clearDates,
  };
}