import PostsMain from '@/crm/views/Posts/PostsMain.vue';
import PostsView from '@/crm/views/Posts/Posts.vue';
import PostView from '@/crm/views/Posts/Post.vue';
import AddPost from '@/crm/views/Posts/AddPost.vue';
import EditPost from '@/crm/views/Posts/EditPost.vue';
import AddComment from '@/crm/views/Posts/AddComment.vue';
import EditComment from '@/crm/views/Posts/EditComment.vue';
import {
  postsSection as section
} from '@/crm/router/urlBits';

export default {
  path: section,
  component: PostsMain,
  children: [
    {
      path: '',
      component: PostsView,
      children: [
        {
          path: 'add',
          component: AddPost,
        },
      ],
    },
    {
      path: ':post_id',
      component: PostView,
      children: [
        {
          path: 'edit',
          component: EditPost,
        },
        {
          path: 'add',
          component: AddComment,
        },
        {
          path: 'edit/:comment_id',
          component: EditComment,
        },
      ],
    },
  ],
};
