import {
  GET_WIDGET_BY_ID,
  GET_WIDGET_BY_ID_LOADING,
  GET_WIDGET_BY_ID_ERROR,
  CLEAR_WIDGET_BY_ID,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import { mapDashboardForStore } from './mappings';
import { FIXED_WIDGETS } from './fixedWidgets';

// mutations
export const getWidgetByIdMutations = {
  [GET_WIDGET_BY_ID]: (state, data) => {
    state.selected.data = mapDashboardForStore(data);
    state.selected.loaded = true;
    state.selected.loading = false;
  },
  [GET_WIDGET_BY_ID_LOADING]: (state) => {
    state.selected.loaded = false;
    state.selected.loading = true;
    state.selected.deleted = false;
  },
  [GET_WIDGET_BY_ID_ERROR]: (state) => {
    state.selected.loaded = false;
    state.selected.loading = false;
  },
  [CLEAR_WIDGET_BY_ID]: (state) => {
    state.selected.data = {};
    state.selected.loaded = false;
    state.selected.loading = false;
    state.selected.deleted = false;
  },
};

// actions
export const getWidgetByIdActions = {
  [GET_WIDGET_BY_ID]: async ({ commit }, { id, widgetId }) => {
    try {
      commit(GET_WIDGET_BY_ID_LOADING);
      let data = {};
      if (id == -1) {
        data = FIXED_WIDGETS;
      } else {
        data = await httpRequest('get', `dashboards/${id}/widget/${widgetId}`, {}, { area: 'dashboard' });
      }
      commit(GET_WIDGET_BY_ID, data);
      return data;
    } catch (error) {
      console.error(error);
      commit(GET_WIDGET_BY_ID_ERROR);
      return false;
    }
  },
  [CLEAR_WIDGET_BY_ID]: ({ commit }) => {
    commit(CLEAR_WIDGET_BY_ID);
  },
};
