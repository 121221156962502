import customers from '@/crm/store/modules/customers';
import contacts from '@/crm/store/modules/contacts';
import prospects from '@/crm/store/modules/prospects';
import opportunities from '@/crm/store/modules/opportunities';
import opportunityProducts from '@/crm/store/modules/opportunityProducts';
import interactions from '@/crm/store/modules/interactions';
import files from '@/crm/store/modules/files';
import businessAreas from '@/crm/store/modules/businessAreas';
import customFields from '@/crm/store/modules/customFields';

export default {
  namespaced: true,
  modules: {
    customers,
    contacts,
    prospects,
    opportunities,
    files,
    businessAreas,
    interactions,
    opportunityProducts,
    customFields,
  }
};
