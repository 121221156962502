<template>
  <LeftHandNavigation
    :items="periodItems"
    :active-section="activeSectionIndex"
    :loading="periodsLoading"
    :sub-section="activeSection.subSectionIndex"
    @item-clicked="itemClicked"
    @active-section-clicked="setActiveSection"
    @sub-section-clicked="subSectionClicked"
  >
    <router-view
      :loading="targetReportLoading"
      :active-section="activeSection"
      :pagination-data="paginationData"
      @reload="getTargets"
      @load-more-targets="loadMoreTargets"
    />
  </LeftHandNavigation>
</template>

<script setup>
// TargetsSettingsView
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import LeftHandNavigation from '@/admin/components/Layout/LeftHandNavigation.vue';
import { FETCH_BUBBLE_DATA, FETCH_MEASUREMENTS, FETCH_REPORT_DATA, FETCH_TARGET_PERIODS } from '@/intelligence/store/actionType';
import { TARGET_INTERVALS } from '@/intelligence/store/data/dateFilters';

// setup
const store = useStore();

// data
const activeSectionIndex = ref(0);
const periodsLoading = ref(true);
const targetReportLoading = ref(true);
const paginationData = ref({offset: 0, limit: 20, recordsCount: 0});
const periodItems = ref([
  {
    id: 1,
    name: '',
    isCustomSection: false,
    canCreateEntities: false,
    sections: [],
    subSectionIndex: 0
  }
]);

// store: state
const bubbleData = computed(() => store.state.intelligence.enquiry.bubbleData);
const targetsCount = computed(() => store.state.intelligence.targets.targetsData.targets.length || 0);

// store: actions
const fetchTargetPeriods = () => store.dispatch(`intelligence/targets/${FETCH_TARGET_PERIODS}`);
const fetchMeasurements = () => store.dispatch(`intelligence/targets/${FETCH_MEASUREMENTS}`);
const fetchTargetReport = payload => store.dispatch(`intelligence/targets/${FETCH_REPORT_DATA}`, payload);
const fetchBubbleData = () => store.dispatch(`intelligence/enquiry/${FETCH_BUBBLE_DATA}`);

// computed
const activeSection = computed(() => periodItems.value[activeSectionIndex.value]);

// lifecycle hooks
onMounted(async () => {
  await fetchTargetPeriods();
  fetchMeasurements();
  processTargetPeriods();
  if (!bubbleData.value) await fetchBubbleData();
  await getTargets();
});

// methods
async function getTargets() {
  const { subSectionIndex, id: year, sections } = activeSection.value;
  const interval = TARGET_INTERVALS[0].value || sections[subSectionIndex].value;
  targetReportLoading.value = true;
  await fetchTargetReport({ params: { year, interval } });
  targetReportLoading.value = false;
  periodsLoading.value = false;
  paginationData.value.recordsCount = targetsCount.value;
}

async function loadMoreTargets(payload) {
  const { subSectionIndex, id: year, sections } = activeSection.value;
  const interval = sections[subSectionIndex].value;
  await fetchTargetReport({ params: { offset: payload, interval, year  } });
  paginationData.value.offset = payload;
  paginationData.value.recordsCount = targetsCount.value;
}

function processTargetPeriods() {
  const currentYear = new Date().getFullYear();
  const periodYears = [currentYear, currentYear + 1];
  periodItems.value = [];
  periodYears.forEach(year => {
    const newItem = {
      id: year,
      name: `${year} Targets`,
      isCustomSection: false,
      canCreateEntities: false,
      sections: [],
      subSectionIndex: 0
    };
    newItem.sections = Object.entries(TARGET_INTERVALS)
      .map(([, value]) => ({ name: value.text, value: value.value }));
    periodItems.value.unshift(newItem);
  });
}

function setActiveSection(index) {
  activeSectionIndex.value = index || 0;
  periodItems.value[activeSectionIndex.value].subSectionIndex = 0;
}

function subSectionClicked(index) {
  periodItems.value[activeSectionIndex.value].subSectionIndex = index;
  getTargets();
}
</script>

<style lang="scss" scoped></style>
