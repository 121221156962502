<template>
  <TabSwitch
    class="tab-switch"
    :items="clauseOptions"
    :selected-value="selectedValue"
    @switch-selected="$emit('switch-selected', $event)"
  />
</template>

<script setup>
import { TabSwitch } from '@sales-i/dsv3';
import { AND_OR_OPTIONS } from '@/intelligence/store/data/filters';

defineProps({
  selectedValue: {
    type: String,
    default: ''
  }
});

defineEmits(['switch-selected']);

const clauseOptions = AND_OR_OPTIONS;
</script>