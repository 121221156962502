import {
  GET_BY_ENTITY,
  GET_BY_ENTITY_LOADING,
  GET_BY_ENTITY_ERROR,
  CLEAR_BY_ENTITY
} from '@/shared/store/actionType';
import { httpRequest, pluralise } from '@sales-i/utils';

// mutations
export const getByEntityMutations = {
  [GET_BY_ENTITY](state, payload) {
    state.entity.data = payload;
    state.entity.loaded = true;
    state.entity.loading = false;
  },
  [GET_BY_ENTITY_LOADING]: (state) => {
    state.entity.loaded = false;
    state.entity.loading = true;
  },
  [GET_BY_ENTITY_ERROR]: (state) => {
    state.entity.loaded = true;
    state.entity.loading = false;
  },
  [CLEAR_BY_ENTITY]: (state) => {
    state.entity.name = '';
    state.entity.data = [];
    state.entity.loaded = false;
    state.entity.loading = false;
  },
};

// actions
export const getByEntityActions = {
  [GET_BY_ENTITY]: async ({ commit, }, { entity, id, }) => {
    const options = entity === 'file' ? { area: 'files' } : {};
    let tags = [];
    // Build the URL for the tags call
    if (entity && id) {
      const requestUrl = `${pluralise.string(entity)}/${id}/tags`;
      // Fetch the list of tags for a customer, and return the promise
      commit(GET_BY_ENTITY_LOADING);
      tags = await httpRequest('get', requestUrl, {}, {
        version: '2', ...options
      });
    }

    commit(GET_BY_ENTITY, tags, id);
  },
  [CLEAR_BY_ENTITY]: ({ commit, }) => {
    commit(CLEAR_BY_ENTITY);
  },
};
