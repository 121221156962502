import {
  POST_ITEM,
  POST_ITEM_LOADING,
  POST_ITEM_ERROR,
  SET_UNSAVED_MODAL_STATE,
  SET_QUERY_STRING_CHANGED_STATE
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import { mapWidgetForAPI } from './mappings';

// mutations
export const postItemMutations = {
  [POST_ITEM]: (state, widget) => {
    state.selected.data.widgets = [...(state.selected.data?.widgets || []), widget];
    state.selected.loading = false;
    state.selected.loaded = true;
  },
  [POST_ITEM_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [POST_ITEM_ERROR]: (state) => {
    state.selected.loading = false;
    state.selected.loaded = false;
  },
  [SET_UNSAVED_MODAL_STATE]: (state, showUnsavedChangesModal) => {
    state.showUnsavedChangesModal = showUnsavedChangesModal;
  },
  [SET_QUERY_STRING_CHANGED_STATE]: (state, isQueryStringChanged) => {
    state.isQueryStringChanged = isQueryStringChanged;
  }
};

// actions
export const postItemActions = {
  [POST_ITEM]: async ({ commit, }, widget) => {
    commit(POST_ITEM_LOADING);
    try {
      const id = await httpRequest('post', `dashboards/${widget.dashboard_id}/widget`, mapWidgetForAPI(widget), { area: 'dashboard' });
      commit(POST_ITEM, { ...widget, id });
      return true;
    } catch (error) {
      console.error(error);
      commit(POST_ITEM_ERROR);
      return false;
    }
  },
  [SET_UNSAVED_MODAL_STATE]: ({ commit }, showUnsavedChangesModal) => commit(SET_UNSAVED_MODAL_STATE, showUnsavedChangesModal),
  [SET_QUERY_STRING_CHANGED_STATE]: ({ commit }, isQueryStringChanged) => commit(SET_QUERY_STRING_CHANGED_STATE, isQueryStringChanged)
};
