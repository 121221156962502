<template>
  <div
    ref="container"
    class="multi-bar-chart-container"
  >
    <svg class="chart" />
  </div>
</template>

<script>
import * as d3 from 'd3';
import { abbr } from '@sales-i/utils';
import chartsMixin from '@/shared/mixins/chartsMixin';

export default {
  mixins: [chartsMixin],
  data() {
    return {
      greenChipClass: 'chip-green',
      redChipClass: 'chip-red',
    };
  },
  computed: {
    chartData() {
      return this.tableData.rows.map(row => {
        return {
          value1: row.value_1,
          value2: row.value_2,
          variance: row.value_variance,
          name: row.period,
        };
      });
    },
  },
  methods: {
    getChartVersion() {
      return this.$refs.container?.clientWidth <= 680 ? 'mobile' : 'desktop';
    },
    getMaximumValue() {
      return Math.max(...this.chartData.map(el => el.value1), ...this.chartData.map(el => el.value2));
    },
    removeChart() {
      d3.select('.multi-bar-chart-container svg.chart g').remove();
    },
    processChartParams(type) {
      // dimensions and margins of the chart (mobile and desktop)
      this.currentParams =
        type === 'mobile'
          ? {
            svgWidth: this.$refs.container?.clientWidth,
            svgHeight: 450,
            margin: {
              top: 0,
              right: 10,
              bottom: 280,
              left: 34,
            },
          }
          : type === 'desktop'
            ? {
              svgWidth: this.$refs.container?.clientWidth,
              svgHeight: 510,
              margin: {
                top: 10,
                right: 0,
                bottom: 200,
                left: 100,
              },
            }
            : '';
    },
    generateChart() {
      this.processChartParams(this.getChartVersion());

      // set parameters to container svg
      const svg = d3
        .select('.multi-bar-chart-container svg.chart')
        .attr('width', this.currentParams.svgWidth)
        .attr('height', this.currentParams.svgHeight)
        .append('g')
        .attr('transform', `translate(${this.currentParams.margin.left},${this.currentParams.margin.top})`);

      //
      // DATA
      //
      const isMobile = this.getChartVersion() === 'mobile',
        isDesktop = this.getChartVersion() === 'desktop',
        line1Title = this.tableData.headings[1].replace(/\D/g, ''),
        line2Title = this.tableData.headings[2].replace(/\D/g, ''),
        bottomOffsetMonths = isMobile ? 60 : 32,
        xValuePosition = 16,
        bgLinesColor = '--colour-panel-g-8',
        barsData = [
          {
            id: 1,
            key: 'value1',
            color: '--colour-brand-viking',
            axisPosition: 45,
            axisPositionMobile: 35,
            legendPosition: 58,
            legendPositionMobile: -75,
            title: line1Title,
            position: isMobile ? -6 : -12,
          },
          {
            id: 2,
            key: 'value2',
            color: '--colour-data-barberry',
            axisPosition: 85,
            axisPositionMobile: 90,
            legendPosition: 98,
            legendPositionMobile: 75,
            title: line2Title,
            position: isMobile ? 2 : 4,
          },
        ];

      //
      // UTILS
      //
      const isValueNegative = value => value < 0;
      const getTextCenter = context => d3.select(context.parentNode).select('text').node().getComputedTextLength();

      function setChipWidthPosition() {
        const textLength = getTextCenter(this) + 24;
        d3.select(this)
          .attr('width', textLength)
          .attr('transform', `translate(-${textLength / 2},1)`);
      }
      function setVarianceChipWidthPosition() {
        const textLength = getTextCenter(this) + 30;
        d3.select(this)
          .attr('width', textLength)
          .attr('transform', `translate(-${textLength / 2},1)`);
      }
      function setArrowBackgroundPosition() {
        const textLength = getTextCenter(this) + 4;
        d3.select(this).attr('transform', `translate(-${textLength / 2},0)`);
      }

      //
      //  DRAWING
      //

      // X axis (with month names)
      const x = d3
        .scalePoint()
        .domain(this.chartData.map((d, i) => i))
        .range([0, this.chartWidth - this.currentParams.margin.left]);
      svg
        .append('g')
        .attr('class', 'chart-axis chart-axis-x')
        .attr('transform', `translate(0, ${this.chartHeight})`)
        .call(d3.axisBottom(x))
        .selectAll('text')
        .data(this.chartData)
        .text(d => d.name)
        .attr(
          'transform',
          `translate(${xValuePosition}, ${this.currentParams.margin.bottom - bottomOffsetMonths - 16})`
        )
        .attr('dominant-baseline', 'middle')
        .attr('class', 'chart-label-x')
        .style('text-anchor', 'middle');

      // Y axis
      const y = d3
        .scaleLinear()
        .domain([0, 1.1 * this.getMaximumValue()])
        .range([this.chartHeight, 0]);
      const axisY = d3.axisLeft(y);
      svg
        .append('g')
        .attr('class', 'chart-axis chart-axis-y')
        .call(axisY)
        .selectAll('text')
        .attr('class', 'chart-label-y')
        .text(d => abbr.float(d, 0, 1));

      // horizontal lines
      svg
        .selectAll('g.chart-axis-y g.tick line')
        .attr('x1', x(0))
        .attr('x2', x(this.chartData.length - 1) + 32)
        .style('stroke-width', 1)
        .style('stroke', `var(${bgLinesColor})`)
        .style('fill', 'none');

      // vertical lines
      svg
        .selectAll('.chart-axis-x g.tick')
        .append('line')
        .attr('x1', xValuePosition)
        .attr('y1', 0)
        .attr('x2', xValuePosition)
        .attr('y2', this.currentParams.margin.bottom - bottomOffsetMonths - 25)
        .attr('class', 'vertical-line')
        .style('stroke-width', 1)
        .style('stroke', `var(${bgLinesColor})`)
        .style('fill', 'none');

      barsData.forEach(bar => {
        let axisTranslate = `translate(${xValuePosition}, ${this.chartHeight + bar.axisPosition})`,
          axisTextTranslate = 'translate(0,0)',
          axisTextAnchor = 'middle',
          axisTextDominant = '';

        if (isMobile) {
          axisTranslate = `translate(0, ${this.chartHeight + bar.axisPositionMobile + 60})`;
          axisTextTranslate = 'translate(0,0)rotate(-90)';
          axisTextAnchor = 'start';
          axisTextDominant = 'middle';
        }

        // legend
        if (isDesktop) {
          // line between chips
          svg
            .append('line')
            .attr('x1', -20)
            .attr('y1', this.chartHeight + bar.legendPosition)
            .attr('x2', x(this.chartData.length - 1) + 24)
            .attr('y2', this.chartHeight + bar.legendPosition)
            .attr('stroke', `var(${bar.color})`)
            .attr('stroke-width', 1)
            .style('fill', 'none');
          // legend text
          svg
            .append('text')
            .attr('x', -24)
            .attr('y', this.chartHeight + bar.legendPosition + 2)
            .attr('class', 'legend-text')
            .text(bar.title)
            .attr('alignment-baseline', 'middle')
            .attr('text-anchor', 'end');
          // dot
          svg
            .append('circle')
            .attr('cx', -16)
            .attr('cy', this.chartHeight + bar.legendPosition)
            .attr('r', 5)
            .attr('stroke', `var(${bar.color})`)
            .attr('fill', `var(${bar.color})`);
        } else if (isMobile) {
          const xLegendPosition = this.chartWidth / 2 + bar.legendPositionMobile,
            xLegendOffset = 60,
            yLegendOffset = 30;
          // small lines near values
          svg
            .append('line')
            .attr('x1', 0)
            .attr('y1', this.chartHeight + bar.axisPositionMobile + 30)
            .attr('x2', 0)
            .attr('y2', this.chartHeight + bar.axisPositionMobile + 50)
            .attr('stroke', `var(${bar.color})`)
            .attr('stroke-width', 4)
            .style('fill', 'none');
          // lines of the legend
          svg
            .append('line')
            .attr('x1', xLegendPosition - xLegendOffset)
            .attr('y1', this.currentParams.svgHeight - yLegendOffset)
            .attr('x2', xLegendPosition - xLegendOffset + yLegendOffset)
            .attr('y2', this.currentParams.svgHeight - yLegendOffset)
            .attr('stroke', `var(${bar.color})`)
            .attr('stroke-width', 4)
            .style('fill', 'none');
          // text of the legend
          svg
            .append('text')
            .attr('x', xLegendPosition)
            .attr('y', this.currentParams.svgHeight - yLegendOffset)
            .attr('class', 'legend-text')
            .text(bar.title)
            .attr('alignment-baseline', 'middle')
            .style('text-anchor', 'middle');
        }

        // X axis with value 1 and value 2
        svg
          .append('g')
          .attr('class', `chart-axis chart-axis-${bar.key}`)
          .attr('transform', axisTranslate)
          .call(d3.axisBottom(x))
          .selectAll('text')
          .data(this.chartData)
          .text(d => abbr.float(d[bar.key]))
          .attr('transform', axisTextTranslate)
          .attr('class', 'chart-label-values')
          .attr('dominant-baseline', axisTextDominant)
          .style('text-anchor', axisTextAnchor);

        // chips for X values
        if (isDesktop) {
          svg
            .selectAll(`g.chart-axis-value${bar.id} g.tick`)
            .data(this.chartData)
            .insert('rect', 'text')
            .attr('class', 'chip-white')
            .attr('ry', 12)
            .attr('rx', 12)
            .attr('height', 24)
            .each(setChipWidthPosition);
        }

        // Bars
        svg
          .selectAll('mybar')
          .data(this.chartData)
          .join('rect')
          .attr('x', (d, i) => x(i) + bar.position + 16)
          .attr('y', d => y(d[bar.key]))
          .attr('rx', '4')
          .attr('ry', '4')
          .attr('width', isMobile ? 4 : 8)
          .attr('height', d => this.chartHeight - y(d[bar.key]))
          .attr('fill', `var(${bar.color})`)
          .attr('class', 'bar');
      });

      // VARIANCE
      let getVariancePosition = d => (isValueNegative(d.variance) ? 110 : 0);
      if (isMobile) {
        getVariancePosition = d => (isValueNegative(d.variance) ? 110 : -40);
      }
      const getVarianceClass = d => (isValueNegative(d.variance) ? this.redChipClass : this.greenChipClass);
      const getVarianceValue = d => abbr.float(Math.abs(d.variance));
      if (isDesktop) {
        // Axis with variances
        svg
          .append('g')
          .attr('class', 'chart-variance')
          .attr('transform', `translate(${xValuePosition}, ${this.chartHeight + 10})`)
          .call(d3.axisBottom(x))
          .selectAll('text')
          .data(this.chartData)
          .text(d => getVarianceValue(d))
          .attr('transform', 'translate(8,9)')
          .attr('y', d => getVariancePosition(d))
          .attr('dominant-baseline', '')
          .attr('class', d => getVarianceClass(d))
          .style('text-anchor', 'middle');

        // chips for variances
        svg
          .selectAll('g.chart-variance g.tick')
          .data(this.chartData)
          .insert('rect', 'text')
          .attr('class', 'chip-white')
          .attr('ry', 12)
          .attr('rx', 12)
          .attr('height', 24)
          .attr('class', d => getVarianceClass(d))
          .attr('y', d => getVariancePosition(d))
          .each(setVarianceChipWidthPosition);

        // arrow icon
        const arrowIcon = svg
          .selectAll('g.chart-variance g.tick')
          .data(this.chartData)
          .append('g')
          .attr('class', 'variance-chip-icon')
          .each(setArrowBackgroundPosition);
        // arrow background
        arrowIcon
          .append('circle')
          .attr('cx', 0)
          .attr('cy', d => getVariancePosition(d) + 13)
          .attr('r', 7)
          .attr('class', d => getVarianceClass(d));
        // just arrow
        arrowIcon
          .append('path')
          .attr(
            'd',
            'M26,15.6599998 L33.1800003,22.8200006 L36,20 L24,8 L12,20 L14.8199995,22.8199968 L22,15.6599998 L22,40 L26,40 L26,15.6599998 Z'
          )
          .attr('fill', 'var(--colour-utility-white)')
          .attr('stroke-width', 1)
          .attr('transform', d =>
            isValueNegative(d.variance)
              ? 'translate(8.5,131.5) scale(0.35) rotate(180)'
              : 'translate(-8.2,4.8) scale(0.35)'
          );

        // remove chip with zero value
        svg.selectAll('g.chart-variance g.tick text').each(function () {
          if (d3.select(this).text() === '0') d3.select(this.parentNode).remove();
        });
      } else if (isMobile) {
        // Axis with variances
        svg
          .append('g')
          .attr('class', 'chart-variance')
          .attr('transform', `translate(0, ${this.chartHeight + 70})`)
          .call(d3.axisBottom(x))
          .selectAll('text')
          .data(this.chartData)
          .text(d => getVarianceValue(d))
          .attr('transform', d => `translate(0,${getVariancePosition(d)})rotate(-90)`)
          .attr('dominant-baseline', 'middle')
          .attr('class', d => getVarianceClass(d))
          .style('text-anchor', 'middle');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.multi-bar-chart-container {
  position: relative;
  display: block;
  overflow-x: auto;
  margin-bottom: var(--spacing-6);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-bottom: var(--spacing-2);
  }

  .chart {
    display: block;
    margin: auto;
  }
}
</style>

<style lang="scss">
.multi-bar-chart-container {
  .chart-label-x,
  .chart-label-values,
  .chart-variance,
  .legend-text {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-small);
    font-family: EuclidCircularA;
  }

  .legend-text {
    font-size: var(--font-size-regular);
  }

  .chart-axis-x,
  .chart-axis-value1,
  .chart-axis-value2,
  .chart-variance {
    line {
      display: none;
    }
  }
  .chart-axis-x line.vertical-line {
    display: block;
  }

  .chart-label-values,
  .chart-variance,
  .legend-text {
    font-weight: var(--font-weight-semibold);
  }

  text.chip-green {
    color: var(--colour-data-de-york-label);
  }

  text.chip-red {
    color: var(--colour-data-mandy-label);
  }

  rect.chip-white,
  rect.chip-red,
  rect.chip-green {
    fill: var(--colour-panel-g-0);
    stroke: var(--colour-panel-g-16);
    stroke-width: 1;
  }

  circle.chip-red {
    fill: var(--colour-data-mandy-label);
    stroke: var(--colour-data-mandy-label);
  }
  circle.chip-green {
    fill: var(--colour-data-de-york-label);
    stroke: var(--colour-data-de-york-label);
  }

  path.domain {
    display: none;
  }
}
</style>
