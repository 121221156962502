import AddProspect from '@/crm/views/Prospects/AddProspect';
import Dashboard from '@/crm/views/CustomersProspects/Dashboard.vue';
import { baseUrl, prospectsArea as area, } from '@/crm/router/urlBits';

export default {
  path: `${baseUrl}/${area}`,
  component: Dashboard,
  props: { entity: 'prospect' },
  children: [
    {
      path: 'add',
      component: AddProspect,
    }
  ],
};
