<template>
  <div class="drop">
    <CustomCard
      purpose="alert"
      class="item"
    >
      <div class="header">
        <div class="title-area">
          <h4>
            <slot />
          </h4>
        </div>
      </div>
    </CustomCard>
  </div>
</template>

<script type="text/javascript">
import { CustomCard } from '@sales-i/dsv3';

export default {
  name: 'SectionsDrop',
  components: {
    CustomCard,
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.item {
  padding: 0;
  opacity: 0.5;
}

.header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: var(--spacing-1);
  justify-content: space-between;
  padding: var(--spacing-2);

  .grow {
    flex-grow: 1;
  }
}

.drop {
  display: block;
  animation: blinker 1s linear infinite;
  border-radius: var(--border-radius-1);
  border: 2px dashed var(--colour-utility-action);
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
