import {
  GET_BY_ID,
  GET_BY_ID_LOADING,
  GET_BY_ID_ERROR,
  CLEAR_BY_ID,
} from '@/shared/store/actionType';
import { httpRequest, updateBreadcrumb } from '@sales-i/utils';

function createCurrentlyEditing(role) {
  return {
    details: {
      name: role.name,
      description: role.description,
    },
    features: role.features
  };
}

// mutations
export const getByIdMutations = {
  [GET_BY_ID]: (state, {data, currentlyEditing}) => {
    state.currentlyEditing = currentlyEditing;
    state.selected.data = data;
    state.selected.loaded = true;
    state.selected.loading = false;
    state.selected.updated = false;
  },
  [GET_BY_ID_LOADING]: (state) => {
    state.selected.loaded = false;
    state.selected.loading = true;
  },
  [GET_BY_ID_ERROR]: (state) => {
    state.selected.loaded = false;
    state.selected.loading = false;
  },
  [CLEAR_BY_ID]: (state) => {
    state.selected.data = {};
    state.selected.loaded = false;
    state.selected.loading = false;
  },
};

// actions
export const getByIdActions = {
  [GET_BY_ID]: async ({ commit, }, parameters) => {
    try {
      const {
        id,
        options = {
          version: '2',
          area: 'iam'
        }
      } = parameters;
      commit(GET_BY_ID_LOADING);
      const data = await httpRequest('get', `tenants/current/roles/${id}`, {}, options);
      let currentlyEditing = createCurrentlyEditing(data);
      updateBreadcrumb('roles', id, data.name);
      commit(GET_BY_ID, {data, currentlyEditing});
      return data;
    } catch (error) {
      console.error(error);
      commit(GET_BY_ID_ERROR);
      return false;
    }
  },
  [CLEAR_BY_ID]: ({ commit, }) => {
    commit(CLEAR_BY_ID);
  },
};
