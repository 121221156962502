import OpportunitiesSettingsView from '@/admin/views/CRMSetup/OpportunitiesSettingsView.vue';
import OpportunitiesStagesView from '@/admin/views/CRMSetup/OpportunitiesStagesView.vue';
import Sections from '@/admin/views/CRMSetup/Sections.vue';
import Settings from '@/admin/views/CRMSetup/Settings.vue';
import { opportunitiesSection as section, customSectionsPath, stagesPath } from '@/admin/router/urlBits';

export default {
  path: `:entity(${section})`,
  component: OpportunitiesSettingsView,
  children: [
    {
      path: '',
      component: Sections,
    },
    {
      path: customSectionsPath,
      component: Sections,
    },
    {
      path: stagesPath,
      component: OpportunitiesStagesView,
    },
    {
      path: 'settings',
      component: Settings,
    },
  ],
};
