import {
  GET_USER_DETAILS,
  SET_USER_DETAILS,
  GET_CURRENCY,
  LOG_OUT,
  LOG_OUT_ERROR,
} from '@/shared/store/actionType';
import { currency as cFuncs, httpRequest, auth } from '@sales-i/utils';

// initial state
const state = () => ({
  data: undefined,
  currencyName: false,
  cs: '',
  error: undefined,
});

// getters
const getters = {
};

// mutations
const mutations = {
  [SET_USER_DETAILS]: (state, data) => {
    state.data = data;
  },
  [GET_CURRENCY]: (state, currency) => {
    state.currencyName = currency;
    state.cs = cFuncs.symbol(currency);
  },
  [LOG_OUT_ERROR]: (state, error) => {
    state.error = error;
  }
};

// actions
const actions = {
  [GET_USER_DETAILS]: async ({ commit, }) => {
    try {
      const user = await httpRequest('get', 'subjects/me', {}, { area: 'iam', version: '2', });
      commit(SET_USER_DETAILS, user);
    } catch(error) {
      commit(SET_USER_DETAILS, undefined);
    }
  },
  [GET_CURRENCY]: async ({ commit }) => {
    try {
      const data = await httpRequest('get', `${process.env.VUE_APP_BUREAU_URI}/v1/user`);
      const currency = data.user.hubs[0].currencyCode;
      commit(GET_CURRENCY, currency);
    } catch(error) {
      commit(GET_CURRENCY, undefined);
    }
  },
  [LOG_OUT]: async ({ commit, }) => {
    try {
      await auth.logout();
      commit(SET_USER_DETAILS);
    } catch(error) {
      commit(LOG_OUT_ERROR, error);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
