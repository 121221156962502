<template>
  <span
    class="products-label"
    :class="{ expand: extendedOpportunityItem.expanded }"
  >
    <strong class="short">
      {{ productCodes.slice(0, productsLabelLimit).join(', ') }}
      <CustomButton
        v-if="productCodes.length > productsLabelLimit"
        purpose="text"
        tabindex="0"
        @click="extendedOpportunityItem.expanded = true"
        @keydown.enter="extendedOpportunityItem.expanded = true"
      >
        + {{ productCodes.length - productsLabelLimit }} {{ t('more') }}
      </CustomButton>
    </strong>
    <strong class="long">
      {{ productCodes.join(', ') }}
    </strong>
  </span>
</template>

<script>
import { CustomButton } from '@sales-i/dsv3';
import { t } from '@sales-i/utils';

export default {
  name: 'TogglableProductsLabel',
  components: { CustomButton },
  props: {
    opportunityItem: {
      type: Object,
      default: () => {},
    },
    productsLabelLimit: {
      type: Number,
      default: 3,
    },
    opportunityType: {
      type: String,
      default: '',
    },
    enableProductCode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      extendedOpportunityItem: {},
      productCodes: [],
    };
  },
  computed: {
    products() {
      return this.extendedOpportunityItem.products || [];
    },
  },
  mounted() {
    this.extendedOpportunityItem = { ...this.opportunityItem, expanded: false };
    this.productCodes = this.getProductCodes();
  },
  methods: {
    t,
    getProductCodes(isShort) {
      const items = [];

      for (let i = 0; i < this.products.length; i++) {
        if (isShort && items.length === this.productsLabelLimit) break;
        if (this.products[i].prod_category === this.opportunityType) {
          items.push(`${this.products[i].product_name} ${this.enableProductCode ? `(${this.products[i].prod_code})` : ''}`);
        }
      }

      return items;
    },
  },
};
</script>

<style lang="scss" scoped>
.products-label {
  .long {
    display: none;
  }

  &.expand {
    .short {
      display: none;
    }
    .long {
      display: inline;
    }
  }

  a {
    cursor: pointer;
  }
}
</style>
