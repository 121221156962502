import {
  GET_BY_ENTITY,
  GET_BY_ENTITY_LOADING,
  GET_BY_ENTITY_ERROR,
  CLEAR_BY_ENTITY,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

// initial state
const state = () => ({
  loading: true,
  loaded: false,
  data: [],
  errors: [],
});

// getters
const getters = {
};

// mutations
const mutations = {
  [GET_BY_ENTITY]: (state, data) => {
    state.data = data;
    state.loaded = true;
    state.loading = false;
  },
  [GET_BY_ENTITY_LOADING]: (state) => {
    state.loaded = false;
    state.loading = true;
  },
  [GET_BY_ENTITY_ERROR]: (state, data) => {
    state.data = [];
    state.loaded = false;
    state.loading = false;
    state.errors = data;
  },
  [CLEAR_BY_ENTITY]: (state) => {
    state.data = [];
    state.loaded = false;
    state.loading = true;
  },
};

// actions
const actions = {
  [GET_BY_ENTITY]: async ({ commit, }) => {
    try {
      commit(GET_BY_ENTITY_LOADING);
      let data = await httpRequest('get', 'config/schema/field_types', {}, { version: '2' });
      commit(GET_BY_ENTITY, data);
    } catch(e) {
      commit(GET_BY_ENTITY_ERROR, []);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
