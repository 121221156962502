import {
  baseUrl,
  alertsScope,
} from '@/intelligence/router/urlBits';
import AlertBuilder from '@/intelligence/views/Communities/AlertBuilder.vue';
import { PRODUCT_COMMUNITY_INSIGHTS, } from '@/intelligence/store/data/reportTypes';
import AlertBuilderWizard from './AlertBuilderWizard';
import { NEW_ALERT_ROUTE } from '@/intelligence/router/routeNames';

export default [
  // Main page and add new alert
  {
    path: `${baseUrl}/${alertsScope}`,
    name: NEW_ALERT_ROUTE,
    component: AlertBuilder,
    children: [
      ...AlertBuilderWizard
    ],
  },
  // Edit Alert
  {
    path: `${baseUrl}/${alertsScope}/:queryId(\\d+)`,
    name: PRODUCT_COMMUNITY_INSIGHTS,
    component: AlertBuilder,
    children: [
      ...AlertBuilderWizard
    ],
  }
];
