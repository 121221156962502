<template>
  <section
    v-if="view === 'day'"
    class="day-card"
  >
    <p class="time">
      {{ dates.format(props.item.start, 'timeSimple') }}
    </p>

    <CRMCard
      v-if="props.item.is_personal"
      :title="props.item.title"
      :links="links"
      :on-click="openInteraction"
      @open="openInteraction"
      @edit="editInteraction"
      @delete="deleteInteraction"
      @complete="completeInteraction"
    >
      <template #info>
        <p>{{ props.item.content }}</p>
        <p>
          {{ dates.format(props.item.start, 'timeSimple') }} -
          {{ dates.format(props.item.end, 'timeSimple') }}
        </p>
      </template>
    </CRMCard>
    <CRMCard
      v-else
      :currency="currency"
      :title="props.item.title || t('No contact')"
      :chips="chips"
      :links="links"
      :on-click="openInteraction"
      @open="openInteraction"
      @edit="editInteraction"
      @delete="deleteInteraction"
      @complete="completeInteraction"
    >
      <template #info>
        <p><ReplaceSpaceWithNBSPComponent :text="name" /></p>
        <p>{{ contactMobilePhone }}</p>
        <p>
          {{ dates.format(props.item.start, 'timeSimple') }} -
          {{ dates.format(props.item.end, 'timeSimple') }}
        </p>
      </template>
      <template #tags>
        <Tag
          :id="props.item.id"
          class="ml-1"
          entity="interaction"
        />
      </template>
    </CRMCard>
  </section>

  <CRMCard
    v-if="view === 'week'"
    class="week-card"
    :on-click="openInteraction"
  >
    <template #info>
      <h3 class="text-sm fw-sbld">
        {{ props.item.title || t('No contact') }}
      </h3>
      <p>{{ name }}</p>
    </template>
  </CRMCard>
</template>

<script setup>
// CalendarInteractionCard
import { computed } from 'vue';
import { CRMCard } from '@sales-i/dsv3';
import Tag from '@/shared/components/Tags/Tag.vue';
import ReplaceSpaceWithNBSPComponent from '@/shared/components/ReplaceSpaceWithNBSP.vue';
import usePermissions from '@/shared/composables/usePermissions';
import { interactions } from '@/shared/store/data/policies';
import useCurrency from '@/shared/composables/useCurrency';
import { dates, t } from '@sales-i/utils';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  view: {
    type: String,
    default: 'day',
  },
});

const emit = defineEmits(['openInteraction', 'editInteraction', 'deleteInteraction', 'completeInteraction']);

const { cs, currency } = useCurrency();

const { getPermissions } = usePermissions();
const permissions = getPermissions(interactions);

const name = computed(() => {
  if (props.item.is_personal) {
    return props.item.content;
  }
  const getFirstName = type => {
    const found = props.item.relationships && props.item.relationships[type] && props.item.relationships[type][0];
    return found && found.name;
  };
  const customerName = getFirstName('customers');
  if (customerName) return customerName;

  const prospectName = getFirstName('prospects');
  if (prospectName) return prospectName;

  return t('No customer name');
});

const links = computed(() => {
  let links = [];
  const { id, is_personal, outcome_id } = props.item;

  if (permissions.delete && id) {
    links.push({
      emit: 'delete',
      text: t('Delete'),
      id,
    });
  }

  if (permissions.update && id && (outcome_id === 0 || is_personal)) {
    links.push({
      emit: 'edit',
      text: t('Edit'),
      id,
      is_personal
    });
  }

  if (permissions.read && id) {
    links.push({
      emit: 'open',
      text: t('Open'),
      id,
      is_personal
    });
  }


  if (permissions.update && id && !is_personal) {
    links.push({
      emit: 'complete',
      text: t('Complete'),
      id,
      is_personal
    });
  }
  return links;
});

const chips = computed(() => {
  let { value } = props.item;
  if (value === undefined || value === 0) {
    return [];
  } else {
    return [
      {
        value,
        prefix: cs,
        abbr: true,
        green: 15000,
        amber: 7000,
      },
    ];
  }
});

const contactMobilePhone = computed(() => {
  const phone = props.item.relationships?.contacts?.[0]?.mobile_phone;
  return (phone === 'null' ? null : phone) || '';
});

function openInteraction() {
  emit('openInteraction', props.item);
}

function editInteraction() {
  emit('editInteraction', props.item);
}

function deleteInteraction() {
  emit('deleteInteraction', props.item);
}

function completeInteraction() {
  emit('completeInteraction', props.item);
}
</script>

<style lang="scss">
@import '@/shared/assets/scss/_variables';
.day-card {
  display: flex;
  flex-wrap: nowrap;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: var(--spacing-3) 24% 0;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    flex-flow: column;
  }
}

.time {
  color: var(--colour-utility-black);
  margin-top: var(--spacing-half);
  background-color: var(--colour-panel-g-2);
  padding: 0 var(--spacing-2) 0 var(--spacing-1);
  min-width: 64px;
}

.week-card {
  // Override for card component to add a horizontal scroll bar for the week view.
  .c-card {
    overflow-x: auto;
  }
}
</style>
