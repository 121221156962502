/* eslint-disable no-unused-vars */
import {
  GET_FIELDS,
  CLEAR_FIELDS
} from '@/shared/store/actionType';
// import store from '@/shared/store';
import postData from '@/shared/store/data/postFields';

export const fieldsMutations = {
  [GET_FIELDS]: async (state, { post, tagProps, data, }) => {
    let dataTemp = data;
    dataTemp = dataTemp.map(stage => {
      const fields = stage.fields.map(field => {
        if (post) {
          if (field.id === 'title') {
            field.value = post.title;
          }
          if (field.id === 'text') {
            field.value = post.text;
          }
        }
        if (tagProps) {
          if (field.id === 'tags') {
            field.tagProps = tagProps;
          }
        }
        return field;
      });
      stage.fields = fields;
      return stage;
    });
    state.fields = dataTemp;
  },
  [CLEAR_FIELDS]: (state) => {
    state.fields = [];
    state.selected.loading = false;
    state.selected.loaded = true;
  },
};

export const fieldsActions = {
  [GET_FIELDS]: ({ commit, }, { post, tagProps } = {}) => {
    const data = JSON.parse(JSON.stringify(postData));
    commit(GET_FIELDS, {
      post,
      tagProps,
      data,
    });
  },
  [CLEAR_FIELDS]: async ({ commit, }) => {
    commit(CLEAR_FIELDS);
  },
};
