<template>
  <CustomCard
    purpose="reversed"
    class="details-container"
  >
    <h2>{{ name }}</h2>

    <CustomSelect
      :label="t('Viewing associations at')"
      :value="type_filter"
      :items="filters"
      @on-change="changeAssociationType"
    />

    <CustomCard
      v-for="item in items"
      :key="`${item.antecedent_code}-${item.consequent_code}`"
      purpose="reversed"
      class="item"
      footer-color="grey"
    >
      <div class="main-area">
        <div
          v-if="item.muted || !showUpdateButton"
          class="muted"
        />
        <div class="header">
          <div class="associated-products-title">
            <div class="product-title">
              <div>
                <h3>{{ item.antecedent_name }}</h3>
                <span>{{ item.antecedent_code }}</span>
              </div>
              <div class="link-wrapper">
                <div class="link">
                  <div />
                  <div />
                  <div />
                </div>
                <RagChip
                  class="percent"
                  :value="item.confidence * 100"
                  suffix="%"
                  :green="67"
                  :amber="33"
                  small
                />
              </div>
              <div>
                <h3>{{ item.consequent_name }}</h3>
                <span>{{ item.consequent_code }}</span>
              </div>
            </div>
            <div class="grow" />
          </div>
        </div>

        <div
          v-if="industryTypes !== undefined && 'data' in industryTypes && industryTypes.data.length > 0"
          class="field-container"
        >
          <Field
            :small="false"
            type="checkbox"
            :label="t('Apply to all')"
            :value="hasAllIndustryTypes(item, industryTypes.data.length)"
            @input="applyAllIndustryTypesToAssociation($event, item)"
          />

          <Field
            v-for="(industryType, index) in industryTypes.data"
            :id="industryType.industry_type_code"
            :key="industryType.industry_type_code"
            :label="industryType.industry_type_desc"
            :value="!!isIndustryTypeSelected(item, industryType)"
            :class="index > 9 && overFlowHidden.indexOf(item.rule_id) === -1 ? 'visually-hidden' : ''"
            :small="false"
            type="checkbox"
            @input="onCheckIndustryTypeInput($event, item, industryType)"
          />

          <CustomButton
            v-if="industryTypes.data.length > 10 && overFlowHidden.indexOf(item.rule_id) === -1"
            class="view-more-link"
            purpose="text"
            @click="() => overFlowHidden.push(item.rule_id)"
            @keydown.enter="() => overFlowHidden.push(item.rule_id)"
          >
            {{ t('View more') }}
          </CustomButton>
        </div>
      </div>
      <template #footer>
        <ButtonGroup>
          <CustomButton
            v-if="showDismissButton"
            small
            purpose="reversed"
            @on-click="() => setMuted(item)"
          >
            {{ t('Dismiss') }}
          </CustomButton>
          <CustomButton
            v-if="showUpdateButton && industryTypes?.data?.length"
            small
            purpose="action"
            @on-click="() => update(item)"
          >
            {{ t('Update') }}
          </CustomButton>
        </ButtonGroup>
      </template> 
    </CustomCard>

    <Pagination
      :offset="offset"
      :limit="limit"
      :records-count="items.length"
      @page-changed="changePage"
    />
  </CustomCard>
</template>

<script type="text/javascript">
import { t } from '@sales-i/utils';
import { CustomCard, RagChip, CustomButton, CustomSelect } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import Field from '@/shared/components/Form/Field.vue';
import Pagination from '@/shared/components/Pagination/Pagination.vue';
import paginationMixin from '@/shared/components/Pagination/mixins/paginationMixin';
import { mapActions, mapState } from 'vuex';
import { FETCH_REPORT_DATA, APPLY_DATE_RANGE, APPLY_AXIS } from '@/intelligence/store/actionType';
import { SET_PRODUCT_ASSOCIATION_DETAILS, ADD_INDUSTRY_TYPE_TO_ASSOCIATION, REMOVE_INDUSTRY_TYPE_FROM_ASSOCIATION } from '@/admin/store/actionType';
import { AIML_PRODUCT_TRENDS } from '@/intelligence/store/data/reportTypes';
import { product_associations_admin } from '@/shared/store/data/policies';
import { scrollIntoView } from '@/shared/directives';

export default {
  name: 'ProductsComponent',
  components: {
    CustomButton,
    CustomCard,
    Field,
    RagChip,
    ButtonGroup,
    CustomSelect,
    Pagination,
  },
  mixins: [paginationMixin],
  props: {
    name: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    industryTypes: {
      type: Object,
      default: () => {},
    },
    associationIndustryTypes: {
      type: Array,
      default: () => [],
    },
    offset: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  emits: ['changeType', 'setMuted', 'update', 'changePage'],
  data() {
    return {
      type_filter: 'item_level',
      overFlowHidden: [],
    };
  },
  computed: {
    ...mapState({
      permissions: state => state.pbac.permissionsGranted,
    }),
    showDismissButton() {
      return this.permissions[product_associations_admin] && this.permissions[product_associations_admin].delete;
    },
    showUpdateButton() {
      return this.permissions[product_associations_admin] && this.permissions[product_associations_admin].update;
    },
    query() {
      return this.$route.query;
    },
    filters() {
      return [
        {
          text: t('Item Level'),
          value: 'item_level',
        },

        {
          text: t('Group Level'),
          value: 'group_level',
        },
      ];
    },
    followUpOptions() {
      const option2 = 7;
      const option3 = 28;
      return [
        {
          text: t('Next day'),
          value: 1,
        },
        {
          text: t(`${option2} days`, 'number_days', {
            interpolations: {
              number: option2,
            },
          }),
          value: option2,
        },
        {
          text: t(`${option3} days`, 'number_days', {
            interpolations: {
              number: option3,
            },
          }),
          value: option3,
        },
      ];
    },
  },
  watch: {
    $route() {},
  },
  mounted() {},
  methods: {
    t,
    ...mapActions({
      fetchReportData: `intelligence/shared/${FETCH_REPORT_DATA}`,
      setDateRange: `intelligence/shared/${APPLY_DATE_RANGE}`,
      setAxes: `intelligence/enquiry/${APPLY_AXIS}`,

      updateProductAssociation: `admin/associations/${SET_PRODUCT_ASSOCIATION_DETAILS}`,

      addIndustryTypeToAssociation: `admin/associations/${ADD_INDUSTRY_TYPE_TO_ASSOCIATION}`,
      removeIndustryTypeFromAssociation: `admin/associations/${REMOVE_INDUSTRY_TYPE_FROM_ASSOCIATION}`,
    }),
    applyAllIndustryTypesToAssociation($event, item) {
      if ($event) {
        this.industryTypes.data.forEach(type => {
          this.removeIndustryTypeFromAssociation([item.rule_id, type]);
          return this.addIndustryTypeToAssociation([item.rule_id, type]);
        });
      } else {
        this.industryTypes.data.forEach(type => {
          this.removeIndustryTypeFromAssociation([item.rule_id, type]);
        });
      }
    },
    onCheckIndustryTypeInput($event, item, industryType) {
      if ($event) {
        return this.addIndustryTypeToAssociation([item.rule_id, industryType]);
      }

      return this.removeIndustryTypeFromAssociation([item.rule_id, industryType]);
    },
    isIndustryTypeSelected(item, industryType) {
      if (item.rule_id in this.associationIndustryTypes === false) {
        return;
      }

      return this.associationIndustryTypes[item.rule_id].findIndex(o => o.industry_type_code === industryType.industry_type_code) !== -1;
    },
    changePage(page) {
      scrollIntoView();
      this.$emit('changePage', page);
    },
    changeAssociationType(value) {
      this.type_filter = value;
      this.$emit('changeType', value);
    },
    setMuted(item) {
      this.itemToDismiss = item;
      this.showDismissModal = true;

      this.$emit('setMuted', item);
    },
    update(item) {
      this.$emit('update', item);
    },
    showInsights(item) {
      // We are showing this in line, so no emit necessary
      this.loadReportData(item);
    },
    async loadReportData(item) {
      this.processFilterParams(item);
      await this.fetchReportData({ reportType: AIML_PRODUCT_TRENDS });

      this.dataLoaded = true;
    },
    processFilterParams(item) {
      const currentDate = new Date(Date.now()).getFullYear();

      this.setDateRange({
        date_from: `${currentDate}-01-01 00:00:00`,
        date_to: `${currentDate}-12-31 00:00:00`,
      });
      this.setAxes({
        xAxis: item.antecedent_code,
        yAxis: item.consequent_code,
      });
    },
    dataHeadings() {
      return {};
    },
    getClassFor(item) {
      return `item-chart-${item.antecedent_code}-${item.consequent_code}`;
    },
    setValue(value, item, key) {
      this.updateProductAssociation({
        item,
        key,
        value,
      });
    },
    hasAllIndustryTypes(item, industryTypesCount) {
      const associationIndustryTypes = this.associationIndustryTypes[item.rule_id];
      return associationIndustryTypes?.length === industryTypesCount;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.space-filler {
  flex-grow: 1;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    display: none;
  }
}

.details-container {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  box-shadow: none;
}
.view-more-link {
  margin: 0 0 0 var(--spacing-3);
}

.details {
  margin-top: 0;
  padding: var(--spacing-3);
  background-color: var(--colour-panel-action);
  text-align: right;
}

.field-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  flex-wrap: wrap;
  padding-block-start: var(--spacing-3);
  .field {
    padding-inline: var(--spacing-half);
    margin-bottom: var(--spacing-1);
    &.visually-hidden {
      display: none;
    }
  }
}

.main-area {
  position: relative;
  .muted {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.2;
    z-index: 2;
    background-color: var(--colour-utility-black);
    border-top-left-radius: var(--border-radius-1);
    border-top-right-radius: var(--border-radius-1);
  }
}

.associated-products-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}



.product-title {
  h3 {
    font-weight: var(--font-weight-semibold);
  }
  span {
    font-size: var(--font-size-small);
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    display: flex;
  }
}

.link-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block-start: var(--spacing-1);
  padding-block-end: var(--spacing-2);
  
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding-block: var(--spacing-1);
  }

}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: var(--spacing-2);
  padding-block: var(--spacing-4);
  transform: rotate(90deg);

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding-inline: var(--spacing-2);
    padding-block-start: var(--spacing-1);
    padding-block-end: 0;
    transform: initial;
  }

  div:first-child,
  div:last-child {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: var(--colour-panel-g-16);
  }
  div:nth-child(2) {
    height: 2px;
    width: var(--spacing-6);
    background-color: var(--colour-panel-g-16);

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      width: var(--spacing-5);
    }
  }
}

.percent {
  margin-top: calc(var(--spacing-2) * 1.6);
  position: absolute;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    margin-top: var(--spacing-1);
    position: initial;
  }
}

.chart {
  margin: 0;
  padding: 0;
}
</style>
