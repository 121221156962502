import { putByIdActions, putByIdMutations } from './putCustomSection';
// initial state
const state = () => ({
  customSection : {
    data: {},
    loading: false,
    loaded: false,
    updated: false
  } 
});

// actions
const actions = {
  ...putByIdActions,
};
// mutations
const mutations = {
  ...putByIdMutations
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};