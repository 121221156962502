<template>
  <MenuFilter
    class="customer-filter"
    :items="processedCustomerList"
    :selected-value="selectedCustomer"
    menu-position="right"
    @on-change="changeCustomer"
  />
</template>

<script>
import { MenuFilter } from '@sales-i/dsv3';
import { mapState } from 'vuex';
export default {
  components: {
    MenuFilter,
  },
  props: {
    customerList: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['changeCustomer'],
  computed: {
    ...mapState({
      customer: state => state.intelligence.shared.requestParameters.snapshotBubbleValue,
    }),
    selectedCustomer() {
      return this.customer.replace(/\s/g, '');
    },
    processedCustomerList() {
      return this.customerList.map(customer => ({ ...customer, text: customer.name }));
    },
  },
  methods: {
    changeCustomer(customerObject) {
      this.$emit('changeCustomer', customerObject.text);
    },
  },
};
</script>
