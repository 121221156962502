import { FETCH_REPORT_DATA, SAVE_REPORT_DATA } from '@/intelligence/store/actionType';
import { httpRequest, dates } from '@sales-i/utils';
import querystring from 'querystring';

// initial state
const state = () => ({
  data: []
});

// actions
const actions = {
  [FETCH_REPORT_DATA]: async ({ commit }, parameters = {}) => {
    const processedParams = {
      ...parameters,
      date_from: dates.forApi(parameters.date_from, true),
      date_to: dates.forApi(parameters.date_to, true),
    };
    const params = querystring.stringify(processedParams);
    try {
      const response = await httpRequest('get', `opportunities/summary?${params}&type=MISSED&type=POTENTIAL`);
      commit(SAVE_REPORT_DATA, response);
    } catch (error) {
      console.error(error);
    }
  },
};

// mutations
const mutations = {
  [SAVE_REPORT_DATA]: (state, data) => {
    state.data = data;
    state.loading = false;
    state.error = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
  