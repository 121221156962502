<template>
  <div>
    <EnquiryNav
      class="nav"
      :report-type="reportType"
      :dates="dateParams"
      :active-area-prop="defultReportArea"
      :is-date-filter-active="isDateFilterActive"
      @toggle-date-filter="toggleDateFilter"
    />
    <div class="container">
      <div class="description">
        <i>{{ inputData.description }}</i>
      </div>
      <DatePickerView
        v-if="isDateFilterActive"
        :report-type="reportType"
        @set-active-date="setDate"
      />
      <InteractionSpendTable
        v-else-if="!isDateFilterActive && isReportEnabled && (!dataLoading || lazyLoading)"
        :key="reportId"
        :report-id="reportId"
        :report-type="reportType"
        :current-sort="currentSort"
        :sort-option="sortOption"
        @apply-new-sort="handleNewSort"
        @load-more-data="loadMoreData"
      />
      <div
        v-else
        class="buffer"
      >
        <BufferImage
          color="var(--colour-utility-black)"
          float="center"
          class="loading-spinner"
        />
      </div>
      <ReportFooter
        v-if="isReportFooterVisible"
        :report-type="reportType"
        :request-params="requestParams"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onBeforeUnmount, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { DATE_FILTER_KEYS } from '@/intelligence/store/data/dateFilters';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import DatePickerView from '@/intelligence/components/Date/DatePickerView';
import { INTERACTION_SPEND_SUMMARY } from '@/intelligence/store/data/reportTypes';
import { REPORT_AREA_PERFORMANCE } from '@/intelligence/store/data/areas';
import InteractionSpendTable from '@/intelligence/views/Insights/InteractionSpendSummary/InteractionSpendTable';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';
import { BufferImage } from '@sales-i/dsv3';

import {
  CLEAR_REPORT_DATA,
  FETCH_DATES,
  APPLY_DATE_RANGE,
  CLEAR_FILTERS,
  APPLY_SORT,
  APPLY_NEW_FILTER,
  FETCH_REPORT_DATA,
  CLEAR_ENQUIRY_FILTERS,
  SAVE_REPORT_PARAMETERS,
  RESET_REPORT_PARAMETERS,
  APPLY_OFFSET,
  REFRESH_REPORT
} from '@/intelligence/store/actionType';
import { GET_BY_ENTITY } from '@/shared/store/actionType';
import isRolldate from '@/intelligence/store/utils/isRolldate';
import { navigateToUrl } from 'single-spa';

const store = useStore();
const vroute = useRoute();
const vrouter = useRouter();

const isDateFilterActive = ref(true);
const defultReportArea = ref(REPORT_AREA_PERFORMANCE);
const reportType = ref(INTERACTION_SPEND_SUMMARY);
const isReportEnabled = ref(false);
const currentSort = ref('');
const sortOption = ref('');
const sortDescending = ref(true);
const reportId = ref(1);
const lazyLoading = ref(false);
const dates = ref({});
const fetchedDates = computed(() => store.state.intelligence.calendar.fetchedDates);
const enquiryFilters = computed(() => store.state.intelligence.enquiry.filtersApplied);
const performanceInsightFilters = computed(() => store.state.intelligence.performanceInsight.filters);
const dataLoading = computed(() => store.state.intelligence.shared.loading);
const report_type = computed(() => store.state.intelligence.performanceInsight.report_type);

const getInputData = rt => store.getters['intelligence/shared/getInputData'](rt);
const getReportData = () => store.getters['intelligence/shared/getReportData']();

const inputData = computed(() => getInputData(reportType.value));
const dateParams = computed(() => {
  const data = {};

  Object.keys(vroute.query).forEach(key => {
    if (DATE_FILTER_KEYS.includes(key)) {
      data[key] = vroute.query[key];
    }
  });

  return data;
});
const requestParams = computed(() => ({...vroute.query, 'report_type': report_type.value || 'customer'}));
const computedFilters = computed(() => {
  const {user, interaction_type} = performanceInsightFilters.value;

  return [user, interaction_type, enquiryFilters.value];
});
const reportData = computed(() => getReportData(reportId.value) || []);
const isReportFooterVisible = computed(() => isReportEnabled.value && !isDateFilterActive.value && reportData.value.length);

const fetchDates = params => store.dispatch(`intelligence/calendar/${FETCH_DATES}`, params);
const applyDate = params => store.dispatch(`intelligence/shared/${APPLY_DATE_RANGE}`, params);
const clearReportData = params => store.dispatch(`intelligence/shared/${CLEAR_REPORT_DATA}`, params);
const clearFilters = params => store.dispatch(`intelligence/performanceInsight/${CLEAR_FILTERS}`, params);
const applyNewFilter = params => store.dispatch(`intelligence/performanceInsight/${APPLY_NEW_FILTER}`, params);
const applySort = params => store.dispatch(`intelligence/performanceInsight/${APPLY_SORT}`, params);
const fetchReportData = params => store.dispatch(`intelligence/shared/${FETCH_REPORT_DATA}`, params);
const clearEnquiryFilters = params => store.dispatch(`intelligence/enquiry/${CLEAR_ENQUIRY_FILTERS}`, params);
const saveReportParameters = params => store.dispatch(`intelligence/reportLazyLoad/${SAVE_REPORT_PARAMETERS}`, params);
const resetReportParameters = params => store.dispatch(`intelligence/reportLazyLoad/${RESET_REPORT_PARAMETERS}`, params);
const applyOffset = params => store.dispatch(`intelligence/shared/${APPLY_OFFSET}`, params);
const refreshReport = params => store.dispatch(`intelligence/shared/${REFRESH_REPORT}`, params);
const getCustomSchemaFields = params => store.dispatch(`admin/fields/${GET_BY_ENTITY}`, params);

async function applyFilterFromUrl(val) {
  const filterVal = val.split(',');
  const id = filterVal[0];
  await getCustomSchemaFields({ entity: 'interactions' });
  applyNewFilter({
    type: id,
    value: filterVal.slice(1).map(n => id === 'user' ? n : Number(n)),
  });
  refreshReport();
}
async function setDate(event) {
  let newDates = event.dates || event;
  const eventValues = Object.values(newDates);

  if (eventValues.includes(null))
    return;

  if (eventValues.includes(undefined))
    newDates = dateParams.value;

  const newQuery = {
    ...newDates,
  };

  const route = vrouter.resolve({
    query: {
      ...vroute.query,
      ...newQuery
    },
  });
  navigateToUrl(route.href);

  if (isRolldate(newDates)) {
    await fetchDates(newDates);
    dates.value = fetchedDates.value;
  } else {
    dates.value = newDates;
  }

  applyDate(dates.value);
  applyOffset(0);
  resetReportParameters();
  hideDateFilter();
  loadTable();
}
function toggleDateFilter() {
  isDateFilterActive.value = !isDateFilterActive.value;
}
function hideDateFilter() {
  isDateFilterActive.value = false;
}
function handleNewSort(headerValue) {
  if (headerValue === currentSort.value) {
    sortDescending.value = !sortDescending.value;
  } else if (!currentSort.value) {
    sortDescending.value = false;
  }

  currentSort.value = headerValue;
  sortOption.value = sortDescending.value ? 'desc' : 'asc';
  updateSortRequest(headerValue, sortOption.value);
  applyOffset(0);
  resetReportParameters();
  updateTable();
}
function loadTable() {
  const headerValue = currentSort.value ? currentSort.value : inputData.value.defaultSortHeader;
  const option = sortDescending.value ? 'desc' : 'asc';

  updateSortRequest(headerValue, option);
  updateTable();
}
async function updateTable() {
  isReportEnabled.value = false;
  reportId.value = await fetchReportData({ reportType: reportType.value });
  saveReportData();
  isReportEnabled.value = true;
}
async function updateSortRequest(headerValue, optionValue) {
  await applySort({
    header: headerValue,
    option: optionValue
  });
}
async function loadMoreData() {
  lazyLoading.value = true;
  await fetchReportData({ reportType: reportType.value });
  saveReportData();
  lazyLoading.value = false;
}
function saveReportData() {
  if (!reportData.value) return;
  saveReportParameters({
    rows: [...reportData.value],
  });
}

watch(() => ({
  date_from: vroute.query.date_from,
  date_to: vroute.query.date_to
}), (to) => {
  if(!to.date_from || !to.date_to)
    isDateFilterActive.value = true;
});

watch(() => computedFilters.value, () => {
  updateTable();
});

onBeforeUnmount(() => {
  clearReportData();
  clearFilters();
  clearEnquiryFilters();
  resetReportParameters();
});
onMounted(async() => {
  const { date_from, date_to, filter } = vroute.query;

  if (filter) {
    if (Array.isArray(filter)) {
      filter.forEach(async (val, index) => {
        applyFilterFromUrl(val, index);
      });
    } else {
      applyFilterFromUrl(filter, 0);
    }
  }

  if (enquiryFilters.value.length)
    clearEnquiryFilters();


  if (date_from && date_to) {
    hideDateFilter();
    await setDate({
      date_from,
      date_to
    });
  }
});
</script>
<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.container {
  padding: 0 0 var(--spacing-2);
  background-image: linear-gradient(var(--colour-utility-white) 0 var(--spacing-3), var(--colour-panel-performance) var(--spacing-3) 100%);

   @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 0 var(--spacing-2) var(--spacing-2);
  }
}

.description {
  border-radius: var(--border-radius-1);
  display: grid;
  font-weight: var(--font-weight-semibold);
  place-content: center;
  padding: calc(var(--spacing-1) * 1.5);
  color: var(--colour-data-puerto-rico-label);
  background: var(--colour-data-puerto-rico-light);
  margin: 0 var(--spacing-2) var(--spacing-2);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin: 0 0 var(--spacing-2);
  }
}

.nav {
  &:deep(.drill-btn) {
    display: none;
  }
}

.buffer {
  background: var(--colour-panel-g-2);
  padding: var(--spacing-3) 0;
}
</style>
