import {
  SET_VISIBLE,
  CLEAR_VISIBLE
} from '@/shared/store/actionType';

// mutations
export const getSetVisibleMutations = {
  [SET_VISIBLE]: (state, data) => {
    state.showModal = true;
    state.id = data.id;
    state.entity.name = data.entity;
  },
  [CLEAR_VISIBLE]: (state) => {
    state.showModal = false;
    state.id = '';
    state.entity.name = '';
  },
};

// actions
export const getSetVisibleActions = {
  [SET_VISIBLE]: async ({ commit, }, data) => {
    commit(SET_VISIBLE, data);
  },
  [CLEAR_VISIBLE]: async ({ commit, }) => {
    commit(CLEAR_VISIBLE);
  },
};
