import { menuGetters, menuMutations, menuActions } from './menu';
import { fieldsGetters, fieldsMutations, fieldsActions } from './fields';
import { getByEntityGetters, getByEntityMutations, getByEntityActions } from './getByEntity';
import { postByEntityGetters, postByEntityMutations, postByEntityActions } from './postByEntity';
import { putByEntityMutations, putByEntityActions } from './putByEntity';
import { deleteByEntityMutations, deleteByEntityActions } from './deleteByEntity';

// initial state
const state = () => ({
  entity: '',
  loading: false,
  loading_menu: false,
  loading_section: false,
  updatesNotSaved: false,
  schema: {
    sections: []
  },
  originalSchema: {
    sections: []
  },
  newSection: {
    name: '',
    description: '',
  }
});

// getters
const getters = {
  ...menuGetters,
  ...fieldsGetters,
  ...getByEntityGetters,
  ...postByEntityGetters,
};

// mutations
const mutations = {
  ...menuMutations,
  ...fieldsMutations,
  ...getByEntityMutations,
  ...postByEntityMutations,
  ...putByEntityMutations,
  ...deleteByEntityMutations,
};

// actions
const actions = {
  ...menuActions,
  ...fieldsActions,
  ...getByEntityActions,
  ...postByEntityActions,
  ...putByEntityActions,
  ...deleteByEntityActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
