<template>
  <!-- eslint-disable vue/no-v-html -->
  <span
    class="mention"
    v-html="textWithHighlighting"
  />
</template>

<script>
import { mentionRegex } from '@/shared/regex/form';

export default {
  name: 'Mention',
  components: {},
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  computed: {
    textWithHighlighting() {
      let str = '';
      str = this.content.replace(mentionRegex, match => {
        let name = match.substr(1).split(']')[0];
        return `<span>${name}</span>`;
      });
      return str;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.mention > span {
  color: var(--colour-data-puerto-rico);
}
</style>
