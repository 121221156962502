<template>
  <TableWrapper v-if="sortedTableRows">
    <thead>
      <tr>
        <th
          v-for="(header, index) in inputData.visualInput"
          :key="header.key"
          :class="{
            'sorting-header': currentSortingHeaderIndex === index,
            'contains-chip-header': header.includesChip,
          }"
          @click="applySort(header.key, index)"
        >
          {{ header.name }}
          <IconBase
            v-if="currentSortingHeaderIndex === index"
            class="sort-icon"
            :icon-name="sortDescending ? 'arrow-down' : 'arrow-up'"
            :height="24"
            :width="24"
          />
          <p
            v-if="header.includesChip"
            class="total-chip"
          >
            {{ header.prefix ? cPrice(getTotal(header.key)) : abbrFloat(getTotal(header.key)) }}{{ header.suffix }}
          </p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(unit, trIndex) in sortedTableRows"
        :key="trIndex"
      >
        <td
          v-for="item in inputData.visualInput"
          :key="item.key"
        >
          <CustomButton
            v-if="item.key === 'name'"
            small
            purpose="text"
            :label="unit[item.key]"
            @click="redirectHandler(unit[item.key])"
          />
          <span
            v-else-if="item.key === 'potential_value'"
            :class="{ negative: parseInt(unit[item.key]) < 0 }"
          >
            {{ cPrice(unit[item.key]) }}</span>
          <span v-else>{{ unit[item.key] }}</span>
        </td>
      </tr>
    </tbody>
  </TableWrapper>
  <p
    v-else
    class="m-3 my-1"
  >
    {{ t('No data to display') }}
  </p>
</template>

<script>
import { IconBase, CustomButton } from '@sales-i/dsv3';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import { mapGetters } from 'vuex';
import { baseUrl, opportunitiesArea } from '@/crm/router/urlBits';
import { SALES_OPPORTUNITY } from '@/intelligence/store/data/reportTypes';
import { abbr, t } from '@sales-i/utils';
import useCurrency from '@/shared/composables/useCurrency';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'OpportunityTable',
  components: {
    TableWrapper,
    IconBase,
    CustomButton,
  },
  props: {
    tableData: {
      type: Object,
      required: true,
    }
  },
  setup() {
    let { cs, cPrice } = useCurrency();
    return { cs, cPrice };
  },
  data() {
    return {
      sortDescending: true,
      currentSortingHeaderIndex: '',
      sortedTableRows: '',
    };
  },
  computed: {
    ...mapGetters({
      getInputData: 'intelligence/shared/getInputData',
    }),
    inputData() {
      return this.getInputData(SALES_OPPORTUNITY);
    },
  },
  mounted() {
    if (this.inputData.defaultSortOption === 'asc') this.sortDescending = false;
    if (this.tableData) {
      this.sortedTableRows = Array.isArray(this.tableData.customers) ? [...this.tableData.customers] : '';
    }
  },
  methods: {
    t,
    abbrFloat: abbr.float,
    getTotal(key) {
      let total = 0;

      if (this.sortedTableRows) {
        this.sortedTableRows.forEach(unit => {
          total += unit[key];
        });
      }

      total = Math.round(total * 100) / 100;
      return total;
    },
    applySort(sortValue, index) {
      this.sortDescending = !this.sortDescending;
      this.currentSortingHeaderIndex = index;

      if (this.sortDescending) this.sortedTableRows.sort((a, b) => b[sortValue] - a[sortValue]);
      else this.sortedTableRows.sort((a, b) => a[sortValue] - b[sortValue]);

      if (['name', 'allocated_to'].includes(sortValue)) {
        this.sortedTableRows.sort((a, b) =>
          this.sortDescending ? a[sortValue].localeCompare(b[sortValue]) : b[sortValue].localeCompare(a[sortValue])
        );
      }
    },
    redirectHandler(customer) {
      return navigateToUrl(`${baseUrl}/${opportunitiesArea}?filter=customer&search=${customer}`);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.table-container {
  margin-top: var(--spacing-4);
}

tbody tr:first-child {
  height: var(--spacing-8);

  th,
  td {
    vertical-align: bottom;
  }
}

td {
  font-size: var(--font-size-small);
  padding: var(--spacing-1);
  line-height: var(--spacing-2);
  text-align: center;

  &:first-child {
    background: var(--colour-panel-g-2);
    border-right: 1px solid var(--colour-panel-g-8);
  }

  &:first-child,
  &:last-child {
    padding-left: var(--spacing-6);

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      padding: var(--spacing-2) var(--spacing-8);
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding-left: var(--spacing-1);
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 140px;
    }
  }
}

tr {
  background: var(--colour-panel-g-0);

  &:nth-child(even) {
    background: var(--colour-panel-g-2);
  }
}

th,
td {
  color: var(--colour-utility-black);
  padding: var(--spacing-2);
  border: none;
}

th {
  font-size: var(--font-size-4);
  background: var(--colour-panel-action);
  color: var(--colour-utility-action);
  position: sticky;
  top: 0;
  text-align: center;
  min-height: 70px;
  vertical-align: middle;
  text-decoration: underline;
  cursor: pointer;

  &.contains-chip-header {
    padding-bottom: var(--spacing-3);
  }

  &.sorting-header {
    background: var(--colour-panel-g-2);
    color: var(--colour-utility-black);
    font-weight: var(--font-weight-medium);
    text-decoration: none;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: var(--font-size-5);
    padding: var(--spacing-2) var(--spacing-1);
    min-width: 100px;
  }
}

.sort-icon {
  display: inline-block;
  position: absolute;
}

.total-chip {
  position: absolute;
  width: 120px;
  color: var(--colour-utility-black);
  background: var(--colour-panel-g-0);
  box-shadow: 0 0 var(--border-radius-half) var(--shadow-spread) var(--shadow-colour);
  font-size: var(--font-size-5);
  font-weight: 500;
  letter-spacing: 0;
  line-height: var(--spacing-3);
  padding: var(--spacing-1);
  border-radius: var(--spacing-6);
  margin: auto;
  margin-top: var(--spacing-1);
  right: 50%;
  transform: translateX(50%);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: auto;
    min-width: 80px;
  }
}

.negative {
  color: var(--colour-data-mandy-label);
}
</style>
