<template>
  <div class="filter-summary">
    <p class="title">
      {{ t('Alert parameters') }}: 
    </p>

    <div class="flow">
      <p v-if="noFilterData">
        <b>Create your alert</b>
      </p>

      <!-- Sales filters -->
      <section v-if="salesFilterData">
        <FilterSummarySection
          :is-filter-type="isSalesLink"
          :filter-link="salesLink"
        >
          <template
            v-for="(item, index) in formattedSalesFilter"
            :key="index"
          >
            <span v-if="index !== 0">{{ item.type }}</span> {{ item.field }} {{ item.operator }} {{ item.value }}&nbsp;
          </template>
        </FilterSummarySection>
      </section> 

      <!-- CRM filters -->
      <section v-if="CRMFilterData">
        <FilterSummarySection
          :is-filter-type="isCRMLink"
          :filter-link="CRMLink"
        >
          <template
            v-for="(item, index) in formattedCRMFilters"
            :key="index"
          >
            <span v-if="index !== 0">{{ item.clause }}</span> {{ item.field }} {{ item.value }}
          </template>
        </FilterSummarySection>
      </section>

      <!-- Product filters -->
      <section v-if="productFilterData">
        <FilterSummarySection
          :is-filter-type="isProductLink"
          :filter-link="productLink"
        >
          <template
            v-for="(item, index) in productFilterData"
            :key="index"
          >
            <!-- eslint-disable-next-line -->
            <template v-if="index !== 0">&nbsp;{{ item.operator }}</template>
            
            {{ index === 0 ? capitalize(item.status) : item.status }} {{ item.level }}
          </template>
        </FilterSummarySection>
      </section>
    </div>
  </div>  
</template>

<script setup>
import { computed } from 'vue';
import { t } from '@sales-i/utils';
import FilterSummarySection from '@/intelligence/components/AlertBuilder/AlertWizard/FilterSummarySection.vue';
import useFilterSummary from '@/intelligence/composables/useFilterSummary';
import { useAlertBuilder } from '@/intelligence/composables/useAlertBuilder';
import { capitalize } from '@/shared/utils/strings';

defineProps({
  selectedQueryId: {
    type: Number,
    default: 0,
  },
});

const {
  formattedSalesFilter,
  formattedCRMFilters,
  isSalesLink,
  isCRMLink,
  isProductLink,
  salesLink,
  CRMLink,
  productLink,
  salesFilterData,
  CRMFilterData,
} = useFilterSummary();

const { productFilterData } = useAlertBuilder();

const noFilterData = computed(() => !salesFilterData.value && !CRMFilterData.value && !productFilterData.value);
</script>

<style lang="scss" scoped>
  .filter-summary {
    background-color: var(--colour-panel-alert-base);
    padding-inline: var(--spacing-3);
    padding-block: var(--spacing-1);
  }

  .title {
    font-size: var(--font-size-small);
  }
</style>