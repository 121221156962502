<template>
  <div>
    <EnquiryNav
      :report-type="reportType"
      :dates="dateFilter"
      :active-area-prop="defultReportArea"
      :is-date-filter-active="isDateFilterActive"
      :is-period-filter-active="isPeriodFilterActive"
      is-bubble-selection-screen
      :is-meta-date-stamp-active="false"
      @toggle-date-filter="toggleDateFilter"
      @toggle-period-filter="togglePeriodFilter"
      @change-report="changeReport"
    />
    <div class="content">
      <div class="container">
        <DatePickerView
          v-if="isDateFilterActive"
          :report-type="reportType"
          @set-active-date="setActiveDate"
        />
        <PeriodFilter
          v-else-if="isPeriodFilterActive"
          @on-set-active-period="setActivePeriod"
        />
        <template v-else>
          <BubbleList
            v-if="allowRenderList"
            :key="bubbleListRefresher"
            :date-filter="dateFilter"
            :report-type="reportType"
            :hidden-bubble-areas="hiddenBubbleAreas"
            @change-step="changeStep"
            @go-to-report="goToReport"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import BubbleList from '@/intelligence/components/Bubble/BubbleList';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import { SET_DIMENSIONS_NUMBER } from '@/intelligence/store/actionType';
import DatePickerView from '@/intelligence/components/Date/DatePickerView';
import PeriodFilter from '@/intelligence/components/Filters/PeriodFilter';
import { mapActions, mapGetters, mapState } from 'vuex';
import { REPORT_AREA_ENQUIRY } from '@/intelligence/store/data/areas';
import { DATES_ITEM } from '@/shared/constants/storageItems';
import { storage, t } from '@sales-i/utils';

export default {
  components: {
    BubbleList,
    EnquiryNav,
    DatePickerView,
    PeriodFilter,
  },
  props: {
    reportType: {
      type: String,
      default: '',
    },
    hiddenBubbleAreas: {
      type: Array,
      default: () => [],
    },
    querySubstitute: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['goToReport', 'changeReport'],
  data() {
    return {
      dateFilter: {
        date_from: '',
        date_to: '',
      },
      bubbleListRefresher: 0,
      allowRenderList: false,
      isDateFilterActive: false,
      isPeriodFilterActive: false,
      step: 'start',
      defultReportArea: REPORT_AREA_ENQUIRY,
    };
  },
  computed: {
    ...mapState({
      iframe: state => state.system.iframe,
    }),
    ...mapGetters({
      getInputData: 'intelligence/shared/getInputData',
    }),
    inputData() {
      return this.getInputData(this.reportType);
    },
  },
  watch: {
    reportType() {
      this.setDimensionsNumber(this.inputData.urlCode);
      this.bubbleListRefresher++;
      this.isPeriodFilterActive = false;
    },
  },
  mounted() {
    this.setDimensionsNumber(this.inputData.urlCode);
    this.allowRenderList = true;
    this.initializeDates();
  },
  methods: {
    t,
    ...mapActions({
      setDimensionsNumber: `intelligence/enquiry/${SET_DIMENSIONS_NUMBER}`,
    }),
    changeStep(step) {
      this.step = step;
    },
    setActiveDate(dateInfo) {
      this.isDateFilterActive = false;
      this.dateFilter = dateInfo;
      storage.set(DATES_ITEM, dateInfo);
    },
    toggleDateFilter() {
      this.isDateFilterActive = !this.isDateFilterActive;
      this.isPeriodFilterActive = false;
    },
    setActivePeriod() {
      this.isPeriodFilterActive = false;
    },
    togglePeriodFilter() {
      this.isPeriodFilterActive = !this.isPeriodFilterActive;
      this.isDateFilterActive = false;
    },
    initializeDates() {
      const query = this.iframe ? this.querySubstitute : this.$route.query;
      const sessionStorageDates = storage.get(DATES_ITEM);
      let dates = {
        date_from: 'today/y',
        date_to: 'today',
        date_from2: 'today/y-1y',
        date_to2: 'today-1y',
      };
      if (query.date_from && query.date_to) {
        dates = query;
        storage.set(DATES_ITEM, dates);
      } else if (!this.iframe && sessionStorageDates && sessionStorageDates.date_from && sessionStorageDates.date_to) {
        dates = sessionStorageDates;
      }
      this.dateFilter = { ...dates };
    },
    goToReport(enquiryParams) {
      this.$emit('goToReport', enquiryParams);
    },
    changeReport(reportType) {
      this.$emit('changeReport', { reportType, query: this.dateFilter });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

h3 {
  font-weight: var(--font-weight-semibold);
  text-align: center;
}

.content {
  .container {
    background: var(--colour-panel-g-2);
    padding: var(--spacing-3) 0;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding: var(--spacing-3) var(--spacing-5) var(--spacing-3);
    }
  }

  .description {
    padding: var(--spacing-2);
    box-shadow: 0 0 var(--spacing-half) 1px var(--colour-panel-g-16);
    border-radius: var(--border-radius-1) var(--border-radius-1) 0 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--background-color);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding: var(--spacing-3);
    }

    &::after {
      content: ' ';
      position: absolute;
      left: 0;
      right: 0;
      background: var(--background-color);
      bottom: calc(-1 * var(--spacing-half));
      height: var(--spacing-half);
    }

    p,
    a {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-semibold);
    }

    p {
      color: var(--colour-panel-g-32);
    }

    a {
      color: var(--colour-brand-deluge);
    }
  }
}
</style>
