<template>
  <TabsAndTagsLayout
    :title="t('My interactions')"
    :show-tags="false"
    :tabs="tabs"
    :tab-selected-index="activeTabIndex"
    @tab-selected="handleTabSelected"
  >
    <template #cta>
      <div>
        <!-- New interaction button -->
        <div
          v-if="showCta"
          class="dropdown-button-wrapper"
        >
          <CustomDropdown
            v-bind="propsForMenu"
            is-button
            :label="t('Add interaction')"
            small
            :items="addInteractionActions"
            class="mt-1"
            @click="handleAction"
          />
        </div>
      </div>
    </template>
    <template #content>
      <div class="calendar-wrap">
        <!-- Show only if viewing the Calendar route -->
        <Calendar
          class="padded"
          :loading="loading"
          :events="interactionsToShow"
          :summary="summaryFormatted"
          :status="activeTab"
          :is-mobile="isMobile"
          :is-week-view="isWeekView"
          :min-date="minDateForInteractions.toJSDate()"
          :max-date="maxDateForInteractions.toJSDate()"
          @open-interaction="openInteraction"
          @edit-interaction="editInteraction"
          @delete-interaction="handleDeleteInteraction"
          @complete-interaction="completeInteraction"
          @date-changed="changeDate"
          @load-data="loadData"
          @update-month="loadSummary"
          @view-changed="handleViewChanged"
        />
      </div>
      <router-view />
    </template>
  </TabsAndTagsLayout>
  <Teleport to="#modal-teleport-target">
    <CompleteInteractionModal
      v-if="completeInteractionModal"
      :interaction-id="selectedInteractionId"
      @close-modal="closeModal"
      @completed="completed"
    />
  </Teleport>
</template>

<script setup>
// IMPORTS
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';
import Calendar from '@/crm/components/Calendar/Calendar.vue';
import TabsAndTagsLayout from '@/shared/components/Layout/TabsAndTagsLayout.vue';
import { CustomDropdown } from '@sales-i/dsv3';
import { baseUrl as crmBaseUrl, interactionsArea } from '@/crm/router/urlBits';
import usePermissions from '@/shared/composables/usePermissions';
import { interactions } from '@/shared/store/data/policies';
import { dates, t } from '@sales-i/utils';
import { DateTime } from 'luxon';
import { useInteraction } from '@/crm/composables/useInteraction';
import CompleteInteractionModal from '@/crm/views/Interactions/CompleteInteractionModal.vue';
import { navigateToUrl } from 'single-spa';
import { useInteractionsSummary } from '@/crm/composables/useInteractionsSummary';

// VARIABLES
const store = useStore();
const vroute = useRoute();

// Permissions
const { hasAccess } = usePermissions();
const showCta = computed(() => hasAccess(interactions, 'create'));

// Computed
const currentUserId = computed(() => store.state.userDetails.data.id);

// Tabs
const TAB_OPEN = 'open',
  TAB_MISSED = 'missed',
  TAB_COMPLETED = 'complete';

const tabs = computed(() => [
  {
    title: t(`Open (${openCount.value})`, 'open_count', { count: openCount.value }),
    value: TAB_OPEN,
  },
  {
    title: t(`Missed (${missedCount.value})`, 'missed_count', { count: missedCount.value }),
    value: TAB_MISSED,
  },
  {
    title: t(`Completed (${completedCount.value})`, 'completed_count', { count: completedCount.value }),
    value: TAB_COMPLETED,
  },
]);

// Complete interaction modal
const completeInteractionModal = ref(false);
const selectedInteractionId = ref(undefined);
const isWeekView = ref(false);

const TABS = [TAB_OPEN, TAB_MISSED, TAB_COMPLETED];
const activeTab = computed(() => (vroute.query.tab || TAB_OPEN).toLowerCase());
const activeTabIndex = computed(() => TABS.indexOf(activeTab.value));

const isMobile = computed(() => store.state.platform.data === 'mobile');

function handleTabSelected({ item }) {
  const { value } = item;

  if ([TAB_MISSED, TAB_COMPLETED].includes(value)) isWeekView.value = false;

  const query = new URLSearchParams({ ...vroute.query, tab: value });
  navigateToUrl(`${vroute.path}?${query.toString()}`);
}

const minDateForInteractions = computed(() => DateTime.now().minus({years: 4}));
const maxDateForInteractions = computed(() => DateTime.now().plus({years: 1}));

const dateFrom = ref(DateTime.local().startOf('month'));
const dateTo = ref(DateTime.local().endOf('month'));
const LIMIT = 1000;

const addInteractionActions = [
  {
    title: t('New interaction'),
    href: `${crmBaseUrl}/${interactionsArea}/add`,
  },
  {
    title: t('New personal interaction'),
    href: `${crmBaseUrl}/${interactionsArea}/add-personal`,
  },
  {
    title: t('Generate prospect interactions'),
    href: `${crmBaseUrl}/${interactionsArea}/generate-prospect-interaction`,
    entity: 'prospect',
  },
  {
    title: t('Generate customer interactions'),
    href: `${crmBaseUrl}/${interactionsArea}/generate-customer-interaction`,
    entity: 'customer',
  },
];

const propsForMenu = {
  iconColor: 'var(--colour-utility-white)',
  iconWidth: 32,
  iconHeight: 32,
  right: true,
  width: 180,
  items: [
    {
      title: '',
    },
  ],
  closeOnSelection: true,
};

watch(
  () => ({ path: vroute.path, tab: vroute.query.tab }),
  (to, from) => (to.path !== from.path || to.tab !== from.tab) && loadData()
);

// Lifecycle hooks
onMounted(() => {
  loadData();
});

onUnmounted(() => {
  clearInteractions();
});

const {
  openInteractions,
  completedInteractions,
  clearInteractions,
  getInteractions,
  loading,
  selected,
  showConfirmationModal,
  deleteInteraction,
  setDate,
  setRelatedInteraction,
  openInteraction,
  editInteraction,
  postInteraction
} = useInteraction({ store, vroute });

const { summaryFormatted, openCount, missedCount, completedCount, getSummary } = useInteractionsSummary({ store });

const interactionsToShow = computed(() => activeTab.value === TAB_COMPLETED 
  ? completedInteractions.value 
  : openInteractions.value);

function handleAction(item) {
  navigateToUrl(item.href);
}

function loadData() {
  getInteractions({
    date_from: dateFrom.value.toISO(),
    date_to: dateTo.value.toISO(),
    limit: LIMIT,
    status: activeTab.value,
    user_id: currentUserId.value
  });
  loadSummary({
    dateFrom: dateFrom.value.toISO(),
    dateTo: dateTo.value.toISO(),
  });
}

function loadSummary(datesObj = false) {
  let monthStart, monthEnd;
  if (datesObj) {
    monthStart = dates.getDateTime(datesObj.dateFrom);
    monthEnd = dates.getDateTime(datesObj.dateTo);
  } else {
    monthStart = dateFrom.value.startOf('month');
    monthEnd = dateTo.value.endOf('month');
  }

  if (monthEnd < monthStart) {
    monthEnd = monthStart;
    monthEnd.plus({
      month: 1,
    });
  }

  if (monthStart < minDateForInteractions.value) {
    monthStart = minDateForInteractions.value;
  }
  if (monthStart > maxDateForInteractions.value) {
    monthStart = maxDateForInteractions.value.minus({month: 1});
  }

  if (monthEnd < monthStart) {
    monthEnd = monthStart.plus({month: 1});
  }

  if (monthEnd > maxDateForInteractions.value) {
    monthEnd = maxDateForInteractions.value;
  }

  getSummary({
    date_from: monthStart.toISO(),
    date_to: monthEnd.toISO(),
    days_breakdown: true,
    user_id: currentUserId.value
  });
}

function changeDate(value) {
  dateFrom.value = dates.getDateTime(value.dateFrom).startOf('month');
  dateTo.value = dates.getDateTime(value.dateTo).endOf('month');
  loadData();
}

function completeInteraction(event) {
  completeInteractionModal.value = true;
  selectedInteractionId.value = event.id;
}

async function completed(timescale) {
  if (timescale.date && selectedInteractionId.value) {
    await setDate(timescale);
    await setRelatedInteraction(vroute.params.id);
    await postInteraction();
    const { data } = selected.value;
    navigateToUrl(`${crmBaseUrl}/${interactionsArea}/${data.id}`);
  } else {
    navigateToUrl(`${crmBaseUrl}/${interactionsArea}`);
  }
  loadData();
}

function closeModal() {
  completeInteractionModal.value = false;
  selectedInteractionId.value = undefined;
}

function handleDeleteInteraction(item) {
  showConfirmationModal({
    message: t(`Do you really want to delete Interaction #${item.id}`, 'do_you_really_want_to_delete_interaction_id', {
      interpolations: {
        id: item.id,
      },
    }),
    updatedMessage: t(`You have deleted Interaction #${item.id}`, 'you_have_deleted_interaction_id', {
      interpolations: {
        id: item.id,
      },
    }),
    updateFunction: async () => {
      try {
        await deleteInteraction(item.id);
        if (dateFrom.value && dateTo.value) loadData();
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  });
}

function handleViewChanged(view) {
  isWeekView.value = view;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

h1 {
  text-align: center;
}

.calendar-wrap {
  background-color: var(--colour-panel-g-2);
  flex: 1;
}

// Temporary overrides for the dropdown menu
.list {
  left: 30px;
}
.export-dropdown-items {
  // min-width: 200px;
  padding: 0 var(--spacing-2);

  ul {
    list-style: none;
  }

  li {
    text-align: left;
    padding: var(--spacing-1) 0;
  }

  a {
    font-weight: var(--font-weight-semibold);
  }
}

.dropdown-container > *:last-child {
  display: none;
}

// Styles for both calendars
.dropdown-button-wrapper {
  :deep(.button.round) {
    height: var(--iconButtonHeight);
    width: var(--iconButtonWidth);
  }
}
</style>
