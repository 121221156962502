import {
  GET_ALL,
  GET_ALL_LOADING,
  GET_ALL_ERROR,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

export const getAllMutations = {
  [GET_ALL]: (state, sections) => {
    state.sections = sections;
    state.loading = false;
    state.loaded = true;
    state.errors = [];
  },
  [GET_ALL_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.errors = [];
  },
  [GET_ALL_ERROR]: (state, errors) => {
    state.loading = false;
    state.loaded = true;
    state.errors = errors;
  },
};

export const getAllActions = {
  [GET_ALL]: async ({ commit }, type) => {
    commit(GET_ALL_LOADING);
    try {
      const response = await httpRequest('get', `config/schema/${type}`, {}, {
        area: 'crm',
        version: '2',
      });

      commit(GET_ALL, response.sections);
    } catch (e) {
      commit(GET_ALL_ERROR, [e]);
    }
  },
};