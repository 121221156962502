// import { consolidateTagsMutations, consolidateTagsActions } from './putConsolidateTags';
import { tags } from '@/shared/store/data/policies';
import { deleteByEntityMutations, deleteByEntityActions } from './deleteByEntity';
import { getAllMutations, getAllActions } from './getAll';
// import { getReportMutations, getReportActions } from './getReport';
import { getByEntityMutations, getByEntityActions } from './getByEntity';
import { getSetVisibleMutations, getSetVisibleActions } from './getSetVisible';
import { postByEntityMutations, postByEntityActions } from './postByEntity';

// initial state
const state = () => ({
  permissionSet: tags,
  litmus: false,
  showModal: false,
  id: '',
  all: {
    data: [],
    loaded: false,
    loading: false,
  },
  entity: {
    name: '',
    data: [],
    loaded: false,
    loading: false,
  },
  // report: {
  //   data: [],
  //   loaded: false,
  //   loading: false,
  // },
  // consolidateTags: {
  //   original_tag: [],
  //   to_tag: [],
  //   loaded: false,
  //   loading: false,
  // },
  // putConsolidateTags: {
  //   data: [],
  //   loaded: false,
  //   loading: false,
  // }
});

// getters
const getters = {
};

// mutations
const mutations = {
  'LITMUS_TEST': (state) => {
    state.litmus = true;
  },
  // ...consolidateTagsMutations,
  ...deleteByEntityMutations,
  ...getAllMutations,
  ...getByEntityMutations,
  ...getSetVisibleMutations,
  ...postByEntityMutations,
  // ...getReportMutations
};

// actions
const actions = {
  'LITMUS_TEST': ({ commit, }) => {
    commit('LITMUS_TEST');
  },
  // ...consolidateTagsActions,
  ...deleteByEntityActions,
  ...getAllActions,
  ...getByEntityActions,
  ...getSetVisibleActions,
  ...postByEntityActions,
  // ...getReportActions
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
