<template>
  <div class="snapshot-summary-container flow">
    <!-- Product group purchase analysis -->
    <section
      v-if="canAccessProductGroupPurchaseAnalysis"
      class="analysis-chart-container"
      :class="{ 'no-results': !isPurchaseAnalysisDataAvailable }"
    >
      <div v-if="!analysisGraphLoading && isPurchaseAnalysisDataAvailable">
        <h2>{{ t('Purchase Analysis') }}</h2>
        <LineBarChart
          :report-id="purchaseAnalysisId"
          class="purchase-analysis-chart"
        />
      </div>
      <NoResults v-if="!analysisGraphLoading && !isPurchaseAnalysisDataAvailable" />
      <BufferImage
        v-if="analysisGraphLoading"
        color="black"
        float="center"
        class="loading-spinner"
      />
    </section>

    <!-- Interaction history -->
    <InteractionHistory
      v-if="canAccessInteractions"
      :customer-object="customerObject"
      :last-interaction="lastInteraction"
      :missed-opportunities="missedOpportunities"
    />

    <!-- Trends -->
    <section
      v-if="canAccessTrends"
      class="trends-container"
      :class="{ 'no-results': !isTrendsDataAvailable }"
    >
      <div v-if="!trendsGraphLoading && isTrendsDataAvailable">
        <h2>{{ t('Performance') }}</h2>
        <TrendsChart :report-id="trendsId" />
      </div>
      <NoResults v-if="!trendsGraphLoading && !isTrendsDataAvailable" />
      <BufferImage
        v-if="trendsGraphLoading"
        color="black"
        float="center"
        class="loading-spinner"
      />
    </section>

    <!-- Potential opportunities -->
    <div
      v-if="canAccessPotentialOpportunities"
      class="potential-container"
    >
      <h2>{{ t('Potential') }}</h2>
      <p>
        {{ potentialOpportunitiesNumber }}
        {{ t('Selling suggestions for this interaction') }}
      </p>
      <SummaryOpportunityCard
        v-for="(item, index) in potentialOpportunities"
        :key="index"
        :item="item"
        :title="`${t('You could sell')}:`"
        class="opportunity-card"
      >
        <template #info>
          <TogglableProductsLabel
            :opportunity-item="item"
            :opportunity-type="'POTENTIAL'"
            enable-product-code
          />
        </template>
      </SummaryOpportunityCard>
    </div>

    <ReportFooter
      class="report-footer"
      :report-type="CUSTOMER_SNAPSHOT"
      :interaction-id="customerObject.interactionId"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import { BufferImage } from '@sales-i/dsv3';
import { GET_ALL } from '@/shared/store/actionType';
import {
  FETCH_LAST_INVOICE,
  FETCH_BUBBLE_DATA,
  FETCH_REPORT_DATA,
  SAVE_BUBBLE_DATA,
} from '@/intelligence/store/actionType';
import LineBarChart from '@/intelligence/components/SnapshotReport/Charts/LineBarChart';
import InteractionHistory from '@/intelligence/components/SnapshotReport/Summary/InteractionHistory';
import SummaryOpportunityCard from '@/intelligence/components/SnapshotReport/Summary/SummaryOpportunityCard.vue';
import TrendsChart from '@/intelligence/components/Trends/TrendsChart';
import { CUSTOMER_SNAPSHOT, PRODUCT_GROUP_PURCHASE_ANALYSIS, TRENDS } from '@/intelligence/store/data/reportTypes';
import NoResults from '@/intelligence/components/Shared/NoResults';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';
import TogglableProductsLabel from '@/intelligence/components/SnapshotReport/TogglableProductsLabel.vue';
import usePermissions from '@/shared/composables/usePermissions';
import {
  missed_potential_opportunities,
  interactions,
  intelligence_products,
  intelligence_enquiries
} from '@/shared/store/data/policies';
import { DateTime } from 'luxon';

const store = useStore();
// Permissions
const { hasAccess } = usePermissions();

const props = defineProps({
  customerObject: {
    type: Object,
    default: () => ({}),
  },
});

const missedOpportunities = ref([]);
const trendsGraphLoading = ref(true);
const potentialOpportunities = ref([]);
const lastInteraction = ref({});
const analysisGraphLoading = ref(true);
const isPurchaseAnalysisDataAvailable = ref(false);
const isTrendsDataAvailable = ref(false);
const trendsId = ref(null);
const purchaseAnalysisId = ref(null);

// Map store state
const selectedCustomer = computed(() => store.state.intelligence.shared.requestParameters.snapshotBubbleValue);
const interactionsItems = computed(() => store.state.crm.interactions.all.data);
const requestParams = computed(() => store.state.intelligence.shared.requestParameters);

// Map store getters
const getSortedOpportunitiesByDecisionDate = computed(
  () => store.getters['intelligence/opportunities/getSortedOpportunitiesByDecisionDate']
);
const getReportData = computed(() => store.getters['intelligence/shared/getReportData']);
const canAccessTrends = computed(() => hasAccess(intelligence_enquiries));
const canAccessProductGroupPurchaseAnalysis = computed(() => hasAccess(intelligence_products));
const canAccessInteractions = computed(() => hasAccess(interactions));
const canAccessPotentialOpportunities = computed(() => hasAccess(missed_potential_opportunities));
const potentialOpportunitiesNumber = computed(() => potentialOpportunities.value.length);

onMounted(async () => {
  if (!requestParams.value.productBubbleId) await fetchBubbleIds();
  saveBubbleData('customer');

  fetchLastInteraction();
  fetchLastInvoice({ customer_id: props.customerObject.id });
  fetchOpportunities();

  renderAnalysisChart();
  renderTrendsChart();
});

// Map store actions
const fetchLastInvoice = customer => store.dispatch(`intelligence/lastInvoice/${FETCH_LAST_INVOICE}`, customer);
const getOpportunities = payload => store.dispatch(`intelligence/opportunities/${GET_ALL}`, payload);
const fetchReportData = type => store.dispatch(`intelligence/shared/${FETCH_REPORT_DATA}`, type);
const getInteractions = parameters => store.dispatch(`crm/interactions/${GET_ALL}`, { parameters });
const fetchBubbleIds = () => store.dispatch(`intelligence/templateReport/${FETCH_BUBBLE_DATA}`);
const saveBubbleData = params => store.dispatch(`intelligence/templateReport/${SAVE_BUBBLE_DATA}`, params);

async function fetchLastInteraction() {
  // If the user doesn't have access to the interactions, don't fetch the data when the page loads
  if (!canAccessInteractions.value) return;
  const entity_type = 'customer';
  const entity_id = props.customerObject.id;
  const limit = 1;
  const sort = 'date_start:asc';
  const date_from = DateTime.local().startOf('day').toISO();
  const date_to = DateTime.local().endOf('day').toISO();
  await getInteractions({ limit, sort, entity_type, entity_id, date_from, date_to });
  lastInteraction.value = interactionsItems.value.length ? interactionsItems.value[0] : {};
}

async function fetchOpportunities() {
  await getOpportunities({
    type: 'MISSED',
    sort: 'decision_date:desc',
    customer: selectedCustomer,
  });
  missedOpportunities.value = getSortedOpportunitiesByDecisionDate.value
    .filter(el => el.entity_name === selectedCustomer.value)
    .slice(0, 3);
  await getOpportunities({
    type: 'POTENTIAL',
    sort: 'value:desc',
    customer: selectedCustomer,
  });
  potentialOpportunities.value = getSortedOpportunitiesByDecisionDate.value
    .filter(el => el.entity_name === selectedCustomer.value)
    .slice(0, 3);
}

function getPurchaseAnalysisAvailability() {
  const reportData = getReportData.value(purchaseAnalysisId.value) || [];
  return reportData.length;
}

function getTrendsAvailability() {
  const reportData = getReportData.value(trendsId.value) || null;
  return reportData && reportData.rows.some(row => row.value_1 !== 0 || row.value_2 !== 0 || row.value_variance !== 0);
}

async function renderAnalysisChart() {
  purchaseAnalysisId.value = await fetchReportData({
    reportType: PRODUCT_GROUP_PURCHASE_ANALYSIS,
    customParams: { 
      snapshotBubbleValueId: [`"${props.customerObject.account_number}"`]
    },
  });
  analysisGraphLoading.value = false;
  isPurchaseAnalysisDataAvailable.value = getPurchaseAnalysisAvailability();
}

async function renderTrendsChart() {
  if (canAccessTrends.value) {
    trendsId.value = await fetchReportData({ reportType: TRENDS });
  } else {
    trendsId.value = null;
  }

  trendsGraphLoading.value = false;
  isTrendsDataAvailable.value = getTrendsAvailability();
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.snapshot-summary-container {
  position: relative;
  margin: 0 auto;
  max-width: 900px;
}

.report-footer {
  margin-top: 0;
}
.loading-spinner {
  position: absolute;
  top: calc(50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

section {
  position: relative;
}

h2 {
  text-align: center;
  font-size: var(--font-size-3);
}

p {
  text-align: center;
  font-size: var(--font-size-small);
  margin-bottom: var(--spacing-2);
}

.analysis-chart-container,
.trends-container,
.potential-container {
  width: 100%;
  border-radius: var(--border-radius-1);
  position: relative;
  background: var(--colour-panel-g-0);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
  padding: var(--spacing-2) var(--spacing-1);
}

.no-results {
  padding: 0;
  min-height: 350px;
}

.analysis-chart-container {
  margin-top: 0;
}

.heading-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: var(--spacing-1);
  overflow: hidden;
  margin-bottom: var(--spacing-4);
}

.purchase-analysis-chart {
  margin-bottom: 0;
}

.trends-chart-container {
  margin-top: var(--spacing-2);
  margin-bottom: 0;
}

.potential-container {
  padding-left: 0;
  padding-right: 0;

  .opportunity-card {
    border-radius: 0;
    box-shadow: none;

    :deep(.card-title) {
      font-size: var(--font-size-4);
      font-weight: var(--font-weight-regular);
      margin-bottom: var(--spacing-1);
    }

    strong {
      margin-left: var(--spacing-2);
    }
  }
}
</style>
