<template>
  <div
    v-if="inputData && inputData.tableComponent" 
    class="snapshot-report"
    :class="[{ expanded }, expandedDataView, inputData.tableComponent.toLowerCase()]"
  >
    <BufferImage
      v-if="!isReportLoaded"
      color="black"
      float="center"
      class="loading-spinner"
    />
    <NoResults v-if="!isReportDataAvailable && isReportLoaded" />
    <template v-if="isReportDataAvailable">
      <section class="header-section">
        <h3>{{ inputData.title }}</h3>
        <DateSubHeader :date-range-number="inputData.dateRangeNumber" />
        <div class="container-wrapper">
          <FullScreenButton
            enable-switch
            :visible-data-type="expandedDataView"
            @full-screen-data-type-change="switchDataView"
          />
        </div>
      </section>
      <section class="chart-section">
        <component
          :is="inputData.chartComponent"
          :report-id="reportId"
          :report-type="reportType"
        />
      </section>
      <section class="data-section">
        <component
          :is="inputData.tableComponent"
          v-if="isReportLoaded"
          :report-id="reportId"
          :report-type="reportType"
          :enquiry-data="enquiryData"
          :is-quantity-type="isQuantityReport"
          is-snapshot
          @load-more-data="loadMoreData"
          @save-parameters="saveReportData"
          @apply-new-sort="applyNewSort"
        />
      </section>
      <ReportFooter
        :report-type="reportType"
        report-description="Example description for each snapshot report"
        :report-area="snapshotType"
        :interaction-id="customerObject.interactionId"
      />
    </template>
  </div>
</template>

<script>
import { BufferImage } from '@sales-i/dsv3';
import TemplateReportTable from '@/intelligence/components/TemplateReport/TemplateReportTable';
import TrendsTable from '@/intelligence/components/Trends/TrendsTable';
import VarianceTable from '@/intelligence/components/Variance/VarianceTable';
import MatrixTable from '@/intelligence/components/Matrix/MatrixTable';
import PieChart from '@/intelligence/components/SnapshotReport/Charts/PieChart';
import MultiBarChart from '@/intelligence/components/SnapshotReport/Charts/MultiBarChart';
import LineBarChart from '@/intelligence/components/SnapshotReport/Charts/LineBarChart';
import DateSubHeader from '@/intelligence/components/SnapshotReport/DateSubHeader';
import { FETCH_REPORT_DATA, FETCH_BUBBLE_DATA, SAVE_BUBBLE_DATA } from '@/intelligence/store/actionType';
import {
  MONTHLY_PURCHASES_BY_QUANTITY,
  MONTHLY_PURCHASES_BY_VALUE,
  PURCHASES_VS_GAPS_BY_PURCHASE_QUANTITY,
  PURCHASES_VS_GAPS_BY_PURCHASE_VALUE,
  SNAPSHOT_REPORT_LIST,
  VARIANCE_ENQUIRY_BY_PURCHASE_VALUE,
  VARIANCE_ENQUIRY_BY_QUANTITY,
  PRODUCT_GROUP_PURCHASE_ANALYSIS
} from '@/intelligence/store/data/reportTypes';
import { mapActions, mapGetters, mapState } from 'vuex';
import FullScreenButton from '@/intelligence/components/Shared/FullScreenButton';
import NoResults from '@/intelligence/components/Shared/NoResults';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';

export default {
  components: {
    BufferImage,
    TemplateReportTable,
    FullScreenButton,
    DateSubHeader,
    TrendsTable,
    VarianceTable,
    MatrixTable,
    PieChart,
    LineBarChart,
    MultiBarChart,
    NoResults,
    ReportFooter,
  },
  props: {
    reportType: {
      type: String,
      default: '',
      validator: function (value) {
        return SNAPSHOT_REPORT_LIST.indexOf(value) !== -1;
      },
    },
    customerObject: {
      type: Object,
      default: () => ({}),
    },
    snapshotType: {
      type: String,
      default: 'customer',
    },
  },
  data() {
    return {
      expandedDataView: 'data',
      isReportLoaded: false,
      reportId: 1,
      lazyLoadingData: [],
      currentSortHeader: '',
      currentSortOption: 'asc',
    };
  },
  computed: {
    ...mapState({
      expanded: state => state.intelligence.shared.isReportExpanded,
      requestParams: state => state.intelligence.shared.requestParameters,
    }),
    ...mapGetters({
      getReportData: 'intelligence/shared/getReportData',
      getInputData: 'intelligence/shared/getInputData',
    }),
    inputData() {
      return this.getInputData(this.reportType);
    },
    enquiryData() {
      const reportData = { ...this.getReportData(this.reportId) } || null;
      if (this.lazyLoadingData.length && reportData && this.isVarianceBasedReport) {
        for (const iteration of this.lazyLoadingData) {
          const { row_count, rows } = iteration;
          reportData.row_count += row_count;
          reportData.rows = reportData.rows.concat(rows);
        }
      }
      return reportData;
    },
    isReportDataAvailable() {
      const reportData = this.getReportData(this.reportId) || null;
      switch (this.reportType) {
      case MONTHLY_PURCHASES_BY_VALUE:
      case MONTHLY_PURCHASES_BY_QUANTITY:
        return reportData && reportData.rows && reportData.rows.some(row => row.value_1 !== 0 || row.value_2 !== 0 || row.value_variance !== 0);
      case VARIANCE_ENQUIRY_BY_QUANTITY:
      case VARIANCE_ENQUIRY_BY_PURCHASE_VALUE:
        return reportData && reportData.row_count;
      case PURCHASES_VS_GAPS_BY_PURCHASE_VALUE:
      case PURCHASES_VS_GAPS_BY_PURCHASE_QUANTITY:
        return reportData && reportData.rows && reportData.rows.length;
      default:
        return reportData && reportData.length;
      }
    },
    isVarianceBasedReport() {
      return [VARIANCE_ENQUIRY_BY_QUANTITY, VARIANCE_ENQUIRY_BY_PURCHASE_VALUE].includes(this.reportType);
    },
    isQuantityReport() {
      return [VARIANCE_ENQUIRY_BY_QUANTITY, MONTHLY_PURCHASES_BY_QUANTITY, PURCHASES_VS_GAPS_BY_PURCHASE_QUANTITY].includes(this.reportType);
    },
  },
  async mounted() {
    if (!this.requestParams.productBubbleId || !this.requestParams.transactionBubbleId) await this.fetchBubbleIds();
    this.saveBubbleData(this.snapshotType);
    await this.loadReport();
    this.isReportLoaded = true;
  },
  methods: {
    ...mapActions({
      fetchReportData: `intelligence/shared/${FETCH_REPORT_DATA}`,
      fetchBubbleIds: `intelligence/templateReport/${FETCH_BUBBLE_DATA}`,
      saveBubbleData: `intelligence/templateReport/${SAVE_BUBBLE_DATA}`,
    }),
    switchDataView(dataType) {
      this.expandedDataView = dataType;
    },
    async loadReport(customParams = {}) {
      if (this.reportType === PRODUCT_GROUP_PURCHASE_ANALYSIS) {
        this.reportId = await this.fetchReportData({
          reportType: this.reportType,
          customParams: {
            ...customParams,
            snapshotBubbleValueId: [`"${this.customerObject.account_number}"`]
          }
        });
      } 
      else if(this.reportType === MONTHLY_PURCHASES_BY_VALUE) { 
        this.reportId = await this.fetchReportData({
          reportType: this.reportType,
          customParams: {
            ...customParams,
            snapshotBubbleValue: [`"${this.customerObject.name}"`]
          }
        });
      }
      else {
        this.reportId = await this.fetchReportData({
          reportType: this.reportType,
          customParams: {
            ...customParams,
          },
        });
      }
    },
    async loadMoreData() {
      const lazyLoadId = await this.fetchReportData({ reportType: this.reportType });
      this.lazyLoadingData.push(this.getReportData(lazyLoadId));
    },
    async applyNewSort(headerKey) {
      if (this.currentSortHeader === headerKey) {
        this.currentSortOption = (this.currentSortOption === 'desc' ? 'asc' : 'desc');
      } else {
        this.currentSortHeader = headerKey;
        this.currentSortOption = 'desc';
      }

      this.isReportLoaded = false;
      this.reportId = await this.loadReport({
        currentSortHeader: headerKey,
        currentSortOption: this.currentSortOption,
      });
      this.isReportLoaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.snapshot-report {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  border-radius: var(--border-radius-1);
  position: relative;
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 350px;

  &.variancetable {
    .data-section {
      display: table;
    }
  }

  .data-section {
    &.matrixtable {
      display: flex;
      overflow: hidden;
      flex: auto;
    }

    &.trendstable,
    &.templatereporttable {
      display: block;
      overflow: auto;
    }
  }
}

.header-section {
  position: relative;
  margin-bottom: var(--spacing-5);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-bottom: var(--spacing-3);
    margin-top: var(--spacing-8);
  }

  h3 {
    text-align: center;
    padding: var(--spacing-4) var(--spacing-6);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-3);
    max-width: 560px;
    margin: auto;
    padding-bottom: 0;

    @media #{map-get($display-breakpoints, 'md-and-down')} {
      font-size: var(--font-size-3);
      padding: var(--spacing-4);
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: var(--font-size-4);
      padding: var(--spacing-2);
      padding-bottom: 0;
      max-width: 400px;
    }
  }
}

.export-dropdown-wrapper {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    top: -48px;
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

.container-wrapper {
  position: absolute;
  display: flex;
  gap: var(--spacing-1);
  top: var(--spacing-5);
  right: var(--spacing-2);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    top: var(--spacing-3);
    right: var(--spacing-3);
  }
}
</style>
<style lang="scss">
@import '@/shared/assets/scss/_variables';
.snapshot-report {
  .filters {
    display: flex;
    margin-bottom: var(--spacing-3);
  }

  &.expanded {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: white;
    max-width: 100%;
    height: 100%;

    &.data {
      .chart-section {
        display: none;
      }
    }

    &.graph {
      .data-section {
        display: none;
      }
      .chart-section {
        min-height: unset;
        height: calc(100vh - 176px);
        overflow: auto;
        margin: 0;
      }
    }

    .data-section {
      height: calc(100% - 162px);
      overflow: hidden;
    }

    .table-wrapper {
      max-height: 100%;
    }

    &.variancetable {
      .header-section {
        margin-bottom: var(--spacing-1);
      }

      .data-section,
      .table-wrapper {
        height: calc(100vh - 178px);
      }

      .table-wrapper {
        .rows {
          height: 100%;
          max-height: unset;
        }
      }
    }

    &.matrixtable {
      .data-section {
        height: calc(100% - 162px);
        overflow: hidden;
      }
    }

    .export-dropdown-wrapper {
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        display: none;
      }
    }
  }
}
</style>
