<template>
  <div class="tooltip-wrapper">
    <slot />
    <span
      :ref="ref"
      class="tooltiptext"
      :style="{ ...textStyle, ...widthStyle, ...maxWidthStyle }"
    >
      <slot name="text">
        {{ text }}
      </slot>
    </span>
    <span class="pin" />
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 120,
    },
  },
  data() {
    return {
      ref: 'tt',
      bounds: {},
      widthStyle: {
        width: `${this.width}px`,
      },
      textStyle: {
        left: '50%',
        'margin-left': `-${this.width / 2}px`,
      },
    };
  },
  computed: {
    maxWidthStyle() {
      return {
        'max-width': `${this.maxWidth}px`,
      };
    },
  },
  maxWidth() {
    return document.body.clientWidth - 64;
  },
  mounted() {
    this.bounds = this.$refs[this.ref].getBoundingClientRect();
    if (this.bounds.left - this.bounds.width / 2 < 0) {
      this.textStyle = {
        left: '0%',
        'margin-left': 0,
      };
    } else if (this.bounds.left + this.bounds.width / 2 > document.body.clientWidth - 64) {
      this.textStyle = {
        right: '-50%',
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip-wrapper .tooltiptext {
  opacity: 0;
  transition: opacity 1s;
  visibility: hidden;
  background-color: black;
  color: var(--colour-utility-white);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  line-height: 1rem;
  z-index: 999;
  top: 100%;
}

.tooltip-wrapper .pin {
  content: '';
  opacity: 0;
  transition: opacity 1s;
  visibility: hidden;
  position: absolute;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
  bottom: 0%; /* At the top of the tooltip */
  left: 50%;
  z-index: 999;
}

.tooltip-wrapper:hover .tooltiptext,
.tooltip-wrapper:hover .pin {
  visibility: visible;
  opacity: 1;
}
</style>
