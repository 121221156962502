<template>
  <CustomerOverview
    v-if="dateParams"
    is-tenant-overview
    class="customer-overview"
    :dates="dates"
    :date-params="dateParams"
    @date-updated="updateDates"
  />
</template>

<script setup>
// SalesOverviewContent
import CustomerOverview from '@/crm/views/Customers/CustomerOverview.vue';
import { useDates } from '@/intelligence/composables/useDates';

const { dates, dateParams, updateDates } = useDates({ updateDatesOnMount: true });
</script>
