<template>
  <div>
    <EnquiryNav
      :active-area-prop="reportArea"
      :report-type="reportType"
      :dates="dateFilter"
      :hide-filters="!containsFilterButton"
      hide-drill
      hide-date-filter
    />
    <!-- DEMO -->
    <template v-if="vroute.name === 'market-penetration'">
      <img
        src="@/intelligence/views/TemplateReport/NAPA_Market_Penetration.png"
        alt=""
        style="max-width: 100%; margin-top: -16px;"
      >
    </template>
    <template v-else>
      <TwoColumnLayout
        :key="`${reportRefreshCounter}_${reportType}`"
        :title="reportArea"
        :description="inputData.description"
      >
        <template #sidebar>
          <div class="filters">
            <div
              v-if="filtersTitles"
              class="filters-titles"
            >
              <h3>{{ filtersTitles.title }}</h3>
              <p>{{ filtersTitles.subtitle }}</p>
            </div>

            <div
              v-for="(filter, i) in filters"
              :key="i"
              class="filter"
            >
              <DateRange
                v-if="filter.type === 'date'"
                :model-value="dateParams"
                @update:model-value="handleDateRangeChange"
              />
              <TemplateReportFilter
                v-else
                :filter="filter"
              />
              <div
                v-if="shouldFilterBeVisible"
                class="flex"
              >
                <MenuFilter
                  menu-position="right"
                  :items="templateFilters"
                  :title="t('Sales Rep')"
                  :selected-value="selectedFilter"
                  @on-change="handleFilterChange"
                />       
                <CustomButton
                  v-if="selectedFilter"
                  :label="selectedFilter ? t('Clear filter') : ''"
                  :small="true"
                  class="clear-filter"
                  icon-name="close"
                  purpose="transparent"
                  @click="clearFilter"
                />
              </div>
            </div>
          </div>
        </template>

        <template #content>
          <TemplateReport
            v-if="reportType"
            :key="`${reportType}_${dateParams.date_from}_${dateParams.date_to}`"
            :report-type="reportType"
          />
        </template>
      </TwoColumnLayout>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { CUSTOMER_TEMPLATE_REPORT_TYPES, CUSTOMER_WHO_SPENT_IN_PREVIOUS } from '@/intelligence/store/data/reportTypes';
import { REPORT_AREA_PRODUCT, REPORT_AREA_CUSTOMER } from '@/intelligence/store/data/areas';
import TemplateReportFilter from '@/intelligence/components/TemplateReport/TemplateReportFilter';
import TwoColumnLayout from '@/shared/components/Layout/TwoColumnLayout';
import TemplateReport from '@/intelligence/components/TemplateReport/TemplateReport';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import { t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';
import useShared from '@/intelligence/composables/useShared';
import DateRange from '@/intelligence/components/Date/DateRange.vue';
import { MenuFilter, CustomButton } from '@sales-i/dsv3';
import addFilterToUrlPath from '@/intelligence/utils/addFilterToUrlPath';
import usePermissions from '@/shared/composables/usePermissions';
import { useFilters } from '@/intelligence/composables/useFilters';
import useLazyLoading from '@/intelligence/composables/useLazyLoading';
import useEnquiries from '@/intelligence/composables/useEnquiries';

const props = defineProps({
  reportArea: {
    type: String,
    default: REPORT_AREA_PRODUCT
  }
});

const store = useStore();
const vroute = useRoute();
const vrouter = useRouter();

const dateFilter = ref({
  date_from: '',
  date_to: '',
  value: ''
});

const { getInputArea, getInputData, refreshReport, reportRefreshCounter } = useShared({ store });
const { hasAccessToPerformanceInsights } = usePermissions();
const { applyFilterFromUrl, filtersApplied, bubbleData, deleteFilter } = useFilters();
const { resetReportParameters } = useLazyLoading({ store });
const { applyFiltersToQuery, setFilter, bubbleValues, fetchBubbleValues, fetchBubbleData } = useEnquiries({ store });

onMounted(async () => {
  getBubbleValues();
  if (!query.value.filter || inputData.value.area !== REPORT_AREA_CUSTOMER) return;

  const { filter } = query.value;
  if (!bubbleData.value) await fetchBubbleData();

  const filters = Array.isArray(filter) ? filter : [filter];
  filters.forEach((val, index) => {
    applyFilterFromUrl(val, index);
  });

  const refreshReportInterval = setInterval(() => {
    if (filters.length === filtersApplied.value.length) {
      applyFiltersToQuery();
      resetReportParameters();
      refreshReport();
      clearInterval(refreshReportInterval);
    }
  }, 100);
});

const shouldFilterBeVisible = computed(() => {
  // hasAccessToPerformanceInsights is equal to intelligence_crm_reports, which regarding PBAC roles and permissions gives Team Leader, Manager and Admin rights.
  return inputData.value.area === REPORT_AREA_PRODUCT && hasAccessToPerformanceInsights;
});
const selectedFilter = computed(() => filtersApplied.value[0]?.selected_values[0].id || '');
const query = computed(() => vroute.query);

const reportType = computed(() => vroute.name || reportsFilteredByArea.value[0].urlCode);
const inputData = computed(() => getInputData(reportType.value) || {});
const reportsFilteredByArea = computed(() => getInputArea(props.reportArea));
const dateParams = computed(() => ({
  date_from: vroute.query.date_from || 'today/y',
  date_to: vroute.query.date_to || 'today',
  value: vroute.query.value || 'today/y',
}));
const templateFilters = computed(() => {
  return bubbleValues.value.map(filter => ({
    text: filter.value,
    value: filter.id
  }));
});
const filtersTitles = computed(() => {
  if (reportType.value === CUSTOMER_WHO_SPENT_IN_PREVIOUS) {
    return {
      title: t('Adjust revenue'),
      subtitle: t('Based on this years revenue')
    };
  }
  return null;
});

const filters = computed(() => {
  if (!('filters' in inputData.value)) return [];
  return inputData.value.filters.map(filter => ({
    ...filter,
    values:
      filter.values &&
      filter.values.map(val => {
        const { text, value } = val;
        return {
          text: `${filter.prefix || ''} ${text} ${filter.suffix || ''}`.trim(),
          value
        };
      })
  }));
});

const containsFilterButton = computed(() => {
  return CUSTOMER_TEMPLATE_REPORT_TYPES.includes(reportType.value) && hasAccessToPerformanceInsights;
});

const handleDateRangeChange = async dateObject => {
  dateParams.value = dateObject;
  const { href } = vrouter.resolve({ query: {...vroute.query, ...dateObject }});
  navigateToUrl(href);
};

// hardcoded customer id, taken from bubbleData;
const customerId = '1';

const getBubbleValues = async() => {
  await fetchBubbleValues([customerId, '']);
};
const createFilterObject = (filter) => ({
  title: `${t('Filter')} 1`,
  selectedFilterType: t('Customer'),
  selectedFieldTitle: t('Sales Rep'),
  description: `${t('Customer')} > ${t('Sales Rep')}`,
  id: customerId,
  selected_values: [
    {
      value: filter.text,
      id: filter.value,
    },
  ],
  filter_type: 'include',
});
const handleFilterChange = async (filter) => {
  const filterObject = createFilterObject(filter);
  await setFilter([filterObject, 0]);
  applyFiltersToQuery();
  const filterPath = addFilterToUrlPath(filterObject);
  const query = parseQueryString(filterPath);
  navigateToUrl(`?${query}`);
};
const parseQueryString = (filterPath) => {
  const params = new URLSearchParams(filterPath);
  return params.toString();
};
const clearFilter = async () => {
  const { href } = vrouter.resolve({ query: {} });

  deleteFilter(filtersApplied.value[0]);
  navigateToUrl(href);
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.sidebar.hide {
  .sidebar-menu {
    display: none;
    opacity: 0;
  }
}

.sidebar-menu {
  ul {
    padding: 0;
    list-style-type: none;
  }

  li {
    position: relative;
    text-decoration: underline;
    cursor: pointer;
    padding-top: var(--spacing-1);
    padding-bottom: var(--spacing-2);
    color: var(--colour-brand-deluge);
    border-bottom: 1px solid var(--colour-panel-g-48);

    &:last-of-type {
      border-bottom: 0;
    }

    &.loading {
      text-decoration: none;
      cursor: initial;
      color: var(--colour-panel-g-24);
    }
  }
}

:deep(.area.products .container),
:deep(.area.customer .container) {
  padding-bottom: 100px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-bottom: var(--spacing-2);
  }
}

.filters-titles {
  p {
    color: var(--colour-utility-base);
  }
}

.button.clear-filter {
  padding: 0;
}
</style>