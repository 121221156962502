import ContactsSettingsView from '@/admin/views/CRMSetup/ContactsSettingsView.vue';
import Sections from '@/admin/views/CRMSetup/Sections.vue';
import Settings from '@/admin/views/CRMSetup/Settings.vue';
import { contactsSection as section } from '@/admin/router/urlBits';

export default {
  path: `:entity(${section})`,
  component: ContactsSettingsView,
  children: [
    {
      path: '',
      component: Sections,
    },
    {
      path: 'custom-sections',
      component: Sections,
    },
    {
      path: 'settings',
      component: Settings,
    },
  ],
};
