<template>
  <div class="enquiries">
    <BubbleSelection
      v-if="!isReportView"
      :report-type="reportType"
      :query-substitute="enquiryParams"
      @go-to-report="goToReport"
      @change-report="resetReport"
    />
    <EnquiryReportView
      v-else
      :report-type="reportType"
      :query-substitute="enquiryParams"
      @update-query="updateEnquiryParams"
      @reset-report="resetReport"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import EnquiryReportView from '@/intelligence/components/EnquiryReport/EnquiryReportView.vue';
import BubbleSelection from '@/intelligence/components/Bubble/BubbleSelection.vue';
import { FULL_PICTURE } from '@/intelligence/store/data/reportTypes';

// data
const isReportView = ref(false);
const reportType = ref(FULL_PICTURE);
const enquiryParams = ref({});

function goToReport(params) {
  updateEnquiryParams(params);
  isReportView.value = true;
}
function resetReport(params) {
  isReportView.value = false;
  reportType.value = params.reportType;
  updateEnquiryParams(params.query);
}
function updateEnquiryParams(params) {
  enquiryParams.value = params;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.enquiry-links-container {
  background-color: var(--colour-panel-alert-base);
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: var(--spacing-3);
  padding-top: var(--spacing-3);
  margin-bottom: var(--spacing-4);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-bottom: var(--spacing-3);
    margin: 0 0 var(--spacing-4) 0;
  }

  h4 {
    font-weight: var(--font-weight-regular);
  }

  .enquiry-links {
    display: flex;
    gap: var(--spacing-4);
    margin-top: var(--spacing-2);

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      flex-direction: column;
      gap: var(--spacing-2);
    }

    .enquiry-link {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: var(--font-size-4);
      font-weight: var(--font-weight-semibold);

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        justify-content: flex-start;
      }

      span {
        margin-left: var(--spacing-1);
      }
    }
  }
}
</style>
