import {
  GET_CUSTOM_SECTION,
  GET_CUSTOM_SECTION_LOADING,
  GET_CUSTOM_SECTION_ERROR,
  CLEAR_CUSTOM_SECTION
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
// mutations
export const getSectionMutations = {
  [GET_CUSTOM_SECTION]: (state, data) => {
    state.customSection.data = data;
    state.customSection.loaded = true;
    state.customSection.loading = false;
  },
  [GET_CUSTOM_SECTION_LOADING]: (state) => {
    state.customSection.loaded = false;
    state.customSection.loading = true;
  },
  [GET_CUSTOM_SECTION_ERROR]: (state) => {
    state.customSection.loaded = false;
    state.customSection.loading = false;
  },
  [CLEAR_CUSTOM_SECTION]: (state) => {
    state.customSection.data = {};
    state.customSection.loaded = false;
    state.customSection.loading = false;
  },
};

// actions
export const getSectionActions = {
  [GET_CUSTOM_SECTION]: async ({ commit, rootState }, parameters) => {
    try {
      let {entity, id, section_id, options = { version: '2',}} = parameters;
      commit(GET_CUSTOM_SECTION_LOADING);
      let fieldData = [];
      let data = await httpRequest('get', `${entity}/${id}/sections/${section_id}`, {}, options);
      if (!data.values) {
        const allSections = rootState.admin.schema.schema.sections;
        const thisSection = allSections.find(x => x.id === parseInt(section_id, 10));
        fieldData = thisSection.fields.map(x => {
          let newField = {
            field_id: x.id,
            value: ''
          };
          if (x.list_options) {
            newField.list_option_id = null;
          }
          return newField;
        });
        await httpRequest('put', `${entity}/${id}/sections/${section_id}`, fieldData, options);
        data = await httpRequest('get', `${entity}/${id}/sections/${section_id}`, {}, options);
      }
      commit(GET_CUSTOM_SECTION, data);
      return data;
    } catch (error) {
      console.error(error);
      commit(GET_CUSTOM_SECTION_ERROR);
      return false;
    }
  },
  [CLEAR_CUSTOM_SECTION]: ({ commit, }) => {
    commit(CLEAR_CUSTOM_SECTION);
  },
};
