import {
  GET_BY_ID,
  GET_BY_ID_LOADING,
  GET_BY_ID_ERROR,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

export const getByIdCommunitiesMutations = {
  [GET_BY_ID]: (state, data) => {
    state.currentlyLoaded = data;
    state.loaded = true;
    state.loading = false;
    state.error = null;
  },
  [GET_BY_ID_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.error = null;
  },
  [GET_BY_ID_ERROR]: (state, error) => {
    state.loading = false;
    state.loaded = true;
    state.error = error;
  }
};

export const getByIdCommunitiesActions = {
  [GET_BY_ID]: async ({ commit }, id) => {
    commit(GET_BY_ID_LOADING);

    try {
      const response = await httpRequest('get', `communities/${id}`, {}, {
        version: 2,
        area: 'associations'
      });

      commit(GET_BY_ID, response);
    } catch (e) {
      commit(GET_BY_ID_ERROR, e);
    }
  }
};