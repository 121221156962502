<template>
  <template
    v-if="type === 'text'"
  >
    <span>{{ value }}</span>
  </template>
  <template v-else>
    <span :class="dynamicClass">{{ getFormattedValue(value) }}</span>
  </template>
</template>
  
<script setup>
import { computed } from 'vue';
import { dates } from '@sales-i/utils';
import useCurrency from '@/shared/composables/useCurrency';

const { cPrice } = useCurrency();

const props = defineProps({
  value: {
    type: String || Number,
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  }
});

const dynamicClass = computed(() => {
  const numericValue = parseFloat(props.value);
  if (props.type === 'percent') {
    if (numericValue > 67) return 'green';
    if (numericValue > 33) return 'amber';
    return 'red';
  }
  if (props.type === 'price') {
    if (numericValue > 150000) return 'green';
    if (numericValue > 75000) return 'amber';
    return 'red';
  }
  return props.type; // default class based on type
});

function getFormattedValue(value) {
  switch(props.type) {
  case 'date':
    return dates.format(value);
  case 'price':
    return cPrice(value);
  case 'percent':
    return `${value}%`;
  default:
    return value;
  }
}
</script>
<style lang="scss" scoped>
.red {
  color: var(--colour-data-mandy-label);
}
.amber {
  color: var(--colour-data-barberry-label);
}

.green {
  color: var(--colour-data-de-york-label);
}

.red,
.amber,
.green {
  font-weight: var(--font-weight-semibold);
}
</style>
  