import {
  SET_UPDATED_FLAG,
  CLEAR_UPDATED_FLAG
} from '@/shared/store/actionType';

// mutations
export const getSetUpdatedMutations = {
  [SET_UPDATED_FLAG]: (state) => {
    state.selected.updated = true;
  },
  [CLEAR_UPDATED_FLAG]: (state) => {
    state.selected.updated = false;
  },
};

// actions
export const getSetUpdatedActions = {
  [SET_UPDATED_FLAG]: ({ commit, }) => {
    commit(SET_UPDATED_FLAG);
  },
  [CLEAR_UPDATED_FLAG]: async ({ commit, }) => {
    commit(CLEAR_UPDATED_FLAG);
  },
};
