import {
  EXPORT_REPORT_FULL_PICTURE,
  GENERATE_FILE_ID_FULL_PICTURE,
} from '@/intelligence/store/actionType';
import { gqlRequest, httpFileRequest } from '@sales-i/utils';
import { gql } from 'graphql-request';
import { saveAs } from 'file-saver';

export const fullPictureActions = {
  [GENERATE_FILE_ID_FULL_PICTURE]: async (store, [report, payload]) => {    
    const { customerMode, reporter, name, address, currency, fileExt } = payload;
    try {
      const getRequestParameters = store.rootGetters['intelligence/shared/getRequestParameters'];
      const 
        query = gql`{ exportfullpicture(
          ${getRequestParameters([report, { limit: 1000 }])}
          export_details: {
            file_type: ${fileExt},
            reporter: "${reporter}",
            customer: "${name}",
            customer_address: "${address}",
            currency_symbol: "${currency}",
            delimiter: COMMA
            customer_mode: ${customerMode}
          }    
          ) { 
            id
          }
        }`;
      const { exportfullpicture: { id } } = await gqlRequest(query, {}, { debug: true });
      return {id, extension: fileExt};
    } catch (error) {
      console.error(error);
    }
  },
  [EXPORT_REPORT_FULL_PICTURE]: async ( { dispatch }, [report, payload]) => {
    const file = await dispatch(GENERATE_FILE_ID_FULL_PICTURE, [report, payload]);
    const { id, extension } = file;
    try {
      const response = await httpFileRequest(`download/${id}`, {
        area: 'exports',
      });
      const blob = new Blob(
        [response.data],
        {type: 'text/plain;charset=utf-8'}
      );
      saveAs(blob, `${id}.${extension}`);
      return response;
    } catch (error) {
      console.error(error);
    }
  }
};