<template>
  <div class="flow">
    <SearchField
      :search-func="onSearch"
      icon-color="var(--colour-utility-action)"
      @input="onSearchInput"
    />
    <div class="entity-filter">
      <Field
        v-for="entity in entities"
        :id="entity.value"
        :key="entity.value"
        :value="(selectedEntities || []).includes(entity.value)"
        type="checkbox"
        class="mr-2 mb-1"
        :label="entity.label"
        @input="onCheckEntityInput($event, entity.value)"
      />
    </div>
    <GroupList
      title-field="tag"
      key-field="tag"
      :items="items"
      :loading="loading"
      :multi-select="multiSelect"
      :selected-items="selectedItems"
      :tag-limit="tagLimit"
      @items-selected="onItemsSelected"
    />
  </div>
</template>
<script>
import Field from '@/shared/components/Form/Field.vue';
import GroupList from '@/shared/components/List/GroupList.vue';
import SearchField from '@/shared/components/Search/SearchField.vue';
import { mapActions } from 'vuex';
import { GET_REPORT, CLEAR_REPORT } from '@/admin/store/actionType';

export default {
  name: 'TagSearch',
  components: {
    Field,
    GroupList,
    SearchField,
  },
  props: {
    entities: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    multiSelect: {
      type: Boolean,
      default: true,
    },
    selectedEntities: {
      type: Array,
      default: () => [],
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    tagLimit: {
      type: Number,
      default: 200,
    },
  },
  emits: ['itemsSelected', 'onCheckEntityInput'],
  data() {
    return {};
  },
  computed: {},
  methods: {
    ...mapActions({
      clear: `admin/tags/${CLEAR_REPORT}`,
      getReportData: `admin/tags/${GET_REPORT}`,
    }),
    async onSearch(q) {
      await this.clear();
      if (!q) return;
      await this.getReportData({ q });
      return this.items.map(x => ({ ...x, value: x.tag, text: x.tag }));
    },
    onSearchInput() {
      this.clear();
    },
    onItemsSelected(items) {
      this.$emit('itemsSelected', items);
    },
    onCheckEntityInput($event, entityType) {
      this.$emit('onCheckEntityInput', $event, entityType);
    },
  },
};
</script>
<style lang="scss" scoped>
.entity-filter {
  display: flex;
  flex-flow: row wrap;
}

// Hide the results drop-down on this component. Hopefully we will make the drop-down a flag on the SearchField component.
:deep(.search .dropdown-container) {
  display: none;
}
</style>
