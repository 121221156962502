export const salutationList = [
  {
    text: 'Mr',
    value: 'Mr',
  },
  {
    text: 'Mrs',
    value: 'Mrs',
  },
  {
    text: 'Miss',
    value: 'Miss',
  },
  {
    text: 'Ms',
    value: 'Ms',
  },
  {
    text: 'Dr',
    value: 'Dr',
  },
  {
    text: 'Prefer not to say',
    value: 'pnts',
  },
];
