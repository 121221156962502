import {
  ADD_COMMUNITY,
} from '@/admin/store/actionType';
import { t } from '@sales-i/utils';

const parseProducts = (products) => {
  let currentlyLoaded = [];
  let n = 0;

  for (n; n < products.length; n++) {
    const [product_code, product_name] = products[n].split('||');
    currentlyLoaded.push({
      community_name: t('Product Search'),
      product_code,
      product_name,
    });
  }

  return currentlyLoaded;
};

export const addCommunityMutators = {
  [ADD_COMMUNITY]: (state, products) => {
    state.currentlyLoaded = parseProducts(products);
  },
};

export const addCommunityActions = {
  [ADD_COMMUNITY]: ({ commit }, products) => {
    commit(ADD_COMMUNITY, products);
  },
};