<template>
  <div class="details-container">
    <slot name="header">
      <h2 class="mb-2">
        {{ name }}
      </h2>
    </slot>
    <div
      v-if="!value || !value.length"
      class="add-item"
    >
      <div
        :ref="`drop-0`"
        class="divider"
      />
      <CustomButton
        class="add-btn"
        icon-name="plus"
        icon-color="var(--colour-utility-white)"
        icon-only
        :icon-height="24"
        :icon-width="24"
        @click="add(0)"
      />
    </div>
    <div
      v-for="(item, index) in value"
      :key="`area-${index}`"
      :class="{ highlighted: toIndex >= 0 && toIndex === index }"
      role="button"
      tabindex="0"
      @drop.prevent="dragDrop(index)"
      @dragenter.prevent="dragEnter($event, index)"
      @dragover.prevent="dragOver($event, index)"
      @dragstart="dragStart($event, item, index)"
    >
      <SectionsDrop
        v-show="toIndex === index && index !== fromIndex"
        class="my-1"
      >
        {{ t('Stage') }} {{ toIndex + 1 }}
      </SectionsDrop>
      <CustomCard
        :key="`stage-${item.id}`"
        purpose="reversed"
        draggable
        class="item p-0 my-2"
        @dragstart="dragStart($event, item, index)"
      >
        <div class="main-area">
          <div class="header">
            <IconBase
              icon-name="move"
              icon-color="var(--colour-utility-action)"
              :height="24"
              :width="24"
            />
            <h4 class="px-2 stage-title">
              {{
                t(`Stage ${index + 1} - ${item.name}`, 'stage_number_name', {
                  interpolations: {
                    number: index + 1,
                    name: item.name,
                  },
                })
              }}
            </h4>
            <CustomButton
              icon-name="trash"
              icon-color="var(--colour-utility-action)"
              icon
              :height="24"
              :disabled="!item.deletable"
              :width="24"
              @click="del(item, index)"
            />
          </div>
          <div class="form-grid">
            <div class="row">
              <div class="col col-12 col-md-6">
                <Field
                  :value="item.name"
                  :label="t('Label')"
                  required
                  :error="!item.name ? true : null"
                  :error-message="t('Label is required')"
                  @input="value => fieldInput(index, 'name', value)"
                />
              </div>
              <div class="col col-12 col-md-6">
                <Field
                  :value="item.score"
                  type="number"
                  :label="t('% Value', 'percentage_value')"
                  :min="0"
                  :max="100"
                  :error-message="t('Stage score should be between 0 and 100')"
                  @input="value => fieldInput(index, 'score', value)"
                />
              </div>
            </div>
          </div>
        </div>
      </CustomCard>
      <div class="add-item">
        <div
          :ref="`drop-${index}`"
          class="divider"
        />
        <CustomButton
          class="add-btn"
          icon-name="plus"
          icon-color="white"
          icon-only
          :icon-height="24"
          :icon-width="24"
          @click="add(index)"
        />
      </div>
    </div>
    <CustomCard
      key="total"
      class="actions mt-2"
    >
      <div class="main-area">
        <ButtonGroup>
          <CustomButton
            :label="t('Update')"
            purpose="reversed"
            :disabled="!isValid"
            @click="update"
          />
        </ButtonGroup>
      </div>
    </CustomCard>
  </div>
</template>

<script type="text/javascript">
import { t } from '@sales-i/utils';
import { CustomCard, IconBase, CustomButton } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field.vue';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import SectionsDrop from '@/admin/views/CRMSetup/SectionsDrop.vue';

export default {
  name: 'Stages',
  components: {
    CustomCard,
    Field,
    IconBase,
    CustomButton,
    ButtonGroup,
    SectionsDrop
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['move', 'add', 'delete', 'edit', 'save'],
  data() {
    return {
      fromIndex: -1,
      toIndex: -1,
      dragging: false,
    };
  },
  computed: {
    isValid() {
      return true;
    },
  },
  methods: {
    t,
    clearDragState() {
      this.fromIndex = -1;
      this.toIndex = -1;
      this.dragging = false;
    },
    dragStart(ev, item, index) {
      this.clearDragState();
      this.dragging = true;
      ev.dataTransfer.dropEffect = 'move';
      ev.dataTransfer.effectAllowed = 'move';
      ev.dataTransfer.setData('index', index);
      this.fromIndex = index;
    },
    dragDrop(toIndex) {
      this.$emit('move', { from: this.fromIndex, to: toIndex });
      this.clearDragState();
    },
    dragEnter(ev, index) {
      this.toIndex = this.fromIndex == index ? -1 : index;
    },
    dragOver(ev, index) {
      this.toIndex = index;
    },
    dragLeave() {
      this.toIndex = -1;
    },
    add(index) {
      this.$emit('add', index);
    },
    del(item, index) {
      this.$emit('delete', { index, item });
    },
    fieldInput(index, fieldName, value) {
      this.$emit('edit', { index, fieldName, value });
    },
    update() {
      this.$emit('save');
    },
  },
};
</script>

<style lang="scss" scoped>
.details-container {
  :deep(.icon-move) {
    cursor: grab;
  }

  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  box-shadow: none;

  div.highlighted {
    animation: blinker 1s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0.2;
    }
  }

  .btn-delete {
    cursor: hand;
  }

  .add-item {
    position: relative;
    height: 2rem;
    padding: var(--spacing-2);

    .divider {
      position: absolute;
      top: 50%;
      left: 1%;
      width: 98%;
      height: 0px;
      border-bottom: 1px solid var(--shadow-colour);
    }

    .add-btn {
      position: absolute;
      top: 0;
      right: var(--spacing-2);
    }
  }

  h2 {
    font-weight: var(--font-weight-semibold);
  }

  .item,
  div.actions {
    padding: 0;

    .details {
      margin-top: 0;
      padding: var(--spacing-3);
      background-color: var(--colour-panel-action);
      text-align: right;

      button {
        margin-left: var(--spacing-1);
        margin-right: var(--spacing-1);
      }
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
    padding: var(--spacing-2);
    margin-bottom: var(--spacing-2);
    background-color: var(--colour-panel-alert-base);

    h4.stage-title {
      flex-grow: 1;
      flex: 1;
      max-width: calc(100% - 40px);
      word-wrap: break-word;
      word-break: break-all;
    }

    .stages-title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
    .stages-title .stage-title {
      display: flex;
      span {
        font-size: var(--font-size-small);
      }
    }
    .stages-title .percent {
      position: relative;
      top: var(--spacing-1);
    }
  }

  div.actions {
    box-shadow: none;
    background-color: var(--colour-panel-action);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-bottom: var(--spacing-2);
    text-align: center;
    .total {
      padding: var(--spacing-2);
      background-color: var(--colour-utility-error);
      color: var(--colour-utility-white);
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
    }
    button {
      margin-top: var(--spacing-2);
    }
  }
}
</style>
