// Components
import AddInteractionEntity from '@/crm/components/Calendar/AddInteraction/AddInteractionEntity.vue';
import AddInteractionOpportunity from '@/crm/components/Calendar/AddInteraction/AddInteractionOpportunity.vue';
import AddInteractionFurtherDetails from '@/crm/components/Calendar/AddInteraction/AddInteractionFurtherDetails.vue';
import AddInteractionOpportunityUpdate from '@/crm/components/Calendar/AddInteraction/AddInteractionOpportunityUpdate.vue';
import AddInteractionFollowUp from '@/crm/components/Calendar/AddInteraction/AddInteractionFollowUp.vue';
import AddInteractionDetails from '@/crm/components/Calendar/AddInteraction/AddInteractionDetails.vue';
import AddInteractionDatePicker from '@/crm/components/Calendar/AddInteraction/AddInteractionDatePicker.vue';

export default [
  // Add interactions
  {
    path: '',
    component: AddInteractionDatePicker,
  },

  {
    path: 'details',
    component: AddInteractionDetails,
  },
  {
    path: 'entity',
    component: AddInteractionEntity,
  },
  {
    path: 'opportunity',
    component: AddInteractionOpportunity,
  },
  {
    path: 'further-details',
    component: AddInteractionFurtherDetails
  },
  {
    path: 'update-opportunity',
    component: AddInteractionOpportunityUpdate,
  },
  {
    path: 'follow-up',
    component: AddInteractionFollowUp,
  },
];
