import { computed } from 'vue';
import { useStore } from 'vuex';

import { GET_BY_ID, CLEAR_BY_ID, GET_CUSTOMER_ID, UPDATE_RECENTLY_VIEWED } from '@/shared/store/actionType';
import { GET_CHILDREN, CLEAR_CHILDREN, ROLL_UP } from '@/crm/store/actionType';

export const useCustomer = ({ store = useStore() } = {}) => {

  const selectedCustomer = computed(() => store.state.crm.customers.selected.data);
  const selectedCustomerLoading = computed(() => store.state.crm.customers.selected.loading);
  const selectedCustomerLoaded = computed(() => store.state.crm.customers.selected.loaded);
  const customerChildren = computed(() => store.state.crm.customers.children);
  const isRolledUp = computed(() => store.state.crm.customers.children.isRolledUp);
  const customerHasChildren = computed(() => !selectedCustomerLoading.value 
    && customerChildren.value.loaded && customerChildren.value.data.length > 0);

  const getCustomerById = params => store.dispatch(`crm/customers/${GET_BY_ID}`, params);
  const getCustomerChildren = params => store.dispatch(`crm/customers/${GET_CHILDREN}`, params);
  const clearCustomerChildren = () => store.dispatch(`crm/customers/${CLEAR_CHILDREN}`);
  const clearCustomerById = () => store.dispatch(`crm/customers/${CLEAR_BY_ID}`);
  const setRollUp = params => store.dispatch(`crm/customers/${ROLL_UP}`, params);
  const getCustomerId = accountId => store.dispatch(`crm/customers/${GET_CUSTOMER_ID}`, accountId);
  const updateRecentlyViewedCustomer = accountId => store.dispatch(`searchBarResults/${UPDATE_RECENTLY_VIEWED}`, {
    entity_type: 'customer',
    entity_id: typeof accountId === 'number' ? accountId : parseInt(accountId, 10),
  });

  return {
    customerChildren,
    customerHasChildren,
    isRolledUp,
    selectedCustomer,
    selectedCustomerLoading,
    selectedCustomerLoaded,
    getCustomerById,
    getCustomerId,
    getCustomerChildren,
    clearCustomerChildren,
    clearCustomerById,
    setRollUp,
    updateRecentlyViewedCustomer
  };
};

