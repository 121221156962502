<template>
  <TrendsReportView
    :title="t('Sales Comparison')"
    disable-nav
    hide-footer
  />
</template>

<script setup>
import { t } from '@sales-i/utils';
import TrendsReportView from '@/intelligence/views/Trends/TrendsReportView.vue';
</script>

<style lang="scss" scoped>
</style>
