<template>
  <span>{{ title }}</span>
</template>
    
<script setup>
import { computed } from 'vue';
import { t } from '@sales-i/utils';
import { useStore } from 'vuex';
import { useUsers } from '@/shared/composables/useUsers';
  
const store = useStore();
  
const { getUserById } = useUsers(store);
  
const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
});
    
const title = computed(() => {
  if (!Array.isArray(props.item.allocated_users) || !props.item.allocated_users.length) return t('Not allocated');
  const firstUser = getUserById(props.item.allocated_users[0].allocated_user_id);
  const firstUserName = firstUser.first_name && firstUser.last_name ? `${firstUser.first_name} ${firstUser.last_name}` : t('Unknown user');
  return firstUserName + (props.item.allocated_users.length === 1 ? '' : ` (+${props.item.allocated_users.length - 1})`);
});
</script>
    