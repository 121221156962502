<template>
  <div class="flow">
    <h3>{{ t('Are there any specific contacts you want to target?') }}</h3>
    <div class="form-field-grid">
      <Field
        :id="`job-title-${filter.id}`"
        required
        :error-message="t(`Please enter a job title`)"
        type="text"
        :value="filter.value"
        :label="t('Job title')"
        @input="updateFilter('value', $event)"
      />
    </div>
  </div>
</template>

<script setup>
import Field from '@/shared/components/Form/Field.vue';
import { t } from '@sales-i/utils';

const props = defineProps({
  filter: {
    type: Object,
    default: undefined
  },
});

const emit = defineEmits(['update:filter']);

function updateFilter(field, value) {
  emit('update:filter', {
    id: props.filter.id,
    [field]: value,
  });
}
</script>