export const CUSTOMER_CONTACT = 'Customer & Contact';
export const OPPORTUNITY = 'Opportunity';
export const OPPORTUNITY_UPDATE = 'Update opportunity';
export const FURTHER_DETAILS = 'Further details';
export const FOLLOW_UP = 'Schedule follow up';
export const TIMESCALE = 'Timescale';
export const SETTINGS = 'Settings';
export const OPPORTUNITIES_ADD = 'Adding opportunities';
export const PROFILE = 'Profile completion status';
export const LOCATION_PROSPECTS = 'Location of prospects';
export const LOCATION_CUSTOMERS = 'Location of customers';
export const NOTE = 'Add an interaction note';
