import { authActions, authMutations, authGetters } from './auth';
import { calendarActions, calendarMutations, calendarGetters} from './calendar';
// initial state
const state = () => ({
  url: {
    loading: false,
    error: false,
  },
  details: {
    data: {},
    loading: false,
    error: false
  },
  calendar: {
    data: [],
    loading: false,
    error: false
  }
});

// getters
const getters = {
  ...authGetters,
  ...calendarGetters
};

// mutations
const mutations = {
  ...authMutations,
  ...calendarMutations
};

// actions
const actions = {
  ...authActions,
  ...calendarActions
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
