<template>
  <div>
    <EnquiryNav
      :report-type="reportType"
      :dates="dateParams"
      :active-area-prop="defaultReportArea"
      :is-date-filter-active="isDateFilterActive"
      :is-period-filter-active="isPeriodFilterActive"
      :is-percent-value-active="isPercentValueActive"
      :is-going-back-from-breadcrumbs="isGoingBackFromBreadcrumbs"
      @toggle-date-filter="toggleDateFilter"
      @toggle-period-filter="togglePeriodFilter"
      @toggle-percent-value="togglePercentValue"
      @change-report="resetReport"
    >
      <template #buttons>
        <IconButton
          v-if="reportType === VARIANCE && isFeatFlagChart"
          class="button chart-button"
          :class="{ active: isChart }"
          icon-name="dimensions"
          :icon-color="isChart ? 'var(--colour-utility-white)' : 'var(--colour-utility-action)'"
          purpose="transparent"
          icon-only
          :icon-size="26"
          @click.stop="handleChartBtnClick"
        />
      </template>
    </EnquiryNav>
    <EnquiryBreadcrumbs
      v-if="!isDateFilterActive && !isPeriodFilterActive && !isReportSvG"
      :enquiry-params="enquiryParams"
      :drill-params="drillParams"
      :abandoned-drill-params="abandonedDrills"
      :query="query"
      :is-matrix-based-report="isMatrixBasedReport"
      :report-type="reportType"
      @go-to-selected-drill="goToSelectedDrill"
      @reset-report="resetReport(reportType)"
    />
    <MultiDimBreadcrumbs
      v-if="isReportSvG"
      :bubble-data="bubbleData"
      :params="allParams"
      :abandoned-drill-params="abandonedDrills"
      @go-to-selected-drill="gotoDrillMultiDim"
      @reset-report="resetReport(reportType)"
    />
    <BufferImage
      v-if="isGoingBackFromBreadcrumbs && isMobile && !isReportEnabled"
      color="black"
      float="center"
      class="nav-loading-spinner"
    />
    <div
      class="container data"
      :class="{ datepicker: isDateFilterActive }"
    >
      <div
        v-if="!isDateFilterActive && !isPeriodFilterActive"
        class="background-cover"
      />
      <DatePickerView
        v-if="isDateFilterActive"
        :report-type="reportType"
        @set-active-date="setDate"
      />
      <PeriodFilter
        v-if="isPeriodFilterActive"
        @on-set-active-period="setActivePeriod"
      />
      <component
        :is="reportComponents[inputData.component]"
        v-show="isReportTableVisible"
        :key="reportKey"
        :title="bubbleBasedTitle"
        :report-type="reportType"
        :is-report-enabled="isReportEnabled"
        :is-chart-enabled="isChart && isFeatFlagChart"
        :is-percent-value-active="isPercentValueActive"
        :date-params="dateParams"
        :query-substitute="querySubstitute"
        :active-columns="activeColumns"
        @handle-enquiry-drill="handleEnquiryDrill"
        @loading="isReportEnabled = false"
        @loaded="isReportEnabled = true"
        @update:columns="updateRouteQuery({ activeColumns: $event })"
      />

      <div
        v-if="showBubbles && isReportEnabled && !isDateFilterActive && !isPeriodFilterActive"
        class="bubbles"
      >
        <BubbleList
          drill
          :hidden-bubbles="hiddenBubbles"
          :report-type="reportType"
          @drill-selected="drillSelectedHandler"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { reportScope } from '@/intelligence/router/urlBits';
import matrix from '@/intelligence/components/Matrix/MatrixReport';
import sales_vs_gaps from '@/intelligence/components/SalesVsGaps/SVGReport';
import full_picture from '@/intelligence/components/FullPicture/FullPictureTable';
import variance from '@/intelligence/components/Variance/VarianceReport';
import {
  CLEAR_REPORT_DATA,
  FETCH_BUBBLE_DATA,
  APPLY_AXIS,
  APPLY_DIMS,
  FETCH_DATES,
  APPLY_FILTERS_TO_QUERY,
  APPLY_DATE_RANGE,
  REFRESH_REPORT,
  APPLY_OFFSET,
  TOGGLE_DRILL,
  APPLY_MEASURE,
  SET_DIMENSIONS_NUMBER,
  SET_FILTERS,
  DISABLE_SELECT_BUBBLES_POPUP,
  TOGGLE_SELECT_BUBBLES_POPUP,
  SET_LOADING,
  SET_ACTIVE_COLUMNS,
  RESET_REPORT_PARAMETERS
} from '@/intelligence/store/actionType';
import { MATRIX as MATRIX_NAME, SALES_VS_GAPS as SALES_VS_GAPS_NAME, VARIANCE } from '@/intelligence/store/data/reportTypes';
import { DATE_FILTER_KEYS } from '@/intelligence/store/data/dateFilters';
import { BufferImage, IconButton } from '@sales-i/dsv3';
import BubbleList from '@/intelligence/components/Bubble/BubbleList';
import DatePickerView from '@/intelligence/components/Date/DatePickerView';
import PeriodFilter from '@/intelligence/components/Filters/PeriodFilter';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import EnquiryBreadcrumbs from '@/intelligence/components/EnquiryReport/EnquiryBreadcrumbs/EnquiryBreadcrumbs';
import MultiDimBreadcrumbs from '@/intelligence/components/EnquiryReport/EnquiryBreadcrumbs/MultiDimBreadcrumbs';
import { ENQUIRY_AXIS_X, ENQUIRY_AXIS_Y } from '@/intelligence/store/data/kpis';
import { REPORT_AREA_ENQUIRY } from '@/intelligence/store/data/areas';
import breakpoints from '@/shared/utils/breakpoints';
import isRolldate from '@/intelligence/store/utils/isRolldate';
import { storage } from '@sales-i/utils';
import { DATES_ITEM } from '@/shared/constants/storageItems';
import { capitalize } from '@/shared/utils/strings';
import { navigateToUrl } from 'single-spa';
import { useMq } from 'vue3-mq';
import { useFilters } from '@/intelligence/composables/useFilters';

const props = defineProps({
  reportType: {
    type: String,
    default: '',
    require: true,
  },
  querySubstitute: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['updateQuery', 'resetReport']);
const store = useStore();
const vroute = useRoute();
const vrouter = useRouter();
const mq = useMq();

const { applyFilterFromUrl } = useFilters();

const reportComponents = ref({
  full_picture,
  matrix,
  sales_vs_gaps,
  variance
});

const isDateFilterActive = ref(false);
const isPeriodFilterActive = ref(false);
const isPercentValueActive = ref(false);
const defaultReportArea = ref(REPORT_AREA_ENQUIRY);
const abandonedDrills = ref([]);
const savedQuery = ref({});
const temporaryQuery = ref({});
const breadcrumbsDigging = ref(false);
const isReportEnabled = ref(false);
const dateParams = ref({});
const isChart = ref(false);

const bubbleData = computed(() => store.state.intelligence.enquiry.bubbleData);
const drillActiveRows = computed(() => store.state.intelligence.shared.drillActiveRows);
const isSelectBubblesPopupActiveOnMobile = computed(() => store.state.intelligence.shared.isSelectBubblesPopupActiveOnMobile);
const fetchedDates = computed(() => store.state.intelligence.calendar.fetchedDates);
const reportKey = computed(() => store.state.intelligence.shared.reportRefreshCounter);
const iframe = computed(() => store.state.system.iframe);
const activeColumns = computed(() => store.state.intelligence.shared.activeColumns);
const filtersApplied = computed(() => store.state.intelligence.enquiry.filtersApplied);

const getDrillsLevel = drillLevel => store.getters['intelligence/shared/getDrillsLevel'](drillLevel);
const getDrillsQueryParams = computed(() => store.getters['intelligence/shared/getDrillsQueryParams']);
const getDrillsLevelBasedOnQuery = dq => store.getters['intelligence/shared/getDrillsLevelBasedOnQuery'](dq);
const getInputData = rt => store.getters['intelligence/shared/getInputData'](rt);
const periodFilter = computed(() => store.getters['intelligence/enquiry/getPeriod']);
  
    
const isSalesVsGaps = computed(() => props.reportType === SALES_VS_GAPS_NAME);
// todo: insert get feat flag value code
// SA-5972
const isFeatFlagChart = computed(() => true);
const inputData = computed(() => ({ ...getInputData(props.reportType), component: props.reportType.replaceAll('-', '_') }));
const query = computed(() => iframe.value ? props.querySubstitute : vroute.query);
const hiddenBubbles = computed(() => {
  if (isSalesVsGaps.value) {
    const params = allParams.value;
    const hiddenBubblesArr = [];

    Object.keys(params).filter((key) => key.includes('dim') && !key.includes('values')).forEach(key => {
      if (!isDrillAbandoned(key)) {
        const value = allParams.value[key];
        if (value !== null) {
          hiddenBubblesArr.push(value);
        }
      }
    });

    return hiddenBubblesArr;
  }

  const enquiryValues = Object.values(enquiryParams.value);
  const drillValues = drillParams.value.flat();
  const combinedValues = [...enquiryValues, ...drillValues].filter(value => value !== null);
  
  return combinedValues;
});
const dimension1 = computed(() => inputData.value.bubbleCaptions[0]);
const dimension2 = computed(() => inputData.value.bubbleCaptions[1]);
const allParams = computed(() => {
  const data = {};
  const filteredKeys = ['xaxis', 'measure'];

  if (Object.keys(temporaryQuery.value)?.length) {
    Object.keys(temporaryQuery.value).forEach((key) => {
      if (key === undefined) return;

      if (
        !filteredKeys.includes(key) &&
          !DATE_FILTER_KEYS.includes(key)
      ) {
        data[key] = temporaryQuery.value[key];
      }
    });

    return data;
  } 

  Object.keys(query.value).forEach((key) => {
    if (key === undefined ) return;

    if (
      !filteredKeys.includes(key) &&
        !DATE_FILTER_KEYS.includes(key)
    ) {

      data[key] = query.value[key];
    }
  });

  if (!data['activeDim']) {
    if (getLatestDimensionLevel.value === '0') {
      data['activeDim'] = 'dim1';
    } else {
      data['activeDim'] = `${getLatestDimensionLevel.value}-dim1`;
    }
  }
  return data;
});
const enquiryParams = computed(() => {
  const data = {};

  Object.keys(query.value).forEach(key => {
    if (key !== undefined && !key.includes('values') && !DATE_FILTER_KEYS.includes(key)) {
      if (
        (isMatrixBasedReport.value && key.includes('dim')) ||
          (!isMatrixBasedReport.value && !key.includes('1-dim'))
      )
        return;

      data[key] = query.value[key];
    }
  });

  return Object.keys(data)
    .sort()
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});
});
const drillParams = computed(() => {
  const params = [];
  const drillParamsValue = getDrillsQueryParams.value;

  Object.keys(drillParamsValue).forEach(key => {
    const drillId = drillParamsValue[key];

    if (!key.includes('values') && isMatrixBasedReport.value && key.includes('dim')) {
      if (drillId !== null) {
        params.push([drillId]);
      }
    } else if (!key.includes('values') && !isMatrixBasedReport.value && !key.includes('1-dim')) {
      const match = key.match(/^\d+/);
      const paramIndex = match ? Number(match[0]) : null;
      if (paramIndex !== null && drillId !== null) {
        if (params[paramIndex]) {
          params[paramIndex].push(drillId);
        } else {
          params[paramIndex] = [drillId];
        }
      }
    }
  });

  const filteredParams = params.filter(el => el !== null && el.length > 0);
  
  return filteredParams;
});
const datesFromQuery = computed(() => {
  const data = {};

  Object.keys(query.value).forEach(key => {
    if (DATE_FILTER_KEYS.includes(key)) {
      data[key] = query.value[key];
    }
  });

  return data;
});
const showBubbles = computed(() => {
  if (isMobile.value) {
    return isSelectBubblesPopupActiveOnMobile.value;
  }
  return drillActiveRows.value.length;
});
const bubbleBasedTitle = computed(() => {
  let title = '';

  if (!bubbleData.value) return title;

  Object.values(enquiryParams.value).forEach(value => {
    title += `${processBubbleData(value).title} - `;
  });

  return title.slice(0, -3);
});
const isReportSvG = computed(() => props.reportType === SALES_VS_GAPS_NAME);
const isReportMatrix = computed(() => props.reportType === MATRIX_NAME);
const isMatrixBasedReport = computed(() => isReportMatrix.value || isReportSvG.value);
const isMobile = computed(() => breakpoints.smAndDown.includes(mq.current));
const isReportTableVisible = computed(() => {
  return (
    !(isSelectBubblesPopupActiveOnMobile.value && isMobile.value) &&
      !isDateFilterActive.value &&
      !isPeriodFilterActive.value
  );
});
const isGoingBackFromBreadcrumbs = computed(() => isMobile.value && abandonedDrills.value.length > 0);
const getLatestDimensionLevel = computed(() => {
  const keys = Object.keys(query.value).filter(key => key.includes('-dim')).map(key => key.split('-')[0]).sort((a, b) => b - a);
  const latestKey = keys[0];
  return latestKey;
});

watch(() => reportKey.value, () => isReportEnabled.value = false);

const clearEnquiryData = params => store.dispatch(`intelligence/shared/${CLEAR_REPORT_DATA}`, params);
const refreshReport = params => store.dispatch(`intelligence/shared/${REFRESH_REPORT}`, params);
const resetReportParameters = params => store.dispatch(`intelligence/reportLazyLoad/${RESET_REPORT_PARAMETERS}`, params);
const fetchBubbleData = params => store.dispatch(`intelligence/enquiry/${FETCH_BUBBLE_DATA}`, params);
const setDimensionsNumber = params => store.dispatch(`intelligence/enquiry/${SET_DIMENSIONS_NUMBER}`, params);
const applyAxis = params => store.dispatch(`intelligence/enquiry/${APPLY_AXIS}`, params);
const applyDims = params => store.dispatch(`intelligence/enquiry/${APPLY_DIMS}`, params);
const applyDate = params => store.dispatch(`intelligence/shared/${APPLY_DATE_RANGE}`, params);
const fetchDates = params => store.dispatch(`intelligence/calendar/${FETCH_DATES}`, params);
const applyFiltersToQuery = params => store.dispatch(`intelligence/enquiry/${APPLY_FILTERS_TO_QUERY}`, params);
const applyOffsetY = params => store.dispatch(`intelligence/shared/${APPLY_OFFSET}`, params);
const toggleDrillOption = params => store.dispatch(`intelligence/shared/${TOGGLE_DRILL}`, params);
const addDrillingDimensionRowValuesOnLevel = params => store.dispatch('intelligence/shared/addDrillingDimensionRowValuesOnLevel', params);
const setDrillsDataByQuery = params => store.dispatch('intelligence/shared/setDrillsDataByQuery', params);
const addDrillingDimensionOnLevel = params => store.dispatch('intelligence/shared/addDrillingDimensionOnLevel', params);
const generateDrillActiveRows = params => store.dispatch('intelligence/shared/generateDrillActiveRows', params);
const setDrillActiveRows = params => store.dispatch('intelligence/shared/setDrillActiveRows', params);
const applyMeasure = params => store.dispatch(`intelligence/enquiry/${APPLY_MEASURE}`, params);
const setFilters = params => store.dispatch(`intelligence/enquiry/${SET_FILTERS}`, params);
const disableSelectDimensionsPopup = params => store.dispatch(`intelligence/shared/${DISABLE_SELECT_BUBBLES_POPUP}`, params);
const toggleSelectBubblesPopup = params => store.dispatch(`intelligence/shared/${TOGGLE_SELECT_BUBBLES_POPUP}`, params);
const setLoading = params => store.dispatch(`intelligence/shared/${SET_LOADING}`, params);
const setActiveColumns = params => store.dispatch(`intelligence/shared/${SET_ACTIVE_COLUMNS}`, params);

onBeforeMount(() => {
  setDrillsDataByQuery(query.value);
  setFiltersFromSessionStorage();
  applyAxis({
    xAxis: query.value['xaxis'] || null,
    yAxis: query.value['yaxis'] || null,
  });

  if (isReportSvG.value) {
    applyDims({
      dim1: query.value['dim1'] || null,
      dim2: query.value['dim2'] || null
    });
  }

  initializeDates();
  if (query.value.measure) applyMeasure(query.value.measure);
  if (query.value.activeColumns) setActiveColumns(Array.isArray(query.value.activeColumns) ? query.value.activeColumns : [query.value.activeColumns]);
});

onMounted(async() => {
  applyFiltersToQuery();
  if (!bubbleData.value) fetchBubbleData();
  setDimensionsNumber(props.reportType);
  if (query.value.filter) {
    const filter = query.value.filter;
    let filterLength = 1;
    await fetchBubbleData();
    if (Array.isArray(filter)) {
      filter.forEach(async (val, index) => {
        applyFilterFromUrl(val, index);
      });
      filterLength = filter.length;
    } else {
      applyFilterFromUrl(filter, 0);
    }
    const refreshReportInterval = setInterval(() => {
      if (filterLength === filtersApplied.value.length) {
        applyFiltersToQuery();
        resetReportParameters();
        refreshReport();
        clearInterval(refreshReportInterval);
      }
    }, 100);
  }
});
onBeforeUnmount(() => {
  clearEnquiryData();
});
  
function isDrillAbandoned(drillLevel) {
  // get current drill, if NaN then set to 0
  const currentDrill = Number(getDimLevel(allParams.value?.activeDim)) || 0;
  return currentDrill < Number(getDimLevel(drillLevel));
}
function getDimLevel(dim) {
  return dim?.split('-dim')[0];
}
function updateRouteQuery(queryData) {
  if (!iframe.value) {
    const route = vrouter.resolve({ query: { ...vroute.query, ...queryData } });
    history.replaceState({}, '', route.href);
  }
}
function initializeDates() {
  const sessionStorageDates = storage.get(DATES_ITEM);
  let dates = {
    date_from: 'today/y',
    date_to: 'today',
    date_from2: 'today/y-1y',
    date_to2: 'today-1y',
  };
  if (datesFromQuery.value.date_from && datesFromQuery.value.date_to) {
    dates = datesFromQuery.value;
    storage.set(DATES_ITEM, dates);
  } else if (!iframe.value && sessionStorageDates && sessionStorageDates.date_from && sessionStorageDates.date_to) {
    dates = sessionStorageDates;
  }
  dateParams.value = { ...dates };
}
function setFiltersFromSessionStorage() {
  const filters = storage.get('filters');
  if (filters) {
    setFilters(filters);
    storage.set('filters', null);
  }
}
function processBubbleData(bubbleId, description, key) {
  if (!bubbleData.value) return;

  let id, icon, area;
  id = icon = area = 'calendar';

  if (key === ENQUIRY_AXIS_X.value) description = ENQUIRY_AXIS_X.title;

  if (key === ENQUIRY_AXIS_Y.value) description = ENQUIRY_AXIS_Y.title;

  let title = capitalize(bubbleId, { onlyFirstLetter: true }),
    color = 'transparent',
    disabled = true;

  if (bubbleData.value.some(bubble => bubble.id === bubbleId)) {
    const selectedBubble = bubbleData.value.find(bubble => bubble.id === bubbleId);
    ({ id, icon, title, area, color } = { ...selectedBubble });
    disabled = false;
  }

  return {
    id,
    icon,
    title,
    area,
    iconBackground: color,
    description,
    disabled,
    clickable: false,
    xButtonEvent: key === undefined && !disabled,
    checked: false,
  };
}
function handleEnquiryDrill(data) {
  const { id, checked } = data;
  if (checked) {
    setDrillActiveRows([...drillActiveRows.value, { ...data }]);
  } else {
    setDrillActiveRows(drillActiveRows.value.filter(activeRow => activeRow.id !== id));
  }
}
function routerPush(routeObject) {
  const route = vrouter.resolve(routeObject);
  navigateToUrl(route.href);
}
function drillSelectedHandler(bubbles) {
  isReportEnabled.value = false;
  toggleDrillOption(false);
  abandonedDrills.value = [];
  const drillQuery = Object.keys(temporaryQuery.value).length ? { ...temporaryQuery.value } : { ...query.value };


  if (isSalesVsGaps.value) {
    const activeDim = query.value.activeDim;

    const desiredDrillLevel = (Number(activeDim?.split('-')[0]) || 0) + 1;
    const desiredDrillKey = `${desiredDrillLevel}-dim1`;
    
    drillQuery['activeDim'] = desiredDrillKey;

    for (const key in drillQuery) {
      const numberPart = parseInt(key.split('-')[0]);
      if (numberPart >= desiredDrillLevel) {
        delete drillQuery[key];
        delete drillQuery[`${key}values`];
      }
    }
  }

  drillActiveRows.value.forEach(drillActiveRow => {
    addDrillingDimensionRowValuesOnLevel({
      values: isMatrixBasedReport.value ? { [bubbles.id]: drillActiveRow.item } : drillActiveRow.item,
      level: getDrillsLevelBasedOnQuery(drillQuery),
      isMatrixType: isMatrixBasedReport.value,
    });
  });

  if (!isMatrixBasedReport.value) {
    const level = getDrillsLevel() + 1;
    for (const bubble of bubbles) {
      addDrillingDimensionOnLevel({ dimensionId: Object.values(bubble)[0], level });
    }
  }

  const queryToPush = {
    ...drillQuery,
    ...getDrillsQueryParams.value,
  };
  iframe.value ?
    emit('updateQuery', queryToPush) : routerPush({ query: queryToPush });

  generateDrilledReport();
  temporaryQuery.value = {};
  breadcrumbsDigging.value = false;
  setDrillActiveRows([]);
  window.scrollTo(0, 0);
}
function gotoDrillMultiDim(key) {
  const newQuery = { ...query.value };
  const desiredDrillsLevel = key;
    
  newQuery['activeDim'] = desiredDrillsLevel;
    
  iframe.value ? emit('updateQuery', newQuery) : routerPush({ query: newQuery });
    
  const drillLevel = Number(key.split('-')[0]) || 0;
  let activeDimensionValues = newQuery[`${drillLevel+1}-dim1values`];
  if (drillLevel === 0) {
    activeDimensionValues = newQuery['1-dim1values'];
  }

  let activeRows = [];

  if (activeDimensionValues && typeof activeDimensionValues === 'string') {
    activeRows.push({
      id: activeDimensionValues.replace(':', ','),
      item: {
        pair: activeDimensionValues.split(':')[1],
        value: activeDimensionValues.split(':')[0],
      },
      checked: true,
    });
  } else {
    for (let i = 0; i < activeDimensionValues?.length; i++) {
      activeRows.push({
        id: activeDimensionValues[i].replace(':', ','),
        item: {
          pair: activeDimensionValues[i].split(':')[1],
          value: activeDimensionValues[i].split(':')[0],
        },
        checked: true,
      });
    }
  }

  setDrillActiveRows(activeRows);

  for (const key in newQuery) {
    const numberPart = parseInt(key.split('-')[0]);
    if (numberPart > drillLevel) {
      delete newQuery[key];
      delete newQuery[`${key}values`];
    }
  }

  setDrillsDataByQuery(newQuery);
  generateDrilledReport();
  window.scrollTo(0, 0);
}
function goToSelectedDrill(drillParam) {
  if (
    (drillParams.value.length && drillParams.value.at(-1).includes(drillParam)) ||
      (getDrillsLevel() === 1 &&
        drillParam === Object.values(enquiryParams.value)[0] &&
        !isMatrixBasedReport.value)
  )
    return;

  isReportEnabled.value = false;

  if (!breadcrumbsDigging.value) {
    savedQuery.value = query.value;
    breadcrumbsDigging.value = true;
  }
  setDrillsDataByQuery(savedQuery.value);
  abandonedDrills.value = [];
  const desiredDrillsLevel = getDrillsLevel(drillParam);
  const drillQuery = { ...savedQuery.value };

  // if it's Matrix or SvG report, then assign drillParam from upper level
  if (isReportMatrix.value && getDrillsLevel() !== getDrillsLevel(drillParam)) {
    const flattenDrillParams = drillParams.value.flat();
    const indexOfNewDrillParam = flattenDrillParams.findIndex(param => param === drillParam) + 1;
    drillParam = flattenDrillParams[indexOfNewDrillParam];
  }

  if (isSalesVsGaps.value)
    drillQuery[`${desiredDrillsLevel}-dim1`] = drillParam;

  generateDrillActiveRows([drillParam, isMatrixBasedReport.value]);
  processDrillsQuery(desiredDrillsLevel, drillQuery);

  setDrillsDataByQuery(drillQuery);
  generateDrilledReport();
  window.scrollTo(0, 0);
}
function processDrillsQuery(desiredDrillsLevel, drillQuery) {
  for (let i = desiredDrillsLevel; i <= getDrillsLevel(); i++) {
    if (i > desiredDrillsLevel) {
      abandonedDrills.value.push([drillQuery[`${i}-dim1`], ...(drillQuery[`${i}-dim2`] ? [query[`${i}-dim2`]] : [])]);
      delete drillQuery[`${i}-dim1`];
      delete drillQuery[`${i}-dim2`];
    }
    if (!(isMatrixBasedReport.value && i === desiredDrillsLevel)) {
      delete drillQuery[`${i}-dim1values`];
      delete drillQuery[`${i}-dim2values`];
    }
  }
  temporaryQuery.value = drillQuery;
}
async function generateDrilledReport() {
  applyOffsetY(0);
  await fetchDates(dateParams.value);
  applyDate(fetchedDates.value);
  refreshReport();
  if (abandonedDrills.value.length && isMobile.value) {
    toggleSelectBubblesPopup();
    setLoading(true);
    return;
  }
  disableSelectDimensionsPopup();
}
async function setDate(event) {
  let dates = event.dates || event;
  const eventValues = Object.values(dates);
  const data = {};

  if (eventValues.includes(null)) return;

  if (eventValues.includes(undefined)) dates = dateParams.value;

  Object.keys(query.value).forEach(key => {
    if (!DATE_FILTER_KEYS.includes(key)) {
      data[key] = query.value[key];
    }
  });

  if (!iframe.value) {
    const route = await vrouter.resolve({
      name: `${props.reportType}-${reportScope}`,
      query: {
        ...data,
        ...dates,
      },
    });
    navigateToUrl(route.href);

    storage.set(DATES_ITEM, dates);
  }

  applyAxis({
    xAxis: isReportSvG.value
      ? periodFilter.value
      : enquiryParams.value[dimension1.value.value],
    yAxis: isReportSvG.value ? '' : enquiryParams.value[dimension2.value.value]
  });

  isReportSvG.value &&
      applyDims({
        dim1: query.value['dim1'] || null,
        dim2: query.value['dim2'] || null
      });

  if (isRolldate(dates)) {
    await fetchDates(dates);
    dates = fetchedDates.value;
  }
  applyDate(dates);
  dateParams.value = dates;

  if (isDateFilterActive.value) refreshReport();
  toggleDateFilter();
  setDrillActiveRows([]);
}
function toggleDateFilter() {
  isDateFilterActive.value = !isDateFilterActive.value;
  isPeriodFilterActive.value = false;
}
function togglePeriodFilter() {
  isPeriodFilterActive.value = !isPeriodFilterActive.value;
  isDateFilterActive.value = false;
}
function togglePercentValue() {
  isPercentValueActive.value = !isPercentValueActive.value;
}
function resetReport(reportType) {
  window.scrollTo(0, 0);
  if (iframe.value) emit('resetReport', { reportType, query: dateParams.value });
  
  else {
    const route = vrouter.resolve({
      name: reportType,
      query: { ...dateParams.value },
      
    });
    navigateToUrl(route.href);
  }
}
function setActivePeriod() {
  isPeriodFilterActive.value = false;
  const queryToPush = { ...query.value, xaxis: periodFilter.value };
  iframe.value ? emit('updateQuery', queryToPush) : routerPush({ query: queryToPush });
  applyAxis({
    xAxis: isReportSvG.value
      ? periodFilter.value
      : enquiryParams.value[dimension1.value.value]
  });
  refreshReport();
  setDrillActiveRows([]);
}
function handleChartBtnClick() {
  isChart.value = !isChart.value;
}
</script>
<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.data {
  padding-left: 0;
  padding-right: 0;
  background: var(--colour-panel-g-2);
  padding-bottom: var(--spacing-2);
  position: relative;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-left: clamp(var(--spacing-2), 2.5vw, var(--spacing-4));
    padding-right: clamp(var(--spacing-2), 2.5vw, var(--spacing-4));
  }

  &.datepicker {
    background: var(--colour-panel-g-2);
    padding-top: 0;
    padding-bottom: var(--spacing-5);
    position: relative;
  }
}

.bubbles {
  background: var(--colour-utility-white);
  z-index: 100;
  box-shadow: 0 0 var(--spacing-half) 1px var(--colour-panel-g-16);
  border-radius: var(--border-radius-1);
  margin: 0 auto;
  overflow: auto;
  position: relative;
  max-width: calc(100% - 32px);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: absolute;
    top: 0;
    bottom: var(--spacing-2);
    right: var(--spacing-2);
    left: 30%;
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    left: 24%;
  }
}

.background-cover {
  background: var(--colour-panel-alert-base);
  height: 32px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: initial;
}

.nav-loading-spinner {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;
  background: var(--colour-utility-white);
  bottom: 0;
  width: 100%;
  height: 66vh;
  display: flex;
  justify-content: center;
  align-items: center;

  :deep(img) {
    width: var(--spacing-10);
    height: var(--spacing-10);
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: none;
  }
}

/** copy-pasted styles from EnquiryNav.vue */
.chart-button.button {
    box-shadow: 0 0 4px 1px var(--colour-panel-g-16);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--colour-utility-white);

    &.active {
      background: var(--colour-utility-black);
    }

    &.disabled {
      background: var(--colour-panel-g-32);
      cursor: not-allowed;
    }
  }
</style>