import {
  GET_FEATURES,
  GET_FEATURES_LOADING,
  GET_FEATURES_ERROR
} from '@/admin/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const featuresMutations = {
  [GET_FEATURES]: (state, data) => {
    state.features.data = data;
    state.features.loading = false;
    state.features.loaded = true;
  },
  [GET_FEATURES_LOADING]: (state) => {
    state.features.loading = true;
    state.features.loaded = false;
  },
  [GET_FEATURES_ERROR]: (state, errors) => {
    state.features.errors = errors;
    state.features.loading = false;
    state.features.loaded = false;
  },
};


// actions
export const featuresActions = {
  [GET_FEATURES]: async ({ commit }) => {
    commit(GET_FEATURES_LOADING);
    try {
      let facets = await httpRequest('get', 'subjects/facets', {}, {
        version: 2,
        area: 'iam',
      });
      commit(GET_FEATURES, facets.permissions);
    } catch (error) {
      commit(GET_FEATURES_ERROR);
    }
  },
};
