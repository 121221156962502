import {
  CLEAR_CURRENTLY_LOADED,
} from '@/admin/store/actionType';

export const clearCurrentlyLoadedMutators = {
  [CLEAR_CURRENTLY_LOADED]: (state) => {
    state.loaded = false;
    state.loading = false;
    state.error = null;
    state.data = [];
    state.currentlyLoaded = [];
  },
};

export const clearCurrentlyLoadedActions = {
  [CLEAR_CURRENTLY_LOADED]: ({ commit }) => {
    commit(CLEAR_CURRENTLY_LOADED);
  },
};
