<template>
  <template v-if="isReportDataLoaded">
    <h4>{{ subtitle }}</h4>
    <template v-if="reportData?.length">
      <ReportTable
        class="report-table"
        :report-data="reportData"
        :visual-input="visualInput"
      />
    </template>
    <p v-else>
      {{ t('No results were found') }}
    </p>
  </template>
  <BufferImage
    v-if="!isReportDataLoaded"
    class="loading-spinner mt-4"
    color="var(--colour-utility-black)"
    float="center"
  />
</template>

<script setup>
// InteractionSpendSummaryContent
import { computed, onMounted, ref, onBeforeUnmount, watch } from 'vue';
import { BufferImage } from '@sales-i/dsv3';
import { dates, t } from '@sales-i/utils';
import ReportTable from '@/intelligence/components/Dashboard/Elements/ReportTable.vue';
import { useStore } from 'vuex';
import { CLEAR_REPORT_DATA, FETCH_REPORT_DATA } from '@/intelligence/store/actionType';
import { DEFAULT_WIDGET_SETTINGS, } from '@/intelligence/components/Dashboard/constants';
import { useWidget } from '@/intelligence/components/Dashboard/composables/useWidget';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  settings: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['update:menuOptions']);

onMounted(() => {
  loadReport();
  emit('update:menuOptions', menuOptions.value);
});

onBeforeUnmount(() => {
  clearReportData();
});

const store = useStore();
const {
  fetchDates,
  setLoading,
  getDateParams,
} = useWidget({ store, props, options: { chartTypes: [] } });

const dateParams = computed(() => getDateParams(filters.value));
const fd = dates.format;

watch(
  () => props.settings,
  () => {
    emit('update:menuOptions', menuOptions.value);
  },
  { deep: true }
);

watch(
  () => props.settings.limit,
  () => {
    loadReport();
  }
);

const fetchedDates = ref({});
const subtitle = computed(() => `${fd(fetchedDates.value.date_from)}-${fd(fetchedDates.value.date_to)}, ${fd(fetchedDates.value.date_from2)}-${fd(fetchedDates.value.date_to2)}`);

const isReportDataLoaded = ref(false);

const filters = computed(() => props.item.requestParams);

const reportId = ref(0);
const reportData = computed(() => store.getters['intelligence/shared/getReportData'](reportId.value)?.customers);
const inputData = computed(() => store.getters['intelligence/shared/getInputData'](props.item.reportType));

// overload first column by report_type request param
const visualInput = computed(() => inputData.value?.visualInput);

const menuOptions = computed(() => []);
const fetchReportData = params => store.dispatch(`intelligence/shared/${FETCH_REPORT_DATA}`, params);
const clearReportData = params => store.dispatch(`intelligence/shared/${CLEAR_REPORT_DATA}`, params);
const report_type = computed(() => filters.value.report_type || 'customer');

async function loadReport(completeRefresh = false) {
  isReportDataLoaded.value = false;
  setLoading(true);
  fetchedDates.value = await fetchDates(dateParams.value);
  reportId.value = await fetchReportData({
    reportType: props.item.reportType,
    completeRefresh,
    customParams: {
      ...fetchedDates.value,
      report_type: report_type.value,
      offset: 0,
      limit: props.settings.limit || DEFAULT_WIDGET_SETTINGS.limit,
      currentSortHeader: inputData.value.defaultSortHeader,
      currentSortOption: inputData.value.defaultSortOrder
    }
  });
  isReportDataLoaded.value = true;
}
</script>

<style lang="scss" scoped>
h4 {
  text-align: center;
}

.report-table {
  margin-bottom: var(--spacing-1);
  max-height: 260px;
  /** todo: improve this later - it's temporary solution to not let chart be on top of the table */
  z-index: 1;
}
</style>
