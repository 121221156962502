import {
  PATCH_ITEM,
  PATCH_ITEM_LOADING,
  PATCH_ITEM_ERROR
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import { mapWidgetForAPI } from './mappings';

// mutations
export const patchItemMutations = {
  [PATCH_ITEM]: (state, item) => {
    state.selected.data.widgets = state.selected.data.widgets.map(widget => widget.id !== item.id 
      ? widget
      : {
        ...widget,
        ...item
      });
    state.selected.loading = false;
    state.selected.loaded = true;
  },
  [PATCH_ITEM_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [PATCH_ITEM_ERROR]: (state) => {
    state.selected.loading = false;
    state.selected.loaded = false;
  },
};

// actions
export const patchItemActions = {
  [PATCH_ITEM]: async ({ commit, }, item) => {
    commit(PATCH_ITEM_LOADING);
    try {
      const payload = mapWidgetForAPI(item, false);
      await httpRequest('patch', `dashboards/${item.dashboard_id}/widget/${item.id}`, payload, { area: 'dashboard' });
      commit(PATCH_ITEM, item);
      return true;
    } catch (error) {
      console.error(error);
      commit(PATCH_ITEM_ERROR);
      return false;
    }
  },
};
