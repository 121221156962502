<template>
  <div class="form-content">
    <BufferImage
      v-if="fieldsLoading"
      class="buffer"
      color="var(--colour-utility-black)"
      float="center"
    />
    <form
      v-if="displayForm"
      class="form-grid"
    >
      <div
        v-if="!fieldsLoading"
        class="two-col-equal-height"
      >
        <div>
          <div
            v-for="item in columns[0]"
            :key="item.id"
          >
            <Field
              v-bind="item"
              @valid="validatedField"
            />
          </div>
        </div>

        <div>
          <div>
            <div
              v-for="item in columns[1]"
              :key="item.id"
            >
              <Field
                v-bind="item"
                @valid="validatedField"
              />
            </div>
          </div>

          <div>
            <div
              v-for="item in columns[2]"
              :key="item.id"
            >
              <Field
                v-bind="item"
                @valid="validatedField"
              />
            </div>
          </div>
        </div>
        <div>
          <InteractionTimescaleForm
            v-if="!fieldsLoading"
            :title="t('Do you need to schedule a follow-up interaction?')"
            :is-generate="false"
            is-open-follow-up
            :enabled-schedules="['schedule']"
            :date-time="timescale.date"
            :start-hours="startHours"
            :start-minutes="startMinutes"
            :duration="timescale.duration"
            :reset-signal="resetSignal"
            @request-reset="handleReset"
            @set-date="setDate"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { computed, onMounted, watch, ref } from 'vue';
import { t, dates } from '@sales-i/utils';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';
import { BufferImage } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field.vue';
import InteractionTimescaleForm from '@/crm/components/Calendar/InteractionTimescaleForm.vue';
import { useUsers } from '@/shared/composables/useUsers';
import { useInteraction } from '@/crm/composables/useInteraction';
import useFields from '@/shared/composables/useFields';

// Props
defineProps({
  resetSignal: {
    type: Number,
    default: 0
  },
});

const timescale = ref({ duration: { minutes: 15 } });

const store = useStore();
const vroute = useRoute();

const emit = defineEmits(['stageUpdate', 'valid', 'onFollowUp']);

const { usersOptions } = useUsers(store);
const { selected, currentlyEditing, setFurtherDetails, } = useInteraction({ store, vroute });

const { 
  loading: fieldsLoading, 
  interactionOptionsFields, 
  interactionTypeOptions,
  interactionOutcomeOptions,
  getFields 
} = useFields({ store });

const userDetailsId = computed(() => store.state.userDetails?.data?.id);
const displayForm = computed(() => vroute.params.id ? selected.value.loaded : true);
const firstColumn = computed(() => [purposeField.value, interactionNote.value]);
const secondColumn = computed(() => [interactionType.value]);
const thirdColumn = computed(() => [interactionActions.value, assigneesField.value, interactionOutcome.value]);
const purposeField = computed(() => ({
  ...interactionOptionsFields.value[1],
  value: currentlyEditing.value.furtherDetails.purpose,
  valid: !!currentlyEditing.value.furtherDetails.purpose,
}));

const interactionNote = computed(() => {
  let field = {
    label: t('Interaction note'),
    id: 'interactionNote',
    type: 'textarea',
    errorMessage: t('Please enter an interaction note'),
    maxLength: 280,
  };
  if (currentlyEditing.value.furtherDetails.interactionNote) {
    field.value = currentlyEditing.value.furtherDetails.interactionNote;
  }
  return field;
});

const interactionType = computed(() => {
  // Loop through all items in the interactionTypeOptions array
  let options = [];

  interactionTypeOptions.value?.forEach(item => {
    if (item.name !== 'Personal') {
      options.push({
        text: item.name,
        value: item.id,
      });
    }
  });
  let field = {
    label: t('Interaction type'),
    id: 'interactionType',
    type: 'select',
    options,
    valid: false,
    required: true,
    errorMessage: t('Please select an interaction type'),
  };
  if (currentlyEditing.value.furtherDetails.interactionType) {
    field.value = currentlyEditing.value.furtherDetails.interactionType;
    field.valid = true;
  }

  return field;
});

const interactionOutcome = computed(() => {
  let options = [];
  interactionOutcomeOptions.value?.forEach(item => {
    if (item.name !== 'Personal') {
      options.push({
        text: item.name,
        value: item.id,
      });
    }
  });

  let field = {
    label: t('Outcome'),
    id: 'outcome',
    type: 'select',
    subtitle: t('populating the outcome field will automatically "complete" this interaction'),
    options,
    valid: true,
    errorMessage: t('Please select an outcome'),
  };
  if (currentlyEditing.value.furtherDetails.outcome) {
    field.value = currentlyEditing.value.furtherDetails.outcome;
    field.valid = true;
  }
  return field;
});

const interactionActions = computed(() => {
  // Filter getInteractionsOptionsFields array to get the nextAction options
  let options = interactionOptionsFields.value.filter(item => item.id === 'nextAction')[0].options;
  let field = {
    label: t('Next action'),
    id: 'nextAction',
    type: 'select',
    options,
    errorMessage: t('Please enter a next action'),
  };
  if (currentlyEditing.value.furtherDetails.nextAction) {
    field.value = currentlyEditing.value.furtherDetails.nextAction;
    field.valid = true;
  }
  return field;
});

const assigneesField = computed(() => {
  let field = {
    label: t('Assignee'),
    id: 'assignee',
    type: 'select',
    options: usersOptions.value,
    errorMessage: t('Please select colleagues'),
  };
  if (currentlyEditing.value.furtherDetails.assignee) {
    field.value = currentlyEditing.value.furtherDetails.assignee;
  } else if (userDetailsId.value) {
    const assignedUser = usersOptions.value.find(user => user.value === userDetailsId.value);
    if (assignedUser) {
      field.value = assignedUser.value;
    }
  }
  return field;
});

const columns = computed(() => [firstColumn.value, secondColumn.value, thirdColumn.value]);
const valid = computed(() => validateForm(false));
const startHours = computed(() => dates.getStartHours(timescale.value.date));
const startMinutes = computed(() => dates.getStartMinutes(timescale.value.date));

watch(() => valid, (newVal) => {
  emit('valid', newVal);
});

onMounted(() => {
  emit('stageUpdate', 'Customer/contact');
  getFieldsForInteractions();
  emit('valid', valid);
});

const buildFurtherDetails = () => {
  let furtherDetails = {};
  columns.value.forEach(fields =>
    fields.forEach(field => {
      furtherDetails[field.id] = field.value;
    })
  );
  return furtherDetails;
};

const getFieldsForInteractions = () => {
  if (vroute.params.id) {
    window.setTimeout(() => {
      if (Object.keys(currentlyEditing.value.furtherDetails).length > 0) {
        getFields({ entity: 'interactions' });
      } else {
        getFieldsForInteractions();
      }
    }, 100);
  } else {
    getFields({ entity: 'interactions' });
  }
};

const validatedField = field => {
  columns.value.forEach(fields =>
    fields.forEach(foundField => {
      if (foundField.id === field.id) {
        foundField.value = field.value;
        foundField.valid = field.isValid;
      }
    })
  );
  let furtherDetails = buildFurtherDetails();
  setFurtherDetails(furtherDetails);
};

const validateForm = (showUpdates = true) => {
  let valid = true;
  let focused = false;
  let firstFieldWithError = null;

  columns.value.forEach(fields =>
    fields.forEach(field => {
      if (field.valid === false) {
        let fieldInDoc = document.querySelector(`#${field.id}`);
        if (fieldInDoc && showUpdates) {
          fieldInDoc.focus();
        }
        if (!focused) {
          focused = true;
          firstFieldWithError = fieldInDoc;
        }
        valid = false;
      }
    })
  );

  if (firstFieldWithError && showUpdates) {
    firstFieldWithError.focus();
  }
  return valid;
};

const setDate = (value) =>  {
  timescale.value = value;
  emit('onFollowUp', value);
};

const handleReset = () => {
  startHours.value = '';
  startMinutes.value = '';
  timescale.value = {};
};
</script>

<style lang="scss" scoped>
// deep allows us to target a scoped element in order to apply an override to just this instance of the component styles. Ultimately this would be better as a component with a gap prop.
.two-col-equal-height {
  display: grid;
  gap: 0 var(--spacing-4);
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
}
</style>