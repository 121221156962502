<template>
  <div id="communities-chart-wrapper">
    <svg
      id="communities-chart"
      class="chart"
    />
    <div id="communities-chart-product-name-tooltip" />
    <div class="chart-key">
      <p>
        {{ t('Key:') }}
        <button
          :class="['dot', 'hub', {'selected': type === 'hubs'}]"
          @click="showHubs"
          @keydown="showHubs"
        >
          {{ '&nbsp;' }}
        </button>
        {{ t('Hub') }}
        <button
          :class="['dot', 'influencer', {'selected': type === 'influencers'}]"
          @click="showInfluencers"
          @keydown="showInfluencers"
        >
          {{ '&nbsp;' }}
        </button>
        {{ t('Influencer') }}
        <button
          :class="['dot', 'hubandinfluencer', {'selected': type === 'hubsandinfluencers'}]"
          @click="showHubsAndInfluencers"
          @keydown="showHubsAndInfluencers"
        >
          {{ '&nbsp;' }}
        </button>
        {{ t('Hub & Influencer', 'hub_and_influencer') }}
        <button
          :class="['dot', 'associated', {'selected': type === 'associated'}]"
          @click="showAssociated"
          @keydown="showAssociated"
        >
          {{ '&nbsp;' }}
        </button>
        {{ t('Associated') }}
      </p>
    </div>
  </div>
</template>

<script type="text/javascript">
import * as d3 from 'd3';
import { mapState } from 'vuex';
import { t } from '@sales-i/utils';

export default {
  name: 'CommunityChart',
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    dataChanged: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      svgWidth: 610,
      svgHeight: 550,

      type: 'all',
    };
  },
  computed: {
    ...mapState({
      communities: state => state.admin.communities,
    }),
    tShirtSizing() {
      switch (true) {
      case this.communities.currentlyLoaded.length > 100:
        return {
          large: 10,
          regular: 7,
          gravity: -7,
        };
      default:
        return {
          large: 13,
          regular: 10,
          gravity: -100
        };
      }
    },
    filteredProducts() {
      const products = [];
      let x;

      for (x in this.communities.currentlyLoaded) {
        const product = this.communities.currentlyLoaded[x];

        if (product.product_code === '') {
          continue;
        }

        const found = products.find(o => {
          return o.code === product.product_code;
        });

        if (found !== undefined) {
          continue;
        }

        switch(this.type) {
        case 'hubs':
          if (product.is_hub) {
            products.push({
              hub: product.is_hub,
              influencer: product.is_influencer,
              code: product.product_code,
              name: product.product_name,
              target: product.consequent_links,
              strength: product.strength,
            });
          }
          break;
        case 'influencers':
          if (product.is_influencer) {
            products.push({
              hub: product.is_hub,
              influencer: product.is_influencer,
              code: product.product_code,
              name: product.product_name,
              target: product.consequent_links,
              strength: product.strength,
            });
          }
          break;
        case 'hubsandinfluencers':
          if (product.is_hub && product.is_influencer) {
            products.push({
              hub: product.is_hub,
              influencer: product.is_influencer,
              code: product.product_code,
              name: product.product_name,
              target: product.consequent_links,
              strength: product.strength,
            });
          }
          break;
        case 'associated':
          if (!product.is_hub && !product.is_influencer) {
            products.push({
              hub: product.is_hub,
              influencer: product.is_influencer,
              code: product.product_code,
              name: product.product_name,
              target: product.consequent_links,
              strength: product.strength,
            });
          }
          break;
        default:
          products.push({
            hub: product.is_hub,
            influencer: product.is_influencer,
            code: product.product_code,
            name: product.product_name,
            target: product.consequent_links,
            strength: product.strength,
          });
        }
      }

      return products;
    },
  },
  watch: {
    expanded() {
      setTimeout(() => this.generateChart(), 250);
    },
    dataChanged() {
      if (this.dataChanged === true) {
        this.generateChart();
      }
    },
  },
  mounted() {
    this.generateChart();
  },
  methods: {
    t,
    showHubs() {
      this.showParticularNodeType('hubs');
    },
    showInfluencers() {
      this.showParticularNodeType('influencers');
    },
    showHubsAndInfluencers() {
      this.showParticularNodeType('hubsandinfluencers');
    },
    showAssociated() {
      this.showParticularNodeType('associated');
    },
    showParticularNodeType(type) {
      if (this.type === type) {
        this.type = 'all';
      } else {
        this.type = type;
      }

      this.generateChart();
    },
    generateChart() {
      // append the svg object to the body of the page
      const wrapper = document.getElementById('communities-chart-wrapper');
      let width = this.svgWidth;
      if (wrapper !== null) {
        width = wrapper.offsetWidth;
      }

      const svg = d3.select('svg#communities-chart').attr('width', width).attr('height', this.svgHeight);

      svg.selectAll('*').remove();

      var dragDrop = d3
        .drag()
        .on('start', function (event, node) {
          node.fx = node.x;
          node.fy = node.y;
        })
        .on('drag', function (event, node) {
          simulation.alphaTarget(0).restart();
          node.fx = event.x;
          node.fy = event.y;
        })
        .on('end', function (event, node) {
          if (!event.active) {
            simulation.alphaTarget(0);
          }
          node.fx = null;
          node.fy = null;
        });

      const simulation = d3
        .forceSimulation()
        .force('charge', d3.forceManyBody().strength(this.tShirtSizing.gravity))
        .force('center', d3.forceCenter(width / 2, this.svgHeight / 2))
        .force(
          'collision',
          d3.forceCollide().radius(d => d.radius)
        );

      svg
        .append('defs')
        .selectAll('marker')
        .data(['end'])
        .enter()
        .append('svg:marker')
        .attr('id', 'end')
        .attr('viewBox', '0 -5 10 10')
        .attr('refX', 18)
        .attr('markerWidth', '10')
        .attr('markerHeight', '10')
        .attr('orient', 'auto')
        .append('svg:path')
        .attr('fill', 'var(--colour-data-mandy)')
        .attr('stroke', '#E5E5E5')
        .attr('d', 'M0,-5L10,0L0,5');

      // Prepare the Data
      const data = this.prepareReportData();
      const links = this.prepareReportLinks();

      const linkElements = svg
        .append('g')
        .selectAll('line')
        .data(links)
        .enter()
        .append('line')
        .attr('stroke-width', 1)
        .attr('stroke', 'var(--colour-data-mandy)')
        .attr('marker-end', 'url(#end)');

      const Tooltip = d3.select('#communities-chart-product-name-tooltip');
      const textElements = svg
        .append('g')
        .selectAll('text.annotation')
        .data(data)
        .enter()
        .append('text')
        .text(node => node.id)
        .attr('class', 'annotation')
        .attr('font-size', 15)
        .attr('dx', 15)
        .attr('dy', 4)
        .on('mouseover', (event, d) => {
          Tooltip.style('display', 'block')
            .style('top', d.y - 75 + 'px')
            .style('left', d.x + 'px');
          Tooltip.html(`(${d.id}) ${d.name}`);
        })
        .on('mouseout', () => {
          Tooltip.style('display', 'none');
        });

      const percentElements = svg
        .append('g')
        .selectAll('text.strength')
        .data(links)
        .enter()
        .append('text')
        .text(link => `${link.strength * 100}%`)
        .attr('class', 'strength')
        .attr('font-size', 15)
        .attr('x', 15)
        .attr('dy', 4);

      const nodeElements = svg
        .append('g')
        .selectAll('circle')
        .data(data)
        .enter()
        .append('circle')
        .attr('r', d => {
          if (d.hub || d.influencer) {
            return this.tShirtSizing.large;
          }
          return this.tShirtSizing.regular;
        })
        .attr('fill', this.getNodeColour)
        .call(dragDrop);

      const checkBounds = d => {
        if (d.x < 0) d.x = 0;
        if (d.x > width) d.x = width;
        if (d.y < 0) d.y = 0;
        if (d.y > this.svgHeight) d.y = this.svgHeight;
      };

      simulation.nodes(data).on('tick', () => {
        nodeElements
          .attr('cx', node => {
            checkBounds(node);
            return node.x;
          })
          .attr('cy', node => {
            checkBounds(node);
            return node.y;
          });
        textElements.attr('x', node => node.x).attr('y', node => node.y);
        percentElements.attr('x', d => (d.source.x + d.target.x) / 2).attr('y', d => (d.source.y + d.target.y) / 2);
        linkElements
          .attr('x1', link => link.source.x)
          .attr('y1', link => link.source.y)
          .attr('x2', link => link.target.x)
          .attr('y2', link => link.target.y);
      });

      simulation.force(
        'link',
        d3
          .forceLink()
          .links(links)
          .id(link => link.id)
          .strength(() => .001)
      );
    },
    getNodeColour(node) {
      if (node.hub && node.influencer) {
        return '#7360A4';
      }
      if (node.hub) {
        return '#E96061';
      }
      return node.influencer ? '#64C4E0' : 'gray';
    },
    prepareReportData() {
      // We need to do de-duping for cleanliness
      const reportData = [];
      const products = this.filteredProducts;
      for (let item in products) {
        const product = products[item];
        const found = reportData.find(item => item.id === product.code);
        if (found === undefined && item.target !== '') {
          reportData.push({
            id: product.code,
            name: product.name,
            target: product.target,
            influencer: product.influencer,
            hub: product.hub,
            strength: product.strength,
          });
        }
      }

      return reportData;
    },
    linkExists(target) {
      const reportData = this.prepareReportData();
      const found = reportData.find(product => {
        return target === product.id;
      });
      return found !== undefined;
    },
    prepareReportLinks() {
      const reportData = this.prepareReportData();
      const linkData = [];
      let x;
      for (x in reportData) {
        const product = reportData[x];
        let n;
        for (n in product.target) {
          const target = product.target[n];
          // Check this link doesn't already exist
          const found = linkData.find(o => {
            if ((o.target === target && o.source === product.id) || (o.target === product.id && o.source === target)) {
              return o;
            }
            return false;
          });

          if (this.linkExists(target) && found === undefined && target !== '') {
            linkData.push({
              target: target,
              source: product.id,
              strength: product.strength,
            });
          }
        }
      }

      return linkData;
    },
  },
};
</script>

<style lang="scss" scoped>
#communities-chart-wrapper {
  position: relative;

  #communities-chart-product-name-tooltip {
    position: absolute;
    display: none;
    color: white;
    background: black;
    padding: var(--spacing-2);
    border-radius: var(--border-radius-1);
  }
}

.chart-key {
  margin: var(--spacing-2) 0;

  .dot {
    display: inline;
    background-color: gray;
    padding: 1px 9px;
    border-radius: 100px;
    margin: 0 0 0 var(--spacing-1);
    cursor: pointer;

    &.hub {
      background-color: var(--colour-utility-error);
    }
    &.influencer {
      background-color: var(--colour-brand-viking);
    }
    &.hubandinfluencer {
      background-color: var(--colour-brand-deluge);
    }

    &.selected {
      box-shadow: 0px 0px 0px 2px var(--colour-data-viking-label);
    }
  }
}
</style>
