<template>
  <div class="targets-report-container">
    <CustomCard
      class="targets-report"
      purpose="action"
    >
      <div class="header-section">
        <h3>
          {{ props.activeSection.name }}
        </h3>
        <CustomButton
          purpose="action"
          small
          @on-click="open"
        >
          {{ t('Add a target') }}
        </CustomButton>
      </div>
      <div class="data-section">
        <BufferImage
          v-if="loading"
          color="black"
          float="center"
          class="loading-spinner"
        />
        <table v-else>
          <thead>
            <tr>
              <th class="th-title">
                {{ t('Dimensions') }}
              </th>
              <th class="th-title">
                {{ t('Metric') }}
              </th>
              <th
                v-for="header in targetsData.header"
                :key="header"
              >
                {{ header }}
              </th>
              <th>{{ t('Delete') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="target in targetsData.targets"
              :key="target.id"
            >
              <td class="td-title">
                <CustomButton 
                  class="dimension-link"
                  purpose="text"
                  @on-click="editTarget(target)"
                >
                  {{ target.name }}
                </CustomButton>
                <p>{{ generateDimensionsString(target.dimensions) }}</p>
                <CustomButton 
                  purpose="text"
                  @click="modalTrigger(target)"
                >
                  <p>{{ t('Details') }} {{ countDimensionValues(target) }}</p>
                </CustomButton>
              </td>
              <td class="td-title td-metric">
                <p>{{ target.measurement.metric }}</p>
                <p>{{ target.measurement.unit }}</p>
              </td>
              <td
                v-for="value in target.periodValues"
                :key="value.id"
              >
                {{ value.value }}
              </td>
              <td>
                <CustomButton
                  icon-name="trash"
                  purpose="transparent"
                  aria-label="delete"
                  @on-click="confirmationModalHandler(target.id, target.name)"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <Teleport to="#modal-teleport-target">
          <CustomModal
            v-if="isModalVisible && !loading"
            id="details-modal"
            :title="targetDetails.value.name"
            :show-modal="isModalVisible"
            @close-modal="closeModal"
          >
            <TargetDetailModal
              v-if="!loading"
              :target-details="targetDetails"
            />
          </CustomModal>
        </Teleport>
        <ConfirmationModal
          v-if="isDeleteModal"
          :updated-message="deleteModalProps.updatedMessage"
          :message="deleteModalProps.message"
          :save-label="deleteModalProps.saveLabel"
          :finish-function="deleteModalProps.finishFunction"
          :close-function="deleteModalProps.closeFunction"
          :update-function="deleteModalProps.updateFunction"
        />
      </div>
      <div v-if="!loading">
        <p
          v-if="!targetsData.targets?.length"
          class="no-targets"
        >
          {{ t('No targets') }}
        </p>
        <Pagination
          :offset="paginationData.offset"
          :limit="paginationData.limit"
          :records-count="paginationData.recordsCount"
          @page-changed="handlePageChanged"
        />
      </div>
      <router-view 
        @reload="emit('reload')"
        @close="close"
      />
    </CustomCard>
  </div>
</template>

<script setup>
// TargetsReport
import { useStore } from 'vuex';
import { BufferImage, CustomButton, CustomModal } from '@sales-i/dsv3';
import { t } from '@sales-i/utils';
import { computed, ref } from 'vue';
import { baseUrl, companyArea, targetsSection } from '@/admin/router/urlBits';
import TargetDetailModal from '@/intelligence/components/Targets/TargetDetailModal.vue';
import Pagination from '@/shared/components/Pagination/Pagination.vue';
import ConfirmationModal from '@/shared/components/Modal/ConfirmationModal.vue';
import { FETCH_REPORT_DATA } from '@/intelligence/store/actionType';
import { navigateToUrl } from 'single-spa';

// setup
const store = useStore();
const emit = defineEmits(['reload','loadMoreTargets']);

// data
const isModalVisible = ref(false);
const targetDetails = ({});
const isDeleteModal = ref(false);
const deleteModalProps = ref({});

// props
const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  activeSection: {
    type: Object,
    default: () => ({})
  },
  paginationData: {
    type: Object,
    default: () => ({})
  }
});

// store actions
const deleteTarget = payload => store.dispatch(`intelligence/targets/${FETCH_REPORT_DATA}`, payload);

// computed
const targetsData = computed(() => store.state.intelligence.targets.targetsData || { targets: [] });
const bubbleData = computed(() => store.state.intelligence.enquiry.bubbleData);

// methods
function close() {
  navigateToUrl(`${baseUrl}/${companyArea}/${targetsSection}`);
}
function open() {
  let path = `${baseUrl}/${companyArea}/${targetsSection}/add`;
  let query = new URLSearchParams({ year: props.activeSection.id });
  navigateToUrl(`${path}?${query.toString()}`);
}
function editTarget(target) {
  let path = `${baseUrl}/${companyArea}/${targetsSection}/${target.id}/edit`;
  let query = new URLSearchParams({ year: props.activeSection.id });
  navigateToUrl(`${path}?${query.toString()}`);
}
function generateDimensionsString(dimensionsArray) {
  const getBubble = id => bubbleData.value.find(bubble => bubble.id == id);
  let dimensionsString = '';
  dimensionsArray.forEach(dim => {
    if (dimensionsString) dimensionsString += ' & ';
    if (getBubble(dim.id)) dimensionsString += getBubble(dim.id).title;
  });
  return dimensionsString;
}
function modalTrigger(target) {
  targetDetails.value = target;
  isModalVisible.value = true;
}
function closeModal() {
  isModalVisible.value = false;
}
function countDimensionValues(target) {
  const results = target.dimensions.map(dimension =>
    dimension.values.length === 0 ? '"All"' : dimension.values.length
  );

  return `( ${results.join(', ')} )`;
}
function handlePageChanged(page) {
  emit('loadMoreTargets', page);
}
function confirmationModalHandler(id, targetName) {
  isDeleteModal.value = true;
  deleteModalProps.value = {
    message: t(
      `Do you really want to delete Target ${targetName}?`,
      'do_you_really_want_to_delete_target_fieldname',
      {
        interpolations: {
          fieldname: targetName
        }
      }
    ),
    updatedMessage: t(
      `You have deleted Target ${targetName}`,
      'you_have_deleted_target_fieldname',
      {
        interpolations: {
          fieldname: targetName
        }
      }
    ),
    saveLabel: t('Yes'),
    updateFunction: () => deleteTargethandler(id),
    finishFunction: () => emit('reload'),
    closeFunction: () => isDeleteModal.value = false,
  };
}
async function deleteTargethandler(id) {
  try {
    const result = await deleteTarget({
      type: 'delete',
      params: {
        targetId: id,
      }
    });
    return result;
  } catch (error) {
    console.error('Failed to delete target:', error);
    throw error;
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.targets-report-container {
  padding-inline: var(--spacing-3);
  padding-block-end: var(--spacing-3);
  width: 100%;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-block-start: var(--spacing-3);
  }
}


.targets-report {
  background-color: var(--colour-utility-white);
  color: var(--colour-utility-black);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--colour-panel-g-16);
  padding: var(--spacing-2);
  h3 {
    font-weight: var(--font-weight-semibold);
  }
}

.data-section {
  width: 100%;
  margin-top: var(--spacing-3);
  overflow: auto;
  max-height: 640px;
  height: 100%;
  border-radius: var(--border-radius-1);
  position: relative;
  flex-grow: 1;

  table {
    width: 100%;

    tr {
      background: var(--colour-panel-g-0);

      th {
        text-decoration: none;
        color: var(--colour-utility-black);
        text-align: center;
        padding: var(--spacing-2);
        background: var(--colour-panel-action);
        vertical-align: middle;
        font-size: var(--font-size-body);
        border: 0;
        min-width: unset;
        font-weight: var(--font-weight-regular);

        &.th-title {
          text-align: left;
          min-width: 150px;

          &:first-child {
            min-width: 250px;
          }
        }
      }

      td {
        vertical-align: middle;
        font-size: var(--font-size-body);
        color: var(--colour-utility-black);
        text-align: center;
        border: 0;

        &.td-title {
          text-align: left;
          padding: var(--spacing-2);
        }

        &.td-metric p:first-child {
          font-size: var(--font-size-body);
          color: var(--colour-utility-black);
        }

        p {
          font-size: var(--font-size-small);
          color: var(--colour-utility-base);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .dimension-link {
          font-size: var(--font-size-body);
          font-weight: var(--font-weight-semibold);
          color: var(--colour-utility-action);
          display: inline-flex;
          cursor: pointer;
          text-align: left;
        }

        button {
          display: inline-block;
        }
      }
    }

    tbody {
      background: var(--colour-panel-g-0);

      tr {
        &:nth-child(even) {
          background: var(--colour-panel-g-2);
        }
      }
    }
  }
}

.loading-spinner {
  position: absolute;
  top: calc(50% - var(--spacing-3));
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}
:deep(.content-wrapper) {
  padding-bottom: 0;
}
:deep(.content.top) {
  flex-grow: 1;
}
.no-targets {
  text-align: center;
  font-size: var(--font-size-body);
  color: var(--colour-utility-black);
  margin: var(--spacing-3) 0;
}
</style>
