<template>
  <TableWrapper
    v-if="items.length"
    table-height="510px"
  >
    <table>
      <thead>
        <tr>
          <th
            v-for="(heading, i) in headings"
            :key="i"
          >
            {{ heading.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, rowIndex) in items"
          :key="rowIndex"
        >
          <td
            v-for="(heading, headingIndex) in headings"
            :key="headingIndex"
          >
            <template v-if="heading.value === 'address'">
              <AddressField :addresses="row.addresses" />
            </template>
            <template v-else-if="heading.value === 'created_date'">
              <EntityListViewCell 
                :value="row.created_date"
                type="date" 
              />
            </template>
            <template v-else-if="heading.value === 'decision_date'">
              <EntityListViewCell 
                :value="row.decision_date"
                type="date" 
              />
            </template>
            <template v-else-if="heading.value === 'value'">
              <EntityListViewCell 
                :value="row.value"
                type="price" 
              />
            </template>
            <template v-else-if="heading.value === 'probability' && props.entity === 'opportunity'">
              <EntityListViewCell 
                :value="row.probability"
                type="percent" 
              />
            </template>
            <template v-else-if="heading.value === 'allocated_users' && props.entity === 'opportunity'">
              <OpportunityUserCell :item="row" />
            </template>
            <template v-else-if="heading.value === 'tags'">
              <Tag
                :id="row.id"
                title="Manage"
                :entity="props.entity"
                :show-as-direct-link="true"
              />
            </template>
            <template v-else-if="heading.value === 'links'">
              <a
                v-if="row.id && props.entity === 'customer'"
                :href="openLink(row.id)"
                target="_blank"
              >
                {{ row.name }}
              </a>
              <div
                v-if="row.id && ['prospect', 'opportunity'].includes(props.entity)"
                class="links-container"
              >
                <CustomButton
                  v-for="(link, i) in links"
                  :key="i"
                  purpose="text"
                  @click="handleButtonClick(row.id, link.purpose, row)"
                >
                  {{ link.text }}
                </CustomButton>
              </div>
            </template>
            <template v-else>
              <EntityListViewCell :value="row[heading.value]" />
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </TableWrapper>
  <p 
    v-else
    class="no-results"
  >
    {{ t('No results') }} 
  </p>
</template>

<script setup>
// EntityListViewTable
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { t } from '@sales-i/utils';
import { SET_ROUTE, SET_CONFIRMATION_MODAL, DELETE_BY_ID} from '@/shared/store/actionType';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import AddressField from '@/crm/components/Common/AddressField.vue';
import Tag from '@/shared/components/Tags/Tag.vue';
import { CustomButton } from '@sales-i/dsv3';
import { baseUrl, customersArea, prospectsArea, opportunitiesArea } from '@/crm/router/urlBits';
import OpportunityUserCell from '@/crm/views/Opportunities/OpportunityUserCell.vue';
import EntityListViewCell from '@/shared/components/Tables/EntityListViewCell.vue';
import usePermissions from '@/shared/composables/usePermissions';
import { prospects } from '@/shared/store/data/policies';
import { useOpportunity } from '@/crm/composables/useOpportunity';
import { navigateToUrl } from 'single-spa';

const emit = defineEmits(['closeModal', 'deleted', 'opportunityDeleted', 'edit']);

const vroute = useRoute();
const store = useStore();

const { getPermissions } = usePermissions();
const { showDeleteOpportunityModal } = useOpportunity({ store, emit });
const permissions = getPermissions(prospects);

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  headings: {
    type: Array,
    default: () => [],
  },
  entity: {
    type: String,
    default: '',
  },
  status: {
    type: String,
    default: '',
  }
});

const setRoute = params => store.dispatch(`system/${SET_ROUTE}`, params);
const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);
const deleteProspect = params => store.dispatch(`crm/prospects/${DELETE_BY_ID}`, params);

const selectedLoaded = computed(() => store.state.crm.prospects.selected.loaded);
const links = computed(() => {
  const baseLinks = [{ text: t('Open'), purpose: 'open' }];

  if (props.entity === 'prospect') {
    // Only show edit and delete links for prospects
    if (permissions.update) {
      baseLinks.push({ text: t('Edit'), purpose: 'edit' });
    }
    if (permissions.delete) {
      baseLinks.push({ text: t('Delete'), purpose: 'delete' });
    }
  } else if (props.entity === 'opportunity' && props.status === 'open') {
    // Only show edit and delete links for won opportunities
    if (permissions.update) {
      baseLinks.push({ text: t('Edit'), purpose: 'edit' });
    }
    if (permissions.delete) {
      baseLinks.push({ text: t('Delete'), purpose: 'delete' });
    }
  }
  return baseLinks;
});

function openLink(id) {
  return `${baseUrl}/${customersArea}/${id}`;
}
function handleProspect(id, purpose, prospect) {
  switch (purpose) {
  case 'open':
    navigateToUrl(`${baseUrl}/${prospectsArea}/${id}`);
    break;
  case 'edit':
    setRoute({
      success: `${baseUrl}/${prospectsArea}/${id}`,
      cancel: vroute.fullPath,
    });
    navigateToUrl(`${baseUrl}/${prospectsArea}/${id}/edit`);
    break;
  case 'delete':
    showConfirmationModal({
      message: t(`Do you really want to delete Prospect ${prospect.name}?`, 'do_you_really_want_to_delete_name?', {
        interpolations: {
          variable: prospect.name,
        },
      }),
      updatedMessage: t(`You have deleted Prospect ${prospect.name}`, 'you_have_deleted_prospect_name', {
        interpolations: {
          variable: prospect.name,
        },
      }),
      updateFunction: () => confirmDelete(id),
      finishFunction: () => finishDelete(id),
    });
  }
}
function finishDelete() {
  emit('closeModal');
}
async function confirmDelete(id) {
  try {
    await deleteProspect(id);
    emit('deleted');
    return selectedLoaded;
  } catch (e) {
    console.error(e);
    return false;
  }
}
function handleOpportunity(id, purpose, oppo) {
  switch (purpose) {
  case 'open':
    navigateToUrl(`${baseUrl}/${opportunitiesArea}/${id}`);
    break;
  case 'edit':
    emit('edit', oppo);
    break;
  case 'delete':
    showDeleteOpportunityModal(oppo);
    emit('opportunityDeleted', oppo);
    break;
  default:
    // eslint-disable-next-line no-console
    console.warn(`Unhandled purpose: ${purpose}`);
    break;
  }
}
function handleButtonClick(id, purpose, row) {
  if (props.entity === 'prospect') {
    handleProspect(id, purpose, row);
  } else if (props.entity === 'opportunity') {
    handleOpportunity(id, purpose, row);
  }
}
</script>
<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

table {
  position: relative;
  width: 100%;
}

thead {
  position: relative;
  z-index: 1;
}

tr {
  background: var(--colour-panel-g-0);
  &:nth-child(even) {
    background: var(--colour-panel-g-2);
  }
}

th,
td {
  color: var(--colour-utility-black);
  padding: var(--spacing-2);
  border: none;
}

th {
  font-size: var(--font-size-body);
  background: var(--colour-panel-action);
  color: var(--colour-utility-action);
  position: sticky;
  top: 0;
  text-align: left;
  min-height: 70px;
  min-width: 180px;
  vertical-align: middle;
  padding: var(--spacing-3);
}

td {
  font-size: var(--font-size-small);
  padding: var(--spacing-1) var(--spacing-3);
  line-height: var(--spacing-2);
  text-align: left;
}

.no-results {
  text-align: center;
}

.links-container {
  .button.text {
    display: inline-block;
    margin-right: var(--spacing-1);
  }
}
</style>