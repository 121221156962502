<template>
  <div class="container container--fluid">
    <Tabs
      class="mb-0"
      :items="navigation"
      :selected-index="selectedIndex"
      @nav-clicked="navClicked"
      @tab-selected="tabSelected"
    />
    <div class="padded mb-3">
      <router-view />
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, } from 'vue-router';
import { t } from '@sales-i/utils';
import Tabs from '@/shared/components/Tabs/Tabs.vue';
import usePermissions from '@/shared/composables/usePermissions';
import { product_associations_admin, product_communities_admin, intelligence_admin } from '@/shared/store/data/policies';
import { useHead } from '@unhead/vue';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'SetupTabsView',
  components: {
    Tabs,
  },
  setup() {
    const selectedIndex = ref(0);
    const { permissions: permissionsComputed } = usePermissions();

    const vroute = useRoute();

    const navigation = computed(() => {
      const permissions = permissionsComputed.value;
      let arr = [];
      if (permissions[intelligence_admin] && permissions[intelligence_admin].read) {
        arr.push({
          title: t('Enquiries'),
          icon: 'mdi-information',
          path: '/admin/intelligence/enquiries',
          key: 'enquiries',
        });
      }
      if (permissions[product_associations_admin] && permissions[product_associations_admin].read) {
        arr.push({
          title: t('Associations'),
          icon: 'mdi-account-circle-outline',
          path: '/admin/intelligence/associations',
          key: 'associations',
        });
      }
      if (permissions[product_communities_admin] && permissions[product_communities_admin].read) {
        arr.push({
          title: t('Communities'),
          icon: 'mdi-account-circle-outline',
          path: '/admin/intelligence/communities',
          key: 'communities',
        });
      }
      return arr;
    });

    watch(
      () => vroute.path,
      to => {
        setSelectedIndex(to);
      }
    );

    onMounted(() => {
      setSelectedIndex(vroute.fullPath);
      tabSelected(navigation.value[selectedIndex.value].title);
    });

    function navClicked(data) {
      const { index, item } = data;
      selectedIndex.value = index;
      if (decodeURI(vroute.path) !== item.path) {
        navigateToUrl(item.path);
      }
    }
    function tabSelected(tab) {
      useHead({
        title: tab,
      });
    }

    function setSelectedIndex(path) {
      selectedIndex.value = navigation.value.findIndex(e => {
        const keys = path.split('/').map(x => x.replace('#', ''));
        return keys.includes(e.key);
      });
    }

    return {
      // data
      selectedIndex,
      // computed
      navigation,
      // methods
      navClicked,
      tabSelected,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: var(--spacing-4);
}
</style>
