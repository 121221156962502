<template>
  <AddInteractionDatePickerModal
    v-if="shouldRender"
    schedule="personal"
    :start-time="currentlyEditing.dateTime"
    :duration="{
      hours: readDurationHours,
      minutes: readDurationMinutes,
    }"
    :desc-prop="currentlyEditing.furtherDetails.interactionNote"
    :scroll-to-top="false"
    @close-modal="closeModal"
    @select="selectDateTime"
  />
</template>

<script>
import AddInteractionDatePickerModal from '@/crm/components/Calendar/AddInteraction/AddInteractionDatePickerModal.vue';
import { useInteraction } from '@/crm/composables/useInteraction';
import { baseUrl, baseUrl as crmBaseUrl, interactionsArea } from '@/crm/router/urlBits';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'AddEditPersonal',
  components: {
    AddInteractionDatePickerModal,
  },
  setup() {
    const {
      clearInteractionsById,
      currentlyEditing,
      durationHours,
      durationMinutes,
      getInteraction,
      postInteraction,
      putInteraction,
    } = useInteraction();
    return {
      clearInteractionsById,
      currentlyEditing,
      durationHours,
      durationMinutes,
      getInteraction,
      postInteraction,
      putInteraction,
    };
  },
  data() {
    return {};
  },
  computed: {
    readDurationHours() {
      return this.durationHours || '';
    },
    readDurationMinutes() {
      // If there is no interactionID, then we are adding a new personal interaction, so we should default it to 15 minutes. Only do this if the route exactly matches /crm/interactions/add-personal. Otherwise, clicking back to the first page will reset the user's choice to 15 minutes
      if (!this.$route.params.id && this.$route.path === `${crmBaseUrl}/${interactionsArea}/add-personal`) {
        return this.durationMinutes || 15;
      }
      return this.durationMinutes || '';
    },
    shouldRender() {
      let { params } = this.$route;
      if (params.addedit === 'add-personal') return true;
      if (params.addedit === 'edit-personal' && this.currentlyEditing.dateTime) return true;
      return false;
    },
  },
  mounted() {
    let { params } = this.$route;
    if (params.addedit === 'edit-personal') {
      // get personal interaction to edit
      this.getInteraction({ id: params.id });
    }
  },
  methods: {
    closeModal() {
      this.clearInteractionsById();
      navigateToUrl(`${baseUrl}/${interactionsArea}`);
    },
    async selectDateTime(dateTime) {
      let { params } = this.$route;
      let detailsToSave = {
        isPersonal: true,
        data: {
          dateTime: dateTime.date,
          interactionNote: dateTime.description,
          durationHours: dateTime.durationHours,
          durationMinutes: dateTime.durationMinutes,
        },
      };
      if (params.id) {
        detailsToSave.id = params.id;
        await this.putInteraction(detailsToSave);
      } else {
        await this.postInteraction(detailsToSave);
      }
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped></style>
