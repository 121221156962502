<template>
  <CRMCard
    :on-click="() => emit('open')"
    :title="title"
    :links="sectionLinks"
  >
    <template #info>
      {{ description }}
    </template>
  </CRMCard>
</template>

<script setup>
import { CRMCard } from '@sales-i/dsv3';

defineProps({
  description: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  cardPurpose: {
    type: String,
    default: 'reversed',
  },
  icon: {
    type: Boolean,
    default: false,
  },
  linkText: {
    type: String,
    default: '',
  },
  sectionLinks: {
    type: Array,
    default: () => []
  }
});
const emit = defineEmits(['open']);
</script>

<style lang="scss" scoped>
</style>
