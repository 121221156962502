import { postMutations, postActions } from './post';

// initial state
const state = () => ({
  loaded: false,
  loading: false,
});

const getters = {};

// mutations
const mutations = {
  ...postMutations,
};

// actions
const actions = {
  ...postActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
