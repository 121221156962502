import { deleteByEntityActions, deleteByEntityMutations } from './deleteByEntity';
import { getByEntityGetters, getByEntityActions, getByEntityMutations } from './getByEntity';
import { patchByEntityGetters, patchByEntityActions, patchByEntityMutations } from './patchByEntity';
import { t } from '@sales-i/utils';

// initial state
const state = () => ({
  loading: true,
  loaded: false,
  data: {
    type: [],
    outcome: []
  },
  managers: {
    type: {
      id: 'interaction-type',
      label: t('Interaction type'),
      placeholder: t('Interaction type'),
      value: t('Interaction type'),
      loading: false,
      ticker: 0,
    },
    outcome: {
      id: 'ideal-outcome',
      label: t('Outcome'),
      placeholder: t('Outcome'),
      value: t('Outcome'),
      loading: false,
      ticker: 0,
    },
    purpose: {
      id: 'interaction-purpose',
      label: t('Interaction purpose'),
      placeholder: t('Interaction purpose'),
      value: t('Interaction Purpose'),
      loading: false,
      ticker: 0,
    },
    nextAction: {
      id: 'next-action',
      label: t('Next action'),
      placeholder: t('Next action'),
      value: t('Next action'),
      loading: false,
      ticker: 0,
    },
  },
  errors: [],
});

// getters
const getters = {
  ...getByEntityGetters,
  ...patchByEntityGetters,
};

// mutations
const mutations = {
  ...deleteByEntityMutations,
  ...getByEntityMutations,
  ...patchByEntityMutations,
};

// actions
const actions = {
  ...deleteByEntityActions,
  ...getByEntityActions,
  ...patchByEntityActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
