// js spacings for easier calculations of dynamic styles
// --spacing-1
export const spacing = {
  '1': 8,
  '2': 16,
  '3': 24,
  '4': 32,
  '5': 40,
  '6': 48,
  '8': 64,
  '10': 80,
  '12': 96,
  '16': 128,
  'half': 4,
  'border-radius-1': 8,
  'border-radius-half': 4
};
