<template>
  <CustomCard
    purpose="reversed"
    class="details-container"
  >
    <h3>{{ titles.settings }}</h3>

    <CardSettingsList :items="settings.data" />
    <ButtonGroup class="btn-group-bg">
      <CustomButton
        purpose="reversed"
        @on-click="() => {}"
      >
        {{ t('Update') }}
      </CustomButton>
    </ButtonGroup>
  </CustomCard>
</template>

<script type="text/javascript">
import { mapActions, mapState } from 'vuex';
import { GET_BY_ENTITY } from '@/shared/store/actionType';
import { CustomCard, CustomButton } from '@sales-i/dsv3';
import { t } from '@sales-i/utils';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import CardSettingsList from '@/admin/components/Settings/CardSettingsList.vue';

export default {
  name: 'SettingsView',
  components: {
    ButtonGroup,
    CustomButton,
    CustomCard,
    CardSettingsList,
  },
  props: {
    entity: {
      type: String,
      default: 'customers',
    },
    titles: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      settings: state => state.admin.settings,
    }),
  },
  mounted() {
    this.getSettings({ entity: this.entity });
  },
  methods: {
    t,
    ...mapActions({
      getSettings: `admin/settings/${GET_BY_ENTITY}`,
    }),
  },
};
</script>

<style lang="scss" scoped>
.update {
  align-items: center;
}
</style>
