import CustomerOverview from '@/crm/views/Customers/CustomerOverview.vue';


export default {
  path: '',
  component: CustomerOverview,
  props: {
    showOpportunities: true
  }
};
