import TagsSettingsView from '@/admin/views/Company/TagsSettingsView.vue';
import TagsReport from '@/admin/components/Company/Tags/TagsReport.vue';
import TagsConsolidation from '@/admin/components/Company/Tags/TagsConsolidation.vue';
import TagsFrom from '@/admin/components/Company/Tags/TagsFrom.vue';
import TagsTo from '@/admin/components/Company/Tags/TagsTo.vue';
import { tagConsolidationSection, tagsReportSection, tagsSection as section } from '@/admin/router/urlBits';

export default [
  {
    path: section,
    component: TagsSettingsView,
    children: [
      {
        path: '',
        component: TagsReport
      },
      {
        path: tagsReportSection,
        component: TagsReport
      },
      {
        path: tagConsolidationSection,
        component: TagsConsolidation,
        children: [
          {
            path: '',
            component: TagsFrom
          },
          {
            path: 'consolidate-to-tag',
            component: TagsTo
          }
        ]
      },
    ]
  }
];
