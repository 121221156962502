<template>
  <div class="search-customers">
    <div
      v-if="isSearchBarVisible"
      class="search-container-outer"
    >
      <div class="search-container">
        <div class="search-input">
          <div class="text">
            <input
              v-model="query"
              type="text"
              tabindex="0"
              :placeholder="t('Search')"
              :aria-label="t('Search')"
              @keyup="onSearchBarInput"
              @click="onSearchBarClick"
            >
            <CustomButton
              class="reset-button"
              purpose="transparent"
              icon-name="close-line"
              icon-color="var(--colour-utility-black)"
              small
              round
              :icon-width="18"
              :icon-height="18"
              @click="resetSearch"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isSearchResultContainerVisible"
      class="overlay"
      aria-hidden="true"
      tabindex="-1"
      @click="onOverlayClick"
    />
    <div
      v-if="isSearchResultContainerVisible"
      class="search-bar-result"
    >
      <BufferImage
        v-if="loading"
        color="black"
        float="center"
      />
      <ul v-if="!loading && searchResults.length">
        <li
          v-for="item in searchResults"
          :key="item.id"
          role="button"
          tabindex="0"
          @click="onItemClick(item)"
          @keydown.enter="onItemClick(item)"
        >
          <TextHighlighter
            :text="item.header"
            :highlight="query"
          />
        </li>
      </ul>
      <div
        v-if="!loading && !searchResults.length"
        class="no-results-container"
      >
        {{ t('Results not found...') }}
      </div>
    </div>
    <IconButton
      v-if="!isSearchBarVisible"
      class="search-button"
      icon-name="search"
      purpose="transparent"
      icon-align="left"
      icon-color="var(--colour-utility-action)"
      variant="round"
      :icon-size="26"
      :label="query"
      @click.stop="searchBarClicked"
    />
  </div>
</template>

<script>
import { BufferImage, IconButton, CustomButton, TextHighlighter } from '@sales-i/dsv3';
import { mapActions, mapState } from 'vuex';
import { CLEAR_SEARCH_BAR_RESULTS, GET_SEARCH_BAR_RESULTS } from '@/shared/store/actionType';
import { debounce, t } from '@sales-i/utils';

export default {
  name: 'SnapshotSearch',
  components: {
    BufferImage,
    IconButton,
    CustomButton,
    TextHighlighter
  },
  props: {
    predefinedQuery: {
      type: String,
      default: '',
    },
    selectedValue: {
      type: String,
      default: '',
    },
    searchType: {
      type: String,
      default: 'customer',
    },
    isCustomer: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['itemClick'],
  data() {
    return {
      isSearchBarVisible: true,
      isSearchResultContainerVisible: false,
      query: '',
    };
  },
  computed: {
    ...mapState({
      loading: state => state.searchBarResults.loading,
      searchResults: state => state.searchBarResults.data,
    }),
    debouncedSearchForResults() {
      return debounce(this.searchForResults, 500);
    },
  },
  mounted() {
    if (this.predefinedQuery || !this.isCustomer) this.isSearchBarVisible = false;
    this.query = this.predefinedQuery;
  },
  methods: {
    t,
    ...mapActions({
      clearSearchBarResults: `searchBarResults/${CLEAR_SEARCH_BAR_RESULTS}`,
      getSearchBarResults: `searchBarResults/${GET_SEARCH_BAR_RESULTS}`,
    }),
    searchBarClicked() {
      this.query = '';
      this.isSearchBarVisible = true;
    },
    resetSearch() {
      this.query = this.selectedValue || '';
      this.isSearchBarVisible = false;
      this.isSearchResultContainerVisible = false;
    },
    async searchForResults() {
      if (!this.query) {
        this.isSearchResultContainerVisible = false;
        this.clearSearchBarResults();
        return;
      }
      this.isSearchResultContainerVisible = true;
      await this.getSearchBarResults(this.getPayload());
    },
    getPayload() {
      return {
        url: `${this.searchType}s`,
        type: this.searchType,
        parameters: {
          q: this.query,
          limit: 10000,
          offset: 0,
        },
        moreResults: false,
      };
    },
    onItemClick(item) {
      this.query = item.name || item.header;
      this.clearSearchBarResults();
      this.isSearchBarVisible = false;
      this.isSearchResultContainerVisible = false;
      this.$emit('itemClick', item);
    },
    onOverlayClick() {
      this.isSearchResultContainerVisible = false;
    },
    onSearchBarClick() {
      if (this.query) this.isSearchResultContainerVisible = true;
    },
    onSearchBarInput() {
      if (!this.query) this.isSearchResultContainerVisible = false;
      this.debouncedSearchForResults();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.search-customers {
  margin-top: var(--spacing-1);

  .search-button {
    color: var(--colour-utility-black);
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-semibold);

    :deep(.button-container) {
      box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) rgb(40 25 25 / 16%);
      margin-left: 0;
      margin-right: var(--spacing-1);
      height: 40px;
      width: 40px;
    }
  }

  .search-bar-result {
    position: absolute;
    background: var(--colour-utility-white);
    z-index: $searchDropdownZIndex;
    min-height: 40px;
    width: 100%;
    left: 0;
    top: 116px;
    padding: var(--spacing-1) 0;
    box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-colour);
    max-height: 300px;
    overflow: auto;

    .no-results-container {
      padding: 0 var(--spacing-2);
      height: 40px;
      display: flex;
      align-items: center;
    }

    ul {
      list-style: none;

      li {
        cursor: pointer;
        padding: 0 var(--spacing-2);
        height: 40px;
        display: flex;
        align-items: center;
        text-overflow: ellipsis;

        &:hover {
          background: var(--colour-panel-g-8);
        }
      }
    }
  }

  .search-container-outer {
    height: 40px;
    margin-bottom: var(--spacing-4);
  }

  .search-container {
    background: var(--colour-panel-action);
    position: absolute;
    width: 100%;
    top: 56px;
    left: 0;
    padding: var(--spacing-3) var(--spacing-2);
    z-index: $searchDropdownZIndex;

    .search-input {
      display: flex;
      border-radius: 100px;
      background: white;
      overflow: hidden;
      width: 100%;
      box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);

      .text {
        background: url("data:image/svg+xml,%3Csvg width='32px' height='32px' fill='%237360A4' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.437 26.926l2.571 -3.065l14.555 12.213l-2.571 3.065l-14.555 -12.213Z' /%3E%3Cg%3E%3CclipPath id='1'%3E%3Cpath d='M19 6c7.18 0 13 5.82 13 13c0 7.18 -5.82 13 -13 13c-7.18 0 -13 -5.82 -13 -13c0 -7.18 5.82 -13 13 -13Zm0 4c-4.971 0 -9 4.029 -9 9c0 4.971 4.029 9 9 9c4.971 0 9 -4.029 9 -9c0 -4.971 -4.029 -9 -9 -9Z' /%3E%3C/clipPath%3E%3Cg clip-path='url(%231)'%3E%3Crect x='6' y='6' width='26' height='26' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
          no-repeat 8px 6px;
        // Don't be alarmed. This SVG encoded as a data URI is an exception. It allows us to use an SVG as a background image and change its colour without hving to create a new image.
        background-size: 24px;
        display: flex;
        width: 100%;
        align-items: center;

        .svg {
          margin-left: var(--spacing-1);
        }

        input {
          background-color: transparent;
          padding: var(--spacing-1) var(--spacing-1) var(--spacing-1) var(--spacing-5);
          box-shadow: none;
          width: 100%;
          font-weight: var(--font-weight-regular);
          border: none;
          font-size: var(--font-size-small);
        }

        .reset-button {
          box-shadow: none;
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  background: black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  z-index: $searchDropdownOverlayZIndex;
}
</style>
