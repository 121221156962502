export default function getInteractionFiltersForWidget(url) {
  const savedFilters = {};
  const search = new URLSearchParams(url);
  const filter = search.getAll('filter');
  filter.forEach((val, index) => {
    const [filterName, filterValues] = applyFilterFromUrl(val, index);
    savedFilters[filterName] = filterValues;
  });
  return savedFilters;
}

function applyFilterFromUrl(val) {
  const filterVal = val.split(',');
  const filterName = filterVal[0];
  return [filterName, filterVal.slice(1).map(n => filterName === 'user' ? n : Number(n))];
}