import {
  PUT_BY_ID,
  PUT_BY_ID_LOADING,
  PUT_BY_ID_ERROR,
  POST_BY_ENTITY,
  DELETE_BY_ENTITY,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const putByIdMutations = {
  [PUT_BY_ID]: (state, data) => {
    state.selected.data = data;
    state.selected.loading = false;
    state.selected.loaded = true;
    state.selected.updated = true;
  },
  [PUT_BY_ID_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [PUT_BY_ID_ERROR]: (state) => {
    state.selected.loading = false;
    state.selected.loaded = false;
    state.selected.updated = false;
  },
};

// actions
export const putByIdActions = {
  [PUT_BY_ID]: async ({ dispatch, commit, }, params) => {
    const { id, post_id, entity, payload, } = params;
    const { post, tags: { tagsToAdd, tagsToDelete } } = payload;
    commit(PUT_BY_ID_LOADING);
    try {
      const data = await httpRequest('put', `${entity}/${id}/posts/${post_id}?${params}`, post, {version: '2',});

      if (tagsToAdd.length > 0) {
        await dispatch(`tags/${POST_BY_ENTITY}`,{
          entity: 'posts',
          id: data.id,
          tags: tagsToAdd
        },{
          root:true
        });
      }

      if (tagsToDelete.length > 0) {
        await dispatch(`tags/${DELETE_BY_ENTITY}`,{
          entity: 'posts',
          id: data.id,
          tags: tagsToDelete
        },{
          root:true
        });
      }

      commit(PUT_BY_ID, {
        ...post,
        ...data,
      });
    } catch (error) {
      commit(PUT_BY_ID_ERROR);
    }
  },
};
