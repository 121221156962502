<template>
  <div class="product-selection-container">
    <div
      v-for="product in filteredProducts"
      :key="product.product_code"
      class="product-selection"
    >
      <IconButton
        icon-name="close-line"
        purpose="reversed"
        class="close"
        @click.stop="deleteProduct(product)"
      />
      <p>{{ productName[filteredProducts.indexOf(product)] }}</p>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

// Components
import IconButton from '@sales-i/dsv3/src/components/IconButton/IconButton.vue';

const props = defineProps({
  productDescription: {
    type: Array,
    default: () => [],
  },
  products: {
    type: Array,
    default: () => [],
  },
  status: {
    type: String,
    default: '',
  },
});

const getProdGroupLevelIds = (item) => {
  return [
    'pg-',
    item.prod_group_level_1 ? item.prod_group_level_1 : '',
    item.prod_group_level_2 ? `-${item.prod_group_level_2}` : '',
    item.prod_group_level_3 ? `-${item.prod_group_level_3}` : '',
    item.prod_group_level_4 ? `-${item.prod_group_level_4}` : '',
    item.prod_group_level_5 ? `-${item.prod_group_level_5}` : '',
  ].join('');
};

const getProdGroupDescription = (item) => {
  return [
    item.prod_group_level_1_desc ? item.prod_group_level_1_desc : '',
    item.prod_group_level_2_desc ? `-${item.prod_group_level_2_desc}` : '',
    item.prod_group_level_3_desc ? `-${item.prod_group_level_3_desc}` : '',
    item.prod_group_level_4_desc ? `-${item.prod_group_level_4_desc}` : '',
    item.prod_group_level_5_desc ? `-${item.prod_group_level_5_desc}` : '',
  ].join('');
};

const productName = computed(() => {
  return filteredProducts.value.map((product) => {
    if (product.product_name) {
      return `${product.product_code} - ${product.product_name}`;
    } else {
      return `(${getProdGroupLevelIds(product)}) - ${getProdGroupDescription(product)}`; 
    }
  });
});

const emit = defineEmits(['deleteProduct']);

// Filter products based on the status prop
const filteredProducts = computed(() => {
  return props.products.filter(product => {
    // Assuming there's a property named 'status' in each product
    return product.status === props.status;
  });
});

const deleteProduct = (product) => {
  emit('deleteProduct', product);
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.product-selection {
  display: flex;
  align-items: flex-start;

  p {
    padding-block: var(--spacing-half)
  }
}
</style>