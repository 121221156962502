import { getAllActions, getAllMutations } from './getAll';
import { getByIdActions, getByIdMutations } from './getById';
import { downloadByIdActions, downloadByIdMutations } from './downloadById';
import { deleteByIdActions, deleteByIdMutations } from './deleteById';
import { uploadActions, uploadMutations } from './upload';

// initial state
const state = () => ({
  all: {
    data: [],
    fields: [],
    loaded: false,
    loading: false,
    errors: [],
  },
  selected: {
    data: {},
    loaded: false,
    loading: false,
    downloading: false,
    updated: false,
    errors: [],
  },
  sorting: [
    {
      text: 'Customer/Prospect ASC - DES',
      value: 'entity_id:asc',
    },
    {
      text: 'Customer/Prospect DES - ASC',
      value: 'entity_id:desc',
    },
    {
      text: 'Value ASC - DES',
      value: 'value:asc',
    },
    {
      text: 'Value DES - ASC',
      value: 'value:desc',
    },
    {
      text: 'Decision Date ASC - DES',
      value: 'decision_date:asc',
    },
    {
      text: 'Decision Date DES - ASC',
      value: 'decision_date:desc',
    },
    {
      text: '% Probability ASC - DES',
      value: 'probability:asc',
    },
    {
      text: '% Probability DES - ASC',
      value: 'probability:desc',
    },
  ],
});

// getters
const getters = {
};

// mutations
const mutations = {
  ...getAllMutations,
  ...getByIdMutations,
  ...downloadByIdMutations,
  ...deleteByIdMutations,
  ...uploadMutations
};

// actions
const actions = {
  ...getAllActions,
  ...getByIdActions,
  ...downloadByIdActions,
  ...deleteByIdActions,
  ...uploadActions
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
