import Cookies from 'js-cookie';
import store from '@/shared/store';
import { GET_PLATFORM } from '@/shared/store/actionType';

export const setPlatform = (platform) => {
  Cookies.set('platform', platform, Infinity);
  store.dispatch(`platform/${GET_PLATFORM}`, platform);
};

export const getPlatform = () => {
  const platform = Cookies.get('platform');
  store.dispatch(`platform/${GET_PLATFORM}`, platform);
  return platform;
};
