import { getAllCommunitiesActions, getAllCommunitiesMutations } from './getAll';
import { getByIdCommunitiesActions, getByIdCommunitiesMutations } from './getById';
import { putByIdCommunitiesActions, putByIdCommunitiesMutations } from './putById';
import { deleteByIdCommunitiesActions, deleteByIdCommunitiesMutations } from './deleteById';
import { postByEntityCommunitiesActions, postByEntityCommunitiesMutations } from './postByEntity';
import { deleteProductsCommunitiesActions, deleteProductsCommunitiesMutations } from './deleteProducts';
import { clearCurrentlyLoadedMutators, clearCurrentlyLoadedActions } from './clearCurrentlyLoaded';
import { addCommunityMutators, addCommunityActions } from './addCommunity';

const state = () => ({
  data: [],
  currentlyLoaded: [],
  loaded: false,
  loading: false,
  error: null,
});

const actions = {
  ...getAllCommunitiesActions,
  ...getByIdCommunitiesActions,
  ...putByIdCommunitiesActions,
  ...deleteByIdCommunitiesActions,
  ...postByEntityCommunitiesActions,
  ...deleteProductsCommunitiesActions,
  ...clearCurrentlyLoadedActions,
  ...addCommunityActions,
};

const mutations = {
  ...getAllCommunitiesMutations,
  ...getByIdCommunitiesMutations,
  ...putByIdCommunitiesMutations,
  ...deleteByIdCommunitiesMutations,
  ...postByEntityCommunitiesMutations,
  ...deleteProductsCommunitiesMutations,
  ...clearCurrentlyLoadedMutators,
  ...addCommunityMutators,
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
