import {
  POST_BY_ENTITY,
  POST_BY_ENTITY_LOADING,
  POST_BY_ENTITY_ERROR,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

export const postByEntityCommunitiesMutations = {
  [POST_BY_ENTITY]: (state, data) => {
    state.currentlyLoaded = data;
    state.loaded = true;
    state.loading = false;
    state.error = null;
  },
  [POST_BY_ENTITY_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.error = null;
  },
  [POST_BY_ENTITY_ERROR]: (state, error) => {
    state.loading = false;
    state.loaded = true;
    state.error = error;
  }
};

export const postByEntityCommunitiesActions = {
  [POST_BY_ENTITY]: async ({ commit }, payload) => {
    commit(POST_BY_ENTITY_LOADING);

    try {
      const endpoint = 'id' in payload === false ? 'communities' : `communities/${payload.id}`;
      const response = await httpRequest('post', endpoint, payload.details, {
        version: 2,
        area: 'associations'
      });

      commit(POST_BY_ENTITY, response);
    } catch (e) {
      commit(POST_BY_ENTITY_ERROR, e);
    }
  }
};
