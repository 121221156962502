<template>
  <div class="date-subheader">
    <p v-if="dateRangeNumber === 1">
      {{ formatDate(requestParameters.date_from) }} - {{ formatDate(requestParameters.date_to) }}
    </p>
    <div
      v-else
      class="date-range-two"
    >
      <p
        v-for="n in 2"
        :key="n"
      >
        {{ n }}:
        <IconBase
          icon-name="calendar"
          :height="16"
          :width="16"
        />
        {{ formatDate(requestParameters[`date_from${n === 1 ? '' : 2}`]) }} - {{ formatDate(requestParameters[`date_to${n === 1 ? '' : 2}`]) }}
      </p>
    </div>
  </div>
</template>

<script>
import { IconBase } from '@sales-i/dsv3';
import { mapState } from 'vuex';
import { dates } from '@sales-i/utils';

export default {
  components: {
    IconBase,
  },
  props: {
    dateRangeNumber: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapState({
      requestParameters: state => state.intelligence.shared.requestParameters,
    }),
  },
  methods: { formatDate: dates.format },
};
</script>

<style lang="scss" scoped>
.date-subheader {
  p {
    text-align: center;
    font-size: var(--font-size-small);
    line-height: var(--font-size-body);
    margin: var(--spacing-half) 0 0;
  }

  .date-range-two p {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
