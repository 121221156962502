import { currentlyEditingGetters, currentlyEditingMutations, currentlyEditingActions } from './currentlyEditing';
// import { fieldsMutations, fieldsActions } from './fields';
import { getAllGetters, getAllMutations, getAllActions } from './getAll';
import { getSummaryGetters, getSummaryMutations, getSummaryActions } from './getSummary';
import { deleteByIdMutations, deleteByIdActions } from './deleteById';
import { getByIdGetters, getByIdMutations, getByIdActions } from './getById';
import { postMutations, postActions } from './post';
import { generateInteractionsGetters, generateInteractionsMutations, generateInteractionsActions } from './generateInteractions';
import { putByIdMutations, putByIdActions } from './putById';

// initial state
const state = () => ({
  all: {
    data: [],
    fields: [],
    loaded: false,
    loading: false,
    error: undefined,
  },
  summary: {
    data: {},
    loaded: false,
    loading: false,
    error: undefined,
  },
  currentlyEditing: {
    dateTime: false,
    duration: {},
    entityType: false,
    entity: {},
    entityIDs: [],
    contact: {},
    opportunities: [],
    furtherDetails: {},
    backdated: false,
  },
  selected: {
    data: {},
    loaded: false,
    loading: false,
    updated: false,
    error: undefined,
  },
  selectedItems: [],
  stages: {
    data: [],
    loaded: false,
    loading: false,
    error: undefined,
  },
  generateInteractions: {
    data: {},
    responseData: {},
    loaded: false,
    loading: false,
    error: undefined,
  },
  sorting: [
    {
      text: 'Customer/Prospect ASC - DES',
      value: 'entity_id:asc',
    },
    {
      text: 'Customer/Prospect DES - ASC',
      value: 'entity_id:desc',
    },
    {
      text: 'Value ASC - DES',
      value: 'value:asc',
    },
    {
      text: 'Value DES - ASC',
      value: 'value:desc',
    },
    {
      text: 'Decision Date ASC - DES',
      value: 'decision_date:asc',
    },
    {
      text: 'Decision Date DES - ASC',
      value: 'decision_date:desc',
    },
    {
      text: '% Probability ASC - DES',
      value: 'probability:asc',
    },
    {
      text: '% Probability DES - ASC',
      value: 'probability:desc',
    },
  ],
});

// getters
const getters = {
  ...currentlyEditingGetters,
  ...getByIdGetters,
  ...getAllGetters,
  ...getSummaryGetters,
  ...generateInteractionsGetters
};

// mutations
const mutations = {
  ...currentlyEditingMutations,
  ...deleteByIdMutations,
  //...fieldsMutations,
  ...getByIdMutations,
  ...putByIdMutations,
  ...postMutations,
  ...getAllMutations,
  ...getSummaryMutations,
  ...generateInteractionsMutations
};

// actions
const actions = {
  ...currentlyEditingActions,
  ...deleteByIdActions,
  //...fieldsActions,
  ...getByIdActions,
  ...putByIdActions,
  ...postActions,
  ...getAllActions,
  ...getSummaryActions,
  ...generateInteractionsActions
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
