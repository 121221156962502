<template>
  <CustomCard
    purpose="reversed"
    class="details-container"
  >
    <h3>{{ titles.fields }}</h3>

    <div
      v-for="manager in managers"
      :key="manager.type"
    >
      <FieldManager
        v-if="fields.data[manager.type]"
        :title="manager.name"
        :manager="fields.managers[manager.type]"
        :manager-type="manager.type"
        :fields="fields.data[manager.type]"
        :saving="saving"
        @add-item="addItem(manager.type)"
        @delete-item="deleteItem"
        @edit-item="editItem"
        @save="save"
      />
    </div>
  </CustomCard>
</template>

<script setup>
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { GET_BY_ENTITY, SET_ITEMS, REMOVE_BY_INDEX, PATCH_BY_ENTITY, DELETE_BY_ID, SET_CONFIRMATION_MODAL } from '@/shared/store/actionType';
import { CustomCard } from '@sales-i/dsv3';
import FieldManager from '@/admin/components/Settings/FieldManager.vue';
import { t } from '@sales-i/utils';

const store = useStore();

const props = defineProps({
  entity: {
    type: String,
    default: 'customers',
  },
  titles: {
    type: Object,
    default: () => ({}),
  },
});

const managers = ref([
  { name: t('Interaction type'), type: 'type' },
  { name: t('Outcome'), type: 'outcome' },
  { name: t('Interaction purpose'), type: 'purpose' },
  { name: t('Next action'), type: 'nextAction' },
]);
const saving = ref(false);

const fields = computed(() => store.state.admin.fields);
  
onMounted(() => {
  getFields({ entity: props.entity });
});

const getFields = params => store.dispatch(`admin/fields/${GET_BY_ENTITY}`, params);
const addEditField = params => store.dispatch(`admin/fields/${SET_ITEMS}`, params);
const removeField = params => store.dispatch(`admin/fields/${REMOVE_BY_INDEX}`, params);
const deleteField = params => store.dispatch(`admin/fields/${DELETE_BY_ID}`, params);
const saveFieldset = params => store.dispatch(`admin/fields/${PATCH_BY_ENTITY}`, params);
const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);

const addItem = type => addEditField({ type });
const editItem = (field, type) => addEditField({ type, isNew: false, field });
    
const deleteItem = deleteObj => {
  let { item } = deleteObj;
  let updateFunc = typeof deleteObj.item.id === 'string' && deleteObj.item.id.includes('FAKE') ? removeField : deleteField;
  showConfirmationModal({
    message: t(`Do you really want to delete field "${item.value}"?`, 'do_you_really_want_to_delete_field_variable', {
      interpolations: {
        variable: item.value,
      },
    }),
    updatedMessage: t(`You have deleted field "${item.value}"`, 'you_have_deleted_field_variable', {
      interpolations: {
        variable: item.value,
      },
    }),
    updateFunction: async () => {
      try {
        await updateFunc(deleteObj);
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    finishFunction: () => {
      return true;
    },
  });
};
const save = async type => {
  saving.value = true;
  await saveFieldset({ type });
  saving.value = false;
};
</script>

<style lang="scss" scoped>
.update {
  align-items: center;
}
</style>
