<template>
  <CustomCard 
    purpose="reversed"
    footer-color="grey"
    class="upload-card"
  >
    <header>
      <h3>{{ title }}</h3>
    </header>

    <div
      class="drop-area"
      :class="{ 'highlight': highlighted, 'bordered': !uploadedImage}"
      role="button"
      tabindex="-1"
      @dragenter.stop.prevent="onEnter"
      @dragover.stop.prevent="onOver"
      @dragleave.stop.prevent="onLeave"
      @drop.stop.prevent="onDrop"
    >
      <form class="fileform">
        <div
          v-if="!uploadedImageSrc && !sentImageSrc"
          class="fileform-placeholder"
        >
          <p>
            {{ t('Drag & drop image here') }}
          </p>
          <img
            v-if="entityType === 'logo'"
            src="@/shared/assets/image/admin/logo_placeholder_desktop.png"
            alt="desktop logo placeholder"
          >
          <img 
            v-else
            src="@/shared/assets/image/admin/logo_placeholder_mobile.png"
            alt="mobile logo placeholder"
          >
        </div>
        <img 
          v-else
          class="preview-image"
          :src="uploadedImageSrc || sentImageSrc" 
          alt="logo preview"
        >
      </form>
    </div>

    <template #footer>
      <div :class="['info-box', status && status.class]">
        <p v-if="status">
          <IconBase
            v-if="status.icon"
            :icon-name="status.icon"
            icon-color="var(--colour-data-de-york-label)"
            width="30"
            height="30" 
          />
          {{ status.message }}
        </p>
        <ul v-else>
          <li>JPEG, PNG</li>
          <li>{{ t('Maximum size') }} {{ maxWidth }} x {{ maxHeight }} {{ t('pixels') }}</li>
          <li>{{ t('File size under 2MB') }}</li>
        </ul>
      </div>
      <ButtonGroup class="button-group-logo">
        <CustomButton
          v-if="uploadedImage"
          small
          purpose="reversed"
          @click="clearUploadedImage()"
        >
          {{ t('Cancel') }}
        </CustomButton>
        <!-- TODO: Hidden for now, as BE DELETE endpoint is not implemented yet (https://sales-i.atlassian.net/browse/SA-7221) -->

        <!-- <CustomButton
          v-else
          class="delete-button"
          small
          purpose="reversed"
          :disabled="!sentImageSrc"
          @click="deleteImage()"
        >
          {{ t('Delete') }}
        </CustomButton> -->

        <label
          v-if="!uploadedImage"
          class="button"
          :for="inputId"
          :title="t('Press Ctrl+U to open file dialog')"
        >
          <input
            :id="inputId"
            :key="`upload_btn_${filesRef}`"
            class="upload-input"
            type="file"
            accept="image/*"
            @change="loadImage($event.target.files)"
          >
          {{ t('Browse') }}
        </label>
        <CustomButton
          v-else
          small
          purpose="action"
          @click="sendImage()"
        >
          {{ t('Save') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomCard>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { t, httpRequest } from '@sales-i/utils';
import { CustomButton, CustomCard, IconBase } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { getFileExtension, getFileBaseName } from '@/shared/utils/file';

const MAX_SIZE = 2 * 1024 * 1024;
const ALLOWED_EXTENSIONS = ['jpeg', 'jpg', 'png', 'webp', 'svg'];
const STATUSES = {
  UPLOADED: {
    message: t('Upload accepted! Select Save to apply your changes'),
  },
  TOO_BIG: {
    message: t('Upload rejected! The file size is too big'),
  },
  WRONG_TYPE: {
    message: t('Upload rejected! The file type is wrong'),
  },
  DELETED: {
    message: t('Image deleted!'),
    class: 'status-deleted',
    icon: 'trash',
  },
  APPLIED: {
    message: t('Changes applied!'),
    class: 'status-applied',
    icon: 'check',
  }
};
const endpointOptions = {
  area: 'config',
  version: '2',
  config: {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
};

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  entityType: {
    type: String,
    default: 'logo',
  },
  inputId: {
    type: String,
    default: 'upload',
  },
  maxWidth: {
    type: Number,
    default: 0,
  },
  maxHeight: {
    type: Number,
    default: 0,
  },
});

const uploadedImage = ref(null);
const uploadedImageSrc = ref(null);
const sentImageSrc = ref(null);
const highlighted = ref(false);
const status = ref(null);

onMounted(async () => {
  sentImageSrc.value = await getLogoAPI();
});

const changeStatus = fileStatus => {
  status.value = fileStatus;
  setTimeout(() => {
    status.value = null;
  }, 3000);
};

const mapFile = (file) => {
  const ext = getFileExtension(file.name);
  const fileType = file.type.split('/')[1];
  const allowedExt = ALLOWED_EXTENSIONS.includes(ext);
  const name = getFileBaseName(file.name);
  if (!allowedExt) {
    changeStatus(STATUSES.WRONG_TYPE);
    return null;
  } else if (file.size > MAX_SIZE) {
    changeStatus(STATUSES.TOO_BIG);
    return null;
  } else {
    changeStatus(STATUSES.UPLOADED);
  }

  return { file, fileType, name, ext };
};

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

const showHighlight = (showHighlight) => {
  highlighted.value = showHighlight;
};

const loadImage = async (files) => {
  uploadedImage.value = mapFile(files[0]);
  if (!uploadedImage.value) {
    uploadedImageSrc.value = null;
    return;
  }
  try {
    const result = await toBase64(uploadedImage.value.file);
    uploadedImageSrc.value = result;
  } catch(error) {
    console.error(error);
    return;
  }
};

const getLogoAPI = async () => {
  try {
    const response = await httpRequest('get', 'tenant-logos', null, endpointOptions);
    return response[props.entityType];
  } catch (error) {
    console.error(error);
  }
};

const postLogoAPI = async () => {
  const file = new FormData();
  file.append('file', uploadedImage.value.file);

  try {
    await httpRequest('post', `tenant-logos?entity_type=${props.entityType}`, file, endpointOptions);
  } catch (error) {
    console.error(error);
  }
};

// TODO: Hidden for now, as BE DELETE endpoint is not implemented yet (https://sales-i.atlassian.net/browse/SA-7221)
// const deleteLogoAPI = async () => {
//   try {
//     await httpRequest('delete', `tenant-logos?entity_type=${props.entityType}`, null, endpointOptions);
//   } catch (error) {
//     console.error(error);
//   }
// };

const clearUploadedImage = () => {
  uploadedImage.value = null;
  uploadedImageSrc.value = null;
};

const sendImage = async () => {
  try {
    await postLogoAPI();
    changeStatus(STATUSES.APPLIED);
  } catch (error) {
    console.error(error);
  }
  clearUploadedImage();
  sentImageSrc.value = await getLogoAPI();
};

// TODO: Hidden for now, as BE DELETE endpoint is not implemented yet (https://sales-i.atlassian.net/browse/SA-7221)
// const deleteImage = () => {
//   clearUploadedImage();
//   deleteLogoAPI();
//   sentImageSrc.value = null;
//   changeStatus(STATUSES.DELETED);
// };

const onEnter = () => {
  showHighlight(true);
};
const onOver = () => {
  showHighlight(true);
};
const onLeave = () => {
  showHighlight(false);
};
const onDrop = event => {
  showHighlight(false);
  const dt = event.dataTransfer;
  const files = dt.files;
  loadImage(files);
};
</script>

<style lang="scss" scoped>
.upload-card {
  max-width: 455px;
  padding: 0;

  h3 {
    text-align: center;
    margin-top: var(--spacing-2);
  }

  .drop-area {
    border: none;
    border-radius: var(--border-radius-1);
    min-height: 225px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: var(--spacing-1) var(--spacing-1) var(--spacing-2);

    &.bordered {
      border: 2px dashed #bcbcbc;
    }
    &.highlight {
      border-color: var(--colour-utility-action);
    }
  }
  
  .fileform {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    p {
      margin-bottom: 0;
    }

    img.preview-image {
      width: 100%;
    }

    .fileform-placeholder {
      text-align: center;
    }
  }

  .button-group-logo {
    padding: var(--spacing-2) 0;

    .delete-button:not(:disabled) {
      color: var(--colour-data-mandy-dark);
    }
  }

  .info-box {
    background-color: var(--colour-data-viking-light);
    padding: var(--spacing-2);
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.status-deleted,
    &.status-applied {
      background-color: var(--colour-data-de-york);
      color: var(--colour-data-de-york-label);
    }

    ul {
      list-style-type: disc;
      margin: 0;
      padding-left: var(--spacing-3);
    }

    p {
      font-weight: var(--font-weight-bold);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--spacing-half);
    }
  }

  .upload-input {
    display: none;
  }
}

label.button {
  margin: 0;
  padding: var(--spacing-1) var(--spacing-2);
  color: var(--colour-utility-white);
  background-color: var(--colour-utility-action);
  cursor: pointer;
  border-radius: var(--border-radius-half);
  &:hover,
  &:focus {
    background-color: var(--colour-utility-focus);
  }
}
</style>