<template>
  <div>
    <CustomButton
      class="export-button"
      purpose="action"
      small
      @click="modalTrigger"
    >
      {{ title }}
    </CustomButton>
    <Teleport to="#modal-teleport-target">
      <CustomModal
        v-if="isModalVisible"
        id="export-modal"
        :title="title"
        :show-modal="isModalVisible"
        @close-modal="closeModal"
      >
        <p class="subtitle">
          {{ t('Export as') }}
        </p>
        <CustomSelect
          v-if="props.reportType"
          :value="selectedFileFormat"
          :items="fileFormats"
          @on-change="onFileFormatChange"
        />
        <div>
          <CustomButton
            v-if="!downloading"
            purpose="action"
            @click="exportHandler"
          >
            {{ t('Export') }}
          </CustomButton>
          <BufferImage
            v-else
            color="black"
            float="center"
            class="loading-spinner"
          />
        </div>
      </CustomModal>
    </Teleport>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { CustomModal, CustomButton, CustomSelect, BufferImage } from '@sales-i/dsv3';
import {
  EXPORT_REPORT_FULL_PICTURE,
  EXPORT_REPORT_MATRIX,
  EXPORT_REPORT_SALES_VS_GAPS,
  EXPORT_REPORT_VARIANCE,
  EXPORT_REPORT,
} from '@/intelligence/store/actionType';
import {
  FULL_PICTURE,
  MATRIX,
  QUERY_BUILDER,
  SALES_VS_GAPS,
  VARIANCE
} from '@/intelligence/store/data/reportTypes';
import useCurrency from '@/shared/composables/useCurrency';
import { t } from '@sales-i/utils';

const props = defineProps({
  activeColumnsData: {
    type: Array,
    default: () => [],
  },
  title: {
    type: String,
    default: ''
  },
  reportType: {
    type: String,
    default: ''
  },
  query: {
    type: Object,
    default: () => ({}),
  },
  requestParameterOverride: {
    type: String,
    default: '',
  },
});

const store = useStore();
const { cs } = useCurrency();
const customerObject = ref({});
const selectedFileFormat = ref('CSV');
let isModalVisible = ref(false);
const downloading = ref(false);
const fileFormats = ref([
  {
    text: 'CSV',
    value: 'CSV'
  },
  {
    text: 'PDF',
    value: 'PDF'
  },
  {
    text: 'XLSX',
    value: 'XLSX'
  },
]);
if (props.reportType === MATRIX) {
  fileFormats.value = fileFormats.value.filter(format => format.value !== 'PDF');
}
const userDetails = computed(() => store.state.userDetails);
const platform = computed(() => store.state.platform);
const isCustomerModeEnabled = computed(() => store.state.admin.intelligence.isCustomerModeEnabled);



const exportFullPictureReport = (payload) =>  store.dispatch(`intelligence/enquiry/${EXPORT_REPORT_FULL_PICTURE}`, payload);
const exportSvgReport = (payload) =>  store.dispatch(`intelligence/enquiry/${EXPORT_REPORT_SALES_VS_GAPS}`, payload);
const exportMatrixReport = (payload) =>  store.dispatch(`intelligence/enquiry/${EXPORT_REPORT_MATRIX}`, payload);
const exportVarianceReport = (payload) =>  store.dispatch(`intelligence/enquiry/${EXPORT_REPORT_VARIANCE}`, payload);
const exportReport = (payload) => store.dispatch(`intelligence/enquiry/${EXPORT_REPORT}`, payload);

function modalTrigger() {
  isModalVisible.value = true;
}

function closeModal() {
  isModalVisible.value = false;
}

const sanitiseRequestParameterOverride = (parameters) => {
  parameters = parameters.replace(')', '');
  parameters = parameters.replace(/([a-zA-Z]+)\s?\(/, '');

  return parameters;
};

async function exportHandler() {
  const ud = userDetails.value.data;
  const reporter = `${ud.first_name} ${ud.last_name}`;
  const { name } = customerObject.value;
  const fileExt = selectedFileFormat.value;
  const customerMode = isCustomerModeEnabled.value;
  // Get the list of selected columns 
  const cols = props.activeColumnsData;
  const exportData = {
    cols,
    fileExt,
    reporter,
    name,
    currency: cs,
    customerMode
  };

  if (platform.value.data === 'mobile') {    
    const mobileData = {
      exportData,
    };
    try {
      window.webkit.messageHandlers.exportSnapshotCallbackHandler.postMessage(JSON.stringify(mobileData));
    } catch(err) {
      // onErr
    }
  } else {
    downloading.value = true;


    if (props.reportType === QUERY_BUILDER) {
      await exportReport([
        QUERY_BUILDER,
        {
          ...exportData,
          requestParameterOverride: sanitiseRequestParameterOverride(props.requestParameterOverride),
        },
      ]);
    }

    if (props.reportType === FULL_PICTURE) {
      await exportFullPictureReport([
        FULL_PICTURE,
        exportData
      ]);
    }

    if (props.reportType === SALES_VS_GAPS) {
      await exportSvgReport([
        SALES_VS_GAPS,
        exportData
      ]);
    }

    if (props.reportType === MATRIX) {
      await exportMatrixReport([
        MATRIX,
        exportData
      ]);
    }

    if (props.reportType === VARIANCE) {
      await exportVarianceReport([
        VARIANCE,
        exportData
      ]);
    }
    downloading.value = false;
    closeModal();
  }
}

function onFileFormatChange(format) {
  selectedFileFormat.value = format;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

#export-modal {
  .subtitle {
    margin-bottom: var(--spacing-1);
    color: var(--colour-utility-base);
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    min-width: initial;
    max-width: 360px;
  }

  .content-wrapper .content{
    padding: 0 var(--spacing-3);
  }
}

.export-button {
  width: 100%;
}
</style>
