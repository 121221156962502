<template>
  <div>
    <!-- Form fields -->
    <div
      v-for="(section, sectionIndex) in formData"
      :key="sectionIndex"
    >
      <div class="flow">
        <h3 class="fw-sbld">
          {{ t(section.sectionTitle) }}
        </h3>

        <div class="location-form-wrapper flow">
          <div
            v-for="(field, fieldIndex) in section.fields"
            :key="field.id"
            class="field"
          >
            <Field
              v-bind="field"
              :ref="field.id"
              v-model="formData[sectionIndex].fields[fieldIndex].value"
              :class="field.id"
              :disabled="isFieldDisabled(field)"
              @valid="handleFieldValid"
              @keydown="handleKeydown"
              @keypress="handleKeypress"
              @keyup="handleKeyup"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-actions">
      <CustomButton
        v-scroll-to-top
        :label="t('Continue')"
        :class="`action ${valid ? '' : 'disabled'}`"
        @on-click="goNextStep"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { baseUrl as crmBaseUrl, interactionsArea } from '@/crm/router/urlBits';
import { pluralise, t } from '@sales-i/utils';
import { useGenerateInteractionValidation } from './composables/useGenerateInteractionValidation';
import { CustomButton } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field.vue';

const emit = defineEmits(['stageUpdate']);
const vroute = useRoute();

const selectedIndex = ref(2);
const nextStep = ref(`${crmBaseUrl}/${interactionsArea}/${vroute.params.entityPath}/interaction-note`);

const valid = computed(() => {
  if (!formData?.value?.length) return false;
  return (locationType.value !== 'none' && location.value) || (locationType.value === 'none' && !location.value);
});

const {
  entityType,
  formData,
  handleKeydown,
  handleKeypress,
  handleKeyup,
  handleFieldValid,
  goNextStep,
} = useGenerateInteractionValidation(emit, selectedIndex, nextStep, { valid });

const location = computed(() => formData?.value[0]?.fields[1]?.value);
const locationType = computed(() => {
  if (!formData?.value[0]?.fields) return 'none';
  return formData.value[0].fields[0].value.value;
});

onMounted(() => {
  emit('stageUpdate', t(`Location of ${pluralise.string(entityType.value)}`));
});

const isFieldDisabled = field => field.id === 'search_location' && locationType.value == 'none';

watch(() => [...formData.value], () => {
  if (locationType.value === 'none' && location.value) {
    formData.value[0].fields[1].value = '';
  }
});
</script>

<style lang="scss" scoped>
.location-form-wrapper {
  width: 50%;
}

// If you use a filter component but don't render the filter, it still renders the column where the label should be. This style hides it.
:deep(.location_type .row .col:first-child) {
  display: none;
}

:deep(.form-group) {
  margin-bottom: 0;
}
:deep(#location) {
  width: 32ch;
}
</style>
