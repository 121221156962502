import {
  PATCH_BY_ENTITY,
  PATCH_BY_ENTITY_LOADING,
  PATCH_BY_ENTITY_ERROR
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const patchMutations = {
  [PATCH_BY_ENTITY]: (state, { id, widgets, ...fields }) => {
    const dashbordIndex = state.all.data.findIndex(d => d.id == id);
    let dashboard = state.all.data[dashbordIndex];
    dashboard = {...dashboard, ...fields};
    state.all.data[dashbordIndex] = dashboard;
    state.all.loading = false;
    state.all.loaded = true;
    
    // let's update also order in selected
    if (widgets && state.selected.data) {
      state.selected.data.widgets = state.selected.data.widgets.map(w => ({
        ...w, 
        ...widgets.find(x => x.id == w.id) 
      }));
    }
  },
  [PATCH_BY_ENTITY_LOADING]: (state) => {
    state.all.loading = true;
    state.all.loaded = false;
    state.selected.deleted = false;
  },
  [PATCH_BY_ENTITY_ERROR]: (state) => {
    state.all.loading = false;
    state.all.loaded = false;
    state.all.updated = false;
  },
};

// actions
export const patchdActions = {
  [PATCH_BY_ENTITY]: async ({ commit, }, dashboard) => {
    commit(PATCH_BY_ENTITY_LOADING);
    try {
      await httpRequest('patch', `dashboards/${dashboard.id}`, dashboard, { area: 'dashboard' });
      commit(PATCH_BY_ENTITY, dashboard);
      return true;
    } catch (error) {
      console.error(error);
      commit(PATCH_BY_ENTITY_ERROR);
      return false;
    }
  },
};
