import { getAllActions, getAllMutations } from './getAll';

// initial state
const state = () => ({
  data: [],
  loaded: false,
  loading: false,
  errors: undefined,
});

// getters
const getters = {
};

// mutations
const mutations = {
  ...getAllMutations,
};

// actions
const actions = {
  ...getAllActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
