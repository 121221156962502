<template>
  <div
    :key="bubbleData.id"
    class="bubble"
    :class="{ collapsed }"
  >
    <label
      :for="`id-${bubbleData.id}`"
      :class="{ pointer: !bubbleData.clickable, fade: isBubbleDisabled }"
    >
      <input
        :id="`id-${bubbleData.id}`"
        ref="bubbleCheckbox"
        v-model="checked"
        type="checkbox"
        name="bubbles"
        class="bubble-input"
        :disabled="isBubbleDisabled"
        @change="bubbleClick"
      >

      <div
        class="icon-circle"
      >
        <IconBase
          class="icon"
          :style="{ background: iconBackground }"
          :width="iconSize"
          :height="iconSize"
          :icon-name="icon"
          icon-color="var(--colour-utility-white)"
        />
      </div>

      <div :class="{ description: bubbleData.description }">
        <div
          v-if="bubbleData.description"
          class="small"
        >
          {{ bubbleData.description }}
        </div>
        <div>{{ bubbleData.title }}</div>
      </div>
      <div
        v-if="bubbleData.checked || bubbleData.xButtonEvent"
        class="icon cross"
        role="button"
        tabindex="-1"
        @click="closeButtonClick"
        @keydown.enter="closeButtonClick"
      />
    </label>
  </div>
</template>

<script>
import { IconBase } from '@sales-i/dsv3';
import { BUBBLE_AREA_CUSTOMER, BUBBLE_AREA_PRODUCT, BUBBLE_AREA_SALES, BUBBLE_DATA_KEYS, BUBBLE_AREA_CALENDAR } from '@/intelligence/store/data/bubbles';

export default {
  components: {
    IconBase,
  },
  props: {
    bubbleData: {
      type: Object,
      required: true,
      validator: bubbleData => BUBBLE_DATA_KEYS.every(key => key in bubbleData),
    },
    collapsed: {
      default: false,
      type: Boolean,
    },
  },
  emits: ['bubbleClick', 'closeButtonClick'],
  data() {
    return {
      checked: false,
    };
  },
  computed: {
    iconBackground() {
      if (this.checked && this.bubbleData.clickable) {
        return 'var(--colour-utility-black)';
      } else if (this.bubbleData.disabled) {
        return 'var(--colour-panel-g-16)';
      } else if (this.bubbleData.iconBackground) {
        return this.bubbleData.iconBackground;
      }

      switch (this.bubbleData.area) {
      case BUBBLE_AREA_CUSTOMER:
        return 'var(--colour-data-turmeric-dark)';
      case BUBBLE_AREA_PRODUCT:
        return 'var(--colour-data-viking-dark)';
      case BUBBLE_AREA_SALES:
        return 'var(--colour-data-barberry-dark)';
      case BUBBLE_AREA_CALENDAR:
        return 'var( --colour-data-puerto-rico-dark)';
      default:
        return 'var(--colour-utility-error)';
      }
    },
    icon() {
      if (this.bubbleData.icon) return this.bubbleData.icon;

      switch (this.bubbleData.area) {
      case BUBBLE_AREA_PRODUCT:
        return 'product';
      case BUBBLE_AREA_SALES:
        return 'opps';
      case BUBBLE_AREA_CALENDAR:
        return 'calendar';
      default:
        return 'user-circle';
      }
    },
    emitBubbleDataSet() {
      return {
        id: this.bubbleData.id,
        checked: this.checked,
        area: this.bubbleData.area,
      };
    },
    isBubbleDisabled() {
      return (this.bubbleData.disabled && !this.checked) || !this.bubbleData.clickable;
    },
    iconSize() {
      return this.collapsed ? 16 : 18;
    },
  },
  watch: {
    bubbleData: { 
      handler(newData) {
        this.checked = newData.checked;
      },
      immediate: true
    }
  },
  methods: {
    bubbleClick() {
      this.$emit('bubbleClick', this.emitBubbleDataSet);
    },
    closeButtonClick() {
      if (this.bubbleData.xButtonEvent) this.$emit('closeButtonClick');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.bubble {
  display: flex;
  width: 100%;
  position: relative;

  &.collapsed {
    .icon {
      min-width: 32px;
      width: 32px;
      height: 32px;
    }
  }
  label {
    border-radius: var(--border-radius-1);
    flex-basis: 100%;
    display: flex;
    column-gap: var(--spacing-1);
    align-items: center;
    margin-bottom: 0;
    cursor: pointer;

    .icon-circle {
      border: 1px solid var(--colour-utility-base);
      border-radius: 50%;
      padding: 2px;
    }

    &.pointer {
      cursor: default;
      height: 48px;
    }

    &:active {
      // Removes the background when clicking (there's a perceptible delay between the click and the selection)
      background: none;
    }
  }

  &.selected {
    border: var(--spacing-half) solid var(--colour-utility-black);
    border-radius: var(--spacing-half);
    box-shadow: 0 0 0 var(--spacing-half) var(--colour-data-puerto-rico);
  }
  &.collapsed {
    .description,
    .cross {
      display: none;
    }

    + .filters {
      display: none;
    }
  }
}

.bubble-input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.cross {
  margin-left: auto;
  cursor: pointer;
}

.description {
  color: var(--colour-utility-black);

  .small {
    font-size: var(--font-size-small);
    color: var(--color-utility-base);

    + div {
      font-weight: var(--font-weight-semibold);
      white-space: nowrap;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        max-width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.disabled {
  opacity: 0.4;
}
</style>
