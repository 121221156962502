<template>
  <template v-if="isReportDataLoaded">
    <h4>{{ subtitle }}</h4>
    <template v-if="reportId">
      <TrendsChart
        v-if="settings.isChartShown"
        :class="item?.reportType"
        :report-id="reportId"
      />
      <TrendsTable
        v-if="settings.isTableShown"
        :report-id="reportId"
      />
      <p
        v-if="!settings.isChartShown && !settings.isTableShown"
        class="p-2"
      >
        {{ item.description }}
      </p>
    </template>
    <p
      v-else
      class="m-3"
    >
      {{ t('No results were found') }}
    </p>
  </template>
  <BufferImage
    v-if="!isReportDataLoaded"
    class="loading-spinner mt-4"
    color="var(--colour-utility-black)"
    float="center"
  />
</template>

<script setup>
// TrendsReportContent
import { computed, onMounted, ref, onBeforeUnmount, watch } from 'vue';
import { BufferImage } from '@sales-i/dsv3';
import { t, dates } from '@sales-i/utils';
import TrendsChart from '@/intelligence/components/Trends/TrendsChart';
import TrendsTable from '@/intelligence/components/Trends/TrendsTable';
import { useStore } from 'vuex';
import { CLEAR_REPORT_DATA, FETCH_REPORT_DATA } from '@/intelligence/store/actionType';
import { useWidget } from '@/intelligence/components/Dashboard/composables/useWidget';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  settings: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['update:menuOptions']);

onMounted(() => {
  loadReport();
  emit('update:menuOptions', menuOptions.value);
});

onBeforeUnmount(() => {
  clearReportData();
});

const store = useStore();
const {
  fetchDates,
  setLoading,
  getDateParams,
  chartAndTableMenuOptions,
} = useWidget({ store, props, options: { chartTypes: [] }});

const dateParams = computed(() => getDateParams(filters.value));

watch(() => props.settings,
  () => {
    emit('update:menuOptions', menuOptions.value);
  },
  { deep: true }
);

const isReportDataLoaded = ref(false);

const filters = computed(() => props.item.requestParams);
const subtitle = computed(() => `
${dates.format(filters.value.date_from)} - ${dates.format(filters.value.date_to)}, 
${dates.format(filters.value.date_from2)} - ${dates.format(filters.value.date_to2)}`);

const reportId = ref(0);
const inputData = computed(() => store.getters['intelligence/shared/getInputData'](props.item.reportType));

const customParams = ref({});

const menuOptions = computed(() => chartAndTableMenuOptions.value);
const fetchReportData = params => store.dispatch(`intelligence/shared/${FETCH_REPORT_DATA}`, params);
const clearReportData = params => store.dispatch(`intelligence/shared/${CLEAR_REPORT_DATA}`, params);

async function loadReport(completeRefresh = false) {
  if (!inputData.value) return;
  isReportDataLoaded.value = false;
  // apply the data from URL
  await setReportParams();
  setLoading(true);
  emit('update:menuOptions', menuOptions.value);
  // start fetching data from API
  reportId.value = await fetchReportData({
    reportType: props.item.reportType,
    completeRefresh,
    customParams: customParams.value
  });
  isReportDataLoaded.value = true;
}

async function setReportParams() {
  inputData.value.filters?.forEach(filter => {
    const queryParamKey = filter.queryParamKey;
    let queryParamValue = filters.value[queryParamKey] || filter.default;
    
    if (filter.isNumber) {
      queryParamValue = parseInt(queryParamValue);
    }
    if (filter.type === 'date') {
      customParams.value.date_from = filters.value.date_from;
      customParams.value.date_to = filters.value.date_to;
    } else {
      customParams.value[queryParamKey] = queryParamValue;
    }
  });
  
  // dates
  const fetchedDates = await fetchDates(dateParams.value);
  customParams.value = { ...customParams.value, ...fetchedDates };
}
</script>

<style lang="scss" scoped>
h4,h5 {
  text-align: center;
}

.report-table {
  margin-bottom: var(--spacing-1);
  max-height: 260px;
  /** todo: improve this later - it's temporary solution to not let chart be on top of the table */
  z-index: 1;
}
</style>
