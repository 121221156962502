<template>
  <div class="container container--fluid bg">
    <div class="padded">
      <div
        v-if="entity === 'customers' || entity === 'prospects'"
        class="toggle-container"
      >
        <span>{{ dynamicCustomerText }}</span>
        <CustomButton
          purpose="text"
          :small="true"
          @click="toggleView"
        >
          {{ t(dynamicButtonText) }}
        </CustomButton>
      </div>
      <div
        v-if="isContactView"
        class="contacts-list"
      >
        <BufferImage
          v-if="isContactsLoading || loading"
          class="loading-spinner"
          color="var(--colour-utility-black)"
          float="center"
        />
        <div
          v-for="(group, index) in contacts?.data"
          :key="`group-${index}`"
        >
          <div
            v-for="contact in group?.items"
            :key="contact.id"
            class="contact-entry"
          >
            <div class="flex align-center mt-2 mb-2">
              <IconBase
                height="25"
                width="25"
                icon-name="user-circle"
                icon-color="black"
              />
              <h2>{{ contact.full_name }}</h2>
            </div>
            <EntityList
              :key="contact.id"
              :contact-id="contact.id"
              :show-pagination="false"
              :loading="isContactsLoading || loading"
            >
              <div
                role="list"
                class="list list-card"
              >
                <CRMCard
                  v-for="(section, sectionIndex) in props.sectionsTitles.filter(s => s.show)"
                  :key="`section-${sectionIndex}`"
                  :links="cardLinks(section)"
                  @open="handleOpen(section, contact.id)"
                >
                  <template #info>
                    {{ section.description || '' }}
                  </template>
                </CRMCard>
              </div>
            </EntityList>
          </div>
        </div>
        <p 
          v-if="!contacts.data.length"
          class="no-results"
        >
          {{ t('No results were found') }}
        </p>
        <Pagination
          :offset="offset"
          :limit="limit"
          :records-count="recordsCount"
          @page-changed="changePage"
        />
      </div>
      <EntityList
        v-else-if="props.permissions.schema.read && props.permissions.entity.read"
        :show-pagination="false"
        :loading="loading && !loaded"
      >
        <div
          role="list"
          class="list list-card"
        >
          <CRMCard
            v-for="(section, index) in props.sectionsTitles.filter(s => s.show)"
            :key="index"
            :title="section.stage"
            :links="cardLinks(section)"
            @open="handleOpen(section)"
          >
            <template #info>
              {{ section.description || '' }}
            </template>
          </CRMCard>
        </div>
      </EntityList>
      <router-view
        v-if="vroute.params.sectionId"
        :permissions="props.permissions"
        :sections-titles="sectionsTitles"
      />
    </div>
  </div>
</template>

<script setup>
// CustomSectionsList
import { t } from '@sales-i/utils';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { baseUrl, customSectionsSection } from '@/crm/router/urlBits';
import { useRoute } from 'vue-router';
import EntityList from '@/crm/components/EntityList/EntityList.vue';
import { CRMCard, CustomButton, IconBase, BufferImage } from '@sales-i/dsv3';
import { GET_CUSTOM_SCHEMA } from '@/admin/store/actionType';
import { GET_CONTACTS } from '@/crm/store/actionType';
import { paginationProps } from '@/shared/composables/usePagination';
import Pagination from '@/shared/components/Pagination/Pagination.vue';
import { navigateToUrl } from 'single-spa';

const props = defineProps({
  ...paginationProps,
  permissions: {
    type: Object,
    default: () => ({}),
  },
  sectionsTitles: {
    type: Array,
    default: () => [],
  },
  entityName: {
    type: String,
    default: ''
  },
  isContactView: {
    type: Boolean,
    default: false
  }
});

const vroute = useRoute();
const store = useStore();
const emit = defineEmits(['toggleCustomSchema']);

const isContactsLoading = ref(false);
const offset = ref(0);
const limit = ref(50);

watch(() => props.isContactView, (newValue) => {
  if (newValue && (entity.value === 'customers' || entity.value === 'prospects')) {
    loadSchema({ entity: 'contacts' });
    loadPageData();
  }
  else {
    loadSchema({ entity: entity.value });
  }
}, { immediate: false });

onMounted(() => {
  loadSchema({ entity: entity.value, refreshCall: true });
});

const loadSchema = params => store.dispatch(`admin/schema/${GET_CUSTOM_SCHEMA}`, params);
const getContactsCustomSection = params => store.dispatch(`crm/${entity.value}/${GET_CONTACTS}`, params);

const entity = computed(() => vroute.path.split('/')[2]);
const entityId = computed(() => vroute.params.id);
const recordsCount = computed(() => contacts.value.data.reduce((prev, curr) => prev + curr.items.length, 0) || 0);
const contacts = computed(() => (entity.value ? store.state.crm[entity.value].contacts : {}));
const dynamicCustomerText = computed(() => props.isContactView ? t('Contacts') : props.entityName);
const dynamicButtonText = computed(() => props.isContactView ? props.entityName : t('Contacts'));

const cardLinks = section =>
  (section.fields || []).length
    ? [
      {
        text: t('Open'),
        id: section.id,
        emit: 'open',
      },
    ]
    : [];

const changePage = (page) => {
  offset.value = page;
  loadPageData();
};
function handleOpen(section, contactId) {
  if (!(section.fields || []).length) return;
  let path = `${baseUrl}/${entity.value}/${entityId.value}/${customSectionsSection}/${section.id}`;
  let query = new URLSearchParams(props.isContactView ? { contactId, entity: 'contacts' } : {});
  navigateToUrl(`${path}?${query.toString()}`);
}
function toggleView() {
  emit('toggleCustomSchema');
}
async function loadPageData() {
  isContactsLoading.value = true;
  await getContactsCustomSection({
    id: vroute.params.id,
    parameters: {
      limit: limit.value,
      offset: offset.value,
      sort: 'first_name:asc',
    },
  }).then(() => {
    isContactsLoading.value = false;
  }).catch((error) => {
    console.error('Error loading page data:', error);
    isContactsLoading.value = false;
  });
}
</script>

<style lang="scss" scoped>
.bg {
  background-color: var(--colour-panel-g-2);
  flex: 1;
}

.toggle-container {
  margin: var(--spacing-2) 0;
  display: flex;
  span {
    padding: var(--spacing-2);
  }

  button {
    font-weight: var(--font-weight-semibold);
    
    &:focus {
      color: var(--colour-utility-action);
    } 
  }
}

.no-results {
  text-align: center;
}
</style>
