import {
  EXPORT_REPORT_SALES_VS_GAPS,
  GENERATE_FILE_ID_SALES_VS_GAPS,
} from '@/intelligence/store/actionType';
import { gqlRequest, httpFileRequest } from '@sales-i/utils';
import { gql } from 'graphql-request';
import { saveAs } from 'file-saver';

export const svgActions = {
  [GENERATE_FILE_ID_SALES_VS_GAPS]: async (store, [report, payload]) => {
    const { address, currency, fileExt, reporter, name, } = payload;
    try {
      const getRequestParameters = store.rootGetters['intelligence/shared/getRequestParameters'];
      const 
        query = gql`{ exportsvg(
          ${getRequestParameters([report, { limit: 1000, limitX: 1000 }])}
          export_details: {
            file_type: ${fileExt},
            reporter: "${reporter}",
            customer: "${name}",
            customer_address: "${address}",
            currency_symbol: "${currency}",
            delimiter: COMMA
          }
        ) { 
        id
       }
      }`;
      const { exportsvg: { id } } = await gqlRequest(query, {}, { debug: true });
      return {id, extension: fileExt};
    } catch (error) {
      console.error(error);
    }
  },
  [EXPORT_REPORT_SALES_VS_GAPS]: async ( { dispatch }, [report, payload]) => {
    const file = await dispatch(GENERATE_FILE_ID_SALES_VS_GAPS, [report, payload]);
    const { id, extension } = file;
    try {
      const response = await httpFileRequest(`download/${id}`, {
        area: 'exports',
      });
      const blob = new Blob(
        [response.data],
        {type: 'text/plain;charset=utf-8'}
      );
      saveAs(blob, `${id}.${extension}`);
      return response;
    } catch (error) {
      console.error(error);
    }
  }
};