<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<template>
  <div
    :class="{ draggable: !props.disabled }"
    :draggable="!props.disabled"
    @dragstart="handleDragStart"
  >
    <slot />
  </div>
</template>

<script setup>
// DraggableItem
const props = defineProps({
  transferData: {
    type: [String, Number, Object],
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['dragStarted']);

const handleDragStart = event => {
  event.dataTransfer.setData('value', JSON.stringify(props.transferData));
  emit('dragStarted', props.transferData);
};
</script>

<style lang="scss" scoped>
.draggable {
  display: flex;
}
</style>
