<template>
  <div style="position: relative">
    <CustomCard
      v-if="loaded && contact && contact.id"
      purpose="reversed"
      footer-color="grey"
    >
      <template #header>
        <div class="flex align-center mb-1">
          <IconBase
            icon-name="user-circle"
            icon-color="black"
            :height="46"
            :width="46"
          />
          <CustomRibbon
            type="action"
            rounded
          >
            {{ contact.is_default_contact ? t('Default contact') : t('Contact') }}
          </CustomRibbon>
        </div>

        <h3 class="fw-sbld">
          {{ contact.full_name }}
        </h3>

        <div class="flex contact-icons">
          <RagChip
            v-if="false"
            :value="contact.percent_complete"
            suffix="%"
            :green="67"
            :amber="33"
            small
          />
          <CustomButton
            v-if="contact.mobile_phone"
            icon
            :href="getTelephoneLink(contact.mobile_phone)"
            :width="40"
            :height="40"
          >
            <span class="visually-hidden">{{ t('Telephone') }}</span>
            <IconBase
              icon-name="phone"
              :width="24"
              :height="24"
            />
          </CustomButton>
          <CustomButton
            v-if="contact.email && authData.status === 'VALID'"
            icon
            :width="40"
            :height="40"
            :disabled="loadingMail"
            @on-click="handleViewMail"
          >
            <span class="visually-hidden">{{ t('Email') }}</span>
            <template v-if="!loadingMail">
              <IconBase
                icon-name="email"
                :width="24"
                :height="24"
              />
            </template>
            <template v-else>
              <BufferImage
                :size="20"
                color="var(--colour-utility-black)"
              />
            </template>
          </CustomButton>
        </div>
      </template>
      <MiniMail
        v-if="mailOpen"
        :name="contact.full_name"
        :email-address="contact.email"
        :items="loadedContent"
        :mode="mailMode"
        :loading="loadingMail"
        :show-view-more="viewMorePossible"
        @composing="setComposing"
        @view-more="loadMoreMail"
      />
      <LoadingOverlay
        v-if="loading"
        style="border-radius: var(--border-radius-1)"
      />
      <div
        v-else
        class="default-contact--body"
      >
        <dl :class="`detail-grid flow ${mq.current}`">
          <div
            v-for="(item, index) in displayFields"
            :key="index"
            class="dl-wrap flow"
          >
            <dt>{{ t(item.label) }}:</dt>
            <dd v-if="/email/iu.test(item.label)">
              <a :href="getMailtoLink(item.value)">{{ item.value }}</a>
            </dd>
            <dd v-else-if="/telephone/iu.test(item.label)">
              <a :href="getTelephoneLink(item.value)">{{ item.value }}</a>
            </dd>
            <dd v-else-if="/created/iu.test(item.label)">
              {{ dates.format(item.value) }}
            </dd>
            <dd
              v-else-if="/web/iu.test(item.label)"
              class="website"
            >
              <a
                :href="item.value"
                target="_blank"
                rel="noopener noreferrer"
              >{{ item.value }}</a>
            </dd>
            <dd v-else-if="item.label === 'Address'">
              <Address
                :key="index"
                :value="item.value"
              />
            </dd>
            <dd v-else>
              {{ item.value }}
            </dd>
          </div>
        </dl>
      </div>
      <template
        v-if="showActions && canUpdateContacts"
        #footer
      >
        <ButtonGroup>
          <CustomButton
            small
            :label="t('Update profile')"
            @on-click="updateContact"
          />
        </ButtonGroup>
      </template>
    </CustomCard>
    <CustomCard
      v-if="loaded && contact && !contact.id"
      purpose="reversed"
      footer-color="grey"
    >
      <template #header>
        <div class="flex align-center">
          <IconBase
            icon-name="user-circle"
            icon-color="black"
            :height="46"
            :width="46"
          />
          <CustomRibbon
            type="action"
            rounded
          >
            {{ t('Default contact') }}
          </CustomRibbon>
        </div>
      </template>
      <h2 class="text-3 fw-sbld">
        {{ t('No default contact') }}
      </h2>
      <template
        v-if="showActions && canCreate"
        #footer
      >
        <ButtonGroup>
          <CustomButton
            small
            :label="t('Add a contact')"
            @on-click="addContact"
          />
        </ButtonGroup>
      </template>
    </CustomCard>
  </div>
</template>

<script setup>
// ContactCard
import { ref, computed, onUnmounted } from 'vue';
import { useMq } from 'vue3-mq';

import { BufferImage, CustomCard, IconBase, CustomButton, LoadingOverlay, RagChip, CustomRibbon } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import mapCustomerContactDetails from '@/shared/utils/mapCustomerContactDetails';
import Address from '@/shared/components/Map/Address.vue';
import usePermissions from '@/shared/composables/usePermissions';
import { contacts } from '@/shared/store/data/policies';
import { dates, t, mailState, getMail } from '@sales-i/utils';
import MiniMail from '@/crm/components/Mail/MiniMail.vue';

const mq = useMq();

const props = defineProps({
  contact: {
    type: Object,
    default: () => {},
  },
  loaded: {
    type: Boolean,
    default: true,
  },
  entityType: {
    type: [String, Number],
    default: 'customer',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  showActions: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['add', 'update']);

const authData = ref({});

const rxm = mailState.subscribe(newMailState => {
  authData.value = newMailState.details;
});

const loadedContent = ref([]);
const composing = ref(false);
const mailMode = ref('view');
const loadingMail = ref(false);
const mailOpen = ref(false);
const viewMorePossible = ref(true);
const limit = ref(3); // this is never changed, just makes sense to scope it here
const offset = ref(0);

const displayFields = computed(() => mapCustomerContactDetails(props.contact));
const { hasAccess } = usePermissions();
const canCreate = computed(() => hasAccess(contacts, 'create'));
const canUpdateContacts = computed(() => hasAccess(contacts, 'update'));

function addContact() {
  emit('add');
}

function updateContact() {
  emit('update');
}

/**
 * These functions were the subject of the review by SonarCloud, because a\
 * person could potentially inject something into the item.value and it would
 * be executed by the application, unescaped by Vue
 */
function getTelephoneLink(phone) {
  return `tel:${phone}`;
}

function getMailtoLink(email) {
  return `mailto:${email}`;
}

// TODO: move to a composable, in SimpleContactCard & InteractionDetails
const handleViewMail = async(e) => {
  e.stopPropagation();
  if (mailOpen.value) {
    mailOpen.value = false;
    loadedContent.value = [];
    offset.value = 0;
    viewMorePossible.value = true;
  } else {
    await loadMail();
    mailOpen.value = true;
  }
};

const loadMail = async() => {
  loadingMail.value = true;
  let results = await getMail({
    limit: limit.value,
    // offset: offset.value, need to replace with v3 equivalent, non-working now
    any_email: props.contact.email,
  }) ?? [];
  if (results.length < limit.value) {
    viewMorePossible.value = false;
  }
  loadedContent.value = [
    ...results,
  ];
  loadingMail.value = false;
  return loadedContent.value;
};

const loadMoreMail = () => {
  offset.value += 3;
  loadMail();
};
const setComposing = (isComposing) => {
  composing.value = isComposing;
  mailMode.value = isComposing ? 'message' : 'view';
};

onUnmounted(() => {
  rxm.unsubscribe();
});
</script>

<style lang="scss" scoped>
.svg-container {
  z-index: 1;
}

.ribbon {
  margin-left: calc(var(--spacing-1) * -1);
}

.default-contact--body {
  margin: var(--spacing-2) calc(var(--spacing-3) * -1) var(--spacing-3);
  padding: var(--spacing-3) var(--spacing-3) 0;
  border-top: 2px solid var(--colour-panel-g-4);
}

.contact-icons {
  align-items: center;
  line-height: 0;
  margin-top: var(--spacing-1);
  gap: var(--spacing-2);
}

:deep(.c-card--body) {
  flex: 1 0 auto;
}
</style>
