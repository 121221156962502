import { FETCH_LAST_INVOICE } from '@/intelligence/store/actionType';
import { httpRequest } from '@sales-i/utils';
import querystring from 'querystring';

// initial state
const state = () => ({
  lastInvoice: '',
});

// getters
const getters = {};

// mutations
const mutations = {
  [FETCH_LAST_INVOICE]: (state, data) => {
    state.lastInvoice = data;
  },
};

// actions
const actions = {
  [FETCH_LAST_INVOICE]: async({ commit }, customerId) => {
    try {
      const params = querystring.stringify(customerId);
      const response = await httpRequest('get', `invoices/last?${params}`, {}, {
        version: 2,
        area: 'erp',
      });
      commit(FETCH_LAST_INVOICE, response);
    } catch (e) {
      console.error(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
