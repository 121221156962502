/* eslint-disable no-unused-vars */
import {
  GET_FIELDS,
  CLEAR_FIELDS
} from '@/shared/store/actionType';
// import store from '@/shared/store';
import commentData from '@/shared/store/data/commentFields';

export const fieldsMutations = {
  [GET_FIELDS]: async (state, { comment, data, }) => {
    let dataTemp = data;
    dataTemp = dataTemp.map(stage => {
      const fields = stage.fields.map(field => {
        if (comment) {
          if (field.id === 'comment') {
            field.value = comment.comment;
          }
        }
        return field;
      });
      stage.fields = fields;
      return stage;
    });
    state.fields = dataTemp;
  },
  [CLEAR_FIELDS]: (state) => {
    state.fields = [];
    state.selected.loading = false;
    state.selected.loaded = true;
  },
};

export const fieldsActions = {
  [GET_FIELDS]: ({ commit, }, comment) => {
    const data = JSON.parse(JSON.stringify(commentData));
    commit(GET_FIELDS, {
      comment,
      data,
    });
  },
  [CLEAR_FIELDS]: async ({ commit, }) => {
    commit(CLEAR_FIELDS);
  },
};
