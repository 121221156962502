<template>
  <div
    v-if="items?.length"
    class="expandable-list"
    :class="[mode, { isExpanded }]"
  >
    <ul>
      <li
        v-for="(item, index) in visibleItems"
        :key="index"
      >
        <slot
          name="item"
          v-bind="item"
        >
          {{ item?.[field]?.trim() }}<span v-show="items?.length > itemsToDisplay && index == visibleItems.length - 1 && !isExpanded">, &hellip;</span>
        </slot>
        <slot
          v-if="items?.length > itemsToDisplay && index == visibleItems.length - 1"
          name="cta"
        >
          <CustomButton
            purpose="transparent"
            class="expand-btn"
            :icon-name="isExpanded? 'chevron-up' : 'chevron-down'"
            icon-color="var(--colour-utility-action)"
            icon-only
            :icon-height="18"
            :icon-width="18"
            @click="handleExpand"
          />
        </slot>
      </li>
    </ul>
  </div>
  <div v-else>
    <slot name="noitems" />
  </div>
</template>

<script setup>
// ExpandableList
import { CustomButton } from '@sales-i/dsv3';
import { computed, ref } from 'vue';

const emit = defineEmits(['update:expanded']);

const props = defineProps({
  mode: {
    type: String,
    default: 'comma'
  },
  items: {
    type: Object,
    required: true
  },
  field: {
    type: String,
    default: 'value'
  },
  itemsToDisplay: {
    type: Number,
    default: 3
  },
  expanded: {
    type: Boolean,
    default: false
  }
});

const isExpanded = ref(props.expanded);
const visibleItems = computed(() => (isExpanded.value ? props.items : props.items.slice(0, props.itemsToDisplay || 3)));

function handleExpand() {
  isExpanded.value = !isExpanded.value;
  emit('update:expanded', isExpanded.value); 
}
</script>

<style lang="scss" scoped>
.comma {
  display: inline-block;
  list-style: none;    

  li {
    display: inline;

    .expand-btn {
      display: inline-block;
      vertical-align: middle;
      padding-top: 0!important;
    }
  }

  li:after {
    content: ", ";
  }

  li:last-child:after {
    content: "";
  }
}
</style>
