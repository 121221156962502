import CustomerOverviewIframe from '@/crm/views/Iframe/CustomerOverviewIframe';
import TenantDashboard from '@/crm/views/Dashboard/Dashboard';
import { iframeArea, overviewSection } from '@/crm/router/urlBits';
import { alertsScope, } from '@/intelligence/router/urlBits';
import AlertBuilder from '@/intelligence/views/Communities/AlertBuilder.vue';
import AlertBuilderWizard from '@/intelligence/router/Communities/AlertBuilderWizard';
import EnquiriesTab from '@/crm/views/Dashboard/EnquiriesTab.vue';
import OverviewTab from '@/crm/views/Dashboard/OverviewTab.vue';
import OpportunitiesTab from '@/crm/views/Dashboard/OpportunitiesTab.vue';
import SalesComparisonTab from '@/crm/views/Dashboard/SalesComparisonTab.vue';
import { NEW_ALERT_IFRAME_ROUTE } from '@/intelligence/router/routeNames';

export default [
  {
    path: `/${iframeArea}/${overviewSection}/`,
    name: 'iframeTenantOverview',
    component: TenantDashboard,
    children: [
      {
        path: '',
        redirect: `/${iframeArea}/${overviewSection}/insights`
      },
      {
        path: 'insights',
        component: OverviewTab,
        meta: { activeIndex: 0 }
      },
      {
        path: 'enquiries',
        component: EnquiriesTab,
        meta: { activeIndex: 1 }
      },
      {
        path: 'comparison',
        component: SalesComparisonTab,
        meta: { activeIndex: 2 }
      },
      {
        path: 'opportunities',
        component: OpportunitiesTab,
        meta: { activeIndex: 3 }
      },
      {
        path: alertsScope,
        name: NEW_ALERT_IFRAME_ROUTE,
        component: AlertBuilder,
        meta: { activeIndex: 4 },
        children: [
          ...AlertBuilderWizard
        ],
      },
      {
        path: `${alertsScope}/:queryId`,
        component: AlertBuilder,
        meta: { activeIndex: 4 },
        children: [
          ...AlertBuilderWizard
        ]
      },
    ]
  },
  {
    path: `/${iframeArea}/${overviewSection}/:id([^/]+)`,
    name: 'iframeCustomerOverview',
    component: CustomerOverviewIframe
  },
];
