import { getAllActions, getAllMutations } from './getAll';

const state = () => ({
  errors: [],
  loading: true,
  loaded: false,
  sections: [],
});

const getters = {};

const mutations = {
  ...getAllMutations,
};

const actions = {
  ...getAllActions,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};