import { MERGE_PROSPECT, GET_MERGE_ITEMS } from '@/crm/store/actionType';
import { httpRequest } from '@sales-i/utils';

// actions
export const mergeActions = {
  [GET_MERGE_ITEMS]: async (_x, { id }) => {
    try {
      const res = await httpRequest('get', `prospects/${id}/mergeable`);
      return res?.customers || [];
    } catch (error) {
      return false;
    }
  },
  [MERGE_PROSPECT]: async (_x, { id, customer_id }) => {
    try {
      await httpRequest('put', `prospects/${id}/merge/${customer_id}`);
      return true;
    } catch (error) {
      return false;
    }
  },
};

export default {
  namespaced: true,
  mergeActions,
};