// useCustomerFilters.js
import { ADD_CUSTOMER_FILTER_TO_QUERY, REMOVE_ALL_CUSTOMER_FILTER_FROM_QUERY, REMOVE_CUSTOMER_FILTER_FROM_QUERY, UPDATE_CUSTOMER_FILTER_IN_QUERY } from '@/intelligence/store/actionType';
import { computed } from 'vue';
import { useStore } from 'vuex';

export function useCustomerFilters({ store = useStore() } = {}) {
  const customerFilters = computed(() => store.state.intelligence.queryBuilder.customerFilters);
  const filterClauses = computed (() => store.state.intelligence.queryBuilder.customerFilters.filterClauses);

  const  isValid = computed(() => filterClauses.value.every(({ entity, field, is, value }) => (
    (field === 'interaction_type' && (value === '' || value?.length >= 0)) ||
    (field === 'date_end' && (!is || is !== 'BETWEEN' && value?.length == 1 || is == 'BETWEEN' && value?.length == 2)) ||
    (entity === 'contact' && value !== '') ||
    (entity === 'address' && field !== '' && value !== '')
  )));

  const addFilter = (filter) => store.dispatch(`intelligence/queryBuilder/${ADD_CUSTOMER_FILTER_TO_QUERY}`, filter);
  const updateFilter = (filter) => store.dispatch(`intelligence/queryBuilder/${UPDATE_CUSTOMER_FILTER_IN_QUERY}`, filter);
  const removeFilter = (filter) => store.dispatch(`intelligence/queryBuilder/${REMOVE_CUSTOMER_FILTER_FROM_QUERY}`, filter);
  const removeAllFilters = () => store.dispatch(`intelligence/queryBuilder/${REMOVE_ALL_CUSTOMER_FILTER_FROM_QUERY}`);

  return {
    customerFilters,
    filterClauses,
    isValid,
    addFilter,
    updateFilter,
    removeFilter,
    removeAllFilters,
  };
}