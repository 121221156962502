<template>
  <OpportunitiesDetail
    :type="type.toUpperCase()"
    :reload="reload"
    :customer-id="customerId"
    :type-filter="typeFilter"
    :is-rolled-up="isRolledUp"
    @dismiss="dismissItem"
    @opportunities-loaded="opportunitiesLoaded"
    @change-opportunity-level="changeOpportunityLevel"
  />
</template>

<script>
import OpportunitiesDetail from '@/intelligence/views/Opportunities/OpportunitiesDetail.vue';

export default {
  name: 'OpportunitiesView',
  components: {
    OpportunitiesDetail,
  },
  props: {
    type: {
      type: String,
      default: 'missed'
    },
    typeFilter: {
      type: String,
      default: 'item_level',
      validator: function (value) {
        return ['item_level', 'group_level'].includes(value);
      }
    },
    reload: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: [String, Number],
      default: ''
    },
    isRolledUp: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['dismiss', 'opportunitiesLoaded', 'changeOpportunityLevel'],
  mounted() {
  },
  methods: {
    dismissItem(item) {
      this.$emit('dismiss', item);
    },
    opportunitiesLoaded() {
      this.$emit('opportunitiesLoaded');
    },
    changeOpportunityLevel(level) {
      this.$emit('changeOpportunityLevel', level);
    }
  },
};
</script>
