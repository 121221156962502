/* eslint-disable no-unused-vars */
import {
  GET_ALL,
  GET_ALL_LOADING,
  GET_ALL_ERROR,
  CLEAR_ALL
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import runIfPermittedOrReject from '@/shared/store/utils/runIfPermittedOrReject';
import querystring from 'querystring';

// mutations
export const getAllMutations = {
  [GET_ALL]: (state, data) => {
    state.all.data = data;
    state.all.metadata = data.metadata;
    state.all.loaded = true;
    state.all.loading = false;
  },
  [GET_ALL_LOADING]: (state) => {
    state.all.loaded = false;
    state.all.loading = true;
  },
  [GET_ALL_ERROR]: (state) => {
    state.all.loaded = false;
    state.all.loading = false;
    state.all.errors = [];
  },
  [CLEAR_ALL]: (state) => {
    state.all.data = [];
    state.all.loaded = false;
    state.all.loading = false;
    state.all.errors = [];
  },
};

// actions
export const getAllActions = {
  [GET_ALL]: async ({ commit, state, rootState }, parameters) => {
    await runIfPermittedOrReject(
      rootState.pbac.permissionsGranted,
      { name: state.permissionSet },
      async () => {
        try {
          const params = querystring.stringify(parameters);
          commit(GET_ALL_LOADING);
          const response = await httpRequest('get', `prospects?${params}`, {}, {version: '2',});
          const data = response;
          commit(GET_ALL, data);
        } catch (error) {
          commit(GET_ALL_ERROR);
        }
      },
    );
  },
  [CLEAR_ALL]: ({ commit, }) => {
    commit(CLEAR_ALL);
  },
};
