import ProspectsTabView from '@/crm/views/Prospects/Prospect.vue';
import ProspectsDashboardRoute from './ProspectsDashboard';
import ProspectDetailsRoute from './ProspectDetails';
import ProspectPostsRoute from './ProspectPosts';
import ProspectContactsRoute from './ProspectContacts';
import FilesRoute from '@/crm/router/Files';
import ProspectCustomSectionsRoute from './ProspectCustomSections';
import ProspectInteractionsHistory from './ProspectInteractionsHistory';
import { baseUrl, prospectsArea as area } from '@/crm/router/urlBits';

export default [
  ProspectsDashboardRoute,
  {
    path: `${baseUrl}/${area}/:id(\\d+)`,
    component: ProspectsTabView,
    children: [
      ProspectDetailsRoute,
      ProspectPostsRoute,
      ProspectContactsRoute,
      ProspectCustomSectionsRoute,
      ProspectInteractionsHistory,
      ...FilesRoute,
    ],
  }
];
