<template>
  <div class="date-filter">
    <div class="description">
      <IconBase
        icon-name="clock"
        :height="48"
        :width="48"
      />
      <h3>{{ t('Select period') }}</h3>
    </div>
    <button
      v-for="option in options"
      :key="option.text"
      class="option"
      @click="setActivePeriod(option.value)"
    >
      {{ capitalize(t(option.text)) }}
    </button>
  </div>
</template>

<script>
import { IconBase } from '@sales-i/dsv3';
import { PERIODS } from '@/intelligence/store/data/periods';
import { SET_PERIOD_FILTER } from '@/intelligence/store/actionType';
import { mapActions, mapGetters } from 'vuex';
import { capitalize } from '@/shared/utils/strings';
import { t } from '@sales-i/utils';

export default {
  components: {
    IconBase,
  },
  emits: ['onSetActivePeriod'],
  data() {
    return {
      options: PERIODS,
    };
  },
  computed: {
    ...mapGetters({
      selectedValue: 'intelligence/enquiry/getPeriod',
    }),
  },
  methods: {
    t,
    capitalize,
    ...mapActions({
      setPeriodFilter: `intelligence/enquiry/${SET_PERIOD_FILTER}`,
    }),
    setActivePeriod(value) {
      this.setPeriodFilter(value);
      this.$emit('onSetActivePeriod', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.date-filter {
  max-width: 360px;
  background: var(--colour-panel-g-0);
  box-shadow: 0 0 var(--shadow-blur) var(--shadow-spread) var(--colour-panel-g-16);
  border-radius: var(--border-radius-1);
  padding: var(--spacing-3) var(--spacing-2);
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto var(--spacing-8);
  position: relative;
}

.description {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: var(--spacing-3);

  h3 {
    font-weight: var(--font-weight-semibold);
    flex-basis: 100%;
  }
}

.option {
  background: var(--colour-panel-action);
  color: var(--colour-data-deluge);
  font-size: var(--font-size-4);
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-2);
}
</style>
