<template>
  <div class="opportunity-stages">
    <div
      v-for="(stage, index) in stages"
      :key="stage.id"
      class="pill"
      :class="{ 
        'active': stage.id == opportunity.stage_id, 
        'stage-completed': index < stageIndex, 
        'current-stage': index === stageIndex 
      }"
    >
      <IconBase
        v-if="index < stageIndex"
        class="check-icon"
        icon-color="var(--colour-data-de-york-label)"
        icon-name="check"
        :height="20"
        :width="20"
      />
      <span class="stage-name">{{ truncate(stage.name, 15) }}</span>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { IconBase } from '@sales-i/dsv3';
import { truncate } from '@/shared/utils/strings';
import { useOppStages } from '@/crm/composables/useOppStages';

defineProps({
  opportunity: {
    type: Object,
    default: () => ({}),
  },
  stageIndex: {
    type: Number,
    default: 0,
  },
});

const { stages, fetchStages  } = useOppStages();

onMounted(() => {
  if (!stages.value.length) fetchStages();
});
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.opportunity-stages {
  display: flex;
  flex-flow: column nowrap;
  max-width: 240px;
  padding-right: var(--spacing-4);
  align-items: center;

  .pill {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 200px;
    margin: var(--spacing-half) 0;
    color: var(--colour-panel-g-56);
    padding: var(--spacing-1) var(--spacing-2);
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    background: var(--colour-utility-white);
    border-radius: 100px;
    box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-colour);

    .check-icon {
      margin-right: var(--spacing-1);
    }

    .stage-name {
      flex-grow: 1;
    }
  }

  .active {
    background: var(--colour-utility-black);
    color: var(--colour-utility-white);
  }

  .stage-completed {
    background: var(--colour-data-de-york-background);
    color: var(--colour-data-de-york-label);
  }

  .current-stage {
    background: var(--colour-utility-black);
    color: var(--colour-utility-white);
  }
}
</style>
