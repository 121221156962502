import {
  SET_TAGS_TO_CONSOLIDATE,
  SET_TAG_TO_CONSOLIDATE_TO,
  DELETE_TAG_CONSOLIDATION,
  PUT_TAG_CONSOLIDATION,
  PUT_TAG_CONSOLIDATION_LOADING,
  PUT_TAG_CONSOLIDATION_ERROR
} from '@/admin/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const consolidateTagsMutations = {
  [SET_TAGS_TO_CONSOLIDATE]: (state, data) => {
    state.consolidateTags.original_tag = data.original_tag;
  },
  [SET_TAG_TO_CONSOLIDATE_TO]: (state, data) => {
    state.consolidateTags.to_tag = data.to_tag;
  },
  [DELETE_TAG_CONSOLIDATION]: (state, index) => {
    state.consolidateTags.original_tag.splice(index, 1);
  },
  [PUT_TAG_CONSOLIDATION]: (state) => {
    state.report.data = [];
    state.report.loaded = false;
    state.report.loading = false;
  },
  [PUT_TAG_CONSOLIDATION_LOADING]: (state) => {
    state.report.data = [];
    state.report.loaded = false;
    state.report.loading = true;
  },
  [PUT_TAG_CONSOLIDATION_ERROR]: (state) => {
    state.report.loaded = false;
    state.report.loading = false;
    state.report.errors = [];
  },
};

// actions
export const consolidateTagsActions = {
  [SET_TAGS_TO_CONSOLIDATE]: async ({commit}, data) => {
    commit(SET_TAGS_TO_CONSOLIDATE, data);
  },
  [SET_TAG_TO_CONSOLIDATE_TO]: async ({commit}, data) => {
    commit(SET_TAG_TO_CONSOLIDATE_TO, data);
  },
  [DELETE_TAG_CONSOLIDATION]: ({ commit, }, index) => {
    commit(DELETE_TAG_CONSOLIDATION, index);
  },
  [PUT_TAG_CONSOLIDATION]: async ({ state, commit, }) => {
    commit(PUT_TAG_CONSOLIDATION_LOADING);
    let tagsToPost = {
      original_tag: state.consolidateTags.original_tag,
      to_tag: state.consolidateTags.to_tag,
    };
    try {
      const data = await httpRequest('put', 'tags/consolidate', tagsToPost, {version: '2',});
      commit(PUT_TAG_CONSOLIDATION, data);
      return true;
    } catch (error) {
      console.error(error);
      commit(PUT_TAG_CONSOLIDATION_ERROR);
      return false;
    }
  }
};
