import SalesOpportunityView from '@/intelligence/views/SalesOpportunity/SalesOpportunityView.vue';
import { baseUrl, insightsScope } from '@/intelligence/router/urlBits';
import { SALES_OPPORTUNITY } from '@/intelligence/store/data/reportTypes';
import { REPORT_AREA_PERFORMANCE } from '@/intelligence/store/data/areas';

export default [
  {
    path: `${baseUrl}/${insightsScope}/${REPORT_AREA_PERFORMANCE}/${SALES_OPPORTUNITY}`,
    component: SalesOpportunityView,
    name: SALES_OPPORTUNITY,
  }
];
