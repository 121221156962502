import {
  DELETE_BY_ID,
  DELETE_BY_ID_LOADING,
  DELETE_BY_ID_ERROR
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

// getters
const getters = {
};

// mutations
export const deleteByIdMutations = {
  [DELETE_BY_ID]: (state) => {
    state.selected.loading = false;
    state.selected.loaded = true;
    state.selected.updated = true;
    state.selected.data = {};
  },
  [DELETE_BY_ID_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [DELETE_BY_ID_ERROR]: (state, data) => {
    state.selected.loading = false;
    state.selected.loaded = true;
    state.selected.updated = false;
    state.selected.errors = data;
  },
};

// actions
export const deleteByIdActions = {
  [DELETE_BY_ID]: async ({ commit, }, params) => {
    try {
      const { id, comment_id, } = params;
      commit(DELETE_BY_ID_LOADING);
      await httpRequest('delete', `posts/${id}/comments/${comment_id}`, {}, { version: '2', });
      commit(DELETE_BY_ID);
    } catch (error) {
      commit(DELETE_BY_ID_ERROR, error.errors);
    }
  },
};

export default {
  namespaced: true,
  getters,
  deleteByIdActions,
  deleteByIdMutations,
};
