import { computed } from 'vue';
import { useStore } from 'vuex';
import { DELETE_FILTER } from '@/intelligence/store/actionType';

export function useFilters({ store = useStore() } = {}) {
  const filtersApplied = computed(() => store.state.intelligence.enquiry.filtersApplied);
  const bubbleData = computed(() => store.state.intelligence.enquiry.bubbleData);

  const deleteFilter = filter => store.dispatch(`intelligence/enquiry/${DELETE_FILTER}`, filter);

  async function applyFilterFromUrl(val, index) {
    const filterVal = val.split(',');
    const id = filterVal[0];
    const selectedFilterType = filterVal[2];
    const selectedFieldTitle = bubbleData.value.find(bbl => bbl.id === id)?.title;
    const bubbles = await store.dispatch('intelligence/enquiry/FETCH_BUBBLE_VALUES', [filterVal[0], '']);
    const selectedVals = filterVal.slice(3).map(id => {
      const decodedId = decodeURIComponent(decodeURI(id));
      const value = bubbles.find(bubble => decodedId === bubble.id)?.value;
      return { id: decodedId, value };
    });
    const newFilter = {
      title: `Filter ${index + 1}`,
      description: `${selectedFilterType} > ${selectedFieldTitle}`,
      id,
      filter_type: filterVal[1],
      selectedFilterType,
      selected_values: selectedVals,
      selectedFieldTitle,
    };
    store.dispatch('intelligence/enquiry/SET_FILTERS', [newFilter, index]);
  }

  return {
    applyFilterFromUrl,
    filtersApplied,
    bubbleData,
    deleteFilter,
  };
}
