import { getUsersGetters, getUsersMutations, getUsersActions } from './getUsers';

// initial state
const state = () => ({
  data: [],
  loaded: false,
  loading: false,
});

const getters = {
  ...getUsersGetters,
};

// mutations
const mutations = {
  ...getUsersMutations,
};

// actions
const actions = {
  ...getUsersActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
