import {
  PUT_BY_ID,
  PUT_BY_ID_LOADING,
  PUT_BY_ID_ERROR
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const putByIdMutations = {
  [PUT_BY_ID]: (state, data) => {
    state.selected.data = data;
    state.selected.loading = false;
    state.selected.loaded = true;
    state.selected.updated = true;
  },
  [PUT_BY_ID_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [PUT_BY_ID_ERROR]: (state) => {
    state.selected.loading = false;
    state.selected.loaded = false;
    state.selected.updated = false;
  },
};

// actions
export const putByIdActions = {
  [PUT_BY_ID]: async ({ commit, }, params) => {
    const { id, comment_id, payload, } = params;
    commit(PUT_BY_ID_LOADING);
    try {
      const data = await httpRequest('put', `posts/${id}/comments/${comment_id}`, payload, {version: '2',});
      commit(PUT_BY_ID, {
        ...payload,
        ...data,
      });
    } catch (error) {
      commit(PUT_BY_ID_ERROR);
    }
  },
};
