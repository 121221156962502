<template>
  <div class="button-icon-container">
    <div
      v-for="link in links"
      :key="link.name"
    >
      <CustomButton
        v-if="isButton"
        :label="link.label"
        :small="link.small"
        @on-click="link.function"
      />

      <CustomButton
        v-if="!isButton"
        :icon-name="link.name"
        icon-color="white"
        icon-only
        :icon-height="breakpoints.mdAndDown.includes(mq.current) ? iconMobile : iconDesktop"
        :icon-width="breakpoints.mdAndDown.includes(mq.current) ? iconMobile : iconDesktop"
        :small="breakpoints.mdAndDown.includes(mq.current)"
        @on-click="link.function"
      />
    </div>
  </div>
</template>

<script>
import { CustomButton } from '@sales-i/dsv3';
import breakpoints from '@/shared/utils/breakpoints';

export default {
  name: 'EntityLinks',
  components: {
    CustomButton,
  },
  inject: ['mq'],
  props: {
    isButton: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    links: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      breakpoints,
      iconColor: 'white',
      iconDesktop: 24,
      iconMobile: 34,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
