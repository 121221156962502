
import { escapeSpecialCharacters } from '@sales-i/utils';

// Highlight text
export const highlight = function(text, search) {
  if (!text) return '';
  
  // Create a check regex without replacing spaces in the search term
  var check = new RegExp(escapeSpecialCharacters(search), 'ig');
  
  return text.toString().replace(check, function(matchedText) {
    // Wrap matched text with highlight span
    return '<span class="highlight">' + matchedText + '</span>';
  });
};