<template>
  <form class="form-grid flow py-3">
    <div
      v-if="!loading"
      class="three-col-equal-height"
    >
      <div>
        <div
          v-for="item in firstColumn"
          :key="item.id"
        >
          <Field
            v-bind="item"
            twoway
            @valid="validateField"
          />
        </div>
      </div>
      <div>
        <div
          v-for="item in secondColumn"
          :key="item.id"
        >
          <Field
            v-bind="item"
            twoway
            @valid="validateField"
          />
        </div>
      </div>

      <div>
        <div
          v-for="item in thirdColumn"
          :key="item.id"
        >
          <Field
            v-bind="item"
            twoway
            @valid="validateField"
          />
        </div>
      </div>
    </div>
  </form>
  <div class="form-actions">
    <!-- Button should be disabled to further notice (task/SA-4925) -->
    <CustomButton
      v-if="false"
      purpose="reversed"
      small
      :disabled="!valid"
      @on-click="completeStage(true)"
    >
      {{ t('Add as company wide') }}
    </CustomButton>
    <CustomButton
      purpose="action"
      small
      :disabled="!valid"
      @on-click="completeStage(false)"
    >
      {{ t('Select dimensions') }}
    </CustomButton>
  </div>
</template>

<script setup>
// AddEditTargetFields
import { computed, ref, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import Field from '@/shared/components/Form/Field.vue';
import { CustomButton } from '@sales-i/dsv3';
import { TARGET_INTERVALS } from '@/intelligence/store/data/dateFilters';

// setup
const store = useStore();
const emit = defineEmits(['completeStage']);
const props = defineProps({
  targetData: {
    type: Object,
    default: () => ({})
  },
  isModalOpened: {
    type: Boolean,
    default: false
  }
});

// store
const measurements = computed(() => store.state.intelligence.targets.measurementsData);

// computed
const metricOptions = computed(() => measurements.value.length ? measurements.value.map(el => (
  { unit: el.unit, text: el.metric, value: el.id }
)) : []);
const valid = computed(() => fields.value.every(field => field.valid));

const firstColumn = ref([
  {
    label: t('Target Name'),
    id: 'target-name',
    type: 'text',
    value: '',
    errorMessage: t('Please enter target name'),
    valid: false,
    required: true,
  },
]);

const secondColumn = ref([
  {
    label: t('Target Description'),
    id: 'target-description',
    type: 'textarea',
    value: '',
    errorMessage: t('Please enter target description'),
    valid: false,
    required: true,
  },
]);

const thirdColumn = ref([
  {
    label: t('Date range'),
    id: 'date-range',
    type: 'select',
    value: 'YEARLY',
    options: TARGET_INTERVALS,
    errorMessage: t('Please select date range'),
    valid: true,
    required: true,
  },
  {
    label: t('Metric'),
    id: 'metric',
    type: 'select',
    value: 1,
    options: metricOptions.value,
    errorMessage: t('Please select metric'),
    valid: true,
    required: true,
  },
  {
    label: t('Measurement unit'),
    id: 'unit',
    type: 'select',
    value: 'local', // TEMPORARILY HARDCODED
    options: [{ text: 'Local', value: 'local' }], // TEMPORARILY HARDCODED
    errorMessage: t('Please select measurement unit'),
    valid: true,
    required: true,
  },
]);

const fields = ref([ ...firstColumn.value, ...secondColumn.value, ...thirdColumn.value ]);

watch(props.targetData, () => {
  const getObject = id => fields.value.find(field => field.id === id);
  if (props.targetData.name) {
    getObject('target-name').value = props.targetData.name;
    getObject('target-name').valid = true;
    getObject('target-description').value = props.targetData.description;
    getObject('target-description').valid = true;
    getObject('date-range').value = props.targetData.interval.interval;
    getObject('metric').value = props.targetData.measurementId;
  }
}, 
{ immediate: true });

watch(metricOptions, (newOptions) => {
  fields.value.find(field => field.id === 'metric').options = newOptions;
});

watch(() => props.isModalOpened, (opened) => {
  if (!opened) return;

  nextTick(() => {
    const inputElement = document.getElementById('target-name');
    inputElement?.focus();
  });
});

function validateField(item) {
  fields.value.forEach(field => {
    if (field.id === item.id) {
      field.value = item.value;
      field.valid = item.isValid;
    }
  });
}

function completeStage(skipSecondStage = false) {
  const getValue = id => fields.value.find(field => field.id === id).value;
  const targetData = {
    name: getValue('target-name'),
    description: getValue('target-description'),
    interval: getValue('date-range'),
    measurementId: getValue('metric'),
  };
  emit('completeStage', { targetData, newStage: skipSecondStage ? 3 : 1 });
}
</script>

<style lang="scss" scoped>
.form-grid {
  padding: clamp(var(--spacing-2), 2.5vw, var(--spacing-4));
  flex-grow: 1;
}

.form-actions {
  display: flex;
  gap: var(--spacing-2);
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: var(--spacing-4);
  padding: var(--spacing-2) 0 var(--spacing-2);
  background-color: var(--colour-panel-action);
}

:deep(.three-col-equal-height) {
  --gap-space: var(--spacing-3);
}
</style>
