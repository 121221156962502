<template>
  <CustomCard
    purpose="reversed"
    class="details-container"
  >
    <LoadingOverlay
      v-if="schemaLoading"
      style="border-radius: var(--border-radius-1)"
    />
    <h3>
      {{ currentSection.name }}
    </h3>

    <!-- This will always be visible when you are creating a new section. Hide this once the form has been created -->
    <div
      v-if="isCustomSection"
      class="create-section"
    >
      <div class="fields-wrapper">
        <Field
          id="title"
          :key="customSchemaName"
          type="text"
          :label="t('Title')"
          :error-message="t('Please enter a title for the custom section')"
          :max-length="40"
          required
          :value="customSchemaName"
          @keydown="handleKeydown"
          @keypress="handleKeypress"
          @keyup="handleKeyup"
          @valid="updateMeta"
        />
        <Field
          id="description"
          :key="customSchemaDescription"
          type="textarea"
          :label="t('Short description')"
          :max-length="250"
          :value="customSchemaDescription"
          @keydown="handleKeydown"
          @keypress="handleKeypress"
          @keyup="handleKeyup"
          @valid="updateMeta"
        />
      </div>
    </div>

    <!--
      Show this section once there is at least one field. At the moment, 
      it just checks if ther are more than the default two fields. Probably a better way to do this.
    -->
    <div>
      <div
        v-for="(item, index) in nonSystemFields"
        :key="item.id"
        role="button"
        tabindex="0"
        @drop.prevent="dragDrop(index)"
        @dragenter.prevent="dragEnter(index)"
        @dragover.prevent="dragOver(index)"
      >
        <SectionsDrop
          v-show="toIndex >= 0 && toIndex === index && index != fromIndex && index != fromIndex + 1"
          class="mb-2"
        >
          {{ dropAreaTitle }}
        </SectionsDrop>
        <div
          class="drag"
          role="button"
          tabindex="0"
          :draggable="item.editable && isCustomSection"
          @dragstart="dragStart($event, index)"
        >
          <CustomCard
            :key="`section-${item.id}`"
            purpose="alert"
            class="item"
          >
            <div class="header">
              <div class="title-area">
                <button
                  v-if="item.editable"
                  @click="() => {}"
                >
                  <IconBase
                    v-if="isCustomSection"
                    icon-name="move"
                    icon-color="var(--colour-utility-action)"
                    :height="24"
                    :width="24"
                  />
                </button>
                <h4>
                  {{ getFieldTitle(index, item) }}
                </h4>
              </div>

              <div class="controls">
                <Field
                  :id="item.name"
                  small
                  type="checkbox"
                  :label="t('Mandatory')"
                  :value="item.required"
                  :disabled="!canUpdateSchema"
                  @input="() => toggleRequired(index)"
                />

                <Field
                  v-if="props.isCustomSection"
                  :id="item.name"
                  small
                  type="checkbox"
                  :label="t('Enquiries')"
                  :value="!item.enquiry_excluded"
                  :disabled="!canUpdateSchema"
                  @input="() => toggleEnquiries(index)"
                />
                <CustomButton
                  small
                  purpose="text"
                  @click="showModal(true)"
                >
                  {{ t('Translate') }}
                </CustomButton>

                <IconButton
                  v-if="(item.deletable && canDeleteSchema) && isCustomSection"
                  icon-name="trash"
                  icon-color="var(--colour-utility-action)"
                  purpose="transparent"
                  :height="24"
                  :width="24"
                  @click="deleteField(item)"
                />
              </div>
            </div>
            <div :class="`details ${item.original_type.toLowerCase()}-form`">
              <Field
                v-bind="item"
                type="text"
                required
                :disabled="(!item.editable && !canUpdateSchema) || !isCustomSection"
                :max-length="90"
                :value="item.system_flag === 'default' ? item.name : item.value"
                @valid="validatedField"
              />

              <div>
                <ul v-if="['ADVANCEDLIST', 'LIST'].includes(item.original_type)">
                  <li
                    v-for="(option, idx) in item.list_options"
                    :key="option.id"
                  >
                    <Field
                      :id="option.id"
                      :label="t('Option') + ' ' + (idx + 1)"
                      :name="getFieldName(item, option)"
                      :value="option.value"
                      :disabled="!option.editable"
                      :max-length="250"
                      :icon="
                        option.deletable
                          ? {
                            name: 'trash',
                            size: 24,
                            align: 'right',
                            color: 'var(--colour-utility-action)',
                            func: () => deleteListOption(item, option),
                          }
                          : {}
                      "
                      required
                      @input="value => updateListItem(item, option, value)"
                    />

                    <span
                      v-if="item.format == 'ADVANCEDLIST'"
                      class="item-label"
                    >
                      {{ t('Score') }}
                    </span>
                    <Field
                      v-if="item.format == 'ADVANCEDLIST'"
                      :name="getStageProbabilityFieldName(item, option)"
                      :value="option.score"
                      @input="value => updateStageProbability(item, option, value)"
                    />
                  </li>
                </ul>

                <div
                  v-if="isCustomSection && canUpdateSchema"
                  class="list-add-btn"
                >
                  <IconButton
                    v-if="item.editable && (item.original_type === 'ADVANCEDLIST' || item.original_type === 'LIST')"
                    icon-name="plus"
                    icon-color="white"
                    icon-size="20"
                    label="Add option"
                    variant="round"
                    @click="addOptionToSelect(item, index)"
                  />
                </div>
              </div>
            </div>
          </CustomCard>
        </div>
      </div>
      <EditTranslationModal
        v-if="isModalVisible"
        @close-modal="showModal(false)"
      />
    </div>

    <div
      v-if="isCustomSection"
      role="button"
      tabindex="0"
      @dragenter.prevent="dragEnter(nonSystemFields.length)"
      @dragover.prevent="dragOver(nonSystemFields.length)"
      @drop.prevent="dragDrop(nonSystemFields.length)"
    >
      <SectionsDrop
        v-show="toIndex === nonSystemFields.length && fromIndex < toIndex - 1"
        class="mb-2"
      >
        {{ dropAreaTitle }}
      </SectionsDrop>

      <div class="button-underline" />
      <div
        v-if="canUpdateSchema"
        class="button-container"
      >
        <CustomDropdown
          v-bind="propsForMenu"
          close-on-selection
          :items="fieldTypes.data"
          display-field="name"
          @click="addField"
        />
      </div>
    </div>

    <ButtonGroup
      class="btn-group-bg"
    >
      <CustomButton
        v-if="canDeleteSchema"
        v-show="isCustomSection"
        purpose="reversed"
        @on-click="deleteSection"
      >
        {{ t('Delete') }}
      </CustomButton>
      <CustomButton
        v-if="canUpdateSchema"
        purpose="reversed"
        :disabled="schemaLoading || !updatesNotSaved"
        @on-click="saveChanges"
      >
        {{ schemaLoading ? t('Loading') : t('Update') }}
      </CustomButton>
    </ButtonGroup>
    
    <CustomModal
      v-if="addingField"
      :max-width="400"
      @close-modal="closeAddFieldModal"
    >
      <div class="add-field-content">
        <Field
          :value="newField.name"
          :label="t('Enter field label')"
          required
          @valid="updateFieldName"
        />
        <ButtonGroup>
          <CustomButton
            small
            purpose="action"
            :disabled="!allowFieldAdd || schemaLoading"
            @on-click="addFieldToSchema"
          >
            {{ schemaLoading ? t('Loading') : t('Add') }}
          </CustomButton>
        </ButtonGroup>
      </div>
    </CustomModal>
  </CustomCard>
</template>

<script setup>
import { inject, ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import usePermissions from '@/shared/composables/usePermissions';
import { t } from '@sales-i/utils';
import EditTranslationModal from '@/admin/components/Company/Languages/EditTranslationModal.vue';
import SectionsDrop from '@/admin/views/CRMSetup/SectionsDrop.vue';
import { CustomCard, LoadingOverlay, CustomModal, IconBase, IconButton, CustomButton, CustomDropdown } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import Field from '@/shared/components/Form/Field.vue';
import { GET_BY_ENTITY, SET_UPDATED_FLAG, DELETE_BY_ENTITY, SET_CONFIRMATION_MODAL, SET_ITEMS } from '@/shared/store/actionType';
import {
  ADD_CUSTOM_LIST_ITEM,
  UPDATE_CUSTOM_LIST_SCHEMA,
  SAVE_CUSTOM_SCHEMA,
  ADD_FIELD,
  MOVE_FIELD,
  DELETE_FIELD,
  DELETE_LIST_OPTION,
} from '@/admin/store/actionType';
import {
  FETCH_BUBBLE_DATA,
  FETCH_REPORT_DATA,
} from '@/intelligence/store/actionType';
import { crm_admin } from '@/shared/store/data/policies';

const store = useStore();
const { hasAccess } = usePermissions();

const updatesNotSaved = computed(() => store.state.admin.schema.updatesNotSaved);
const canUpdateSchema = computed(() => hasAccess(crm_admin, 'update'));
const canDeleteSchema = computed(() => hasAccess(crm_admin, 'delete'));

const { setSaveRef } = inject('saveRefProvider');

const props = defineProps({
  activeSection: {
    type: Number,
    default: 0,
  },
  isCustomSection: {
    type: Boolean,
    default: true,
  },
  entity: {
    type: String,
    default: 'customers',
  },
});

const emits = defineEmits(['keydown', 'keypress', 'keyup', 'subSectionClicked']);

const addingField = ref(false);
const allowFieldAdd = ref(false);
const fromIndex = ref(-1);
const isModalVisible = ref(false);
const newField = ref({
  name: '',
  type: 'text',
  index: 0,
});
const propsForMenu = ref({
  iconWidth: 20,
  iconHeight: 20,
  iconColor: 'var(--colour-utility-white)',
  toggleIcons: ['plus', 'close-line']
});
const toIndex = ref(-1);
const sectionTitle = ref({});
const sectionDescription = ref({});
const editedEnquiryFields = ref({});

const schemaSections = computed(() => store.getters['admin/schema/getSections']);

const fieldTypes = computed(() => store.state.admin.fieldTypes);
const schemaLoading = computed(() => store.state.admin.schema.loading_section);

const bubbleData = computed(() => store.state.intelligence.enquiry.bubbleData);

const currentSection = computed(() => getActiveSection() || {});
const customSchemaName = computed(() => currentSection.value.name);
const customSchemaDescription = computed(() => currentSection.value.description);
const nonSystemFields = computed(() => {
  let arr = [];
  let fields = get('fields');
  fields.forEach(field => {
    if (field.system_flag !== 'system' && !field.hidden) {
      arr.push(field);
    }
  });
  return arr;
});
const dropAreaTitle = computed(() => (fromIndex.value >= 0 ? getFieldTitle(fromIndex.value, nonSystemFields.value[fromIndex.value]) : ''));
const updateCustomListSchema = updates => store.dispatch(`admin/schema/${UPDATE_CUSTOM_LIST_SCHEMA}`, updates);
const saveCustomSchema = updates => store.dispatch(`admin/schema/${SAVE_CUSTOM_SCHEMA}`, updates);
const addSchemaField = params => store.dispatch(`admin/schema/${ADD_FIELD}`, params);
const addSelectOption = params => store.dispatch(`admin/schema/${ADD_CUSTOM_LIST_ITEM}`, params);
const moveField = params => store.dispatch(`admin/schema/${MOVE_FIELD}`, params);
const setUpdated = bool => store.dispatch(`admin/schema/${SET_UPDATED_FLAG}`, bool);
const getFieldTypes = () => store.dispatch(`admin/fieldTypes/${GET_BY_ENTITY}`);
const deleteSectionByEntity = params => store.dispatch(`admin/schema/${DELETE_BY_ENTITY}`, params);
const deleteSectionField = params => store.dispatch(`admin/schema/${DELETE_FIELD}`, params);
const deleteSectionListOption = params => store.dispatch(`admin/schema/${DELETE_LIST_OPTION}`, params);
const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);

onMounted(() => {
  getFieldTypes();
  setSaveRef(saveChanges);
  getBubbles();
});

const deleteTarget = payload => store.dispatch(`intelligence/targets/${FETCH_REPORT_DATA}`, payload);
const getBubbles = params => store.dispatch(`intelligence/enquiry/${FETCH_BUBBLE_DATA}`, params);

function getFieldTitle(index, item) {
  const fieldTypeTitles = {
    'ADVANCEDLIST': 'advanced list',
    'LIST': 'list',
    'CURRENCY': 'currency',
    'DATETIME': 'date time' 
  };

  const fieldTypeTitle = fieldTypeTitles[item.original_type] || item.type;
    
  return t(`Field ${index + 1} - ${fieldTypeTitle}`, 'field_cardinal_type', {
    interpolations: {
      cardinal: index + 1,
      type: fieldTypeTitle,
    },
  });
}

function getActiveSection() {
  let { defaultSections, customSections } = schemaSections.value;
  return props.isCustomSection ? customSections[props.activeSection] : defaultSections[props.activeSection];
}
function get(name) {
  const activeSection = getActiveSection();
  return activeSection ? activeSection[name] : name === 'stage' ? '' : [];
}
function toggleRequired(index) {
  const newItem = { ...nonSystemFields.value[index] };
  newItem.required = !newItem.required;
  nonSystemFields.value.splice(index, 1, newItem);
  setUpdated(true);
}

function fieldIsBubble(newItem) {
  let isBubble = false;
  let x = 0;

  for (x; x < bubbleData.value.length; x++) {
    const _ref = bubbleData.value[x];

    if (_ref.area.toLowerCase() === 'crm' && _ref.title === newItem.name) {
      isBubble = true;
    }
  }

  return isBubble;
}

function getBubbleForField(field) {
  let bubble = 0;
  let x = 0;

  for (x; x < bubbleData.value.length; x++) {
    const _ref = bubbleData.value[x];

    if (_ref.area.toLowerCase() === 'crm' && _ref.title === field.name) {
      bubble = _ref.id;
    }
  }

  return bubble;
}

function toggleEnquiries(index) {
  const newItem = { ...nonSystemFields.value[index] };

  if (!newItem.enquiry_excluded && fieldIsBubble(newItem)) {
    editedEnquiryFields.value[newItem.id] = newItem;
  } else {
    // Check if the field exists, and if it does pop it off
    delete editedEnquiryFields.value[newItem.id];
  }

  newItem.enquiry_excluded = !newItem.enquiry_excluded;
  nonSystemFields.value.splice(index, 1, newItem);
  setUpdated(true);
}
function showModal(show = true) {
  isModalVisible.value = show;
}
function handleKeydown(event) {
  emits('keydown', event);
}
function handleKeypress(event) {
  emits('keypress', event);
}
function handleKeyup(event) {
  emits('keyup', event);
}
function checkForUpdates() {
  let updated = false;

  nonSystemFields.value.forEach(field => {
    // Compare the fetched field value with the name value. If they are different, then the field has been updated
    if (field.value !== field.name) {
      updated = true;
    }
  });

  // Check if sectionTitle and sectionDescription have been updated
  if (sectionTitle.value) {
    updated = true;
  }
  if (sectionDescription.value) {
    updated = true;
  }

  return updated;
}

function updateMeta(field) {
  if (field.id === 'title') {
    sectionTitle.value = field;
  }
  if (field.id === 'description') {
    sectionDescription.value = field;
  }
  setUpdated(checkForUpdates());
}
function validatedField(field) {
  // Find the matching field in nonSystemFields
  let matchingField = nonSystemFields.value.find(item => item.id === field.id);

  if (matchingField) {
    matchingField.value = field.value;
    matchingField.valid = field.isValid;
  } else {
    // Find the matching option in list_options
    let matchingOption = nonSystemFields.value.find(item => item.list_options.find(option => option.id === field.id));

    if (matchingOption) {
      let updatedOption = matchingOption.list_options.find(option => option.id === field.id);

      if (updatedOption) {
        updatedOption.value = field.value;
        updatedOption.valid = field.isValid;
      }
    }
  }
  setUpdated(checkForUpdates());
}

function validateForm(showUpdates = true) {
  let isValid = true;
  let focused = false;
  let firstFieldWithError = null;

  if (!sectionTitle.value.isValid) {
    let fieldInDoc = document.querySelector(`#${sectionTitle.value.id}`);
    focused = true;
    firstFieldWithError = fieldInDoc;
    isValid = false;
  }

  nonSystemFields.value.forEach(field => {
    if (field.valid === false) {
      let fieldInDoc = document.querySelector(`#${field.id}`);
      if (fieldInDoc && showUpdates) {
        fieldInDoc.focus();
      }
      if (!focused) {
        focused = true;
        firstFieldWithError = fieldInDoc;
      }
      isValid = false;
    } else {
      isValid = true;
    }

    // Check list options inside the field
    if (field.list_options) {
      field.list_options.forEach(option => {
        if (option.value === '') {
          let fieldInDoc = document.querySelector(`#option-${option.id}`);
          if (fieldInDoc && showUpdates) {
            fieldInDoc.focus();
          }
          if (!focused) {
            focused = true;
            firstFieldWithError = fieldInDoc;
          }
          isValid = false;
        }
      });
    }
  });

  if (firstFieldWithError && showUpdates) {
    firstFieldWithError.focus();
  }
  return isValid;
}
function getFieldName(item, option) {
  return `list[${item.id}][${option.id}]`;
}
function getStageProbabilityFieldName(item, option) {
  return `probability[${item.id}][${option.id}]`;
}
async function saveChanges() {
  let isValid = updatesNotSaved.value && validateForm(true);

  if (isValid && Object.keys(editedEnquiryFields.value).length > 0) {
    // Get the list of deleted bubbles
    let n;
    let bubbles = [];
    for (n in editedEnquiryFields.value) {
      bubbles.push(getBubbleForField(editedEnquiryFields.value[n]));
    }

    // Warn the user their targets will be deleted
    let message = 'This update includes changes to enquiries enabled fields, removing these will delete any related targets. Are you sure?';
    let messageVariable = 'do_you_really_want_to_remove_enquiries_fields';

    showConfirmationModal({
      message: t(message, messageVariable),
      updatedMessage: t('Targets deleted successfully.', 'targets_deleted_successfully'),
      updateFunction: async () => {
        try {
          let x = 0;
          for (x; x < bubbles.length; x++) {
            if (bubbles[x] !== 0) {
              await deleteTargetsByBubbleIdHandler(bubbles[x]);
            }
          }
          await updateCustomSchema();
          return true;
        } catch (e) {
          console.error(e);
          return false;
        }
      },
    });
  } else if (isValid) {
    await updateCustomSchema();
  }
}

async function deleteTargetsByBubbleIdHandler(id) {
  try {
    const result = await deleteTarget({
      type: 'deleteTargetsByBubbleId',
      params: {
        bubbleId: id,
      }
    });
    return result;
  } catch (error) {
    console.error('Failed to delete targets:', error);
    throw error;
  }
}

async function updateCustomSchema() {
  try {
    await saveCustomSchema({
      entity: props.entity,
      name: sectionTitle.value?.value ?? customSchemaName.value,
      description: sectionDescription.value?.value ?? customSchemaDescription.value,
      section_id: currentSection.value.id,
      updatedFields: nonSystemFields.value,
    });

    // Check if the alert queue is empty before dispatching a new alert
    const alertQueue = store.state.alerts.data;
    if (alertQueue.length === 0) {
      showSuccessOnUpdate();
    }
  } catch (error) {
    store.dispatch(`alerts/${SET_ITEMS}`, {
      type: 'error',
      message: t('Error updating custom section'),
      selfDismiss: true,
    });
  }
}

function closeAddFieldModal() {
  addingField.value = false;
}
async function deleteSection() {
  let sectTitle = customSchemaName.value;
  showConfirmationModal({
    message: t(`Do you really want to delete ${sectTitle}?`, 'do_you_really_want_to_delete_variable', { interpolations: { variable: sectTitle } }),
    updatedMessage: t(`You have deleted ${sectTitle}`, 'you_have_deleted_variable', {
      interpolations: { variable: sectTitle },
    }),
    updateFunction: async () => {
      try {
        await deleteSectionByEntity({
          entity: props.entity,
          section_id: currentSection.value.id,
        });
        emits('subSectionClicked', 0);
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  });
}
function updateFieldName(field) {
  newField.value.name = field.value;
  allowFieldAdd.value = field.isValid;
}

function updateListItem(item, option, value) {
  updateCustomListSchema({
    item,
    option,
    value,
    param: 'value',
    section: props.activeSection,
  });
}
function updateStageProbability(item, option, value) {
  updateCustomListSchema({
    item,
    option,
    value: parseInt(value),
    param: 'score',
    section: props.activeSection,
  });
}
function addField(type, index) {
  newField.value.type = type.name.toUpperCase();
  newField.value.index = index;
  addingField.value = true;
}
function showSuccessOnUpdate() {
  store.dispatch(`alerts/${SET_ITEMS}`, {
    type: 'success',
    message: t('Successfully updated custom section'),
    selfDismiss: true,
  });
}
async function addOptionToSelect(item, index) {
  let { customSections } = schemaSections.value;
  const section = customSections[index];
  // Set updatesNotSaved to true
  setUpdated(true);
  await addSelectOption({section, item, index});
  saveChanges();
}

function resetNewField() {
  newField.value = {
    type: '',
    name: '',
  };
  addingField.value = false;
}
async function addFieldToSchema() {
  await addSchemaField({
    ...newField.value,
    section_id: currentSection.value.id,
    entity: props.entity,
  });
  resetNewField();
  closeAddFieldModal();
}
async function deleteListOption(item, option) {
  // get the 'name' of the selected list_option
  // let fieldTitle = option.list_options.find(item => item.id === option.id).value;
  showConfirmationModal({
    message: t(`Do you really want to delete ${option.value}`, 'do_you_really_want_to_delete_variable', {
      interpolations: {
        variable: option.value
      }
    }),
    updatedMessage: t(`You have deleted ${option.value}`, 'you_have_deleted_variable', {
      interpolations: {
        variable: option.value
      },
    }),
    updateFunction: async () => {
      try {
        await deleteSectionListOption({
          entity: props.entity,
          list_name: item.name,
          option_id: option.id,
        });
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  });
}
async function deleteField(item) {
  let fieldTitle = item.value;

  let message = `Do you really want to delete ${fieldTitle}`;
  let messageVariable = 'do_you_really_want_to_delete_variable';

  if (item.enquiry_excluded === false) {
    message = `Do you really want to delete ${fieldTitle}. Deleting this field, will delete any targets created using this field.`;
    messageVariable = 'do_you_really_want_to_delete_variable_enquiry_included';
  }

  showConfirmationModal({
    message: t(message, messageVariable, {
      interpolations: {
        variable: fieldTitle
      }
    }),
    updatedMessage: t(`You have deleted ${fieldTitle}`, 'you_have_deleted_variable', {
      interpolations: {
        variable: fieldTitle
      },
    }),
    updateFunction: async () => {
      try {
        await deleteSectionField({
          section_id: currentSection.value.id,
          entity: props.entity,
          item: item
        });
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  });
}
function clear() {
  fromIndex.value = -1;
  toIndex.value = -1;
}
/** @todo this event is not firing for some reason - research is needed */
function dragStart(ev, index) {
  clear();
  ev.dataTransfer.dropEffect = 'move';
  ev.dataTransfer.effectAllowed = 'move';
  ev.dataTransfer.setData('index', index);
  fromIndex.value = index;
}
function dragDrop(goToIndex) {
  moveField({
    entity: props.entity,
    section_id: currentSection.value.id,
    from: fromIndex.value,
    to: goToIndex,
  });
  clear();
}
function dragEnter(index) {
  if (fromIndex.value == index) return;
  toIndex.value = index;
}
function dragOver(index) {
  toIndex.value = index;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
// Over-rides
.list-add-btn,
.button-container {
  .menu-dropdown {
    min-width: initial;
  }
}

.fields-wrapper {
  width: 50%;
}

.details-container {
  position: relative;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  box-shadow: none;

  h3 {
    margin-top: 0;
  }
}

.item {
  padding: 0;
  margin-bottom: var(--spacing-2);
}


.details {
  background-color: var(--colour-utility-white);
  border-bottom-left-radius: var(--border-radius-1);
  border-bottom-right-radius: var(--border-radius-1);
  margin-block-start: 0;
  padding: var(--spacing-3);

  .list-add-btn {
    display: flex;
    justify-content: flex-end;
    
    .menu-dropdown {
      min-width: initial;
    }
  }

  > div,
  ul {
    flex: 1;
    padding-top: 0;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.advancedlist-form,
.list-form {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    display: flex;
    gap: var(--spacing-2);
  }
}

.details ul {
  list-style-type: none;
}

.button-underline {
  position: relative;
  top: var(--spacing-2);
  height: 2px;
  background-color: var(--colour-panel-g-8);
}
.button-container {
  position: relative;
  margin-right: var(--spacing-3);
  text-align: right;
}

.header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: var(--spacing-1);
  justify-content: space-between;
  padding: var(--spacing-2);

  .grow {
    flex-grow: 1;
  }
}

.controls {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--spacing-2);
}

.controls .checkbox {
  font-size: var(--font-size-small);
  margin-bottom: 0;
}


:deep(.list-add-btn .menu-dropdown .list) {
  background-color: var(--colour-utility-black);
  right: -8px;
  bottom: -8px;
  top: inherit;
  z-index: 2;

  ul {
    width: auto;
  }

  ul li {
    margin: 0;
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    max-height: inherit !important; // The only way to override the inline styles created by the <MenuDropdown> component.
  }
}

:deep(.button-container .menu-dropdown .list) {
  background-color: var(--colour-utility-black);
  right: -8px;
  bottom: -8px;
  top: inherit;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    max-height: inherit !important; // The only way to override the inline styles created by the <MenuDropdown> component.
  }
}

.field-selection {
  padding: var(--spacing-half) var(--spacing-3) var(--spacing-4);
  text-align: left;
}

.field-type {
  color: var(--colour-utility-white);
  font-size: var(--font-size-small);
  min-height: var(--spacing-6);
  text-decoration: underline;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    min-height: initial;
  }
}

.add-field-content {
  padding-left: var(--spacing-1);
  padding-right: var(--spacing-1);

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding-left: var(--spacing-2);
    padding-right: var(--spacing-2);
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-left: var(--spacing-3);
    padding-right: var(--spacing-3);
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    padding-left: var(--spacing-4);
    padding-right: var(--spacing-4);
  }

  @media #{map-get($display-breakpoints, 'xl-only')} {
    padding-left: var(--spacing-5);
    padding-right: var(--spacing-5);
  }
}


// Over-rides
:deep(.menu-dropdown.left .list) {
  right: calc(var(--spacing-1) * -1);
}

:deep(.menu-dropdown) {
  z-index: 1;
}

:deep(.list) {

  ul {
    padding-block-end: var(--spacing-4);
    padding-block-start: var(--spacing-1);
  }

  .list-item:hover {
    background-color: var(--colour-panel-g-72);
  }

  .list-item__title {
    color: var(--colour-utility-white);
    font-size: var(--font-size-small);
    min-height: var(--spacing-6);
    padding-block: var(--spacing-1);
    padding-inline: var(--spacing-3);
    text-decoration: underline;

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      min-height: initial;
    }
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    max-height: inherit !important; // The only way to override the inline styles created by the <MenuDropdown> component.
  }

}

:deep(.menu-dropdown-button) {
  position: relative;
  z-index: 999;
}

:deep(.btn-group-bg) {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-left: var(--spacing-1);
    padding-right: var(--spacing-1);
  }
}
</style>

<style lang="scss">
.title-area {
  display: flex;
  gap: var(--spacing-1);
}
.btn-group-bg {
  background-color: var(--colour-panel-action);
  padding-top: var(--spacing-3);
  padding-bottom: var(--spacing-3);
}
</style>