import {
  SET_DOWNLOADING,
  DOWNLOAD_BY_ID,
  DOWNLOAD_BY_ID_ERROR
} from '@/crm/store/actionType';
import { httpFileRequest, pluralise } from '@sales-i/utils';
import { saveAs } from 'file-saver';

// mutations
export const downloadByIdMutations = {
  [SET_DOWNLOADING]: (state, downloading) => {
    state.selected.loaded = false;
    state.selected.loading = false;
    state.selected.downloading = downloading;
  },
  [DOWNLOAD_BY_ID_ERROR]: (state, errors) => {
    state.selected.loaded = false;
    state.selected.loading = false;
    state.selected.downloading = false;
    state.selected.errors = errors;
  }
};

// actions
export const downloadByIdActions = {
  [DOWNLOAD_BY_ID]: async ({ commit, }, parameters) => {
    try {
      let { file, options } = parameters;

      commit(SET_DOWNLOADING, true);
      let response = await httpFileRequest(`${pluralise.string(file.entity_type)}/${file.entity_id}/${file.id}/download`, options);
      const blob = new Blob(
        [response.data], 
        { type: file.mime_type }
      );
      saveAs(blob, file.file_name);
      commit(SET_DOWNLOADING, false);
      return response;
    } catch (error) {
      console.error(error);
      commit(DOWNLOAD_BY_ID_ERROR, error);
      return false;
    }
  },
};

