<template>
  <div :class="`card-list schema-links ${mq.current}`">
    <div class="container p-0 flow">
      <h3 class="text-3 fw-sbld">
        {{ t('Discover more') }}
      </h3>
      <div
        v-for="(item, index) in items"
        :key="index"
      >
        <a
          v-if="item.to"
          :href="item.to.path"
          @click.prevent="navigateToUrl(item.to.path)"
        >
          <CustomCard
            purpose="reversed"
            class="p-2"
          >
            <h4 class="fw-sbld">
              {{ t(item.title) }}
            </h4>
            <p class="mt-0 text-sm">
              {{ t(item.description) }}
            </p>
          </CustomCard>
        </a>
        <CustomCard
          v-else
          purpose="reversed"
          class="p-2"
          :on-click="item.func"
        >
          <h4 class="fw-sbld">
            {{ t(item.title) }}
          </h4>
          <p class="mt-0 text-sm">
            {{ t(item.description) }}
          </p>
        </CustomCard>
      </div>
    </div>
  </div>
</template>

<script setup>
import { CustomCard } from '@sales-i/dsv3';
import { useMq } from 'vue3-mq';
import { t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

const mq = useMq();

defineProps({
  items: {
    type: Array,
    default: () => [],
  },
});
</script>

<style lang="scss" scoped>
.schema-links {
  padding: var(--spacing-5);
}
.schema-links {
  &.xl,
  &.lg,
  &.md {
    padding: var(--spacing-4);
  }
}
</style>
