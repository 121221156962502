export default function modifyUrl(urlString, baseUrl, params) {
  // Step 1: Create a URL object from the URL string
  const url = new URL(urlString, baseUrl);

  // Step 2: Modify the query parameters
  const searchParams = new URLSearchParams(url.search);
  Object.entries(params).forEach(([key, value]) => {
    if (value === null) {
      // Remove the query parameter if the value is null
      searchParams.delete(key);
    } else {
      // Set the new value for the query parameter
      searchParams.set(key, value);
    }
  });

  // Replace the existing searchParams in the URL
  url.search = searchParams.toString();

  // Step 3: Reconstruct the URL into a string
  return url;
}