import IntelligenceBaseView from '@/intelligence/views/BaseViews/Intelligence.vue';
import EnquiriesBaseView from '@/intelligence/views/BaseViews/Enquiries.vue';
import { baseUrl, enquiryScope } from '@/intelligence/router/urlBits';

export default [
  {
    path: `${baseUrl}`,
    component: IntelligenceBaseView,
  },
  {
    path: `${baseUrl}/${enquiryScope}`,
    component: EnquiriesBaseView,
  },
];
