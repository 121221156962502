import {
  GET_ADDRESSES,
  GET_ADDRESSES_LOADING,
  GET_ADDRESSES_ERROR,
  CLEAR_ADDRESSES
} from '@/crm/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const getAddressesMutations = {
  [GET_ADDRESSES]: (state, data) => {
    state.addresses.data = data;
    state.addresses.metadata = data.metadata;
    state.addresses.loaded = true;
    state.addresses.loading = false;
  },
  [GET_ADDRESSES_LOADING]: (state) => {
    state.addresses.loaded = false;
    state.addresses.loading = true;
  },
  [GET_ADDRESSES_ERROR]: (state) => {
    state.addresses.loaded = false;
    state.addresses.loading = false;
    state.addresses.errors = [];
  },
  [CLEAR_ADDRESSES]: (state) => {
    state.addresses.data = [];
    state.addresses.loaded = false;
    state.addresses.loading = false;
    state.addresses.errors = [];
  },
};

// actions
export const getAddressesActions = {
  [GET_ADDRESSES]: async ({ commit, }, ids) => {
    try {
      if (!ids || !Array.isArray(ids) || !ids.length) return [];
      commit(GET_ADDRESSES_LOADING);
      const response = await httpRequest('post', 'customers/address-coordinates', ids, {version: '2',});
      const data = response;
      commit(GET_ADDRESSES, data);
    } catch (error) {
      commit(GET_ADDRESSES_ERROR);
    }
  },
  [CLEAR_ADDRESSES]: ({ commit, }) => {
    commit(CLEAR_ADDRESSES);
  },
};
