<template>
  <CustomModal
    v-if="modalToDisplay"
    :title="t('Error')"
    :max-width="400"
    @close-modal="popModal"
  >
    <div>
      <div class="confirm-message-wrapper text-centre">
        <h1 class="fw-sbld text-2">
          {{ parseHtmlEntities(modalToDisplay.title) }}
        </h1>
        <p>{{ parseHtmlEntities(modalToDisplay.message) }}</p>
      </div>
    </div>

    <template #footer>
      <div class="buttons-container">
        <CustomButton
          label="OK"
          small
          class="ok"
          @click="popModal"
        />
      </div>
    </template>
  </CustomModal>
</template>

<script>
import { CustomModal, CustomButton } from '@sales-i/dsv3';
import { parseHtmlEntities } from '@/shared/utils/strings';
import { popMessageFromStack, t } from '@sales-i/utils';

export default {
  name: 'ModalQueue',
  components: {
    CustomModal,
    CustomButton,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['popModal'],
  computed: {
    modalToDisplay() {
      if (this.items.length > 0) {
        return this.items[0];
      }
      return false;
    },
  },
  methods: {
    t,
    parseHtmlEntities,
    popModal() {
      if (this.modalToDisplay.closeFunction) {
        this.modalToDisplay.closeFunction();
      }
      this.$emit('popModal');
      popMessageFromStack();
    },
  },
};
</script>

<style lang="scss">
.confirm-message-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-3) var(--spacing-4);
}

.buttons-container {
  display: flex;
  gap: var(--spacing-2);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
</style>
