import { ADD_STAGE, ADD_SECTION, GET_CUSTOM_SCHEMA, } from '@/admin/store/actionType';
import { httpRequest } from '@sales-i/utils';
import getEntityStr from '@/admin/store/utils/getEntityStr';

export const postByEntityGetters = {
  greatestSectionOrderPos: (state) => {
    let { sections } = state.schema;
    let currentGreatestOrderPos = 0;
    sections.forEach(section => {
      if (section.order_pos > currentGreatestOrderPos) {
        currentGreatestOrderPos = section.order_pos;
      }
    });
    return currentGreatestOrderPos + 1;
  }
};
export const postByEntityMutations = {
  // eslint-disable-next-line
  [ADD_STAGE](state, orderPos) {
    // const { schema } = state;
    //
    // // Find the field we want and update it
    // const newSection = {
    //   deletable: true,
    //   display_name: 'Untitled',
    //   editable: true,
    //   system_flag: 'custom',
    //   fields: [
    //     {
    //       deletable: false,
    //       display_name: 'Text',
    //       editable: true,
    //       enquiry_excluded: true,
    //       format: 'TEXT',
    //       name: 'Title',
    //       order_pos: 1,
    //       required: true,
    //       size: 36,
    //       type: 'TEXT',
    //     },
    //     {
    //       deletable: false,
    //       display_name: 'Textarea',
    //       editable: true,
    //       enquiry_excluded: true,
    //       format: 'TEXT',
    //       name: 'Short description',
    //       order_pos: 2,
    //       required: true,
    //       size: 255,
    //       type: 'TEXTAREA',
    //     }
    //   ],
    //   name: 'Untitled',
    //   order_pos: orderPos,
    // };
    //
    // schema.sections.push(newSection);
    //
    // state.schema = schema;
  },
  // eslint-disable-next-line
  [ADD_SECTION](state, orderPos) {
  }
};

export const postByEntityActions = {
  [ADD_STAGE]: async ({ commit, getters, }) => {
    let greatestSectionOrderPos = getters.greatestSectionOrderPos;
    await httpRequest('post', 'config/schema/opportunities', {
      entity: 'opportunity',
      locale: 'en_GB',
      sections: [
        {
          name: 'Opp Custom Sect 1',
          description: 'Opp Custom Sect 1',
          order_pos: greatestSectionOrderPos,
          editable: true,
          deletable: true,
          system_flag: 'custom',
          fields: []
        }
      ]
    }, {
      version: '2'
    });
    commit(ADD_STAGE, greatestSectionOrderPos);
  },
  [ADD_SECTION]: async ({ commit, getters, dispatch }, { entity = 'prospects' }) => {
    let greatestSectionOrderPos = getters.greatestSectionOrderPos;
    let entityStr = getEntityStr(entity);
    await httpRequest('patch', `config/schema/${entity}`, {
      entity: entityStr,
      locale: 'en_GB',
      sections: [
        {
          name: `Custom Sect ${greatestSectionOrderPos}`,
          description: `Custom Sect ${greatestSectionOrderPos}`,
          order_pos: greatestSectionOrderPos,
          editable: true,
          deletable: true,
          system_flag: 'custom',
          fields: []
        }
      ]
    }, {
      version: '2'
    });
    commit(ADD_SECTION, greatestSectionOrderPos);
    dispatch(GET_CUSTOM_SCHEMA, { entity, fetchOnUpdate: true, refreshCall: true, });
  },
};
