import TrendsReportView from '@/intelligence/views/Trends/TrendsReportView.vue';
import { baseUrl, enquiryScope } from '@/intelligence/router/urlBits';
import { TRENDS } from '@/intelligence/store/data/reportTypes';

export default [
  {
    path: `${baseUrl}/${enquiryScope}/${TRENDS}`,
    component: TrendsReportView,
    name: TRENDS,
  }
];
