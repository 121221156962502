<template>
  <CustomCard purpose="reversed">
    <template #header>
      <h2 class="fw-sbld">
        {{ t('Email') }}
      </h2>
    </template>
    <div class="nylas-explanation flex">
      <div class="text-container">
        <p>{{ t('Save precious time, send and receive emails in sales-i with contacts in a customer or prospect account.') }}</p>
        <p>{{ t('By connecting your email provider you will only see the emails between you and the contact’s email address. Other users, even admins will not be able to see those emails.') }}</p>
        <p>
          <strong>{{ t('Note:') }}</strong> {{ t('Shared Mailboxes are not currently supported.') }}
        </p>
      </div>
      <div class="auth-buttons">
        <ButtonGroup class="btn-group-bg flex">
          <span class="email-value text-4">{{ authData.email_address }}</span>
          <CustomButton
            v-if="showConnectAccountButton"
            class="connect-button"
            small
            purpose="action"
            :disabled="urlLoading"
            @on-click="goToMailSync"
          >
            <template v-if="urlLoading">
              <BufferImage :size="24" />
            </template>
            <template v-else>
              {{ t('Connect account') }}
            </template>
          </CustomButton>
          <CustomButton
            v-else
            :class="{ 'action-button' : !urlLoading}"
            small
            purpose="action"
            :disabled="urlLoading"
            :icon-color="!urlLoading ? 'var(--colour-utility-error)' : 'var(--colour-utility-success)'"
            @on-click="disconnectAccount"
          >
            <template v-if="urlLoading">
              <BufferImage :size="24" />
            </template>
            <template v-else>
              {{ t('Disconnect') }}
            </template>
          </CustomButton>
          <span
            v-if="urlError"
            class="try-again-error"
          >
            {{ t('Please try again') }}
          </span>
        </ButtonGroup>
      </div>
      <p v-if="isInvalid">
        {{ t('You have been disconnected, and need to re-authenticate. Please do so below.') }}
      </p>
    </div>
    <hr>
    <CalendarSync
      :auth-data="authData"
      :email-status="authData.status"
      @calendar-updated="handleCalendarUpdated"
    />
  </CustomCard>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import { BufferImage, CustomCard, CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { GET_AUTH_URL, POST_AUTH_CODE, DELETE_AUTH_CODE, GET_CALENDAR } from '@/admin/store/actionType';
import { mailState, getAccountInfo, t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';
import { useStore } from 'vuex';
import { companyArea, emailSyncSection, root } from '@/admin/router/urlBits';
import CalendarSync from './CalendarSync.vue';

const store = useStore();
const vroute = useRoute();

const authData = ref({});

const urlLoading = computed(() => store.state.admin.email.url.loading);
const urlError = computed(() => store.state.admin.email.url.error);
const isInvalid = computed(() => authData.value.status === 'INVALID');
const showConnectAccountButton = computed(() => !authData.value.status || isInvalid.value);

const rxm = mailState.subscribe(newMailState => {
  authData.value = newMailState.details;
});

const getAuthUrl = () => store.dispatch(`admin/email/${GET_AUTH_URL}`);
const postAuthCode = params => store.dispatch(`admin/email/${POST_AUTH_CODE}`, params);
const deleteAuthCode = () => store.dispatch(`admin/email/${DELETE_AUTH_CODE}`);
const getCalendar = () => store.dispatch(`admin/email/${GET_CALENDAR}`);

const goToMailSync = async () => {
  if (isInvalid.value) await deleteAuthCode();
  const url = await getAuthUrl();
  if (url) navigateToUrl(url);
};

const disconnectAccount = async () => {
  await deleteAuthCode();
};

const handleCalendarUpdated = async () => {
  await getAccountInfo();
  await getCalendar();
};

onMounted(async () => {
  const { state, code } = vroute.query;
  if (state && code) {
    await postAuthCode({ code, state });
    // once state and code are used, they are removed from the URL history
    history.replaceState({}, '', `/${root}/${companyArea}/${emailSyncSection}`);
  }
  await getAccountInfo();
});

onUnmounted(async () => {
  rxm.unsubscribe();
});
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.nylas-explanation {
  margin-top: var(--spacing-2);
  flex-direction: column;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    flex-direction: row;
  }

  .btn-group-bg {
    padding: var(--spacing-3);
    width: auto;
    border-radius: var(--border-radius-1);

    .connect-button :deep(div) {
      text-wrap: nowrap;
    }

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      flex-wrap: nowrap;
      justify-content: space-evenly;
    }
  }

  .auth-buttons {
    width: 100%;
    align-items: baseline;
    margin: 0 auto;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: 40%;
    }

    .email-value {
      background-color: var(--colour-panel-g-16);
      width: 100%;
      text-align: center;
      padding: var(--spacing-1) 0;
      border-radius: var(--border-radius-half);
      min-height: var(--spacing-5);

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        width: initial;
        min-width: 50%;
      }
    }
  }

  .text-container {
    width: 100%;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: 50%;
    }

    p {
      margin-bottom: var(--spacing-2);
      font-size: var(--font-size-4);
    }
  }
}

.try-again-error {
  color: var(--colour-utility-error);
}

.button.action-button {
  background-color: var(--colour-brand-mandy);
}
</style>
