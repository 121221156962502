<template>
  <div class="roles-form-container">
    <Accordion
      v-for="(section) of featureList"
      :key="section.title"
      ref="accordion"
      :title="section.title"
      accordion-size="big"
    >
      <template #header>
        <CustomCheckbox
          :id="section.title"
          :label="t('Active')"
          class="section-title-checkbox"
          :value="getSectionValue(section.title)"
          @input="setAllForSection($event, section.title)"
        />
      </template>
      <template #content>
        <div class="feature-list">
          <div
            v-for="feature in section.features"
            :key="feature.name"
          >
            <CustomCheckbox
              :id="feature.name"
              class="feature-list-title"
              :label="feature.name"
              :value="getFeatureValue(section.title, feature.name)"
              @input="setAllForFeature($event, section.title, feature.name)"
            />
            <div class="feature-checkbox-group">
              <CustomCheckbox
                :id="`${feature.name}-create`"
                :label="t('Create')"
                :value="feature.create"
                @input="setSingularPermission($event, section.title, feature.name, 'create')"
              />
              <CustomCheckbox
                :id="`${feature.name}-read`"
                :label="t('Read')"
                :value="feature.read"
                @input="setSingularPermission($event, section.title, feature.name, 'read')"
              />
              <CustomCheckbox
                :id="`${feature.name}-update`"
                :label="t('Update')"
                :value="feature.update"
                @input="setSingularPermission($event, section.title, feature.name, 'update')"
              />
              <CustomCheckbox
                :id="`${feature.name}-delete`"
                :label="t('Delete')"
                :value="feature.delete"
                @input="setSingularPermission($event, section.title, feature.name, 'delete')"
              />
            </div>
          </div>
        </div>
      </template>
    </Accordion>
  </div>
  <ButtonGroup class="form-actions">
    <template v-if="true">
      <CustomButton
        v-for="action in actions"
        :key="action.label"
        :label="action.label"
        :disabled="action.disabled"
        :purpose="action.isSubmit ? 'action' : action.purpose || 'action'"
        :small="action.small || false"
        :class="`action ${valid ? '' : 'disabled'}`"
        :type="action.isSubmit ? 'submit' : 'button'"
        @on-click="
          event => {
            action.onClick(event);
          }
        "
      />
    </template>
    <template v-if="false">
      <BufferImage :size="40" />
    </template>
  </ButtonGroup>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import { BufferImage, CustomButton, CustomCheckbox } from '@sales-i/dsv3';
import Accordion from '@/shared/components/Accordion/Accordion';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { GET_FEATURES, SET_USER_ROLES } from '@/admin/store/actionType';
import { POST_BY_ENTITY, PUT_BY_ID } from '@/shared/store/actionType';
import { baseUrl, companyArea, permissionsSection, usersSection } from '@/admin/router/urlBits';
import { navigateToUrl } from 'single-spa';

const emit = defineEmits(['stageUpdate']);

const store = useStore();
const vroute = useRoute();

const pbacFeatures = computed(() => store.state.admin.pbac.features.data);
const selectedRoleFeatures = computed(() => store.state.admin.pbac.currentlyEditing.features);

const setUserRoles = userRoles => store.dispatch(`admin/users/${SET_USER_ROLES}`, userRoles);
const addUser = () => store.dispatch(`admin/users/${POST_BY_ENTITY}`);
const editUser = params => store.dispatch(`admin/users/${PUT_BY_ID}`, params);
const getFeatures = () => store.dispatch(`admin/pbac/${GET_FEATURES}`);

const roles = ref({});
const valid = ref(false);

const featureList = ref([]);

watch(() => ({
  features: pbacFeatures.value,
  current: selectedRoleFeatures.value,
}), (to) => {
  setupFeatureList(to.features);
});

const actions = ref([
  {
    label: t('Complete'),
    purpose: 'reversed',
    small: true,
    onClick: () => {
      if (valid.value) {
        setUserRoles(Object.keys(roles.value));
        if (vroute.params.addedit === 'edit' && vroute.params.id) {
          editUser({ id: vroute.params.id });
        } else {
          addUser();
        }
        navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}`);
      }
    },
    display: true,
  },
  {
    label: t('Add data restrictions'),
    small: true,
    onClick: () => {
      if (valid.value) {
        setUserRoles(Object.keys(roles.value));
        navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}/data-restrictions`);
      }
    },
    display: true,
    isSubmit: true,
  },
]);

onMounted(() => {
  emit('stageUpdate', 'Feature restrictions');
  getFeatures();
  setupFeatureList(pbacFeatures.value);
});

const setupFeatureList = (features) => {
  let sectionTitles = [];
  let sections = [];
  features.forEach(feat => {
    let sectionTitle = feat.resource.split('_')[0];
    if (!sectionTitles.includes(sectionTitle)) {
      sectionTitles.push(sectionTitle);
      sections.push({
        title: sectionTitle,
        features: []
      });
    }
    let sectionIndex = sections.findIndex(s => s.title === sectionTitle);
    sections[sectionIndex].features.push({
      name: feat.resource,
      create: false,
      read: false,
      update: false,
      delete: false,
    });
  });
  featureList.value = sections;
};

const getSectionValue = (sectionTitle) => {
  let hasSomePermission = false;
  let sectionIndex = featureList.value.findIndex(s => s.title === sectionTitle);
  featureList.value[sectionIndex].features.forEach(feature => {
    ['create', 'read', 'update', 'delete'].forEach(permission => {
      if (feature[permission]) {
        hasSomePermission = true;
      }
    });
  });
  return hasSomePermission;
};

const getFeatureValue = (sectionTitle, featureName) => {
  let hasSomePermission = false;
  let sectionIndex = featureList.value.findIndex(s => s.title === sectionTitle);
  featureList.value[sectionIndex].features.forEach(feature => {
    if (feature.name === featureName) {
      ['create', 'read', 'update', 'delete'].forEach(permission => {
        if (feature[permission]) {
          hasSomePermission = true;
        }
      });
    }
  });
  return hasSomePermission;
};

const setAllForSection = (val, sectionTitle) => {
  let sectionIndex = featureList.value.findIndex(s => s.title === sectionTitle);
  featureList.value[sectionIndex].features.forEach(feature => {
    feature.create = val;
    feature.read = val;
    feature.update = val;
    feature.delete = val;
  });
};
const setAllForFeature = (val, sectionTitle, featureName) => {
  let sectionIndex = featureList.value.findIndex(s => s.title === sectionTitle);
  featureList.value[sectionIndex].features.forEach(feature => {
    if (feature.name === featureName) {
      feature.create = val;
      feature.read = val;
      feature.update = val;
      feature.delete = val;
    }
  });
};
const setSingularPermission = (val, sectionTitle, featureName, permission) => {
  let sectionIndex = featureList.value.findIndex(s => s.title === sectionTitle);
  featureList.value[sectionIndex].features.forEach(feature => {
    if (feature.name === featureName) {
      feature[permission] = val;
    }
  });
};
</script>

<style lang="scss" scoped>
.section-title-checkbox {
  margin-left: auto;
}

.feature-list {
  padding: var(--spacing-2);
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-4);
}

.feature-list-title {
  font-size: var(--font-size-4);
}

.feature-checkbox-group {
  padding-top: var(--spacing-1);
  padding-left: var(--spacing-2);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
}
.form-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: var(--spacing-2);
  background-color: var(--colour-panel-action);
  @media (min-height: 500px) {
    &.sticky-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      margin-left: 0;
      width: 100%;
    }
  }
}
</style>
