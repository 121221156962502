<template>
  <div
    class="lh-navigation"
    :class="mq"
  >
    <CustomCard
      v-if="!itemsLoading"
      purpose="action"
      class="section-container"
    >
      <CustomCard
        v-for="(item, index) in items"
        :key="`${item.id}-${item.name}`"
        purpose="reversed"
        :class="{
          section: true,
          active: activeSection == index,
        }"
        :on-click="() => itemClicked(item, index)"
      >
        <div class="section-title">
          <h3>{{ item.name }}</h3>
        </div>
        <div
          v-if="activeSection === index && item.sections"
          class="custom-sections"
        >
          <DraggableItems
            :items="item.sections"
            :active-item="subSection"
            :loading="loading"
            :is-custom-section="item.isCustomSection"
            :can-create-entities="item.canCreateEntities"
            :add-type="addType"
            @move="move"
            @item-clicked="setSubSection"
            @create-entity="createEntity"
          />
        </div>
      </CustomCard>
    </CustomCard>
    <BufferImage
      v-else
      class="buffer my-4"
      color="var(--colour-utility-black)"
      float="center"
    />
    <slot v-if="!loading && !itemsLoading" />
  </div>
</template>

<script type="text/javascript">
import { CustomCard, BufferImage } from '@sales-i/dsv3';
import DraggableItems from './DraggableItems.vue';

export default {
  name: 'LeftHandNavigation',
  components: {
    CustomCard,
    DraggableItems,
    BufferImage,
  },
  inject: ['mq'],
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    itemsLoading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    activeSection: {
      // Int to determine the section index
      type: Number,
      default: 0,
    },
    subSection: {
      // Int to determine the sub section where applicable
      type: Number,
      default: 0,
    },
    allowClickOnActive: {
      // Bool to allow clicking on active menu item
      type: Boolean,
      default: false,
    },
    addType: {
      type: String,
      default: 'section',
    }
  },
  emits: ['itemClicked', 'activeSectionClicked', 'subSectionClicked', 'move', 'createEntity'],
  methods: {
    itemClicked(item, index) {
      if (this.activeSection == index && !this.allowClickOnActive) return;
      this.setActiveSection(index);
      this.$emit('itemClicked', item, index);
    },
    setActiveSection(index) {
      this.$emit('activeSectionClicked', index);
      this.setSubSection(0);
    },
    setSubSection(index, directClick = false) {
      this.$emit('subSectionClicked', index, directClick);
    },
    move({ from, to }) {
      // When the section is moved, update the subSection if it's the same as the section that was moved. 
      
      if (this.subSection === from) {
        this.setSubSection(to);
      }
      this.$emit('move', { from, to });
    },
    createEntity() {
      this.$emit('createEntity');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.lh-navigation {
  display: flex;
  flex-direction: row;
  background-color: var(--colour-panel-action);
  border-radius: var(--border-radius-1);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-colour);

  .section-container {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: none;
    max-width: 30%;

    h3 {
      font-size: 1rem;
      line-height: 1rem;
      font-weight: var(--font-weight-bold);
    }

    .active {
      .section-title {
        color: var(--colour-utility-white);
        background-color: var(--colour-utility-black);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  .section {
    padding: 0;
    .section-title {
      padding: var(--spacing-2);
      border-radius: var(--border-radius-1);
      box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-colour);
    }
    .custom-sections {
      background-color: white;
      margin-top: 0;
      padding: var(--spacing-2) var(--spacing-2);
      border-bottom-left-radius: var(--border-radius-1);
      border-bottom-right-radius: var(--border-radius-1);
      color: var(--colour-utility-black);
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    flex-direction: column;
    .section-container {
      max-width: 100%;
    }
  }

  &.mdMinus {
    margin-bottom: var(--spacing-16);
  }
}
</style>
