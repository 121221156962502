<template>
  <div
    v-if="permissions[intelligence_enquiries]?.read"
    class="enquiries-data"
  >
    <h2>
      {{
        t(`Explore enquiries data for ${customerName}`, 'explore_enquiries_data_for_customername', {
          interpolations: {
            customername: customerName,
          },
        })
      }}
    </h2>
    <div class="list">
      <CardInsights
        v-for="(item, index) in enquiriesInputItems"
        :key="index"
        :title="item.title"
        :description="item.description"
        :link-text="t('Open')"
        @open="goToReport(item)"
      />
    </div>
  </div>
</template>

<script>
import CardInsights from '@/dsv3/Card/CardInsights';
import { mapState, mapGetters } from 'vuex';
import { MATRIX, FULL_PICTURE, SALES_VS_GAPS, VARIANCE } from '@/intelligence/store/data/reportTypes';
import { intelligence_enquiries } from '@/shared/store/data/policies';
import { t } from '@sales-i/utils';

export default {
  name: 'EnquiriesDataForCustomer',
  components: {
    CardInsights,
  },
  props: {
    customerName: {
      type: String,
      default: '',
      required: true,
    },
  },
  computed: {
    ...mapState({
      permissions: state => state.pbac.permissionsGranted,
    }),
    ...mapGetters({
      getInputData: 'intelligence/shared/getInputData',
    }),
    enquiriesInputItems() {
      let arr = [];
      if (this.permissions[intelligence_enquiries] && this.permissions[intelligence_enquiries].read) {
        arr.push(MATRIX);
        arr.push(FULL_PICTURE);
        arr.push(SALES_VS_GAPS);
        arr.push(VARIANCE);
      }
      return arr.map(x => this.getInputData([x]));
    },
  },
  methods: {
    t,
    goToReport(item) {
      const name = item.urlCode,
        query = { customer: this.customerName },
        route = this.$router.resolve({ name, query });

      window.open(route.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.enquiries-data {
  box-sizing: border-box;
  border-radius: 8px;
  background-color: var(--colour-panel-action);
  padding: var(--spacing-3) var(--spacing-3) var(--spacing-5);
  margin: var(--spacing-3) auto;
}

h2 {
  text-align: center;
  margin-bottom: var(--spacing-4);
}

.list {
  overflow-x: auto;
  padding: 0 0 var(--spacing-2) 0;
  display: grid;
  gap: var(--spacing-2);
  min-width: 100%;
  grid-auto-flow: column;
  justify-content: start;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 0 0 var(--spacing-4) 0;
  }

  p {
    font-size: var(--font-size-small);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      font-size: var(--font-size-4);
    }
  }
}

:deep(.c-card) {
  position: relative;
  padding: var(--spacing-2);
  width: 284px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: var(--spacing-2);
    width: 358px;
  }

  h3 {
    font-size: var(--font-size-4);
    line-height: var(--font-size-4);
  }

  p {
    font-size: var(--font-size-small);
  }
}

:deep(header) {
  margin-bottom: var(--spacing-1);
}
</style>
