import sortByOrderPos from '@/shared/store/utils/sortByOrderPos';
/**
 * 
 * @param {*} section section structure from schema
 * @param {*} sectionValues values from database
 * @returns form fields structure from *custom* schema section
 */
export const mapCustomSectionToForm = (section, sectionValues) => ({
  id: section.id,
  order_pos: section.order_pos,
  show: typeof section.show === 'boolean' ? section.show : true,
  name: section.name || section.stage,
  description: section.description,
  fields: (section.fields || []).map(e => {
    const field = { ...e };
    field.original_type = field.type;
      
    switch (e.type.toLowerCase()) {
    case 'string':
    case 'currency':
      field.type = 'text';
      break;
    case 'list':
    case 'advancedlist':
      field.type = 'select';
      field.options = e.list_options ? e.list_options.map(option => {
        return {
          text: option.name,
          value: option.value
        };
      }).sort(sortByOrderPos) : [];
      break;
    case 'datetime':
      field.type = 'date';
      break;
    default:
      field.type = e.type.toLowerCase();
      break;
    }
    field.id = `${field.type}-${field.id}`;
    field.label = sectionValues ? e.name : 'Label';
    field.placeholder = field.type;
    field.readonly = field.editable === false;
    // if sectionValues is not undefined fill values from it otherwise provide sample values
    field.value = sectionValues ? sectionValues[e.name] : e.name;
    return field;
  }).sort(sortByOrderPos)
});

/**
 * 
 * @param {*} section 
 * @param {*} sectionValues 
 * @returns form fields structure from *default* schema section
 */
export const mapDefaultSectionToForm = (section, sectionValues) => ({
  id: section.id,
  order_pos: section.order_pos,
  name: section.name,
  description: section.description,
  fields: section.fields.map(e => {
    const field = { ...e };
    field.original_type = field.type;
      
    switch (e.type.toLowerCase()) {
    case 'string':
    case 'currency':
      field.type = 'text';
      break;
    case 'list':
    case 'advancedlist':
      field.type = 'select';
      field.options = e.list_options ? e.list_options.map(option => {
        return {
          text: option.name,
          value: option.value
        };
      }).sort(sortByOrderPos) : [];
      break;
    case 'datetime':
      field.type = 'date';
      break;
    default:
      field.type = e.type.toLowerCase();
      break;
    }
    field.id = `${field.type}-${field.id}`;
    field.label = e.name;
    field.placeholder = e.name;
    field.readonly = field.editable === false;
    field.value = sectionValues ? sectionValues[e.name] : '';
    return field;
  }).sort(sortByOrderPos)
});

/**
 * 
 * @param {*} section 
 * @param {*} sectionValues 
 * @returns form fields structure from schema section (*default or custom*)
 */
export const mapSchemaSectionToForm = (section, sectionValues) => (
  section.system_flag === 'custom'
    ? mapCustomSectionToForm(section, sectionValues)
    : mapDefaultSectionToForm(section, sectionValues)
);

export const mapSectionValuesToObject = (dataFromAPI) => {
  return ((dataFromAPI || {}).values || []).reduce((obj, item) => ({
    ...obj,
    [item.field]: item.value
  }), {});
};