import {
  DELETE_ITEM,
  DELETE_ITEM_LOADING,
  DELETE_ITEM_ERROR,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const deleteItemMutations = {
  [DELETE_ITEM]: (state, id) => {
    state.selected.data.widgets = state.selected.data.widgets.filter(x => x.id !== id);
    state.selected.loading = false;
    state.selected.loaded = true;
  },
  [DELETE_ITEM_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [DELETE_ITEM_ERROR]: (state) => {
    state.selected.loading = false;
    state.selected.loaded = true;
  },
};

// actions
export const deleteItemActions = {
  [DELETE_ITEM]: async ({ commit, }, { id, dashboard_id }) => {
    try {
      commit(DELETE_ITEM_LOADING);
      await httpRequest('delete', `dashboards/${dashboard_id}/widget/${id}`, undefined, { area: 'dashboard' });
      commit(DELETE_ITEM, id);
      return true;
    } catch (error) {
      console.error(error);
      commit(DELETE_ITEM_ERROR);
      return false;
    }
  },
};
