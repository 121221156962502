<template>
  <div
    v-for="(item, index) in items"
    :key="index"
  >
    <div class="flex align-center mt-2 mb-2">
      <IconBase
        height="25"
        width="25"
        icon-name="user-circle"
        icon-color="black"
      />
      <h2 class="px-1 fw-sbld text-2 group-by-title">
        {{ item.title }}
      </h2>
    </div>
    <div
      role="list"
      class="list list-card"
    >
      <SimpleContactCard
        v-for="contact in item.items"
        :key="contact.id"
        :item="contact"
        @item-clicked="handleItemClicked"
        @edit="handleEdit"
      />
    </div>
  </div>
</template>

<script setup>
// GroupContactsList
import { IconBase } from '@sales-i/dsv3';
import SimpleContactCard from './SimpleContactCard.vue';

defineProps({
  items: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['edit', 'itemClicked']);

function handleItemClicked(item) {
  emit('itemClicked', item);
}
function handleEdit(item) {
  emit('edit', item);
}
</script>

<style lang="scss" scoped>
.buffer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
