<template>
  <div class="measure-filter">
    <MenuFilter
      :items="MEASURES"
      :selected-value="selectedMeasure"
      menu-position="right"
      @on-change="changeMeasure"
    />
  </div>
</template>

<script>
import { MenuFilter } from '@sales-i/dsv3';
import { APPLY_MEASURE, REFRESH_REPORT } from '@/intelligence/store/actionType';
import { mapActions, mapState } from 'vuex';
import { MEASURES } from '@/intelligence/store/data/kpis';
import { navigateToUrl } from 'single-spa';
export default {
  name: 'EnquiryMeasure',
  components: { MenuFilter },
  props: {
    isBubbleSelectionScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      MEASURES,
    };
  },
  computed: {
    ...mapState({
      selectedMeasure: state => state.intelligence.shared.requestParameters.measure,
      iframe: state => state.system.iframe,
    }),
  },
  methods: {
    ...mapActions({
      applyMeasure: `intelligence/enquiry/${APPLY_MEASURE}`,
      refreshReport: `intelligence/shared/${REFRESH_REPORT}`,
    }),
    changeMeasure(measure) {
      this.applyMeasure(measure.value);

      if (!this.isBubbleSelectionScreen) {
        if (!this.iframe) {
          const route = this.$router.resolve({ query: { ...this.$route.query, measure: measure.value } });
          navigateToUrl(route.href);
        }
        this.refreshReport();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.measure-filter {
  left: 70px;
  top: 12px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: relative;
    left: auto;
    top: var(--spacing-1)
 }

  &:deep(.menu-filter .list) {
    top: auto;
    bottom: var(--spacing-6);
    right: 0;
    left: initial;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      top: var(--spacing-5);
      bottom: auto;
      right: initial;
      left: 0;
    }
  }

  &:deep(.menu-filter .filter-select__selections[data-v-24eb7e9e]::before) {
    width: 16px;
  }

  &:deep(.filter-select__selection) {
    white-space: break-spaces;
    max-width: 52px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      white-space: nowrap;
      max-width: none;
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: relative;
    left: auto;
    top: var(--spacing-1);
  }
}
</style>
