export const BUBBLE_ID_KEY = 'id';
export const BUBBLE_TITLE_KEY = 'title';
export const BUBBLE_DISABLED_KEY = 'disabled';
export const BUBBLE_CLICKABLE_KEY = 'clickable';
export const BUBBLE_DESCRIPTION_KEY = 'description';
export const BUBBLE_CROSS_BUTTON_EVENT_KEY = 'xButtonEvent';
export const BUBBLE_CHECKED_KEY = 'checked';
export const BUBBLE_AREA_KEY = 'area';

export const BUBBLE_AREA_CUSTOMER = 'Customer';
export const BUBBLE_AREA_PRODUCT = 'Product';
export const BUBBLE_AREA_SALES = 'Sales';
export const BUBBLE_AREA_CALENDAR = 'Calendar';

export const DRILL_PARAMS_KEY = 'drillParams';
export const DRILL_ACTIVE_ROWS_KEY = 'drillActiveRows';

export const BUBBLE_DATA_KEYS = [
  BUBBLE_ID_KEY,
  BUBBLE_TITLE_KEY,
  BUBBLE_AREA_KEY,
  BUBBLE_DISABLED_KEY,
  BUBBLE_CLICKABLE_KEY,
  BUBBLE_DESCRIPTION_KEY,
  BUBBLE_CROSS_BUTTON_EVENT_KEY,
  BUBBLE_CHECKED_KEY,
];

// List of x-axis IDs corresponding to the Calendar bubbles for a Matrix report
// If the xAxis is one of these IDs, the x-axis order will be set to ascending ("asc")
// For all other x-axis IDs, the order will be descending ("desc")
export const BUBBLE_CALENDAR_ASC = [
  'dt101',
  'dt104',
  'dt105',
  'dt106',
  'dt107',
  'dt108',
  'dt109',
  'dt111',
  'dt112',
  'dt113',
  'dt114',
  'dt115',
  'dt117',
  'dt118',
  'dt119',
  'dt120',
  'dt121'
];
