import BubbleSelection from '@/intelligence/components/Bubble/BubbleSelection.vue';
import EnquiryReportView from '@/intelligence/components/EnquiryReport/EnquiryReportView';
import { baseUrl, enquiryScope, reportScope } from '@/intelligence/router/urlBits';
import { FULL_PICTURE } from '@/intelligence/store/data/reportTypes';

export default [
  {
    path: `${baseUrl}/${enquiryScope}/${FULL_PICTURE}`,
    component: BubbleSelection,
    name: FULL_PICTURE,
    props: { reportType: FULL_PICTURE }
  },
  {
    path: `${baseUrl}/${enquiryScope}/${FULL_PICTURE}/${reportScope}`,
    component: EnquiryReportView,
    name: `${FULL_PICTURE}-${reportScope}`,
    props: { reportType: FULL_PICTURE }
  }
];
