import { SET_ITEMS, } from '@/shared/store/actionType';
import {
  RESET_CHANGES,
  GET_CUSTOM_SCHEMA,
  LOADING_CUSTOM_SCHEMA,
  CLEAR_CUSTOM_SCHEMA
} from '@/admin/store/actionType';
import { httpRequest } from '@sales-i/utils';
import { 
  mapCustomSectionToForm, 
  mapDefaultSectionToForm 
} from '@/shared/utils/customSchema';


export const getByEntityGetters = {
  getSections: (state) => {
    const defaultSections = [];
    const customSections = [];
    for (let n in state?.schema?.sections) {
      const section = state.schema.sections[n];
      if (section.system_flag === 'custom') {
        customSections.push(mapCustomSectionToForm(section));
      } else {
        defaultSections.push(mapDefaultSectionToForm(section));
      }
    }
    return { defaultSections, customSections };
  }
};

export const getByEntityMutations = {
  [CLEAR_CUSTOM_SCHEMA]: (state) => {
    state.entity = '';
    state.schema = {
      sections: []
    };
    state.originalSchema = {
      sections: []
    };
    state.loading = false;
    state.loading_menu = false;
    state.loading_section = false;
  },
  [LOADING_CUSTOM_SCHEMA]: (state, fetchOnUpdate) => {
    state.loading = !fetchOnUpdate;
    state.loading_menu = !fetchOnUpdate;
    state.loading_section = true;
  },
  [GET_CUSTOM_SCHEMA]: (state, data) => {
    state.entity = data.entity;
    state.schema = data.payload;

    state.schema.sections.forEach(section => {
      section.fields.forEach(field => {
        // Check if there is a list_options array.
        if (field.list_options) return;
        
        // Add a list option array if the field is a list type.
        if (field.type === 'ADVANCEDLIST' || field.type === 'LIST') {
          field.list_options = [];
        }
      });
    });

    state.originalSchema = data.payload;
    state.loading = false;
    state.loading_menu = false;
    state.loading_section = false;
    state.updatesNotSaved = false;
  },
  [RESET_CHANGES]: (state) => {
    // destructuring to prevent schema pointing to originalSchema
    state.schema = {
      ...state.originalSchema,
      sections: [
        ...state.originalSchema.sections
      ],
    };
  },
};

export const getByEntityActions = {
  [GET_CUSTOM_SCHEMA]: async ({ commit, dispatch }, { entity = 'prospects', fetchOnUpdate = false, refreshCall = false }) => {
    if (!fetchOnUpdate) {
      commit(CLEAR_CUSTOM_SCHEMA);
    }
    const needsRefresh = refreshCall ? `?now=${new Date().getTime()}` : '';
    commit(LOADING_CUSTOM_SCHEMA, fetchOnUpdate);
    if (['customers', 'prospects', 'contacts', 'opportunities', 'interactions'].includes(entity)) {
      try {
        const response = await httpRequest('get', `config/schema/${entity}${needsRefresh}`, {}, {
          version: '2'
        });
        commit(GET_CUSTOM_SCHEMA, {
          payload: response,
          entity: entity,
        });
      } catch (e) {
        dispatch(`alerts/${SET_ITEMS}`, {
          type: 'error',
          message: JSON.stringify(e)
        }, {root: true});
        commit(CLEAR_CUSTOM_SCHEMA);
      }
    }
    // TODO: replace temp data with plumbed in API response
    if (['files'].includes(entity)) {
      let entityStr = '';
      switch (entity) {
      case 'contacts':
        entityStr = 'contact';
        break;
      case 'opportunities':
        entityStr = 'opportunity';
        break;
      case 'interactions':
        entityStr = 'interaction';
        break;
      case 'files':
        entityStr = 'file';
        break;
      case 'company':
        entityStr = 'company';
        break;
      }

      setTimeout(
        () => {
          let payload = {
            entity: entityStr,
            locale: 'en_GB',
            sections: [],
            version: 'v1'
          };
          commit(GET_CUSTOM_SCHEMA, {
            payload: payload,
            entity: entity,
          });
        },
        1000
      );
    }
  },
  [RESET_CHANGES]: ({ commit }) => {
    commit(RESET_CHANGES);
  },
  [CLEAR_CUSTOM_SCHEMA]: ({ commit }) => {
    commit(CLEAR_CUSTOM_SCHEMA);
  },
};
