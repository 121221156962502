import { LOCATION_PROSPECTS } from './interactionStageNames';
import { t } from '@sales-i/utils';


export const USE_CURRENCY = 'USE_CURRENCY';

export const generateInteractionFields = [
  {
    stage: t('Settings'),
    sections: [
      {
        sectionTitle: t('Allocation'),
        fields: [
          {
            label: t('Allocate to'),
            id: 'allocated_to',
            type: 'select',
            options: [
              {
                text: t('Loading'),
                value: 0
              }
            ],
            minLength: 1,
            maxLength: 40,
            placeholder: '',
            required: true,
            disabled: false,
            errorMessage: 'Please enter a valid customer name',
            twoway: true
          },
        ]
      },
      {
        sectionTitle: t('Number/duration'),
        fields: [
          {
            label: t('Interaction type'),
            id: 'interaction_type',
            type: 'select',
            options: [
              {
                text: t('Loading'),
                value: 0
              }
            ],
            required: true,
            errorMessage: 'Please enter interaction type',
            twoway: true,
            value: 0
          },
          {
            label: t('Number of interactions'),
            id: 'number_of_interactions',
            type: 'number',
            value: 1,
            min: 1,
            max: 100,
            required: true,
            errorMessage: 'Please enter a number between 1 and 100',
            twoway: true
          },
          {
            label: t('Interaction duration (mins)'),
            id: 'duration_of_each_interaction',
            type: 'select',
            value: 5,
            min: 5,
            max: 90,
            options: [
              { text: '5', value: 5 },
              { text: '10', value: 10 },
              { text: '15', value: 15 },
              { text: '20', value: 20 },
              { text: '25', value: 25 },
              { text: '30', value: 30 },
              { text: '35', value: 35 },
              { text: '40', value: 40 },
              { text: '45', value: 45 },
              { text: '50', value: 50 },
              { text: '55', value: 55 },
              { text: '60', value: 60 },
              { text: '65', value: 65 },
              { text: '70', value: 70 },
              { text: '75', value: 75 },
              { text: '80', value: 80 },
              { text: '85', value: 85 },
              { text: '90', value: 90 },
            ],
            required: true,
            disabled: false,
            twoway: true
          },
          {
            label: t('Time between interactions (mins)'),
            id: 'mins_between_interactions',
            type: 'select',
            value: 5,
            min: 5,
            max: 120,
            options: [
              { text: '5', value: 5 },
              { text: '10', value: 10 },
              { text: '15', value: 15 },
              { text: '20', value: 20 },
              { text: '25', value: 25 },
              { text: '30', value: 30 },
              { text: '35', value: 35 },
              { text: '40', value: 40 },
              { text: '45', value: 45 },
              { text: '50', value: 50 },
              { text: '55', value: 55 },
              { text: '60', value: 60 },
              { text: '65', value: 65 },
              { text: '70', value: 70 },
              { text: '75', value: 75 },
              { text: '80', value: 80 },
              { text: '85', value: 85 },
              { text: '90', value: 90 },
              { text: '95', value: 95 },
              { text: '100', value: 100 },
              { text: '105', value: 105 },
              { text: '110', value: 110 },
              { text: '115', value: 115 },
              { text: '120', value: 120 },
            ],
            required: true,
            disabled: false,
            errorMessage: 'Please input greater value than duration',
            twoway: true
          },
        ]
      },
      {
        sectionTitle: t('Exclusions'),
        fields: [
          {
            // Set label to value of entityType
            // label: `Exclude ${entityType} contacted within`,
            label: t('Exclude prospects contacted within:'),
            id: 'exclude_recently_contacted_days',
            type: 'select',
            options: [
              {
                text: t(
                  '7 days',
                  'number_days',
                  { interpolations: {
                    number: 7
                  }}
                ),
                value: 7,
              },
              {
                text: t(
                  '14 days',
                  'number_days',
                  { interpolations: {
                    number: 14
                  }}
                ),
                value: 14,
              },
              {
                text: t(
                  '30 days',
                  'number_days',
                  { interpolations: {
                    number: 30
                  }}
                ),
                value: 30,
              },
              {
                text: t(
                  '60 days',
                  'number_days',
                  { interpolations: {
                    number: 60
                  }}
                ),
                value: 60,
              },
              {
                text: t(
                  '90 days',
                  'number_days',
                  { interpolations: {
                    number: 90
                  }}
                ),
                value: 90,
              },
              {
                text: t(
                  '120 days',
                  'number_days',
                  { interpolations: {
                    number: 120
                  }}
                ),
                value: 120,
              },
              {
                text: t(
                  '180 days',
                  'number_days',
                  { interpolations: {
                    number: 180
                  }}
                ),
                value: 180,
              },
              {
                text: t(
                  '365 days',
                  'number_days',
                  { interpolations: {
                    number: 365
                  }}
                ),
                value: 365,
              },
            ],
            minLength: 1,
            maxLength: 40,
            placeholder: '',
            disabled: false,
            errorMessage: 'Please select value',
            twoway: true
          },
        ]
      },
    ],
  },

  {
    stage: t('Adding opportunites'),
    sections: [
      {
        sectionTitle: t('Opportunities'),
        fields: [
          {
            label: t('Value greater than'),
            id: 'opportunity_min',
            value: 0,
            type: 'number',
            min: 0,
            placeholder: USE_CURRENCY,
            errorMessage: 'Please enter a number',
            twoway: true
          },
          {
            label: t('Value less than'),
            id: 'opportunity_max',
            value: 100000000000,
            type: 'number',
            min: 0,
            placeholder: USE_CURRENCY,
            errorMessage: 'Please enter a number greater than Min',
            twoway: true
          },
          {
            label: t('Include opportunities'),
            id: 'opportunity_include',
            value: true,
            type: 'checkbox',
            required: false,
            twoway: true
          },
        ]
      }
    ],
  },

  // {
  //   stage: t('Profile completion status'),
  //   sections: [
  //     {
  //       sectionTitle: t('Profile completion status'),
  //       fields: [
  //         {
  //           id: 'complete_less_than_33',
  //           name: 'profile_completion_status',
  //           label: t('< 33%'),
  //           type: 'radio',
  //         },
  //         {
  //           id: 'complete_33_to_66',
  //           name: 'profile_completion_status',
  //           label: t('33% - 66%'),
  //           type: 'radio',
  //         },
  //         {
  //           id: 'complete_greater_than_66',
  //           name: 'profile_completion_status',
  //           label: t('> 66%'),
  //           type: 'radio',
  //         },
  //         {
  //           id: 'all',
  //           name: 'profile_completion_status',
  //           label: t('All'),
  //           type: 'radio',
  //           value: true,
  //         },
  //       ]
  //     }
  //   ],
  // },

  {
    stage: t(LOCATION_PROSPECTS),
    sections: [
      {
        sectionTitle: t(LOCATION_PROSPECTS),
        fields: [
          {
            label: t(),
            id: 'search_location_type',
            type: 'filter',
            value:{
              text: t('Everywhere'),
              value: 'none',
            },
            options: [
              {
                text: t('Everywhere'),
                value: 'none',
              },
              {
                text: t('Zip/postcode'),
                value: 'postcode',
              },
              {
                text: t('Town/city'),
                value: 'town',
              },
            ],
            required: false,
            twoway: true
          },
          {
            label: t('Prospect location'),
            id: 'search_location',
            type: 'text',
            minLength: 1,
            maxLength: 40,
            placeholder: '',
            required: false,
            disabled: false,
            errorMessage: 'Please enter a valid location',
            twoway: true
          },
        ]
      }
    ]
  },

  {
    stage: t('Add an interaction note'),
    sections: [
      {
        sectionTitle: t('Interaction note'),
        fields: [
          {
            label: t('Note'),
            id: 'description',
            type: 'textarea',
            rows: 5,
            minLength: 1,
            maxLength: 280,
            placeholder: '',
            disabled: false,
            errorMessage: 'Please enter a valid note',
            twoway: true
          },
        ]
      }
    ]
  },
];
