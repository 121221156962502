import { RELOAD_VIEW } from '@/shared/store/actionType';

// initial state
const state = () => ({
  reloadViewCounter: 0,
});

// getters
const getters = {
};

// mutations
const mutations = {
  [RELOAD_VIEW]: state => {
    state.reloadViewCounter++;
  },
};

// actions
const actions = {
  [RELOAD_VIEW] ({ commit }) {
    commit(RELOAD_VIEW);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
