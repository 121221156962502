<template>
  <div class="logo-container">
    <div class="logo-upload-wrapper">
      <h3>{{ t('Upload and configure') }}</h3>
      <p>{{ t('Replace or remove your logo for desktop and mobile') }}</p>
      <div class="upload-boxes-wrapper">
        <UploadBox
          v-for="uploadBox in uploadBoxes"
          :key="uploadBox.inputId"
          :input-id="uploadBox.inputId"
          :title="uploadBox.title"
          :entity-type="uploadBox.entityType"
          :max-width="uploadBox.maxWidth"
          :max-height="uploadBox.maxHeight"
        />
      </div>
    </div>
    <div class="logo-review-wrapper">
      <h3>{{ t('Review') }}</h3>
      <p>{{ t('Make your mark, your logo will appear across sales-i and all of your exported files') }}</p>
      <img
        src="@/shared/assets/image/admin/logo_review_home.png"
        alt=""
        class="logo-review-home-img"
      >
      <img
        src="@/shared/assets/image/admin/logo_review_snapshots.png"
        alt=""
        class="logo-review-snapshots-img"
      >
    </div>
  </div>
</template>

<script setup>
import { t } from '@sales-i/utils';
import UploadBox from '@/admin/components/Company/Logo/UploadBox.vue';

const uploadBoxes = [
  {
    inputId: 'desktop',
    title: t('Desktop'),
    entityType: 'logo',
    maxWidth: 1489,
    maxHeight: 300,
  },
  {
    inputId: 'mobile',
    title: t('Mobile'),
    entityType: 'icon',
    maxWidth: 300,
    maxHeight: 300,
  },
];
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.logo-container {
  display: flex;
  padding: 0 var(--spacing-4);
  flex-direction: column;

  @media #{map-get($display-breakpoints, 'xxl-only')} {
    flex-direction: row;
  }

  h3 {
    margin-bottom: var(--spacing-2);
  }

  p {
    margin-bottom: var(--spacing-4);
  }

  .logo-upload-wrapper {
    margin-bottom: var(--spacing-5);

    .upload-boxes-wrapper {
      display: flex;
      gap: var(--spacing-5)
    }
  }

  .logo-review-wrapper {
    border-left: none;
    border-top: 1px solid var(--colour-panel-g-16);
    padding-left: 0;
    padding-top: var(--spacing-4);
    margin-left: 0;

    @media #{map-get($display-breakpoints, 'xxl-only')} {
      border-left: 1px solid var(--colour-panel-g-16);
      border-top: none;
      padding-left: var(--spacing-4);
      padding-top: 0;
      margin-left: var(--spacing-4);
    }

    .logo-review-home-img {
      max-width: 450px;
      vertical-align: top;
      margin-right: var(--spacing-3)
    }
    .logo-review-snapshots-img {
      max-width: 280px;
      vertical-align: top;
    }
  }
}

:deep(.c-card--footer) {
  margin: 0;
  padding: 0;
}
</style>