import { getters as gapsGetters, mutations as gapsMutations, actions as gapsActions } from './gaps';

const state = () => ({
  data: [],
  errors: [],
  loading: true,
  loaded: false,
  drilled: {},
});

const getters = {
  ...gapsGetters,
};

const mutations = {
  ...gapsMutations,
};

const actions = {
  ...gapsActions,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};