/* eslint-disable no-unused-vars */
import {
  POST_GENERATE_INTERACTIONS,
  SET_GENERATE_INTERACTIONS_REQUEST,
  CLEAR_GENERATE_INTERACTIONS,
  SET_GENERATE_INTERACTIONS_ERROR,
  POST_GENERATE_INTERACTIONS_LOADING,
  SET_GENERATE_INTERACTIONS_RESULT
} from '@/crm/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const generateInteractionsGetters = {
  generatedCount: (state) => {
    return state.generateInteractions.responseData.id
      ? state.generateInteractions.responseData.id.length
      : 0;
  }
};

// mutations
export const generateInteractionsMutations = {
  [SET_GENERATE_INTERACTIONS_REQUEST]: (state, data) => {
    state.generateInteractions.data = {
      ...state.generateInteractions.data,
      ...data
    };
  },
  [POST_GENERATE_INTERACTIONS_LOADING]: (state) => {
    state.generateInteractions.responseData = {};
    state.generateInteractions.loaded = true;
    state.generateInteractions.loading = false;
    state.generateInteractions.error = undefined;
  },
  [SET_GENERATE_INTERACTIONS_RESULT]: (state, data) => {
    state.generateInteractions.responseData = data;
    state.generateInteractions.loaded = true;
    state.generateInteractions.loading = false;
    state.generateInteractions.error = undefined;
  },
  [CLEAR_GENERATE_INTERACTIONS]: (state) => {
    state.generateInteractions.data = {};
    state.generateInteractions.responseData = {};
    state.generateInteractions.loaded = false;
    state.generateInteractions.loading = false;
    state.generateInteractions.error = undefined;
  },
  [SET_GENERATE_INTERACTIONS_ERROR]: (state, errors) => {
    state.generateInteractions.responseData = {};
    state.generateInteractions.loaded = false;
    state.generateInteractions.loading = false;
    state.generateInteractions.error = errors;
  },
};

const mapGenerateInteractionData = (data, entity_ids, entity_type, description = '') => {
  if (!data) return undefined;
  let returnObj = {
    entity_type,
    entity_ids,
    ...data,
    // If there is no value for search_location_type, set it to none
    search_location_type: data.search_location_type ? data.search_location_type.value : 'none',
    search_location: data.search_location || '',
    opportunity_min: data.opportunity_min || 0,
    opportunity_max: data.opportunity_max || 0,
    mins_between_interactions: +data.mins_between_interactions || 0,
    exclude_recently_contacted_days: +data.exclude_recently_contacted_days || 0,
    interaction_type: +data.interaction_type,
    description,
  };
  if (returnObj.opportunity_include === false) {
    delete returnObj.opportunity_min;
    delete returnObj.opportunity_max;
  }
  // switch (returnObj.profile_completion_status) {
  // case 'all':
  if (entity_type === 'prospect') {
    returnObj.entity_percent_complete_max = 100;
    returnObj.entity_percent_complete_min = 0;
    delete returnObj.entity_ids;
  } else {
    delete returnObj.entity_percent_complete_max;
    delete returnObj.entity_percent_complete_min;

    if (returnObj.entity_ids && returnObj.entity_ids.length > 0) {
      returnObj.search_location_type = 'none',
      returnObj.opportunity_include = false,
      returnObj.exclude_recently_contacted_days = 0,
      returnObj.number_of_interactions = 0,
      delete returnObj.opportunity_min;
      delete returnObj.opportunity_max;
      delete returnObj.search_location;
    }
  }
  //   break;
  // case 'complete_greater_than_66':
  //   returnObj.entity_percent_complete_max = 100;
  //   returnObj.entity_percent_complete_min = 67;
  //   break;
  // case 'complete_33_to_66':
  //   returnObj.entity_percent_complete_max = 66;
  //   returnObj.entity_percent_complete_min = 33;
  //   break;
  // case 'complete_less_than_33':
  //   returnObj.entity_percent_complete_max = 32;
  //   returnObj.entity_percent_complete_min = 0;
  //   break;
  // }
  delete returnObj.profile_completion_status;
  if (returnObj.profile_completion_status === 'all') {
    delete returnObj.profile_completion_status;
  }
  return returnObj;
};

// actions
export const generateInteractionsActions = {
  [CLEAR_GENERATE_INTERACTIONS]: async ({commit}) => {
    commit(CLEAR_GENERATE_INTERACTIONS);
  },
  [SET_GENERATE_INTERACTIONS_REQUEST]: async ({commit}, data) => {
    commit(SET_GENERATE_INTERACTIONS_REQUEST, data);
  },
  [POST_GENERATE_INTERACTIONS]: async ({state, commit}, { description }) => {
    // Get entityType from state
    let entityIDs = state.currentlyEditing.entityIDs;
    let entityType = state.currentlyEditing.entityType;
    
    try {
      commit(POST_GENERATE_INTERACTIONS_LOADING);
      let requestData = mapGenerateInteractionData(state.generateInteractions.data, entityIDs, entityType, description);
      if (!requestData) return;
      let data = await httpRequest('post', 'interactions/generate', requestData, { version: '2', debug: true });
      commit(SET_GENERATE_INTERACTIONS_RESULT, data);
      return data;
    } catch (error) {
      console.error(error);
      commit(SET_GENERATE_INTERACTIONS_ERROR, error);
      return false;
    }
  },
};
