/* eslint-disable */
import { DELETE_BY_ENTITY, DELETE_BY_ENTITY_ERROR, } from '@/shared/store/actionType';
import {
  GET_CUSTOM_SCHEMA,
  LOADING_CUSTOM_SCHEMA,
} from '@/admin/store/actionType';
import { httpRequest } from '@sales-i/utils';
import getSchemaId from '@/admin/store/utils/getSchemaId';
import getEntityStr from '@/admin/store/utils/getEntityStr';


export const deleteByEntityMutations = {
  [DELETE_BY_ENTITY_ERROR]: (state) => {
    state.loading = false;
    state.loading_menu = false;
    state.loading_section = false;
  }
};

export const deleteByEntityActions = {
  [DELETE_BY_ENTITY]: async ({ commit, state, dispatch }, { entity, section_id, }) => {
    commit(LOADING_CUSTOM_SCHEMA, true);
    try {
      await httpRequest('delete', `config/schema/${entity}/sections/${section_id}`, {}, { version: '2' },);
      dispatch(GET_CUSTOM_SCHEMA, { entity, fetchOnUpdate: true, refreshCall: true, });
    } catch(e) {
      commit(DELETE_BY_ENTITY_ERROR, e);
    }
  }
};
