<template>
  <div class="container container--fluid">
    <Tabs
      class="mb-0"
      :items="navigation"
      :selected-index="selectedIndex"
      @nav-clicked="navClicked"
      @tab-selected="tabSelected"
    />
    <div class="padded">
      <router-view />
    </div>
  </div>
</template>

<script setup>
import Tabs from '@/shared/components/Tabs/Tabs.vue';
import {
  baseUrl,
  crmArea as area,
  customersPropspectsSection,
  contactsSection,
  opportunitiesSection,
  interactionsSection,
} from '@/admin/router/urlBits';

import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, } from 'vue-router';
import { t } from '@sales-i/utils';
import { useHead } from '@unhead/vue';
import { navigateToUrl } from 'single-spa';

const selectedIndex = ref(0);
const vroute = useRoute();

const navigation = computed(() => [
  {
    title: t('Customer/Prospect'),
    path: `${baseUrl}/${area}/${customersPropspectsSection}`,
    key: customersPropspectsSection,
  },
  {
    title: t('Contacts'),
    path: `${baseUrl}/${area}/${contactsSection}`,
    key: contactsSection,
  },
  {
    title: t('Opportunities'),
    path: `${baseUrl}/${area}/${opportunitiesSection}`,
    key: opportunitiesSection,
  },
  {
    title: t('Interactions'),
    path: `${baseUrl}/${area}/${interactionsSection}`,
    key: interactionsSection,
  },
]);

watch(
  () => vroute.fullPath,
  to => setSelectedIndex(to)
);
onMounted(() => {
  setSelectedIndex(vroute.fullPath);
  tabSelected(navigation.value[selectedIndex.value].title);
});

function navClicked(data) {
  const { index, item } = data;
  selectedIndex.value = index;
  if (decodeURI(vroute.path) !== item.path) {
    navigateToUrl(item.path);
  }
}

function tabSelected(tab) {
  useHead({
    title: tab,
  });
}

function setSelectedIndex(path) {
  selectedIndex.value = Math.max(
    0,
    navigation.value.findIndex(e => {
      const keys = path.split('/');
      return keys.includes(e.key);
    })
  );
}
</script>
