import {
  GET_BY_ENTITY,
  GET_BY_ENTITY_LOADING,
  GET_BY_ENTITY_ERROR,
} from '@/shared/store/actionType';
import { t } from '@sales-i/utils';

const customerSettings = [
  {
    setting: t(
      'Prospect percent complete chip',
      'entity_percent_complete_chip',
      { interpolations: {
        entity: 'Prospect'
      }}
    ),
    card: {
      title: t('Prospect Name'),
      info: '',
      helpText: t(
        'Display the percentage completion of a prospect profile.',
        'display_the_percentage_completion_of_a_entity_profile',
        { interpolations: {
          entity: 'prospect'
        }}
      ),
    }
  }
];

const contactSettings = [
  {
    setting: t(
      'Contact percent complete chip',
      'entity_percent_complete_chip',
      { interpolations: {
        entity: 'Contact'
      }}
    ),
    card: {
      title: 'John Smith',
      info: t('Company Name'),
      helpText: t(
        'Display the percentage completion of a contact profile.',
        'display_the_percentage_completion_of_a_entity_profile',
        { interpolations: {
          entity: 'contact'
        }}
      ),
    }
  },
];

const opportunitySettings = [
  {
    setting: t(
      'Opportunity percent complete chip',
      'entity_percent_complete_chip',
      { interpolations: {
        entity: 'Opportunity'
      }}
    ),
    card: {
      title: 'Eric Clapton',
      info: t('Company Name'),
      helpText: t('Display how close to completion an opportunity is.'),
    }
  },
];

// getters
export const getByEntityGetters = {
};

// mutations
export const getByEntityMutations = {
  [GET_BY_ENTITY]: (state, data) => {
    state.data = data;
    state.loaded = true;
    state.loading = false;
  },
  [GET_BY_ENTITY_LOADING]: (state) => {
    state.loaded = false;
    state.loading = true;
  },
  [GET_BY_ENTITY_ERROR]: (state, data) => {
    state.data = [];
    state.loaded = false;
    state.loading = false;
    state.errors = data;
  },
};

// actions
export const getByEntityActions = {
  [GET_BY_ENTITY]: async ({ commit, }, { entity }) => {
    commit(GET_BY_ENTITY_LOADING);
    try {
      let data = [];
      switch(entity) {
      case 'customers':
        data = customerSettings;
        break;
      case 'contacts':
        data = contactSettings;
        break;
      case 'opportunities':
        data = opportunitySettings;
        break;
      }
      commit(GET_BY_ENTITY, data);
    } catch(e) {
      commit(GET_BY_ENTITY_ERROR, []);
    }

  },
};
