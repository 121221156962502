<template>
  <div
    class="template-report"
    :class="[{ expanded }, reportType, expandedDataView]"
  >
    <BufferImage
      v-if="(dataLoading && !lazyLoading) || !isReportDataLoaded"
      color="black"
      float="center"
      class="loading-spinner"
    />
    <div>
      <section class="header-section">
        <h3>
          {{ getProcessedReportTitle(inputData.title) }}
          <br>
          <span v-if="rows.length"> {{ dateFilterTitle }} {{ reportDateBoundaries }} </span>
        </h3>
      </section>
      <div
        v-if="rows.length"
        class="container-wrapper mb-2"
      >
        <FullScreenButton
          enable-switch
          :visible-data-type="expandedDataView"
          @full-screen-data-type-change="switchDataView"
        />
      </div>
      <TemplateReportCharts
        v-if="isReportDataLoaded && chartInput?.length"
        :key="reportId"
        :class="[reportType, mq.current]"
        :report-data="rows"
        :chart-inputs="chartInput"
        :settings="{ limit: 5 }"
        :is-carousel="breakpoints.smAndDown.includes(mq.current)"
        :sort-option="inputData.defaultSortOption"
      />
    </div>
    <section
      v-if="rows.length && isReportDataLoaded"
      class="data-section"
    >
      <TemplateReportTable
        :current-sort="currentSort"
        :report-id="reportId"
        :report-type="reportType"
        @apply-new-sort="handleNewSort"
        @handle-chart-sorting="handleChartSorting"
        @load-more-data="loadMoreData"
        @cta-click="handleCtaClick"
      />
    </section>
    <NoResults v-if="!rows.length && !dataLoading && isReportDataLoaded" />
    <ReportFooter
      v-if="rows.length && isReportDataLoaded"
      :dimensions="dimensions"
      :report-type="reportType"
    />
  </div>
</template>

<script setup>
// TemplateReport
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useMq } from 'vue3-mq';
import breakpoints from '@/shared/utils/breakpoints';
import { BufferImage } from '@sales-i/dsv3';

import { FETCH_BUBBLE_DATA, CLEAR_ENQUIRY_FILTERS } from '@/intelligence/store/actionType';
import { DATE_FILTER_KEYS, DATE_FILTER_OPTIONS } from '@/intelligence/store/data/dateFilters';
import { FULL_PICTURE, 
  PRODUCT_GROUP_TEMPLATE_REPORT_TYPES, PRODUCT_TEMPLATE_REPORT_TYPES, 
  REPORT_TYPES
} from '@/intelligence/store/data/reportTypes';
import { REPORT_AREA_PRODUCT } from '@/intelligence/store/data/areas';

import TemplateReportTable from '@/intelligence/components/TemplateReport/TemplateReportTable';
import TemplateReportCharts from '@/intelligence/components/TemplateReport/TemplateReportCharts.vue';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';
import FullScreenButton from '@/intelligence/components/Shared/FullScreenButton';
import NoResults from '@/intelligence/components/Shared/NoResults';

import useTemplateReport from '@/intelligence/composables/useTemplateReport';
import useCalendar from '@/intelligence/composables/useCalendar';
import useShared from '@/intelligence/composables/useShared';
import useLazyLoading from '@/intelligence/composables/useLazyLoading';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import { useFilters } from '@/intelligence/composables/useFilters';

const vroute = useRoute();
const mq = useMq();
const props = defineProps({
  reportType: {
    type: String,
    default: '',
    validator: function (value) {
      return REPORT_TYPES.indexOf(value) !== -1;
    },
  },
});

const currentSort = ref('');
const expandedDataView = ref('data');
const dateFilterOptions = ref(DATE_FILTER_OPTIONS);
const defaultDateFrom = ref('today/y');
const defaultDateTo = ref('today');
const isReportDataLoaded = ref(false);
const reportId = ref(1);
const lazyLoading = ref(false);
   
const store = useStore();
const { 
  dataLoading, 
  expanded, 
  getReportData, 
  getInputData, 
  fetchReportData,
  clearReportData,
  applyDate,
  setLoading
} = useShared({ store });

const { fetchedDates, fetchDates } = useCalendar({ store }); 
const { getProcessedReportTitle, applyNewSort, applyNewFilter } = useTemplateReport({ store });
const { openReportWithSingleFilter, applyFiltersToQuery } = useEnquiries({ store });
const { applyFilterFromUrl } = useFilters();

onMounted(async () => {
  if (query.value.filter && inputData.value.area === REPORT_AREA_PRODUCT) {
    const filter = query.value.filter;
    if (!bubbleData.value) await fetchBubbleData();
    await applyFilterFromUrl(filter, 0);
    applyFiltersToQuery();
  }
  await loadReport();
  saveReportData();
});

onBeforeUnmount(() => {
  clearReportData();
  resetReportParameters();
  if (inputData.value.area === REPORT_AREA_PRODUCT) clearEnquiryFilters();
});

const fetchBubbleData = () => store.dispatch(`intelligence/enquiry/${FETCH_BUBBLE_DATA}`);
const clearEnquiryFilters = params => store.dispatch(`intelligence/enquiry/${CLEAR_ENQUIRY_FILTERS}`, params);

const rows = computed(() => store.state.intelligence.reportLazyLoad.rows);
const bubbleData = computed(() => store.state.intelligence.enquiry.bubbleData);
const dimensions = computed(() => inputData.value.visualInput[0].name);
const inputData = computed(() => getInputData(props.reportType));
const dateFilterTitle = computed(() => {
  const predefinedOptionValue = Object.values(dateFilterOptions.value).find(
    option => option.date_from === dateParams.value.date_from && option.date_to === dateParams.value.date_to
  );
  return `(${predefinedOptionValue ? predefinedOptionValue.text : 'Custom'})`;
});

const chartInput = computed(() => (inputData.value || []).visualInput.filter(item => item.includesChart));
const query = computed(() => vroute.query);

const dateParams = computed(() => {
  let dateObject = {};

  Object.keys(query.value).forEach(key => {
    if (DATE_FILTER_KEYS.includes(key)) {
      dateObject[key] = query.value[key];
    }
  });

  if (!Object.keys(dateObject).length) {
    dateObject = {
      date_from: defaultDateFrom.value,
      date_to: defaultDateTo.value,
    };
  }

  return dateObject;
});

const reportDateBoundaries = computed(() => {
  if (!reportData()) return '';
  const reportDataKeys = Object.keys(reportData());
  if (!reportDataKeys.length || !reportDataKeys.includes('date_range') || (reportDataKeys.includes('customers') && !reportData().customers.length))
    return '';
  const dateBoundaries = reportData().date_range;
  return `${new Date(dateBoundaries.start).toLocaleDateString('en-GB')} - ${new Date(dateBoundaries.end).toLocaleDateString('en-GB')}`;
});

watch(
  () => query.value,
  async (newQueryValue) => {
    if (newQueryValue) {
      await loadReport(); // Load report data with updated query
    }
  }
);

const { saveReportParameters, resetReportParameters } = useLazyLoading({ store });

async function loadReport(completeRefresh = true) {
  isReportDataLoaded.value = false;
  reportId.value = 0;
  setLoading(true);
  applyNewFilter(['limit', 100]);
  // apply the data from URL
  processDataFromUrl();
  currentSort.value = query.value.sort || inputData.value.defaultSortHeader;
  applyNewSort(currentSort.value);
  await fetchDates(dateParams.value);
  applyDate(fetchedDates.value);
  applyFiltersToQuery();
  // start fetching data from API
  reportId.value = await fetchReportData({
    reportType: props.reportType,
    completeRefresh,
  });
  isReportDataLoaded.value = true;
}

function processDataFromUrl() {
  inputData.value.filters.forEach(filter => {
    const queryParamKey = filter.queryParamKey;
    let queryParamValue = query.value[queryParamKey] || filter.default;

    if (filter.isNumber) {
      queryParamValue = parseInt(queryParamValue);
    }
    if (filter.type === 'date') {
      applyNewFilter(['date_from', query.value.date_from]);
      applyNewFilter(['date_to', query.value.date_to]);
    } else {
      applyNewFilter([queryParamKey, queryParamValue]);
    }
  });
}

function switchDataView(dataType) {
  expandedDataView.value = dataType;
}

async function handleNewSort(sort) {
  currentSort.value = sort;
  applyNewSort(currentSort.value);
  reportId.value = await fetchReportData({ reportType: props.reportType });
  saveReportData();
}

async function loadMoreData() {
  lazyLoading.value = true;
  reportId.value = await fetchReportData({ reportType: props.reportType });
  saveReportData();
  lazyLoading.value = false;
}

function saveReportData() {
  if (!reportData()) return;
  const res = Array.isArray(reportData()) 
    ? reportData() 
    : reportData().customers;
  saveReportParameters({ rows: [...res] });
}

function reportData() {
  return getReportData(reportId.value);
}

function handleCtaClick(item) {
  const params = { search: item.customer || item.name, reportType: FULL_PICTURE, dates: dateParams.value };
  // if it is product group related report type
  if (PRODUCT_GROUP_TEMPLATE_REPORT_TYPES.includes(props.reportType)) {
    openReportWithSingleFilter({ ...params, filterName: 'prod_group_level_1_desc', dimName: 'name' });
  }
  // if it is product related report type
  else if (PRODUCT_TEMPLATE_REPORT_TYPES.includes(props.reportType)) {
    openReportWithSingleFilter({ ...params, filterName: 'product_name', dimName: 'name' });
  }
  // search by customer name by default
  else openReportWithSingleFilter({ ...params, filterName: 'name', dimName: 'product_name' });
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.template-report {
  width: 100%;
  padding-right: var(--spacing-2);
  padding-left: var(--spacing-2);
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 480px;

  > div:not(.loading-spinner) {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 0 var(--spacing-1);
    }
  }
}

.no-carousel {
  display: flex;
  flex-flow: row nowrap;
  > .multi-chart {
    flex: 1;
  }
}

.header-section {
  position: relative;

  h3 {
    text-align: center;
    font-weight: var(--font-weight-semibold);
    margin-top: var(--spacing-2);
  }
}

.container-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-2);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-right: var(--spacing-3);
  }
}

.mobile-charts {
  display: none;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    display: block;

    .slider {
      z-index: 0;
    }
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

:deep(.date-filter) {
  max-width: 320px;
}

.data-section {
  flex: 1;
}

.expanded {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: var(--colour-utility-white);
  max-width: 100%;
  height: 100%;

  &.data {
    :deep(.template-report-charts) {
      display: none;
    }
  }

  &.graph {
    .data-section {
      display: none;
    }

    .template-report-charts {
      min-height: unset;
      height: calc(100vh - 262px);
      overflow: auto;
      margin: 0;
    }
  }

  .data-section {
    height: calc(100% - 162px);
    overflow: hidden;
  }

  .table-wrapper {
    max-height: 100%;
  }

  .footer-section {
    position: absolute;
    bottom: 0;
  }

  .export-dropdown-wrapper {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      display: none;
    }
  }

  &.customer-down-on-spend {
    .table-wrapper {
      height: 100%;
      max-height: calc(100vh - 192px);

      @media #{map-get($display-breakpoints, 'md-and-down')} {
        max-height: calc(100vh - 222px);
      }

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        max-height: calc(100vh - 230px);
      }

      @media #{map-get($display-breakpoints, 'xs-only')} {
        max-height: calc(100vh - 228px);
      }
    }
  }

  &.customer-with-zero-spend,
  &.customer-top-number-of-accounts-down {
    .table-wrapper {
      height: 100%;
      max-height: calc(100vh - 222px);

      @media #{map-get($display-breakpoints, 'md-and-down')} {
        max-height: calc(100vh - 255px);
      }

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        max-height: calc(100vh - 263px);
      }

      @media #{map-get($display-breakpoints, 'xs-only')} {
        max-height: calc(100vh - 260px);
      }
    }
  }

  &.customer-who-spent-in-previous {
    .table-wrapper {
      height: 100%;
      max-height: calc(100vh - 288px);

      @media #{map-get($display-breakpoints, 'md-and-down')} {
        max-height: calc(100vh - 320px);
      }

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        max-height: calc(100vh - 328px);
      }
    }
  }

  &.customer-not-buying-top-products {
    .table-wrapper {
      height: 100%;
      max-height: calc(100vh - 255px);

      @media #{map-get($display-breakpoints, 'md-and-down')} {
        max-height: calc(100vh - 287px);
      }

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        max-height: calc(100vh - 295px);
      }

      @media #{map-get($display-breakpoints, 'xs-only')} {
        max-height: calc(100vh - 292px);
      }
    }
  }
}
</style>