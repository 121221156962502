<template>
  <div class="button-group">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ButtonGroup',
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.button-group {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-weight: var(--font-weight-regular);
  border-bottom-left-radius: var(--border-radius-1);
  border-bottom-right-radius: var(--border-radius-1);

  :deep() {
    > div:not(:last-child),
    > button:not(:last-child) {
      margin-right: var(--spacing-2);
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      gap: var(--spacing-2);
      > div,
      > button {
        flex: 1;
        flex-wrap: wrap;
        white-space: nowrap;
      }
      > div:not(:last-child),
      > button:not(:last-child) {
        margin-right: 0;
      }
      > div > button {
        width: 100%;
        margin: 0;
      }
    }
  }
}
</style>
