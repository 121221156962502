import { ref, onMounted } from 'vue';
import Cookies from 'js-cookie';

export default function useFeatures() {
  const fullPbac = ref(false);
  const featureTreemap = ref(false);
  const featureTreemapDepth = ref(2);
  const getFeatureValue = key => Cookies.get(key);

  onMounted(() => {
    if (Cookies.get('full_pbac')) {
      fullPbac.value = true;
    }
    if (Cookies.get('feat_treemap')) {
      featureTreemap.value = true;
    }
    if (Cookies.get('feat_treemap_depth')) {
      featureTreemapDepth.value = parseInt(Cookies.get('feat_treemap_depth'), 10);
    }
  });
  
  return {
    getFeatureValue,
    fullPbac,
    featureTreemap,
    featureTreemapDepth,
  };
}