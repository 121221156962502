<template>
  <div>
    <EnquiryNav
      :active-area-prop="reportArea"
      :report-type="reportType"
      :dates="dateFilter"
      :hide-enquiry-buttons="isTemplateReport"
    />
    <img
      src="@/intelligence/views/ActivityReport/NAPA_Activity_Report.png"
      alt=""
      style="max-width: 100%;margin-top:-16px;"
    >
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import { REPORT_AREA_PERFORMANCE} from '@/intelligence/store/data/areas';
import { DATE_FILTER_KEYS, DATE_FILTER_OPTIONS } from '@/intelligence/store/data/dateFilters';
import { APPLY_NEW_DATE_RANGE, FETCH_DATES, FETCH_STAGES_DATA, SET_FILTER_TITLE } from '@/intelligence/store/actionType';
import { ACTIVITY_REPORT } from '@/intelligence/store/data/reportTypes';
import isRolldate from '@/intelligence/store/utils/isRolldate';
import { t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'SalesOpportunityView',
  components: {
    EnquiryNav,
  },
  props: {
    reportArea: {
      type: String,
      default: REPORT_AREA_PERFORMANCE
    }
  },
  emits: ['filterSelected', 'openModal'],
  data() {
    return {
      dateFilter: {
        date_from: '',
        date_to: '',
      },
      dateFilterOptions: DATE_FILTER_OPTIONS,      
      dateFiltersKeys: DATE_FILTER_KEYS,
      defaultDateFrom: 'today/y',
      defaultDateTo: 'today', 
      isTemplateReport: true,
      reportType: ACTIVITY_REPORT
    };
  },  
  computed: {
    ...mapState({
      fetchedDates: state => state.intelligence.calendar.fetchedDates,
      reportKey: state => state.intelligence.shared.reportRefreshCounter
    }),
    ...mapGetters({
      getInputData: 'intelligence/shared/getInputData',
    }),
    inputData() {
      return this.getInputData(this.reportType);
    },
    query() {
      return this.$route.query;
    },  
    dateParams() {
      let data = {};

      Object.keys(this.query).forEach(key => {
        if (this.dateFiltersKeys.includes(key)) {
          data[key] = this.query[key];          
        }
      });

      if (!Object.keys(data).length) {
        data = {
          date_from: this.defaultDateFrom,
          date_to: this.defaultDateTo,
        };
      }

      return data;
    },  
  },
  mounted() {
    this.setDateFilterTitle();
  },
  methods: {
    ...mapActions({
      fetchDates: `intelligence/calendar/${FETCH_DATES}`,
      applyNewDateRage: `intelligence/performanceInsight/${APPLY_NEW_DATE_RANGE}`,
      fetchStagesData: `intelligence/performanceInsight/${FETCH_STAGES_DATA}`,
      setFilterTitle: `intelligence/performanceInsight/${SET_FILTER_TITLE}`      
    }),
    filterSelected(value) {
      this.$emit('filterSelected', value);
    },
    showMenu() {
      this.$emit('openModal');
    },
    setDateFilterTitle() {
      const predefinedOptionValue = Object.values(this.dateFilterOptions).find(
        option => option.date_from === this.dateParams.date_from && option.date_to === this.dateParams.date_to
      );
      const dateFilterTitle = predefinedOptionValue ? predefinedOptionValue.text : t('Custom');

      this.setFilterTitle(dateFilterTitle);
    },
    async dateRangeChangeHandler(dateObject) {
      const dateValues = Object.values(dateObject);
      const data = {};
      
      if (dateValues.includes(null))
        return;

      if (dateValues.includes(undefined))
        dateObject = this.dateParams;

      Object.keys(this.query).forEach(key => {
        if (!this.dateFiltersKeys.includes(key)) {
          data[key] = this.query[key];
        }
      });

      const route = await this.$router.resolve({
        query: {
          ...data,
          ...dateObject,
        },
      });
      navigateToUrl(route.href);

      let dates = dateObject;
      if (isRolldate(dateObject)) {
        await this.fetchDates(dateObject);
        dates = this.fetchedDates;
      }
      this.applyNewDateRage(dates);
      await this.fetchStagesData();

      this.setDateFilterTitle();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/shared/assets/scss/_variables";
.sales-opportunity-report {
  padding-right: var(--spacing-4);
  padding-left: var(--spacing-4);
  width: 100%;
  margin: auto;
  position: relative;
  background: var(--colour-panel-g-0);

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    min-width: 750px;
  }
  
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: var(--spacing-2);
  }
}
.sidebar.hide {
  .date-filter {
    display: none;
    opacity: 0;
  }
}

:deep(.area.performance .container) {
  padding-bottom: 100px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-bottom: var(--spacing-2);
  }
}
</style>