<template>
  <CustomCard
    purpose="reversed"
    class="flow set-permissions"
  >
    <div
      v-if="showHeading"
      class="heading-and-controls"
    >
      <h3 class="fw-sbld">
        {{
          t(
            `${tenantDetails.name || ''} Users (${totalUserCount})`,
            'tenant_item_count',
            {
              interpolations: {
                tenant: tenantDetails.name,
                item: t('Users'),
                count: totalUserCount,
              }
            }
          )
        }}
      </h3>
      <div class="actions">
        <CustomButton
          v-if="deleteUserList.length && permissions.delete"
          purpose="action"
          small
          @on-click="deleteUsers"
        >
          {{
            t(
              `Delete users (${deleteUserList.length})`,
              'delete_users_count',
              {
                interpolations: {
                  count: deleteUserList.length
                }
              }
            )
          }}
        </CustomButton>
        <CustomButton
          v-if="permissions.create"
          purpose="action"
          small
          @on-click="addUser"
        >
          {{ t('Add a user') }}
        </CustomButton>
      </div>
    </div>
    <router-view
      :delete-user-key="deleteUserKey"
      @delete-user-list="setupDeleteUserList"
    />
  </CustomCard>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import { CustomCard, CustomButton, } from '@sales-i/dsv3';
import { useRoute, } from 'vue-router';
import { user_management } from '@/shared/store/data/policies';
import usePermissions from '@/shared/composables/usePermissions';
import { GET_SUMMARY, DELETE_BY_ID, SET_CONFIRMATION_MODAL } from '@/shared/store/actionType';
import { baseUrl, companyArea, permissionsSection, usersSection } from '@/admin/router/urlBits';
import { navigateToUrl } from 'single-spa';

const store = useStore();
const vroute = useRoute();
const { getPermissions } = usePermissions();

const permissions = computed(() => getPermissions(user_management));
const deleteUserKey = ref(0);
const deleteUserList = ref([]);

const totalUserCount = computed(() => store.getters['admin/users/totalCount']);
const tenantDetails = computed(() => store.state.system.tenant.data);
const getUserSummary = params => store.dispatch(`admin/users/${GET_SUMMARY}`, params);
const deleteUsersById = params => store.dispatch(`admin/users/${DELETE_BY_ID}`, params);
const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);

const showHeading = computed(() => {
  return !(vroute.path.includes('add') || vroute.path.includes('edit'));
});

function addUser() {
  navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}/add`);
}

function deleteUsers() {
  let userNames = '';
  let userIds = [];
  for (let i = 0, len = deleteUserList.value.length; i < len; i++) {
    const user = deleteUserList.value[i];
    if (i > 0) userNames += ', ';
    userNames += user.display_name;
    userIds.push(user.id);
  }
  showConfirmationModal({
    message: t(
      `Do you really want to delete ${userNames}?`,
      'do_you_really_want_to_delete_variable',
      { interpolations: { variable: `${userNames}` }, }
    ),
    updatedMessage: t(
      `You have deleted ${userNames}`,
      'you_have_deleted_variable',
      { interpolations: { variable: userNames }, }
    ),
    updateFunction: async () => {
      try {
        await deleteUsersById(userIds);
        getUserSummary();
        deleteUserList.value = [];
        deleteUserKey.value++;
        return true;
      } catch (e) {
        return false;
      }
    },
    finishFunction: () => {
      return true;
    },
    closeFunction: () => {
      return true;
    },
  });
}

function setupDeleteUserList(list) {
  deleteUserList.value = list;
}

onMounted(() => {
  getUserSummary();
});
</script>

<style lang="scss" scoped>
.set-permissions {
  margin: var(--spacing-3);

  :deep(.c-card--footer) {
    padding: 0;
  }

  .heading-and-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .actions {
    display: flex;
    gap: var(--spacing-1);
  }
}
</style>
