import OpportunitiesTabView from '@/crm/views/Opportunities/OpportunitiesTabView.vue';
import OpportunityView from '@/crm/views/Opportunities/OpportunityView.vue';
import AddEditOpportunityModalView from '@/crm/views/Opportunities/AddEditOpportunityModalView.vue';
import OpportunityDetailsRoute from './OpportunityDetails';
import OpportunitiesPostsRoute from './OpportunitiesPosts';
import OpportunityCustomSections from './OpportunityCustomSections';
import { baseUrl, opportunitiesArea as area } from '@/crm/router/urlBits';

export default [
  {
    path: `${baseUrl}/${area}`,
    component: OpportunitiesTabView,
    children: [
      // adding new opportunity
      {
        name: 'AddOpportunityModalRoute',
        path: ':addedit(add)',
        component: AddEditOpportunityModalView,
      },
      {
        name: 'EditOpportunityModalRoute',
        path: ':id(\\d+)/:addedit(edit)',
        component: AddEditOpportunityModalView,
        props: route => ({id: route.params.id,}),
      },
    ],
  },
  // id provided, view
  {
    path: `${baseUrl}/${area}/:id(\\d+)`,
    component: OpportunityView,
    children: [
      OpportunityDetailsRoute,
      OpportunitiesPostsRoute,
      OpportunityCustomSections
    ],
  },
];
