import {
  SET_VISIBLE,
  CLEAR_VISIBLE,
  SET_ACTIVE_AREA,
} from '@/shared/store/actionType';
import { REPORT_AREA_PERFORMANCE } from '@/intelligence/store/data/areas';

const state = () => ({
  visible: false,
  activeArea: REPORT_AREA_PERFORMANCE,
});

const getters = {};

const mutations = {
  [SET_VISIBLE]: (state) => {
    state.visible = true;
  },
  [CLEAR_VISIBLE]: (state) => {
    state.visible = false;
  },
  [SET_ACTIVE_AREA]: (state, area) => {
    state.activeArea = area;
  },
};

const actions = {
  [SET_VISIBLE] ({ commit }, area) {
    if (area) commit(SET_ACTIVE_AREA, area);
    commit(SET_VISIBLE);
  },
  [SET_ACTIVE_AREA] ({ commit }, area) {
    commit(SET_ACTIVE_AREA, area);
  },
  [CLEAR_VISIBLE] ({ commit }) {
    commit(CLEAR_VISIBLE);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
