<template>
  <div class="flow">
    <Field
      id="date-end-is"
      type="select"
      :options="periodOptions"
      :label="t('On, before or after')"
      :value="filter?.is"
      @input="handlePeriodTypeInput"
    />
    <DateRange
      v-if="filter?.is === 'BETWEEN'"
      :key="filter?.id"
      :model-value="dateRangeValue"
      @update:model-value="handleDateRangeChange"
    />
    <Field
      v-else-if="filter?.is"
      :id="`selected-date-${filter?.id}`"
      type="date"
      :value="dateRangeValue.date_to"
      @input="handleDatePick"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { t } from '@sales-i/utils';
import { DateTime } from 'luxon';
import Field from '@/shared/components/Form/Field.vue';
import DateRange from '@/intelligence/components/Date/DateRange';
import useCalendar from '@/intelligence/composables/useCalendar';

const props = defineProps({
  filter: {
    type: Object,
    default: undefined
  }
});

const emit = defineEmits(['update:filter']);

const { fetchDates } = useCalendar();

const dateRangeValue = computed(() => ({
  date_from: props.filter?.value?.at(-2) || DateTime.now().toISODate(),
  date_to: props.filter?.value?.at(-1) || DateTime.now().toISODate(),
  value: 'custom-datepicker'
}));

const periodOptions = [
  { text: t('ON'), value: '=' },
  { text: t('BEFORE'), value: '<' },
  { text: t('AFTER'), value: '>' },
  { text: t('BETWEEN'), value: 'BETWEEN' },
];

function handlePeriodTypeInput(is) {
  const { date_from, date_to } = dateRangeValue;
  emit('update:filter', {
    ...props.filter,
    is,
    value: is === 'BETWEEN'? [date_from, date_to] : [date_to],
  });
}

async function handleDateRangeChange(date) {
  const { date_from, date_to } = await fetchDates(date, false);
  emit('update:filter', {
    ...props.filter,
    value: [date_from, date_to],
  });
}

function handleDatePick(value) {
  emit('update:filter', {
    ...props.filter,
    value: [value],
  });
}
</script>