/* eslint-disable no-unused-vars */
import {
  GET_ALL,
  GET_ALL_LOADING,
  GET_ALL_ERROR,
  CLEAR_ALL
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
// import querystring from 'querystring';


// initial state
const state = () => ({
  all: {
    data: [],
    fields: [],
    loaded: false,
    loading: false,
    error: undefined,
  },
  selected: {
    data: {},
    loaded: false,
    loading: false,
    updated: false,
    error: undefined,
  }
});

// getters
const getters = {
  businessAreaOptions: (state) => {
    if (!state.all || !Array.isArray(state.all.data)) return [];
    return state.all.data.map(s => ({text: s.name, value: s.id}));
  }
};

// mutations
const mutations = {
  [GET_ALL]: (state, data) => {
    state.all.data = data;
    state.all.loaded = true;
    state.all.loading = false;
    state.all.error = undefined;
  },
  [GET_ALL_LOADING]: (state) => {
    state.all.loaded = false;
    state.all.loading = true;
    state.all.error = undefined;
  },
  [GET_ALL_ERROR]: (state, error) => {
    state.all.loaded = false;
    state.all.loading = false;
    state.all.error = error;
  },
  [CLEAR_ALL]: (state) => {
    state.all.data = [];
    state.all.loaded = false;
    state.all.loading = false;
    state.all.error = undefined;
  },
};

// actions
const actions = {
  // TODO: Change this to use a better API call, but demo needs must
  [GET_ALL]: async ({ commit, },) => {
    try {
      commit(GET_ALL_LOADING);
      const data = await httpRequest('get', 'config/schema/opportunities', {}, {version: '2',});
      let businessAreas = [];
      data.sections.forEach(section => {
        let options = section.fields.find(field => field.description === 'business_area_id');
        if (options) {
          businessAreas = options.list_options;
        }
      });
      commit(GET_ALL, businessAreas);
      return true;
    } catch (error) {
      commit(GET_ALL_ERROR, error);
      return false;
    }
  },
  [CLEAR_ALL]: ({ commit, }) => {
    commit(CLEAR_ALL);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
