<template>
  <div class="overview-card">
    <div class="overview-card-inner">
      <div
        v-if="permissions?.read"
        class="overview-header"
      >
        <IconButton
          class="datepicker-button button"
          :class="{ active: isDateFilterActive }"
          icon-name="calendar"
          :icon-color="isDateFilterActive ? 'var(--colour-utility-white)' : 'var(--colour-utility-action)'"
          purpose="transparent"
          icon-only
          :icon-size="26"
          @click.stop="toggleDateFilter"
        />
        <div class="middle-section">
          <div class="title">
            <h3>{{ isTenantOverview ? t('Overall') : '' }} {{ t('Performance') }}</h3>
          </div>
          <div class="dates-container">
            <div
              v-for="(date, index) in dateRanges"
              :key="index"
              class="date-ranges"
            >
              <span class="indicator">{{ index + 1 }}:</span>
              <IconBase
                icon-name="calendar"
                :height="24"
                :width="24"
              />
              <span class="date-range">{{ date }}</span>
            </div>
          </div>
        </div>
        <div class="rollup-wrap">
          <Field
            v-if="children.data?.length"
            small
            type="checkbox"
            :label="t('Include children')"
            :value="includeChildCustomerData"
            @input="rolldown"
          />
        </div>
      </div>
      <DatePickerView
        v-if="isDateFilterActive"
        @set-active-date="setDate"
      />
      <article v-show="!isDateFilterActive">
        <CustomerSegments
          :customer-id="selectedCustomer.id"
          class="segments"
        />
        <CustomerSavedAlerts
          v-if="!isTenantOverview && shouldShowAlerts"
          class="mx-3"
          :customer-details="selectedCustomer"
          :customer-alerts="customerAlerts"
          :children-names="childrenNames"
          :children-ids="childrenIds"
        />

        <KPIs
          v-if="!isTenantOverview && permissions?.read"
          :variance-summary-data="varianceSummaryData"
          :variance-summary-loading="varianceSummaryLoading"
        />

        <div
          v-if="!isTenantOverview && permissions?.read"
          class="enquiry-links-container bg-blue-light"
        >
          <h2>{{ t('Intelligence') }}</h2>
          <h4>{{ t('View enquiries data for this customer') }}</h4>
          <div class="enquiry-links">
            <CustomButton
              v-for="(enquiry, index) in enquiriesInputItems"
              :key="index"
              class="enquiry-link"
              purpose="text"
              @click="goToEnquiry(enquiry)"
            >
              <IconBase
                icon-name="dimensions"
                width="32"
                height="32"
              />
              <span>{{ enquiry.title }}</span>
            </CustomButton>
          </div>
        </div>
        <div
          v-else-if="permissions?.read"
          class="enquiry-links-container bg-blue-light"
        >
          <h2>{{ t('Top 5 Reports') }}</h2>
          <h4>{{ t('Keep an eye on the important stuff!') }}</h4>
        </div>
        <div
          v-if="permissions?.read"
          class="charts-section"
        >
          <div
            v-for="(item, i) in visuals"
            :key="i"
            class="item"
          >
            <h3>{{ item.title }}</h3>
            <div class="dates-container">
              <div
                v-for="(date, index) in dateRanges"
                v-show="!index || item.usesTwoDateRanges"
                :key="index"
                class="date-ranges"
              >
                <span
                  v-if="item.usesTwoDateRanges"
                  class="indicator"
                >{{ index + 1 }}:</span>
                <span class="date-range">{{ date }}</span>
              </div>
            </div>
            <component
              :is="item.component"
              v-if="!item.loading && item.hasData"
              :id="`visual-${item.id}`"
              class="item-component"
              :report-id="item.id"
              :report-type="item.reportType"
              :dates="dates"
              :chart-input="item.additionalInputData"
              :include-child-customer-data="includeChildCustomerData"
              :show-view-more="!iframe"
              @view-more="goToVarianceProduct(item.getAdditionalParams)"
            />
            <BufferImage
              v-else-if="item.loading"
              class="loading-spinner"
              color="var(--colour-utility-black)"
              float="center"
            />
            <NoResults
              v-else
              class="no-results"
            />
          </div>
        </div>
        <section
          v-if="showOpportunities"
          class="mx-3"
        >
          <div class="enquiry-links-container bg-blue-light">
            <h2>{{ t('Missed and Potential Opportunities') }}</h2>
            <h4>{{ t('Recommended products for your customer base') }}</h4>
          </div>
          <Opportunities
            v-if="selectedCustomer.name"
            hidden-header
            :customer-id="selectedCustomer.id"
            :is-rolled-up="includeChildCustomerData"
          />
        </section>
        <div
          v-if="!isTenantOverview && permissions?.read"
          class="footer-section"
        >
          <div class="insight p-3">
            <img
              srcset="@/shared/assets/image/crm/insight_graph.svg"
              alt=""
            >
            <div class="insight-text">
              <h2>{{ t('Sell Smart with insights') }}</h2>
              <template v-if="selectedCustomer?.name">
                <p>
                  {{ t('Explore sales performance for') }}
                </p>
                <p class="fw-sbld customer-name">
                  {{ selectedCustomer.name }}
                </p>
              </template> 
              <ExportModal
                v-if="iframe"
                :title="t('Export Snapshot')"
                :customer-object="selectedCustomer"
                :date-params="dateParams"
              />
              <CustomButton
                v-else
                purpose="action"
                @on-click="openInsights"
              >
                {{ t('View Snapshot Insights') }}
              </CustomButton>
            </div>
            <img
              srcset="@/shared/assets/image/crm/insight_bars.svg"
              alt=""
            >
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, shallowRef, watch } from 'vue';
import { useStore } from 'vuex';
import usePermissions from '@/shared/composables/usePermissions';
import { CustomButton, IconBase, IconButton, BufferImage } from '@sales-i/dsv3';
import {
  MATRIX,
  FULL_PICTURE,
  SALES_VS_GAPS,
  VARIANCE,
  PRODUCTS_PURCHASED_FREQUENTLY,
  PRODUCTS_PURCHASED_FREQUENTLY_TITLE,
  PRODUCT_GROUP_PURCHASE_ANALYSIS,
  MONTHLY_PURCHASES_BY_VALUE,
  VARIANCE_ENQUIRY_BY_QUANTITY_TITLE,
  HIGHEST_SELLING_PRODUCTS,
  HIGHEST_REVENUE_CUSTOMER,
  SNAPSHOT_SUMMARY,
} from '@/intelligence/store/data/reportTypes';
import { intelligence_enquiries } from '@/shared/store/data/policies';
import DatePickerView from '@/intelligence/components/Date/DatePickerView';
import PieChartComponent from '@/intelligence/components/SnapshotReport/Charts/PieChart';
import MultiBarChartComponent from '@/intelligence/components/SnapshotReport/Charts/MultiBarChart';
import LineBarChartComponent from '@/intelligence/components/SnapshotReport/Charts/LineBarChart';
import VarianceMiniTableComponent from '@/intelligence/components/Variance/VarianceMiniTable';
import TemplateReportChartComponent from '@/intelligence/components/TemplateReport/TemplateReportChart';
import ExportModal from '@/intelligence/components/Shared/ExportModal.vue';
import CustomerSegments from '@/aiml/components/Churn/CustomerSegments.vue';
import { dates as dFuncs, t } from '@sales-i/utils';
import {
  REPORTS_KPI_AVERAGE_PRICE,
  REPORTS_KPI_COST,
  REPORTS_KPI_COST_TITLE,
  REPORTS_KPI_COUNT,
  REPORTS_KPI_COUNT_TITLE,
  REPORTS_KPI_GROSS_PROFIT,
  REPORTS_KPI_PROFIT,
  REPORTS_KPI_PROFIT_TITLE,
  REPORTS_KPI_QUANTITY,
  REPORTS_KPI_QUANTITY_TITLE,
  REPORTS_KPI_REVENUE,
  REPORTS_KPI_SALES_VALUE,
  REPORTS_KPI_VALUE_TITLE,
} from '@/intelligence/store/data/kpis';
import { useRouter } from 'vue-router';
import NoResults from '@/intelligence/components/Shared/NoResults';
import { CURRENCY_SYMBOL, PERCENT_SYMBOL } from '@/intelligence/store/data/reportsInput';
import Field from '@/shared/components/Form/Field.vue';
import { useCustomer } from '@/crm/composables/useCustomer';
import useShared from '@/intelligence/composables/useShared';
import { navigateToUrl } from 'single-spa';
import CustomerSavedAlerts from './CustomerSavedAlerts.vue';
import KPIs from './KPIs.vue';
import useSavedQueries from '@/intelligence/composables/useSavedQueries';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import useTemplateReport from '@/intelligence/composables/useTemplateReport';
import Opportunities from '@/intelligence/views/Opportunities/Opportunities.vue';

const store = useStore();
const { getPermissions } = usePermissions();
const permissions = computed(() => getPermissions(intelligence_enquiries));
const vrouter = useRouter();

const PieChart = shallowRef(PieChartComponent);
const MultiBarChart = shallowRef(MultiBarChartComponent);
const LineBarChart = shallowRef(LineBarChartComponent);
const VarianceMiniTable = shallowRef(VarianceMiniTableComponent);
const TemplateReportChart = shallowRef(TemplateReportChartComponent);

// props
const props = defineProps({
  isTenantOverview: {
    type: Boolean,
    default: false,
  },
  children: {
    type: Object,
    default: () => ({}),
  },
  includeChildCustomerData: {
    type: Boolean,
    default: false,
  },
  dateParams: {
    type: Object,
    default: () => ({}),
  },
  dates: {
    type: Object,
    default: () => ({}),
  },
  showOpportunities: {
    type: Boolean,
    default: false
  }
});

// emits
const emit = defineEmits(['goToEnquiry', 'goToVarianceProduct', 'refreshParent', 'dateUpdated']);

// data
const isDateFilterActive = ref(false);
const varianceSummaryId = ref(1);
const varianceSummaryLoading = ref(true);
const varianceSummaryData = ref([]);

const customerAccountNoValue = ref(0);
const customerAccountNoBubbleId = ref('');
const productBubbleId = ref('');
const productDrills = ref({});
const customerDrills = ref({});

const { getInputData, getReportData, fetchReportData, clearReportData } = useShared({ store });
const { getCustomerAccountNoBubbleId, getProductBubbleId, fetchBubbleIds } = useTemplateReport({ store });
const { applyFiltersToQuery, setFilter, clearEnquiryFilters } = useEnquiries({ store });

const customerOverviewVisuals = ref([
  {
    id: 1,
    loading: true,
    reportType: MONTHLY_PURCHASES_BY_VALUE,
    title: t('Sales Comparison'),
    component: MultiBarChart,
    usesTwoDateRanges: true,
    isAvailable: reportData =>
      reportData &&
      reportData.rows &&
      reportData.rows.some(row => row.value_1 !== 0 || row.value_2 !== 0 || row.value_variance !== 0),
    getAdditionalParams: () => ({
      snapshotBubbleValue: formatData(selectedFilterValues.value),
      snapshotBubbleId: customerAccountNoBubbleId.value,
    }),
  },
  {
    id: 2,
    loading: true,
    reportType: VARIANCE,
    title: VARIANCE_ENQUIRY_BY_QUANTITY_TITLE,
    component: VarianceMiniTable,
    usesTwoDateRanges: true,
    isAvailable: reportData => reportData && reportData.row_count,
    getAdditionalParams: () => ({
      snapshotBubbleValue: selectedFilterValues.value,
      snapshotBubbleId: customerAccountNoBubbleId.value,
      productBubbleId: productBubbleId.value,
      drills: productDrills.value,
      currentSortHeader: 'quantity_1',
      currentSortOption: 'desc',
      limit: 5,
    }),
  },
  {
    id: 3,
    loading: true,
    reportType: PRODUCTS_PURCHASED_FREQUENTLY,
    title: PRODUCTS_PURCHASED_FREQUENTLY_TITLE,
    component: PieChart,
    usesTwoDateRanges: false,
    isAvailable: reportData => reportData?.length,
    getAdditionalParams: () => ({
      snapshotBubbleValue: selectedFilterValues.value,
      snapshotBubbleId: customerAccountNoBubbleId.value,
    }),
  },
  {
    id: 4,
    loading: true,
    reportType: PRODUCT_GROUP_PURCHASE_ANALYSIS,
    title: t('Purchase Analysis'),
    component: LineBarChart,
    usesTwoDateRanges: true,
    isAvailable: reportData => reportData?.length,
    getAdditionalParams: () => {
      return {
        snapshotBubbleValue: customerAccountNoValue.value,
        snapshotBubbleValueId: props.includeChildCustomerData
          ? childrenAccountNumbersForQuery.value
          : `"${customerAccountNoValue.value || ''}"`,
        snapshotBubbleId: customerAccountNoBubbleId.value,
      };
    },
  },
]);
const tenantVisuals = ref([
  {
    id: 1,
    loading: true,
    reportType: HIGHEST_REVENUE_CUSTOMER,
    title: t('Top 5 Customers by Revenue'),
    component: TemplateReportChart,
    usesTwoDateRanges: false,
    isAvailable: reportData => reportData?.length,
    getAdditionalParams: () => ({
      limit: 5,
    }),
    additionalInputData: {
      prefix: CURRENCY_SYMBOL,
      key: REPORTS_KPI_REVENUE,
      colorSet: 1,
    },
  },
  {
    id: 2,
    loading: true,
    reportType: HIGHEST_SELLING_PRODUCTS,
    title: t('Top 5 Products by Revenue'),
    component: TemplateReportChart,
    usesTwoDateRanges: false,
    isAvailable: reportData => reportData?.length,
    getAdditionalParams: () => ({
      currentSortHeader: 'revenue',
      limit: 5,
    }),
    additionalInputData: {
      prefix: CURRENCY_SYMBOL,
      key: REPORTS_KPI_REVENUE,
      colorSet: 3,
    },
  },
  {
    id: 3,
    loading: true,
    reportType: VARIANCE,
    title: t('Top 5 Customer Variance'),
    component: VarianceMiniTable,
    usesTwoDateRanges: true,
    isAvailable: reportData => reportData?.row_count,
    getAdditionalParams: () => ({
      currentSortHeader: 'quantity_1',
      currentSortOption: 'desc',
      drills: customerDrills.value,
      limit: 5,
      snapshotBubbleId: customerAccountNoBubbleId.value,
    }),
  },
  {
    id: 4,
    loading: true,
    reportType: VARIANCE,
    title: t('Top 5 Products Variance'),
    component: VarianceMiniTable,
    usesTwoDateRanges: true,
    isAvailable: reportData => reportData?.row_count,
    getAdditionalParams: () => ({
      currentSortHeader: 'quantity_1',
      currentSortOption: 'desc',
      drills: productDrills.value,
      limit: 5,
      productBubbleId: productBubbleId.value,
    }),
  },
]);
const kpisTemplate = [
  {
    key: REPORTS_KPI_SALES_VALUE,
    title: REPORTS_KPI_VALUE_TITLE,
    prefix: true,
  },
  {
    key: REPORTS_KPI_COST,
    title: REPORTS_KPI_COST_TITLE,
    prefix: true,
  },
  {
    key: REPORTS_KPI_PROFIT,
    title: REPORTS_KPI_PROFIT_TITLE,
    prefix: true,
  },
  {
    key: REPORTS_KPI_GROSS_PROFIT,
    title: t('GP'),
    prefix: false,
    suffix: PERCENT_SYMBOL,
  },
  {
    key: REPORTS_KPI_AVERAGE_PRICE,
    title: t('AVG Price'),
    prefix: true,
  },
  {
    key: REPORTS_KPI_QUANTITY,
    title: REPORTS_KPI_QUANTITY_TITLE,
    prefix: false,
  },
  {
    key: REPORTS_KPI_COUNT,
    title: REPORTS_KPI_COUNT_TITLE,
    prefix: false,
  },
];

// store: state
const { selectedCustomer } = useCustomer();
const iframe = computed(() => store.state.system.iframe);

// computed
const enquiriesInputItems = computed(() => {
  const reports = [];
  if (permissions.value?.read) {
    reports.push(MATRIX);
    reports.push(FULL_PICTURE);
    reports.push(SALES_VS_GAPS);
    reports.push(VARIANCE);
  }
  return reports.map(report => getInputData([report]));
});
const dateRanges = computed(() => {
  const format = dFuncs.formatWithOrdinals;
  const { date_from, date_to, date_from2, date_to2 } = props.dateParams;
  return [`${format(date_from)} - ${format(date_to)}`, `${format(date_from2)} - ${format(date_to2)}`];
});

// Computed properties below are for children accounts
const childrenIds = computed(() => props.children.data?.map(child => child.id));
const { customerAlerts, shouldShowAlerts, getCustomerAlerts } = useSavedQueries({ store });

const childrenFilterValues = computed(() => {
  if (Array.isArray(props.children?.data)) {
    return props.children.data.map(child => child.account_number);
  }
  return [];
});
const selectedFilterValues = computed(() => {
  return props.includeChildCustomerData 
    ? [selectedCustomer.value.account_number, ...childrenFilterValues.value] 
    : [selectedCustomer.value.account_number];
});
const customerFilterValues = computed(() => {
  const customerValue = {
    value: selectedCustomer.value.account_number, 
    id: selectedCustomer.value.account_number
  };
  return props.includeChildCustomerData
    ? [customerValue, ...childrenFilterValues.value.map(account_number => ({ value: account_number, id: account_number }))]
    : [customerValue];
});
const childrenAccountNumbersForQuery = computed(() => {
  let accountNumbers = [];
  if (Array.isArray(props.children?.data)) {
    accountNumbers = props.children.data.map(child => `"${child.account_number}"`);
  }
  accountNumbers.unshift(`"${customerAccountNoValue.value}"`);
  return accountNumbers.join(',');
});

const visuals = computed(() => props.isTenantOverview ? tenantVisuals.value : customerOverviewVisuals.value);

// lifecycle hooks
onMounted(async () => {
  if (!permissions.value?.read) return;
  if (!customerAccountNoBubbleId.value) await fetchBubbleIds();
  productBubbleId.value = getProductBubbleId.value;
  productDrills.value = getDrills(getProductBubbleId.value);
  customerAccountNoBubbleId.value = getCustomerAccountNoBubbleId.value;
  customerDrills.value = getDrills(getCustomerAccountNoBubbleId.value);
  await prepareDataForFetch();
  // fetching data for all charts and summaries
  fetchVarianceSummaryData();
  fetchVisuals();
  prepareSavedAlertsVisuals();
});

onUnmounted(() => {
  clearEnquiryFilters();
  clearReportData();
});

watch(
  () => props.dateParams,
  async () => {
    await prepareDataForFetch();
    fetchVarianceSummaryData();
    fetchVisuals();
    toggleDateFilter();
  }
);

// methods
async function prepareDataForFetch() {
  // selecting the customer bubble to drill through (for variance reports)
  if (!props.isTenantOverview) processFilters();
}

function prepareSavedAlertsVisuals() {
  if (props.isTenantOverview) return;
  const id = selectedCustomer.value.id;
  const children = childrenIds.value;
  getCustomerAlerts({id, children});
}

function processFilters() {
  // applying customer to filters for snapshot-like charts
  customerAccountNoValue.value = selectedCustomer.value.account_number;
  // applying customer filter for enquiries like Variance
  clearEnquiryFilters();
  setFilter([
    {
      title: 'Filter 1',
      selectedFilterType: 'Customer',
      selectedFieldTitle: t('Account Number'),
      description: `${t('Customer')} > ${t('Account Number')}`,
      id: customerAccountNoBubbleId.value,
      selected_values: customerFilterValues.value,
      filter_type: 'include',
    },
    0,
  ]);
  applyFiltersToQuery(props.includeChildCustomerData);
}

function goToEnquiry(enquiry) {
  const { urlCode } = enquiry;
  if (!iframe.value) {
    const name = urlCode,
      query = { customer: selectedCustomer.value.name },
      route = vrouter.resolve({ name, query });
    window.open(route.href, '_blank');
  } else {
    emit('goToEnquiry', urlCode);
  }
}

function getDrills(bubbleId) {
  return { 'level-1': { dimensions: [{ id: bubbleId, values: [] }] } };
}

function goToVarianceProduct(additionalParams) {
  const {
    snapshotBubbleId,
    snapshotBubbleValue,
    productBubbleId,
    currentSortHeader,
    currentSortOption,
  } = additionalParams();
  const { date_from, date_to, date_from2, date_to2 } = props.dates;
  const { name, account_number } = selectedCustomer.value;
  const query =
    iframe.value && !props.isTenantOverview
      ? {
        date_from,
        date_to,
        date_from2,
        date_to2,
        ...(productBubbleId && snapshotBubbleId && { '1-dim1': productBubbleId }),
        ...(currentSortHeader && { sort: `${currentSortHeader}:${currentSortOption || 'desc'}` }),
      }
      : {
        date_from,
        date_to,
        date_from2,
        date_to2,
        ...(snapshotBubbleId && { '1-dim1': snapshotBubbleId }),
        ...(snapshotBubbleValue && { '1-dim1values': `${name}:${account_number}` }),
        ...(productBubbleId && snapshotBubbleId && { '2-dim1': productBubbleId }),
        ...(productBubbleId && !snapshotBubbleId && { '1-dim1': productBubbleId }),
        ...(currentSortHeader && { sort: `${currentSortHeader}:${currentSortOption || 'desc'}` }),
      };
  if (iframe.value) {
    emit('goToVarianceProduct', query);
  } else {
    const name = `${VARIANCE}-report`;
    const route = vrouter.resolve({ name, query });
    window.open(route.href, '_blank');
  }
}

function openInsights() {
  const route = vrouter.resolve({
    name: SNAPSHOT_SUMMARY,
    query: { customer: selectedCustomer.value.name },
  });
  navigateToUrl(route.href);
}

async function setDate(dateObject) {
  emit('dateUpdated', dateObject);
}

function toggleDateFilter() {
  isDateFilterActive.value = !isDateFilterActive.value;
}

async function fetchVarianceSummaryData() {
  varianceSummaryLoading.value = true;
  varianceSummaryId.value = await fetchReportData({
    reportType: VARIANCE,
    customParams: {
      currentSortHeader: 'sales_value_variance',
      drills: customerDrills.value,
    },
  });
  const responseObject = getReportData(varianceSummaryId.value)?.summary;

  varianceSummaryData.value = kpisTemplate.map(kpi => {
    const { key, title, prefix, suffix } = kpi;

    return {
      value1: responseObject[key].values[0],
      value2: responseObject[key].values[1],
      variance: responseObject[key].variance,
      variance_percent: responseObject[key].variance_percent,
      key,
      title,
      prefix,
      ...{ suffix },
    };
  });
  varianceSummaryLoading.value = false;
}

function fetchVisuals() {
  const fetchDataWithLoading = async item => {
    item.loading = true;
    item.id = await fetchReportData({ 
      reportType: item.reportType, 
      customParams: item.getAdditionalParams() 
    });
    item.hasData = item.isAvailable(getReportData(item.id));
    item.loading = false;
  };
  for (const item of visuals.value) {
    fetchDataWithLoading(item);
  }
}

async function rolldown() {
  emit('refreshParent', !props.includeChildCustomerData);
}

function formatData(data) {
  return data.map(v => `"${v}"`).join(',');
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.bg-grey {
  background-color: var(--colour-panel-g-2);
}
.container {
  padding: var(--spacing-3);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: var(--spacing-2);
    padding-bottom: var(--spacing-4);
  }
}

.charts-section,
.opportunities-container,
.footer-section {
  margin: 0 var(--spacing-3);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin: 0 var(--spacing-2);
  }
}

.overview-header {
  margin: var(--spacing-3) 0;
  padding: var(--spacing-2) var(--spacing-8);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  transition: all 0.5s ease-in-out;
  background-color: var(--colour-panel-action);
  border-radius: var(--border-radius-1);
  min-height: 72px;
  position: relative;

  .datepicker-button {
    position: absolute;
    left: var(--spacing-2);
    right: auto;
    top: 50%;
    transform: translate(0, -50%);
    margin-right: auto;
    align-items: center;
    padding: 0;
    height: auto;
    box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    background: var(--colour-utility-white);

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      width: 36px;
      height: 36px;
    }

    &.active {
      background: var(--colour-utility-black);
    }
    &:deep(.button-container) {
      margin-left: 0;
    }
  }

  .middle-section {
    text-align: center;

    .title {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-left: var(--spacing-1);
      align-items: center;

      h3 {
        color: var(--colour-utility-action);
        font-weight: var(--font-weight-semibold);
      }
    }

    p {
      font-size: var(--font-size-small);
      line-height: var(--spacing-2);
      flex-basis: 100%;

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        margin: 0 var(--spacing-6);
      }
    }
  }
}

.dates-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    flex-direction: column;
    align-items: center;
  }

  .date-ranges {
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-bottom: var(--spacing-2);
    font-size: var(--font-size-small);

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      margin-bottom: var(--spacing-1);
    }

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      margin-bottom: 0;
      white-space: nowrap;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:nth-of-type(2):before {
      content: '―';
      margin: 0 var(--spacing-1);
      position: relative;
      top: 0;
      left: 0;
      width: 12px;
      height: 20px;
      display: block;

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        content: '';
        position: absolute;
        border-left: 1px solid;
        height: 10px;
        margin: 0;
        top: -8px;
        left: 6px;
      }
    }
  }
}

.icon-calendar {
  display: inline-flex;
  margin-right: var(--spacing-half);
  vertical-align: bottom;
}

.charts-section {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-6);
  justify-content: center;
  margin-bottom: var(--spacing-8);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-left: 0;
    margin-right: 0;
  }

  .item {
    position: relative;
    width: calc(50% - var(--spacing-3));
    min-height: 230px;

    :deep(.no-results) {
      margin: var(--spacing-3) auto;
    }

    @media #{map-get($display-breakpoints, 'md-and-down')} {
      width: 100%;
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      :deep(.multi-bar-chart-container) {
        margin-right: var(--spacing-2);
        margin-left: var(--spacing-1);
      }
      :deep(.line-bar-chart-container) {
        margin-left: var(--spacing-1);
      }
    }

    h3 {
      font-weight: var(--font-weight-semibold);
      text-align: center;
    }

    .date-ranges {
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        margin-bottom: 0;

        &::before {
          content: none;
        }

        .indicator {
          width: 12px;
        }
      }
    }

    .item-component {
      margin-top: var(--spacing-1);
    }
  }
}

.enquiry-links-container {
  background-color: var(--colour-panel-alert-base);
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: var(--spacing-3);
  padding-top: var(--spacing-3);
  margin-bottom: var(--spacing-4);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-bottom: var(--spacing-3);
    margin: 0 0 var(--spacing-4) 0;
  }

  h4 {
    font-weight: var(--font-weight-regular);
  }

  .enquiry-links {
    display: flex;
    gap: var(--spacing-4);
    margin-top: var(--spacing-2);

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      flex-direction: column;
      gap: var(--spacing-2);
    }

    .enquiry-link {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: var(--font-size-4);
      font-weight: var(--font-weight-semibold);

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        justify-content: flex-start;
      }

      span {
        margin-left: var(--spacing-1);
      }
    }
  }
}

.insight {
  min-width: 100%;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  border-radius: var(--border-radius-1);
  background-color: var(--colour-data-jagged-ice);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--colour-data-jagged-ice);
  margin-top: var(--spacing-3);

  img {
    max-height: 150px;
    margin: var(--spacing-2);
    @media #{map-get($display-breakpoints, 'md-and-down')} {
      display: none;
    }
  }
  .insight-text {
    display: flex;
    flex-flow: column;
    align-items: center;

    p {
      font-size: var(--font-size-3);
      margin: var(--spacing-1);
      line-height: var(--spacing-4);
      max-width: 560px;

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        margin-bottom: 0;
        font-size: var(--font-size-4);
      }

      &.customer-name {
        margin-bottom: var(--spacing-2);
        margin-top: 0;
      }
    }
  }
}

.loading-spinner {
  position: absolute;
  top: calc(50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}
.rollup-wrap {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-1);

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    position: absolute;
    right: var(--spacing-2);
    left: auto;
    top: 50%;
    transform: translate(0, -50%);
    margin-top: initial;
  }
}
</style>
