<template>
  <div
    :id="uniqueId"
    ref="container"
    class="chart-container"
  >
    <svg class="chart" />
    <div class="line-tooltip tooltip" />
    <div class="axis-tooltip tooltip" />
  </div>
</template>

<script setup>
// LineChart
import * as d3 from 'd3';
import { abbr, uuid, debounce } from '@sales-i/utils';
import { computed, onMounted, onUnmounted, ref } from 'vue';

const uniqueId = uuid('chart');
const container = ref(null);

const props = defineProps({
  // [{name, columnKey}]
  // name should be unique
  chartData: {
    type: Array,
    default: () => [],
  },
  columnKey: {
    type: String,
    default: 'value'
  },
  colour: {
    type: String,
    default: 'var(--colour-data-de-york)'
  },
  formatFunc: {
    type: Function,
    default: (value) => value
  },
  svgWidth: {
    type: Number,
    default: 0
  },
  svgHeight: {
    type: Number,
    default: 410
  },
  showValues: {
    type: Boolean,
    default: false,
  },
});

// dimensions and margins of the graph
const margin = {
  top: 20,
  right: 10,
  bottom: 100,
  left: 50,
};

const formatValue = props.formatFunc || (value => value);
const maxBandWidth = 100;
const svgWidthCalc = () => Math.min(props.svgWidth || container.value?.clientWidth - 80, props.chartData?.length * maxBandWidth, container.value?.clientWidth - 80);
const chartWidth = () => (svgWidthCalc() - margin.left - margin.right);
const chartHeight = computed(() => props.svgHeight - margin.top - margin.bottom);

// Prepare the Data
const axisTooltip = ref(null);
const tooltip = ref(null);

const debounceChartResize = debounce(handleChartResize, 250);
const containerResizeObserver = new ResizeObserver(debounceChartResize);

onMounted(() => {
  containerResizeObserver.observe(container.value);
});
onUnmounted(() => {
  containerResizeObserver.disconnect();
});

function handleChartResize() {
  generateChart();
}

onMounted(() => {
  axisTooltip.value = d3.select(`#${uniqueId} .axis-tooltip`);
  tooltip.value = d3.select(`#${uniqueId} .line-tooltip`);
  handleChartResize();
});

// truncate too long names
function truncateLabel() {
  const self = d3.select(this);
  let text = self.text();

  if (text.length > 12) {
    text = text.slice(0, 10);
    self.text(text + '...');
  }
}

function generateChart() {
  // Clear the previous SVG content
  d3.select(`#${uniqueId} svg g`).remove();
  
  // Set up the SVG
  const svg = d3.select(`#${uniqueId} svg`)
    .attr('width', svgWidthCalc())
    .attr('height', props.svgHeight)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  // Calculate the max and min values for the y-axis
  let maxNumericValue = Math.max(...props.chartData.map(a => a?.[props.columnKey]));
  let minNumericValue = Math.min(...props.chartData.map(a => a?.[props.columnKey]));

  // Set up the x-axis scale
  const x = d3.scalePoint()
    .domain(props.chartData.map(d => d.name))
    .range([0, chartWidth()])
    .padding(0.5);

  // Set up the y-axis scale
  const y = d3.scaleLinear()
    .domain([1.1 * Math.min(0, minNumericValue), 1.1 * Math.max(0, maxNumericValue)])
    .range([chartHeight.value, 0]);

  // Define the line
  const line = d3.line()
    .x(d => x(d.name))
    .y(d => y(d[props.columnKey]))
    .curve(d3.curveMonotoneX); // This makes the line smooth

  // Draw the line path
  svg.append('path')
    .datum(props.chartData)
    .attr('fill', 'none')
    .attr('stroke', props.colour)
    .attr('stroke-width', 2)
    .attr('d', line);

  // Draw the x-axis
  svg.append('g')
    .attr('class', 'chart-axis x')
    .attr('transform', `translate(0,${y(0)})`)
    .call(d3.axisBottom(x))
    .selectAll('text')
    .attr('transform', () => `translate(-10,${chartHeight.value - y(0)})rotate(-60)`)
    .attr('class', 'chart-label-x')
    .style('text-anchor', 'end')
    .each(truncateLabel)
    .on('mouseover', (event, d) => {
      const [xPosition, yPosition] = d3.pointer(event, d3.select(`#${uniqueId} svg`).node());
      const svgTopLeft = d3.select(`#${uniqueId} svg`).node().getBoundingClientRect();
      axisTooltip.value
        .style('display', 'block')
        .style('left', (svgTopLeft.left + xPosition) + 'px')
        .style('top', (svgTopLeft.top + yPosition - 30) + 'px')
        .style('opacity', 1)
        .html(d);
    })
    .on('mouseout', () => {
      axisTooltip.value.style('display', 'none');
    });

  // Draw the y-axis
  svg.append('g')
    .attr('class', 'chart-axis y')
    .call(d3.axisLeft(y).tickFormat(d => abbr.float(d)));

  // Add the dots for each data point
  svg.selectAll('.dot')
    .data(props.chartData)
    .enter().append('circle')
    .attr('class', 'dot')
    .attr('cx', d => x(d.name))
    .attr('cy', d => y(d[props.columnKey]))
    .attr('r', 5)
    .attr('fill', props.colour)
    .on('mouseover', (event, d) => {
      const [xPosition, yPosition] = d3.pointer(event, d3.select(`#${uniqueId} svg`).node());
      const svgTopLeft = d3.select(`#${uniqueId} svg`).node().getBoundingClientRect();

      tooltip.value
        .style('display', 'block')
        .style('left', (svgTopLeft.left + xPosition) + 'px')
        .style('top', (svgTopLeft.top + yPosition - 30) + 'px')
        .style('opacity', 1)
        .html(d?.label || formatValue(d?.[props.columnKey], props.columnKey));
    })
    .on('mouseout', () => {
      // Tooltip hide logic
      tooltip.value.style('display', 'none');
    });  
  
  if (props.showValues) {
  // Append text labels to each dot
    svg.selectAll('.dot-label')
      .data(props.chartData)
      .enter().append('text')
      .attr('class', 'dot-label')
      .attr('x', d => x(d.name) + 5) // Position the label right of the dot
      .attr('y', d => y(d[props.columnKey]) - 10) // Position the label above the dot
      .text(d => formatValue(d[props.columnKey])) // Use the format function
      .style('font-size', '10px') // Adjust font size as needed
      .style('text-anchor', 'start')
      .attr('fill', 'black'); // Adjust text color as needed
  }

  const colorValue = getComputedStyle(document.documentElement).getPropertyValue('--colour-panel-g-8');
  
  // Change color of axis line
  svg.selectAll('.chart-axis path')
    .attr('stroke', colorValue);
  svg.selectAll('.chart-axis line')
    .attr('stroke', colorValue);
}
</script>

<style lang="scss" scoped>
.chart-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  
  :deep(.chart-label-x),
  :deep(.chart-label-y) {
    font-size: var(--font-size-small);
    font-family: var(--font-family-primary);
  }
  
  :deep(.chart-label-x) {
    font-weight: var(--font-weight-semibold);
  }
  
  :deep(.line-tooltip) {
    width: 80px;
  }
  
  :deep(.axis-tooltip) {
    width: auto;
    z-index: 10;
  }
}
  
.tooltip {
  position: fixed;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s; 
  padding: var(--spacing-half) var(--spacing-1);
  border-radius: var(--spacing-2);
  text-align: center;
  color: var(--colour-utility-white);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-small);
  letter-spacing: 0;
  line-height: var(--spacing-2);
  background-color: var(--colour-utility-black);
  font-weight: var(--font-weight-semibold);
}
</style>
