<template>
  <CustomModal
    v-if="permissions.update"
    :title="props.title"
    show-modal
    :max-width="800"
    @close-modal="closeModal"
  >
    <BufferImage
      v-if="selected.loading"
      class="center mb-6"
      size="40"
    />
    <div class="flow">
      <Stage
        class="px-3"
        :items="stages"
        :selected-index="stage"
        @stage="changeStage"
      />
      <!-- stage 0 -->
      <div
        v-if="stage === 0"
        class="flow px-3"
      >
        <Field
          id="interaction-outcome"
          v-model="outcome"
          type="select"
          :disabled="fieldsLoading"
          :label="t('Interaction outcome')"
          :options="fieldsOptions.outcome"
          twoway
          required
        />

        <Field
          id="interaction-type"
          v-model="interactionType"
          type="select"
          :disabled="fieldsLoading"
          :label="t('Interaction type')"
          :options="interactionTypeOptions"
          twoway
          required
        />

        <Field
          id="next-action"
          v-model="nextAction"
          type="select"
          :disabled="fieldsLoading"
          :label="t('Next action')"
          :options="fieldsOptions.nextAction"
          twoway
        />
        <InteractionTimescaleForm
          :title="t('Do you need to schedule a follow-up interaction?')"
          :is-generate="false"
          :is-open-follow-up="true"
          :enabled-schedules="['today', 'schedule']"
          :date-time="timescale.date"
          :start-hours="startHours"
          :start-minutes="startMinutes"
          :duration-hours="durationHours"
          :duration-minutes="durationMinutes"
          @set-date="setDate"
        />
      </div>

      <!-- stage 1 -->
      <div
        v-if="stage === 1"
        class="flow"
      >
        <InteractionsAddOpps />
      </div>

      <!-- stage 2 -->
      <div
        v-if="stage === 2"
        class="flow px-3"
      >
        <h3 class="fw-sbld">
          {{ t('Do you need to update your interaction note?') }}
        </h3>
        <Field
          v-model="interactionNote"
          type="textarea"
          :label="t('Interaction note')"
          :max-length="1000"
          twoway
        />
      </div>
    </div>
    <template #footer>
      <ButtonGroup class="btn-group-bg">
        <CustomButton
          small
          purpose="action"
          :disabled="!valid"
          @click="onCompleteClick"
        >
          {{ t('Complete') }}
        </CustomButton>
        <CustomButton
          v-if="stage < 2"
          small
          purpose="action"
          :disabled="!valid"
          @click="onContinueClick"
        >
          {{ t('Continue') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script setup>
// CompleteInteractionModal
import { BufferImage, CustomModal, CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import Field from '@/shared/components/Form/Field.vue';
import InteractionTimescaleForm from '@/crm/components/Calendar/InteractionTimescaleForm.vue';
import Stage from '@/shared/components/Stage/Stage.vue';
import { dates } from '@sales-i/utils';
import useFields from '@/shared/composables/useFields';
import { useInteraction } from '@/crm/composables/useInteraction';
import { computed, nextTick, onMounted, onUnmounted ,ref } from 'vue';
import { t } from '@sales-i/utils';
import { useStore } from 'vuex';
import InteractionsAddOpps from '@/crm/views/Interactions/InteractionsAddOpps.vue';

const props = defineProps({
  title: {
    type: String,
    default: 'Complete interaction',
  },
  interactionId: {
    type: [String, Number, Boolean],
    default: undefined,
  },
});

const emit = defineEmits(['closeModal', 'changeStage', 'completed']);

const timescale = ref({});
const interactionNote = ref('');
const interactionType = ref(0);
const outcome = ref(0);
const nextAction = ref(0);
const stage = ref(0);

const store = useStore();
const { loading: fieldsLoading, getFields, fieldsOptions } = useFields({ store });

const { permissions, selected, putInteraction, getInteraction, setFurtherDetails, furtherDetails } = useInteraction({
  store,
  emit,
});

const interactionTypeOptions = computed(() => fieldsOptions.value.type.filter(o => o.text != t('Personal')));
const stages = ['Interaction outcome', 'Opportunities', 'Update notes'];
const valid = computed(() => !!outcome.value && !!interactionType.value);
const startHours = computed(() => dates.getStartHours(timescale.value.date));
const startMinutes = computed(() => dates.getStartMinutes(timescale.value.date));
const durationHours = computed(() => dates.getDurationHours(timescale.value.duration));
const durationMinutes = computed(() => dates.getDurationMinutes(timescale.value.duration));

onMounted(async () => {
  if (props.interactionId) {
    await getInteraction({ id: props.interactionId });
  }
  initFormDetails();
});

onUnmounted(() => {
  const resetDetails = {
    ...furtherDetails.value,
    nextAction: '',
    assignee: '',
    outcome: ''
  };
  setFurtherDetails(resetDetails);
});

function closeModal(completed = false) {
  emit('closeModal', completed);
}

function changeStage(newStage) {
  stage.value = newStage;
  emit('changeStage', newStage);
}

function initFormDetails() {
  getFields({ entity: 'interactions' });
  interactionNote.value = furtherDetails.value.interactionNote;
  interactionType.value = furtherDetails.value.interactionType;
  nextAction.value = furtherDetails.value.nextAction;
}
async function onContinueClick() {
  stage.value += 1;

  // If it's stage 2, check if there are any attached opportunities and attach them to the interaction.
  if (stage.value === 2) {
    await putInteraction({ id: selected.value.data?.id });
    nextTick(() => getInteraction({ id: selected.value.data?.id }));
  }
}
async function onCompleteClick() {
  let payload = {
    ...furtherDetails.value,
    interactionNote: interactionNote.value,
    outcome: outcome.value,
    interactionType: interactionType.value,
    nextAction: nextAction.value,
  };
  // update and save currently editing
  setFurtherDetails(payload);
  // save interaction
  let result = await putInteraction({ id: selected.value.data?.id });
  // leaving form on if error happened
  if (!result) return;

  // otherwise emitting success event
  emit('completed', timescale.value);
  closeModal(true);
}
function setDate(value) {
  timescale.value = value;
}
</script>

<style lang="scss" scoped>
:deep(.modal) {
  box-shadow: none;
}
.opp-modal :deep(.modal) {
  height: 100%;
  width: 100%;
}

.btn-group-bg {
  padding-top: 0;
  padding-bottom: 0;

  .button {
    margin-top: 0;
  }
}
</style>
