<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<template>
  <div
    class="droppable"
    @dragover="handleOnDragOver"
    @dragleave="onDragLeave"
    @drop="onDrop"
  >
    <slot />
  </div>
</template>

<script setup>
// DroppableItem
const props = defineProps({
  onDragOver: {
    type: Function,
    default: undefined,
  },
  onDragLeave: {
    type: Function,
    default: undefined,
  },
  onDrop: {
    type: Function,
    default: undefined,
  },
});

const handleOnDragOver = event => {
  event.preventDefault();
  props.onDragOver && props.onDragOver(event);
};
</script>

<style lang="scss" scoped>
.droppable {
  display: flex;
}
</style>
