import {
  POST_BY_ENTITY,
  POST_BY_ENTITY_LOADING,
  POST_BY_ENTITY_ERROR,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

export const addItemMutators = {
  [POST_BY_ENTITY]: (state) => {
    state.loaded = true;
    state.loading = false;
    state.success = true;
    state.errors = [];
  },
  [POST_BY_ENTITY_LOADING]: (state) => {
    state.loaded = false;
    state.loading = true;
    state.errors = [];
  },
  [POST_BY_ENTITY_ERROR]: (state, e) => {
    state.loading = false;
    state.loaded = true;
    state.errors = [
      e.message
    ];
  },
};

export const addItemActions = {
  [POST_BY_ENTITY]: async ({ commit }, payload) => {
    commit(POST_BY_ENTITY_LOADING);
    try {
      await httpRequest('post', 'feedback', payload, {
        version: 2,
        area: 'associations',
      });

      commit(POST_BY_ENTITY);
    } catch (e) {
      commit(POST_BY_ENTITY_ERROR, e);
    }
  },
};
