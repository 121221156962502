<template>
  <TableWrapper
    v-if="!dataLoading && dataRows.length"
    :class="{ 'is-snapshot': isSnapshot }"
    table-height="510px"
    :more-results-available="moreResultsAvailable"
    :offset-x="offsetX"
    :offset-y="offsetY"
    :limit="limit"
    :limit-x="limitX"
    :enable-pagination="!isSnapshot"
    :x-headings="xHeadings"
    :y-headings="yHeadings"
    @set-offset="loadData"
  >
    <thead :style="cssVars">
      <th
        v-for="(dimHeading, index) in yDims"
        :key="index"
        :class="[{ 'search-header': !index, 'only-dim' : yDims.length === 1 }, 'dim-header ydim-header', ]"
      >
        <div class="ydim-header-inner">
          <div
            :key="index"
            role="button"
            tabindex="0"
            :class="['heading', `${userSort.column !== index && 'span-2'}`]"
            @click="() => applySort(index)"
            @keypress.space="applySort(index)"
          >
            {{ dimHeading.entity }}
          </div>
          <div
            v-if="userSort.column === index && !isSnapshot"
            class="sort-icon"
            tabindex="0"
            role="button"
            @click="applySort(index)"
            @keypress.space="applySort(index)"
          >
            <IconBase
              :icon-name="
                userSort.direction === 'desc' ? 'arrow-down' : 'arrow-up'
              "
              :height="20"
              :width="20"
            />
          </div>
          <Field
            v-if="!index"
            v-model="searchRowsQuery"
            class="search-container"
            type="text"
            :aria-label="t('Search')"
            :icon="{
              name: 'search',
              size: 24
            }"
            @input="debounceSearch"
          />
        </div>
      </th>
      <th
        v-for="(heading, index) in xHeadings"
        :key="`${heading}-${index}`"
        :title="heading"
        class="dim-header xdim-header"
      >
        <div class="xdim-header-inner">
          <div class="heading">
            {{ heading }}
          </div>
          <IconBase
            v-if="userSort.column === `p${index}` && !isSnapshot"
            class="sort-icon"
            :icon-name="
              userSort.direction === 'desc' ? 'arrow-down' : 'arrow-up'
            "
            :height="20"
            :width="20"
          />
          <div
            class="chip"
            :title="getChipValue(index)"
          >
            {{
              isPriceTypeData
                ? cPrice(getChipValue(index), cs)
                : cNumber(Number(getChipValue(index)))
            }}
          </div>
        </div>
      </th>
    </thead>
    <tbody>
      <tr
        v-for="(row, ind) in rowsToShow"
        :key="row.id"
      >
        <th
          v-for="(dim, idx) in row.ydims"
          :key="idx"
          :class="[
            {
              'chip-hidden': !rowsChipsVisible,
              'only-dim' : yDims.length === 1
            },
            `dim-row`
          ]"
          :title="dim.values[0]"
        >
          <div
            v-if="(isDrillActive && !isSnapshot) && idx === 0"
            class="drill"
          >
            <CustomCheckbox
              :id="ind"
              class="checkbox"
              :value="row.checked"
              @input="handleEnquiryDrill(row, $event)"
            />
          </div>
          <div class="dim-row-inner">
            <span
              v-if="idx === 0"
              class="row-total"
              :class="[row.total < 0 ? 'negative' : 'positive']"
            >
              {{
                isPriceTypeData
                  ? cPrice(row.total || row.row_total, cs)
                  : cNumber(Number(row.total || row.row_total))
              }}
            </span>
            <span
              v-if="idx === 0"
              :class="['order-number', row.customer ? 'order-number-link' : '']"
            >#{{ Number(offsetY) + ind + 1 }}&nbsp;</span>
            <div
              v-if="dim.id && !iframe"
              class="bubble-wrap"
            >
              <a
                class="bubble-title link"
                :href="getCustomerLink(dim.id)"
                target="_blank"
              >
                {{ dim.values[0] }}
              </a>
              <p class="bubble-subtitle">
                {{ dim.values[1] }}
              </p>
            </div>
            <div
              v-else
              class="bubble-wrap"
            >
              <p class="bubble-title">
                {{ dim.values[0] }}
              </p>
              <p class="bubble-subtitle">
                {{ dim.values[1] }}
              </p>
            </div>
            <IconButton
              v-if="isSnapshot && isSmallScreen"
              class="chip-toggler"
              :icon-size="32"
              icon-name="caret-right"
              icon-color="var(--colour-utility-action)"
              purpose="transparent"
              icon-only
              @click="toggleChips"
            />
            <div
              v-if="isSnapshot"
              v-show="rowsChipsVisible"
              class="chip"
              :title="row.total"
            >
              {{
                isPriceTypeData
                  ? cPrice(Number(row.total), cs)
                  : cNumber(Number(row.total))
              }}
            </div>
          </div>
        </th>
        <td
          v-for="(cell, i) in row.values"
          :key="`cell-${row.id}-${i}`"
          :title="parseInt(cell, 10).toLocaleString('en-US')"
          :class="[
            {
              zero: parseInt(cell, 10) === 0,
              negative: parseInt(cell, 10) < 0
            },
            'dim-value'
          ]"
        >
          <div>
            {{
              cell
                ? isPriceTypeData
                  ? cPrice(cell, cs)
                  : cNumber(Number(cell))
                : '-'
            }}
          </div>
        </td>
      </tr>
    </tbody>
  </TableWrapper>
</template>

<script>
import { baseUrl as crmBaseUrl, customersArea } from '@/crm/router/urlBits';
import Fuse from 'fuse.js';
import {
  APPLY_OFFSET,
  APPLY_OFFSET_X,
  TOGGLE_DRILL
} from '@/intelligence/store/actionType';
import { REPORTS_SORT_DESCENDING_KEY } from '@/intelligence/store/data/apiInput';
import {
  REPORTS_KPI_COST,
  REPORTS_KPI_PROFIT,
  REPORTS_KPI_SALES_VALUE,
  REPORTS_KPI_VALUE
} from '@/intelligence/store/data/kpis';
import Field from '@/shared/components/Form/Field';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import breakpoints from '@/shared/utils/breakpoints';
import { CustomCheckbox, IconBase, IconButton } from '@sales-i/dsv3';
import { currency, debounce, t } from '@sales-i/utils';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  components: {
    CustomCheckbox,
    IconBase,
    Field,
    TableWrapper,
    IconButton
  },
  inject: ['mq'],
  props: {
    ySortDirection: {
      type: String,
      default: REPORTS_SORT_DESCENDING_KEY
    },
    isSnapshot: {
      type: Boolean,
      default: false
    },
    reportId: {
      type: Number,
      default: null
    },
    isQuantityType: {
      type: Boolean,
      default: false
    },
    reportType: {
      type: String,
      default: ''
    }
  },
  emits: ['sortOnYAxis', 'handleEnquiryDrill', 'setEnquiryData'],
  data() {
    return {
      searchRowsQuery: '',
      searchIndex: 0,
      showSearch: false,
      search: null,
      searchLoading: false,
      moreResultsAvailable: true, //TODO: BE response
      offsetX: 0,
      offsetY: 0,
      dataRows: [],
      filteredRows: [],
      rowsChipsVisible: true,
      userSort: {
        column: '',
        direction: 'desc',
        clicked: 0
      },
      defaultSort: {
        column: 'total',
        direction: 'desc'
      }
    };
  },
  computed: {
    ...mapState({
      cs: (state) => state.userDetails.cs,
      dataLoading: (state) => state.intelligence.shared.loading,
      limit: (state) => state.intelligence.shared.requestParameters.limit,
      limitX: (state) => state.intelligence.shared.requestParameters.limitX,
      isDrillActive: (state) => state.intelligence.shared.isDrillActive,
      drillActiveRows: (state) => state.intelligence.shared.drillActiveRows,
      measure: (state) => state.intelligence.shared.requestParameters.measure,
      iframe: (state) => state.system.iframe
    }),
    ...mapGetters({
      getDrillsLevel: 'intelligence/shared/getDrillsLevel',
      getDrillingDimensionId: 'intelligence/shared/getDrillingDimensionId',
      getReportData: 'intelligence/shared/getReportData',
      getInputData: 'intelligence/shared/getInputData'
    }),
    // inputData() {
    //   return this.getInputData(SALES_VS_GAPS);
    // },
    cssVars() {
      return {
        '--rows-grid-area': '2/1/3/25',
        '--dimension-columns-grid': `repeat(${this.yDims.length}, 1fr)`,
        '--dimension-column-width': `${
          (240 - (this.isDrillActive ? 30 : 0)) / this.yDims.length
        }px`
      };
    },
    rowsToShow() {
      return this.searchRowsQuery.length ? this.filteredRows : this.dataRows;
    },
    enquiryData() {
      return this.getReportData(this.reportId);
    },
    isSmallScreen() {
      return breakpoints.smAndDown.includes(this.mq.current);
    },
    isSearchVisible() {
      return this.isSmallScreen ? this.showSearch : true;
    },
    xHeadings() {
      return this.enquiryData?.axis?.x?.headings || [];
    },
    yHeadings() {
      return Array.isArray(this.enquiryData?.axis?.y) && this.enquiryData?.axis?.y?.length > 0 && this.enquiryData?.axis?.y[0]?.headings
        ? this.enquiryData?.axis?.y[0]?.headings
        : [];
    },
    yDims() {
      return this.enquiryData?.axis?.y || [];
    },
    summary() {
      return this.enquiryData.summary || [];
    },
    isPriceTypeData() {
      return (
        [
          REPORTS_KPI_COST,
          REPORTS_KPI_PROFIT,
          REPORTS_KPI_SALES_VALUE,
          REPORTS_KPI_VALUE
        ].includes(this.measure) && !this.isQuantityType
      );
    },
    debounceSearch() {
      return debounce(this.filterRows, 500);
    }
  },
  watch: {
    enquiryData(newVal) {
      this.searchRowsQuery = '';
      if (newVal?.rows) {
        this.initializeValueRows();
      }
    },
    drillActiveRows(newRows, oldRows) {
      if (!newRows.length) {
        oldRows.forEach((oldRow) => {
          this.handleEnquiryDrill(this.findRowByDrillValue(oldRow), false);
        });
      }
    },
    isSmallScreen(newValue) {
      this.rowsChipsVisible = !newValue;
    },

  },
  mounted() {
    this.initializeValueRows();
    if (!this.isSmallScreen) this.toggleDrill(true);
    // else this.rowsChipsVisible = false;
  },
  methods: {
    t,
    cPrice: currency.price,
    cNumber: currency.number,
    ...mapActions({
      applyOffsetX: `intelligence/enquiry/${APPLY_OFFSET_X}`,
      applyOffsetY: `intelligence/shared/${APPLY_OFFSET}`,
      toggleDrill: `intelligence/shared/${TOGGLE_DRILL}`
    }),
    initializeValueRows() {
      let customer = null;
      if (!this.enquiryData?.rows) {
        return;
      }

      const rows = [...this.enquiryData.rows];
      this.dataRows = rows.map((row) => {
        const ydims = row.dimension_values;
        const rowf = { ...row };
        const id = row?.dimension_values[0].values.join(',');
        let checked = false;

        for (let i = 0; i < row?.dimension_values.length; i++) {
          if (row?.dimension_values[i].id !== null) {
            customer = i;
            break;
          }
        }
        
        this.drillActiveRows.forEach((activeRow) => {
          if (activeRow.id === id) {
            rowf.checked = true;
            checked = true;
          }
        });

        const rowValue = {
          values: rowf.values,
          total: rowf.total,
          row_total: rowf.total,
          customer,
          checked,
          ydims,
          id
        };

        return rowValue;
      });

      this.search = new Fuse(this.dataRows, {
        threshold: 0.1,
        keys: [
          {
            name: 'ydims',
            getFn: (items) => items.ydims[this.searchIndex].values
          }
        ]
      });
    },
    nextSortDirection() {
      return this.userSort.direction === 'asc' ? 'default' : 'asc';
    },
    applySort(key) {
      if (this.isSnapshot || key === null) return;

      let sortCol = this.defaultSort.column;
      let sortDir = this.defaultSort.direction;

      if (this.userSort.column === key) {
        sortDir = this.nextSortDirection();

        if (sortDir === 'default') {
          this.userSort.column = null;
          this.userSort.direction = 'desc';

          sortCol = this.defaultSort.column;
          sortDir = this.defaultSort.direction;
        } else {
          this.userSort.column = key;
          this.userSort.direction = sortDir;

          sortCol = `DIM${this.userSort.column + 2}`;
        }
      } else {
        sortCol = `DIM${key + 2}`;
        sortDir = 'desc';
        this.userSort.column = key;
        this.userSort.direction = sortDir;
      }

      this.applyOffsetY(0);
      this.$emit('sortOnYAxis', sortDir, sortCol);
    },
    findRowByDrillValue(drillObject) {
      return this.dataRows.find((row) => row.id === drillObject.id);
    },
    getChipValue(index) {
      return this.summary[index];
    },
    getRowId(rowHeading) {
      return rowHeading ? rowHeading.replace(/\s+/g, '-') : null;
    },
    getIdFromDimEntity(entity) {
      return this.yDims.find((dim) => dim.entity === entity)?.id;
    },
    handleEnquiryDrill(dataRow, checked) {
      if (!dataRow) return;
      dataRow.checked = checked;

      const [head1, head2] = dataRow?.ydims[0].values;

      this.$emit('handleEnquiryDrill', {
        checked,
        id: `${head1},${head2}`,
        item: { value: head1, pair: head2 }
      });
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
    },
    toggleChips() {
      this.rowsChipsVisible = !this.rowsChipsVisible;
    },
    loadData(paginationDirection) {
      switch (paginationDirection) {
      case 'right':
        this.offsetX += this.limitX;
        break;
      case 'bottom':
        this.offsetY += this.limit;
        break;
      case 'left':
        this.offsetX -= this.limitX;
        break;
      case 'top':
        this.offsetY -= this.limit;
        break;
      default:
        break;
      }

      this.applyOffsetX(Math.max(0, this.offsetX));
      this.applyOffsetY(Math.max(0, this.offsetY));

      this.$emit('setEnquiryData');
    },
    getCustomerLink(id) {
      return `${crmBaseUrl}/${customersArea}/${id}`;
    },
    filterRows() {
      this.searchLoading = true;
      this.filteredRows = this.searchRowsQuery
        ? this.search.search(this.searchRowsQuery).map((row) => row.item)
        : this.dataRows;

      this.searchLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

table {
  position: relative;

  thead th, 
  tbody th {
    text-align: left;
    padding: 0;
    left: 0;
    z-index: 3;
    height: initial;
    vertical-align: middle;
  }

  tbody td {
    vertical-align: middle;
  }

  thead {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 15;

    th {
      font-weight: var(--font-weight-semibold);
      vertical-align: middle;
      position: relative;
    }
  }

  td.zero {
    color: var(--colour-utility-error);
  }

  td,
  th {
    font-size: var(--font-size-body);
    color: var(--colour-utility-black);
    border: 0;
    min-width: initial;
    height: auto;
  }

  .dim-header {
    border-bottom: 1px solid var(--colour-panel-g-16);
    padding: 0 var(--spacing-1);
    top: 0;
    z-index: 4;

    &.search-header {
      .ydim-header-inner {
        grid-template-rows: 1fr 20px;
      }
    }
  }

  .ydim-header {
    background: var(--colour-panel-action);
    z-index: 4;
    text-align: center;
    width: 10%;

    &.only-dim {
      width: initial;
    }

    &:nth-of-type(2) {
      z-index: 8;
      box-shadow: 0 0 var(--border-radius-half) var(--shadow-spread) var(--shadow-colour);
    }

    .ydim-header-inner {
      text-decoration: underline;
      color: var(--colour-utility-action);
      text-align: center;
      display: inline-flex;  
      align-items: baseline;

      .heading {
        padding: var(--spacing-2) var(--spacing-1);
        &.span-2 {
          grid-column: 1 / 3;
        }
      }

      .search-container {
        grid-row: 2;
        grid-column: 1 / 3;
        z-index: 50;
        margin: 0;
        width: calc(100% - 20px);
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 300px;

        :deep(.form-group) {
          margin: 0;
        }

        .field {
          position: relative;
        }

        :deep(.svg-container.icon-search) {
          top: 50% !important;
          transform: translateY(-50%);
        }

        :deep(input) {
          max-height: 40px;
          border-radius: var(--spacing-4);
          font-weight: var(--font-weight-regular);
          padding: var(--spacing-1) var(--spacing-2) var(--spacing-1)
            var(--spacing-4);
        }
      }
    }

    &:nth-of-type(1) {
      z-index: 5;
    }
  }

  .xdim-header {
    text-align: center;
    z-index: 3;
    min-width: 150px;

    .xdim-header-inner {
      display: grid;
      justify-content: center;
      align-items: center;
      justify-items: stretch;
      height: 100%;
      grid-template-rows: 1fr 20px;

      .heading {
        padding: var(--spacing-2) var(--spacing-1);
        font-weight: var(--font-weight-regular);
      }

      .chip {
        width: 100px;
        color: var(--colour-utility-black);
        background: var(--colour-panel-g-0);
        box-shadow: 0 0 var(--border-radius-half) var(--shadow-spread)
          var(--shadow-colour);
        font-size: var(--font-size-5);
        font-weight: var(--font-weight-medium);
        letter-spacing: 0;
        line-height: var(--spacing-3);
        padding: var(--spacing-1);
        border-radius: var(--spacing-6);
        align-self: flex-start;
        text-decoration: none;

        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
          width: 120px;
        }
      }
    }
  }

  .dim-row {
    padding: var(--spacing-3) var(--spacing-2) var(--spacing-1) var(--spacing-2);
    cursor: help;
    background: var(--colour-panel-g-2);
    position: relative;
    min-width: 150px;

    &:nth-of-type(1) {
      max-width: 250px;
    }

    &.only-dim {
      padding-right: var(--spacing-2);
    }

    &:nth-of-type(2) {
      .dim-row-inner {
        .bubble-wrap {
          grid-column: 1 / 3;
          padding-top: calc(var(--font-size-small) + 4px);
        }
      }
    }

    .dim-row-inner {
      display: grid;
      grid-template-columns: auto 1fr;
      box-sizing: content-box;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        max-width: 225px;
      }
    }

    .bubble-wrap {
      grid-column: 2;
      grid-row: 2;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      max-width: 200px;

      .bubble-title {
        font-size: var(--font-size-5);
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.link {
          font-size: var(--font-size-5);
          span {
            display: inline-block;
          }
        }
      }
    }

    .row-total {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-semibold);
      grid-column: 2 / 3;
      grid-row: 1;
      text-align: start;
    }

    .order-number {
      grid-column: 1;
      grid-row: 2 / 3;
      color: var(--colour-utility-base);
      text-align: right;
      font-size: var(--font-size-5);
      font-weight: var(--font-weight-regular);
      display: block;
    }

    .bubble-subtitle {
      grid-column: 2;
      align-self: start;
      margin-left: 0;
      color: var(--colour-utility-base);
      font-size: var(--font-size-small);
      line-height: var(--font-size-small);
      font-weight: var(--font-weight-regular);
    }

    p:not(.bubble-subtitle) {
      font-weight: var(--font-weight-semibold);
    }
  }

  .dim-value {
    text-align: center;
    &.zero {
      color: var(--colour-utility-error);
    }
  }

  .is-snapshot table {
    tr:first-of-type th {
      .chip-toggler {
        top: calc(50% + 12px);
      }
      .chip {
        margin-top: 12px;
      }
    }
    tr th {
      padding-right: 120px;
      padding-left: var(--spacing-2);

      &.chip-hidden {
        padding-right: var(--spacing-2);
      }

      .chip {
        left: unset;
        right: 0;
        top: 50%;
        transform: translate(-8px, -50%);
        text-align: center;
        margin-top: 0;
      }
    }
    .search-cell {
      padding: var(--spacing-2);
    }

    .chip-toggler {
      position: absolute;
      left: 132px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .positive {
    color: var(--colour-data-de-york-label);
  }

  .negative {
    color: var(--colour-data-mandy-label);
  }
}

.drill {
  background: var(--colour-panel-base);
  padding: var(--spacing-2) var(--spacing-1);
  position: absolute;
  left: 0;
  top: var(--spacing-2);
  bottom: 0;
  display: flex;
  align-items: flex-start;
  padding-top: var(--spacing-3);
  
  ~ .dim-row-inner {
    padding-left: var(--spacing-4);
  }
}
</style>
