import { httpRequest } from '@sales-i/utils';
import {
  SET_LOADING,
  FETCH_STAGES_DATA,
  FETCH_TABLE_DATA,
  FETCH_CHART_DATA,
  APPLY_NEW_OPPORTUNITY,
  APPLY_NEW_DATE_RANGE,
  SET_FILTER_TITLE,
  APPLY_SORT,
  APPLY_USER,
  APPLY_OUTCOME_IDS,
  APPLY_DATE_RANGE,
  CLEAR_INTERACTION_DRILL_DATA,
  APPLY_NEW_FILTER,
  DELETE_FILTER,
  CLEAR_FILTERS,
  APPLY_REPORT_TYPE,
  SET_ANALYSIS_FIELD,
  APPLY_DRILL_TYPE,
} from '@/intelligence/store/actionType';
import setRequestParam from '@/intelligence/store/utils/setRequestParam';

// initial state
const state = () => ({
  stages: {},
  chartData: {},
  chartInput: {},
  tableData: {},
  opportunity: 'open',
  loading: true,
  date_from: null,
  date_to: null,
  filterTitle: '',
  report_type: '',
  filters: {
    user: [],
    interaction_outcome: [],
    interaction_type: [],
    next_action: [],
  }
});

// getters
const getters = {};

// mutations
const mutations = {
  [SET_LOADING]: (state, isLoading) => {
    state.loading = isLoading;
  },
  [FETCH_STAGES_DATA]: (state, data) => {
    state.stages = data;
  },
  [FETCH_TABLE_DATA]: (state, [tableData, stageId]) => {
    state.tableData[stageId] = tableData;
  },
  [FETCH_CHART_DATA]: (state, { chartData, stageId, entity, order }) => {
    state.chartData[stageId] = [...chartData];
    state.chartInput[stageId] = {
      entity,
      order
    };
  },
  [APPLY_NEW_OPPORTUNITY]: (state, data) => {
    state.opportunity = data;
  },
  [APPLY_NEW_DATE_RANGE]: (state, data) => { 
    state.date_from = data?.date_from.split('T')[0];
    state.date_to = data?.date_to.split('T')[0];
  },
  [SET_FILTER_TITLE]: (state, data) => {
    state.filterTitle = data;
  },
  [APPLY_NEW_FILTER]: (state, filter) => {
    state.filters[filter.type].push(...filter.value);
  },
  [APPLY_REPORT_TYPE]: (state, report) => {
    state.report_type = report;
  },
  [DELETE_FILTER]: (state, filter) => {
    const filterArea = filter.filterKey;
    state.filters[filterArea] = Object.values(state.filters[filterArea]).filter(singleFilter => {
      const inValues = filter.selected_values.find(v => v.id === singleFilter);
      return !inValues;
    });
  },
  [CLEAR_FILTERS]: (state) => {
    state.filters = {
      user: [],
      interaction_outcome: [],
      interaction_type: [],
      next_action: [],
    };
  }
};

// actions
const actions = {
  [FETCH_STAGES_DATA]: async ({ commit, state }, { opportunityType = state.opportunity, date_from = state.date_from, date_to = state.date_to } = {}) => {
    try {
      commit(SET_LOADING, true);
      const data = await httpRequest('get', `opportunities/report/stages?status=${opportunityType}&date_from=${date_from?.split('T')[0]}&date_to=${date_to?.split('T')[0]}`);
      commit(FETCH_STAGES_DATA, data);
      return data;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      commit(SET_LOADING, false);
    }
  },
  [FETCH_TABLE_DATA]: async({ commit, state }, { stageId, opportunityType = state.opportunity, date_from = state.date_from, date_to = state.date_to } = {}) => {
    try {
      commit(SET_LOADING, true);
      const data = await httpRequest('get', `opportunities/report/${stageId}?status=${opportunityType}&date_from=${date_from.split('T')[0]}&date_to=${date_to.split('T')[0]}`);
      commit(FETCH_TABLE_DATA, [data, stageId]);
      return data;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      commit(SET_LOADING, false);
    }
  },
  [FETCH_CHART_DATA]: async({ commit, state }, { stageId, opportunityType = state.opportunity, entity, order, date_from = state.date_from, date_to = state.date_to } = {}) => {
    try {
      commit(SET_LOADING, true);
      const chartData = await httpRequest('get', `opportunities/report/${stageId}/visual?status=${opportunityType}&entity=${entity}&order=${order}&date_from=${date_from.split('T')[0]}&date_to=${date_to.split('T')[0]}`);
      commit(FETCH_CHART_DATA, {chartData, stageId, entity, order});
      return chartData;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      commit(SET_LOADING, false);
    }
  },
  [APPLY_NEW_OPPORTUNITY]: ({ commit }, opportunity ) => {
    commit(APPLY_NEW_OPPORTUNITY, opportunity);
  },
  [APPLY_NEW_DATE_RANGE]: ({ commit }, date ) => {
    commit(APPLY_NEW_DATE_RANGE, date);
  },
  [SET_FILTER_TITLE]: ({ commit }, title ) => {
    commit(SET_FILTER_TITLE, title);
  },
  [APPLY_SORT]: ({ commit }, newSort) => {
    setRequestParam(commit, 'currentSortHeader', newSort.header);
    setRequestParam(commit, 'currentSortOption', newSort.option);
  },
  [APPLY_DATE_RANGE]: ({ commit }, dates) => {
    setRequestParam(commit, 'date_from', dates.date_from);
    setRequestParam(commit, 'date_to', dates.date_to);
  },
  [APPLY_USER]: ({ commit }, user) => {
    setRequestParam(commit, 'user', [user]);
  },
  [APPLY_OUTCOME_IDS]: ({ commit }, ids) => {
    const interaction_outcome_ids = ids.map(area => area.id);
    setRequestParam(commit, 'interaction_outcome', interaction_outcome_ids);
  },
  [APPLY_DRILL_TYPE]: ({ commit }, drill_type) => {
    setRequestParam(commit, 'drill_type', drill_type);
  },
  [CLEAR_INTERACTION_DRILL_DATA]: ({ commit, state }) => {
    setRequestParam(commit, 'user', state.filters.user);
    setRequestParam(commit, 'interaction_type', state.filters.interaction_type);
    setRequestParam(commit, 'interaction_outcome', state.filters.interaction_outcome);
    setRequestParam(commit, 'next_action', state.filters.next_action);
  },
  [APPLY_NEW_FILTER]: ({ commit, state }, filter) => {
    commit(APPLY_NEW_FILTER, filter);
    setRequestParam(commit, filter.type, state.filters[filter.type]);
  },
  [APPLY_REPORT_TYPE]: ({ commit }, report) => {
    commit(APPLY_REPORT_TYPE, report);
    setRequestParam(commit, 'report_type', report);
  },
  [SET_ANALYSIS_FIELD]: ({ commit }, analysisField) => {
    const analysisFieldValueIds = [];
    const {id, selected_values} = analysisField[0];

    selected_values.forEach(values => analysisFieldValueIds.push(`"${values.id}"`));
    
    setRequestParam(commit, 'analysis_field_id', id);
    setRequestParam(commit, 'analysis_field_value_id', analysisFieldValueIds);
  },
  [DELETE_FILTER]: ({ commit, state }, filter) => {
    if (filter.selectedFilterType === 'analysis_field_id') {
      commit(`intelligence/enquiry/${DELETE_FILTER}`, filter, { root: true });
      setRequestParam(commit, 'analysis_field_id', '');
      setRequestParam(commit, 'analysis_field_value_id', '');
      return;
    }
    commit(DELETE_FILTER, filter);
    setRequestParam(commit, 'user', state.filters.user);
    setRequestParam(commit, 'interaction_type', state.filters.interaction_type);
    setRequestParam(commit, 'interaction_outcome', state.filters.interaction_outcome);
    setRequestParam(commit, 'next_action', state.filters.next_action);
  },
  [CLEAR_FILTERS]: ({ commit }) => {
    commit(CLEAR_FILTERS);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
