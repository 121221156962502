/* eslint-disable no-unused-vars */
import {
  POST_BY_ENTITY,
  POST_BY_ENTITY_LOADING,
  POST_BY_ENTITY_ERROR,
  SET_ITEMS
} from '@/shared/store/actionType';
import mapInteractionPayload from '@/crm/store/utils/mapInteractionPayload';
import { httpRequest } from '@sales-i/utils';

// mutations
export const postMutations = {
  [POST_BY_ENTITY]: (state, data) => {
    state.selected.data = data;
    state.selected.loading = false;
    state.selected.updated = true;
  },
  [POST_BY_ENTITY_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.updated = false;
  },
  [POST_BY_ENTITY_ERROR]: (state, errors) => {
    state.selected.errors = errors;
    state.selected.loading = false;
    state.selected.updated = false;
  },
};


// actions
export const postActions = {
  [POST_BY_ENTITY]: async ({state, commit, dispatch}, { isPersonal = false, data: interaction = {} } = {}) => {
    commit(POST_BY_ENTITY_LOADING);
    let interactionToPost = mapInteractionPayload(state.currentlyEditing, false, isPersonal, interaction);
    try {
      const data = await httpRequest('post', 'interactions', interactionToPost, { version: '2' });
      commit(POST_BY_ENTITY, data);
      dispatch(`alerts/${SET_ITEMS}`, {
        type: 'success',
        message: 'Interaction added',
        selfDismiss: true,
      }, {root: true});
    } catch (error) {
      commit(POST_BY_ENTITY_ERROR);
    }
  },
};
