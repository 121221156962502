<template>
  <div>
    <ViewContact
      :entity-id="entityId"
      :contact="contact"
      :default-contact="defaultContact"
      @close-modal="closeModal"
      @deleted="$emit('updated')"
    />
    <router-view @updated="$emit('updated')" />
  </div>
</template>

<script>
import ViewContact from '@/crm/components/Contacts/ViewContact.vue';
import { mapState, mapActions } from 'vuex';
import { CLEAR_UPDATED_FLAG, GET_DEFAULT, GET_BY_ID, UPDATE_RECENTLY_VIEWED } from '@/shared/store/actionType';
import { baseUrl, contactsSection } from '@/crm/router/urlBits.js';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'ViewContactView',
  components: {
    ViewContact,
  },
  props: {},
  emits: ['closeModal', 'updated'],
  data() {
    return {
      entityId: this.$route.params.id,
      contactId: this.$route.params.contact_id,
    };
  },
  computed: {
    ...mapState({
      entity: state => state.system.entity,
      contact: state => state.crm.contacts.selected,
      defaultContact: state => state.crm.contacts.default,
      system: state => state.system.route,
    }),
  },
  watch: {
    $route(to) {
      const render = this.contact.updated && to.params.contact_id;

      if (render) {
        this.clearUpdatedFlag();
        this.loadContact();
      }
    },
  },
  mounted() {
    this.getDefault({
      id: this.entityId,
      entity: this.entity,
    });
    this.loadContact();
    this.updateRecentlyViewedContact(this.contactId);
  },
  methods: {
    ...mapActions({
      getDefault: `crm/contacts/${GET_DEFAULT}`,
      getById: `crm/contacts/${GET_BY_ID}`,
      clearUpdatedFlag: `crm/contacts/${CLEAR_UPDATED_FLAG}`,
      updatedRecentlyViewed: `searchBarResults/${UPDATE_RECENTLY_VIEWED}`
    }),
    updateRecentlyViewedContact(contactId) {
      this.updatedRecentlyViewed({
        entity_type: 'contact',
        entity_id: typeof contactId === 'number' ? contactId : parseInt(contactId, 10),
      });
    },
    loadContact() {
      this.getById({
        id: this.contactId,
      });
    },
    closeModal() {
      // Push the route retreived from the store
      navigateToUrl(`${baseUrl}/${this.entity}/${this.entityId}/${contactsSection}/`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
