// request entities
export const REQUEST_ENTITY_PRODUCTS_SALES = 'productsale';
export const REQUEST_ENTITY_PRODUCTS_SALES_GROUP = 'productgroupsale';
export const REQUEST_ENTITY_CUSTOMERS_SALES = 'customersale';
export const REQUEST_ENTITY_CUSTOMERS_DOWN_IN_REVENUE = 'customersdowninrevenue';
export const REQUEST_ENTITY_CUSTOMERS_WITH_ZERO_SPEND = 'customerszerospend';
export const REQUEST_ENTITY_CUSTOMERS_DORMANT_YTD = 'customersdormantytd';
export const REQUEST_ENTITY_CUSTOMERS_NOT_SPENT_THIS_PERIOD = 'customersnospendthisperiod';
export const REQUEST_ENTITY_CUSTOMERS_NOT_BUYING_TOP_PRODUCTS = 'customersnotbuyingtopproducts';
export const REQUEST_ENTITY_CUSTOMERS_DECREASING_SPEND = 'customersdecreasingspend';
export const REQUEST_ENTITY_MATRIX = 'matrix';
export const REQUEST_ENTITY_BUBBLES = 'bubbles';
export const REQUEST_ENTITY_VARIANCE = 'varianceplus';
export const REQUEST_ENTITY_VARIANCE_INITIAL = 'varianceplusinitial';
export const REQUEST_ENTITY_TRENDS = 'trends';
export const REQUEST_ENTITY_FULL_PICTURE = 'fullpicture';
export const REQUEST_ENTITY_AIML_PRODUCT_TRENDS = 'producttrend';
export const REQUEST_ENTITY_QUERY_BUILDER = 'querybuilder';
export const REQUEST_ENTITY_SALES_VS_GAPS = 'salesvsgaps';
export const REQUEST_ENTITY_FREQUENT_PURCHASES = 'frequentpurchases';
export const REQUEST_ENTITY_LATEST_PURCHASES = 'latestpurchases';
export const REQUEST_ENTITY_PRODUCT_GROUP_PURCHASE_ANALYSIS = 'productgrouppurchaseanalysis';
export const REQUEST_ENTITY_ACCOUNTS_ANALYSIS = 'accountsanalysis';
export const REQUEST_ENTITY_TRANSACTIONS_ANALYSIS = 'transactionsanalysis';
export const REQUEST_ENTITY_INTERACTION_SUMMARY = 'interactionsummary';
export const REQUEST_ENTITY_INTERACTION_SUMMARY_DRILL = 'interactionsummarydrill';
export const REQUEST_ENTITY_INTERACTION_SPEND_SUMMARY = 'interactionspend';
export const REQUEST_ENTITY_CUSTOMER_ACTIVITY_LIST = 'customeractivitylist';
export const REQUEST_ENTITY_MARKET_PENETRATION = 'marketpenetration';

export const RESPONSES_SET = {
  [REQUEST_ENTITY_PRODUCTS_SALES]: `
    name
    code
    units_sold
    cost
    revenue
    profit
    margin
  `,
  [REQUEST_ENTITY_PRODUCTS_SALES_GROUP]: `
    name
    code
    units_sold
    cost
    revenue
    profit
    margin
  `,
  [REQUEST_ENTITY_CUSTOMERS_SALES]: `
    id
    name
    code
    cost
    revenue
    profit
    margin
  `,
  [REQUEST_ENTITY_CUSTOMERS_DOWN_IN_REVENUE]: `
    date_range {
      start
      end
    }
    customers {
      id
      customer
      previous_revenue
      revenue
      percent_down
    }
  `,
  [REQUEST_ENTITY_CUSTOMERS_WITH_ZERO_SPEND]: `
    date_range {  
      start
      end
    }
    customers {
      id
      customer
    }
  `,
  [REQUEST_ENTITY_CUSTOMERS_DORMANT_YTD]: `
    date_range {
      start
      end
    }
    customers {
      id
      customer
      revenue_this_period
    }
  `,
  [REQUEST_ENTITY_CUSTOMERS_NOT_SPENT_THIS_PERIOD]: `
    date_range {
      start
      end
    }
    customers {
      id
      customer
      previous_revenue
    }
  `,
  [REQUEST_ENTITY_CUSTOMERS_NOT_BUYING_TOP_PRODUCTS]: `
    date_range {
      start
      end
    }
    customers {
      id
      customer
    }
  `,
  [REQUEST_ENTITY_CUSTOMERS_DECREASING_SPEND]: `
    date_range {
      start
      end
    }
    customers {
      id
      customer
      revenue
      percent_down
    }
  `,
  [REQUEST_ENTITY_BUBBLES]: `
    id
    area
    title
    icon
    color
    type
  `,
  [REQUEST_ENTITY_MATRIX]: `
    date_range {
      start
      end
    }
    axis {
      x {
        entity
        max
        headings
      }
      y {
        entity
        max
        headings
      }
    }
    summary
    rows {
      id
      values
      row_total
    }
  `,
  [REQUEST_ENTITY_MARKET_PENETRATION]: `
      date_range {
        start
        end
      }
      interactions {
        id
        count
      }
      summary
      summaryMonth
      axis {
        x {
          entity
          max
          headings
        }
        y {
          entity
          max
          headings
        }
      }
      rows {
        id
        values
      }
  `,
  [REQUEST_ENTITY_VARIANCE]: `
  row_count
  headings {
    dimensions
    values
  }
  summary {
    sales_value {
      values
      variance
      variance_percent
    }
    cost {
      values
      variance
      variance_percent
    }
    profit {
      values
      variance
      variance_percent
    }
    gross_profit {
      values
      variance
      variance_percent
    }
    average_price{
      values
      variance
      variance_percent
    }
    quantity {
      values
      variance
      variance_percent
    }
    count {
      values
      variance
      variance_percent
    }
    invoice_count {
      values
      variance
      variance_percent
    }
    average_items_per_invoice {
      values
      variance
      variance_percent
    }
  }
  rows {
    dimensions {
      entity_id
      dimensions
    }
    sales_value {
      values
      variance
      variance_percent
    }
    cost {
      values
      variance
      variance_percent
    }
    profit {
      values
      variance
      variance_percent
    }
    gross_profit {
      values
      variance
      variance_percent
    }
    average_price{
      values
      variance
      variance_percent
    }
    quantity {
      values
      variance
      variance_percent
    }
    count {
      values
      variance
      variance_percent
    }
    invoice_count {
      values
      variance
      variance_percent
    }
    average_items_per_invoice {
      values
      variance
      variance_percent
    }
  }
  `,
  [REQUEST_ENTITY_VARIANCE_INITIAL]: `
  row_count
  headings {
    dimensions
    values
  }
  summary {
    sales_value {
      values
      variance
      variance_percent
    }
  }
  rows {
    dimensions {
      entity_id
      dimensions
    }
    sales_value {
      values
      variance
      variance_percent
    }
  }
`,
  [REQUEST_ENTITY_TRENDS]: `
  headings
  summary {
    value_1
    value_2
    value_variance
  }
  rows {
    period
    value_1
    value_2
    value_variance
  }
  `,
  [REQUEST_ENTITY_FULL_PICTURE]: `
  row_count
  headings {
    dimensions
    values
  }
  summary {
    sales_value
    cost
    profit
    gross_profit
    average_price
    quantity
    count
    invoice_count
    average_items_per_invoice
  }
  targets_exist
  target_info {
    id
    name
    description
    interval
    measurement_type
    measurement_unit
    value
  } 
  rows {
    dimensions {
      entity_id
      dimensions
    }
    sales_value
    cost
    profit
    gross_profit
    average_price
    quantity
    count
    invoice_count
    average_items_per_invoice
    targets_exist
    profit_target {
      id
      target
      difference
      perc_change
    }
    sales_value_target {
      id
      target
      difference
      perc_change
    }
    quantity_target {
      id
      target
      difference
      perc_change
    }
  }
  `,
  [REQUEST_ENTITY_QUERY_BUILDER]: `
  row_count
  headings {
    dimensions
    values
  }
  summary {
    sales_value
    cost
    profit
    gross_profit
    average_price
    quantity
    count
    invoice_count
    average_items_per_invoice
    customer_count
  }
  rows {
    dimensions {
      entity_id
      dimensions
    }
    sales_value
    cost
    profit
    gross_profit
    average_price
    quantity
    count
    invoice_count
    average_items_per_invoice
  }
  `,
  [REQUEST_ENTITY_AIML_PRODUCT_TRENDS]: `
  products {
    name
    code
  }
  rows {
    period
    product_1
    product_2
    product_both
  }
  `,
  [REQUEST_ENTITY_FREQUENT_PURCHASES]: `
    code
    description
    count
  `,
  [REQUEST_ENTITY_LATEST_PURCHASES]: `
    code
    description
    value
    quantity
  `,
  [REQUEST_ENTITY_SALES_VS_GAPS]: `
    date_range {
      start
      end
    }
    axis {
      x {
        entity
        max
        headings
      }
      y {
        entity
        id
        max
        headings
      }
    }
    summary
    rows {
      values
      dimension_values {
        id
        values
      }
      total
    }
  `,
  [REQUEST_ENTITY_PRODUCT_GROUP_PURCHASE_ANALYSIS]: `
    description
    total
    purchase_share
    average_customer_spend
    average_industry_spend
  `,
  [REQUEST_ENTITY_ACCOUNTS_ANALYSIS]: `
    id
    name
    account_number
    value
  `,
  [REQUEST_ENTITY_TRANSACTIONS_ANALYSIS]: `
    dimension_value
    value
    quantity
  `,
  [REQUEST_ENTITY_INTERACTION_SUMMARY]: `
    name
    rep_id
    interactions_made
    interactions_allocated
  `,
  [REQUEST_ENTITY_INTERACTION_SUMMARY_DRILL]: `
    customer
    customer_id
    contact
    customer_type
    customer_account_number
    order_value
    linked_opportunities {
      id
    }
    interaction_type
    interaction_date_start
    interaction_date_end
    interaction_note
    interaction_outcome
    interaction_purpose
    interaction_duration
    interaction_created
    interaction_updated
    next_action
    follow_up_id
    follow_up_date
    follow_up_assigned_to
  `,
  [REQUEST_ENTITY_INTERACTION_SPEND_SUMMARY]: `
    name
    id
    type
    account_number
    total_sales 
    total_sales_ytd
    total_interactions
    average_interactions_per_day
    average_sales_per_interaction
    periods {
    period
    total_interactions_in_period
    interaction_types {
      interaction_type
      interaction_count
    }
  }
`,
  [REQUEST_ENTITY_CUSTOMER_ACTIVITY_LIST]: `
    customers {
      id
      name
      account_number
      avg_sales_value
      sales_value_1
      sales_value_2
      sales_value_variance
      gross_profit_1
      gross_profit_2
      gross_profit_variance
      interaction_count
      avg_interaction_count_per_wd
    }
`,
};

// sort keys
export const REPORTS_SORT_ASCENDING_KEY = 'asc';
export const REPORTS_SORT_DESCENDING_KEY = 'desc';

// Sales opportunity filter keys
export const REPORTS_CHART_INITIAL_KEY1 = 'rep';
export const REPORTS_CHART_INITIAL_KEY2 = 'high';
