import {
  GET_FIELDS,
  CLEAR_FIELDS
} from '@/shared/store/actionType';
import store from '@/shared/store';
import { httpRequest } from '@sales-i/utils';
import { customerContactData } from '@/crm/store/data/customerContact';
import { prospectContactData } from '@/crm/store/data/prospectContact';

export const fieldsMutations = {
  [GET_FIELDS]: async (state, { entityId, setDefault, data, defaultFields, addressFields }) => {
    let dataTemp = data;
    let entity = store.state.system.entity;

    if(entityId) {
      let { name } = (entity === 'customers') ? store.state.crm.customers.selected.data : store.state.crm.prospects.selected.data;
      let contact = store.state.crm.contacts.selected.data;
      let contactAddress = contact.addresses && contact.addresses.length > 0 ? contact.addresses[0] : false;
      dataTemp = data.map(e => {
        let fields = e.fields.map(field => {
          // will only set is_default_contact to true if setDefault and adding a contact
          let defaultFieldFromSchema = defaultFields.fields.find(f => f.name === field.id);
          if (!defaultFieldFromSchema) {
            defaultFieldFromSchema = addressFields.fields.find(f => f.name === field.id);
          }

          field.required = defaultFieldFromSchema ? defaultFieldFromSchema.required : field.required;
          if (field.id === 'is_default_contact' && setDefault) {
            field.value = true;
          }
          if (contact[field.id]) {
            field.value = contact[field.id];
          }
          if(field.id === 'company' || field.id === 'prospect') {
            field.disabled = true;
            field.required = defaultFieldFromSchema ? defaultFieldFromSchema.required : true;
            field.value = name;
          }

          if (contactAddress && contactAddress[field.id]) {
            field.value = contactAddress[field.id];
          }
          return field;
        });
        e.fields = fields;
        return e;
      });
    }
    state.fields = dataTemp;
  },
  [CLEAR_FIELDS]: (state) => {
    state.fields = [];
    state.selected.loading = false;
    state.selected.loaded = true;
  },
};

export const fieldsActions = {
  [GET_FIELDS]: async ({ commit, }, { entityId = false, setDefault = false }) => {
    const response = await httpRequest('get', 'config/schema/contacts', {}, {
      version: '2'
    });
    let entity = store.state.system.entity;

    const defaultFields = response.sections.find(sect => sect.name === 'Default');
    const addressFields = response.sections.find(sect => sect.name === 'Addresses');

    const data = (entity === 'customers') ? JSON.parse(JSON.stringify(customerContactData)) : JSON.parse(JSON.stringify(prospectContactData));

    // const data = JSON.parse(JSON.stringify(customerContactData));
    commit(GET_FIELDS, {
      entityId,
      setDefault,
      data,
      defaultFields,
      addressFields,
    });
  },
  [CLEAR_FIELDS]: async ({ commit, }) => {
    commit(CLEAR_FIELDS);
  },
};
