import {
  APPLY_NEW_SORT,
  APPLY_NEW_FILTER,
  FETCH_BUBBLE_DATA,
} from '@/intelligence/store/actionType';

import { t } from '@sales-i/utils';

import { 
  CUSTOMER_LAST_COMPLETE_PERIODS_BUT_NOT_BOUGHT_TITLE, 
  CUSTOMER_NOT_BUYING_TOP_PRODUCTS_TITLE, 
  CUSTOMER_WITH_ZERO_SPEND_TITLE 
} from '@/intelligence/store/data/reportTypes';
import { computed } from 'vue';

export default function useTemplateReport({ store } = {}) {
  const getCustomerAccountNoBubbleId = computed(() => store.getters['intelligence/templateReport/getCustomerAccountNoBubbleId']);
  const getProductBubbleId = computed(() => store.getters['intelligence/templateReport/getProductBubbleId']);

  const fetchBubbleIds = () => store.dispatch(`intelligence/templateReport/${FETCH_BUBBLE_DATA}`);
  const applyNewSort = (params) => store.dispatch(`intelligence/templateReport/${APPLY_NEW_SORT}`, params);
  const applyNewFilter = (params) => store.dispatch(`intelligence/templateReport/${APPLY_NEW_FILTER}`, params);

  const getProcessedReportTitle = (title) => {
    const sharedStore = store.state.intelligence.shared;
    switch (title) {
    case CUSTOMER_LAST_COMPLETE_PERIODS_BUT_NOT_BOUGHT_TITLE:
      title = t(
        `Last ${sharedStore.requestParameters.periods} complete months, but not yet bought this month`,
        'last_variableperiod_complete_periods_but_not_yet_bought_this_month',
        { interpolations: { variableperiod: sharedStore.requestParameters.periods }}
      );
      break;
    case CUSTOMER_NOT_BUYING_TOP_PRODUCTS_TITLE:
      title = t(
        `Top ${sharedStore.requestParameters.limit} Accounts that have not bought our most profitable products (in the last ${sharedStore.requestParameters.periods} months)`,
        'top_variablelimit_accounts_that_have_not_bought_our_most_profitable_products_in_the_last_variableperiod_months',
        { interpolations:
          { variablelimit: sharedStore.requestParameters.limit,
            variableperiod: sharedStore.requestParameters.periods
          },
        }
      );
      break;
    case CUSTOMER_WITH_ZERO_SPEND_TITLE:
      title = t(
        `Customers with zero spend in the past ${sharedStore.requestParameters.periods} months (lost accounts)`,
        'customers_with_zero_spend_in_the_past_variableperiod_months_lost_accounts',
        { interpolations: { variableperiod: sharedStore.requestParameters.periods }}
      );
      break;
    }
    return title;
  };

  return {
    getCustomerAccountNoBubbleId,
    getProductBubbleId,
    applyNewSort,
    applyNewFilter,
    getProcessedReportTitle,
    fetchBubbleIds
  };
}