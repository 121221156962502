/* eslint-disable no-unused-vars */
import {
  GET_PRODUCTS,
  GET_PRODUCTS_ERROR,
  CLEAR_PRODUCTS,
  GET_PRODUCTS_LOADING,

  SET_PRODUCT_ASSOCIATION_DETAILS,

  GET_CRM_PRODUCTS,
  CLEAR_CRM_PRODUCTS,
  GET_CRM_PRODUCTS_LOADING,
  GET_CRM_PRODUCTS_ERROR,
} from '@/admin/store/actionType';
import {
  DELETE_BY_ID,
  DELETE_BY_ID_LOADING,
  DELETE_BY_ID_ERROR,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import querystring from 'querystring';

// FOR DEMO PURPOSES, REMOVE AFTER
import useJwt from '@/shared/composables/useJwt';
const { isNapa } = useJwt();

const additionalAssociations = {
  'item_level': [
    {
      'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
      'rule_id': 94,
      'antecedent_code': 'NBO.NB.48880279',
      'antecedent_name': 'BRAKE ROTOR',
      'consequent_code': 'SRB.SR.SR914',
      'consequent_name': 'DISC PAD',
      'association_type': 'Item_Level',
      'confidence': 0.80,
      'min_sales_revenue': 10.4,
      'muted': false,
      'follow_up_days': 26,
      'created_date': '2022-11-01T23:23:37.821Z',
      'updated_date': '2023-05-18T12:51:40.746138+01:00',
      'created_by': 'Intelligence_User',
      'updated_by': 'Suraj Singh',
      'created_by_id': '00000000-0000-0000-0000-000000000000',
      'updated_by_id': 'bb8834cd-48a6-41bb-a17d-5023b864ce08',
    },
    {
      'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
      'rule_id': 94,
      'antecedent_code': 'MOT.MOB.124315',
      'antecedent_name': 'PURCHASED MOBIL 1 5W30 QT',
      'consequent_code': 'STN.SHE.550045126',
      'consequent_name': 'ROT T4 15W40 _1GL',
      'association_type': 'Item_Level',
      'confidence': 0.75,
      'min_sales_revenue': 10.4,
      'muted': false,
      'follow_up_days': 26,
      'created_date': '2022-11-01T23:23:37.821Z',
      'updated_date': '2023-05-18T12:51:40.746138+01:00',
      'created_by': 'Intelligence_User',
      'updated_by': 'Suraj Singh',
      'created_by_id': '00000000-0000-0000-0000-000000000000',
      'updated_by_id': 'bb8834cd-48a6-41bb-a17d-5023b864ce08',
    },
    {
      'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
      'rule_id': 94,
      'antecedent_code': 'RQR.RNR.NR2370B',
      'antecedent_name': 'RADIATOR',
      'consequent_code': 'NAF.NAF.1GAL',
      'consequent_name': 'NAPA ANTIFREEZE GAL',
      'association_type': 'Item_Level',
      'confidence': 0.65,
      'min_sales_revenue': 10.4,
      'muted': false,
      'follow_up_days': 26,
      'created_date': '2022-11-01T23:23:37.821Z',
      'updated_date': '2023-05-18T12:51:40.746138+01:00',
      'created_by': 'Intelligence_User',
      'updated_by': 'Suraj Singh',
      'created_by_id': '00000000-0000-0000-0000-000000000000',
      'updated_by_id': 'bb8834cd-48a6-41bb-a17d-5023b864ce08',
    },
    {
      'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
      'rule_id': 94,
      'antecedent_code': 'RQR.RNR.NR2370B',
      'antecedent_name': 'RADIATOR',
      'consequent_code': 'NAF.NAF.RTU1EXT',
      'consequent_name': 'RTU EXT/LIFE GAL',
      'association_type': 'Item_Level',
      'confidence': 0.70,
      'min_sales_revenue': 10.4,
      'muted': false,
      'follow_up_days': 26,
      'created_date': '2022-11-01T23:23:37.821Z',
      'updated_date': '2023-05-18T12:51:40.746138+01:00',
      'created_by': 'Intelligence_User',
      'updated_by': 'Suraj Singh',
      'created_by_id': '00000000-0000-0000-0000-000000000000',
      'updated_by_id': 'bb8834cd-48a6-41bb-a17d-5023b864ce08',
    },
  ],
  group_level: [
    {
      'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
      'rule_id': 94,
      'antecedent_code': '997',
      'antecedent_name': 'Napa Brake Rotor',
      'consequent_code': '780',
      'consequent_name': 'Napa Brake Pads',
      'association_type': 'Group_Level',
      'confidence': 0.75,
      'min_sales_revenue': 10.4,
      'muted': false,
      'follow_up_days': 26,
      'created_date': '2022-11-01T23:23:37.821Z',
      'updated_date': '2023-05-18T12:51:40.746138+01:00',
      'created_by': 'Intelligence_User',
      'updated_by': 'Suraj Singh',
      'created_by_id': '00000000-0000-0000-0000-000000000000',
      'updated_by_id': 'bb8834cd-48a6-41bb-a17d-5023b864ce08',
    },
    {
      'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
      'rule_id': 94,
      'antecedent_code': '998',
      'antecedent_name': 'Motor Oil',
      'consequent_code': '779',
      'consequent_name': 'HD Oil Filters and LD Oil Filters',
      'association_type': 'Group_Level',
      'confidence': 0.70,
      'min_sales_revenue': 10.4,
      'muted': false,
      'follow_up_days': 26,
      'created_date': '2022-11-01T23:23:37.821Z',
      'updated_date': '2023-05-18T12:51:40.746138+01:00',
      'created_by': 'Intelligence_User',
      'updated_by': 'Suraj Singh',
      'created_by_id': '00000000-0000-0000-0000-000000000000',
      'updated_by_id': 'bb8834cd-48a6-41bb-a17d-5023b864ce08',
    },
    {
      'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
      'rule_id': 94,
      'antecedent_code': '998',
      'antecedent_name': 'Motor Oil',
      'consequent_code': '778',
      'consequent_name': 'LD Oil Filters',
      'association_type': 'Group_Level',
      'confidence': 0.80,
      'min_sales_revenue': 10.4,
      'muted': false,
      'follow_up_days': 26,
      'created_date': '2022-11-01T23:23:37.821Z',
      'updated_date': '2023-05-18T12:51:40.746138+01:00',
      'created_by': 'Intelligence_User',
      'updated_by': 'Suraj Singh',
      'created_by_id': '00000000-0000-0000-0000-000000000000',
      'updated_by_id': 'bb8834cd-48a6-41bb-a17d-5023b864ce08',
    },
    {
      'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
      'rule_id': 94,
      'antecedent_code': '999',
      'antecedent_name': 'Napa Radiators',
      'consequent_code': '777',
      'consequent_name': 'Napa Antifreeze',
      'association_type': 'Group_Level',
      'confidence': 0.65,
      'min_sales_revenue': 10.4,
      'muted': false,
      'follow_up_days': 26,
      'created_date': '2022-11-01T23:23:37.821Z',
      'updated_date': '2023-05-18T12:51:40.746138+01:00',
      'created_by': 'Intelligence_User',
      'updated_by': 'Suraj Singh',
      'created_by_id': '00000000-0000-0000-0000-000000000000',
      'updated_by_id': 'bb8834cd-48a6-41bb-a17d-5023b864ce08',
    },
    {
      'tenant_id': '00101581-3dd4-40b8-a2e3-175624586f85',
      'rule_id': 94,
      'antecedent_code': '1000',
      'antecedent_name': 'Brake Calipers',
      'consequent_code': '776',
      'consequent_name': 'Brake Pads',
      'association_type': 'Group_Level',
      'confidence': 0.75,
      'min_sales_revenue': 10.4,
      'muted': false,
      'follow_up_days': 26,
      'created_date': '2022-11-01T23:23:37.821Z',
      'updated_date': '2023-05-18T12:51:40.746138+01:00',
      'created_by': 'Intelligence_User',
      'updated_by': 'Suraj Singh',
      'created_by_id': '00000000-0000-0000-0000-000000000000',
      'updated_by_id': 'bb8834cd-48a6-41bb-a17d-5023b864ce08',
    },
  ]
};
// END

// getters
export const getters = {
};

// mutations
export const productsMutations = {
  [GET_PRODUCTS]: (state, data) => {
    state.products.data = data;
    state.products.loaded = true;
    state.products.loading = false;
    state.products.errors = [];
  },
  [GET_PRODUCTS_LOADING]: (state, data) => {
    state.products.loaded = false;
    state.products.loading = true;
    state.products.errors = [];
  },
  [GET_PRODUCTS_ERROR]: (state) => {
    state.products.loaded = false;
    state.products.loading = true;
    state.products.errors = ['Failed to get products'];
  },
  [DELETE_BY_ID]: (state, parameters) => {
    state.products.loaded = true;
    state.products.loading = false;
    state.products.errors = [];
  },
  [DELETE_BY_ID_LOADING]: (state) => {
    state.products.loading = true;
    state.products.loaded = false;
    state.products.errors = [];
  },
  [DELETE_BY_ID_ERROR]: (state, e) => {
    state.products.loading = false;
    state.products.loaded = false;
    state.products.errors = ['Failed to delete'];
  },
  [CLEAR_PRODUCTS]: (state) => {
    state.products.data = [];
    state.products.loaded = false;
    state.products.loading = true;
    state.products.errors = undefined;
  },

  [SET_PRODUCT_ASSOCIATION_DETAILS]: (state, data) => {
    // Find the product
    const { item, value, key } = data;
    const productIndex = state.products.data.findIndex(
      o => o.antecedent_code === item.antecedent_code && o.consequent_code === item.consequent_code
    );

    if (productIndex === -1) {
      // Item not found, don't update it
      return;
    }

    item[key] = parseInt(value, 10);

    state.products.data[productIndex] = item;
  },

  [GET_CRM_PRODUCTS]: (state, data) => {
    state.crmProducts.data = data;
    state.crmProducts.loaded = true;
    state.crmProducts.loading = false;
    state.crmProducts.errors = [];
  },
  [GET_CRM_PRODUCTS_LOADING]: (state) => {
    state.crmProducts.loaded = false;
    state.crmProducts.loading = true;
  },
  [CLEAR_CRM_PRODUCTS]: (state) => {
    state.crmProducts.data = [];
    state.crmProducts.loaded = true;
    state.crmProducts.loading = false;
    state.crmProducts.errors = [];
  },
  [GET_CRM_PRODUCTS_ERROR]: (state, errors) => {
    state.crmProducts.loaded = true;
    state.crmProducts.loading = false;
    state.crmProducts.errors = errors;
  },
};

// actions
export const productsActions = {
  [GET_PRODUCTS]: async ({ commit, }, parameters) => {
    commit(GET_PRODUCTS_LOADING);
    try {
      const params = querystring.stringify(parameters);
      let data = await httpRequest('get', `associations?${params}`, {}, {
        area: 'associations',
        version: '2',
      });
      const addAdditionalData = await isNapa();
      if (addAdditionalData) {
        data = [
          ...additionalAssociations[parameters.type],
          ...data,
        ];
      }
      commit(GET_PRODUCTS, data);
    } catch (e) {
      commit(GET_PRODUCTS_ERROR);
    }
  },
  [GET_CRM_PRODUCTS]: async ({ commit }, parameters) => {
    commit(GET_CRM_PRODUCTS_LOADING);
    try {
      let { q, limit, offset } = parameters;
      const params = querystring.stringify({ q, limit, offset });
      let data = await httpRequest('get', `products?${params}`);
      commit(GET_CRM_PRODUCTS, data);
    } catch (e) {
      commit(GET_CRM_PRODUCTS_ERROR, [e]);
    }
  },
  [DELETE_BY_ID]: async ({ commit, }, parameters) => {
    commit(DELETE_BY_ID_LOADING);
    try {
      await httpRequest('delete', 'associations', parameters, {
        version: 2,
        area: 'associations',
      });
      commit(DELETE_BY_ID);
    } catch (e) {
      commit(DELETE_BY_ID_ERROR);
    }
  },
  [CLEAR_CRM_PRODUCTS]: ({ commit }) => {
    commit(CLEAR_CRM_PRODUCTS);
  },
  [CLEAR_PRODUCTS]: ({ commit, }) => {
    commit(CLEAR_PRODUCTS);
  },
  [SET_PRODUCT_ASSOCIATION_DETAILS]: ({ commit }, parameters) => {
    commit(SET_PRODUCT_ASSOCIATION_DETAILS, parameters);
  },
};
