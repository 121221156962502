import { t } from '@sales-i/utils';

export const REPORTS_KPI_REVENUE_TITLE = t('Revenue');
export const REPORTS_KPI_PROFIT_TITLE = t('Profit');
export const REPORTS_KPI_COST_TITLE = t('Cost');
export const REPORTS_KPI_AMOUNT_TITLE = t('Amount');
export const REPORTS_KPI_MARGIN_TITLE = t('Margin');
export const REPORTS_KPI_NAME_TITLE = t('Name');
export const REPORTS_KPI_PERCENT_DOWN_TITLE = t('% Down');
export const REPORTS_KPI_CUSTOMER_TITLE = t('Customer');
export const REPORTS_KPI_PREVIOUS_REVENUE_TITLE = t('Previous revenue');
export const REPORTS_KPI_PRODUCT_NAME_TITLE = t('Product Name');
export const REPORTS_KPI_PRODUCT_GROUP_NAME_TITLE = t('Product Group Name');
export const REPORTS_KPI_CUSTOMER_NAME_TITLE = t('Customer Name');
export const REPORTS_KPI_VALUE_TITLE = t('Value');
export const REPORTS_KPI_SALES_VALUE_TITLE = t('Sales Value');
export const REPORTS_KPI_GROSS_PROFIT_TITLE = t('Gross Profit');
export const REPORTS_KPI_QUANTITY_TITLE = t('Quantity');
export const REPORTS_KPI_COUNT_TITLE = t('Count');
export const REPORTS_KPI_CODE_TITLE = t('Code');
export const REPORTS_KPI_DESCRIPTION_TITLE = t('Description');
export const REPORTS_KPI_ACCOUNT_NUMBER_TITLE = t('Account Number');
export const REPORTS_KPI_TOTAL_INTERACTIONS_TITLE = t('Total Interactions');
export const REPORTS_KPI_INVOICES_TITLE = t('Invoices');
export const REPORTS_KPI_INVOICE_ITEMS_TITLE = t('Invoice items');
export const REPORTS_KPI_AVERAGE_PRICE_TITLE = t('Average Price');

export const REPORTS_KPI_REVENUE = 'revenue';
export const REPORTS_KPI_PROFIT = 'profit';
export const REPORTS_KPI_COST = 'cost';
export const REPORTS_KPI_AMOUNT = 'units_sold';
export const REPORTS_KPI_MARGIN = 'margin';
export const REPORTS_KPI_NAME = 'name';
export const REPORTS_KPI_PERCENT_DOWN = 'percent_down';
export const REPORTS_KPI_CUSTOMER = 'customer';
export const REPORTS_KPI_PREVIOUS_REVENUE = 'previous_revenue';
export const REPORTS_KPI_VALUE = 'value';
export const REPORTS_KPI_SALES_VALUE = 'sales_value';
export const REPORTS_KPI_GROSS_PROFIT = 'gross_profit';
export const REPORTS_KPI_QUANTITY = 'quantity';
export const REPORTS_KPI_AVERAGE_PRICE = 'average_price';
export const REPORTS_KPI_COUNT = 'count';
export const REPORTS_KPI_CODE = 'code';
export const REPORTS_KPI_DESCRIPTION = 'description';
export const REPORTS_KPI_ACCOUNT_NUMBER = 'account_number';
export const REPORTS_KPI_TOTAL_INTERACTIONS = 'total_interactions';
export const REPORTS_KPI_INVOICES = 'invoice_count';
export const REPORTS_KPI_INVOICE_ITEMS = 'average_items_per_invoice';

// what can we show in customer demo mode
export const REPORTS_KPIS_FOR_CUSTOMER_MODE = [
  REPORTS_KPI_SALES_VALUE, 
  REPORTS_KPI_QUANTITY, 
  REPORTS_KPI_AMOUNT, 
  REPORTS_KPI_COUNT, 
  REPORTS_KPI_INVOICES,
  REPORTS_KPI_INVOICE_ITEMS
];

// enquiry bubble captions
export const ENQUIRY_AXIS_X = { value: 'xaxis', title: t('X-Axis') };
export const ENQUIRY_AXIS_Y = { value: 'yaxis', title: t('Y-Axis') };

export const ENQUIRY_DIMENSION_1 = { value: 'dim1', title: t('Dimension 1') };
export const ENQUIRY_DIMENSION_2 = { value: 'dim2', title: t('Dimension 2') };

export const ENQUIRY_AXES = [ ENQUIRY_AXIS_X, ENQUIRY_AXIS_Y ];
export const ENQUIRY_DIMENSIONS = [ ENQUIRY_DIMENSION_1, ENQUIRY_DIMENSION_2 ];

export const MEASURES = [
  { text: REPORTS_KPI_SALES_VALUE_TITLE, value: REPORTS_KPI_VALUE }, // default
  { text: REPORTS_KPI_COST_TITLE, value: REPORTS_KPI_COST },
  { text: REPORTS_KPI_PROFIT_TITLE, value: REPORTS_KPI_PROFIT },
  { text: REPORTS_KPI_QUANTITY_TITLE, value: REPORTS_KPI_QUANTITY },
  { text: REPORTS_KPI_COUNT_TITLE, value: REPORTS_KPI_COUNT },
  { text: REPORTS_KPI_INVOICES_TITLE, value: REPORTS_KPI_INVOICES }, // this is the number of invoices
  { text: REPORTS_KPI_INVOICE_ITEMS_TITLE, value: REPORTS_KPI_INVOICE_ITEMS }, // this is the average number of items on an invoice
];

export const VARIANCE_COLUMNS = [
  { text: REPORTS_KPI_SALES_VALUE_TITLE, value: REPORTS_KPI_SALES_VALUE },
  { text: REPORTS_KPI_COST_TITLE, value: REPORTS_KPI_COST },
  { text: REPORTS_KPI_PROFIT_TITLE, value: REPORTS_KPI_PROFIT },
  { text: REPORTS_KPI_GROSS_PROFIT_TITLE, value: REPORTS_KPI_GROSS_PROFIT },
  { text: REPORTS_KPI_AVERAGE_PRICE_TITLE, value: REPORTS_KPI_AVERAGE_PRICE },
  { text: REPORTS_KPI_QUANTITY_TITLE, value: REPORTS_KPI_QUANTITY },
  { text: REPORTS_KPI_COUNT_TITLE, value: REPORTS_KPI_COUNT },
  { text: REPORTS_KPI_INVOICES_TITLE, value: REPORTS_KPI_INVOICES },
  { text: REPORTS_KPI_INVOICE_ITEMS_TITLE, value: REPORTS_KPI_INVOICE_ITEMS },
];