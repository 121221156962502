<template>
  <MenuFilter
    v-for="(filter, key) in filters"
    :key="key"
    class="select"
    :items="filter"
    :menu-props="{ bottom: true }"
    menu-position="right"
    :selected-value="values?.[key]"
    @on-change="onSelectedFilter(key, $event)"
  />
</template>

<script setup>
// ChartFilters
import { MenuFilter } from '@sales-i/dsv3';

const props = defineProps({
  filters: {
    type: Object,
    default: () => ({}),
  },
  values: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['update:filter']);

function onSelectedFilter(key, { value }) {
  emit('update:filter', { ...props.values, [key]: value });
}
</script>

<style lang="scss" scoped>
.menu-filter {
  margin-right: var(--spacing-2);
}

.menu-filter:last-child {
  margin-right: 0;
}
</style>
