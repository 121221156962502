import TargetsView from '@/admin/views/Company/TargetsSettingsView.vue';
import AddEditTarget from '@/intelligence/components/Targets/AddEditTarget/AddEditTarget.vue';
import TargetsReport from '@/intelligence/components/Targets/TargetsReport.vue';
import { targetsSection as section } from '@/admin/router/urlBits';

export default [
  {
    path: section,
    component: TargetsView,
    children: [
      {
        path: '',
        component: TargetsReport,
        children: [
          {
            path: 'add',
            component: AddEditTarget,
          },
          {
            path: ':id(\\d+)/edit',
            component: AddEditTarget,
          },
        ]
      },
    ],
  }
];
