<template>
  <CustomCard
    purpose="reversed"
    class="ai-display-card"
  >
    <template #header>
      <h2 class="fw-sbld text-center">
        {{ t('Customer Opportunities') }}
      </h2>
    </template>
    <div class="summary-overview">
      <p class="text-center mb-3 text-3">
        {{ t('AI Assisted Selling') }}
      </p>
      <p>
        {{ t('sales-i has looked at the buying patterns of') }} <span>{{ summaryOverview?.customer_count }} {{ t('customers') }}</span> {{ t('across') }}
        <span>{{ summaryOverview?.product_count }} {{ t('products') }}</span> {{ t('to identify potential opportunities for you to investigate and add to your customer conversations.') }}
      </p>
      <p>
        {{ t('AI has identified') }} <span>{{ summaryOverview?.opportunity_count }} {{ t('opportunities') }}</span> {{ t('at total value of') }} 
        <span>{{ cPrice(summaryOverview?.opportunity_value) }}</span> {{ t('for you to act on.') }}
      </p>
    </div>
    <div class="flex">
      <SearchInput
        class="search-field"
        :placeholder="t('Search')"
        :value="searchQuery"
        icon-color="var(--colour-utility-action)"
        @search="handleSearch"
      />
      <MenuFilter
        :items="MONTHLY_FILTER_SET"
        :selected-value="selectedDateRange"
        :menu-props="{ bottom: true }"
        menu-position="right"
        @on-change="setDate"
      />
    </div>
    <CustomerOpportunitiesTable
      v-if="tableData.length > 0"
      :table-data="tableData"
      :current-sort="currentSort"
      :sort-direction="sortDirection"
      :search-query="searchQuery"
      @sort="sort"
    />
    <p
      v-else
      class="no-results text-center mt-3"
    >
      {{ t('No results were found') }}
    </p>
    <CustomButton
      v-if="tableData.length > 0"
      small
      purpose="action"
      :label="t('Plot to Map')"
      class="map-action-button"
      @click="toggleAddressModal"
    />
    <AddressModal
      v-if="showCustomerMapModal && addressesLoaded"
      :customers="tableData"
      @close="toggleAddressModal"
    />
  </CustomCard>
</template>
<script setup>
// CustomerOpportunitiesView
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import CustomerOpportunitiesTable from '@/intelligence/components/CustomerOpportunities/CustomerOpportunitiesTable.vue';
import AddressModal from '@/intelligence/views/Communities/AddressModal.vue';
import { MenuFilter, CustomCard, CustomButton } from '@sales-i/dsv3';
import { getDateTimeOptions } from '@/intelligence/store/data/dateFilters';
import { FETCH_REPORT_DATA, FETCH_DATES } from '@/intelligence/store/actionType';
import { GET_ADDRESSES } from '@/crm/store/actionType';
import { REPORTS_SORT_ASCENDING_KEY, REPORTS_SORT_DESCENDING_KEY } from '@/intelligence/store/data/apiInput';
import useCurrency from '@/shared/composables/useCurrency';
import SearchInput from '@/dsv3/Forms/SearchInput.vue';

const store = useStore();
const { cPrice } = useCurrency();
const MONTHLY_FILTER_SET = getDateTimeOptions({ isMonthly: true });

const currentSort = ref('');
const searchQuery = ref('');
const sortDescending = ref(true);
const showCustomerMapModal = ref(false);
const selectedDateRange = ref('today-1y');
const dateParams = ref({ date_from: 'today-12m', date_to: 'today' });

const fetchReportData = async params => await store.dispatch(`intelligence/customerOpportunities/${FETCH_REPORT_DATA}`, params);
const fetchDates = params => store.dispatch(`intelligence/calendar/${FETCH_DATES}`, params);
const getAddresses = params => store.dispatch(`crm/customers/${GET_ADDRESSES}`, params);

const addressesLoaded = computed(() => store.state.crm.customers.addresses.loaded);
const fetchedDates = computed(() => store.state.intelligence.calendar.fetchedDates);
const sortDirection = computed(() => sortDescending.value ? REPORTS_SORT_DESCENDING_KEY : REPORTS_SORT_ASCENDING_KEY);
const tableData = computed(() => store.state.intelligence.customerOpportunities.data.opportunities || []);
const summaryOverview = computed(() => store.state.intelligence.customerOpportunities.data?.summary_overview);

onMounted(async () => {
  const fetchedDates = await fetchDates(dateParams.value);
  await fetchReportData(fetchedDates);
});

async function setDate(dates) {
  if (dates) {
    dateParams.value.date_from = dates.date_from;
    dateParams.value.date_to = dates.date_to;
    selectedDateRange.value = dates.value;
  }
  const fetchedDates = await fetchDates(dateParams.value);
  const parameters = { ...fetchedDates };
  if (currentSort.value) parameters.sort = `${currentSort.value}:${sortDirection.value}`;
  await fetchReportData(parameters);
}
function handleSearch(value) {
  searchQuery.value = value;
}
async function toggleAddressModal() {
  showCustomerMapModal.value = !showCustomerMapModal.value;

  if (showCustomerMapModal.value) {
    const formattedIds = tableData.value.map(opportunity => ({ id: opportunity.customer_id }));
    if (formattedIds.length > 0) await getAddresses(formattedIds);
  }
}
async function sort(headerValue) {
  if (headerValue === currentSort.value) {
    sortDescending.value = !sortDescending.value;
  } else {
    sortDescending.value = true;
    currentSort.value = headerValue;
  }

  const sort = `${currentSort.value}:${sortDirection.value}`;
  const { date_from, date_to } = fetchedDates.value;
  await fetchReportData({sort, date_from, date_to});
}

</script>

<style lang="scss" scoped>
.summary-overview {
  p {
    margin-bottom: var(--spacing-1);
    font-size: var(--font-size-4);
    
    span {
      font-weight: var(--font-weight-semibold);
    }
  }
}

.ai-display-card {
  margin-top: var(--spacing-5);
  .ai-body-content {
    margin-top: var(--spacing-1);
    padding-left: var(--spacing-4);
    padding-right: var(--spacing-4);
    > div {
      margin-top: var(--spacing-3);
    }
  }
}

h2 {
  margin-bottom: var(--spacing-3);
}

.search-field {
  margin-bottom: 0;
}

.map-action-button {
  margin-left: auto;
}
</style>