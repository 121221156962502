import {
  POST_BY_ENTITY,
  POST_BY_ENTITY_LOADING,
  POST_BY_ENTITY_ERROR
} from '@/shared/store/actionType';
import { httpRequest, pluralise } from '@sales-i/utils';

// mutations
export const postByEntityMutations = {
  [POST_BY_ENTITY](state, payload) {
    state.entity.data = payload;
    state.entity.loaded = true;
    state.entity.loading = false;
  },
  [POST_BY_ENTITY_LOADING]: (state) => {
    state.entity.loaded = false;
    state.entity.loading = true;
  },
  [POST_BY_ENTITY_ERROR]: (state) => {
    state.entity.loaded = true;
    state.entity.loading = false;
  },
};

// actions
export const postByEntityActions = {
  [POST_BY_ENTITY]: async ({ commit, state }, { id, tags, entity }) => {
    const options = entity === 'file' ? { area: 'files' } : {};
    try {
      // Build the URL for the tags call
      const requestUrl = `${pluralise.string(entity)}/${id}/tags`;
      // Fetch the list of tags for a customer, and return the promise
      commit(POST_BY_ENTITY_LOADING);
      await httpRequest('post', requestUrl, tags, {
        version: '2', ...options
      });
      commit(POST_BY_ENTITY, [...state.entity.data, ...tags]);
    } catch (error) {
      commit(POST_BY_ENTITY_ERROR, error);
    }
  }
};
