import {
  GET_BY_ID,
  GET_BY_ID_LOADING,
  GET_BY_ID_ERROR,
  CLEAR_BY_ID
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const getByIdMutations = {
  [GET_BY_ID]: (state, data) => {
    state.selected.data = data;
    state.selected.loaded = true;
    state.selected.loading = false;
  },
  [GET_BY_ID_LOADING]: (state) => {
    state.selected.loaded = false;
    state.selected.loading = true;
  },
  [GET_BY_ID_ERROR]: (state) => {
    state.selected.loaded = false;
    state.selected.loading = false;
  },
  [CLEAR_BY_ID]: (state) => {
    state.selected.data = {};
    state.selected.loaded = false;
    state.selected.loading = false;
  },
};

// actions
export const getByIdActions = {
  [GET_BY_ID]: async ({ commit, }, parameters) => {
    try {
      const { id, comment_id, options = {version: '2'}  } = parameters;
      commit(GET_BY_ID_LOADING);
      const data = await httpRequest('get', `posts/${id}/comments/${comment_id}`, {}, options);
      commit(GET_BY_ID, data);
      return data;
    } catch (error) {
      console.error(error);
      commit(GET_BY_ID_ERROR);
      return false;
    }
  },
  [CLEAR_BY_ID]: ({ commit, }) => {
    commit(CLEAR_BY_ID);
  },
};
