import { httpRequest } from '@sales-i/utils';
// import querystring from 'querystring';

import {
  GET_DEFAULT,
  GET_DEFAULT_LOADING,
  GET_DEFAULT_ERROR,
  SET_DEFAULT,
  SET_DEFAULT_LOADING,
  SET_DEFAULT_ERROR,
  CLEAR_DEFAULT,
} from '@/shared/store/actionType';

// mutations
export const getSetDefaultMutations = {
  [GET_DEFAULT]: (state, data) => {
    state.default.data = data;
    state.default.loaded = true;
    state.default.loading = false;
  },
  [GET_DEFAULT_LOADING]: (state) => {
    state.default.loaded = false;
    state.default.loading = true;
  },
  [GET_DEFAULT_ERROR]: (state, data) => {
    state.default.errors = data;
    state.default.loaded = false;
    state.default.loading = false;
  },
  [SET_DEFAULT]: (state, data) => {
    state.default.data = data;
    state.selected.updated = true;
    state.default.loaded = true;
    state.default.loading = false;
  },
  [SET_DEFAULT_LOADING]: (state) => {
    state.default.loaded = false;
    state.default.loading = true;
  },
  [SET_DEFAULT_ERROR]: (state) => {
    state.default.loaded = false;
    state.default.loading = false;
  },
  [CLEAR_DEFAULT]: (state) => {
    state.default.data = {};
    state.default.loaded = true;
    state.default.loading = false;
  },
};

// actions
export const getSetDefaultActions = {
  [GET_DEFAULT]: async ({ commit, }, parameters) => {
    try {
      const { entity, id, } = parameters;
      commit(GET_DEFAULT_LOADING);

      const data = await httpRequest('get', `${entity}/${id}/contacts/default`, {}, { version: '2', throwErrors: false, });
      commit(GET_DEFAULT, data);
    } catch (error) {
      if (error.status === 404) {
        commit(GET_DEFAULT, {});
      } else {
        commit(GET_DEFAULT_ERROR, error.errors);
      }
    }
  },
  [SET_DEFAULT]: async ({ commit, }, parameters) => {
    try {
      const { entity, id, contact_id, } = parameters;
      commit(SET_DEFAULT_LOADING);

      // const data = await httpRequest('get', `list/contact?${params}`);
      const data = await httpRequest('put', `${entity}/${id}/contacts/default`, { id: contact_id }, {version: '2',});
      commit(SET_DEFAULT, data);
    } catch (error) {
      commit(SET_DEFAULT_ERROR);
    }
  },
  [CLEAR_DEFAULT]: ({ commit, }) => {
    commit(CLEAR_DEFAULT);
  },
};
