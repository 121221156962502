<template>
  <CustomCard
    v-if="noAddress && !loading && canUpdate"
    class="no-address-details"
    purpose="reversed"
    footer-color="grey"
  >
    <template #header>
      <div class="header">
        <h3 class="fw-sbld">
          {{ t('No address details') }}
        </h3>
      </div>
    </template>
    <div class="default-contact--body">
      <p class="text-sm mb-0">
        {{ t("Why don't you add some?") }}
      </p>
    </div>
    <template #footer>
      <ButtonGroup>
        <CustomButton
          small
          :label="t('Edit prospect')"
          @on-click="editProspect"
        />
      </ButtonGroup>
    </template>
  </CustomCard>
  <BufferImage
    v-if="loading"
    class="center"
  />
  <CustomerProspectDetails
    :no-address="noAddress"
    :customer="addressDetails"
  />
</template>

<script setup>
import { computed } from 'vue';
import { CustomButton, CustomCard, BufferImage } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { prospects } from '@/shared/store/data/policies';
import usePermissions from '@/shared/composables/usePermissions';
import { t } from '@sales-i/utils';
import CustomerProspectDetails from '@/crm/components/CustomerProspect/CustomerProspectDetails.vue';

const { hasAccess } = usePermissions();
const canUpdate = computed(() => hasAccess(prospects, 'update'));

const props = defineProps({
  addressDetails: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const noAddress = computed(() => {
  if (!props.addressDetails.addresses || !props.addressDetails.addresses.length) return true;
  const addressProps = ['address_line_1', 'address_line_2', 'address_line_3', 'address_line_4', 'town', 'county', 'post_code', 'country'];
  let noAddressDetails = true;
  const address = props.addressDetails.addresses[0];
  addressProps.forEach(prop => {
    if (address[prop]) {
      noAddressDetails = false;
    }
  });
  return noAddressDetails;
});

const emit = defineEmits(['editProspect']);

function editProspect() {
  emit('editProspect');
}
</script>

<style lang="scss" scoped>
.no-address-details {
  height: auto;
}
</style>
