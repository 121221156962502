import { computed, ref } from 'vue';
import { BAR_CHART, DEFAULT_WIDGET_SETTINGS, LINE_CHART } from '@/intelligence/components/Dashboard/constants';
import { APPLY_DATE_RANGE, FETCH_DATES, SET_LOADING } from '@/intelligence/store/actionType';
import { DATE_FILTER_KEYS, DATE_FILTER_OPTIONS_V2 } from '@/intelligence/store/data/dateFilters';
import { t } from '@sales-i/utils';
import { PIE_CHART, MULTIBAR_CHART } from '@/intelligence/components/Dashboard/constants';

export function useWidget({ store, props, options } = {}) {

  const getSortedAndSlicedData = ({ arr = [], sort = 'asc', limit, key }) => {
    return [...arr]
      .sort((a, b) => (sort == 'asc' ? a[key] - b[key] : b[key] - a[key]))
      .slice(0, limit || DEFAULT_WIDGET_SETTINGS.limit);
  };

  const getDateParams = (filtersValue) => {
    let dateObject = {};
    Object.keys(filtersValue).forEach(key => {
      if (DATE_FILTER_KEYS.includes(key)) {
        dateObject[key] = filtersValue[key];
      }
    });
    if (!Object.keys(dateObject).length) {
      dateObject = {
        date_from: 'today/y',
        date_to: 'today',
      };
    }
    return dateObject;
  };

  const getDateFilterTitle = ({ date_from, date_to}) => {
    const predefinedOptionValue = Object.values(DATE_FILTER_OPTIONS_V2).find(
      option => option.date_from === date_from && option.date_to === date_to
    );
    return `${predefinedOptionValue ? predefinedOptionValue.text : `${date_from} .. ${date_to}`}`;
  };

  const fetchDates = params => store.dispatch(`intelligence/calendar/${FETCH_DATES}`, params);
  const applyDate = params => store.dispatch(`intelligence/shared/${APPLY_DATE_RANGE}`, params);
  const setLoading = params => store.dispatch(`intelligence/shared/${SET_LOADING}`, params);
  const fetchedDates = computed(() => store.state.intelligence.calendar.fetchedDates);

  const currentChartType = computed(() => 
    props.settings.chartTypes?.[currentChartIndex.value] ||
    (options?.chartTypes || [BAR_CHART])[0]
  );
  const currentChartIndex = ref(0);

  const chartAndTableMenuOptions = computed(() => {
    const { id, dashboard_id } = props.item;

    const chartTypeOptions = props.settings.isChartShown
      ? [
        {
          action: 'patch',
          chartType: BAR_CHART,
          value: {
            id,
            dashboard_id,
            settings: {
              ...props.settings,
              chartTypes: { ...props.settings.chartTypes, [currentChartIndex.value]: BAR_CHART },
            },
          },
          title: t('Show Bar chart'),
          type: 'chart'
        },
        {
          action: 'patch',
          chartType: LINE_CHART,
          value: {
            id,
            dashboard_id,
            settings: {
              ...props.settings,
              chartTypes: { ...props.settings.chartTypes, [currentChartIndex.value]: LINE_CHART },
            },
          },
          title: t('Show Line chart'),
          type: 'chart'
        },
        {
          action: 'patch',
          chartType: PIE_CHART,
          value: {
            id,
            dashboard_id,
            settings: {
              ...props.settings,
              chartTypes: { ...props.settings.chartTypes, [currentChartIndex.value]: PIE_CHART },
            },
          },
          title: t('Show Pie chart'),
          type: 'chart'
        },
        {
          action: 'patch',
          chartType: MULTIBAR_CHART,
          value: {
            id,
            dashboard_id,
            settings: {
              ...props.settings,
              chartTypes: { ...props.settings.chartTypes, [currentChartIndex.value]: MULTIBAR_CHART },
            },
          },
          title: t('Show Multi Bar'),
          type: 'chart'
        },
      /** removing current one from the list */
      ].filter(option => (options?.chartTypes === undefined || options?.chartTypes.includes(option.chartType)) && option.chartType !== currentChartType.value)
      : [];

    const chartTableShowOptions = [
      {
        action: 'patch',
        title: props.settings.isChartShown ? t('Hide chart') : t('Show chart'),
        value: { id, dashboard_id, settings: { ...props.settings, isChartShown: !props.settings.isChartShown } },
        type: 'chart'
      },
      {
        action: 'patch',
        title: props.settings.isTableShown ? t('Hide table') : t('Show table'),
        value: { id, dashboard_id, settings: { ...props.settings, isTableShown: !props.settings.isTableShown } },
        type: 'table'
      },
    ];

    return [...chartTypeOptions, ...chartTableShowOptions];
  });

  function stripWidgetUrlParams(url) {
    if (!url) return '';
    return url.replace(/&?dashboard_id=[\w-]+/, '').replace(/&?id=[\w-]+/, '');
  }
  
  
  return {
    stripWidgetUrlParams,
    getSortedAndSlicedData,
    getDateParams,
    getDateFilterTitle,
    fetchDates,
    applyDate,
    setLoading,
    fetchedDates,
    chartAndTableMenuOptions,
    currentChartType,
    currentChartIndex,
    DEFAULT_WIDGET_SETTINGS
  };
}