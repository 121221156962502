import {
  DELETE_PRODUCTS,
  DELETE_PRODUCTS_LOADING,
  DELETE_PRODUCTS_ERROR,
} from '@/admin/store/actionType';
import { httpRequest } from '@sales-i/utils';

export const deleteProductsCommunitiesMutations = {
  [DELETE_PRODUCTS]: (state) => {
    state.loaded = true;
    state.loading = false;
    state.error = null;
  },
  [DELETE_PRODUCTS_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.error = null;
  },
  [DELETE_PRODUCTS_ERROR]: (state) => {
    state.loading = false;
    state.loaded = false;
    state.error = 'Failed to delete community products';
  },
};

export const deleteProductsCommunitiesActions = {
  [DELETE_PRODUCTS]: async ({ commit }, { communityCode, products }) => {
    commit(DELETE_PRODUCTS_LOADING);
    try {
      await httpRequest('delete', `communities/${communityCode}/products`, products, {
        version: 2,
        area: 'associations',
      });
      commit(DELETE_PRODUCTS);
    } catch (e) {
      commit(DELETE_PRODUCTS_ERROR);
    }
  },
};
