import { SAVE_REPORT_PARAMETERS, RESET_REPORT_PARAMETERS } from '@/intelligence/store/actionType';

const LAZY_LOAD_PARAMETERS = ['rows', 'columnHeadings', 'summaryData', 'dimensions'];

// initial state
const state = () => ({
  rows: [],
  columnHeadings: [],
  summaryData: {},
  dimensions: [],
});

// getters
const getters = {};

// mutations
const mutations = {
  [SAVE_REPORT_PARAMETERS]: (state, parameters) => {
    Object.keys(parameters).forEach(key => {
      if (!LAZY_LOAD_PARAMETERS.includes(key)) {
        console.error(`Not valid parameter name "${key}".`);
        return;
      }
      if (key === 'rows') {
        state[key].push(...parameters.rows);
        return;
      }
      state[key] = parameters[key];
    });
  },
  [RESET_REPORT_PARAMETERS]: (state) => {
    state.rows = [];
    state.columnHeadings = [];
    state.summaryData = [];
    state.dimensions = [];
  },
};

// actions
const actions = {
  [SAVE_REPORT_PARAMETERS]: ({commit}, data) => {
    commit(SAVE_REPORT_PARAMETERS, data);
  },
  [RESET_REPORT_PARAMETERS]: ({commit}) => {
    commit(RESET_REPORT_PARAMETERS);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
