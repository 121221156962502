import {
  GET_BY_ID,
  GET_BY_ID_LOADING,
  GET_BY_ID_ERROR,
  CLEAR_BY_ID,
  SET_INTERACTIONS_SELECTED,
  CLEAR_INTERACTIONS_SELECTED,
  REMOVE_INTERACTIONS_SELECTED
} from '@/shared/store/actionType';

import { httpRequest, updateBreadcrumb } from '@sales-i/utils';

// getters
export const getByIdGetters = {
  selectedItems: (state) => {
    return state.selectedItems;
  },
};

// mutations
export const getByIdMutations = {
  [GET_BY_ID]: (state, data) => {
    state.selected.data = data;
    state.selected.loaded = true;
    state.selected.loading = false;
    state.selected.error = undefined;
    state.selected.updated = false;
  },
  [GET_BY_ID_LOADING]: (state) => {
    state.selected.loaded = false;
    state.selected.loading = true;
  },
  [GET_BY_ID_ERROR]: (state, error) => {
    state.selected.loaded = false;
    state.selected.loading = false;
    state.selected.error = error;
  },
  [CLEAR_BY_ID]: (state) => {
    state.selected.data = {};
    state.selected.loaded = false;
    state.selected.loading = false;
  },
  [SET_INTERACTIONS_SELECTED]: (state, data) => {
    state.selectedItems.push(data);
  },
  [CLEAR_INTERACTIONS_SELECTED]: (state) => {
    state.selectedItems = {};
  },
  [REMOVE_INTERACTIONS_SELECTED]: (state, id) => {
    state.selectedItems.data = {};
    state.selectedItems.splice(state.selectedItems.findIndex(x => x.id === id), 1);
    state.selectedItems.loaded = true;
    state.selectedItems.loading = false;
    state.selectedItems.error = undefined;
  },
};

// actions
export const getByIdActions = {
  [GET_BY_ID]: async ({ commit }, parameters) => {
    //debugger // eslint-disable-line no-debugger

    try {
      const { id, options = {version: '2'} } = parameters;
      commit(CLEAR_BY_ID);
      commit(GET_BY_ID_LOADING);
      let data = await httpRequest('get', `opportunities/${id}`, {}, options);
      commit(GET_BY_ID, data);
      updateBreadcrumb('opportunities', id, `Opportunity with ${data.entity_name}`);
      return data;
    } catch (error) {
      console.error(error);
      commit(GET_BY_ID_ERROR, error);
      return false;
    }
  },
  [CLEAR_BY_ID]: ({ commit, }) => {
    commit(CLEAR_BY_ID);
  },
  
  [SET_INTERACTIONS_SELECTED]: async ({ commit }, payload) => {
    commit(SET_INTERACTIONS_SELECTED, payload);
  },
  [REMOVE_INTERACTIONS_SELECTED]: async ({ commit }, payload) => {
    commit(REMOVE_INTERACTIONS_SELECTED, payload);
  },
  [CLEAR_INTERACTIONS_SELECTED]: ({ commit, }) => {
    commit(CLEAR_BY_ID);
  },
};
