<template>
  <CustomCard
    purpose="reversed"
    footer-color="grey"
    class="interaction-card"
  >
    <header>
      <h2>{{ t('Your next interaction') }}</h2>
      <p>{{ nextInteraction.name }} {{ nextInteractionTime }}</p>
    </header>
    <BufferImage
      v-if="!isChartEnabled"
      class="loading-spinner"
      color="var(--colour-utility-black)"
      float="center"
    />
    <LineBarChart
      v-if="isChartEnabled && !hasCurrentAnalysisDataEmpty"
      :report-id="reportId"
      class="line-bar-chart-container"
    />
    <p 
      v-if="hasCurrentAnalysisDataEmpty && isChartEnabled"
      class="no-data"
    >
      {{ t('No report data available for this customer') }}
    </p>
    <div 
      v-if="isChartEnabled && showSlider"
      :class="{ 'bottom' : hasCurrentAnalysisDataEmpty }"
      class="interactions-slider"
    >
      <IconButton
        icon-name="arrow-left"
        icon-color="white"
        small
        :disabled="!isPrevActive"
        @on-click="handlePrev"
      />
      <IconButton
        icon-name="arrow-right"
        icon-color="white"
        small
        :disabled="!isNextActive"
        @on-click="handleNext"
      />
    </div>
    <template #footer>
      <ButtonGroup>
        <CustomButton
          small
          purpose="action"
          @click="goToSnapshotSummary"
        >
          {{ t('Get full snapshot') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomCard>
</template>

<script>
import { BufferImage, CustomCard, CustomButton, IconButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import LineBarChart from '@/intelligence/components/SnapshotReport/Charts/LineBarChart';
import { dates, t } from '@sales-i/utils';
import { SNAPSHOT_SUMMARY } from '@/intelligence/store/data/reportTypes';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'TodayNextInteraction',
  components: {
    ButtonGroup,
    CustomCard,
    CustomButton,
    LineBarChart,
    BufferImage,
    IconButton,
  },
  props: {
    isChartEnabled: {
      type: Boolean,
      default: false,
    },
    nextInteraction: {
      type: Object,
      default: () => ({}),
    },
    reportId: {
      type: Number,
      default: null,
    },
    currentIndex: {
      type: Number,
      required: true
    },
    totalInteractions: {
      type: Number,
      required: true
    },
    hasCurrentAnalysisDataEmpty: {
      type: Boolean,
      required: true
    },
  },
  emits: ['navigate'],
  computed: {
    nextInteractionTime() {
      if (!this.isChartEnabled) return '';
      const dateTime = new Date(this.nextInteraction.date);
      return `${dates.getStartHours(dateTime)}:${dates.getStartMinutes(dateTime)}`;
    },
    isPrevActive() {
      return this.currentIndex > 0;
    },
    isNextActive() {
      return this.currentIndex < this.totalInteractions - 1;
    },
    showSlider() {
      return this.totalInteractions > 1;
    }
  },
  methods: {
    t,
    goToSnapshotSummary() {
      const route = this.$router.resolve({ name: SNAPSHOT_SUMMARY });
      navigateToUrl(route.href);
    },
    handlePrev() {
      if (this.isPrevActive) {
        this.$emit('navigate', 'prev');
      }
    },
    handleNext() {
      if (this.isNextActive) {
        this.$emit('navigate', 'next');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.line-bar-chart-container {
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.interaction-card {
  position: relative;
  &:deep(.c-card--body) {
    height: auto;
    min-height: 600px;
    overflow-y: auto;
  }
}

.loading-spinner {
  position: absolute;
  top: calc(50% - 50px);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

.interactions-slider {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: var(--spacing-3);
}

.no-data {
  text-align: center;
  padding-top: 50%;
}

.bottom {
  padding-top: 50%;
}
</style>
