import usePermissions from '@/shared/composables/usePermissions';
import {
  intelligence_crm_reports,
  intelligence_insights,
  intelligence_enquiries,
  intelligence_products,
} from '@/shared/store/data/policies';

export default function useReportPolicies() {
  const { hasAccess } = usePermissions();
  const canAccessIntelligenceCrmReports = hasAccess(intelligence_crm_reports, 'read');
  const canAccessIntelligenceInsights = hasAccess(intelligence_insights, 'read');
  const canAccessIntelligenceEnquiries = hasAccess(intelligence_enquiries, 'read');
  const canAccessIntelligenceProducts = hasAccess(intelligence_products, 'read');
  const canAccessCustomersNotBuyingTopProducts = hasAccess(intelligence_insights, 'read') && hasAccess(intelligence_products, 'read');
  
  // hasAccessToSnapshots is a getter that calculates if the user has access to snapshots via the policies defined in the checkPermissions.js
  const canAccessSnapshots = hasAccess(intelligence_enquiries, 'read') && usePermissions().hasAccessToSnapshots.value;
  
  const permissions = [
    { 'snapshot-report': canAccessSnapshots },
    { 'intelligence-insights': canAccessIntelligenceInsights },
    { 'intelligence-enquiries': canAccessIntelligenceEnquiries },
    { 'intelligence-products': canAccessIntelligenceProducts },
    { 'intelligence-crm-reports': canAccessIntelligenceCrmReports },
  ];

  return {
    permissions,
    canAccessCustomersNotBuyingTopProducts,
  };
}