import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
  CLEAR_ENQUIRY_FILTERS,
  FETCH_BUBBLE_DATA,
  FETCH_REPORT_DATA,
  SET_FILTERS,
  SET_CRM_FILTERS,
  SET_SALES_FILTERS,
  APPLY_CRM_FILTERS_TO_QUERY,
  APPLY_SALES_FILTERS_TO_QUERY,
  APPLY_FILTERS_TO_QUERY,
  ADD_SALES_FILTER_TO_QUERY,
  ADD_CUSTOMER_FILTER_TO_QUERY,
  ADD_PRODUCT_TO_QUERY,
  REMOVE_ALL_FILTERS_FROM_QUERY,
  SALES_DATA_CLAUSE,
  CRM_DATA_CLAUSE,
  PRODUCT_DATA_CLAUSE,
  UPDATE_SALES_FILTER_IN_QUERY,
  REMOVE_SALES_FILTER_FROM_QUERY,
  GET_SALES_FILTER_OPTIONS
} from '@/intelligence/store/actionType';
import {
  QUERY_BUILDER
} from '@/intelligence/store/data/reportTypes';
import {
  GET_ALL,
  GET_BY_ID,
} from '@/shared/store/actionType';
import { pluralise, dates } from '@sales-i/utils';
import { alertBuilderScope, alertsScope, baseUrl as intelligenceBaseUrl } from '@/intelligence/router/urlBits';

export const useAlertBuilder = ({ store = useStore(), vroute = useRoute() } = {}) => {
  const iframe = computed(() => store.state.system.iframe);
  const isEditMode = computed(() => !!vroute.params.queryId);

  const alertBuilderBaseUrl = computed(() => iframe.value ? '/iframe/overview' : intelligenceBaseUrl);
  const alertBuilderModalBaseUrl = computed(() => isEditMode.value 
    ? `${alertBuilderBaseUrl.value}/${alertsScope}/${vroute.params.queryId}/${alertBuilderScope}`
    : `${alertBuilderBaseUrl.value}/${alertsScope}/${alertBuilderScope}`
  );
  
  const customerBubble = ref({});
  const abandonedDrills = ref([]);
  const reportEnabled = ref(false);
  const showReport = ref(false);
  const runCount = ref(1);

  const bubbles = computed(() => store.getters['intelligence/queryBuilder/getBubbles']);
  const getDrillsQueryParams = computed(() => store.getters['intelligence/shared/getDrillsQueryParams']);
  const crmFilters = computed(() => store.getters['intelligence/queryBuilder/getCrmFilters']);
  const salesFilters = computed(() => store.getters['intelligence/queryBuilder/getSalesFilters']);
  const allProducts = computed(() => store.getters['intelligence/queryBuilder/getAllProducts']);
 
  // Product data
  const products = computed(() => store.state.intelligence.queryBuilder.products);
  const buyingProduct = computed(() => products.value.filter((product) => product.status === 'buying' && product.level === 'item_level'));
  const notBuyingProduct = computed(() => products.value.filter((product) => product.status === 'not_buying' && product.level === 'item_level'));
  const buyingProductGroup = computed(() => products.value.filter((product) => product.status === 'buying' && product.level === 'product_group_level'));
  const notBuyingProductGroup = computed(() => products.value.filter((product) => product.status === 'not_buying' && product.level === 'product_group_level'));
  
  const buyingProductCount = computed(() => buyingProduct.value.length === 0 ? 0 :
    buyingProduct.value[0].items.length
  );
  const notBuyingProductCount = computed(() => notBuyingProduct.value.length === 0 ? 0 :
    notBuyingProduct.value[0].items.length
  );
  const  buyingProductGroupCount = computed(() => buyingProductGroup.value.length === 0 ? 0 :
    buyingProductGroup.value[0].items.length
  );
  const notBuyingProductGroupCount = computed(() => notBuyingProductGroup.value.length === 0 ? 0 :
    notBuyingProductGroup.value[0].items.length
  );
  
  const productFilterData = computed(() => {

    return products.value.map((product) => {
      return {
        id: product.id,
        level: `${pluralise.number(product.items.length, product.level === 'item_level' ? 'product' : 'product group', { includeNumber: true })}`,
        status: product.status === 'buying' ? 'buying' : 'not buying',
        items: product.items,
        operator: product.operator.toUpperCase(),
      };
    });
  });

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const clearEnquiryFilters = () => store.dispatch(`intelligence/enquiry/${CLEAR_ENQUIRY_FILTERS}`);
  const fetchBubbleData = () => store.dispatch(`intelligence/enquiry/${FETCH_BUBBLE_DATA}`);
  const setDrillsDataByQuery = (query) => store.dispatch('intelligence/shared/setDrillsDataByQuery', query);
  const applyFilters = () => store.dispatch(`intelligence/enquiry/${APPLY_FILTERS_TO_QUERY}`);
  const applyCrmFilters = () => store.dispatch(`intelligence/enquiry/${APPLY_CRM_FILTERS_TO_QUERY}`);
  const applySalesFilters = () => store.dispatch(`intelligence/enquiry/${APPLY_SALES_FILTERS_TO_QUERY}`);
  const setCrmFilter = (filters) => store.dispatch(`intelligence/enquiry/${SET_CRM_FILTERS}`, filters);
  const setSalesFilter = (filters) => store.dispatch(`intelligence/enquiry/${SET_SALES_FILTERS}`, filters);
  const fetchEnquiryData = type => store.dispatch(`intelligence/shared/${FETCH_REPORT_DATA}`, type);
  const setFilters = (filter) => store.dispatch(`intelligence/enquiry/${SET_FILTERS}`, filter);
  const getSavedAlertById = (id) => store.dispatch(`intelligence/savedQuery/${GET_BY_ID}`, id);
  const getAllSavedAlerts = params => store.dispatch(`intelligence/savedQuery/${GET_ALL}`, params);
  const setLargeLimit = () => store.dispatch('intelligence/queryBuilder/SET_LARGE_LIMIT');

  // Filters
  const addSalesFilterToQuery = (params) => store.dispatch(`intelligence/queryBuilder/${ADD_SALES_FILTER_TO_QUERY}`, params);
  const addCustomerFilterToQuery = (params) => store.dispatch(`intelligence/queryBuilder/${ADD_CUSTOMER_FILTER_TO_QUERY}`, params);
  const addProductToQuery = (params) => store.dispatch(`intelligence/queryBuilder/${ADD_PRODUCT_TO_QUERY}`, params);
  const clearAllFilters = () => store.dispatch(`intelligence/queryBuilder/${REMOVE_ALL_FILTERS_FROM_QUERY}`);

  // Data clauses
  const salesDataClause = (params) => store.dispatch(`intelligence/enquiry/${SALES_DATA_CLAUSE}`, params);
  const crmDataClause = (params) => store.dispatch(`intelligence/enquiry/${CRM_DATA_CLAUSE}`, params);
  const productDataClause = (params) => store.dispatch(`intelligence/enquiry/${PRODUCT_DATA_CLAUSE}`, params);

  // Update/remove the filters
  const updateSalesFilterInQuery = (params) => store.dispatch(`intelligence/queryBuilder/${UPDATE_SALES_FILTER_IN_QUERY}`, params);
  const removeSalesFilterFromQuery = (params) => store.dispatch(`intelligence/queryBuilder/${REMOVE_SALES_FILTER_FROM_QUERY}`, params);
  
  const salesFiltersOptions = () => store.dispatch(`intelligence/queryBuilder/${GET_SALES_FILTER_OPTIONS}`);

  const drillParams = computed(() => {
    const params = [];
    Object.keys(getDrillsQueryParams.value).forEach(key => {
      const drillId = getDrillsQueryParams.value[key];
      if (!key.includes('values') && !key.includes('1-dim')) {
        const paramIndex = Number(key.charAt(0));
        params[paramIndex] ? params[paramIndex].push(drillId) : (params[paramIndex] = [drillId]);
      }
    });
  
    return params.filter(el => el);
  });
  
  const query = computed(() => {
    const drillsDataQuery = {
      '1-dim1': customerBubble.value.id,
    };
    let index = 2;
  
    // Take all the items from the query string and append them
    for (let n in vroute.query) {
      if (n.indexOf('values') !== -1) {
        drillsDataQuery[n] = vroute.query[n];
      }
    }
  
    if (bubbles.value.length > 0) {
      let n = 0;
      for (n; n < bubbles.value.length; n++) {
        if (bubbles.value[n].id !== customerBubble.value.id) {
          drillsDataQuery[`1-dim${index}`] = bubbles.value[n].id;
        }
        index++;
      }
    }
  
    let x = 0;
    index = 1;
    let len = drillParams.value.flat();
    for (x; x < len.length; x++) {
      drillsDataQuery[`2-dim${index}`] = drillParams.value.flat()[x];
      index++;
    }
  
    len = abandonedDrills.value.flat();
    for (x; x < len.length; x++) {
      drillsDataQuery[`2-dim${index}`] = abandonedDrills.value.flat()[x];
      index++;
    }
  
    return drillsDataQuery;
  });

  const getProdGroupLevelIds = (item) => {
    return [
      item.prod_group_level_1 ? item.prod_group_level_1 : '',
      item.prod_group_level_2 ? `-${item.prod_group_level_2}` : '',
      item.prod_group_level_3 ? `-${item.prod_group_level_3}` : '',
      item.prod_group_level_4 ? `-${item.prod_group_level_4}` : '',
      item.prod_group_level_5 ? `-${item.prod_group_level_5}` : '',
    ].join('');
  };
  
  function addItemsToFilter(allProducts) {
    let filterCount = 1;
    let x = 0;
    
    for (x; x < allProducts.value.length; x++) {
      const _ref = allProducts.value[x];
      const productCodes = _ref.items.map(o => {
        if (_ref.level === 'item_level') {
          return {
            id: o.product_code,
          };
        } else {
          return {
            id: getProdGroupLevelIds(o),
          };
        }
      });
  
      const filter = {
        id: filterCount,
        type: _ref.level === 'item_level' ? 'product' : 'product_group',
        filter_type: _ref.status === 'buying' ? 'INCLUDE' : 'EXCLUDE',
        clause: _ref.operator.toUpperCase(),
        selected_values: productCodes,
      };
  
      // Set the filter
      setFilters([filter, filterCount]);
        
      // Increment the filter count for the next filter
      filterCount++;
    }
  }

  const goToReport = async (options = {}) => {
    if (Object.keys(customerBubble.value).length === 0) {
      return;
    }
  
    clearEnquiryFilters();

    setLargeLimit();
  
    reportEnabled.value = false;
  
    // If there are any product items, run the addProductItemsToFilterInclude function
    if (allProducts.value.length > 0) {
      addItemsToFilter(allProducts);
    }
  
    if (Object.keys(crmFilters.value).length > 0) {
      setCrmFilter(crmFilters.value);
    }
  
    applyCrmFilters();
  
    if (Object.keys(salesFilters.value).length > 0) {
      setSalesFilter(salesFilters.value);
    }
    // Only apply the sales filter if all of the values are set
    if (salesFilters.value.every(filter => filter.field && filter.operator && filter.value)) {
      applySalesFilters();
    }
  
    // Check the bubble query and apply all
    const drillsDataQuery = query.value;
  
    setDrillsDataByQuery(drillsDataQuery);
    applyFilters();
  
    showReport.value = true;
  
    // Refresh the data
    fetchBubbleData();
    await fetchEnquiryData({
      reportType: QUERY_BUILDER,
    });

    if ('incrementRunCount' in options && options?.incrementRunCount !== false) {
      runCount.value = runCount.value + 1;
    }
  };

  function getNextId(section) {
    const existingIds = section.map(filter => filter.id);
    if (existingIds.length === 0) {
      return 1;
    }
    const maxId = Math.max(...existingIds);
    return maxId + 1;
  }

  function getQueryArgFilters() {
    // Get the query_arguments and split the string at the |||| and ~~~~ delimiters to get the raw query as well as the filters and dataClauses
    if (store.state.intelligence.savedQuery.data.results && store.state.intelligence.savedQuery.data.results.length > 0) {
      // Get the first result from the savedQuery data (there should only be one result in the array)
      const dataToParse = store.state.intelligence.savedQuery.data.results[0];
  
      // Split the query_arguments string at the |||| delimiter
      const parts = dataToParse.query_arguments.split('||||');
  
      // The second part contains filters and data clauses
      const filtersAndData = parts[1].split('~~~~');
      
      // The first object is the filters
      const filters = filtersAndData[0];
  
      // The second object is the data clauses
      const dataClauses = filtersAndData[1];
  
      // Return the filters and dataClauses as an object
      return {
        filters,
        dataClauses,
      };
    } else {
      // Handle the case where results is undefined or empty
      return {
        filters: null,
        dataClauses: null,
      };
    }
  }
  
  function convertFiltersToJSON() {
    // Get the first object from getQueryArgFilters and parse it to JSON
    const feFilters = JSON.parse(getQueryArgFilters().filters);
  
    // Check if feFilters is null or not
    if (feFilters !== null) {
      // Loop through the feFilters object and push the 'salesFilters' to a salesFilters array
      const salesFilters = feFilters.salesFilters ? feFilters.salesFilters.map((filter) => filter) : [];
      const filter_clauses = feFilters.crmFilters ? feFilters.crmFilters.map((filter) => filter) : [];
      const products = feFilters.productFilters ? feFilters.productFilters.map((filter) => filter) : [];
  
      return {
        salesFilters,
        filter_clauses,
        products,
      };
    } else {
      // Return an empty object if feFilters is null
      return {
        salesFilters: [],
        filter_clauses: [],
        products: [],
      };
    }
  }
  
  function convertDataClausesToJSON() {
    // Get the second object from getQueryArgFilters and parse it to JSON
    const dataClauses = JSON.parse(getQueryArgFilters().dataClauses);
    
    // Check if dataClauses is null or not
    if (dataClauses !== null) {
      return dataClauses;
    } else {
      // Return an empty object if dataClauses is null
      return {
        salesDataClause: null,
        crmDataClause: null,
        productDataClause: null,
      };
    }
  }
  
  function addQueryArgsToStore() {
    // Get each filter array into one object. Then, push these arrays to the store
    const queryArgs = convertFiltersToJSON();
    const dataClauses = convertDataClausesToJSON();
    
    // Add the filters to the store
    queryArgs.salesFilters.forEach(filter => addSalesFilterToQuery(filter));
    queryArgs.filter_clauses.forEach(filter => addCustomerFilterToQuery(filter));
    queryArgs.products.forEach(filter => addProductToQuery(filter));
  
    // Add the data clauses to the store even though they're not objects
    salesDataClause(dataClauses.salesDataClause);
    crmDataClause(dataClauses.crmDataClause);
    productDataClause(dataClauses.productDataClause);
  
    return queryArgs;
  }

  const formatDateForAlertBuilder = (date) => {
    return date ? dates.getDateTime(date)?.toISODate()+'T00:00:00.000+0000' : '';
  };
  
  return {
    alertBuilderBaseUrl,
    alertBuilderModalBaseUrl,
    customerBubble,
    abandonedDrills,
    reportEnabled,
    showReport,
    bubbles,
    getDrillsQueryParams,
    crmFilters,
    salesFilters,
    allProducts,
    isEditMode,
    clearEnquiryFilters,
    fetchBubbleData,
    setDrillsDataByQuery,
    applyFilters,
    applyCrmFilters,
    applySalesFilters,
    setCrmFilter,
    setSalesFilter,
    clearAllFilters,
    setFilters,
    getSavedAlertById,
    getAllSavedAlerts,
    drillParams,
    goToReport,
    getNextId,
    getQueryArgFilters,
    convertFiltersToJSON,
    convertDataClausesToJSON,
    addQueryArgsToStore,
    runCount,
    buyingProductCount,
    notBuyingProductCount,
    buyingProductGroupCount,
    notBuyingProductGroupCount,
    productFilterData,
    capitalizeFirstLetter,
    updateSalesFilterInQuery,
    removeSalesFilterFromQuery,
    addSalesFilterToQuery,
    addCustomerFilterToQuery,
    addProductToQuery,
    salesFiltersOptions,
    formatDateForAlertBuilder
  };
};
