<template>
  <div
    v-if="total > pageSize"
    class="numbered-pagination"
  >
    <CustomButton
      v-for="page in pages"
      :key="page.number"
      purpose="text"
      :class="page.isActive ? 'active' : ''"
      @on-click="() => changePage(page.number)"
    >
      {{ page.label }}
    </CustomButton>
  </div>
</template>

<script setup>
import { computed, ref, } from 'vue';
import { CustomButton, } from '@sales-i/dsv3';

const emit = defineEmits(['paging']);

const props = defineProps({
  total: {
    type: Number,
    default: 0,
  },
  pageSize: {
    type: Number,
    default: 20,
  }
});

const currentPage = ref(1);
const totalPages = computed(() => Math.ceil(props.total / props.pageSize));

const pages = computed(() => {
  const total = totalPages.value;
  let arr = [];
  let atStart = currentPage.value <= 3;
  let atEnd = currentPage.value >= total - 2 || total <= 5;

  for (let i = 0; i < total; i++) {
    if (i === 0) {
      arr.push({
        label: atStart ? 1 : '1...',
        number: i + 1,
        isActive: currentPage.value === i + 1,
      });
    } else if (i === (total-1)) {
      arr.push({
        label: atEnd ? i + 1 : `...${i + 1}`,
        number: i + 1,
        isActive: currentPage.value === i + 1,
      });
    } else if (isPageAdjacent(i + 1, atStart, atEnd)) {
      arr.push({
        label: i + 1,
        number: i + 1,
        isActive: currentPage.value === i + 1,
      });
    }
  }
  return arr;
});
// 1,2,3,4,...7
// 1...,4,5,6,7
// 1...,3,4,5,...7

const isPageAdjacent = (pageNumber, atStart, atEnd) => {
  if (atStart) {
    return pageNumber <= 4;
  }
  if (atEnd) {
    return pageNumber >= totalPages.value - 3;
  }
  return Math.abs(pageNumber - currentPage.value) <= 1;
};

const changePage = (num) => {
  currentPage.value = num;
  const offset = num === 1 ? 0 : (num-1) * props.pageSize;
  emit('paging', {
    offset,
    limit: props.pageSize,
  });
};
</script>

<style lang="scss" scoped>
.numbered-pagination {
  display: flex;
  gap: var(--spacing-1);
  .active {
    background-color: black;
    border-radius: 50%;
    width: 20px;
    color: white;
  }
}
</style>
