<template>
  <div class="enquiry-breadcrumbs">
    <CustomButton
      v-show="isResettable"
      class="reset-button"
      purpose="action"
      icon-name="close-line"
      icon-color="var(--colour-utility-white)"
      small
      round
      :icon-width="18"
      :icon-height="18"
      @click="resetReport"
    />
    <div
      v-for="(param, index, i) in breadcrumbs"
      :key="index"
    >
      <Breadcrumb
        :dim="param.id"
        :drill-values="param.drilledBy !== null ? breadcrumbs[param.drilledBy].values : null"
        :is-first-child="i === 0"
      >
        <CustomButton
          small
          purpose="text"
          :class="{
            text: true,
            'abandoned-drill': isDrillAbandoned(param.key),
          }"
          @click="goToSelectedDrill(param.key)"
        >
          {{ param.label }}
        </CustomButton>
        <span
          v-if="breadcrumbs[param.drilledBy]?.values?.length"
          class="drill-number"
        >
          ({{ breadcrumbs[param.drilledBy]?.values?.length }})
        </span>
      </Breadcrumb>

      <div
        v-if="getNextKeyValue(breadcrumbs, index) !== undefined && getNextKeyValue(breadcrumbs, index).type === 'dimension'"
        style="padding:0 10px;"
      >
        {{ '/' }}
      </div>
      <div
        v-if="getNextKeyValue(breadcrumbs, index) !== undefined && getNextKeyValue(breadcrumbs, index).type === 'drill'"
        style="padding:0 10px;"
      >
        {{ '>' }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { CustomButton } from '@sales-i/dsv3';

import Breadcrumb from '@/intelligence/components/EnquiryReport/EnquiryBreadcrumbs/Breadcrumb.vue';

export default {
  name: 'MultiDimBreadcrumbs',
  components:{
    CustomButton,
    Breadcrumb
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
    abandonedDrillParams: {
      type: Array,
      default: () => [],
    },
    isResettable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['resetReport', 'goToSelectedDrill'],
  computed: {
    ...mapState({
      bubbleData: state => state.intelligence.enquiry.bubbleData,
    }),
    allProps() {
      return this.enquiryParams && this.query && this.drillParams && this.abandonedDrillParams;
    },
    breadcrumbs() {
      let breadcrumbs = {};

      // parse enquiry params to new type
      Object.entries(this.params).forEach(([key, value]) => {
        const drillValuesKey = `${key}values`;

        switch (true) {
        case key === 'dim1' || key === 'dim2': {
          const dimensionNumber = key.replace('dim', '');
          breadcrumbs = {...breadcrumbs, ...this.parseData({id: value, dim: Number(dimensionNumber), type: 'dimension', key})};
          break;
        }
        case (key.includes('-dim') && !key.includes('values')): {
          // This gets any drill values and maps them to the correct dimension

          const dimNumber = parseInt(key.split('dim')[1], 10);
          const values = this.parseDrillValues(this.params[drillValuesKey]);

          // this will set the drilledBy param of the last dimension matching the drills dimension to the current drill
          if (values?.length > 0) {
            let setLast = false;
            Object.entries(breadcrumbs).forEach(([bcKey, bcVal]) => {
              if (setLast) return;

              // if it already has a drilledBy, skip it
              if (bcVal.dim === dimNumber && !bcVal.drilledBy) {
                breadcrumbs[bcKey] = {
                  ...bcVal,
                  drilledBy: this.getBubbleNameAsKey(value),
                };
                setLast = true;
              }
            });
          }
          breadcrumbs = {...breadcrumbs, ...this.parseData({id: value, dim: dimNumber, type: 'drill', values, key})};
          break;
        }
        default:
          break;
        }
      });

      return breadcrumbs;
    },
  },
  methods: {
    getDimLevel(dim) {
      return dim?.split('-dim')[0];
    },
    getNextKeyValue(obj, currentKey) {
      const keys = Object.keys(obj);
      const currentIndex = keys.indexOf(currentKey);
      const nextIndex = currentIndex + 1;
      return obj[keys[nextIndex]];
    },
    parseData({id, dim, type, values = [], key}) {
      const bubbleName = this.getBubbleName(id);
      if (!bubbleName) return;

      return {[this.getBubbleNameAsKey(id)]: {
        abandoned: false,
        id: id,
        dim: dim,
        label: bubbleName,
        type: type,
        values: values,
        drilledBy: null,
        key
      }};
    },
    parseDrillValues(values) {
      if (!values) return;

      if (typeof values === 'string') {
        return [values.split(':')[0]];
      }

      return values.map(value => {
        return value.split(':')[0];
      });
    },
    getBubbleName(bubbleId) {
      if (!this.bubbleData) return;
      let titleObj = this.bubbleData.find(bubble => bubble.id === bubbleId);
      return titleObj ? titleObj.title : '';
    },
    getBubbleNameAsKey(bubbleId) {
      const bubble = this.getBubbleName(bubbleId);
      if (!bubble) return '';
      return `${bubble}_${bubbleId}`.replace(/ /g, '_').toLowerCase();
    },
    resetReport() {
      this.$emit('resetReport');
    },
    goToSelectedDrill(key) {
      this.$emit('goToSelectedDrill', key);
    },
    isDrillAbandoned(drillLevel) {
      // get current drill, if NaN then set to 0
      const currentDrill = Number(this.getDimLevel(this.params?.activeDim)) || 0;
      return currentDrill < Number(this.getDimLevel(drillLevel));
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/shared/assets/scss/_variables';

.enquiry-breadcrumbs {
  display: flex;
  align-items: center;
  padding: var(--spacing-4);
  background: var(--colour-panel-alert-base);
  flex-wrap: nowrap;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: var(--spacing-2);
    overflow-x: auto;
  }

  .reset-button {
    margin-right: var(--spacing-2);
    height: 24px;
    width: 24px;

    :deep(.svg-container) {
      margin: 0 !important;
    }
  }

  div {
    white-space: nowrap;
    display: inline-block;
  }

  .drillcrumbs {
    display: flex;
    align-items: center;
    flex-basis: 100%;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      flex-basis: auto;
    }
  }

  .abandoned-drill {
      opacity: 48%;
    }

  span {
    font-size: var(--font-size-small);
  }

  .text {
    display: inline-block;
    color: var(--colour-utility-action);
    text-decoration: underline;
    font-weight: var(--font-weight-semibold);
    cursor: pointer;
  }

  .divider {
    margin: 0 var(--spacing-1);
    display: inlnie-block;
  }

  .label {
    margin: 2px var(--spacing-1) 0;
  }

  .drill-number {
    margin-left: var(--spacing-half);
    color: var(--colour-utility-base);
  }
}
</style>