<template>
  <div>
    <div
      v-if="drill"
      class="description"
    >
      <CustomButton
        class="close-button"
        purpose="neutral"
        icon-name="close-line"
        icon-color="var(--colour-utility-action)"
        round
        :icon-width="32"
        :icon-height="32"
        @click="closeAndReset(true)"
      />
    </div>
    <div class="bubbles-container">
      <BubbleSearch
        class="search"
        :bubble-areas="bubbleAreas"
        @search-bubbles="updateSearchBubbleQuery"
        @area-selected="selectedArea = $event"
      />
      <div class="bubbles">
        <BufferImage
          v-if="dataLoading && !showBubbles"
          color="black"
          float="center"
          class="loading-spinner"
        />
        <div
          v-for="(area, index) in bubbleAreas"
          v-show="filterBubbles(area).length > 0"
          :key="index"
          class="section"
        >
          <div
            class="bubble-title"
          >
            <div
              class="icon"
              :style="{ background: getIconBackground(area) }"
            >
              <IconBase
                :width="40"
                :height="40"
                :icon-name="getIcon(area)"
                icon-color="var(--colour-utility-white)"
              />
            </div>
            <h3 :class="{ 'uppercase': area === 'Crm' }">
              {{ area }}
            </h3>
          </div>
          <div class="list">
            <div
              v-for="bubble in getFilteredBubbles(area)"
              v-show="showBubble(bubble)"
              :key="bubble.id"
              class="option"
              :class="{ active: isBubbleActive(bubble.id) }"
            >
              <Bubble
                :key="bubbleRefresh"
                :bubble-data="getBubbleData(bubble)"
                @bubble-click="setBubbles"
                @close-button-click="setBubbles"
              />
            </div>
            <p
              v-if="!getFilteredBubbles(area).length > 0"
              class="no-dimensions"
            >
              {{ t('No more dimensions left in this category') }}
            </p>
          </div>
          <CustomButton
            v-if="!isMobile && isExpandable(area)"
            :label="showAllBubbles ? t('View Less') : t('View More')"
            class="view-more"
            purpose="text"
            @click="showAllBubbles = !showAllBubbles"
          />
        </div>
        <div
          v-if="showSubmit"
          class="submit"
        >
          <CustomButton
            action="submit"
            :label="buttonLabel"
            @click="goToReport"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { FETCH_BUBBLE_DATA, TOGGLE_DRILL, DISABLE_SELECT_BUBBLES_POPUP } from '@/intelligence/store/actionType';
import { BufferImage, CustomButton, IconBase } from '@sales-i/dsv3';
import Bubble from '@/intelligence/components/Bubble/Bubble';
import { alertsScope, baseUrl, enquiryScope, reportScope } from '@/intelligence/router/urlBits';
import { MATRIX, SALES_VS_GAPS, PRODUCT_COMMUNITY_INSIGHTS, FULL_PICTURE, VARIANCE } from '@/intelligence/store/data/reportTypes';
import BubbleSearch from '@/intelligence/components/Bubble/BubbleSearch';
import breakpoints from '@/shared/utils/breakpoints';
import { ENQUIRY_DIMENSION_1, ENQUIRY_DIMENSION_2 } from '@/intelligence/store/data/kpis';
import { t } from '@sales-i/utils';
import { BUBBLE_AREA_CUSTOMER, BUBBLE_AREA_PRODUCT, BUBBLE_AREA_SALES, BUBBLE_AREA_CALENDAR } from '@/intelligence/store/data/bubbles';
import { navigateToUrl } from 'single-spa';

export default {
  components: {
    Bubble,
    CustomButton,
    BufferImage,
    BubbleSearch,
    IconBase,
  },
  inject: ['mq'],
  props: {
    drill: {
      type: Boolean,
      default: false,
    },
    hiddenBubbles: {
      type: Array,
      default: () => [],
    },
    hiddenBubbleAreas: {
      type: Array,
      default: () => [],
    },
    dateFilter: {
      type: Object,
      default: () => {},
    },
    buttonLabels: {
      type: Array,
      default: () => [],
    },
    reportType: {
      type: String,
      default: '',
    },
    initialActiveBubbles: {
      type: Array,
      default: () => []
    },
    disableSubmit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['drillSelected', 'changeStep', 'goToReport', 'bubbleClick'],
  data() {
    return {
      activeBubbles: [],
      activeAreas: [],
      bubbleRefresh: 0,
      searchQuery: '',
      bubbleRefreshKey: 0,
      selectedArea: 'All',
      showAllBubbles: false,
    };
  },
  computed: {
    ...mapState({
      bubbleData: state => state.intelligence.enquiry.bubbleData,
      bubbleAreas: state => state.intelligence.enquiry.bubbleAreas,
      dataLoading: state => state.intelligence.enquiry.bubblesLoading,
      dimensionsNumber: state => state.intelligence.enquiry.dimensionsNumber,
      measure: state => state.intelligence.shared.requestParameters.measure,
      iframe: state => state.system.iframe,
    }),
    ...mapGetters({
      getInputData: 'intelligence/shared/getInputData',
      periodFilter: 'intelligence/enquiry/getPeriod',
    }),
    inputData() {
      return this.getInputData(this.reportType);
    },
    isProductSearch() {
      return 'communityCode' in this.$route.params && this.$route.params.communityCode === 'product-search';
    },
    isSalesVsGaps() {
      return this.reportType === SALES_VS_GAPS;
    },
    isMatrix() {
      return this.reportType === MATRIX;
    },
    isFullPicture() {
      return this.reportType === FULL_PICTURE;
    },
    isVariance() {
      return this.reportType === VARIANCE;
    },
    enquiryParams() {
      let urlParams = { ...this.dateFilter };

      const prefix = this.reportType !== MATRIX && !this.isSalesVsGaps ? '1-' : '';
      this.activeBubbles.forEach(activeBubble => {
        urlParams[`${prefix}${Object.keys(activeBubble)[0]}`] = Object.values(activeBubble)[0];
      });

      if (this.isSalesVsGaps) {
        urlParams['xaxis'] = this.periodFilter;
      }

      if (this.isSalesVsGaps || this.isMatrix) urlParams['measure'] = this.measure;

      if (this.isProductSearch) {
        urlParams = {
          ...urlParams,
          ...this.$route.query,
        };
      }

      if (this.isSalesVsGaps) {
        urlParams['activeDim'] = 'dim1';
      }

      return urlParams;
    },
    bubbleAxis() {
      // if there's not an active bubble already, assign an axis from url query or default Axis
      if (!this.activeBubbles.length) return this.dimension1.value;

      // check which axis is already used in active bubble and assign the other
      return Object.keys(this.activeBubbles[0]).includes(this.dimension1.value)
        ? this.dimension2.value
        : this.dimension1.value;
    },
    getAxisParams() {
      if (!Object.keys(this.query).length || this.iframe) return false;

      const dimension = this.dimension1 || this.dimension2;
      return this.query[dimension.value];
    },
    showBubbles() {
      return !!this.bubbleData;
    },
    query() {
      return this.$route.query;
    },
    showSubmit() {
      return (
        !this.disableSubmit 
        && (
          this.isSalesVsGaps
          && this.activeBubbles.length >= 1 
          || (this.activeBubbles.length >= this.dimensionsNumber) 
          && this.activeBubbles.some((e) => !!e[this.dimension1.value])
        ) // not sure what this is for, but it messes up SvG, so its split out now
      );
    },
    dimension1() {
      return this.inputData
        ? this.inputData.bubbleCaptions[0]
        : ENQUIRY_DIMENSION_1;
    },
    dimension2() {
      return this.inputData
        ? this.inputData.bubbleCaptions[1]
        : ENQUIRY_DIMENSION_2;
    },
    buttonLabel() {
      const label1 = this.buttonLabels[0] || t('Run Enquiry with 1 Dimension');
      const label2 = this.buttonLabels[1] || t('Run Enquiry with 2 Dimensions');
      return this.activeBubbles.length > 1 ? label2 : label1;
    },
    isMobile() {
      return breakpoints.smAndDown.includes(this.mq.current);
    },
    icon() {
      switch (this.bubbleData.area) {
      case BUBBLE_AREA_CUSTOMER:
        return 'user-circle';
      case BUBBLE_AREA_PRODUCT:
        return 'product';
      case BUBBLE_AREA_SALES:
        return 'opps';
      default:
        return 'user-circle';
      }
    },
    iconBackground() {
      switch (this.bubbleData.area) {
      case BUBBLE_AREA_CUSTOMER:
        return 'var(--colour-data-turmeric-dark)';
      case BUBBLE_AREA_PRODUCT:
        return 'var(--colour-data-viking-dark)';
      case BUBBLE_AREA_SALES:
        return 'var(--colour-data-barberry-dark)';
      default:
        return 'var(--colour-utility-error)';
      }
    },
  },
  async mounted() {
    if (!this.bubbleData) await this.fetchBubbleData();

    if (this.initialActiveBubbles.length) {
      this.initialActiveBubbles.forEach(bubble => {
        this.activeBubbles.push({ [this.bubbleAxis]: bubble.id.toString() });
      });
    }
    if (this.getAxisParams && !this.drill) {
      this.setBubbles({
        checked: true,
        id: Object.values(this.query)[0],
        area: this.bubbleData.filter(bubble => bubble.id === Object.values(this.query)[0])[0].area,
      });

      this.bubbleRefresh++;
    }
  },
  methods: {
    t,
    ...mapActions({
      fetchBubbleData: `intelligence/enquiry/${FETCH_BUBBLE_DATA}`,
      setDrillActiveRows: 'intelligence/shared/setDrillActiveRows',
      toggleDrillOption: `intelligence/shared/${TOGGLE_DRILL}`,
      disableSelectDimensionsPopup: `intelligence/shared/${DISABLE_SELECT_BUBBLES_POPUP}`,
    }),
    filterBubbleAreas(area) {
      if (this.hiddenBubbleAreas.length === 0) {
        return true;
      }

      return this.hiddenBubbleAreas.indexOf(area) === -1;
    },
    filterBubbles(area) {
      const filtered = this.selectedArea === 'All' || area === this.selectedArea
        ? this.bubbleData.filter(bubble => bubble.area === area)
        : [];
      if (this.searchQuery) {
        return filtered.filter(bubble => bubble.title.toLowerCase().includes(this.searchQuery.toLowerCase()));
      }
      return filtered;
    },
    isBubbleActive(bubbleId) {
      let isActive = false;
      if (this.drill && (this.isMatrix || this.isSalesVsGaps)) return isActive;

      this.activeBubbles.forEach(activeBubble => {
        if (Object.values(activeBubble).includes(bubbleId)) isActive = true;
      });

      return isActive;
    },
    setBubbles(bubble) {
      if (!this.isFullPicture && !this.isVariance) {
        this.closeAndReset(false);
      }
      if (this.drill && (this.isMatrix || this.isSalesVsGaps)) {
        this.$emit('drillSelected', { ...bubble });
        return;
      }

      // check or uncheck bubble and block or unblock bubble area
      if (bubble.checked && !this.isBubbleActive(bubble.id)) {
        this.activeBubbles.push({
          [this.bubbleAxis]: bubble.id,
        });

        this.$emit(
          'changeStep',
          this.isSalesVsGaps ||
            (this.showSubmit &&
              !this.isSalesVsGaps &&
              this.activeBubbles.length > 1)
            ? 'end'
            : 'middle'
        );

        this.activeAreas.push(bubble.area);

        this.$emit('bubbleClick', [bubble, 'active']);
      } else {
        const areaIndex = this.activeAreas.indexOf(bubble.area);

        if (areaIndex > -1) {
          this.activeAreas.splice(areaIndex, 1);
        }

        this.activeBubbles = this.activeBubbles.filter(
          (activeBubble) => !Object.values(activeBubble).includes(bubble.id)
        );

        if (this.getAxisParams && bubble.id === Object.values(this.query)[0]) {
          const route = this.$router.resolve({ name: this.reportType });
          navigateToUrl(route.href);
        }

        this.$emit(
          'changeStep',
          this.activeBubbles.length ? 'middle' : 'start'
        );

        this.$emit('bubbleClick', [bubble, 'inactive']);
      }
      this.bubbleRefreshKey++;
    },
    getBubbleData(bubble) {
      const { id, title, area } = bubble;

      return {
        id,
        title,
        area,
        disabled: this.getDisabledConditional(area),
        clickable: true,
        description: false,
        xButtonEvent: false,
        checked: this.isBubbleActive(id),
      };
    },
    getDisabledConditional(area) {
      const maximumBubbles =
        this.reportType === PRODUCT_COMMUNITY_INSIGHTS ? 9999 : 2;

      if (this.isSalesVsGaps || this.isMatrix) {
        return this.activeBubbles.length === maximumBubbles;
      }

      const cond1 = this.activeBubbles.length === maximumBubbles,
        cond2 = this.dimensionsNumber === 2 && this.activeAreas.includes(area);

      return cond1 || cond2;
    },
    getChip(bubbleId) {
      let chip = '';

      if (!this.isBubbleActive(bubbleId)) return;

      this.activeBubbles.forEach((activeBubble, index) => {
        if (Object.values(activeBubble).includes(bubbleId)) {
          chip = t(`Dimension ${index + 1}`, 'dimension_number', {
            interpolations: {
              number: index + 1,
            },
          });
        }
      });
      return chip;
    },
    goToReport() {
      if (this.iframe || this.drill) {
        this.$emit('goToReport', this.enquiryParams);
        this.$emit('drillSelected', this.activeBubbles);
        return;
      } else if (this.drill || !this.reportType) {
        this.$emit('drillSelected', this.activeBubbles);
        return;
      }
      window.scrollTo(0, 0);
      let path = `${baseUrl}/${enquiryScope}/${this.reportType}/${reportScope}`;
      // if the report is an alert search we need to replace enquiryScope insightsScope
      if (this.reportType === PRODUCT_COMMUNITY_INSIGHTS) {
        path = path = `${baseUrl}/${alertsScope}/${reportScope}`;
      }
      
      // Merge existing query parameters with enquiryParams 
      const queryParams = new URLSearchParams({
        ...this.$route.query,
        ...this.enquiryParams
      });
      navigateToUrl(`${path}?${queryParams.toString()}`);
    },
    showBubble(bubble) {
      if (this.hiddenBubbles.includes(bubble.id)) return false;

      if (this.searchQuery.length < 2) return true;

      return this.checkTitleInSearch(bubble.title);
    },
    checkTitleInSearch(title) {
      return title.toLowerCase().includes(this.searchQuery.toLowerCase());
    },
    updateSearchBubbleQuery(query) {
      this.searchQuery = query;
      this.showAllBubbles = true;
    },
    closeAndReset(shouldResetDrillRows) {
      this.disableSelectDimensionsPopup();
      if (this.isMobile) return;
      this.toggleDrillOption(true);
      if (shouldResetDrillRows) {
        this.setDrillActiveRows([]);
      }
    },
    getIcon(area) {
      switch (area) {
      case BUBBLE_AREA_PRODUCT:
        return 'product';
      case BUBBLE_AREA_SALES:
        return 'opps';
      case BUBBLE_AREA_CALENDAR:
        return 'calendar';
      default:
        return 'user-circle';
      }
    },
    getIconBackground(area) {
      switch (area) {
      case BUBBLE_AREA_CUSTOMER:
        return 'var(--colour-data-turmeric-dark)';
      case BUBBLE_AREA_PRODUCT:
        return 'var(--colour-data-viking-dark)';
      case BUBBLE_AREA_SALES:
        return 'var(--colour-data-barberry-dark)';
      case BUBBLE_AREA_CALENDAR:
        return 'var( --colour-data-puerto-rico-dark)';
      default:
        return 'var(--colour-utility-error)';
      }
    },
    getFilteredBubbles(area) {
      let filteredBubbles = this.filterBubbles(area);
      filteredBubbles = filteredBubbles.filter(bubble => !this.hiddenBubbles.includes(bubble.id));
      if (!this.showAllBubbles && !this.isMobile) return filteredBubbles.slice(0, 5);
      return filteredBubbles;
    },
    isExpandable(area) {
      const visibleBubblesInArea = this.bubbleData.filter(bubble =>
        bubble.area === area && !this.hiddenBubbles.includes(bubble.id)
      );
      return visibleBubblesInArea.length > 5;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.bubbles {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: var(--spacing-2);

  &.report-page {
    padding-bottom: var(--spacing-8);
  }

  .section {
    flex-basis: 100%;
    margin-bottom: var(--spacing-2);
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      flex-basis: 50%;
      margin-bottom: var(--spacing-3);
    }

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      flex-basis: 33%;
      margin-bottom: var(--spacing-3);
    }

    h3 {
      font-weight: var(--font-weight-semibold);
      margin-bottom: var(--spacing-1);
      padding-left: var(--spacing-half);
    }
   
    .view-more {
      margin-top: auto;
      justify-content: flex-start;
      padding-top: var(--spacing-2);
    }
  }

  .search {
    width: 100%;
    padding-bottom: var(--spacing-2);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: 100%;
      max-width: 192px;
      left: var(--spacing-2);
      top: 0;
      padding-bottom: 0;
    }
  }

  .list {
    display: flex;
    width: 100%;
    margin-top: 0;
    margin-left: var(--spacing-1);
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 320px;
  }

  .option {
    display: flex;
    flex-basis: 100%;
    position: relative;
    margin-bottom: var(--spacing-1);

    label {
      flex-basis: 100%;
      display: flex;
      column-gap: var(--spacing-1);
      align-items: center;
      margin-bottom: 0;
      cursor: pointer;
    }

    &.active {
      :deep(.icon-circle) {
        box-shadow: 0 0 0 1px var(--colour-data-puerto-rico);
      }
    }
  }
}

.submit {
  background: var(--colour-panel-action);
  text-align: center;
  padding: var(--spacing-3);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 16;

  button {
    margin: 0 auto;
    padding: var(--spacing-1) var(--spacing-2);
  }
}

.no-results {
  padding: 0 var(--spacing-1);
}

.description {
  padding: var(--spacing-5);
  border-radius: var(--border-radius-1) var(--border-radius-1) 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--background-color);

  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    background: var(--background-color);
    bottom: calc(-1 * var(--spacing-half));
    height: var(--spacing-half);
  }

  a {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-semibold);
    color: var(--colour-brand-deluge);
  }

  h3 {
    font-weight: var(--font-weight-semibold);
  }

  .close-button {
    position: absolute;
    top: var(--spacing-2);
    right: 0;
    height: 48px;
    width: 48px;
    box-shadow: none;

    :deep(.svg-container) {
      margin: 0 !important;
    }
  }
}

.bubble-title {
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--spacing-2);
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--spacing-6);
  height: var(--spacing-6);
  border-radius: 50%;
  min-width: var(--spacing-6);
  margin-right: var(--spacing-1);
}

.bubbles-container {
  background: var(--colour-panel-g-0);
  box-shadow: 0 0 var(--spacing-half) 1px var(--colour-panel-g-16);
  display: flex;
  flex-direction: column;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: flex;
    flex-direction: row;
  }
}

.uppercase {
  text-transform: uppercase;
}

.no-dimensions {
  font-size: var(--font-size-small);
}
</style>
