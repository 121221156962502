import { baseUrl, interactionsArea as area } from '@/crm/router/urlBits';

// Routes
import InteractionsDashboard from '@/crm/views/Interactions/InteractionsDashboard.vue';
import InteractionView from '@/crm/views/Interactions/Interaction.vue';
import AddEditInteraction from '@/crm/views/Interactions/AddEditInteraction.vue';
import AddEditPersonal from '@/crm/views/Interactions/AddEditPersonal.vue';
import ViewPersonal from '@/crm/views/Interactions/ViewPersonal.vue';

// Components
import GenerateInteractionTimescale from '@/crm/components/Calendar/GenerateInteraction/GenerateInteractionTimescale.vue';
import GenerateInteractions from '@/crm/views/Interactions/GenerateInteractions.vue';
import GenerateInteractionSettings from '@/crm/components/Calendar/GenerateInteraction/GenerateInteractionSettings.vue';
import GenerateInteractionOpportunities from '@/crm/components/Calendar/GenerateInteraction/GenerateInteractionOpportunities.vue';
import GenerateInteractionProfileCompletion from '@/crm/components/Calendar/GenerateInteraction/GenerateInteractionProfileCompletion.vue';
import GenerateInteractionLocation from '@/crm/components/Calendar/GenerateInteraction/GenerateInteractionLocation.vue';
import GenerateInteractionNote from '@/crm/components/Calendar/GenerateInteraction/GenerateInteractionNote.vue';

import InteractionDetailsRoute from './InteractionDetails';
import InteractionHistoryRoute from './InteractionHistory';
import InteractionPostsRoute from './InteractionPosts';

import InteractionAddEditChildrenRoutes from './InteractionAddEdit';

export default [
  // View interaction
  {
    path: `${baseUrl}/${area}/:id(\\d+)`,
    component: InteractionView,
    children: [
      InteractionDetailsRoute,
      InteractionHistoryRoute,
      InteractionPostsRoute
    ],
  },

  // Generate interactions
  {
    path: `${baseUrl}/${area}/:entityPath`,
    component: GenerateInteractions,
    children: [
      {
        path: '',
        component: GenerateInteractionTimescale,
      },
      {
        path: 'settings',
        component: GenerateInteractionSettings,
      },
      {
        path: 'adding-opportunities',
        component: GenerateInteractionOpportunities,
      },
      {
        path: 'profile-completion-status',
        component: GenerateInteractionProfileCompletion,
      },
      {
        path: 'location-of-:entityType',
        component: GenerateInteractionLocation,
      },
      {
        path: 'interaction-note',
        component: GenerateInteractionNote,
      },
    ]
  },

  // Add/Edit interactions
  {
    path: `${baseUrl}/${area}`,
    component: InteractionsDashboard,
    children: [
      {
        path: `${baseUrl}/${area}/:addedit(add)`,
        component: AddEditInteraction,
        children: InteractionAddEditChildrenRoutes
      },
    ],
  },

  // Edit interactions
  {
    path: `${baseUrl}/${area}/:id(\\d+)/:addedit(edit)`,
    component: AddEditInteraction,
    children: InteractionAddEditChildrenRoutes
  },

  {
    path: `${baseUrl}/${area}`,
    component: InteractionsDashboard,
    children: [
      // Add Personal Interaction
      {
        path: ':id(\\d+)?/:addedit(add-personal|edit-personal)',
        component: AddEditPersonal,
      },
      {
        path: ':id(\\d+)?/personal',
        component: ViewPersonal,
      },
    ],
  }
];
