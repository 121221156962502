<template>
  <div :class="formBody">
    <div class="three-col-equal-height">
      <!-- Form fields -->
      <div
        v-for="(section, sectionIndex) in displayFields"
        :key="sectionIndex"
      >
        <div class="flow">
          <h3 class="fw-sbld">
            {{ t(section.sectionTitle) }}
          </h3>
          <div
            v-for="(field, fieldIndex) in section.fields"
            :key="field.id"
          >
            <Field
              v-if="field.id === 'mins_between_interactions'"
              v-bind="field"
              :ref="field.id"
              v-model="displayFields[sectionIndex].fields[fieldIndex].value"
              :class="field.id"
              @valid="handleFieldValid"
              @keydown="handleKeydown"
              @keypress="handleKeypress"
              @keyup="handleKeyup"
            />
            <Field
              v-else
              v-bind="field"
              :ref="field.id"
              v-model="displayFields[sectionIndex].fields[fieldIndex].value"
              :class="field.id"
              @valid="handleFieldValid"
              @keydown="handleKeydown"
              @keypress="handleKeypress"
              @keyup="handleKeyup"
            />
          </div>
        </div>
      </div>
    </div>
    <div :class="formActions">
      <CustomButton
        v-scroll-to-top
        :label="t('Continue')"
        :class="`action ${valid ? '' : 'disabled'}`"
        :disabled="!valid"
        @on-click="goNextStep"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { baseUrl as crmBaseUrl, interactionsArea } from '@/crm/router/urlBits';
import { t } from '@sales-i/utils';
import { useGenerateInteractionValidation } from './composables/useGenerateInteractionValidation';
import { CustomButton } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field.vue';

const emit = defineEmits(['stageUpdate']);
const store = useStore();
const vroute = useRoute();

const selectedIndex = ref(0);
const nextStep = ref('');

const valid = computed(() => {
  if (!formData.value.length) return false;
  return (
    allocatedTo.value &&
    // Don't validate against the number of interactions if the user is generating them from intelligence. They will automatically be set to 0.
    (entityIDs.value || numberOfInteractions.value > 0) &&
    interactionDuration.value > 0 &&
    spaceBetween.value > 0
  );
});

const {
  entityType,
  flatFields,
  formActions,
  formBody,
  formData,
  handleKeydown,
  handleKeypress,
  handleKeyup,
  handleFieldValid,
  goNextStep,
} = useGenerateInteractionValidation(emit, selectedIndex, nextStep, { valid });

const entityIDs = computed(() => store.state.crm.interactions.currentlyEditing.entityIDs);

const allocatedTo = computed(() => flatFields.value.allocated_to);
const numberOfInteractions = computed(() => flatFields.value.number_of_interactions);
const interactionDuration = computed(() => flatFields.value.duration_of_each_interaction);
const spaceBetween = computed(() => flatFields.value.mins_between_interactions);
const displayFields = computed(() => {
  const data = entityIDs.value !== undefined && entityIDs.value.length ?
    formData.value
      .filter(section => {
        section.fields = section.fields.filter(field => {
          return field.id !== 'number_of_interactions' && field.id !== 'exclude_recently_contacted_days';
        });
        return section;
      })
      .filter(section => section.fields.length !== 0)
    : formData.value.filter(section => section.fields.length !== 0);
  return data;
});

onMounted(() => {
  emit('stageUpdate', 'Settings');
  setNextStep();
});

const setNextStep = () => {
  if (entityType.value === 'customer') {
    nextStep.value =
      entityIDs.value !== undefined && entityIDs.value.length
        ? `${crmBaseUrl}/${interactionsArea}/${vroute.params.entityPath}/interaction-note`
        : `${crmBaseUrl}/${interactionsArea}/${vroute.params.entityPath}/adding-opportunities`;
  } else {
    nextStep.value = `${crmBaseUrl}/${interactionsArea}/${vroute.params.entityPath}/adding-opportunities`;
  }
};
</script>
<style lang="scss" scoped>
.three-col-equal-height {
  --gap-space: var(--spacing-4);
}
</style>
