import {
  APPLY_NEW_SORT,
  APPLY_NEW_FILTER,
  FETCH_BUBBLE_DATA,
  SAVE_BUBBLE_DATA,
  EXPORT_REPORT,
  GENERATE_FILE_ID,
} from '@/intelligence/store/actionType';
import { gql } from 'graphql-request';
import setRequestParam from '@/intelligence/store/utils/setRequestParam';
import { BUBBLE_AREA_CUSTOMER, BUBBLE_AREA_PRODUCT, BUBBLE_AREA_SALES } from '@/intelligence/store/data/bubbles';
import { dates, gqlRequest, httpFileRequest } from '@sales-i/utils';
import { saveAs } from 'file-saver';

// initial state
const state = () => ({
  bubbleData: []
});

// getters
const getters = {
  getCustomerBubbleId: state => state.bubbleData.find(el => el.area === BUBBLE_AREA_CUSTOMER && el.title === 'Name').id,
  getCustomerAccountNoBubbleId: state => state.bubbleData.find(el => el.area === BUBBLE_AREA_CUSTOMER && el.title === 'Account Number').id,
  getProductBubbleId: state => state.bubbleData.find(el => el.area === BUBBLE_AREA_PRODUCT && el.title === 'Product Name').id,
};

// mutations
const mutations = {
  [SAVE_BUBBLE_DATA]: (state, data) => {
    state.bubbleData = data;
  },
};

// actions
const actions = {
  [APPLY_NEW_SORT]: ({ commit }, newSortHeader) => {
    setRequestParam(commit, 'currentSortHeader', newSortHeader);
  },
  [APPLY_NEW_FILTER]: ({ commit }, [key, value]) => {
    setRequestParam(commit, key, value);
  },
  [FETCH_BUBBLE_DATA]: async ({ commit }, params) => {
    let bubbleQueryType = 'query';
    let bubbleQuery, parser = undefined;

    if (params !== undefined) {
      [bubbleQueryType, bubbleQuery, parser] = params;
    }
    try {
      const query = gql`{ bubbles(${bubbleQueryType}: "${bubbleQuery || ''}") {
          id
          area
          title
        }}`,
        response = await gqlRequest(query, {}, { debug: true });
      if (parser !== undefined) {
        parser(response.bubbles);
      }
      commit(SAVE_BUBBLE_DATA, response.bubbles);
    } catch (error) {
      console.error(error);
    }
  },
  [SAVE_BUBBLE_DATA]: ({ state, commit }, type) => {
    const customerBubbleId = state.bubbleData.find(el => el.area === BUBBLE_AREA_CUSTOMER && el.title === 'Name').id;
    const productBubbleId = state.bubbleData.find(el => el.area === BUBBLE_AREA_PRODUCT && el.title === 'Product Name').id;
    const transactionBubbleId = state.bubbleData.find(el => el.area === BUBBLE_AREA_SALES).id;
    // const salesRepBubbleId = state.bubbleData.find(el => el.area === BUBBLE_AREA_CUSTOMER && el.title === 'Sales Rep').id;
    setRequestParam(commit, 'snapshotBubbleId', type === 'performance' ? '(here should go salesRepBubbleId later)' : customerBubbleId);
    setRequestParam(commit, 'productBubbleId', productBubbleId);
    setRequestParam(commit, 'transactionBubbleId', transactionBubbleId);
  },
  // eslint-disable-next-line no-empty-pattern
  [GENERATE_FILE_ID]: async ({}, payload) => {
    const { fileExt, account_number, currency, reporter, address, name, date_from, date_to, date_from2, date_to2 } = payload;
    
    // This function escapes double quotes that may exisit in the customers' address
    let escapeDoubleQuotes = (str) => str.replace(/"/g, '\\"');
    let escapedAddress = escapeDoubleQuotes(address);

    try {
      const 
        query = gql`{ exportsnapshot(
          date_range_1: { 
            from: "${dates.forReportApi(date_from)}",
            to: "${dates.forReportApi(date_to)}",
            complete_periods_only: false
          },
          date_range_2: {
            from: "${dates.forReportApi(date_from2)}",
            to: "${dates.forReportApi(date_to2)}",
            complete_periods_only: false
          },
          account_numbers: ["${account_number}"],
          export_details: {
            file_type: ${fileExt},
            reporter: "${reporter}",
            customer: "${name}",
            customer_address: "${escapedAddress}",
            currency_symbol: "${currency}",
          }
          ) {
            id
          }
      }`;
      const fileId = await gqlRequest(query, {}, { debug: true });
      return {id: fileId, extension: fileExt};
    } catch (error) {
      console.error(error);
    }
  },
  [EXPORT_REPORT]: async ( { dispatch }, payload) => {
    const file = await dispatch(GENERATE_FILE_ID, payload);
    const { id, extension } = file;
    try {
      const fileId = id.exportsnapshot.id;
      const crmEndpoint = `${process.env.VUE_APP_API_V2_ENDPOINT}/exports/v2/download`;
      const response = await httpFileRequest(`${crmEndpoint}/${fileId}`, {});
      const blob = new Blob(
        [response.data],
        {type: 'text/plain;charset=utf-8'}
      );
      saveAs(blob, `${fileId}.${extension}`);
      return response;
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
