<template>
  <Teleport to="#modal-teleport-target">
    <RoleDetailsModal
      v-if="roleId"
      :id="roleId"
      @close-modal="closeModal"
    />
  </Teleport>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute, } from 'vue-router';
import { baseUrl, companyArea, permissionsSection, rolesSection } from '@/admin/router/urlBits';
import RoleDetailsModal from '@/admin/components/Company/Permissions/Roles/RoleDetailsModal.vue';
import { navigateToUrl } from 'single-spa';

const vroute = useRoute();

const roleId = ref(false);

onMounted(() => {
  roleId.value = vroute.params.id;
});

const closeModal = () => {
  navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${rolesSection}`);
};
</script>

<style lang="scss" scoped>
</style>
