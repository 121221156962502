import SetupTabs from '@/admin/views/IntelligenceSetup/Tabs.vue';
import Enquiries from './Enquiries';
import Associations from './Associations';
import Communities from './Communities';

import { baseUrl, intelligenceArea as area } from '@/admin/router/urlBits';

export default [{
  path: `${baseUrl}/${area}`,
  redirect: `${baseUrl}/${area}/enquiries`,
  component: SetupTabs,
  children: [
    Enquiries,
    Associations,
    Communities,
  ],
}];
