/* eslint-disable no-unused-vars */
import {
  GET_ALL,
  GET_ALL_LOADING,
  GET_ALL_ERROR,
  CLEAR_ALL,
  MERGE_INTERACTIONS,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import querystring from 'querystring';
import { DateTime } from 'luxon';

export const getAllGetters = {};

// mutations
export const getAllMutations = {
  [GET_ALL]: (state, data) => {
    state.all.data = data;
    state.all.loaded = true;
    state.all.loading = false;
    state.all.error = undefined;
  },
  [GET_ALL_LOADING]: (state) => {
    state.all.loaded = false;
    state.all.loading = true;
    state.all.error = undefined;
  },
  [GET_ALL_ERROR]: (state, error) => {
    state.all.loaded = false;
    state.all.loading = false;
    state.all.error = error;
  },
  [CLEAR_ALL]: (state) => {
    state.all.data = [];
    state.all.loaded = false;
    state.all.loading = false;
    state.all.error = undefined;
  },
  [MERGE_INTERACTIONS]: (state, newData) => {
    state.all.data = state.all.data.concat(newData);
    state.all.loading = false;
    state.all.loaded = true;
    state.all.error = undefined;
  },
};

// actions
/**
 * parameters:
 *  allocated_to
 *  customer
 *  prospect
 *  offset
 *  limit
 *  sort
 */
export const getAllActions = {
  [GET_ALL]: async ({ commit }, { parameters, append = false }) => {
    try {
      const params = querystring.stringify(parameters);
      
      commit(GET_ALL_LOADING);

      let data = await httpRequest('get', `interactions?${params}`, {}, {version: '2',}) || []; // Initialise array if no data is returned

      data.forEach(item => {
        item.start = DateTime.fromISO(item.date_start).toFormat('yyyy-MM-dd HH:mm:ss ZZ');
        item.end = DateTime.fromISO(item.date_end).toFormat('yyyy-MM-dd HH:mm:ss ZZ');
        item.title = item.is_personal ? 'Personal' : item.relationships.contacts && item.relationships.contacts[0] ? item.relationships.contacts[0].full_name : '';
        item.content = item.description;
      });

      commit(append ? MERGE_INTERACTIONS : GET_ALL, data);

      return true;
    } catch (error) {
      commit(GET_ALL_ERROR, error);
      return false;
    }
  },
  [CLEAR_ALL]: ({ commit, }) => {
    commit(CLEAR_ALL);
  },
};
