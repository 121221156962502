import { getTotalsActions, getTotalsMutations } from './getTotals';

// initial state
const state = () => ({
  loading: true,
  loaded: false,
  data: {
    totals: {},
    file_types: [],
  },
  errors: [],
});

// getters
const getters = {
};

// mutations
const mutations = {
  ...getTotalsMutations,
};

// actions
const actions = {
  ...getTotalsActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
