<template>
  <div class="form-group">
    <label
      :class="[classes]"
      :for="id"
    >
      <div
        class="search"
      >
        <span v-if="required && label">*</span> {{ t(label) }}
        <div
          class="input"
          :class="[classes]"
          :disabled="disabled"
        >
          <button
            @click="close"
          >
            <IconBase
              :width="iconSize"
              :height="iconSize"
              icon-name="close-line"
              :icon-color="closeIconColor"
              class="icon"
              :disabled="disabled"
              @click="close"
            />
          </button>
          <input
            :id="id"
            ref="searchinput"
            v-model="searchInputRaw"
            type="text"
            :placeholder="t(placeholder)"
            class="search-input mt-0"
            :disabled="disabled"
            :tabindex="disabled ? -1 : 0"
          >
        </div>
        <small
          v-if="error && errorMessage"
          class="error-text"
        >{{ errorMessage }}</small>
      </div>
    </label>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { IconBase } from '@sales-i/dsv3';
import { debounce, t } from '@sales-i/utils';

export default {
  components: {
    IconBase,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Start typing to search...',
    },
    iconSize: {
      type: Number,
      default: 32,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: 'There is a problem',
    },
    closeIconColor: {
      type: String,
      default: 'var(--colour-utility-action)'
    },
  },
  emits: ['close', 'search'],
  setup(props, { emit }) {
    const searchinput = ref(null);
    const searchInputRaw = ref(props.value || '');
    const searchInputResult = ref(props.value || '');

    const classes = computed(() => ({
      'input-error': props.error,
      'mt-1': !!props.label,
    }));

    watch(() => searchInputRaw.value, debounce(function(newVal) {
      if (newVal === searchInputResult.value) return;
      searchInputResult.value = newVal;
      search();
    }, 500));

    // this is used for parent component to focus on show
    // eslint-disable-next-line
    const focus = () => {
      searchinput.value.focus();
    };

    const search = async() => {
      emit('search', searchInputResult.value);
    };

    const close = () => {
      searchInputResult.value = '';
      searchInputRaw.value = '';
      emit('close');
    };

    return {
      searchinput,
      searchInputRaw,
      classes,
      focus,
      close,
      t,
    };
  }
};
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0;
  width: 100%;
}

.input {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 900px;
  width: 100%;
  border: 2px solid transparent;
  padding: 0 var(--spacing-1);
  background-color: var(--colour-panel-g-0);
  font-weight: var(--font-weight-medium);
  box-shadow: 0px 0px 0px 1px var(--border-utility-base);
  overflow: hidden;

  &:focus-within:not([disabled]) {
    box-shadow: 0px 0px 0px 4px var(--colour-utility-focus);
    border: 2px solid var(--colour-utility-checked);
    outline: 2px solid transparent;
  }

  &.input-error:not([disabled]) {
    box-shadow: 0px 0px 0px 4px var(--colour-utility-error);
    border: 2px solid var(--colour-utility-checked);
    outline: 2px solid transparent;
  }

  input,
  input:focus {
    border: none;
    box-shadow: none;
    outline: none;
    padding-left: var(--spacing-1);
  }

  .close {
    cursor: pointer;
  }
}
</style>
