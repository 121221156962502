export const root = 'admin';
export const baseUrl = `/${root}`;
export const intelligenceArea = 'intelligence';
export const crmArea = 'crm';
export const companyArea = 'company';
export const associationsSection = 'associations';
export const communitiesSection = 'communities';
export const enquiriesSection = 'enquiries';
export const opportunitiesSection = 'opportunities';
export const contactsSection = 'contacts';
export const interactionsSection = 'interactions';
export const customersPropspectsSection = 'customers';
export const tagsSection = 'tags';
export const tagConsolidationSection = 'tag-consolidation';
export const tagsReportSection = 'tag-reporting';
export const emailSyncSection = 'email-sync';
export const storageSection = 'storage';
export const permissionsSection = 'permissions';
export const targetsSection = 'targets';
export const usersSection = 'users';
export const rolesSection = 'roles';
export const logoSection = 'logo';
export const customSectionsPath = 'custom-sections';
export const stagesPath = 'stages';
export const blacklistPath = 'blacklist';
