import {
  GET_BY_ID,
  GET_BY_ID_LOADING,
  GET_BY_ID_ERROR,
  CLEAR_BY_ID
} from '@/shared/store/actionType';
import {
  GET_REP_CODES,
  GET_REP_CODES_LOADING,
  GET_REP_CODES_ERROR,
} from '@/admin/store/actionType';
import { httpRequest, updateBreadcrumb } from '@sales-i/utils';
import { CLEAR_CURRENTLY_EDITING } from '@/crm/store/actionType';

function createCurrentlyEditing(user) {
  return {
    details: {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      telephone_1: user.telephone_1,
      telephone_2: user.telephone_2,
      sales_rep_codes: user.sales_rep_codes,
    },
    roles: user.roles,
    restrictions: {},
    status: user.status,
  };
}

// mutations
export const getByIdMutations = {
  [GET_REP_CODES]: (state, data) => {
    state.rep_codes = data || [];
  },
  [GET_REP_CODES_LOADING]: () => {
  },
  [GET_REP_CODES_ERROR]: () => {
  },
  [GET_BY_ID]: (state, {data, currentlyEditing}) => {
    state.currentlyEditing = currentlyEditing;
    state.selected.data = data;
    state.selected.loaded = true;
    state.selected.loading = false;
  },
  [GET_BY_ID_LOADING]: (state) => {
    state.selected.loaded = false;
    state.selected.loading = true;
  },
  [GET_BY_ID_ERROR]: (state) => {
    state.selected.loaded = false;
    state.selected.loading = false;
  },
  [CLEAR_BY_ID]: (state) => {
    state.selected.data = {};
    state.selected.loaded = false;
    state.selected.loading = false;
    state.restrictionsExist = false;
  },
};

// actions
export const getByIdActions = {
  [GET_REP_CODES]: async ({ commit, }) => {
    try {
      const options = {version: '2', area: 'erp'};
      commit(GET_REP_CODES_LOADING);
      const data = await httpRequest('get', 'salesrep/unmapped', {}, options);
      commit(GET_REP_CODES, data);
    } catch (error) {
      console.error(error);
      commit(GET_REP_CODES_ERROR);
      return false;
    }
  },
  [GET_BY_ID]: async ({ commit, }, parameters) => {
    try {
      const { id, options = {version: '2', area: 'iam'} } = parameters;
      commit(GET_BY_ID_LOADING);
      const data = await httpRequest('get', `subjects/${id}`, {}, options);
      let currentlyEditing = createCurrentlyEditing(data);
      commit(GET_BY_ID, {data, currentlyEditing});
      updateBreadcrumb('users', id, `${data.first_name} ${data.last_name}`);
      return data;
    } catch (error) {
      console.error(error);
      commit(GET_BY_ID_ERROR);
      return false;
    }
  },
  [CLEAR_BY_ID]: ({ commit, }) => {
    commit(CLEAR_BY_ID);
    commit(CLEAR_CURRENTLY_EDITING);
  },
};
