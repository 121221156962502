import {
  SET_CONFIRMATION_MODAL,
  CLEAR_CONFIRMATION_MODAL
} from '@/shared/store/actionType';

let initialState = {
  show: false,
  heading: undefined,
  message: undefined,
  updatedHeading: undefined,
  updatedMessage: undefined,
  cancelLabel: undefined,
  saveLabel: undefined,
  updatedLabel: undefined,
  errorSaveLabel: undefined,
  errorCancelLabel: undefined,
  iconName: undefined,
  iconColor: undefined,
  closeOnUpdate: undefined,
  closeOnCancel: undefined,
  updateFunction: undefined,
  finishFunction: undefined,
  closeFunction: undefined,
  errorHeading: undefined,
  errorMessage: undefined,
};

// initial state
const state = () => ({
  data: { ...initialState, },
});

// getters
const getters = {
};

// mutations
const mutations = {
  [SET_CONFIRMATION_MODAL]: (state, data) => {
    state.data = {
      ...initialState,
      show: true,
      ...data,
    };
  },
  [CLEAR_CONFIRMATION_MODAL]: (state) => {
    state.data = { ...initialState, };
  },
};

// actions
const actions = {
  [SET_CONFIRMATION_MODAL] ({ commit, }, payload) {
    commit(SET_CONFIRMATION_MODAL, payload);
  },
  [CLEAR_CONFIRMATION_MODAL] ({ commit, }) {
    commit(CLEAR_CONFIRMATION_MODAL);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
