export default {
  props: {
    pageLimit: {
      type: Number,
      default: 50
    },
    pageOffset: {
      type: Number,
      default: 0
    },
    /** @todo replace by computed when attrs will work again */
    twoWayPagination: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      limit: this.pageLimit,
      offsetOneWay: this.pageOffset,
    };
  },
  computed: {
    // twoWayPagination() {
    //   return (this.$attrs && !!this.$attrs.onPageChanged); 
    // },
    offset() {
      return this.twoWayPagination ? this.pageOffset : this.offsetOneWay;
    }
  },
  methods: {
    handlePageChanged(offset) {
      this.offsetOneWay = offset;
      if (this.loadPageData) {
        this.loadPageData(); // provide this method in component
      }
      this.$emit('pageChanged', offset);
    },
  },
};
