<template>
  <div :class="dateTimeClass">
    <span v-if="!isFullTime">{{ contractedTime }}</span>
    <span v-else>{{ fullTime }}</span>
    <button @click.stop="alternateTime">
      <IconBase
        icon-name="more"
        :icon-color="iconColor"
        class="icon"
        :height="16"
        :width="16"
      />
    </button>
  </div>
</template>

<script>
import { dates } from '@sales-i/utils';
import { IconBase } from '@sales-i/dsv3';

export default {
  name: 'DateTimeSnippet',
  components: {
    IconBase,
  },
  props: {
    value: {
      type: [Date, String],
      default: () => new Date(),
    },
  },
  data() {
    return {
      isFullTime: false,
    };
  },
  computed: {
    date() {
      return new Date(this.value);
    },
    dateTimeClass() {
      return {
        'date-time-snippet': true,
        'full-time': this.isFullTime,
      };
    },
    iconColor() {
      return this.isFullTime ? 'var(--colour-utility-white)' : 'var(--colour-utility-black)';
    },
    fullTime() {
      return dates.getFullTime(this.date);
    },
    contractedTime() {
      return dates.getContractedTime(this.date);
    },
  },
  mounted() {},
  methods: {
    alternateTime() {
      this.isFullTime = !this.isFullTime;
    },
  },
};
</script>

<style lang="scss" scoped>
.date-time-snippet {
  position: relative;
  display: inline-flex;
  &.full-time {
    position: absolute;
    top: calc(var(--spacing-1) * -1);
    left: 0;
    padding: var(--spacing-1);
    background-color: var(--colour-utility-black);
    color: var(--colour-utility-white);
    border-radius: var(--spacing-half);
    box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-colour);
    > span {
      padding-right: var(--spacing-3);
    }
  }
}
</style>
