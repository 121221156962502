<template>
  <div
    v-for="(item, index) in subtitles"
    :key="index"
    class="date-ranges"
  >
    <span class="indicator">{{ index + 1 }}:</span>
    <IconBase
      :key="index"
      icon-name="calendar"
      :height="24"
      :width="24"
    />
    <span class="date-range">{{ item }}</span>
  </div>
</template>
  
<script setup>
import { IconBase } from '@sales-i/dsv3';
  
defineProps({
  subtitles: {
    type: Array,
    required: true,
  },
});
</script>
  
<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.date-ranges {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: var(--spacing-2);

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    margin-bottom: 0;
    white-space: nowrap;
  }

  .indicator {
    display: block;
    width: var(--spacing-1);
    font-weight: var(--font-weight-bold);
    margin-right: var(--spacing-half);
  }

  .icon-calendar {
    display: inline-flex;
    margin-right: var(--spacing-1);
    vertical-align: bottom;
  }

  &:first-of-type:after {
    content: '|';
    display: block;
    width: 20px;
    height: 20px;
    margin: 0 var(--spacing-1) var(--spacing-4);
    position: absolute;
    top: var(--spacing-3);
    left: 6px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      content: '―';
      margin: 0 var(--spacing-1);
      position: relative;
      top: 0;
      left: 0;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
</style>
  