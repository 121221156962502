import { computed } from 'vue';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import {
  REPORT_AREA_PRODUCT,
  REPORT_AREA_CUSTOMER,
  REPORT_AREA_PERFORMANCE
} from '@/intelligence/store/data/areas';
import {
  baseUrl as crmBaseUrl,
  customersArea,
  prospectsArea,
  opportunitiesArea,
  interactionsArea,
} from '@/crm/router/urlBits';
import {
  baseUrl as intelligenceBaseUrl,
  enquiryScope,
  insightsScope,
  alertsScope
} from '@/intelligence/router/urlBits';
import {
  FULL_PICTURE,
  FULL_PICTURE_TITLE,
  MATRIX,
  MATRIX_TITLE,
  SALES_VS_GAPS,
  SALES_VS_GAPS_TITLE,
  TRENDS,
  TRENDS_TITLE,
  VARIANCE,
  VARIANCE_TITLE,
} from '@/intelligence/store/data/reportTypes';
import {
  customers,
  interactions,
  opportunities,
  prospects,
  intelligence_enquiries,
  intelligence_insights,
  alert_builder,
} from '@/shared/store/data/policies';
import { checkAccessAgainstArray } from '@/shared/store/utils/checkAccessAgainstArray';
import { capitalize } from '@/shared/utils/strings';
import usePermissions from '@/shared/composables/usePermissions';

export default function useNavigation() {
  const {
    permissions: permissionsComputed,
    hasAccessToPerformanceInsights,
    hasAccessToProductInsights,
    hasAccessToCustomerInsights,
  } = usePermissions();   
  
  const store = useStore();
  const isDemo = computed(() => store.getters['system/isDemo']);
  const { hasAccess } = usePermissions();
  const canAccessCustomers = computed(() => hasAccess(customers));

  const navigation = computed(() => {
    const permissions = permissionsComputed.value;
    const CRMMenuItem = {
      title: t('CRM'),
      path: '/',
      sections: [],
    };
    let CRMSectionOne = { items: [] };
    if (permissions[customers] && permissions[customers].read) {
      CRMSectionOne.items.push({
        title: t('Customers'),
        path: `${crmBaseUrl}/${customersArea}`,
      });
    }
    if (permissions[prospects] && permissions[prospects].read) {
      CRMSectionOne.items.push({
        title: t('Prospects'),
        path: `${crmBaseUrl}/${prospectsArea}`,
      });
    }
    if (checkAccessAgainstArray(permissions, [customers, prospects])) {
      CRMMenuItem.sections.push(CRMSectionOne);
    }
    let CRMSectionTwo = { items: [] };
    if (permissions[interactions] && permissions[interactions].read) {
      CRMSectionTwo.items.push({
        title: t('Interactions'),
        path: `${crmBaseUrl}/${interactionsArea}`,
      });
    }
    if (permissions[opportunities] && permissions[opportunities].read) {
      CRMSectionTwo.items.push({
        title: t('Opportunities'),
        path: `${crmBaseUrl}/${opportunitiesArea}`,
      });
    }
    if (checkAccessAgainstArray(permissions, [interactions, opportunities])) {
      CRMMenuItem.sections.push(CRMSectionTwo);
    }
    const EnquiriesMenuItem = {
      title: t('Enquiries'),
      path: '',
      sections: [],
    };
    const InsightsMenuItem = {
      title: t('Insights'),
      path: '',
      sections: [],
    };
    let InsightsItems = [];
    if (hasAccessToPerformanceInsights.value) {
      InsightsItems.push({
        title: capitalize(REPORT_AREA_PERFORMANCE),
        path: `${intelligenceBaseUrl}/${insightsScope}/${REPORT_AREA_PERFORMANCE}`,
      });
    }
    if (hasAccessToProductInsights.value) {
      InsightsItems.push({
        title: capitalize(REPORT_AREA_PRODUCT),
        path: `${intelligenceBaseUrl}/${insightsScope}/${REPORT_AREA_PRODUCT}`,
      });
    }
    if (hasAccessToCustomerInsights.value && canAccessCustomers.value) {
      InsightsItems.push({
        title: capitalize(REPORT_AREA_CUSTOMER),
        path: `${intelligenceBaseUrl}/${insightsScope}/${REPORT_AREA_CUSTOMER}`,
      });
    }
    if (isDemo.value) {
      InsightsItems.push({
        title: t('Tree Map'),
        path: `${intelligenceBaseUrl}/${insightsScope}/tree-map`,
      });
    }
    let EnquiriesItems = [];
    EnquiriesItems.push({
      title: t(FULL_PICTURE_TITLE),
      path: `${intelligenceBaseUrl}/${enquiryScope}/${FULL_PICTURE}`,
    });
    EnquiriesItems.push({
      title: VARIANCE_TITLE,
      path: `${intelligenceBaseUrl}/${enquiryScope}/${VARIANCE}`,
    });
    EnquiriesItems.push({
      title: MATRIX_TITLE,
      path: `${intelligenceBaseUrl}/${enquiryScope}/${MATRIX}`,
    });
    EnquiriesItems.push({
      title: TRENDS_TITLE,
      path: `${intelligenceBaseUrl}/${enquiryScope}/${TRENDS}`,
    });
    EnquiriesItems.push({
      title: SALES_VS_GAPS_TITLE,
      path: `${intelligenceBaseUrl}/${enquiryScope}/${SALES_VS_GAPS}`,
    });
    let menu = [
    ];
    if (checkAccessAgainstArray(permissions, [intelligence_enquiries])) {
      EnquiriesMenuItem.sections.push({ items: EnquiriesItems });
      menu.push(EnquiriesMenuItem);
    }
    if (checkAccessAgainstArray(permissions, [intelligence_insights])) {
      InsightsMenuItem.sections.push({ items: InsightsItems });
      menu.push(InsightsMenuItem);
    }
    if (checkAccessAgainstArray(permissions, [alert_builder])) {
      menu.push({
        title: t('Alerts'),
        path: `${intelligenceBaseUrl}/${alertsScope}`
      });
    }
    menu.push(CRMMenuItem);
    return menu;
  });
  
  return {
    navigation,
  };
}