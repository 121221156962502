import {
  DELETE_BY_ID_LOADING,
  DELETE_BY_ID,
  DELETE_BY_ID_ERROR,
} from '@/shared/store/actionType';
import { httpRequest, pluralise } from '@sales-i/utils';

const defaultOptions = {
  area: 'files',
  version: '2'
};

// mutations
export const deleteByIdMutations = {
  [DELETE_BY_ID_LOADING]: (state) => {
    state.loaded = false;
    state.loading = true;
    state.selected.errors = [];
  },
  [DELETE_BY_ID]: (state) => {
    state.loaded = true;
    state.loading = false;
    state.selected.data = {};
    state.selected.errors = [];
  },
  [DELETE_BY_ID_ERROR]: (state, errors) => {
    state.selected.errors = errors;
    state.selected.loaded = false;
    state.selected.loading = false;
  },
};

// actions
export const deleteByIdActions = {
  [DELETE_BY_ID]: async ({ commit, }, parameters) => {
    try {
      let { file, options, } = parameters;
      options = {...defaultOptions, ...options};
      commit(DELETE_BY_ID_LOADING);
      await httpRequest('delete', `${pluralise.string(file.entity_type)}/${file.entity_id}/${file.id}`, {}, options);
      commit(DELETE_BY_ID);
      return true;
    } catch (error) {
      console.error(error);
      commit(DELETE_BY_ID_ERROR, error);
      return false;
    }
  }
};

