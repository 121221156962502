<template>
  <div
    v-for="(rule, i) in rules"
    :key="i"
  >
    <template v-if="i >= 2">
      <div class="individually-editable-chunk">
        <CustomButton
          v-if="clauseForTheseRules.clause === 'and'"
          :purpose="clauseForTheseRules.clause === 'and' ? 'active' : 'inactive'"
          small
          disabled
          @click="editClause(clauseForTheseRules, 'and', rule)"
        >
          {{ t('AND') }}
        </CustomButton>
        <CustomButton
          v-if="clauseForTheseRules.clause === 'or'"
          :purpose="clauseForTheseRules.clause === 'or' ? 'active' : 'inactive'"
          small
          disabled
          @click="editClause(clauseForTheseRules, 'or', rule)"
        >
          {{ t('OR') }}
        </CustomButton>
      </div>
    </template>
    <template v-if="Array.isArray(rule)">
      <div
        class="ruleset"
        :style="cssVar"
      >
        <IconButton
          class="delete-ruleset-btn"
          label="Delete Ruleset"
          icon-color="var(--colour-utility-white)"
          icon-name="trash"
          variant="round"
          @click="deleteRuleset(rule)"
        />
        <RecursiveRule
          :rules="rule"
          :depth="depth+1"
          @edit-clause="editClause"
          @edit-rule="editRule"
          @delete-rule="deleteRule"
          @delete-ruleset="deleteRuleset"
        />
      </div>
    </template>
    <template v-else-if="!rule.clause">
      <div class="individually-editable-chunk">
        {{ stringRulesFromArray([rule]) }}
        <CustomButton
          label="Edit"
          purpose="text"
          small
          @on-click="editRule(rule)"
        />
        <CustomButton
          label="Delete"
          purpose="text"
          small
          @on-click="deleteRule(rule)"
        />
      </div>
    </template>
  </div>
</template>

<script setup>
// RecursiveRule
import { t } from '@sales-i/utils';
import { onMounted, computed } from 'vue';
import { CustomButton, IconButton } from '@sales-i/dsv3';
import { stringRulesFromArray } from '@/admin/store/utils/dataRestrictionFormatting';

const props = defineProps({
  rules: {
    type: Array,
    default: () => []
  },
  depth: {
    type: Number,
    default: 0,
  }
});

const emit = defineEmits(['editRule', 'editClause', 'deleteRule', 'deleteRuleset']);

const clauseForTheseRules = computed(() => 
  props.rules.length >= 1 && props.rules[0].clause ? props.rules[0] : {}
);

const cssVar = computed(() => ({ '--ruleset-depth': `${props.depth * 5}%` }));

onMounted(() => {
});

const editRule = (rule) => {
  emit('editRule', rule);
};

const deleteRule = rule => {
  emit('deleteRule', rule);
};

const editClause = (current, newClause) => {
  if (current.clause === newClause) return;
  emit('editClause',
    current,
    newClause,
  );
};

const deleteRuleset = ruleset => {
  emit('deleteRuleset', ruleset);
};
</script>

<style lang="scss" scoped>
.individually-editable-chunk {
  display: flex;
  gap: var(--spacing-2);
  margin-bottom: var(--spacing-1);
}

.delete-ruleset-btn {
  align-self: end;
}

.ruleset {
  --ruleset-background: 0%;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  background-color: color-mix(in srgb,var(--colour-panel-g-8),#000 var(--ruleset-depth));
  width: fit-content;
  padding: var(--spacing-2);
  border-radius: var(--border-radius-1);
}
</style>
