<template>
  <div class="storage-container">
    <ProgressBar
      :value="percentage"
      custom-color="var(--colour-panel-g-64)"
      custom-background-color="var(--colour-panel-g-16)"
    />
    <div class="usage-text">
      {{ youHaveUsed }}
    </div>
    <div class="pie-visualisation">
      <div id="my_dataviz" />
      <div class="pie-key">
        <h2>{{ t('Usage Breakdown') }}</h2>
        <div class="key-table">
          <div>
            <div
              v-for="color in usedColoursArray"
              :key="color"
              class="key-color"
              :style="`background-color:${color}`"
            />
          </div>
          <div>
            <div
              v-for="item in chartData.labels"
              :key="item"
              class="key-label"
            >
              {{ item }}
            </div>
          </div>
          <div>
            <div
              v-for="item in chartData.sizesAndCounts"
              :key="item"
              class="key-label"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div id="used_dataviz" />
    </div>
  </div>
</template>

<script type="text/javascript">
import * as d3 from 'd3';
import { mapActions, mapState } from 'vuex';
import { GET_TOTALS } from '@/admin/store/actionType';
import { ProgressBar } from '@sales-i/dsv3';
import { t } from '@sales-i/utils';

export default {
  name: 'DocumentStorageView',
  components: {
    ProgressBar,
  },
  data() {
    return {
      file_count: 99,
      file_size: 536870912,
      file_size_human: '512 MB',
      colorsArray: {
        'doc': '#64c4e0', // doc
        'docx': '#64c4e0', // docx
        'pdf': '#efc7ca', // pdf
        'txt': '#c4e7f2', // txt

        'gif': '#cac7df', // gif
        'jpeg': '#7360a4', // jpeg
        'jpg': '#7360a4', // jpg
        'png': '#544388', // png

        'ppt': '#dfcd11', // ppt
        'pptx': '#dfcd11', // pptx

        'csv': '#7ec197', // csv
        'xls': '#1a6731', // xls
        'xlsx': '#1a6731',// xlsx
      },
    };
  },
  computed: {
    ...mapState({
      files: state => state.admin.files.data.file_types,
      totals: state => state.admin.files.data.totals,
    }),
    percentage() {
      let percentageUsed = ((this.totals.file_size / this.totals.storage_quota) * 100).toFixed(2);
      let len = percentageUsed.length;
      percentageUsed = percentageUsed.substr(len - 2) === '00' ? percentageUsed.substr(0, len - 3) : percentageUsed;
      return parseFloat(percentageUsed, 10);
    },
    youHaveUsed() {
      let { file_count, file_size_human, storage_quota_human } = this.totals;
      return t(
        `You have ${file_count} files and have used ${file_size_human} of ${storage_quota_human} (${this.percentage}%)`,
        'you_have_count_files_and_have_used_amount_of_total_percentage',
        {
          interpolations: {
            count: file_count,
            amount: file_size_human,
            total: storage_quota_human,
            percentage: this.percentage,
          },
        }
      );
    },
    usedColoursArray() {
      let x = 0;
      const colours = [];
      const { labels } = this.chartData;
      for (x; x < labels.length; x++) {
        colours.push(this.colorsArray[labels[x]]);
      }
      return colours;
    },
    chartData() {
      let values = [];
      let labels = [];
      let sizesAndCounts = [];
      let manipulatedData = [...this.files];
      manipulatedData
        .sort((a, b) => {
          // alphabetical a-z by file type
          return a.file_type > b.file_type ? 1 : -1;
        })
        .forEach(item => {
          values.push(item.file_size);
          labels.push(item.file_type);
          let filesStr = item.file_count === 1 ? t('file', 'file_lowercase') : t('files', 'files_lowercase');
          sizesAndCounts.push(
            t(`${item.file_count} ${filesStr} (${item.file_size_human})`, 'count_filestr_size', {
              interpolations: {
                count: item.file_count,
                filestr: filesStr,
                size: item.file_size_human,
              },
            })
          );
        });
      return { values, labels, sizesAndCounts };
    },
  },
  watch: {
    chartData() {
      this.generateChart();
    },
  },
  mounted() {
    this.getTotals();
    this.generateChart();

    window.addEventListener('resize', this.resize);
  },
  unmounted() {
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    t,
    ...mapActions({
      getTotals: `admin/files/${GET_TOTALS}`,
    }),
    resize() {
      this.generateChart();
    },
    generateChart() {
      this.removeChart();
      let { values } = this.chartData;
      let width = 600,
        height = 400;

      if (window.innerWidth < width) {
        width = window.innerWidth;
        height = window.innerWidth * (2/3);
      }
      var container = d3.select('#my_dataviz'),
        svg = container.append('svg').attr('width', width).attr('height', height),
        g = svg.append('g').attr('transform', `translate(${width / 2}, ${height / 2})`),
        radius = Math.min(width, height) / 2;

      var color = d3.scaleOrdinal(this.usedColoursArray);

      // Generate the pie
      var pie = d3.pie().sort(null);

      // Generate the arcs
      var arc = d3.arc().innerRadius(0).outerRadius(radius);

      //Generate groups
      var arcs = g.selectAll('arc').data(pie(values)).enter().append('g').attr('class', 'arc');

      //Draw arc paths
      arcs
        .append('path')
        .attr('fill', function (d, i) {
          return color(i);
        })
        .attr('d', arc);

      // Append labels to Pie directly, but too many small slices, does not work well...
      // arcs
      //   .append('text')
      //   .text(function(d, i){ return labels[i]})
      //   .attr('transform', function(d) { return `translate(${arc.centroid(d)})`;  })
      //   .style('text-anchor', 'middle')
      //   .style('font-size', 17);
    },
    removeChart() {
      d3.select('#my_dataviz svg').remove();
      d3.select('#used_dataviz svg').remove();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.storage-container {
  padding: var(--spacing-3);
  background-color: var(--colour-panel-action);
  border-radius: var(--border-radius-1);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-colour);
  .usage-text {
    text-align: center;
    margin-top: var(--spacing-2);
    margin-bottom: var(--spacing-2);
  }

  .pie-visualisation {
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      flex-direction: row;
    }
  }

  .pie-key {
    h2 {
      margin-bottom: var(--spacing-2);
    }
    .key-table {
      display: flex;
      justify-content: center;
    }
    .key-table > div {
      margin-right: var(--spacing-2);
    }
    .key-table .key-color {
      border: 1px solid var(--utility-color-neutral);
      height: 20px;
      width: 20px;
      border-radius: var(--border-radius-1);
      margin-bottom: var(--spacing-2);
    }
    .key-table .key-label {
      height: 20px;
      margin-bottom: var(--spacing-2);
    }
  }
}
</style>
