<template>
  <Teleport to="#modal-teleport-target">
    <UserDetailsModal
      v-if="userId"
      :id="userId"
      @close-modal="closeModal"
    />
  </Teleport>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute, } from 'vue-router';
import { baseUrl, companyArea, permissionsSection, usersSection } from '@/admin/router/urlBits';
import UserDetailsModal from '@/admin/components/Company/Permissions/Users/UserDetailsModal.vue';
import { navigateToUrl } from 'single-spa';

const vroute = useRoute();

const userId = ref(false);

onMounted(() => {
  userId.value = vroute.params.id;
});

const closeModal = () => {
  navigateToUrl(`${baseUrl}/${companyArea}/${permissionsSection}/${usersSection}`);
};
</script>

<style lang="scss" scoped>
</style>
