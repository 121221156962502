import { getAllGetters, getAllMutations, getAllActions } from './getAll';
// initial state
const state = () => ({
  all: {
    data: [],
    loaded: false,
    loading: false,
    error: undefined
  },
});

// getters
const getters = {
  ...getAllGetters
};

// mutations
const mutations = {
  ...getAllMutations
};

// actions
const actions = {
  ...getAllActions
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
