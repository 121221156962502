import InsightsView from '@/crm/views/Insights/Insights.vue';

import {
  insightsSection as section,
} from '@/crm/router/urlBits';

export default {
  path: section,
  component: InsightsView,
  props: { twoWayPagination: false },
};
