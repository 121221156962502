<template>
  <div class="container container--fluid bg">
    <div class="padded">
      <h2>{{ t('Interactions history') }}</h2>
      <div class="flex button-group">
        <CustomInput
          class="mt-1"
          :placeholder="t('Search')"
          icon-color="var(--colour-utility-action)"
          icon-name="search"
          :value="search"
          :error="isError"
          :error-message="t('Text is not found on this page')"
          @input="handleSearch"
        />
        <div class="flex">
          <TabSwitch
            :selected-value="currentViewMode"
            :items="viewTabs"
            @switch-selected="switchMode"
          />
        </div>
      </div>
      <div v-if="interactionsWithDetails.length && !loading && currentViewMode === 'grid-view'">
        <div
          role="list"
          class="list list-card"
        >
          <InteractionDropdownCard
            v-for="(item, index) in interactionsToShow"
            :key="index"
            class="interaction-card"
            :item="item"
            @cta-click="handleCtaClick"
          />
        </div>
        <BufferImage
          v-show="loading"
          class="buffer my-4"
          color="var(--colour-utility-black)"
          float="center"
        />
        <ButtonGroup class="buttons-group">
          <CustomButton
            v-if="canLoadMore"
            class="load-more-button"
            purpose="action"
            small
            @click="loadMoreInteractions"
          >
            {{ t('See More') }}
          </CustomButton>
        </ButtonGroup>
      </div>
      <InteractionsTable
        v-if="interactionsWithDetails.length && currentViewMode === 'list'"
        class="interactions-table"
        :interactions-to-show="interactionsToShow"
        :can-load-more="canLoadMore"
        :loading="loading"
        @load-more-data="loadMoreInteractions"
      />
      <div v-if="!interactionsWithDetails.length && loaded">
        <p class="no-interactions">
          {{ t('There are no interactions') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
// InteractionsHistory
import { computed, onMounted, ref, onBeforeMount } from 'vue';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';
import { useUsers } from '@/shared/composables/useUsers';
import InteractionDropdownCard from '@/crm/components/Calendar/InteractionDropdownCard.vue';
import { BufferImage, CustomInput, CustomButton, TabSwitch } from '@sales-i/dsv3';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';
import { searchObject, t } from '@sales-i/utils';
import { DateTime } from 'luxon';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { useInteraction } from '@/crm/composables/useInteraction';
import InteractionsTable from '@/crm/views/Interactions/InteractionsTable.vue';
import breakpoints from '@/shared/utils/breakpoints';
import { navigateToUrl } from 'single-spa';

const vroute = useRoute();
const store = useStore();

const props = defineProps({
  entityType: {
    type: String,
    default: 'customer',
    required: true,
  },
});

const offset = ref(0);
const search = ref('');
const limit = 50;
const currentViewMode = ref('list');

const { getUserById } = useUsers(store, true);

const isMobileView = computed(() => window.screen.width < breakpoints.breakpoints.md);
const canLoadMore = computed(() => interactions.value.all.data.length % limit === 0);
const isError = computed(() => !!search.value && interactionsWithDetails.value.length && !interactionsToShow.value.length);

const interactionsToShow = computed(() => (!search.value) 
  ? interactionsWithDetails.value 
  : interactionsWithDetails.value.filter(item => searchObject(item, search.value))
);

const interactionsWithDetails = computed(() =>
  interactions.value.all.data.map(x => ({
    ...x,
    user_name: getUserById(x.user_id).display_name,
  }))
);

const viewTabs = computed(() => {
  const gridTab = {
    title: t('Grid'),
    value: 'grid-view',
  };
  const listTab = {
    title: t('List'),
    value: 'list',
  };
  return [gridTab, listTab];
});

const queryParams = computed(() => ({
  sort: 'created_date:desc',
  date_from: DateTime.local().startOf('year').toISO(),
  date_to: DateTime.local().endOf('year').toISO(),
  limit,
  offset: offset.value,
  entity_id: vroute.params.id,
  entity_type: props.entityType,
  status: 'complete'
}));

onBeforeMount(() => {
  setDefaultView();
});

onMounted(() => {
  loadPageData();
});

const { loading, loaded, interactions, getInteractions } = useInteraction({ store, vroute });

async function loadPageData() {
  await getInteractions(queryParams.value, !!offset.value);
}
function loadMoreInteractions() {
  offset.value = offset.value + limit;
  loadPageData();
}
function handleCtaClick(item) {
  navigateToUrl(`${baseUrl}/${interactionsArea}/${item.id}`);
}
function handleSearch(value) {
  search.value = value;
}
function switchMode(item) {
  currentViewMode.value = item.value;
}
function setDefaultView() {
  currentViewMode.value = isMobileView.value ? 'grid-view' : 'list';
}
</script>

<style lang="scss" scoped>
h2 {
  margin: var(--spacing-2) 0 var(--spacing-3);
  text-align: center;
}

.bg {
  background-color: var(--colour-panel-g-2);
  flex: 1;
  margin-bottom: var(--spacing-2);
}

.interactions-table {
  overflow: hidden;
}

.button-group {
  justify-content: space-between;
  margin-bottom: var(--spacing-3);
  .flex {
    flex-direction: column-reverse;
    gap: var(--spacing-2);
  }
}

.no-interactions {
  text-align: center;
}
</style>
