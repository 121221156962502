export const getRequestParameters = () => ([reportType, params]) => {
  const {
    year = new Date().getFullYear(),
    interval = 'MONTHLY',
    offset = 0,
    limit = 20,
    sortHeader = 'name',
    sortOption = 'asc',
    name = '',
    description = '',
    dimensions = [],
    values = [],
    measurementId = 1,
    targetId,
    bubbleId,
  } = params;

  let processedDimensions = '';
  dimensions.forEach(dim => {
    processedDimensions += `{ id: "${dim.id}", values: [${dim.values.map(val => `"${val}"`)}] },`;
  });

  switch (reportType) {
  case 'get':
    return `
      filter: {
        year: ${year}, 
        interval: ${interval}
      }
      pagination: {
          offset: ${offset}, 
          limit: ${limit}
      }
      sort: "${sortHeader}:${sortOption}"
    `;
  case 'add':
  case 'update':
    return `
      input: {
        ${reportType === 'update' ? `id: ${targetId},` : ''}
        name: "${name}", 
        description: "${description}", 
        interval: ${interval}, 
        dimensions: [${processedDimensions}], 
        measurementID: ${measurementId}, 
        values: [${values}], 
        year: ${year}
      }
    `;
  case 'delete':
    return `id: ${targetId}`;
  case 'deleteTargetsByBubbleId':
    return `bubbleId: ${bubbleId}`;
  }
};
