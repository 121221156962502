export function getContactName(item = {}) {
  if (item.full_name) return item.full_name;
  // returns first name and/or surname with a space if required
  return (item.first_name || '') + (item.first_name && item.surname ? ' ' : '') + (item.surname || '');
}

const getFieldValue = (contact = {}) => {
  let subHeader = contact.customer ? contact.customer.name : '';

  return {
    ...contact,
    header: getContactName(contact),
    subHeader: subHeader,
  };
};

const mapContactsResponse = (contacts = [], sort = 'first_name:asc') => {
  let sortBy = sort.split(',')[0].split(':');
  let [ sortByValue, sortByDirection, ] = sortBy;
  const list = [];
  contacts.forEach(contact => {
    if (contact[sortByValue]) {
      let firstLetter = contact[sortByValue].trim().split('')[0].toUpperCase();
      const exists = list.find(({ title, }) => title === firstLetter);
      const values = getFieldValue(contact, undefined, false);
      if(!exists) {
        list.push({
          title: firstLetter,
          items: [values,],
        });
      } else {
        exists.items.push(values);
      }
    } else {
      const exists = list.find(({ title, }) => title === 'No Name');
      const values = getFieldValue(contact, undefined, false);
      if(!exists) {
        list.push({
          title: 'No Name',
          items: [values,],
        });
      } else {
        exists.items.push(values);
      }
    }
  });
  return list.sort((a, b) => {
    // Ensure No Name is always at bottom of list
    if (a.title === 'No Name') { return 1; }
    if (b.title === 'No Name') { return -1; }
    return a.title > b.title ? sortByDirection === 'asc' ? 1 : -1 : 1;
  });
};

export default mapContactsResponse;
