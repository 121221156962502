<template>
  <div id="treemap-iframe-wrapper" />
</template>

<script setup>
import { ref, onMounted } from 'vue';

const frame = ref(null);

onMounted(() => {
  const iframe = document.createElement('iframe');
  iframe.src = 'https://demous-images.sell-smart.com/tenants/smc/SMC_TreeMaps.html';
  iframe.id = 'treemap-iframe';
  frame.value = iframe;

  document.getElementById('treemap-iframe-wrapper').appendChild(iframe);
});
</script>

<style lang="scss">
#treemap-iframe {
  border: 0;
  width: 100%;
  margin: var(--spacing-2);
  height: 100vh;
}
</style>
