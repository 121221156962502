<template>
  <div class="key-info">
    <CustomButton
      v-if="userAddress"
      purpose="text"
      @click.prevent="loadAddress"
    >
      <IconBase
        icon-name="pin-outline"
        :width="24"
        :height="24"
      />
      {{ userAddress }}
    </CustomButton>
    <CustomButton
      v-if="phone"
      :href="`tel:${phone}`"
    >
      <IconBase
        icon-name="phone"
        :width="24"
        :height="24"
      />
      {{ phone }}
    </CustomButton>
    <CustomButton
      v-if="email"
      :href="`mailto:${email}`"
    >
      <IconBase
        icon-name="email"
        :width="24"
        :height="24"
      />
      {{ email }}
    </CustomButton>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { address } from '@sales-i/utils';
import { IconBase, CustomButton } from '@sales-i/dsv3';

const props = defineProps({
  email: {
    type: String,
    default: '',
  },
  phone: {
    type: String,
    default: '',
  },
  addresses: {
    type: Array,
    default: () => [],
  },
});

const userAddress = computed(() => {
  const addressObj = props.addresses[0];
  return address.asString(addressObj);
});
function loadAddress() {
  address.showOnMap(props.addresses[0]);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.key-info {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-small);
  background-color: var(--colour-panel-alert-base);
  gap: var(--spacing-3);
  padding: var(--spacing-1);
  border-radius: var(--border-radius-1);

  > button,
  a {
    display: flex;
    align-items: center;
    text-align: left;
    gap: var(--spacing-1);
  }
  :deep(.button-container) {
    align-items: center;
    gap: var(--spacing-half);
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    justify-content: center;
  }
}
</style>
