import BubbleSelection from '@/intelligence/components/Bubble/BubbleSelection.vue';
import EnquiryReportView from '@/intelligence/components/EnquiryReport/EnquiryReportView';
import { baseUrl, enquiryScope, reportScope } from '@/intelligence/router/urlBits';
import { MATRIX } from '@/intelligence/store/data/reportTypes';

export default [
  {
    path: `${baseUrl}/${enquiryScope}/${MATRIX}`,
    component: BubbleSelection,
    name: MATRIX,
    props: { reportType: MATRIX }
  },
  {
    path: `${baseUrl}/${enquiryScope}/${MATRIX}/${reportScope}`,
    component: EnquiryReportView,
    name: `${MATRIX}-${reportScope}`,
    props: { reportType: MATRIX }
  }
];
