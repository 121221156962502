<template>
  <div
    class="variance"
    :class="{ 'iframe-height': iframe }"
  >
    <BufferImage
      v-if="!isReportEnabled"
      color="black"
      float="center"
      class="loading-spinner"
    />
    <NoResults v-if="!rowCount && isReportEnabled" />
    <section
      v-else
      class="data-section"
    >
      <VarianceChart
        v-if="isReportEnabled && isChartEnabled"
        :key="enquiryData.rows.length"
        :chart-data="enquiryData"
        :depth="featureTreemapDepth"
        class="my-4"
      />
      <VarianceTable
        v-if="isReportEnabled"
        :table-parameters="tableParameters"
        :enquiry-data="enquiryData"
        :active-columns="activeColumns"
        :is-percent-value-active="isPercentValueActive"
        :is-initial-load="isInitialLoad"
        @handle-enquiry-drill="handleEnquiryDrill"
        @load-table="loadTable"
        @load-more-data="loadMoreData"
        @update:columns="$emit('update:columns', $event)"
      />
    </section>
    <ReportFooter
      v-if="isReportFooterVisible"
      :dimensions="dimensions"
      :report-type="reportType"
      :sub-title="title"
      :active-columns-data="activeColumnsData"
      :query="query"
    />
  </div>
</template>

<script>
// Variance
import { BufferImage } from '@sales-i/dsv3';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';
import { mapActions, mapGetters, mapState } from 'vuex';
import { FETCH_REPORT_DATA, SET_SORTING_DATA, APPLY_DATE_RANGE, FETCH_DATES, APPLY_LIMIT, APPLY_OFFSET } from '@/intelligence/store/actionType';
import { VARIANCE } from '@/intelligence/store/data/reportTypes';
import VarianceTable from '@/intelligence/components/Variance/VarianceTable';
import VarianceChart from '@/intelligence/components/Variance/VarianceChart';
import NoResults from '@/intelligence/components/Shared/NoResults';
import { intelligence_enquiries } from '@/shared/store/data/policies';
import runIfPermittedOrReject from '@/shared/store/utils/runIfPermittedOrReject';
import isRolldate from '@/intelligence/store/utils/isRolldate';
import useFeatures from '@/shared/composables/useFeatures';
import { REQUEST_ENTITY_VARIANCE_INITIAL } from '@/intelligence/store/data/apiInput';

export default {
  components: {
    BufferImage,
    ReportFooter,
    NoResults,
    VarianceTable,
    VarianceChart
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isReportEnabled: {
      type: Boolean,
      default: false,
    },
    isChartEnabled: {
      type: Boolean,
      default: false,
    },
    dateParams: {
      type: Object,
      required: true,
    },
    querySubstitute: {
      type: Object,
      default: () => ({}),
    },
    activeColumns: {
      type: Array,
      default: () => ([]),
    },
    isPercentValueActive: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['handleEnquiryDrill', 'loaded', 'loading', 'update:columns'],
  data() {
    return {
      tableParameters: null,
      reportType: VARIANCE,
      lazyLoadingData: [],
      pageSize: 100,
      varianceId: 0,
      activeColumnsData: [],
      isInitialLoad: true,
    };
  },
  computed: {
    ...mapState({
      fetchedDates: state => state.intelligence.calendar.fetchedDates,
      permissions: state => state.pbac.permissionsGranted,
      iframe: state => state.system.iframe,
    }),
    ...mapGetters({
      getReportData: 'intelligence/shared/getReportData',
      getInputData: 'intelligence/shared/getInputData',
      getResponseParameters: 'intelligence/shared/getResponseParameters'
    }),
    inputData() {
      return this.getInputData(this.reportType);
    },
    rowCount() {
      return this.enquiryData && this.enquiryData.row_count;
    },
    dimensions() {
      return this.enquiryData && this.enquiryData.headings.dimensions;
    },
    enquiryData() {
      if (!this.varianceId) return {};
      const reportData = { ...this.getReportData(this.varianceId) } || {};
      if (this.lazyLoadingData.length && reportData) {
        for (const iteration of this.lazyLoadingData) {
          const { row_count, rows } = iteration;
          reportData.row_count += row_count;
          reportData.rows = reportData.rows.concat(rows);
        }
      }
      return reportData;
    },
    isReportFooterVisible() {
      return this.isReportEnabled && Object.keys(this.enquiryData).length && this.enquiryData.rows.length && !this.iframe;
    },
    query() {
      return this.iframe ? this.querySubstitute : this.$route.query;
    },
    featureTreemapDepth() {
      return parseInt(useFeatures().getFeatureValue('feat_treemap_depth') || '2', 10);
    }
  },
  watch: {
    activeColumns: {
      handler(newColumns) {
        this.activeColumnsData = (newColumns || []).map(column => column.toUpperCase());
      },
      immediate: true,
    },
  },
  async beforeMount() {
    this.applyLimit(this.pageSize);
    const { defaultSortHeader, defaultSortOption } = this.inputData;
    const { sort } = this.query;
    await this.loadTable({
      sortHeader: sort ? sort.split(':')[0] : defaultSortHeader, 
      sortOption: sort ? sort.split(':')[1] : defaultSortOption, 
      activeColumns: []
    }, false);
  },
  mounted() {
    this.isInitialLoad = false;
  },
  methods: {
    ...mapActions({
      fetchReportData: `intelligence/shared/${FETCH_REPORT_DATA}`,
      setSortingData: `intelligence/enquiry/${SET_SORTING_DATA}`,
      applyDates: `intelligence/shared/${APPLY_DATE_RANGE}`,
      fetchDates: `intelligence/calendar/${FETCH_DATES}`,
      applyLimit: `intelligence/shared/${APPLY_LIMIT}`,
      applyOffset: `intelligence/shared/${APPLY_OFFSET}`,
    }),
    async loadTable(payload, completeRefresh = false) {
      await runIfPermittedOrReject(this.permissions, { name: intelligence_enquiries }, async () => {
        this.$emit('loading');
        const sortingData = [payload?.sortHeader, payload?.sortOption];

        // Check if activeColumns is an array or if 'sort' exists in this.query (this is in case if redirected from CustomerOverview component)
        if (Array.isArray(this.query.activeColumns) || this.query.sort) {
          this.isInitialLoad = false;
        }
        
        const customResponse = this.isInitialLoad ? this.getResponseParameters(REQUEST_ENTITY_VARIANCE_INITIAL) : null;
        this.setSortingData(sortingData);
        await this.setDateParams();
        this.lazyLoadingData = [];
        this.varianceId = await this.fetchReportData({ reportType: this.reportType, completeRefresh, customResponse });
        this.tableParameters = payload;
        this.$emit('loaded');
      });
    },
    async loadMoreData() {
      const lazyLoadId = await this.fetchReportData({ reportType: this.reportType });
      this.lazyLoadingData.push(this.getReportData(lazyLoadId));
    },
    handleEnquiryDrill(payload) {
      this.$emit('handleEnquiryDrill', payload);
    },
    async setDateParams() {
      const { date_from, date_to, date_from2, date_to2 } = this.dateParams;

      if (isRolldate(this.dateParams)) {
        await this.fetchDates({
          date_from,
          date_to,
          date_from2,
          date_to2,
        });
        this.applyDates(this.fetchedDates);
      } else {
        this.applyDates({
          date_from,
          date_to,
          date_from2,
          date_to2,
        });
      }

      this.applyOffset(0);
      this.lazyLoadingData = [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.variance {
  width: 100%;
  margin: 0 auto auto;
  border-radius: var(--border-radius-1);
  position: relative;
  background: var(--colour-utility-white);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
  display: flex;
  flex-direction: column;
  min-height: 580px;

  &.flex-space {
    justify-content: space-between;

    .data-section {
      flex: auto;
      display: block;
    }
  }

  .data-section {
    flex: 1;
    display: table;
    position: static;
    overflow: hidden;
    margin-bottom: var(--spacing-10);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      position: relative;
      margin-bottom: 0;
    }
  }

  &.iframe-height {
    min-height: 510px;
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

.sort-button {
  position: absolute;
  right: var(--spacing-half);
  top: calc(100% + var(--spacing-half));

  &.asc {
    transform: rotate(180deg);
  }
}
</style>
