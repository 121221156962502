<template>
  <div
    role="list"
    class="list list-card"
  >
    <CustomCard class="add-card">
      <template #header>
        <h2 class="fw-sbld">
          <slot name="addtitle">
            {{ t('Posts') }}
          </slot>
        </h2>
      </template>
      <p class="add-subtitle">
        <slot name="addsubtitle">
          {{ t('Use Posts to capture and share information.') }}
        </slot>
      </p>
      <div class="add-cta">
        <CustomButton
          v-if="permissions.create"
          class="mb-0"
          small
          :label="t('Add post')"
          @click="handleAddClick"
        />
      </div>
    </CustomCard>
    <Post
      v-for="item in props.data"
      :id="item.id"
      :key="item.id"
      :entity-id="props.id"
      :title="item.title"
      :created-by="item.created_by"
      :created-at="item.created_date"
      :content="item.text"
      :num-of-comments="numOfComments(item)"
      :enabled-actions="enabledActions"
      @item-clicked="itemClicked"
      @edit-post="editPost"
      @deleted-post="deletedPost"
    />
  </div>
</template>

<script setup>
import { t } from '@sales-i/utils';
import { CustomButton, CustomCard } from '@sales-i/dsv3';
import Post from '@/shared/components/Posts/Post';
import usePermissions from '@/shared/composables/usePermissions';
import { posts_comments } from '@/shared/store/data/policies';

const { getPermissions } = usePermissions();
const permissions = getPermissions(posts_comments);
const enabledActions = [permissions.update ? 'edit' : '', permissions.delete ? 'delete' : '', permissions.read ? 'open' : ''].filter(x => !!x);

// name: 'Posts',
const props = defineProps({
  id: {
    type: [Number, String],
    default: '',
  },
  data: {
    type: Array,
    default: () => [],
  },
  loaded: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['itemClicked', 'editPost', 'deletedPost', 'addPost']);

function itemClicked(id) {
  emit('itemClicked', id);
}
function editPost(id) {
  emit('editPost', id);
}
function deletedPost() {
  emit('deletedPost');
}
function numOfComments(item) {
  let countMetadata = (item.metadata || []).find(m => m.key === 'comment_count');
  return countMetadata ? countMetadata.value : '0';
}
function handleAddClick() {
  emit('addPost');
}
</script>

<style lang="scss" scoped>
.add-card {
  width: initial;
  height: initial;
  text-align: center;
  justify-content: center;

  .add-cta {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .add-subtitle {
    font-size: var(--font-size-4);
    line-height: 1.6;
  }
}
</style>
