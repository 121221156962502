<template>
  <AddEditContact
    :title="t('Adding a contact')"
    :customer-id="customerId"
    :prospect-id="prospectId"
    :saved="saved"
    :fields="fields"
    :entity="entity"
    @submit="save"
    @edit="edit"
    @close="close"
  />
</template>

<script>
import AddEditContact from '@/crm/components/Contacts/AddEditContact.vue';
import { mapState, mapActions } from 'vuex';
import { GET_FIELDS, CLEAR_FIELDS, CLEAR_BY_ID, POST_BY_ENTITY, PUSH_ROUTE } from '@/shared/store/actionType';
import { baseUrl, contactsSection } from '@/crm/router/urlBits';
import { t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'AddContactView',
  components: {
    AddEditContact,
  },
  props: {},
  emits: ['closeModal', 'updated'],
  data() {
    return {
      customerId: this.$route.params.id,
      prospectId: this.$route.params.id,
      saved: false,
    };
  },
  computed: {
    ...mapState({
      entity: state => state.system.entity,
      contact: state => state.crm.contacts.selected,
      customer: state => state.crm.customers.selected.data,
      prospect: state => state.crm.prospects.selected.data,
      fields: state => state.crm.contacts.fields,
    }),
  },
  mounted() {
    this.getFields();
  },
  methods: {
    t,
    ...mapActions([
      `crm/contacts/${GET_FIELDS}`,
      `crm/contacts/${CLEAR_FIELDS}`,
      `crm/contacts/${CLEAR_BY_ID}`,
      `crm/contacts/${POST_BY_ENTITY}`,
      `system/${PUSH_ROUTE}`,
    ]),
    getFields() {
      let entityStr = this.entity && this.entity.substr(0, this.entity.length - 1);
      if (entityStr && this[entityStr].id) {
        let { id } = this.$route.params;
        let { isDefault } = this.$route.query;
        this[`crm/contacts/${GET_FIELDS}`]({ entityId: id, setDefault: isDefault });
      } else {
        setTimeout(() => {
          this.getFields();
        }, 100);
      }
    },
    async save(payload, setSubmitting) {
      setSubmitting(true);
      let { id } = this.$route.params;
      await this[`crm/contacts/${POST_BY_ENTITY}`]({
        id: id,
        entity: this.entity,
        payload: payload,
      });
      setSubmitting(false);
      if (this.contact.loaded) {
        this[`crm/contacts/${GET_FIELDS}`](id);
        this.saved = true;
        this.$emit('updated');
      }
    },
    close() {
      let { id } = this.$route.params;
      this[`crm/contacts/${CLEAR_FIELDS}`]();
      this[`crm/contacts/${CLEAR_BY_ID}`]();
      this[`system/${PUSH_ROUTE}`]({
        defaultUrl: `${baseUrl}/${this.entity}/${id}/${contactsSection}`,
        success: this.saved,
      });
    },
    edit() {
      let { id } = this.$route.params;
      this[`crm/contacts/${CLEAR_FIELDS}`]();
      return navigateToUrl(`${baseUrl}/${this.entity}/${id}/${contactsSection}/${this.contact.data.id}/edit`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
