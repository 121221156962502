<template>
  <div class="calendar-sync-container flex">
    <div class="text-container">
      <h2>{{ t('Calendar Events') }}</h2>
      <p>
        {{ t("Sync Interactions from sales-i into your email provider's calendar and vice versa. Connecting to an email calendar will copy the events from that calendar to sales-i as Interactions and sales-i Interactions copied as Calendar Events") }}
      </p>
      <p v-if="emailStatus === 'VALID'">
        {{ t("Please select the appropriate calendar carefully as Interactions/Calendar Events created from the two-way sync cannot be easily reversed without manual bulk deletion.") }}
      </p>
    </div>
    <div class="buttons-container flex">
      <div class="calendar-choice flex">
        <CustomSelect
          v-if="!authData?.calendar_name"
          class="calendar-select bg-white text-4"
          :value="selectedCalendarId"
          :disabled="!calendarItems.length"
          :placeholder="t('Select a calendar to enable')"
          :items="calendarItems"
          @on-change="onCalendarChange"
        />
        <span
          v-else
          class="calendar-value text-4"
        >{{ authData?.calendar_name }}</span>
        <ButtonGroup
          v-if="!authData?.calendar_name"
          class="buttons-group"
        >
          <CustomButton
            :disabled="!calendarItems.length || !selectedCalendarId"
            small
            purpose="reversed"
            @on-click="clearCalendar"
          >
            {{ t('Clear') }}
          </CustomButton>
          <CustomButton
            :disabled="!selectedCalendarId"
            small
            purpose="action"
            @on-click="showConfirmation = true"
          >
            {{ t('Confirm') }}
          </CustomButton>
        </ButtonGroup>
        <CustomButton
          v-if="authData?.calendar_name"
          class="disconnect-button"
          small
          purpose="action"
          @on-click="disconnectCalendar"
        >
          {{ t('Stop Calendar Sync') }}
        </CustomButton>
      </div>
      <div
        v-if="selectedCalendarId && showConfirmation"
        class="confirmation-container flex"
      >
        <div class="flex">
          <IconBase
            icon-name="warning"
            icon-color="var(--colour-brand-mandy)"
            width="48"
            height="48"
          />
          <p class="text-4">
            {{ t('Are you sure you want to initiate two-way sync of all Interactions/Events in this calendar?') }}
          </p>
        </div>
        <ButtonGroup
          v-if="showConfirmation"
          class="buttons-group"
        >
          <CustomButton
            icon-name="close"
            icon-color="var(--colour-brand-mandy)"
            small
            purpose="reversed"
            @on-click="clearCalendar"
          >
            {{ t('Cancel') }}
          </CustomButton>
          <CustomButton
            icon-name="check"
            icon-color="var(--colour-data-de-york)"
            small
            purpose="reversed"
            @on-click="setCalendar"
          >
            {{ t("Yes, I'm sure") }}
          </CustomButton>
        </ButtonGroup>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { GET_CALENDAR, PUT_PRIMARY_CALENDAR, DELETE_CALENDAR } from '@/admin/store/actionType';
import { t } from '@sales-i/utils';
import { CustomSelect, CustomButton, IconBase } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';

const store = useStore();

defineProps({
  emailStatus: {
    type: [String, Number],
    default: 'INVALID',
  },
  authData: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['calendar-updated']);

const selectedCalendarId = ref(null);
const showConfirmation = ref(false);

const calendarItems = computed(() => {
  const calendars = store.state.admin.email.calendar.data;
  return calendars.map(calendar => ({
    value: calendar.id,
    text: calendar.name,
  }));
});

const getCalendar = () => store.dispatch(`admin/email/${GET_CALENDAR}`);
const putPrimaryCalendar = calendarId => store.dispatch(`admin/email/${PUT_PRIMARY_CALENDAR}`, calendarId);
const deleteCalendar = () => store.dispatch(`admin/email/${DELETE_CALENDAR}`);

onMounted(async () => {
  await getCalendar();
});

const setCalendar = async () => {
  if (selectedCalendarId.value) {
    try {
      await putPrimaryCalendar(selectedCalendarId.value);
      emit('calendar-updated');
      showConfirmation.value = false;
    } catch (error) {
      console.error('Failed to set calendar', error);
    }
  }
};

const clearCalendar = () => {
  showConfirmation.value = false;
  selectedCalendarId.value = null;
};

const onCalendarChange = (newId) => {
  selectedCalendarId.value = newId;
};

const disconnectCalendar = async () => {
  try {
    await deleteCalendar();
    selectedCalendarId.value = null;
    emit('calendar-updated'); 
  } catch (error) {
    console.error('Failed to disconnect calendar', error);
  }
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

div.flex {
  flex-direction: column;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    flex-direction: row;
  }
}

.text-container {
  width: 100%;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: 50%;
  }

  h2, p {
    margin-bottom: var(--spacing-2);
    color: var(--colour-utility-black);
  }

  p {
    font-size: var(--font-size-4);
    margin-bottom: var(--spacing-2);
  }
}

.buttons-container {
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  gap: 0;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: 40%;
  }

  .calendar-value {
    background-color: var(--colour-panel-g-16);
    width: 100%;
    text-align: center;
    padding: var(--spacing-1) 0;
    border-radius: var(--border-radius-half);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: initial;
      min-width: 50%;
    }
  }

  .confirmation-container {
    background-color: var(--colour-data-mandy-light);
    width: 100%;
    padding: var(--spacing-3);
    flex-direction: column;
    border-bottom-left-radius: var(--border-radius-1);
    border-bottom-right-radius: var(--border-radius-1);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      gap: 0;
    }

    .buttons-group {
      width: auto;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        align-self: flex-end;
      }
    }
  }
}

.calendar-choice {
  width: 100%;
  padding: var(--spacing-3);
  background-color: var(--colour-panel-action);
  border-top-left-radius: var(--border-radius-1);
  border-top-right-radius: var(--border-radius-1);
  justify-content: space-evenly;

  .buttons-group {
    width: auto;
    flex-wrap: nowrap;
    margin: 0 auto;
  }

  .calendar-select {
    margin-bottom: 0;
    width: 100%;
  }

  .disconnect-button {
    background-color: var(--colour-brand-mandy);
    width: 100%;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: initial;
    }
  }
}
</style>
