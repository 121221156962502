import Opportunities from '@/intelligence/views/Opportunities/Opportunities.vue';
import OpportunitiesView from '@/intelligence/views/Opportunities/OpportunitiesView.vue';
import { baseUrl, opportunitiesScope } from '@/intelligence/router/urlBits';

export default [
  {
    path: `${baseUrl}/${opportunitiesScope}`,
    component: Opportunities,
    children: [
      {
        path: 'missed',
        component: OpportunitiesView,
      },
      {
        path: 'potential',
        component: OpportunitiesView,
      },
    ],
  }
];
