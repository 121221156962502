<template>
  <LeftHandNavigation
    :loading="menu.loading"
    :items="menu.items"
    :active-section="activeSection"
    @item-clicked="itemClicked"
    @active-section-clicked="setActiveSection"
  >
    <router-view />
  </LeftHandNavigation>
</template>

<script type="text/javascript">
import LeftHandNavigation from '@/admin/components/Layout/LeftHandNavigation.vue';
import { baseUrl as adminBase, intelligenceArea } from '@/admin/router/urlBits';
import { t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'EnquiriesView',
  components: {
    LeftHandNavigation,
  },
  data() {
    return {
      activeSection: 0,
      menu: {
        loading: false,
        items: [
          {
            id: 0,
            name: t('Customer View'),
          },
        ],
      },
    };
  },
  mounted() {
    this.setActiveSection();
  },
  methods: {
    formatStringToUrl(string) {
      return string.toLowerCase().trim().replaceAll(' ', '-');
    },
    itemClicked(item) {
      let route = this.formatStringToUrl(item.name);
      let directTo = route !== 'customer-view' ? route : '';
      navigateToUrl(`${adminBase}/${intelligenceArea}/${directTo}`);
    },
    setActiveSection(index) {
      if (index === undefined && !this.$route.fullPath.endsWith('enquiries')) {
        const parts = this.$route.fullPath.split('/');
        index = this.menu.items.find(item => this.formatStringToUrl(item.name) === parts[parts.length - 1]).id;
        this.activeSection = index;
      } else {
        this.activeSection = 0;
      }
    },
  },
};
</script>

<style scoped></style>
