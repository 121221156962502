<template>
  <div class="stages">
    <div class="header-container">
      <button
        v-if="selectedIndex > 0 && !disabled"
        @click="stage(selectedIndex - 1)"
      >
        <IconBase
          icon-name="chevron-left"
          class="icon"
          :height="25"
          :width="25"
          icon-color="#7360A4"
        />
      </button>
      <p
        class="stage-label"
        :title="items[selectedIndex]"
      >
        {{ t('Stage') }} {{ selectedIndex + 1 }} / {{ items.length }} -
        {{ t(items[selectedIndex]) }}
      </p>
    </div>
    <div class="progress">
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="{
          bar: true,
          active: index === selectedIndex,
          clickable: index !== selectedIndex && index < selectedIndex,
          [`progress-stage-${index}`]: true,
        }"
        :title="items[selectedIndex]"
        role="button"
        tabindex="0"
        @click="stage(index)"
        @keydown.enter="stage(index)"
      />
    </div>
  </div>
</template>

<script>
import { IconBase } from '@sales-i/dsv3';
import { t } from '@sales-i/utils';

export default {
  name: 'Stage',
  components: {
    IconBase,
  },
  props: {
    selectedIndex: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['stage'],
  methods: {
    t,
    stage(index) {
      if (!this.disabled && index !== this.selectedIndex && index < this.selectedIndex) {
        this.$emit('stage', index);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.stages {
  .header-container {
    display: flex;
    margin-bottom: var(--spacing-1);
    align-items: center;
    button {
      display: flex;
      .icon {
        display: inline-block;
      }
    }
  }
  .progress {
    display: flex;

    .bar {
      background-color: var(--colour-panel-g-16);
      height: 9px;
      width: 100%;
      border-radius: calc(var(--border-radius-half) / 2);
      margin-left: var(--spacing-half);
      margin-right: var(--spacing-half);
      &.active {
        background-color: var(--colour-data-puerto-rico-dark);
      }
      &.clickable {
        cursor: pointer;
        &:hover {
          background-color: var(--colour-panel-g-32);
        }
      }
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }

    .clickable {
      background-color: var(--colour-data-puerto-rico-dark);
    }
  }
}

.stage-label {
  font-size: var(--font-size-5);
  margin: 0;
}
</style>
