/* eslint-disable no-unused-vars */
import {
  GET_ALL,
  GET_ALL_LOADING,
  GET_ALL_ERROR,
  CLEAR_ALL
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';
import querystring from 'querystring';

const demoData = [
  { description: 'en-GB English (Great Britain)', locale: 'en-gb', area: 'crm' },
  { description: 'en-US English (USA)', locale: 'en-us', area: 'crm' },
  { description: 'en-CA English (Canada)', locale: 'en-ca', area: 'crm' },
];

// mutations
export const getAllGetters = {
  languageOptions: (state) => {
    return state.all.data.map(
      x => ({ text: x.description, value: x.locale, ...x })
    );
  }
};

// mutations
export const getAllMutations = {
  [GET_ALL]: (state, data) => {
    state.all.data = data;
    state.all.loaded = true;
    state.all.loading = false;
    state.all.error = undefined;
  },
  [GET_ALL_LOADING]: (state) => {
    state.all.loaded = false;
    state.all.loading = true;
    state.all.error = undefined;
  },
  [GET_ALL_ERROR]: (state, error) => {
    state.all.loaded = false;
    state.all.loading = false;
    state.all.error = error;
  },
  [CLEAR_ALL]: (state) => {
    state.all.data = [];
    state.all.loaded = false;
    state.all.loading = false;
    state.all.error = undefined;
  },
};

// actions
/**
 * parameters:
 *  allocated_to
 *  customer
 *  prospect
 *  offset
 *  limit
 *  sort
 */
export const getAllActions = {
  [GET_ALL]: async ({ commit, }, parameters) => {
    try {
      const params = querystring.stringify(parameters);
      commit(GET_ALL_LOADING);
      // const response = await httpRequest('get', `opportunities?${params}`, {}, {version: '2',});
      // /** @todo remove mock */
      // const data = response.map((x,i) => ({
      //   ...x,
      //   ...{
      //     entity_type: x.entity_type == 'C'? 'customer' : 'prospect',
      //   }
      // }));
      console.error('DO PLUMBING');
      let data = [...demoData];
      commit(GET_ALL, data);
      return true;
    } catch (error) {
      commit(GET_ALL_ERROR, error);
      return false;
    }
  },
  [CLEAR_ALL]: ({ commit, }) => {
    commit(CLEAR_ALL);
  },
};
