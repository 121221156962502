<template>
  <TabsAndTagsLayout
    :title="title"
    :show-tags="false"
    :tabs="tabs"
    :tab-selected-index="activeTabIndex"
    :cta-title="ctaTitle"
    :show-cta="showCta"
    @cta-click="handleCtaClick"
    @tab-selected="handleTabSelected"
  >
    <template #content>
      <div class="bg">
        <div class="padded">
          <CustomersProspectsList
            :entity="props.entity"
            :two-way-pagination="false"
          />
          <router-view />
        </div>
      </div>
    </template>
  </TabsAndTagsLayout>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute, } from 'vue-router';
import CustomersProspectsList from '@/crm/views/CustomersProspects/CustomersProspectsList.vue';
import TabsAndTagsLayout from '@/shared/components/Layout/TabsAndTagsLayout.vue';
import { prospects } from '@/shared/store/data/policies';
import usePermissions from '@/shared/composables/usePermissions';
import { baseUrl } from '@/crm/router/urlBits';
import { pluralise, t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

const props = defineProps({
  entity: {
    type: String,
    default: '',
  },
});
const vroute = useRoute();

const isProspect = computed(() => props.entity === 'prospect');
const area = computed(() => pluralise.string(props.entity));

const { hasAccess } = usePermissions();
const showCta = computed(() => isProspect.value && hasAccess(prospects, 'create'));
const title = computed(() => (isProspect.value ? t('My prospects') : t('My customers')));
const ctaTitle = computed(() => (isProspect.value ? t('Add prospect') : ''));

const tabs = computed(() => [
  {
    title: t('All'),
    icon: 'mdi-information',
    path: `${baseUrl}/${area.value}`,
    key: 'all',
  },
]);

const status = computed(() => vroute.query.status || 'all');
const activeTabIndex = computed(() => {
  const index = tabs.value.map(t => t.key).findIndex(k => k == status.value);
  return index === -1 ? 0 : index;
});

function handleTabSelected(/*{ item, }*/) {
  // if(decodeURI(vroute.path) !== item.path) {
  //   navigateToUrl(item.path);
  // }
}
function handleCtaClick() {
  navigateToUrl(`${baseUrl}/${area.value}/add`);
}
</script>

<style lang="scss" scoped>
.bg {
  background-color: var(--colour-panel-g-2);
}
</style>
