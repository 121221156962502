import { menuMutations, menuActions } from './menu';
import { productsMutations, productsActions } from './products';
import { blacklistMutators, blacklistActions } from './blacklist';
import { industryTypeMutations, industryTypeActions } from './industryTypes';
import { productGroupsMutations, productGroupsActions } from './productGroups';

// initial state
const state = () => ({
  menu: {
    data: [],
    loaded: false,
    loading: false,
    errors: undefined,
  },
  products: {
    data: [],
    loaded: false,
    loading: false,
    errors: undefined,
  },
  blacklist: {
    loading: false,
    loaded: false,
    data: [],
    errors: undefined,
  },
  industryTypes: {
    data: {},
    loaded: false,
    loading: false,
    errors: undefined,
  },
  crmProducts: {
    data: [],
    loading: true,
    loaded: false,
    errors: [],
  },
  productGroups: {
    data: [],
  },
});

// getters
const getters = {
};

// mutations
const mutations = {
  ...menuMutations,
  ...productsMutations,
  ...blacklistMutators,
  ...industryTypeMutations,
  ...productGroupsMutations,
};

// actions
const actions = {
  ...menuActions,
  ...productsActions,
  ...blacklistActions,
  ...industryTypeActions,
  ...productGroupsActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
