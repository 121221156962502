import {
  GET_PLATFORM,
  SET_PLATFORM
} from '@/shared/store/actionType';

// initial state
const state = () => ({
  data: '',
});

// getters
const getters = {
};

// mutations
const mutations = {
  [SET_PLATFORM]: (state, data) => {
    state.data = data;
  },
};

// actions
const actions = {
  [GET_PLATFORM] ({ commit, }, platform) {
    commit(SET_PLATFORM, platform);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
