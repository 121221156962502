<template>
  <div class="filter">
    <div
      v-if="filter.type === 'input'"
      class="range-filter"
    >
      <label for="input">
        <span>{{ filter.label }}</span>
        <input
          type="number"
          name="input"
          min="0"
          :value="currentFilterValue"
          @change="applyFilter($event.target.value, filter.queryParamKey)"
        >
      </label>
    </div>
    <MenuFilter
      v-else
      :items="filter.values"
      :selected-value="currentFilterValue"
      menu-position="right"
      @on-change="applyFilter($event.value, filter.queryParamKey)"
    />
  </div>
</template>

<script>
import { MenuFilter } from '@sales-i/dsv3';
import { mapActions } from 'vuex';
import { APPLY_NEW_FILTER, REFRESH_REPORT } from '@/intelligence/store/actionType';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'TemplateReportFilter',
  components: { MenuFilter },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    currentFilterValue() {
      const param = this.$route.query[this.filter.queryParamKey] || this.filter.default;
      return this.filter.isNumber ? parseInt(param) : param;
    },
  },
  methods: {
    ...mapActions({
      applyNewFilter: `intelligence/templateReport/${APPLY_NEW_FILTER}`,
      refreshReport: `intelligence/shared/${REFRESH_REPORT}`,
    }),
    async applyFilter(newFilter, queryParamKey) {
      if (newFilter === this.currentFilterValue) return;

      const route = await this.$router.resolve({ query: { ...this.$route.query, [queryParamKey]: newFilter } });
      navigateToUrl(route.href);
      this.applyNewFilter([queryParamKey, newFilter]);
      this.refreshReport();
    },
  },
};
</script>

<style lang="scss" scoped>
.header-section > .filters > .filter {
  margin-left: var(--spacing-2);
  width: fit-content;
}

.range-filter {
  width: 100%;
  display: flex;
  flex-direction: row;

  &.ytd {
    display: none;
  }
}

label {
  padding: var(--spacing-1) 0;

  &:first-of-type {
    padding-left: 0;
    padding-right: var(--spacing-half);
  }
  &:nth-of-type(2) {
    padding-left: var(--spacing-half);
    padding-right: 0;
  }
}

span {
  color: var(--colour-utility-black);
  font-size: var(--font-size-5);
  letter-spacing: 0;
}

input[type='number'] {
  margin-top: var(--spacing-half);
  padding: var(--spacing-1) var(--spacing-half) var(--spacing-1) var(--spacing-1);
  color: var(--colour-utility-black);
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-regular);
  letter-spacing: 0;

  &:disabled {
    background-color: var(--colour-panel-g-8);
    color: var(--colour-panel-g-48);
    cursor: not-allowed;
  }
}
</style>
