<template>
  <div>
    <EnquiryNav
      :active-area-prop="navigationData.area"
      :report-type="navigationData.urlCode"
      :dates="dateParams"
      :is-date-filter-active="isDateFilterActive"
      hide-drill
      hide-filters
      is-snapshot
      @toggle-date-filter="toggleDateFilter"
    />
    <DatePickerView
      v-if="isDateFilterActive"
      :key="reportKey"
      class="container"
      :report-type="reportTypeRef"
      @set-active-date="setActiveDate"
    />
    <TwoColumnLayout
      v-show="!isDateFilterActive"
      :title="navigationData.area"
      :description="navigationData.description"
    >
      <template #beforeHeader>
        <MenuFilter
          class="customers-options"
          :items="customersOptions"
          :selected-value="selectedCustomersOption"
          menu-position="right"
          :disabled="!customerList.length"
          @on-change="switchCustomersOptions"
        />
        <SnapshotSearch
          v-if="selectedCustomersOption === 'all'"
          :selected-value="selectedCustomerComputed"
          :predefined-query="predefinedSearchQuery"
          is-customer
          @item-click="changeCustomer"
        />
        <div
          v-if="selectedCustomersOption === 'today' && isSnapshotVisible"
          class="today-container"
        >
          <EditNote
            :interaction-note="customerNote"
            :interaction-id="customerObject.interactionId"
            @update-note="updateNote"
          />
          <CustomerFilter
            :customer-list="customerList"
            @change-customer="changeCustomer"
          />
        </div>
      </template>
      <template #sidebar>
        <div
          ref="sidebar"
          class="sidebar-menu"
        >
          <CustomSelect
            :label="t('Snapshot page')"
            class="snapshot-select"
            :placeholder="t('Select')"
            icon-align="right"
            :value="reportTypeRef"
            :items="snapshotList"
            @on-change="goToReport"
          />
          <ExportModal
            v-if="isSnapshotVisible && customerObject.id"
            :title="t('Export Snapshot')"
            :customer-object="customerObject"
            :date-params="fetchedDates"
            :export-component-key="exportComponentKey"
            :is-snap-shot="isSnapshotVisible"
          />
        </div>
      </template>
      <template #content>
        <CustomButton
          purpose="transparent"
          class="customer-title"
          @click="goToCustomer"
        >
          <h2>
            {{ customerObject.name }}
          </h2>
        </CustomButton>
        <router-view
          v-if="isSnapshotVisible && customerObject.id"
          :key="reportKey"
          :report-type="reportTypeRef"
          :customer-object="customerObject"
          snapshot-type="customer"
        />
        <BufferImage
          v-else
          color="black"
          float="center"
          class="loading-spinner"
        />
      </template>
    </TwoColumnLayout>
  </div>
</template>

<script setup>
import { ref, computed, watch, onBeforeUnmount, onMounted, onBeforeMount, } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { BufferImage, CustomSelect, MenuFilter, CustomButton } from '@sales-i/dsv3';
import {
  CUSTOMER_SNAPSHOT,
  PRODUCT_GROUP_PURCHASE_ANALYSIS_PIECHART,
  SNAPSHOT_REPORT_LIST,
  SNAPSHOT_SUMMARY,
  SNAPSHOT_SUMMARY_TITLE,
} from '@/intelligence/store/data/reportTypes';
import { GET_SEARCH_BAR_RESULTS } from '@/shared/store/actionType';
import { APPLY_DATE_RANGE, APPLY_NEW_FILTER, APPLY_OFFSET, FETCH_DATES, REFRESH_REPORT, SET_LOADING } from '@/intelligence/store/actionType';
import TwoColumnLayout from '@/shared/components/Layout/TwoColumnLayout';
import CustomerFilter from '@/intelligence/components/SnapshotReport/CustomerFilter';
import SnapshotSearch from '@/intelligence/components/SnapshotReport/SnapshotSearch';
import EditNote from '@/intelligence/components/SnapshotReport/EditNote';
import DatePickerView from '@/intelligence/components/Date/DatePickerView';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import { inputSets, snapshotReportSet } from '@/intelligence/store/data/reportsInput';
import { dates, t } from '@sales-i/utils';
import useTodaysInteractions from '@/shared/composables/useTodaysInteractions';
import useReportPolicies from '@/shared/composables/useReportsPolicies';
import isRolldate from '@/intelligence/store/utils/isRolldate';
import ExportModal from '@/intelligence/components/Shared/ExportModal.vue';
import usePermissions from '@/shared/composables/usePermissions';
import { interactions } from '@/shared/store/data/policies';
import { baseUrl as crmBaseUrl, customersArea } from '@/crm/router/urlBits';
import { navigateToUrl } from 'single-spa';
import { useMq } from 'vue3-mq';
import { baseUrl as intelligenceBaseUrl, customerScope, insightsScope, snapshotReportScope } from '@/intelligence/router/urlBits';

const store = useStore();
const { customerList, clearReportData, fetchTodaysInteractions } = useTodaysInteractions();

const { hasAccess } = usePermissions();
const reportsPolicies = useReportPolicies();
const mq = useMq();
const vroute = useRoute();
const vrouter = useRouter();

const isDateFilterActive = ref(false);
const isSnapshotVisible = ref(false);
const reportTypeRef = ref(SNAPSHOT_SUMMARY);
const customersOptions = ref([
  { text: t('Today’s Customers'), value: 'today' },
  { text: t('All Customers'), value: 'all' },
]);
const selectedCustomersOption = ref('today');
const customerObject = ref({});
const customerNote = ref('');
const predefinedSearchQuery = ref('');
const dateParams = ref({});
const exportComponentKey = ref(1);
const sidebar = ref(null);


const reportKey = computed(() => store.state.intelligence.shared.reportRefreshCounter);
const fetchedDates = computed(() => store.state.intelligence.calendar.fetchedDates);
const selectedCustomerComputed = computed(() => store.state.intelligence.shared.requestParameters.snapshotBubbleValue);
const searchBarResultsInStore = computed(() => store.state.searchBarResults.data);

const canAccessInteractions = computed(() => hasAccess(interactions));
const navigationData = computed(() => inputSets[CUSTOMER_SNAPSHOT]);
const snapshotList = computed(() => {
  return SNAPSHOT_REPORT_LIST.reduce((result, report) => {
    if (report === PRODUCT_GROUP_PURCHASE_ANALYSIS_PIECHART) return result;
    const text = getSnapshotTitle(report);
    const policy = getSnapshotPolicy(report);

    if (reportsPolicies.permissions.some(permission => permission[policy] === true || policy === undefined)) {
      result.push({
        value: report,
        text,
        policy,
      });
    }

    return result;
  }, []);
});

watch(() => vroute.path, () => {
  const { name } = vroute;
  reportTypeRef.value = name;
  routeToDefaultPath();
});

watch(() => selectedCustomerComputed.value, () => {
  replaceRoute();
});

watch(() => dateParams.value, () => {
  replaceRoute();
});

onBeforeMount(async() => {
  const dateFromQuery = vroute.query;
  if (dateFromQuery.date_from && dateFromQuery.date_to) {
    dateParams.value = {
      date_from: dateFromQuery.date_from,
      date_to: dateFromQuery.date_to
    };
  }
});

onMounted(async() => {
  routeToDefaultPath();
  const { query, name } = vroute;
  window.scrollTo(0, 0);
  if (canAccessInteractions.value) {
    await fetchTodaysInteractions();
  }
  reportTypeRef.value = name;

  const selectedCustomer = query.customer || selectedCustomerComputed.value;

  if (customerList.value.length && isCustomerOnTheList(selectedCustomer)) {
    changeCustomer(selectedCustomer);
  } else if (customerList.value.length && !selectedCustomer) {
    changeCustomer(customerList.value[0].name);
  } else if (!isCustomerOnTheList(selectedCustomer)) {
    await getSearchBarResults({
      url: 'customers',
      type: 'customer',
      parameters: {
        q: selectedCustomer,
        limit: 1,
        offset: 0,
      },
      moreResults: false,
    });
    changeCustomer(searchBarResultsInStore.value[0]);
    predefinedSearchQuery.value = customerObject.value.name;
    selectedCustomersOption.value = 'all';
  }
  if (!Object.values(dateParams.value).length) {
    await processDates({
      date_from: 'today/y',
      date_to: 'today',
    });
  } else {
    await processDates(dateParams.value);
  }
  isSnapshotVisible.value = true;
});

onBeforeUnmount(() => {
  applyRequestParam(['snapshotBubbleValue', '']);
  applyRequestParam(['snapshotBubbleValueId', '']);
  clearReportData();
});

const replaceRoute = () => {
  const route = vrouter.resolve({ query: {
    customer: selectedCustomerComputed.value,
    date_from: dateParams.value.date_from,
    date_to: dateParams.value.date_to
  }});
  history.replaceState({}, '', route.href);
};

const refreshReport = params => store.dispatch(`intelligence/shared/${REFRESH_REPORT}`, params);
const applyDateRanges = params => store.dispatch(`intelligence/shared/${APPLY_DATE_RANGE}`, params);
const applyRequestParam = params => store.dispatch(`intelligence/templateReport/${APPLY_NEW_FILTER}`, params);
const fetchDates = params => store.dispatch(`intelligence/calendar/${FETCH_DATES}`, params);
const setLoading = params => store.dispatch(`intelligence/shared/${SET_LOADING}`, params);
const getSearchBarResults = params => store.dispatch(`searchBarResults/${GET_SEARCH_BAR_RESULTS}`, params);
const applyOffset = params => store.dispatch(`intelligence/shared/${APPLY_OFFSET}`, params);

const routeToDefaultPath = () => {
  const { path } = vroute;
  if (path === `${intelligenceBaseUrl}/${insightsScope}/${customerScope}/${snapshotReportScope}`) {
    goToReport(SNAPSHOT_SUMMARY);
  }
};

const goToReport = async(reportType) => {
  isSnapshotVisible.value = false;
  applyOffset(0);
  setLoading(true);
  reportTypeRef.value = reportType;
  const route = await vrouter.resolve({
    name: reportType,
    query: {
      customer: selectedCustomerComputed.value,
      date_from: dateParams.value.date_from,
      date_to: dateParams.value.date_to
    }
  });
  navigateToUrl(route.href);
  refreshReport();
  isSnapshotVisible.value = true;
};

const getSnapshotTitle = (reportType) => {
  if (reportType === SNAPSHOT_SUMMARY) return SNAPSHOT_SUMMARY_TITLE;
  return snapshotReportSet[reportType].title;
};
const getSnapshotPolicy = (reportType) => {
  if (reportType === SNAPSHOT_SUMMARY) return undefined;
  return snapshotReportSet[reportType].policy;
};
const setActiveDate = async(dateObject) => {
  applyOffset(0);
  setLoading(true);

  await processDates(dateObject);

  if (['xs', 'sm'].includes(mq.current)) {
    window.scrollTo(0, sidebar.value.getBoundingClientRect().bottom - 65);
  }

  refreshReport();
  isDateFilterActive.value = false;
};
const processDates = async(dateObject) => {
  if (isRolldate(dateObject)) {
    dateObject.date_from2 = `${dateObject.date_from}-1y`;
    dateObject.date_to2 = `${dateObject.date_to}-1y`;
    await fetchDates(dateObject);
    // console.log('fetchedDates', fetchedDates.value);
    // dateParams.value = fetchedDates.value;
  } // else {
  dateParams.value = dateObject;
  // }

  applyDateRanges(fetchedDates.value);
};
const changeCustomer = (newCustomer) => {
  const customerName = newCustomer.name || newCustomer;
  if (customerName === customerObject.value.name) return;

  if (isCustomerOnTheList(customerName)) {
    customerObject.value = customerList.value.find(cust => cust.name === customerName);
    selectedCustomersOption.value = 'today';
  } else {
    customerObject.value = {
      name: customerName,
      value: newCustomer.name.replace(/\s/g, ''),
      id: newCustomer.id,
      account_number: newCustomer.account_number,
      updatedDate: dates.format(newCustomer.updated_date),
    };
  }
  customerNote.value = customerObject.value.note;
  applyRequestParam(['snapshotBubbleValue', customerObject.value.name]);
  applyRequestParam(['snapshotBubbleValueId', customerObject.value.account_number]);
  refreshReport();
  exportComponentKey.value++;
};
const switchCustomersOptions = async(option) => {
  if (option.value === 'today') {
    // If the user doesn't have access to interactions, don't fetch them
    if (canAccessInteractions.value) {
      await fetchTodaysInteractions();
    }
    changeCustomer(customerList.value[0].name);
  }
  selectedCustomersOption.value = option.value;
};
const isCustomerOnTheList = (customerName) => {
  return customerName && customerList.value.some(cust => cust.name === customerName);
};
const toggleDateFilter = () => {
  isDateFilterActive.value = !isDateFilterActive.value;
};
const updateNote = (note) => {
  const currentCustomer = customerList.value.find(cust => cust.name === customerObject.value.name);
  customerNote.value = currentCustomer.note = note;
  customerObject.value = currentCustomer;
};
const goToCustomer = () => {
  window.open(`${crmBaseUrl}/${customersArea}/${customerObject.value.id}`, '_blank');
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.demo-export-btn {
  display: flex;
  width: 100px;
  margin-top: var(--spacing-4);
  background-color: var(--colour-utility-action);
  color: var(--colour-utility-white);
  height: 40px;
  border-radius: var(--border-radius-half);
  text-decoration: none;
  justify-content: center;
  align-items: center;
}

.customers-options {
  margin-bottom: var(--spacing-1);
}

:deep(.sidebar-before-header) {
  display: flex;
  flex-direction: column;
}

:deep(.area.customer .container) {
  padding-bottom: 100px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-bottom: var(--spacing-2);
  }
}

:deep(.date-filter.container) {
  margin-bottom: 88px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-bottom: var(--spacing-3);
  }
}

.container {
  margin-top: var(--spacing-3);
  margin-bottom: var(--spacing-3);
}

.sidebar-menu {
  .snapshot-select {
    margin-bottom: var(--spacing-2);
  }
}

.today-container {
  display: inline-flex;

  .edit-note-container {
    margin-right: var(--spacing-1);
    display: flex;
  }
}

.customer-title {
  text-align: center;
  text-decoration: underline;
  margin: 0 auto var(--spacing-3);
}

:deep(.menu-filter .filter-select__selection) {
  white-space: initial;
}
</style>
