<template>
  <ContactCard
    :contact="contact"
    loaded
    :loading="false"
    :show-actions="!!entityId"
    :entity-type="entityType"
    @add="addContact"
    @update="updateContact"
  />
</template>

<script>
import ContactCard from '@/crm/components/Contacts/ContactCard.vue';
import { mapState, mapActions } from 'vuex';
import { CLEAR_BY_ID, GET_BY_ID } from '@/shared/store/actionType';
import { baseUrl, contactsSection } from '@/crm/router/urlBits.js';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'ContactComponent',
  components: {
    ContactCard,
  },
  props: {
    entityName: {
      type: String,
      default: '',
    },
    entityType: {
      type: [String, Number],
      default: 'customer',
    },
    entityId: {
      type: [String, Number],
      default: '',
    },
    contactId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      options: { throwErrors: false },
    };
  },
  computed: {
    ...mapState({
      contact: state => state.crm.contacts.selected.data,
      loaded: state => state.crm.contacts.selected.loaded,
      loading: state => state.crm.contacts.selected.loading,
      platform: state => state.platform,
    }),
    entity() {
      return this.entityType === 'customer' ? 'customers' : 'prospects';
    },
  },
  watch: {
    contactId() {
      this.fetch({
        id: this.contactId,
        options: this.options,
      });
    },
  },
  mounted() {
    this.fetch({ id: this.contactId, options: this.options });
  },
  unmounted() {
    this.clear();
  },
  methods: {
    ...mapActions({
      fetch: `crm/contacts/${GET_BY_ID}`,
      clear: `crm/contacts/${CLEAR_BY_ID}`,
    }),
    addContact() {
      if (!this.entityId) return;
      navigateToUrl(`${baseUrl}/${this.entity}/${this.entityId}/${contactsSection}/add`);
    },
    updateContact() {
      if (!this.entityId) return;
      navigateToUrl(`${baseUrl}/${this.entity}/${this.entityId}/${contactsSection}/${this.contactId}/edit`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
