import {
  GET_CUSTOM_SCHEMA,
  LOADING_CUSTOM_SCHEMA,
  UPDATE_CUSTOM_SCHEMA,
  ADD_FIELD,
  MOVE_FIELD,
  SET_REQUIRED,
  SET_INCLUDE_IN_ENQUIRY,
  DELETE_FIELD,
  DELETE_LIST_OPTION,
} from '@/admin/store/actionType';
import { httpRequest } from '@sales-i/utils';
import getSchemaId from '@/admin/store/utils/getSchemaId';


const updateSectionAndFields = (state, section_id, updateFunc) => {
  const currentSection = state.schema.sections.find(section => section.id === section_id);
  let sectionToUpdate = { ...currentSection };
  let fields = [];

  sectionToUpdate.fields.forEach(field => {
    let newField = {
      ...field,
    };
    updateFunc(field, newField);
    if (typeof field.id === 'string') {
      newField.id = getSchemaId(field.id);
      newField.type = newField.type.toUpperCase();
    }
    fields.push(newField);
  });
  sectionToUpdate.fields = fields;
  return sectionToUpdate;
};

export const fieldsGetters = {
};

export const fieldsMutations = {

  [ADD_FIELD]: () => {
  },
  [SET_REQUIRED]: () => {
  },
  [SET_INCLUDE_IN_ENQUIRY]: () => {
  },
  [MOVE_FIELD]: () => {
  },
  [DELETE_FIELD]: () => {
  },
  [DELETE_LIST_OPTION]: () => {
  }
};

export const fieldsActions = {
  /**
    FIELD TYPE
    checkbox -> checkbox
    currency -> number
    date -> date
    list -> select?
    long text -> textarea
    number -> number
    text -> text
    time -> date
    datetime -> date
    hyperlink -> ??
  **/

  [ADD_FIELD]: ({ state, commit, rootState, dispatch }, fieldDetails) => {
    commit(LOADING_CUSTOM_SCHEMA, true);
    const { admin: { fieldTypes: { data: fieldTypes }}} = rootState;
    const { section_id, entity, index } = fieldDetails;
    const type = fieldDetails.type.replace(/\s/ig, '');
    const size = fieldTypes.find(field => field.id === type).max_size;
    const currentSection = state.schema.sections.find(section => section.id === section_id);
    const sectionFields = currentSection.fields;
    let newOrderPos = 1;
    if (!index) {
      sectionFields.forEach(field => {
        if (field.order_pos > newOrderPos) {
          newOrderPos = field.order_pos;
        }
      });
    } else {
      newOrderPos = index;
    }
    newOrderPos++;
    let format = '';
    switch(type) {
    case 'CHECKBOX':
      format = 'checkbox';
      break;
    case 'CURRENCY':
      format = 'number';
      break;
    case 'DATE':
      format = 'date';
      break;
    case 'LIST':
      format = 'select';
      break;
    case 'TEXTLONG':
      format = 'textarea';
      break;
    case 'NUMBER':
      format = 'number';
      break;
    case 'TEXT':
      format = 'text';
      break;
    case 'TIME':
      format = 'date';
      break;
    case 'DATETIME':
      format = 'date';
      break;
    case 'HYPERLINK':
      format = 'text';
      break;
    }
    const field = {
      name: fieldDetails.name,
      type: type,
      format: format,
      required: false,
      editable: true,
      list_options: [],
      vals_editable: true,
      system_flag: 'custom',
      order_pos: newOrderPos,
      deletable: true,
      enquiry_excluded: true,
      size,
    };
    const updateFunc = (field, newField) => {
      if (index) {
        if (newField.order_pos >= newOrderPos) {
          newField.order_pos++;
        }
      }
    };
    let sectionToUpdate = updateSectionAndFields(state, section_id, updateFunc);
    sectionToUpdate.fields.push(field);
    commit(ADD_FIELD);
    dispatch(UPDATE_CUSTOM_SCHEMA, {
      entity,
      payload: [sectionToUpdate],
      immediateRequest: true
    });
  },
  [SET_REQUIRED]: ({ commit, state, dispatch }, { entity, section_id, item }) => {
    if (!item) return;

    const updateFunc = (field, newField) => {
      let itemId = getSchemaId(item.id);
      if (field.id === itemId) {
        newField.required = item.required;
      }
    };

    const sectionToUpdate = updateSectionAndFields(state, section_id, updateFunc);
    commit(SET_REQUIRED);
    dispatch(UPDATE_CUSTOM_SCHEMA, {
      entity,
      payload: [sectionToUpdate],
      immediateRequest: false
    });
  },
  [SET_INCLUDE_IN_ENQUIRY]: ({ commit, state, dispatch }, { entity, section_id, item }) => {
    if (!item) return;
    const updateFunc = (field, newField) => {
      let itemId = getSchemaId(item.id);
      if (field.id === itemId) {
        newField.enquiry_excluded = item.enquiry_excluded;
      }
    };

    const sectionToUpdate = updateSectionAndFields(state, section_id, updateFunc);
    commit(SET_INCLUDE_IN_ENQUIRY);
    dispatch(UPDATE_CUSTOM_SCHEMA, {
      entity,
      payload: [sectionToUpdate],
      immediateRequest: false
    });
  },
  [MOVE_FIELD]: ({ state, commit, dispatch }, {entity, section_id, from, to}) => {
    if (from === to) return;
    const currentSection = state.schema.sections.find(section => section.id === section_id);
    let sectionToUpdate = { ...currentSection };
    let fields = [];
    // this hackery is to avoid mutating state outside of mutations
    sectionToUpdate.fields.forEach(field => {
      let newField = {
        ...field,
      };
      fields.push(newField);
    });
    const movedField = fields.splice(from, 1)[0];
    fields.splice(to, 0, movedField);
    fields.forEach((field, index) => {
      field.order_pos = index + 1;
    });
    sectionToUpdate.fields = fields;
    commit(MOVE_FIELD);
    dispatch(UPDATE_CUSTOM_SCHEMA, {
      entity,
      payload: [sectionToUpdate],
      immediateRequest: true
    });
  },
  [DELETE_FIELD]: async ({ commit, dispatch }, { entity, section_id, item }) => {
    const field_id = getSchemaId(item.id);
    await httpRequest('delete', `config/schema/${entity}/sections/${section_id}/fields/${field_id}`, {}, { version: '2' },);
    commit(DELETE_FIELD);
    dispatch(GET_CUSTOM_SCHEMA, { entity, fetchOnUpdate: true, refreshCall: true, });
  },
  [DELETE_LIST_OPTION]: async ({ commit, dispatch }, { entity, list_name, option_id }) => {
    await httpRequest('delete', `config/schema/${entity}/lists/${encodeURIComponent(list_name)}/${option_id}`, {}, { version: '2' },);
    commit(DELETE_LIST_OPTION);
    dispatch(GET_CUSTOM_SCHEMA, { entity, fetchOnUpdate: true, refreshCall: true, });
  }
};
