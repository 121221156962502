<template>
  <BufferImage
    v-show="loading"
    :size="40"
    float="center"
  />
  <div
    v-if="canCreateEntities"
    class="add-btn-container"
  >
    <IconButton
      icon-name="plus"
      :label="`Add ${addType}`"
      purpose="action"
      icon-color="white"
      icon-size="32"
      variant="round"
      @on-click="createButtonClick"
    />
  </div>

  <div
    v-if="!loading"
    class="section-list"
  >
    <button
      v-for="(item, index) in items"
      :key="`area-${index}`"
      :draggable="isCustomSection"
      @drop.prevent="dragDrop(index)"
      @dragenter.prevent="dragEnter($event, index)"
      @dragover.prevent="dragOver($event, index)"
      @click.stop="itemClicked(index)"
      @dragstart="dragStart($event, item, index)"
    >
      <SectionsDrop
        v-show="toIndex >= 0 && toIndex === index && index != fromIndex && fromIndex > index"
        class="my-1"
      >
        {{ dropAreaTitle }}
      </SectionsDrop>

      <div
        :class="{
          'section-item': true,
          active: activeItem === index,
        }"
      >
        <IconBase
          v-if="isCustomSection"
          icon-name="move"
          icon-color="var(--colour-utility-action)"
          :height="16"
          :width="16"
        />
        <span>{{ item.name }}</span>
        <IconBase
          v-if="itemHasIcon(item, 'postLink')"
          :title="item.icons.postLink.content"
          :icon-name="item.icons.postLink.icon"
          :height="24"
          :width="24"
        />
        <!-- WILL BE ADDED BACK IN LATER, DO NOT DELETE -->
        <!-- <button
          v-if="isCustomSection"
          @click.stop="setVisible(item)"
        >
          <IconBase
            :icon-name="item.show ? 'show' : 'hide'"
            icon-color="var(--colour-utility-black)"
            :height="24"
            :width="24"
          />
        </button> -->
      </div>

      <SectionsDrop
        v-show="toIndex >= 0 && toIndex === index && index != fromIndex && fromIndex < index"
        class="my-1"
      >
        {{ dropAreaTitle }}
      </SectionsDrop>
    </button>
  </div>  
</template>

<script type="text/javascript">
import { BufferImage, IconBase, IconButton } from '@sales-i/dsv3';
import { mapActions } from 'vuex';
import { ADD_STAGE } from '@/admin/store/actionType';
import { SET_VISIBLE } from '@/shared/store/actionType';
import SectionsDrop from '@/admin/views/CRMSetup/SectionsDrop.vue';

export default {
  name: 'DraggableItems',
  components: {
    IconBase,
    IconButton,
    BufferImage,
    SectionsDrop
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activeItem: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isCustomSection: {
      type: Boolean,
      default: false,
    },
    canCreateEntities: {
      type: Boolean,
      default: false,
    },
    addType: {
      type: String,
      default: '',
    }
  },
  emits: ['itemClicked', 'move', 'createEntity', 'add'],
  data() {
    return {
      display: {},
      fromIndex: -1,
      toIndex: -1,
      dragging: false,
    };
  },
  computed: {
    isValid() {
      return true;
    },
    dropAreaTitle() {
      return '';
    },
  },
  methods: {
    ...mapActions({
      setVisible: `admin/schema/${SET_VISIBLE}`,
      addSection: `admin/schema/${ADD_STAGE}`,
    }),
    clear() {
      this.fromIndex = -1;
      this.toIndex = -1;
      this.dragging = false;
    },
    itemClicked(index) {
      if (!this.dragging) {
        this.$emit('itemClicked', index, true);
      }
    },
    dragStart(ev, item, index) {
      this.clear();
      this.dragging = true;
      ev.dataTransfer.dropEffect = 'move';
      ev.dataTransfer.effectAllowed = 'move';
      ev.dataTransfer.setData('index', index);
      this.fromIndex = index;
    },
    dragDrop(toIndex) {
      this.$emit('move', { from: this.fromIndex, to: toIndex });
      this.clear();
    },
    dragEnter(ev, index) {
      this.toIndex = this.fromIndex == index ? -1 : index;
    },
    async dragOver(ev, index) {
      this.$nextTick(function () {
        this.toIndex = index;
      });
    },
    dragLeave() {
      this.toIndex = -1;
    },
    add(index) {
      this.$emit('add', index);
    },
    createButtonClick() {
      if (this.canCreateEntities === false) {
        return this.addSection();
      }
      this.$emit('createEntity');
    },
    itemHasIcon(item, iconName) {
      return 'icons' in item === true && iconName in item.icons && item.icons[iconName];
    },
  },
};
</script>

<style lang="scss" scoped>
.section-list {
  display: flex;
  flex-direction: column;
}
.section-item {
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--colour-utility-action);
  text-decoration: underline;
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-1) 0;

  > span {
    line-height: 1rem;
    flex: 1;
    margin-top: var(--spacing-half);
    margin-left: var(--spacing-1);
    text-align: left;
    word-break: break-word;
  }
  &.active {
    text-decoration: none;
    color: var(--colour-utility-black);
  }

  &:first-child {
    margin-top: 0;
    margin-bottom: var(--spacing-half);
  }
}

.add-btn-container {
  display: flex;
  justify-content: flex-end;  
}

.non-custom:last-child .section-item {
  margin-bottom: var(--spacing-half);
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
