import BubbleSelection from '@/intelligence/components/Bubble/BubbleSelection.vue';
import EnquiryReportView from '@/intelligence/components/EnquiryReport/EnquiryReportView';
import { baseUrl, enquiryScope, reportScope } from '@/intelligence/router/urlBits';
import { SALES_VS_GAPS } from '@/intelligence/store/data/reportTypes';
import { t } from '@sales-i/utils';

export default [
  {
    path: `${baseUrl}/${enquiryScope}/${SALES_VS_GAPS}`,
    component: BubbleSelection,
    name: SALES_VS_GAPS,
    props: {
      heading: t('Select the Y-Axis dimensions'),
      description: t('Select up to 2 dimensions that will run in the column'),
      reportType: SALES_VS_GAPS,
    }
  },
  {
    path: `${baseUrl}/${enquiryScope}/${SALES_VS_GAPS}/${reportScope}`,
    component: EnquiryReportView,
    name: `${SALES_VS_GAPS}-${reportScope}`,
    props: { reportType: SALES_VS_GAPS }
  }
];
