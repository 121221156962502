import { getAllActions, getAllMutations, getAllGetters } from './getAll';
import { dismissActions } from './dismiss';

// initial state
const state = () => ({
  data: [],
  loaded: false,
  loading: false,
});

const getters = {
  ...getAllGetters,
};

// mutations
const mutations = {
  ...getAllMutations,
};

// actions
const actions = {
  ...getAllActions,
  ...dismissActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
