export const customers = 'customers';
export const contacts = 'contacts';
export const prospects = 'prospects';
export const opportunities = 'opportunities';
export const interactions = 'interactions';
export const tags = 'tags';
export const posts_comments = 'posts-comments';
export const files = 'files';
export const intelligence_insights = 'intelligence-insights';
export const intelligence_enquiries = 'intelligence-enquiries';
export const intelligence_crm_reports = 'intelligence-crm-reports';

export const crm_admin = 'crm-admin';
export const targets_admin = 'targets-admin';
export const tenant_admin = 'tenant-admin';
export const intelligence_admin = 'intelligence-admin';
export const user_management = 'user-management';

export const intelligence_products = 'intelligence-products';
export const product_associations = 'product-associations';
export const product_associations_admin = 'product-associations-admin';
export const product_blacklist = 'product-blacklist';
export const product_communities = 'product-communities';
export const product_communities_admin = 'product-communities-admin';
export const missed_potential_opportunities = 'missed-potential-opportunities';

export const iam_update = 'iam-update';
export const alert_builder = 'alert-builder';


// import {
//   product_associations_admin,
//   product_blacklist,
//   product_communities_admin,
//   missed_potential_opportunities,
//   alert_builder,

//   contacts,
//   customers,
//   crm_admin,
//   interactions,
//   opportunities,
//   posts_comments,
//   prospects,
//   tags,
//   files,

//   tenant_admin,
//   intelligence_admin,
//   intelligence_crm_reports,
//   intelligence_enquiries,
//   intelligence_insights,

//   languages,
//   user_management,

// } from '@/shared/store/data/policies';