<template>
  <div :class="['carousel-slide', { active: isActive }]">
    <slot />
  </div>
</template>

<script setup>

defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss">
@import '@/shared/assets/scss/_variables';

.carousel-slide {
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: all 0.5s ease 0s;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;

  &.active {
    opacity: 1;
    z-index: 1;
  }
}
</style>
