<template>
  <div class="flow">
    <p class="filter-content">
      <component
        :is="props.isFilterType ? 'a' : 'span'"
        :href="props.isFilterType ? filterLink : null"
        :target="props.isFilterType && vroute.path.includes('customers') ? '_blank' : null"
        @click.prevent="filterUrl"
      >
        <slot />
      </component>
    </p>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { navigateToUrl } from 'single-spa';

const vroute = useRoute();

const props = defineProps({
  isFilterType: {
    type: String,
    default: null,
  },
  filterLink: {
    type: String,
    default: null,
  },
});

function filterUrl() {
  if (props.isFilterType && !vroute.path.includes('customers')) {
    navigateToUrl(props.filterLink);
  }
}
</script>

<style lang="scss" scoped>
  .filter-content {
    font-weight: var(--font-weight-semibold);
  }
</style>