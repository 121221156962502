import {
  GET_HISTORY_BY_ID,
  GET_HISTORY_BY_ID_LOADING,
  GET_HISTORY_BY_ID_ERROR,
  CLEAR_HISTORY_BY_ID,
} from '@/crm/store/actionType';

import { httpRequest } from '@sales-i/utils';

// mutations
export const getHistoryByIdMutations = {
  [GET_HISTORY_BY_ID]: (state, data) => {
    state.selectedHistory.data = data;
    state.selectedHistory.loaded = true;
    state.selectedHistory.loading = false;
    state.selectedHistory.error = undefined;
    state.selectedHistory.updated = false;
  },
  [GET_HISTORY_BY_ID_LOADING]: (state) => {
    state.selectedHistory.loaded = false;
    state.selectedHistory.loading = true;
  },
  [GET_HISTORY_BY_ID_ERROR]: (state, error) => {
    state.selectedHistory.loaded = false;
    state.selectedHistory.loading = false;
    state.selectedHistory.error = error;
  },
  [CLEAR_HISTORY_BY_ID]: (state) => {
    state.selectedHistory.data = [];
    state.selectedHistory.loaded = false;
    state.selectedHistory.loading = false;
  },
};

// actions
export const getHistoryByIdActions = {
  [GET_HISTORY_BY_ID]: async ({ commit }, parameters) => {
    //debugger // eslint-disable-line no-debugger

    try {
      const { id, options = {version: '2', throwErrors: false} } = parameters;
      commit(CLEAR_HISTORY_BY_ID);
      commit(GET_HISTORY_BY_ID_LOADING);
      let data = await httpRequest('get', `opportunities/${id}/history`, {}, options);
      commit(GET_HISTORY_BY_ID, data);
      return data;
    } catch (error) {
      console.error(error);
      commit(GET_HISTORY_BY_ID_ERROR, error);
      return false;
    }
  },
  [CLEAR_HISTORY_BY_ID]: ({ commit, }) => {
    commit(CLEAR_HISTORY_BY_ID);
  },
};
