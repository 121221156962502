import {
  GET_ALL_BLACKLIST,
  GET_ALL_BLACKLIST_LOADING,
  ADD_TO_BLACKLIST,
  REMOVE_FROM_BLACKLIST,
} from '@/admin/store/actionType';
import { httpRequest } from '@sales-i/utils';

export const blacklistMutators = {
  [GET_ALL_BLACKLIST_LOADING]: (state) => {
    state.blacklist.loading = true;
  },
  [GET_ALL_BLACKLIST]: (state, data) => {
    state.blacklist.loading = false;
    state.blacklist.loaded = true;
    state.blacklist.data = data;
  },
};

export const blacklistActions = {
  [GET_ALL_BLACKLIST]: async ({ commit }) => {
    commit(GET_ALL_BLACKLIST_LOADING);

    const response = await httpRequest('get', 'blacklists?blacklisted=true', {}, {
      version: '2',
      area: 'associations',
    });

    commit(GET_ALL_BLACKLIST, response);
  },
  /* eslint-disable no-unused-vars */
  [ADD_TO_BLACKLIST]: async ({commit}, payload) => {
    const response = await httpRequest('post', 'blacklists', {
      product_code: payload.product_code,
      product_name: payload.product_name,
      is_black_listed: true,
    }, {
      version: '2',
      area: 'associations',
    });

    return response;
  },
  [REMOVE_FROM_BLACKLIST]: async ({commit}, product_code) => {
    const response = await httpRequest('delete', `blacklists/${product_code}`, {
      product_code,
    }, {
      version: '2',
      area: 'associations',
    });
  },
};
