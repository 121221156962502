<template>
  <TabsAndTagsLayout
    entity="interaction"
    :entity-id="item.id"
    :title="interactionTitle"
    :tabs="navigation"
    :tab-selected-index="selectedIndex"
    :cta-title="t('Complete interaction')"
    :show-cta="showCta"
    @cta-click="onCompleteClick"
    @tab-selected="navClicked"
  >
    <template #before>
      <InteractionDetailsInfo
        v-if="!!showInfo"
        @close-modal="showInfo = false"
      />
    </template>
    <template #subtitle>
      <div
        v-if="item.time !== 'NaN:NaN'"
        class="subtitle"
      >
        <h3>{{ interactionSubtitle }}</h3>
        <CustomButton
          small
          purpose="text"
          class="m-1 link-button"
          @click="showInfo = true"
        >
          {{ t('More info') }}
        </CustomButton>
      </div>
    </template>
    <template #content>
      <router-view
        :id="item.id"
        :key="item.id"
        @refresh="initialLoad"
      />
      <Teleport to="#modal-teleport-target">
        <CompleteInteractionModal
          v-if="isCompleteModal"
          @close-modal="onCloseCompleteModal"
          @completed="onComplete"
        />
      </Teleport>
    </template>
  </TabsAndTagsLayout>
</template>

<script setup>
// Interaction
import { ref, computed, watch, onMounted } from 'vue';
import TabsAndTagsLayout from '@/shared/components/Layout/TabsAndTagsLayout.vue';
import InteractionDetailsInfo from '@/crm/views/Interactions/InteractionDetailsInfo.vue';
import CompleteInteractionModal from '@/crm/views/Interactions/CompleteInteractionModal.vue';
import { CustomButton } from '@sales-i/dsv3';
import { t } from '@sales-i/utils';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';
import { useInteraction } from '@/crm/composables/useInteraction';
import { navigateToUrl } from 'single-spa';

const store = useStore();
const vroute = useRoute();

const { clearInteractionsById, getInteraction, setDate, setRelatedInteraction, selected, permissions, postInteraction, updateRecentlyViewedInteraction } = useInteraction(
  {
    store,
    vroute,
  }
);

const showInfo = ref(false);
const selectedIndex = ref(0);
const isCompleteModal = ref(false);

const item = computed(() => store.getters['crm/interactions/item']);
const navigation = computed(() => [
  {
    title: t('Details'),
    path: `${baseUrl}/${interactionsArea}/${item.value.id}`,
    key: 'details',
  },
  {
    title: t('History'),
    path: `${baseUrl}/${interactionsArea}/${item.value.id}/history`,
    key: 'history',
  },
  {
    title: t('Posts'),
    path: `${baseUrl}/${interactionsArea}/${item.value.id}/posts`,
    key: 'posts',
  },
]);

// Set the main title to interaction purpose if it exists, otherwise show the interaction type
const interactionTitle = computed(() => {
  const { purpose, type } = item.value;
  return purpose ? purpose : type;
});

// Show the interaction type as the subtitle if it exists, otherwise just show the date and time
const interactionSubtitle = computed(() => {
  const { purpose, type, date, time } = item.value;
  return purpose ? `${type} - ${date} ${time}` : `${date} ${time}`;
});

const id = computed(() => vroute.params.id);
const showCta = computed(() => permissions.update && selected.value.loaded && !selected.value.loading && !selected.value.data.outcome_id);

watch(
  () => ({ path: vroute.path, id: id.value }),
  (to, from) => {
    setSelectedIndex(to.path);
    if (to.id && to.path.includes(interactionsArea) && to.id !== from.id) {
      initialLoad();
    }
  }
);

onMounted(() => {
  setSelectedIndex(vroute.path);
  clearInteractionsById();
  updateRecentlyViewedInteraction(id.value);
  initialLoad();
});

async function initialLoad() {
  await getInteraction({ id: id.value });
}

function onCloseCompleteModal() {
  isCompleteModal.value = false;
}

function onCompleteClick() {
  isCompleteModal.value = true;
}

async function onComplete(timescale) {
  // if timescale for follow-up selected, adjust currently editing for new follow-up
  if (timescale.date && id.value) {
    await setDate(timescale);
    await setRelatedInteraction(id.value);
    await postInteraction();
    const { data } = selected.value;
    navigateToUrl(`${baseUrl}/${interactionsArea}/${data.id}`);
  } else {
    navigateToUrl(`${baseUrl}/${interactionsArea}`);
  }
}

function navClicked(data) {
  const { index, item } = data;
  selectedIndex.value = index;
  if (decodeURI(vroute.path) !== item.path) {
    navigateToUrl(item.path);
  }
}

function setSelectedIndex(path) {
  selectedIndex.value = Math.max(
    0,
    navigation.value.findIndex(e => {
      const keys = path.split('/');
      return keys.includes(e.key);
    })
  );
}
</script>

<style lang="scss" scoped>
.subtitle {
  color: var(--colour-utility-base);
  margin-top: var(--spacing-1);

  h3,
  button {
    display: inline-block;
  }

  h3 {
    font-weight: var(--font-weight-regular);
  }
}
</style>
