<template>
  <div class="trends-report">
    <BufferImage
      v-if="!isReportEnabled"
      color="black"
      float="center"
      class="loading-spinner"
    />
    <div v-if="isReportDataAvailable && isReportEnabled">
      <section class="chart-section">
        <TrendsChart
          v-if="isReportEnabled"
          :report-id="reportId"
        />
      </section>
      <section class="data-section">
        <TrendsTable
          v-if="isReportEnabled"
          :report-id="reportId"
        />
      </section>
      <ReportFooter
        v-if="!hideFooter"
        ref="footerSection"
        :report-type="reportType"
        :request-params="requestParams"
      />
    </div>
    <NoResults v-if="!isReportDataAvailable && isReportEnabled" />
  </div>
</template>

<script>
import { BufferImage } from '@sales-i/dsv3';
import TrendsChart from '@/intelligence/components/Trends/TrendsChart';
import TrendsTable from '@/intelligence/components/Trends/TrendsTable';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';
import NoResults from '@/intelligence/components/Shared/NoResults';
import { mapActions, mapGetters, mapState } from 'vuex';
import { FETCH_REPORT_DATA } from '@/intelligence/store/actionType';
import { TRENDS } from '@/intelligence/store/data/reportTypes';
import { intelligence_enquiries } from '@/shared/store/data/policies';
import runIfPermittedOrReject from '@/shared/store/utils/runIfPermittedOrReject';

export default {
  components: {
    ReportFooter,
    BufferImage,
    TrendsChart,
    TrendsTable,
    NoResults,
  },
  props: {
    hideFooter: {
      type: Boolean,
      default: false,
    },
    requestParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      footerHeight: 0,
      headerHeight: 0,
      reportType: TRENDS,
      isReportEnabled: false,
      reportId: 1,
    };
  },
  computed: {
    ...mapState({
      permissions: state => state.pbac.permissionsGranted,
      reportKey: state => state.intelligence.shared.reportRefreshCounter,
    }),
    ...mapGetters({
      getReportData: 'intelligence/shared/getReportData',
    }),
    reportData() {
      return this.getReportData(this.reportId);
    },
    isReportDataAvailable() {
      return (
        this.reportData &&
        this.reportData.rows &&
        this.reportData.rows.some(row => row.value_1 !== 0 || row.value_2 !== 0 || row.value_variance !== 0)
      );
    },
  },
  watch: {
    reportKey() {
      this.generateNewReport();
    },
  },
  async mounted() {
    await runIfPermittedOrReject(this.permissions, { name: intelligence_enquiries }, async () => {
      await this.generateNewReport();
      if (this.$refs.footerSection) this.footerHeight = this.$refs.footerSection.$el.clientHeight;
      if (this.$refs.headerSection) this.headerHeight = this.$refs.headerSection.clientHeight;
    });
  },
  methods: {
    ...mapActions({
      fetchReportData: `intelligence/shared/${FETCH_REPORT_DATA}`,
    }),
    async generateNewReport() {
      this.isReportEnabled = false;
      this.reportId = await this.fetchReportData({ reportType: this.reportType });
      this.isReportEnabled = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.trends-report {
  padding-right: var(--spacing-2);
  padding-left: var(--spacing-2);
  position: relative;
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-bottom: 80px;
  }

  .chart-section {
    min-height: 200px;
  }

  > div:not(.loading-spinner) {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 0 var(--spacing-1);
    }
  }

  .loading-spinner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: 1;
  }
}

:deep(.trends-chart-container) {
  max-width: 100%;
}

.data-section {
  border-radius: var(--border-radius-1);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
}
</style>
