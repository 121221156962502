<template>
  <div
    v-if="canRead"
    class="interaction-calendar"
  >
    <div class="calendar-controls">
      <div
        v-if="isSmallScreen"
        class="calendar-controls__show-calendar"
      >
        <CustomButton
          tabindex="0"
          round
          @click="showCalPicker"
        >
          <span class="visually-hidden">{{ t('Toggle calendar picker') }}</span>
          <IconBase
            icon-name="calendar"
            icon-color="var(--colour-utility-white)"
            :height="30"
            :width="30"
          />
        </CustomButton>
        <IconBase
          :icon-name="caretType"
          class="icon"
          :height="32"
          :width="32"
        />
      </div>

      <div class="tab-switch-wrapper">
        <TabSwitch
          :selected-value="'calendar'"
          :items="calTabs"
          @switch-selected="switchMode"
        />
      </div>
    </div>

    <div class="cal-wrapper row">
      <!-- Date picker -->
      <div
        class="cal-picker col col-12 col-md-4 col-lg-3 col-xl-3"
        :class="{ show: isCalPickerVisible }"
      >
        <MiniCalendar
          :locale="locale"
          :selected-date="selectedDate"
          :summary="summary"
          :min-date="props.minDate"
          :max-date="props.maxDate"
          @set-selected-date="updateSelectedDate"
          @update-month="updateMonth"
        />
      </div>

      <div class="cal-main col col-12 col-md-8 col-lg-9 col-xl-9">
        <!-- List view -->
        <vue-cal
          v-if="viewMode === 'list'"
          ref="vuecal"
          :active-view="viewType"
          class="list-view"
          :disable-views="listAvailableViews"
          :hide-body="isWeekView"
          :locale="locale"
          :selected-date="selectedDate"
          @cell-focus="handleCellFocus"
          @ready="changeView"
          @view-change="changeDate"
        >
          <!-- Bespoke next/previous icons -->
          <template #arrow-prev>
            <IconBase
              aria-hidden="true"
              icon-name="chevron-left"
              icon-color="black"
              :height="48"
              :width="48"
            />
          </template>
          <template #arrow-next>
            <IconBase
              aria-hidden="true"
              icon-name="chevron-right"
              icon-color="black"
              :height="48"
              :width="48"
            />
          </template>

          <template #title="{ view }">
            <div class="date-title">
              {{ getTitle(view) }}
              <BufferImage
                v-show="props.loading"
                class="loading-spinner ml-half"
                color="var(--colour-utility-black)"
                :size="16"
              />
            </div>
          </template>
          <template 
            v-if="!isWeekView && viewMode === 'list'"
            #cell-content
          >
            <BufferImage
              v-if="props.loading"
              class="loading-spinner"
              color="var(--colour-utility-black)"
              float="center"
            />
            <InteractionsTable
              v-else
              :status="status"
              :items="props.events"
              :loading="props.loading"
              :selected-date="selectedDate"
              @load-data="loadInteractions"
              @update-month="updateMonth"
            />
          </template>
          <template #no-event />
        </vue-cal>
        <InteractionsTable
          v-if="isWeekView && viewMode === 'list'"
          :items="props.events"
          :selected-date="selectedDate"
          :status="status"
          @load-data="loadInteractions"
          @update-month="updateMonth"
        />

        <!-- Calendar view -->
        <vue-cal
          v-if="viewMode === 'calendar'"
          ref="vuecal"
          :active-view="viewType"
          small
          :disable-views="['years', 'year', 'month']"
          :editable-events="{
            title: true,
            resize: false,
            delete: false,
            create: false,
          }"
          :events="props.events"
          :locale="locale"
          :min-cell-width="220"
          overlaps-per-time-step
          :selected-date="selectedDate"
          :time="showTimeline"
          :time-step="60"
          @cell-focus="handleCellFocus"
          @ready="changeView"
          @view-change="changeDate"
        >
          <!-- Bespoke next/previous icons -->
          <template #arrow-prev>
            <IconBase
              aria-hidden="true"
              icon-name="chevron-left"
              icon-color="black"
              :height="48"
              :width="48"
            />
          </template>

          <template #arrow-next>
            <IconBase
              aria-hidden="true"
              icon-name="chevron-right"
              icon-color="black"
              :height="48"
              :width="48"
            />
          </template>

          <template #title="{ view }">
            <div class="date-title">
              {{ getTitle(view) }}
            </div>
          </template>

          <template #event="{ view, event }">
            <CalendarInteractionCard
              :item="event"
              :view="view"
              @open-interaction="openInteraction"
              @edit-interaction="editInteraction"
              @delete-interaction="deleteInteraction"
              @complete-interaction="completeInteraction"
            />
          </template>

          <template #no-event>
            <BufferImage
              v-if="props.loading"
              class="loading-spinner"
              color="var(--colour-utility-black)"
              float="center"
            />
            <template v-else>
              {{ t('There are no interactions planned for this date range.') }}
            </template>
          </template>
        </vue-cal>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useMq } from 'vue3-mq';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import { BufferImage, TabSwitch, CustomButton, IconBase } from '@sales-i/dsv3';
import breakpoints from '@/shared/utils/breakpoints';
import InteractionsTable from '@/crm/components/Calendar/InteractionsTable.vue';
import CalendarInteractionCard from '@/crm/components/Calendar/CalendarInteractionCard.vue';
import MiniCalendar from '@/crm/components/Calendar/MiniCalendar.vue';
import usePermissions from '@/shared/composables/usePermissions';
import { interactions } from '@/shared/store/data/policies';
import { dates, t } from '@sales-i/utils';
import { openInteractionsMap } from '@/shared/utils/mobile';

let defaultDate = new Date();
const mq = useMq();
const currentView = ref('day');
const dateFrom = ref(defaultDate);
const dateTo = ref(defaultDate);
const isCalPickerVisible = ref(false);
const locale = ref('en');
const selectedDate = ref(defaultDate);
const viewType = ref('day');
const viewModes = ref({
  open: 'calendar',
  missed: 'list',
  complete: 'list',
});

const props = defineProps({
  events: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  status: {
    type: String,
    default: 'open',
  },
  summary: {
    type: Array,
    default: () => [],
  },
  isMobile: {
    type: Boolean,
    default: false
  },
  minDate: {
    type: Date,
    default: () => null,
  },
  maxDate: {
    type: Date,
    default: () => null,
  },
  isWeekView: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['dateChanged', 'openInteraction', 'editInteraction', 'deleteInteraction', 'completeInteraction', 'loadData', 'updateMonth', 'viewChanged']);

const calTabs = computed(() => {
  if (isListOnly.value) return [];
  let tabs = [
    {
      title: t('Calendar'),
      value: 'calendar',
    },
    {
      title: t('List'),
      value: 'list',
    }
  ];
  if (props.isMobile) {
    tabs.push({
      title: t('Map'),
      value: 'map',
    });
  }
  return tabs;
});

const isListOnly = computed(() => ['missed', 'complete'].includes(props.status));
const viewMode = computed(() => isListOnly.value ? 'list' : viewModes.value[props.status]);
const isSmallScreen = computed(() => breakpoints.smAndDown.includes(mq.current));
const isToday = computed(() => selectedDate.value.toDateString() === new Date().toDateString());
const caretType = computed(() => (isCalPickerVisible.value ? 'caret-up' : 'caret-down'));
const showTimeline = computed(() => viewType.value !== 'day');
const { hasAccess } = usePermissions();
const canRead = computed(() => hasAccess(interactions, 'read'));
const listAvailableViews = computed (() => isListOnly.value ? ['years', 'year', 'week', 'month'] : ['years', 'year', 'month']);

function changeDate(view) {
  const { view: currentView, startDate, endDate } = view;
  
  if (currentView !== 'week') selectedDate.value = startDate; // Update selectedDate only if the view is not 'week'
  
  viewType.value = currentView;
  dateFrom.value = startDate;
  dateTo.value = endDate;

  emit('dateChanged', {
    dateFrom: startDate,
    dateTo: endDate,
  });
  emit('viewChanged', currentView === 'week');
}

function updateSelectedDate(event) {
  selectedDate.value = event;
}
function updateMonth(view) {
  emit('updateMonth', view);
}
function handleCellFocus(value) {
  selectedDate.value = value.date || value;
}
function changeView(value) {
  currentView.value = value.view;
}
function openInteraction(item) {
  emit('openInteraction', item);
}
function editInteraction(item) {
  emit('editInteraction', item);
}
function deleteInteraction(item) {
  emit('deleteInteraction', item);
}
function completeInteraction(item) {
  emit('completeInteraction', item);
}
function loadInteractions() {
  emit('loadData');
}
function showCalPicker() {
  isCalPickerVisible.value = !isCalPickerVisible.value;
}
// saves current mode to relevant viewModes element
function switchMode(item) {
  if (item.value === 'map') {
    openInteractionsMap();
  }
  else viewModes.value[props.status] = item.value;
}
function getTitle(view) {
  if (view.id === 'day') return `${isToday.value ? t('Today') : ''} ${dates.format(view.startDate)}`;
  else if (view.id === 'week') return `${dates.format(view.startDate)} - ${dates.format(view.endDate)}`;
}
</script>

<style lang="scss">
@import '@/shared/assets/scss/_variables';
.calendar-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-2);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    justify-content: flex-end;
    margin-bottom: initial;
  }
}
.calendar-controls__show-calendar {
  display: flex;
  align-items: center;
  justify-content: center;

  .button {
    height: 50px !important;
    width: 50px !important;
  }

  &:hover > .button {
    background-color: var(--colour-utility-focus);
  }
}

// Override for card component to add a horizontal scroll bar for the week view.
.interaction-calendar {
  & > .c-card {
    overflow-x: auto;
  }
}

.three-col-equal-height {
  --gap-space: var(--spacing-2);

  display: grid;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  }
  gap: var(--gap-space);

  .c-card--body {
    height: 100%;
  }

  .flow {
    display: flex;
    flex-direction: column;
  }
}

.cal-wrapper {
  position: relative;
}

// Styles for the day 'discs' for the date-picker calendar
.vuecal--date-picker:not(.vuecal--day-view) .vuecal__cell--today .vuecal__cell-content {
  border-color: var(--colour-utility-black);
}

.vuecal--date-picker:not(.vuecal--day-view) .vuecal__cell--selected .vuecal__cell-content {
  background-color: var(--colour-utility-black);
  padding: var(--spacing-1);
}

// Remove the border from the calendar
.vuecal {
  box-shadow: none;
  .vuecal__weekdays-headings {
    border: none;
    padding-right: 0;
  }
  .vuecal__title-bar {
    background-color: inherit;
  }
  .vuecal__heading {
    opacity: 1;
  }
}

// There's a bit of nesting styles going on here but it helps make the styles a bit more specific in order to override Vue Cal's defaults.
.cal-main {
  position: relative;

  .vuecal {
    box-shadow: none;
  }

  .view-selector {
    display: flex;
    justify-content: center;
  }

  .vuecal__header {
    .vuecal__title-bar {
      align-items: center;
      justify-content: center;
      min-height: inherit;
      margin-bottom: var(--spacing-2);
    }

    .vuecal__menu {
      border-bottom: 1px solid var(--colour-panel-g-24);
      margin-bottom: var(--spacing-1);

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        margin-bottom: 0;
      }
    }
    .vuecal__title-bar {
      margin-bottom: var(--spacing-2);
    }
    .vuecal__menu,
    .vuecal__title-bar {
      background-color: inherit;

      .vuecal__title {
        color: var(--colour-utility-base);
        flex: inherit; // The only way to override these vuecal styles
        font-size: var(--font-size-4);
        width: inherit; // The only way to override these vuecal styles
      }
    }

    .vuecal__view-btn {
      font-size: var(--font-size-3);
    }

    .vuecal__view-btn--active {
      border-bottom-width: var(--spacing-half);
      font-weight: bold;
    }

    .vuecal__weekdays-headings {
      background-color: var(--colour-panel-g-2);
      border-top-right-radius: var(--border-radius-1);
      border-top-left-radius: var(--border-radius-1);
      padding-left: 6em;

      .vuecal__heading {
        background-color: var(--colour-panel-g-2);
        height: inherit;
      }

      // Give each even day a different background colour
      .vuecal__heading:nth-child(even) {
        background-color: var(--colour-utility-white);
      }

      // Make the last tiem transparent to allow the background with the border-radius to show through
      .vuecal__heading:last-child {
        background-color: transparent;
      }

      .weekday-label {
        flex-direction: column;
        font-size: var(--font-size-4);
        font-weight: bold;
        padding: var(--spacing-2) 0 var(--spacing-3);

        .small {
          color: var(--colour-panel-g-32);
          font-size: var(--font-size-small);
          font-weight: 300;
          text-transform: uppercase;
        }
      }
    }
  }

  .vuecal__body {
    background-color: var(--colour-panel-white);
    border-radius: var(--border-radius-1);
    //border: 1px solid var(--shadow-colour);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  // Day view only
  .vuecal--day-view {
    .vuecal__body {
      .vuecal__event:before {
        content: '';

        background-image: repeating-linear-gradient(
            0deg,
            var(--colour-panel-g-16),
            var(--colour-panel-g-16) 25px,
            transparent 25px,
            transparent 30px,
            var(--colour-panel-g-16) 30px
          ),
          repeating-linear-gradient(
            90deg,
            var(--colour-panel-g-16),
            var(--colour-panel-g-16) 25px,
            transparent 25px,
            transparent 30px,
            var(--colour-panel-g-16) 30px
          ),
          repeating-linear-gradient(
            180deg,
            var(--colour-panel-g-16),
            var(--colour-panel-g-16) 25px,
            transparent 25px,
            transparent 30px,
            var(--colour-panel-g-16) 30px
          ),
          repeating-linear-gradient(
            270deg,
            var(--colour-panel-g-16),
            var(--colour-panel-g-16) 25px,
            transparent 25px,
            transparent 30px,
            var(--colour-panel-g-16) 30px
          );
        background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
        background-position: 0 0, 0 2px, 100% 0, 0 100%;
        background-repeat: no-repeat;
        border-top-color: transparent;
        height: 1px;
        margin-top: 43px;
        z-index: -1;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
      .card-info h3,
      .card-title {
        font-size: var(--font-size-4);
      }
      .links {
        flex-wrap: wrap;
        gap: 0 var(--spacing-1);

        & > * {
          margin-left: 0 !important;
        }
      }
    }
  }

  // Week view only
  .vuecal--week-view {
    .vuecal__body {
      z-index: 1;
      background-color: var(--colour-panel-g-2);
      border-radius: var(--border-radius-1);

      .vuecal__event {
        // left: 0 !important;
        height: inherit !important;
        padding: var(--spacing-half) var(--spacing-half);
        // width: 100% !important;
      }

      .vuecal__time-column {
        box-shadow: none;
        margin-bottom: var(--spacing-10);
        padding-right: 1rem;
        padding-left: 1.5rem;
        width: auto;
      }
    }

    .crm-card {
      padding: var(--spacing-1);
    }

    .vuecal__cell--selected {
      background-color: var(--colour-utility-focus) !important;
    }

    .vuecal__time-cell {
      height: 40px !important;
    }
  }

  .vuecal__body {
    .vuecal__bg {
      background-color: var(--colour-panel-g-2);
      height: 100%;
    }

    .vuecal__time-column {
      .vuecal__time-cell-line:before {
        background-image: repeating-linear-gradient(
            0deg,
            var(--colour-panel-g-16),
            var(--colour-panel-g-16) 25px,
            transparent 25px,
            transparent 30px,
            var(--colour-panel-g-16) 30px
          ),
          repeating-linear-gradient(
            90deg,
            var(--colour-panel-g-16),
            var(--colour-panel-g-16) 25px,
            transparent 25px,
            transparent 30px,
            var(--colour-panel-g-16) 30px
          ),
          repeating-linear-gradient(
            180deg,
            var(--colour-panel-g-16),
            var(--colour-panel-g-16) 25px,
            transparent 25px,
            transparent 30px,
            var(--colour-panel-g-16) 30px
          ),
          repeating-linear-gradient(
            270deg,
            var(--colour-panel-g-16),
            var(--colour-panel-g-16) 25px,
            transparent 25px,
            transparent 30px,
            var(--colour-panel-g-16) 30px
          );
        background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
        background-position: 0 0, 0 2px, 100% 0, 0 100%;
        background-repeat: no-repeat;
        border-top-color: transparent;
        height: 1px;
        margin-top: 10px;
        z-index: 1;
      }

      .vuecal__time-cell-label {
        background-color: var(--colour-panel-g-2);
        padding: 0 var(--spacing-1);
        position: relative;
        z-index: 1;
      }
    }

    .vuecal__time-cell-label {
      color: var(--colour-utility-black);
    }

    .vuecal__cell {
      border: 6px solid transparent;
      text-align: inherit;
      z-index: 1;
    }

    .vuecal__cell:before {
      border: 0;
    }

    .vuecal__cell:nth-child(even) {
      background-color: var(--colour-utility-white);
    }

    .vuecal__event {
      background: none;
      padding: var(--spacing-half) var(--spacing-1);
      margin: 0;

      // This span element comes from the CRM card component and it makes more sense to change the display declaration here rather than change the element in the CRMCard component.
      // Without this style the CRMCard tries to set the height based on the event duration which is not what the design suggests.
      span {
        display: block;
      }

      .vuecal__event-title {
        font-weight: bold;
      }
    }
    .vuecal__event--focus,
    .vuecal__event:focus {
      box-shadow: none;
      .c-card {
        box-shadow: 0 0 4px 0 var(--colour-panel-g-64);
      }
    }

    .card-info {
      margin-bottom: 0;
      // Show ellipses when there's not enough horizontal space for the content
      & > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      h3 {
        color: var(--colour-utility-black);
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-semibold);
        line-height: 1;
      }

      p {
        color: var(--colour-utility-base);
        margin-bottom: 0;
      }
    }
  }
}

// List-view specific styles
.cal-main .list-view {
  .vuecal__body {
    min-height: 20rem; // Sets the min-height when there are no events in the list so the drop-down menu is not cut-off.
    padding-right: 0;
    padding-left: 0;
  }
  .vuecal__body .vuecal__bg {
    overflow: visible;

    & > .vuecal__flex {
      padding: 0;
    }
  }
  .vuecal__body .vuecal__cell {
    border: 0;
  }
  .vuecal__cell-events > *,
  .vuecal__time-column,
  .vuecal__now-line {
    display: none;
  }
  &.vuecal--week-view {
    height: auto;

    .vuecal__weekdays-headings {
      display: none;
    }
  }
}

.date-title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  line-height: 1rem;
}

.vuecal__no-event,
.interactions_table_no_event {
  text-align: center;
  color: var(--colour-utility-base);
}
</style>
