import RolesView from '@/admin/views/Company/Permissions/Roles.vue';
import RoleDetailsView from '@/admin/views/Company/Permissions/RoleDetailsView.vue';
import RoleList from '@/admin/components/Company/Permissions/Roles/RoleList.vue';
import RoleDetailsForm from '@/admin/components/Company/Permissions/Roles/RoleDetailsForm.vue';
import RestrictionsForm from '@/admin/components/Company/Permissions/Roles/RestrictionsForm.vue';
import AddRole from '@/admin/views/Company/Permissions/AddRole.vue';
import { rolesSection } from '@/admin/router/urlBits';

const roleAddEditChildRoutes = [
  {
    path: '',
    component: RoleDetailsForm,
  },
  {
    path: 'restrictions',
    component: RestrictionsForm,
  },
];

const RoleRoutes = [
  {
    path: rolesSection,
    component: RolesView,
    children: [
      {
        path: '',
        component: RoleList,
        children: [{
          path: ':id(\\d+)',
          component: RoleDetailsView
        }]
      },
      {
        path: ':addedit(add)',
        component: AddRole,
        children: roleAddEditChildRoutes,
      },
      {
        path: ':id(\\d+)/:addedit(edit)',
        component: AddRole,
        children: roleAddEditChildRoutes,
      }
    ],
  }
];

export default RoleRoutes;