
import { computed } from 'vue';
import {
  FETCH_REPORT_DATA,
  CLEAR_REPORT_DATA,
  APPLY_DATE_RANGE,
  SET_LOADING,
  REFRESH_REPORT,
} from '@/intelligence/store/actionType';

export default function useShared({ store } = {}) {
  const fetchedDates = computed(() => store.state.intelligence.shared.loading);
  const expanded = computed(() => store.state.intelligence.shared.isReportExpanded);
  const reportRefreshCounter = computed(() => store.state.intelligence.shared.reportRefreshCounter);
  const getReportData = id => store.getters['intelligence/shared/getReportData'](id);
  const getInputData = reportType => store.getters['intelligence/shared/getInputData'](reportType);
  const getInputArea = inputArea => store.getters['intelligence/shared/getInputArea'](inputArea);

  const fetchReportData = (params) => store.dispatch(`intelligence/shared/${FETCH_REPORT_DATA}`, params);
  const clearReportData = (params) => store.dispatch(`intelligence/shared/${CLEAR_REPORT_DATA}`, params);
  const refreshReport = () => store.dispatch(`intelligence/shared/${REFRESH_REPORT}`);
  const applyDate = (params) => store.dispatch(`intelligence/shared/${APPLY_DATE_RANGE}`, params);
  const setLoading = (params) => store.dispatch(`intelligence/shared/${SET_LOADING}`, params);

  return {
    reportRefreshCounter,
    fetchedDates,
    expanded,
    getInputArea,
    getReportData,
    getInputData,
    fetchReportData,
    clearReportData,
    refreshReport,
    applyDate,
    setLoading
  };
}