import ActivityReportView from '@/intelligence/views/ActivityReport/ActivityReportView.vue';
import { baseUrl, enquiryScope } from '@/intelligence/router/urlBits';
import { ACTIVITY_REPORT } from '@/intelligence/store/data/reportTypes';

export default [
  {
    path: `${baseUrl}/${enquiryScope}/${ACTIVITY_REPORT}`,
    component: ActivityReportView,
    name: ACTIVITY_REPORT,
  }
];
