<template>
  <div>
    <EnquiryNav
      active-area-prop="People"
      :report-type="reportType"
      :dates="dateFilter"
      hide-enquiry-buttons
    />

    <div class="area products">
      <div class="container flow">
        <div class="description products">
          <i>Who's hot who's not</i>
        </div>
        <CustomCard purpose="reversed">
          <div class="wrapper">
            <div class="main">
              <div class="template-report data">
                <div>
                  <section class="header-section">
                    <h3>
                      Sales rep leaderboard<br>
                      (YTD)
                    </h3>
                  </section>

                  <img
                    src="@/shared/assets/image/crm/demo/people-demo-chart.png"
                    alt=""
                    style="max-width: 100%"
                    class="py-5"
                  >

                  <div class="container-wrapper">
                    <FullScreenButton
                      enable-switch
                      :visible-data-type="expandedDataView"
                      @full-screen-data-type-change="switchDataView"
                    />
                  </div>
                  <section class="desktop-charts">
                    <TableWrapper
                      table-height="510px"
                    >
                      <thead>
                        <tr>
                          <th
                            title="Sales Rep"
                            class="dimension"
                          >
                            <div class="heading">
                              Sales Rep
                            </div>
                          </th>
                          <th
                            title="Sales Value"
                            class="sort"
                          >
                            <div
                              class="svg-container icon-arrow-down"
                              style="height: 16px; width: 16px"
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 48 48"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                data-v-6a919e85=""
                                style="color: var(--colour-utility-black)"
                              >
                                <title data-v-6a919e85="">icon/arrow-down</title>
                                <defs data-v-6a919e85="">
                                  <rect
                                    id="path-1"
                                    x="0"
                                    y="0"
                                    width="48"
                                    height="48"
                                    data-v-6a919e85=""
                                  />
                                </defs>
                                <g
                                  id="icon/arrow-down"
                                  stroke="none"
                                  stroke-width="1"
                                  fill-rule="evenodd"
                                  data-v-6a919e85=""
                                >
                                  <g data-v-6a919e85="">
                                    <g
                                      id="arrow-/-short_down-(Background/Mask)"
                                      data-v-6a919e85=""
                                    />
                                    <path
                                      id="arrow-/-short_down"
                                      d="M22,32.3400002 L14.8199997,25.1800003 L12,28 L24,40 L36,28 L33.1800003,25.1800022 L26,32.3400002 L26,8 L22,8 L22,32.3400002 Z"
                                      fill-rule="nonzero"
                                      data-v-6a919e85=""
                                    />
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div class="heading">
                              Sales Value
                            </div>
                            <div
                              class="chip"
                              title="£2.67M"
                            >
                              £2.67M
                            </div>
                          </th>
                          <th
                            title="Cost"
                            class=""
                          >
                            <div class="heading">
                              Cost
                            </div>
                            <div
                              class="chip"
                              title="£2.06M"
                            >
                              £2.06M
                            </div>
                          </th>
                          <th
                            title="Profit"
                            class=""
                          >
                            <div class="heading">
                              Profit
                            </div>
                            <div
                              class="chip"
                              title="£609,934"
                            >
                              £609,934
                            </div>
                          </th>
                          <th
                            title="Gross Profit"
                            class=""
                          >
                            <div class="heading">
                              Gross Profit
                            </div>
                            <div
                              class="chip"
                              title="22.84%"
                            >
                              22.84%
                            </div>
                          </th>
                          <th
                            title="Avg. Price"
                            class=""
                          >
                            <div class="heading">
                              Avg. Price
                            </div>
                            <div
                              class="chip"
                              title="£1.84"
                            >
                              £1.84
                            </div>
                          </th>
                          <th
                            title="Quantity"
                            class=""
                          >
                            <div class="heading">
                              Quantity
                            </div>
                            <div
                              class="chip"
                              title="1449688"
                            >
                              1449688
                            </div>
                          </th>
                          <th
                            title="Count"
                            class=""
                          >
                            <div class="heading">
                              Count
                            </div>
                            <div
                              class="chip"
                              title="104687"
                            >
                              104687
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td
                            class="drill-row row-title"
                            title="Neil Good"
                          >
                            <div class="drill">
                              <label
                                class="checkbox"
                                tabindex="-1"
                                for="check_is4lzwnmm"
                              >
                                <div
                                  class="checkbox-container"
                                  tabindex="-1"
                                >
                                  <input
                                    id="check_is4lzwnmm"
                                    type="checkbox"
                                    tabindex="-1"
                                    value="false"
                                  >
                                  <div
                                    class="check focusable"
                                    tabindex="0"
                                  />
                                </div>
                              </label>
                            </div>
                            <div class="title">
                              Michael Kennedy
                            </div>
                          </td>
                          <td>£2.25M</td>
                          <td>£1.61M</td>
                          <td>£637.86K</td>
                          <td>28.3214%</td>
                          <td>£6.09</td>
                          <td>369875</td>
                          <td>52544</td>
                        </tr>
                        <tr>
                          <td
                            class="drill-row row-title"
                            title="Stella Smith"
                          >
                            <div class="drill">
                              <label
                                class="checkbox"
                                tabindex="-1"
                                for="check_is4lzwnmm"
                              >
                                <div
                                  class="checkbox-container"
                                  tabindex="-1"
                                >
                                  <input
                                    id="check_is4lzwnmm"
                                    type="checkbox"
                                    tabindex="-1"
                                    value="false"
                                  >
                                  <div
                                    class="check focusable"
                                    tabindex="0"
                                  />
                                </div>
                              </label>
                            </div>
                            <div class="title">
                              John Roberts
                            </div>
                          </td>
                          <td>£1.68M</td>
                          <td>£1.21M</td>
                          <td>£472.03K</td>
                          <td>28.0504%</td>
                          <td>£6.25</td>
                          <td>269101</td>
                          <td>58114</td>
                        </tr>
                        <tr>
                          <td
                            class="drill-row row-title"
                            title="Frank Starbuck"
                          >
                            <div class="drill">
                              <label
                                class="checkbox"
                                tabindex="-1"
                                for="check_is4lzwnmm"
                              >
                                <div
                                  class="checkbox-container"
                                  tabindex="-1"
                                >
                                  <input
                                    id="check_is4lzwnmm"
                                    type="checkbox"
                                    tabindex="-1"
                                    value="false"
                                  >
                                  <div
                                    class="check focusable"
                                    tabindex="0"
                                  />
                                </div>
                              </label>
                            </div>
                            <div class="title">
                              Peter Williams
                            </div>
                          </td>
                          <td>£1.42M</td>
                          <td>£1.12M</td>
                          <td>£294.33K</td>
                          <td>20.7801%</td>
                          <td>£6.23</td>
                          <td>227481</td>
                          <td>49677</td>
                        </tr>
                        <tr>
                          <td
                            class="drill-row row-title"
                            title="Nicola Morris"
                          >
                            <div class="drill">
                              <label
                                class="checkbox"
                                tabindex="-1"
                                for="check_is4lzwnmm"
                              >
                                <div
                                  class="checkbox-container"
                                  tabindex="-1"
                                >
                                  <input
                                    id="check_is4lzwnmm"
                                    type="checkbox"
                                    tabindex="-1"
                                    value="false"
                                  >
                                  <div
                                    class="check focusable"
                                    tabindex="0"
                                  />
                                </div>
                              </label>
                            </div>
                            <div class="title">
                              Matthew Dean
                            </div>
                          </td>
                          <td>£1.23M</td>
                          <td>£969.12K</td>
                          <td>£256.89K</td>
                          <td>20.9532%</td>
                          <td>£6.45</td>
                          <td>189995</td>
                          <td>30163</td>
                        </tr>
                        <tr>
                          <td
                            class="drill-row row-title"
                            title="Norman Bannister"
                          >
                            <div class="drill">
                              <label
                                class="checkbox"
                                tabindex="-1"
                                for="check_is4lzwnmm"
                              >
                                <div
                                  class="checkbox-container"
                                  tabindex="-1"
                                >
                                  <input
                                    id="check_is4lzwnmm"
                                    type="checkbox"
                                    tabindex="-1"
                                    value="false"
                                  >
                                  <div
                                    class="check focusable"
                                    tabindex="0"
                                  />
                                </div>
                              </label>
                            </div>
                            <div class="title">
                              Darren Franklin
                            </div>
                          </td>
                          <td>£766.65K</td>
                          <td>£590.4K</td>
                          <td>£176.25K</td>
                          <td>22.9899%</td>
                          <td>£6.03</td>
                          <td>127119</td>
                          <td>11787</td>
                        </tr>
                        <tr>
                          <td
                            class="drill-row row-title"
                            title="Hannah Haselden"
                          >
                            <div class="drill">
                              <label
                                class="checkbox"
                                tabindex="-1"
                                for="check_is4lzwnmm"
                              >
                                <div
                                  class="checkbox-container"
                                  tabindex="-1"
                                >
                                  <input
                                    id="check_is4lzwnmm"
                                    type="checkbox"
                                    tabindex="-1"
                                    value="false"
                                  >
                                  <div
                                    class="check focusable"
                                    tabindex="0"
                                  />
                                </div>
                              </label>
                            </div>
                            <div class="title">
                              Kirsty Sinclaire
                            </div>
                          </td>
                          <td>£723.31K</td>
                          <td>£511.14K</td>
                          <td>£212.17K</td>
                          <td>29.3331%</td>
                          <td>£6.32</td>
                          <td>114514</td>
                          <td>28836</td>
                        </tr>
                        <tr>
                          <td
                            class="drill-row row-title"
                            title="Sarah Barnes"
                          >
                            <div class="drill">
                              <label
                                class="checkbox"
                                tabindex="-1"
                                for="check_is4lzwnmm"
                              >
                                <div
                                  class="checkbox-container"
                                  tabindex="-1"
                                >
                                  <input
                                    id="check_is4lzwnmm"
                                    type="checkbox"
                                    tabindex="-1"
                                    value="false"
                                  >
                                  <div
                                    class="check focusable"
                                    tabindex="0"
                                  />
                                </div>
                              </label>
                            </div>
                            <div class="title">
                              Lucy Jackson
                            </div>
                          </td>
                          <td>£409.15K</td>
                          <td>£289.33K</td>
                          <td>£119.82K</td>
                          <td>29.2842%</td>
                          <td>£6.55</td>
                          <td>62473</td>
                          <td>17306</td>
                        </tr>
                        <tr>
                          <td
                            class="drill-row row-title"
                            title="Jo Farrelly"
                          >
                            <div class="drill">
                              <label
                                class="checkbox"
                                tabindex="-1"
                                for="check_is4lzwnmm"
                              >
                                <div
                                  class="checkbox-container"
                                  tabindex="-1"
                                >
                                  <input
                                    id="check_is4lzwnmm"
                                    type="checkbox"
                                    tabindex="-1"
                                    value="false"
                                  >
                                  <div
                                    class="check focusable"
                                    tabindex="0"
                                  />
                                </div>
                              </label>
                            </div>
                            <div class="title">
                              James Connor
                            </div>
                          </td>
                          <td>£279.25K</td>
                          <td>£192.13K</td>
                          <td>£87.12K</td>
                          <td>31.1968%</td>
                          <td>£6.37</td>
                          <td>43813</td>
                          <td>14283</td>
                        </tr>
                        <tr>
                          <td
                            class="drill-row row-title"
                            title="Craig Newton"
                          >
                            <div class="drill">
                              <label
                                class="checkbox"
                                tabindex="-1"
                                for="check_is4lzwnmm"
                              >
                                <div
                                  class="checkbox-container"
                                  tabindex="-1"
                                >
                                  <input
                                    id="check_is4lzwnmm"
                                    type="checkbox"
                                    tabindex="-1"
                                    value="false"
                                  >
                                  <div
                                    class="check focusable"
                                    tabindex="0"
                                  />
                                </div>
                              </label>
                            </div>
                            <div class="title">
                              Chris Samuel
                            </div>
                          </td>
                          <td>£215.71K</td>
                          <td>£181.96K</td>
                          <td>£33.75K</td>
                          <td>15.6474%</td>
                          <td>£6.67</td>
                          <td>32362</td>
                          <td>1090</td>
                        </tr>
                      </tbody>
                    </TableWrapper>
                    <ReportFooter />
                  </section>
                </div>
              </div>
            </div>
          </div>
        </CustomCard>
      </div>
    </div>
  </div>
</template>

<script>
import { CustomCard } from '@sales-i/dsv3';
import { SET_VISIBLE } from '@/shared/store/actionType';
import { REFRESH_REPORT } from '@/intelligence/store/actionType';
import { mapActions, mapGetters, mapState } from 'vuex';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import { REPORT_AREA_PRODUCT } from '@/intelligence/store/data/areas';
import { DATE_FILTER_KEYS } from '@/intelligence/store/data/dateFilters';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';
import FullScreenButton from '@/intelligence/components/Shared/FullScreenButton';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'PeopleView',
  components: {
    CustomCard,
    EnquiryNav,
    FullScreenButton,
    ReportFooter,
    TableWrapper,
  },
  props: {
    reportArea: {
      type: String,
      default: REPORT_AREA_PRODUCT,
    },
  },
  emits: ['openModal'],
  data() {
    return {
      customDatepickerType: 'custom-datepicker',
      customRelativeType: 'custom-relative',
      dateFilter: {
        date_from: 'today/y',
        date_to: 'today',
      },
      defaultDateFrom: 'today/y',
      defaultDateTo: 'today',

      enquiryData: {
        row_count: 9,
        headings: {
          dimensions: ['Sales Rep'],
          values: [
            'Sales Value',
            'Cost',
            'Profit',
            'Gross Profit',
            'Avg. Price',
            'Quantity',
            'Count',
          ],
        },
        summary: {
          sales_value: 8.97953641e6,
          cost: 6.720428030000001e6,
          profit: 2.25910838e6,
          gross_profit: 228.17979999999997,
          average_price: 15.841483000000002,
          quantity: 4.99222e6,
          count: 373497,
        },
        rows: [
          {
            dimensions: [['Michael Kennedy', '9']],
            sales_value: 2.67018121e6,
            cost: 2.06024706e6,
            profit: 609934.15,
            gross_profit: 22.8424,
            average_price: 1.8419,
            quantity: 1.449688e6,
            count: 104687,
          },
          {
            dimensions: [['John Roberts', '1']],
            sales_value: 1.7132529e6,
            cost: 1.23368583e6,
            profit: 479567.07,
            gross_profit: 27.9916,
            average_price: 2.007074,
            quantity: 853607,
            count: 94896,
          },
          {
            dimensions: [['Peter Williams', '3']],
            sales_value: 1.63245789e6,
            cost: 1.27229955e6,
            profit: 360158.34,
            gross_profit: 22.0623,
            average_price: 1.790389,
            quantity: 911789,
            count: 50384,
          },
          {
            dimensions: [['Matthew Dean', '2']],
            sales_value: 1.48069079e6,
            cost: 1.06003352e6,
            profit: 420657.27,
            gross_profit: 28.4095,
            average_price: 1.934564,
            quantity: 765387,
            count: 70520,
          },
          {
            dimensions: [['Darren Franklin', '4']],
            sales_value: 696552.3,
            cost: 481290.03,
            profit: 215262.27,
            gross_profit: 30.9039,
            average_price: 1.786026,
            quantity: 390001,
            count: 27173,
          },
          {
            dimensions: [['Kirsty Sinclaire', '10']],
            sales_value: 444055.28,
            cost: 348005.73,
            profit: 96049.55,
            gross_profit: 21.63,
            average_price: 1.119155,
            quantity: 396777,
            count: 15033,
          },
          {
            dimensions: [['Lucy Jackson', '5']],
            sales_value: 172924.48,
            cost: 141993.61,
            profit: 30930.87,
            gross_profit: 17.8869,
            average_price: 1.213862,
            quantity: 142458,
            count: 2640,
          },
          {
            dimensions: [['James Connor', '6']],
            sales_value: 107538.21,
            cost: 80182.62,
            profit: 27355.59,
            gross_profit: 25.438,
            average_price: 2.004402,
            quantity: 53651,
            count: 4790,
          },
          {
            dimensions: [['Chris Samuel', '7']],
            sales_value: 61883.35,
            cost: 42690.08,
            profit: 19193.27,
            gross_profit: 31.0152,
            average_price: 2.144111,
            quantity: 28862,
            count: 3374,
          },
        ],
      },

      selectedDateFilterType: 'today/y',
      isTemplateReport: true,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.intelligence.shared.loading,
      reportKey: (state) => state.intelligence.shared.reportRefreshCounter,
    }),
    ...mapGetters({
      getInputArea: 'intelligence/shared/getInputArea',
      isDemo: 'system/isDemo',
      getInputData: 'intelligence/shared/getInputData',
    }),
    inputData() {
      return this.getInputData(this.reportType);
    },
    itemsFilteredByType() {
      return this.getInputArea(this.reportArea);
    },
    query() {
      return this.$route.query;
    },
  },
  watch: {
    reportType() {
      this.reportKey++;
    },
    $route(newRoute, oldRoute) {
      if (oldRoute.href === newRoute.href) return;

      this.reportType = newRoute.query.reportType.toUpperCase();
    },
  },
  mounted() {
    if (Object.keys(this.query).includes('reportType'))
      this.reportType =
        this.query.reportType.toUpperCase() || this.itemsFilteredByType[0].urlCode;
  },
  methods: {
    ...mapActions({
      showReportsModal: `reportsModal/${SET_VISIBLE}`,
      refreshReport: `intelligence/shared/${REFRESH_REPORT}`,
    }),
    dateRangeChangeHandler(dateObject) {
      const dateValues = Object.values(dateObject);
      const data = {};

      if (dateValues.includes(null)) return;

      if (dateValues.includes(undefined)) dateObject = this.dateParams;

      let numberOfSameElements = 0;

      Object.keys(dateObject).forEach((key) => {
        if (dateObject[key] === this.query[key]) numberOfSameElements++;
      });

      if (numberOfSameElements === 3) return;

      Object.keys(this.query).forEach((key) => {
        if (!DATE_FILTER_KEYS.includes(key)) {
          data[key] = this.query[key];
        }
      });

      const route = this.$router.resolve({
        query: {
          ...data,
          ...dateObject,
        },
      });
      navigateToUrl(route.href);

      this.refreshReport();
    },
    setActiveDate(dateInfo) {
      this.isDateFilterActive = false;

      this.dateFilter = dateInfo;
    },
    showMenu() {
      this.showReportsModal();
      this.$emit('openModal');
    },
    goToReport(item) {
      if (item.urlCode !== this.reportType && !this.loading) {
        const route = this.$router.resolve({
          query: { reportType: item.urlCode },
        });
        navigateToUrl(route.href);
        this.reportType = item.urlCode;
        this.refreshReport();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.template-report {
  max-width: 1175px;
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--spacing-4);
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 480px;

  > div:not(.loading-spinner) {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 0 var(--spacing-1);
    }
  }
}

.header-section {
  position: relative;

  h3 {
    text-align: center;
    padding: var(--spacing-2) var(--spacing-5);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-4);
    margin: auto;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      font-size: var(--font-size-4);
      padding: var(--spacing-2) var(--spacing-8);
    }

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      font-size: var(--font-size-3);
      padding: var(--spacing-4) var(--spacing-16);
    }
  }
}

.container-wrapper {
  display: flex;
  gap: var(--spacing-2);
  position: absolute;
  top: var(--spacing-1);
  right: var(--spacing-3);

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    top: var(--spacing-2);
    right: var(--spacing-3);
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    top: var(--spacing-4);
  }
}

.mobile-charts {
  display: none;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    display: block;

    .slider {
      z-index: 0;
    }
  }
}

.desktop-charts {
  border-radius: var(--border-radius-1);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread)
    var(--shadow-colour);

  & > div {
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 1264px) {
      justify-content: space-evenly;
      flex-wrap: nowrap;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      display: none;
    }
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

.date-filter {
  max-width: 320px;
}

.data-section {
  flex: 1;
}

// TemplateReport styles
.template-report {
  .slider-btn {
    height: 40px !important;
    width: 40px !important;
    background: var(--colour-data-deluge-light) !important;
    border-radius: 50%;

    &:hover {
      background: var(--colour-data-deluge) !important;

      .slider-icon {
        border-color: var(--colour-data-deluge-light) !important;
      }
    }
  }

  .slider-btn-left {
    position: absolute !important;
    left: 0 !important;
    top: calc(50% - 40px) !important;
  }

  .slider-btn-right {
    position: absolute !important;
    right: 0 !important;
    top: calc(50% - 40px) !important;
  }

  .slider-icon-left {
    margin: var(--spacing-half) calc(-1 * var(--spacing-half)) 0 0;
  }

  .slider-icon-right {
    margin: var(--spacing-half) 0 0 calc(-1 * var(--spacing-half));
  }

  .slider-icon {
    border-color: var(--colour-data-deluge) !important;
    width: 12px !important;
    height: 12px !important;
  }

  .slider-indicator-active {
    background-color: var(--colour-data-deluge) !important;
  }

  .filters {
    display: flex;
    margin-bottom: var(--spacing-3);
  }

  &.expanded {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: white;
    max-width: 100%;
    height: 100%;

    &.data {
      .mobile-charts,
      .desktop-charts {
        display: none;
      }
    }

    &.graph {
      .data-section {
        display: none;
      }
      .desktop-charts > div {
        min-height: unset;
        height: calc(100vh - 282px);
        overflow: auto;
        margin: 0;
      }
    }

    .data-section {
      height: calc(100% - 162px);
      overflow: hidden;
    }

    .table-wrapper {
      max-height: 100%;
    }

    .footer-section {
      position: absolute;
      bottom: 0;
    }

    .export-dropdown-wrapper {
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        display: none;
      }
    }

    &.CUSTOMER_DOWN_ON_SPEND {
      .table-wrapper {
        height: 100%;
        max-height: calc(100vh - 192px);

        @media #{map-get($display-breakpoints, 'md-and-down')} {
          max-height: calc(100vh - 222px);
        }

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          max-height: calc(100vh - 230px);
        }

        @media #{map-get($display-breakpoints, 'xs-only')} {
          max-height: calc(100vh - 228px);
        }
      }
    }

    &.CUSTOMER_WITH_ZERO_SPEND,
    &.CUSTOMER_TOP_NUMBER_OF_ACCOUNTS_DOWN {
      .table-wrapper {
        height: 100%;
        max-height: calc(100vh - 222px);

        @media #{map-get($display-breakpoints, 'md-and-down')} {
          max-height: calc(100vh - 255px);
        }

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          max-height: calc(100vh - 263px);
        }

        @media #{map-get($display-breakpoints, 'xs-only')} {
          max-height: calc(100vh - 260px);
        }
      }
    }

    &.CUSTOMER_WHO_SPENT_IN_PREVIOUS {
      .table-wrapper {
        height: 100%;
        max-height: calc(100vh - 288px);

        @media #{map-get($display-breakpoints, 'md-and-down')} {
          max-height: calc(100vh - 320px);
        }

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          max-height: calc(100vh - 328px);
        }
      }
    }

    &.CUSTOMER_NOT_BUYING_TOP_PRODUCTS {
      .table-wrapper {
        height: 100%;
        max-height: calc(100vh - 255px);

        @media #{map-get($display-breakpoints, 'md-and-down')} {
          max-height: calc(100vh - 287px);
        }

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          max-height: calc(100vh - 295px);
        }

        @media #{map-get($display-breakpoints, 'xs-only')} {
          max-height: calc(100vh - 292px);
        }
      }
    }
  }
}

// Two-column styles
.container {
  padding: var(--spacing-2);
}

.area {
  width: 100%;
  position: relative;
  margin-top: calc(var(--spacing-2) * -1);
  background-color: var(--colour-panel-g-2);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-top: calc(var(--spacing-3) * -1);
  }

  &.performance {
    background: linear-gradient(
      to bottom,
      transparent 0 var(--spacing-5),
      var(--colour-panel-performance) var(--spacing-5)
    );
  }

  &.products {
    background: linear-gradient(
      to bottom,
      transparent 0 var(--spacing-5),
      var(--colour-panel-product) var(--spacing-5)
    );
  }

  &.customer {
    background: linear-gradient(
      to bottom,
      transparent 0 var(--spacing-5),
      var(--colour-panel-customer) var(--spacing-5)
    );
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    flex-wrap: nowrap;
    flex-direction: row;

    &.full-width {
      flex-wrap: wrap;
    }
  }

  .c-card {
    box-shadow: none;
    padding: 0;
  }
}

.description {
  border-radius: calc(var(--spacing-1) * 1.5);
  display: grid;
  font-weight: var(--font-weight-semibold);
  place-content: center;
  padding: calc(var(--spacing-1) * 1.5);

  &.performance {
    background: var(--colour-data-puerto-rico-light);
    color: var(--colour-data-puerto-rico-label);
  }

  &.products {
    background-color: var(--colour-data-viking-light);
    color: var(--colour-data-viking-label);
  }

  &.customer {
    background: var(--colour-data-turmeric-light);
    color: var(--colour-data-turmeric-label);
  }
}

.main {
  border-left: 2px solid var(--colour-panel-base);
  overflow: auto;
  padding: var(--spacing-3) 0;
}

.content {
  padding: 0 var(--spacing-2);
  display: flex;
  width: 100%;
  transition: width 0.5s ease-in-out;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    .full-width & {
      width: 100%;
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: var(--spacing-2);
  }
}

.sidebar.hide + .content {
  width: calc(100% - var(--spacing-16));

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    width: 100%;
  }
}

.toggle-sidebar-button {
  margin-left: auto;
  transition: transform 0.4s ease-in-out;

  &.rotate {
    transform: rotate(180deg);
  }
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    transform: rotate(90deg);

    &.rotate {
      transform: rotate(-90deg);
    }
  }
}

.sidebar.hide {
  .sidebar-before-header,
  .sidebar-header .heading-wrapper h2 {
    opacity: 0;
    display: none;
  }

  .sidebar-content {
    overflow: hidden;

    .controls {
      opacity: 0;
    }

    .search-bubbles {
      display: none;
    }

    .bubble {
      label.pointer .description,
      label.pointer .icon.cross {
        opacity: 0;
        display: none;
      }
    }
  }
}

.table-container {
  --scrollbar-width: var(--spacing-2);
  width: 100%;
  margin-bottom: hidden;
}

.table-wrapper {
  overflow: auto;
  height: auto;
  width: 100%;
  position: relative;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    scrollbar-width: var(--scrollbar-width);

    &::-webkit-scrollbar {
      width: var(--scrollbar-width);
      padding: 0;
    }

    &::-webkit-scrollbar-track {
      border-radius: var(--border-radius-1);
      background-color: var(--colour-panel-g-16);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: var(--border-radius-1);
      background-color: var(--colour-panel-g-32);
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    height: var(--table-height);
  }
}

.table-container {
  position: relative;

  table {
    width: 100%;
  }

  &:after,
  &:before {
    content: '';
    display: block;
    width: 25px;
    height: calc(100% - var(--scrollbar-width));
    position: absolute;
    top: 0;
    z-index: -1;
    animation-duration: 0.5s;
    animation-name: fadeOut;
    animation-iteration-count: 1;
    opacity: 0;
  }
  &:after {
    background: linear-gradient(
      90deg,
      var(--colour-utility-black) 0%,
      rgba(0, 0, 0, 0.08) 100%
    );
    right: var(--spacing-2);
  }
  &:before {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.08) 0%,
      var(--colour-utility-black) 100%
    );
  }

  &.overflowed-right:after,
  &.overflowed-left:before {
    animation-duration: 0.5s;
    animation-name: fadeIn;
    opacity: 1;
    z-index: 10;
  }

  &.shades-hidden {
    &:after,
    &:before {
      display: none;
    }
  }
}

.expanded {
  .table-container,
  .table-wrapper {
    height: 100%;
  }
  .footer-section {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.full-picture {
  width: 100%;
  margin: 0 auto auto;
  position: relative;
  background: var(--colour-utility-white);
  display: flex;
  flex-direction: column;
  min-height: 580px;

  .data-section {
    border-radius: var(--border-radius-1);
    box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread)
      var(--shadow-colour);
    flex: 1;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  &.flex-space {
    justify-content: space-between;

    .data-section {
      flex: auto;
      display: block;
    }
  }
}

.header-section {
  position: relative;
  padding: var(--spacing-4) var(--spacing-4) var(--spacing-1);

  h3 {
    text-align: center;
    padding: 0 var(--spacing-2);
    font-weight: var(--font-weight-semibold);

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      padding: 0 var(--spacing-4);
    }
  }

  span {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-small);
    display: block;
    text-align: center;
    color: var(--colour-utility-base);
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

table {
  thead {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 15;
  }

  tbody {
    margin-top: 73px;
  }

  td,
  th {
    vertical-align: middle;
    font-size: var(--font-size-body);
    color: var(--colour-utility-black);
    text-align: center;
    border: 0;
    position: relative;
  }

  td {
    font-size: var(--font-size-small);
    padding: 0 var(--spacing-2);
    padding: var(--spacing-2) 0;
  }

  thead {
    th {
      min-width: 120px;
      padding: var(--spacing-3) var(--spacing-2) var(--spacing-3);
      background: var(--colour-panel-action);
      color: var(--colour-utility-action);
      border-bottom: 1px solid var(--colour-panel-g-16);
      position: relative;
      top: 0;
      z-index: 1;
      text-decoration: underline;

      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        min-width: 128px;
      }

      .heading {
        overflow: visible;
        white-space: nowrap;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 24px;
      }

      &.dimension {
        min-width: 120px;
        text-align: left;

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
          min-width: 195px;
        }
      }

      &.sort {
        background-color: var(--colour-panel-g-2);
      }

      .svg-container {
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 10px;
        right: 15px;
      }

      &:not(.dimension):hover {
        cursor: pointer;
        background-color: var(--colour-data-deluge-light);
      }
    }
  }

  tbody {
    tr {
      .drill ~ .title {
        padding-left: var(--spacing-4);
      }
    }
  }

  tr {
    &:first-of-type {
      td {
        padding-top: var(--spacing-5);
      }

      .drill {
        align-items: end;
      }
    }

    td {
      &.row-title {
        text-align: left;
        padding-left: var(--spacing-2);
        cursor: help;
        background: var(--colour-panel-g-2);
      }

      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 195px;
      }
    }
  }
}

.drill {
  background: var(--colour-panel-base);
  padding: var(--spacing-2) var(--spacing-1);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  .checkbox {
    background: var(--colour-utility-white);
    border-radius: var(--border-radius-half);
    margin-bottom: 0;
    width: 1.8em;
    height: 1.8em;
  }
}

.chip {
  position: absolute;
  width: 100px;
  color: var(--colour-utility-black);
  background: var(--colour-panel-g-0);
  box-shadow: 0 0 var(--border-radius-half) var(--shadow-spread) var(--shadow-colour);
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-medium);
  letter-spacing: 0;
  line-height: var(--spacing-3);
  padding: var(--spacing-1);
  border-radius: var(--spacing-6);
  margin: auto;
  margin-top: var(--spacing-1);
  left: 0;
  right: 0;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: 104px;
  }
}

.no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--colour-panel-g-2);
  flex-wrap: wrap;
  width: 100%;

  h3 {
    font-weight: var(--font-weight-semibold);
  }

  h3,
  p {
    text-align: center;
    margin: 0;
  }
}

.checkbox-container {
  input {
    display: none;
  }
}

.checkbox .checkbox-container input[type='checkbox'] + .check {
  border: 2px solid var(--colour-utility-black);
  background: var(--colour-utility-white);
  border-radius: 4px;
  width: 1.8em;
  height: 1.8em;
  transition: all 0.2s;
}
</style>
