import {
  DELETE_BY_ID,
  DELETE_BY_ID_LOADING,
  DELETE_BY_ID_ERROR,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

export const deleteByIdCommunitiesMutations = {
  [DELETE_BY_ID]: (state) => {
    state.currentlyLoaded = [];
    state.loaded = true;
    state.loading = false;
    state.error = null;
  },
  [DELETE_BY_ID_LOADING]: (state) => {
    state.loading = true;
    state.loaded = false;
    state.error = null;
  },
  [DELETE_BY_ID_ERROR]: (state) => {
    state.loading = false;
    state.loaded = false;
    state.error = 'Failed to delete community';
  },
};

export const deleteByIdCommunitiesActions = {
  [DELETE_BY_ID]: async ({ commit }, communityCode) => {
    commit(DELETE_BY_ID_LOADING);
    try {
      await httpRequest('delete', `communities/${communityCode}`, {}, {
        version: 2,
        area: 'associations',
      });
      commit(DELETE_BY_ID);
    } catch (e) {
      commit(DELETE_BY_ID_ERROR);
    }
  },
};
