export const customerHeadings = [
  {
    text: 'Customer Name',
    value: 'links',
  },
  {
    text: 'Account Number',
    value: 'account_number',
  },
  {
    text: 'Address',
    value: 'address',
  },
  {
    text: 'Created Date',
    value: 'created_date',
  },
  {
    text: 'Tags',
    value: 'tags',
  },
];

export const opportunityHeadings = [
  {
    text: 'Assigned to',
    value: 'allocated_users',
  },
  {
    text: 'Customer/Prospect Name',
    value: 'entity_name',
  },
  {
    text: 'Decision Date',
    value: 'decision_date',
  },
  {
    text: 'Value',
    value: 'value',
  },
  {
    text: 'Probability',
    value: 'probability',
  },
  {
    text: 'Tags',
    value: 'tags',
  },
  {
    text: 'Links',
    value: 'links',
  },
];

export const prospectHeadings = [
  {
    text: 'Prospect Name',
    value: 'name',
  },
  {
    text: 'Created Date',
    value: 'created_date',
  },
  {
    text: 'Tags',
    value: 'tags',
  },
  {
    text: 'Links',
    value: 'links',
  },
];