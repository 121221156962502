<template>
  <CRMCard
    :key="props.item.id"
    :on-click="() => handleOpen(props.item)"
    :title="props.item.file_name"
    :icon-name="getIcon(props.item.file_type)"
    :links="getFileLinks(props.item)"
    class="file-card"
    @open="handleOpen"
    @download="handleDownload"
  >
    <template #info>
      <p v-show="props.downloading">
        {{ t('Downloading…') }}
      </p>
      <p class="file-type">
        {{ t('File type') }} <strong>{{ props.item.file_type }}</strong>
      </p>
    </template>
    <template #tags>
      <Tag
        :id="props.item.id"
        entity="file"
        class="ml-1"
      />
    </template>
  </CRMCard>
</template>

<script setup>
// name: 'FileCard',
import { CRMCard } from '@sales-i/dsv3';
import Tag from '@/shared/components/Tags/Tag.vue';
import { t } from '@sales-i/utils';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  downloading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['open', 'download']);

function getIcon(type) {
  return type === 'image' ? 'image' : '';
}
function getFileLinks(item) {
  return [
    {
      text: t('Details'),
      item: item,
      emit: 'open',
    },
    {
      text: t('Download'),
      item: item,
      emit: 'download',
    },
  ];
}
function handleOpen({ item }) {
  emit('open', item);
}
function handleDownload({ item }) {
  emit('download', item);
}
</script>

<style lang="scss" scoped>
:deep(span.card-title) {
  word-break: break-all;
}
</style>
