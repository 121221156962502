export const baseUrl = '/crm';
export const customersArea = 'customers';
export const prospectsArea = 'prospects';
export const opportunitiesArea = 'opportunities';
export const interactionsArea = 'interactions';
export const calendarArea = 'calendar';
export const iframeArea = 'iframe';
export const dashboardArea = 'dashboard';
export const contactsSection = 'contacts';
export const filesSection = 'files';
export const postsSection = 'posts';
export const overviewSection = 'overview';
export const historySection = 'history';
export const insightsSection = 'insights';
export const customSectionsSection = 'custom-sections';
export const productGapSection = 'product-gaps';
export const interactionsHistory = 'interactions-history';
export const detailsSection = 'details';