
import { computed } from 'vue';
import { useStore } from 'vuex';
import { storage } from '@sales-i/utils';
import { enquiryScope, baseUrl as intelligenceBase, reportScope } from '@/intelligence/router/urlBits';
import { VARIANCE } from '@/intelligence/store/data/reportTypes';
import { APPLY_FILTERS_TO_QUERY, CLEAR_ENQUIRY_FILTERS, FETCH_THROWAWAY_BUBBLE_DATA, SET_FILTERS, FETCH_BUBBLE_VALUES, FETCH_BUBBLE_DATA } from '@/intelligence/store/actionType';

export default function useEnquiries({ store = useStore() } = {}) {
  const bubbleValues = computed(() => store.state.intelligence.enquiry.bubbleValues);

  const fetchThrowawayBubble = name => store.dispatch(`intelligence/enquiry/${FETCH_THROWAWAY_BUBBLE_DATA}`, name);
  const applyFiltersToQuery = (params) => store.dispatch(`intelligence/enquiry/${APPLY_FILTERS_TO_QUERY}`, params);
  const setFilter = params => store.dispatch(`intelligence/enquiry/${SET_FILTERS}`, params);
  const clearEnquiryFilters = params => store.dispatch(`intelligence/enquiry/${CLEAR_ENQUIRY_FILTERS}`, params);
  const fetchBubbleValues = params => store.dispatch(`intelligence/enquiry/${FETCH_BUBBLE_VALUES}`, params);
  const fetchBubbleData = params => store.dispatch(`intelligence/enquiry/${FETCH_BUBBLE_DATA}`, params);

  const openReportWithSingleFilter = async ({
    filterName = 'name', search, reportType = VARIANCE, 
    dimName = 'prod_group_level_1', dates
  }) => {
    const { date_from, date_to } = dates;
    const dimBubble = await fetchThrowawayBubble(dimName);
    const filterBubble = await fetchThrowawayBubble(filterName);
    const { selectedFilterType, selectedFieldTitle } = getFilterTitlesByFilter(filterName);
    storage.set('filters', [
      {
        title: 'Filter 1',
        selectedFilterType,
        selectedFieldTitle,
        description: `${selectedFilterType} > ${selectedFieldTitle}`,
        id: filterBubble[0].id,
        selected_values: [
          {
            value: search,
            id: search,
          },
        ],
        filter_type: 'include'
      },
      0,
    ]);
    window.open(
      `${intelligenceBase}/${enquiryScope}/${reportType}/${reportScope}?date_from=${date_from}&date_to=${date_to}&date_from2=${date_from}-1y&date_to2=${date_to}-1y&1-dim1=${dimBubble[0]?.id}`,
      '_blank'
    );
  };

  function getFilterTitlesByFilter(filterName) {
    switch (filterName) {
    case 'name':
      return { selectedFilterType: 'Customer', selectedFieldTitle: 'Name' };
    case 'prod_group_level_1_desc':
      return { selectedFilterType: 'Product', selectedFieldTitle: 'Product Group Level 1 Desc' };
    case 'product_group_level_1':
      return { selectedFilterType: 'Product', selectedFieldTitle: 'Product Group Level 1' };
    case 'product_name':
      return { selectedFilterType: 'Product', selectedFieldTitle: 'Name' };
    default:
      throw `Set selectedFilterType, selectedFieldTitle for ${filterName}`;
    }
  }
  
  return {
    applyFiltersToQuery,
    setFilter,
    clearEnquiryFilters,
    fetchThrowawayBubble,
    openReportWithSingleFilter,
    fetchBubbleValues,
    bubbleValues,
    fetchBubbleData
  };
}