<template>
  <div class="date-range">
    <div class="description">
      <h3>{{ t('Custom date range') }}</h3>
      <div>
        <span v-if="dateRangeNumber > 1"> {{ activeDateRange }}: </span>
        <IconBase
          icon-name="calendar"
          :height="48"
          :width="48"
        />
      </div>
      <h4>
        {{ t('Set date range') }}
        {{ isSecondDateRangeAvailable ? activeDateRange : '' }}
      </h4>
    </div>
    <div class="bar">
      <div
        v-for="(step, i) in dateRangeNumber"
        :key="step"
        :class="{ active: i === 0 || isSecondDateRangeVisible }"
      />
    </div>
    <IconButton
      class="return"
      icon-name="arrow-left"
      icon-color="var(--colour-utility-action)"
      purpose="transparent"
      icon-only
      :icon-size="32"
      @click.stop="hideDateRange"
    />
    <template v-if="!isSecondDateRangeVisible">
      <label for="date_from">
        {{ t('Date') }}
        <span v-if="isSecondDateRangeAvailable">1</span>
        ({{ t('From') }})
        <input
          id="date_from"
          v-model="date_from"
          type="date"
          :max="date_to !== '' ? date_to : null"
          class="mt-1"
        >
        <!-- 
          removed this property so it is possible to
          view future dates in enquiries :max="date_to !== '' ? date_to : formattedCurrentDate"
        -->
      </label>
      <label for="date_to">
        {{ t('Date') }}
        <span v-if="isSecondDateRangeAvailable">1</span>
        ({{ t('To') }})
        <input
          id="date_to"
          v-model="date_to"
          type="date"
          :min="date_from"
          class="mt-1"
        >
        <!-- 
          removed this property so it is possible to
          view future dates in enquiries :max="formattedCurrentDate"
        -->
      </label>
    </template>
    <template v-else>
      <label for="date_from2">
        <p>{{ t('Date') }} 2 ({{ t('From') }})</p>
        <input
          v-model="date_from2"
          type="date"
          name="date_from2"
          :max="date_to2"
        >
        <!-- 
          removed this property so it is possible to
          view future dates in enquiries :max="date_to2 || formattedCurrentDate"
        -->
      </label>
      <label for="date_to2">
        <p>{{ t('Date') }} 2 ({{ t('To') }})</p>
        <input
          v-model="date_to2"
          type="date"
          name="date_to2"
          :min="date_from2"
        >
        <!-- 
          removed this property so it is possible to
          view future dates in enquiries :max="formattedCurrentDate"
        -->
      </label>
    </template>
    <div
      v-if="showSubmit"
      class="submit-container"
    >
      <CustomButton
        class="submit"
        action="submit"
        :label="dateRangeSubmitLabel"
        @click="dateRangeSubmit"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { IconButton, CustomButton, IconBase } from '@sales-i/dsv3';
import { absDateRegex } from '@/shared/regex/datepicker';
import { SET_ITEMS } from '@/shared/store/actionType';
import { t } from '@sales-i/utils';

export default {
  components: {
    IconButton,
    CustomButton,
    IconBase,
  },
  props: {
    dateRangeNumber: {
      type: Number,
      default: 1,
    },
  },
  emits: ['hideDateRange', 'setActiveDate'],
  data() {
    return {
      date_from: '',
      date_to: '',
      date_from2: '',
      date_to2: '',
      isSecondDateRangeVisible: false,
      activeDateRange: 1,
    };
  },
  computed: {
    ...mapState({
      alerts: state => state.alerts,
    }),
    datesObject() {
      return {
        date_from: this.date_from,
        date_to: this.date_to,
      };
    },
    dateRangeSubmitLabel() {
      return `${t('Set date range')} ${this.isSecondDateRangeAvailable ? this.activeDateRange : ''}`;
    },
    formattedCurrentDate() {
      const date = new Date();
      return this.formatDate(date);
    },
    showSubmit() {
      return this.activeDateRange === 1
        ? absDateRegex.test(this.date_from) &&
            absDateRegex.test(this.date_to)
        : absDateRegex.test(this.date_from2) &&
            absDateRegex.test(this.date_to2);
      // below is original, removed future date requirement
      // return this.activeDateRange === 1
      //   ? absDateRegex.test(this.date_from) &&
      //       absDateRegex.test(this.date_to) &&
      //       this.isNotFutureDate(this.date_from) &&
      //       this.isNotFutureDate(this.date_to)
      //   : absDateRegex.test(this.date_from2) &&
      //       absDateRegex.test(this.date_to2) &&
      //       this.isNotFutureDate(this.date_from2) &&
      //       this.isNotFutureDate(this.date_to2);
    },
    isSecondDateRangeAvailable() {
      return this.dateRangeNumber === 2;
    },
  },
  methods: {
    t,
    hideDateRange() {
      this.$emit('hideDateRange');
    },
    isNotFutureDate(date) {
      return new Date() >= new Date(date);
    },
    dateRangeSubmit() {
      const dates = { ...this.datesObject };

      if (this.isSecondDateRangeAvailable && this.activeDateRange === 1) {
        if (!this.validateDates(dates)) return;

        this.activeDateRange += 1;
        this.isSecondDateRangeVisible = true;
        return;
      }

      if (this.isSecondDateRangeVisible) {
        dates.date_from2 = this.date_from2;
        dates.date_to2 = this.date_to2;
      } else {
        const yearFrom = new Number(this.date_from.slice(0, 4)) - 1;
        const yearTo = new Number(this.date_to.slice(0, 4)) - 1;
        dates.date_from2 = yearFrom + dates.date_from.slice(4);
        dates.date_to2 = yearTo + dates.date_to.slice(4);
      }

      if (this.validateDates(dates)) {
        this.$emit('setActiveDate', dates);
      }
    },
    validateDates(dates) {
      if (
        this.formatDate(dates.date_from, 'validation') > this.formatDate(dates.date_to, 'validation') ||
        (dates.date_from2 && dates.date_to2 && this.formatDate(dates.date_from2, 'validation') > this.formatDate(dates.date_to2, 'validation'))
      ) {
        this.$store.dispatch(`alerts/${SET_ITEMS}`, {
          type: 'error',
          message: t('Please correct your date filter, Date From can not be after Date To'),
          selfDismiss: true,
        });

        return false;
      }

      return true;
    },
    formatDate(date, format) {
      if (format === 'validation') {
        return parseInt(date.replaceAll('-', ''));
      }

      const formattedDate = date.toLocaleString('en-GB', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      });

      const dateElements = formattedDate.split('/');

      return `${dateElements[2]}-${dateElements[1]}-${dateElements[0]}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.date-range {
  width: 100%;
  display: flex;
  flex-direction: column;
}

label {
  flex-basis: 100%;
  text-align: left;
  margin-bottom: var(--spacing-3);
}

p {
  color: var(--colour-panel-g-64);
  font-size: var(--font-size-5);
  letter-spacing: 0;
  margin-bottom: var(--spacing-1);
  display: inline-block;
}

.return {
  position: absolute;
  left: var(--spacing-2);
  top: var(--spacing-2);

  &:deep(.button-container) {
    // temp/demo TODO: if no title for IconButton than margin should not be there. Solution: move margin from div to span in component
    margin-left: 0;
  }
}

.submit-container {
  background: var(--colour-panel-action);
  padding: var(--spacing-2);
  margin-left: calc(-1 * var(--spacing-2));
  margin-right: calc(-1 * var(--spacing-2));
}

.submit {
  padding: var(--spacing-1) 0;
  width: 100%;
}

.bar {
  display: flex;
  gap: var(--spacing-1);
  margin-bottom: var(--spacing-3);

  > div {
    height: var(--spacing-1);
    background: var(--colour-panel-g-16);
    flex: 1;

    &.active {
      background: var(--colour-data-puerto-rico);
    }
  }
}

.description {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: var(--spacing-4) 0;

  h3,
  h4 {
    font-weight: var(--font-weight-semibold);
    flex-basis: 100%;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    > span {
      font-size: var(--font-size-3);
      font-weight: var(--font-weight-semibold);
      color: var(--colour-utility-black);
      margin-bottom: 0;
    }
  }
}
</style>
