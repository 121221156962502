<template>
  <TabsAndTagsLayout
    :title="t('My opportunities')"
    :show-tags="false"
    :tabs="tabs"
    :tab-selected-index="activeTabIndex"
    :show-cta="!!ctaTitle"
    :cta-title="ctaTitle"
    @cta-click="handleAdd"
    @tab-selected="handleTabSelected"
  >
    <template #content>
      <div class="container container--fluid bg">
        <component
          :is="listComponent"
          :key="`list_${isCrmTab}`"
          class="padded"
          :filter="filter"
          :search="search"
          :search-label="searchLabel"
          :opportunity-type="opportunityType"
          :status="status"
          :decision-date-filter="ddFilter"
          :sort="sort"
          :page-limit="limit"
          :two-way-pagination="false"
          @filter-changed="handleFilterChanged"
          @sort="handleSort"
        />
      </div>
      <router-view />
    </template>
  </TabsAndTagsLayout>
</template>

<script setup>
// OpportunitiesTabView
import { ref, watch, computed, onBeforeMount } from 'vue';
import TabsAndTagsLayout from '@/shared/components/Layout/TabsAndTagsLayout.vue';
import OpportunitiesListView from '@/crm/views/Opportunities/OpportunitiesListView.vue';
import { SET_ROUTE } from '@/shared/store/actionType';
import { baseUrl, opportunitiesArea } from '@/crm/router/urlBits';
import { usePagination, paginationProps } from '@/shared/composables/usePagination';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';
import usePermissions from '@/shared/composables/usePermissions';
import { opportunities, missed_potential_opportunities } from '@/shared/store/data/policies';
import AiOpportunitiesListView from './AiOpportunitiesListView.vue';
import { DateTime } from 'luxon';
import { dates, t } from '@sales-i/utils';
import { navigateToUrl } from 'single-spa';

const ALLOCATED_TO = 'allocated_to';

const { getPermissions } = usePermissions();
const permissionsCRM = getPermissions(opportunities);
const permissionsAI = getPermissions(missed_potential_opportunities);

const emit = defineEmits(['pageChanged']);
const props = defineProps(paginationProps);
const { limit, offset } = usePagination(props, undefined, emit);
const store = useStore();
const vroute = useRoute();

const sort = ref('decision_date:desc');
const isCrmTab = computed(() => activeTabIndex.value <= 2);
const activeTabIndex = ref(0);
const listComponent = computed(() => (isCrmTab.value ? OpportunitiesListView : AiOpportunitiesListView));

const tabs = [
  ...(permissionsCRM.read
    ? [
      {
        title: 'Open',
        query: 'open',
      },
      {
        title: 'Won',
        query: 'won',
      },
      {
        title: 'Lost',
        query: 'lost',
      },
    ]
    : []),
  ...(permissionsAI.read
    ? [
      {
        title: 'Missed',
        query: 'missed',
      },
      {
        title: 'Potential',
        query: 'potential',
      },
    ]
    : []),
];

const ctaTitle = computed(() => (permissionsCRM.create ? t('Add opportunity') : ''));
const opportunityType = computed(() => vroute.query.type || 'manual');
const status = computed(() => vroute.query.status || 'open');
const filter = computed(() => vroute.query.filter || ALLOCATED_TO);
const search = computed(() => vroute.query.search || '');
const searchLabel = computed(() => vroute.query.searchLabel || '');

const ddFilter = computed(() => ({
  dd_filter: vroute.query.dd_filter || '30',
  date_from: dates.forApi(vroute.query.date_from || DateTime.local(), true),
  date_to: dates.forApi(vroute.query.date_to || DateTime.local().plus({ days: 30 }), true),
}));

watch(
  () => vroute.path,
  to => {
    if (to === `${baseUrl}/${opportunitiesArea}`) {
      setActiveTab();
    }
  }
);

onBeforeMount(() => {
  setActiveTab();
});

const setRoute = params => store.dispatch(`system/${SET_ROUTE}`, params);

function handleSort(nSort) {
  sort.value = nSort;
}

function setActiveTab() {
  activeTabIndex.value = Math.max(
    0,
    tabs.findIndex(e => {
      switch (opportunityType.value) {
      case 'missed':
      case 'potential':
        return e.query === opportunityType.value;
      default:
        return e.query === status.value;
      }
    })
  );
}

function handleFilterChanged(f) {
  if (filter.value !== f.filter || search.value != f.search || ddFilter.value.date_from != f.date_from || ddFilter.value.date_to != f.date_to) {
    offset.value = 0;
    let query = new URLSearchParams({ ...vroute.query, ...f });
    navigateToUrl(`${vroute.path}?${query.toString()}`);
  }
}

function handleAdd() {
  setRoute({
    cancel: vroute.fullPath,
  });
  let path = `${baseUrl}/${opportunitiesArea}/add`;
  let query = new URLSearchParams({ status: status.value });
  navigateToUrl(`${path}?${query.toString()}`);
}

function handleTabSelected(tab) {
  if (activeTabIndex.value !== tab.index) {
    const defaultQuery = new URLSearchParams({
      ...vroute.query,
      type: 'manual',
      status: tab.item.query
    });
    const potentialQuery = new URLSearchParams({
      ...vroute.query,
      status: 'open',
      type: tab.item.query,
      filter: filter.value === ALLOCATED_TO ? 'customer' : filter.value,
      search: filter.value === ALLOCATED_TO ? '' : search.value,
      searchLabel: filter.value === ALLOCATED_TO ? '' : searchLabel.value,
    });
    activeTabIndex.value = tab.index;
    switch (tab.item.query) {
    case 'missed':
    case 'potential':
      navigateToUrl(`${vroute.path}?${potentialQuery.toString()}`);
      break;
    default:
      navigateToUrl(`${vroute.path}?${defaultQuery.toString()}`);
      break;
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
}
.bg {
  background-color: var(--colour-panel-g-2);
  flex: 1;
}
.tabs {
  margin-bottom: 0;
}
.tab-switch {
  display: inline-block;
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
}
</style>
