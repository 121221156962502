<template>
  <div
    ref="container"
    class="pie-chart-container"
  >
    <svg class="pie-chart" />
  </div>
</template>

<script>
import * as d3 from 'd3';
import { mapGetters } from 'vuex';
import { abbr } from '@sales-i/utils';
import chartsMixin from '@/shared/mixins/chartsMixin';

export default {
  mixins: [chartsMixin],
  props: {
    reportType: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      chartColor: '#50BDBA',
      valueSummary: 0,
    };
  },
  computed: {
    ...mapGetters({
      getInputData: 'intelligence/shared/getInputData',
    }),
    inputData() {
      return this.getInputData(this.reportType);
    },
    chartData() {
      return this.tableData
        ? this.tableData.map(element => {
          this.valueSummary += element[this.inputData.chartKey];
          return {
            name: element.name || element.description,
            value: element[this.inputData.chartKey],
          };
        })
        : [];
    },
  },
  methods: {
    removeChart() {
      d3.select('.pie-chart-container svg.pie-chart g').remove();
    },
    processChartParams(type) {
      // dimensions and margins of the chart (mobile and desktop)
      this.currentParams =
        type === 'mobile'
          ? {
            svgWidth: this.$refs.container?.clientWidth,
            svgHeight: 410,
            margin: {
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
            },
          }
          : type === 'desktop'
            ? {
              svgWidth: this.$refs.container?.clientWidth,
              svgHeight: 610,
              margin: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              },
            }
            : '';
    },
    getChartVersion() {
      return this.$refs.container?.clientWidth <= 680 ? 'mobile' : 'desktop';
    },
    generateChart() {
      this.processChartParams(this.getChartVersion());

      // Setting up of our svg with proper calculations
      const { svgWidth, svgHeight, margin } = this.currentParams,
        radius = Math.min(svgWidth, svgHeight) / 2,
        colorChart = this.chartColor,
        colorSelected = 'var(--colour-data-puerto-rico-label)',
        colorMain = 'var(--colour-data-puerto-rico-dark)',
        verticalPositionsArray = [],
        isMobile = this.getChartVersion() === 'mobile',
        isDesktop = this.getChartVersion() === 'desktop';

      const svg = d3
        .select('.pie-chart-container svg.pie-chart')
        .attr('width', svgWidth)
        .attr('height', svgHeight)
        .append('g')
        .attr(
          'transform',
          `translate(${svgWidth / 2 + margin.left - margin.right}, ${svgHeight / 2 + margin.top - margin.bottom})`
        );

      const pie = d3
        .pie()
        .sort(null)
        .value(d => d.value);

      const piedata = pie(this.chartData);

      const arc = d3
        .arc()
        .outerRadius(isDesktop ? radius * 0.55 : radius * 0.9)
        .innerRadius(isDesktop ? radius * 0.3 : radius * 0.5);

      const outerArc = d3
        .arc()
        .innerRadius(radius * 0.65)
        .outerRadius(radius * 0.65);

      // SLICES
      svg
        .selectAll('path')
        .data(piedata)
        .enter()
        .append('path')
        .attr('fill', colorChart)
        .attr('fill-opacity', d => (piedata.length - d.index) / piedata.length)
        .attr('d', arc)
        .attr('class', 'pie-chart-slice')
        .attr('stroke', 'var(--colour-utility-white)')
        .style('stroke-width', 2)
        .style('cursor', 'pointer')
        .on('click', onSliceClick);

      // OUTER CIRCLE

      svg
        .append('circle')
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('r', isDesktop ? radius * 0.57 : radius * 0.92)
        .attr('stroke', 'var(--colour-panel-g-16)')
        .attr('fill', 'none')
        .style('filter', 'drop-shadow( 0 0 2px var(--colour-panel-g-16))');

      // create groups per slice
      const labelGroup = svg
        .selectAll('g')
        .data(piedata)
        .enter()
        .append('g')
        .attr('class', d => `label-group label-group-${d.index}`);

      if (isDesktop) {
        const radiusMultiplier = radius * 0.66;
        // CHIPS
        const chipContainer = labelGroup.append('g').attr('class', 'piechart-chips');

        const chipShape = chipContainer
          .append('rect')
          .attr('ry', 12)
          .attr('rx', 12)
          .attr('height', 24)
          .attr('class', 'chip')
          .attr('fill', 'none');

        chipContainer
          .append('text')
          .text(d => d.data.value)
          .attr('class', d => `chart-label-value chip-value-${d.index}`)
          .attr('dominant-baseline', 'middle')
          .style('text-anchor', d => (midAngle(d) < Math.PI ? 'start' : 'end'))
          .attr('transform', d => {
            const pos = outerArc.centroid(d);
            pos[0] = (radiusMultiplier + 13) * (midAngle(d) < Math.PI ? 1 : -1);
            pos[1] = processVerticalPosition(pos[1]);
            return `translate(${pos})`;
          });

        // processing additional top margin
        const lastItemVerticalPosition = verticalPositionsArray[verticalPositionsArray.length - 1];
        if (lastItemVerticalPosition < -svgHeight / 2) {
          margin.top += Math.abs(lastItemVerticalPosition + svgHeight / 2 - 15);
        }
        svg.attr(
          'transform',
          `translate(${svgWidth / 2 + margin.left - margin.right}, ${svgHeight / 2 + margin.top - margin.bottom + 15})`
        );

        chipShape
          .attr('width', d => getChipWidth(d.index))
          .attr('x', d => {
            const pos = outerArc.centroid(d);
            pos[0] = midAngle(d) < Math.PI ? radiusMultiplier + 3 : -(radiusMultiplier + getChipWidth(d.index) + 3);
            return pos[0];
          })
          .attr('y', d => {
            const pos = outerArc.centroid(d);
            pos[1] = verticalPositionsArray[d.index] - 13;
            return pos[1];
          });

        // TEXT LABELS

        labelGroup
          .append('text')
          .attr('text-anchor', d => (midAngle(d) < Math.PI ? 'start' : 'end'))
          .attr('transform', d => {
            const pos = outerArc.centroid(d);
            pos[0] = (radiusMultiplier + getChipWidth(d.index) + 8) * (midAngle(d) < Math.PI ? 1 : -1);
            pos[1] = verticalPositionsArray[d.index];
            return `translate(${pos})`;
          })
          .attr('dominant-baseline', 'middle')
          .text(d => truncateName(d.data.name));

        // POLYLINES
        const positionMultiplier = 1.34;
        labelGroup
          .append('polyline')
          .attr('class', 'label-polyline')
          .attr('points', d => {
            const pointStart = [arc.centroid(d)[0] * positionMultiplier, arc.centroid(d)[1] * positionMultiplier],
              pointMiddle = [outerArc.centroid(d)[0], verticalPositionsArray[d.index]],
              pointFinish = [radiusMultiplier * (midAngle(d) < Math.PI ? 1 : -1), verticalPositionsArray[d.index]];
            return [pointStart, pointMiddle, pointFinish];
          })
          .attr('fill', 'none')
          .attr('stroke', colorMain)
          .style('stroke-width', '1px');

        // DOTS
        labelGroup
          .append('circle')
          .attr('cx', d => arc.centroid(d)[0] * positionMultiplier)
          .attr('cy', d => arc.centroid(d)[1] * positionMultiplier)
          .attr('r', 4)
          .attr('stroke', colorMain)
          .attr('fill', colorMain)
          .attr('class', 'pie-chart-dot');
      }

      // SUMMARY CHIP

      const summaryChip = svg.append('g').attr('class', 'piechart-summary-chip');
      summaryChip
        .append('text')
        .attr('class', 'summary-value')
        .attr('dominant-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .text(abbr.int(this.valueSummary));
      summaryChip
        .insert('rect', 'text')
        .attr('ry', 32)
        .attr('rx', 32)
        .attr('height', 64)
        .attr('class', 'chip')
        .attr('fill', 'var(--colour-utility-white')
        .each(setSummaryChipWidthPosition);

      // UTILS

      function getChipWidth(index) {
        return d3.select(`text.chip-value-${index}`).node().getComputedTextLength() + 20;
      }

      function truncateName(name) {
        const nameSplit = name.split(' ');
        return nameSplit.length > 1 ? `${nameSplit[0]} ${nameSplit[1] || ''}...` : nameSplit[0];
      }

      function midAngle(d) {
        return d.startAngle + (d.endAngle - d.startAngle) / 2;
      }

      function processVerticalPosition(position) {
        verticalPositionsArray.forEach(el => {
          if (position > el - 20 && position < el + 20) {
            position = el + (position > 0 ? 26 : -26);
          }
        });
        verticalPositionsArray.push(position);
        return position;
      }

      function setSummaryChipWidthPosition() {
        let textLength = d3.select(this.parentNode).select('text').node().getComputedTextLength() + 46;
        d3.select(this)
          .attr('width', textLength)
          .attr('transform', `translate(-${textLength / 2},-35)`);
      }
      function setMobileChipWidthPosition() {
        let textLength = d3.select(this.parentNode).select('text').node().getComputedTextLength() + 26;
        d3.select(this)
          .attr('width', textLength)
          .attr('transform', `translate(-${textLength / 2},-22)`);
      }

      function onSliceClick(e, d, context) {
        const self = context || this;

        // reset all colors
        d3.selectAll('path.pie-chart-slice')
          .data(piedata)
          .attr('fill', colorChart)
          .attr('fill-opacity', d => (piedata.length - d.index) / piedata.length);
        d3.selectAll('circle.pie-chart-dot').attr('stroke', colorMain).attr('fill', colorMain);
        d3.selectAll('polyline.label-polyline').attr('stroke', colorMain);
        d3.selectAll('text.chart-label-value').attr('fill', 'var(--colour-utility-black)');
        d3.selectAll('rect.chip').attr('fill', 'none');

        if (!d3.select(self).attr('selected')) {
          d3.selectAll('path.pie-chart-slice').attr('selected', null);
          // fill slice with selected color
          d3.select(self).attr('fill', colorSelected).attr('fill-opacity', 1).attr('selected', true);
          const labelGroup = d3.select(`g.label-group-${d.index}`);
          labelGroup.select('circle').attr('fill', colorSelected).attr('stroke', colorSelected);
          labelGroup.select('polyline').attr('stroke', colorSelected);
          labelGroup.select('text.chart-label-value').attr('fill', 'var(--colour-utility-white)');
          labelGroup.select('rect.chip').attr('fill', colorSelected);

          if (isMobile) {
            d3.select('g.piechart-mobile-group').remove();
            // mobile element
            const mobileElement = svg.append('g').attr('class', 'piechart-mobile-group');
            // inner circle
            mobileElement
              .append('circle')
              .attr('fill', colorSelected)
              .attr('r', radius * 0.5)
              .attr('class', 'pie-chart-inside-circle');
            // chip with value
            mobileElement
              .append('text')
              .attr('class', 'mobile-value-text')
              .attr('dominant-baseline', 'middle')
              .attr('text-anchor', 'middle')
              .text(d.data.value.toLocaleString());
            mobileElement
              .insert('rect', 'text')
              .attr('ry', 20)
              .attr('rx', 20)
              .attr('height', 40)
              .attr('class', 'chip')
              .attr('fill', 'var(--colour-utility-white')
              .each(setMobileChipWidthPosition);
            // name label
            mobileElement
              .append('text')
              .attr('class', 'mobile-label-text')
              .attr('dominant-baseline', 'middle')
              .attr('text-anchor', 'middle')
              .text(d.data.name)
              .attr('transform', 'translate(0, -40)')
              .each(function () {
                const self = d3.select(this);
                const text = self.text();
                self.text(text.length > 18 ? text.slice(0, 18) + '...' : text);
              });

            // arrow icons:
            const arrowIconsData = [
              {
                name: 'next',
                conditional: d.index < piedata.length - 1,
                position: 'translate(16,40)',
                arrowTransform: 'translate(13, 13) scale(1.5) rotate(180)',
              },
              {
                name: 'previous',
                conditional: d.index > 0,
                position: 'translate(-16,40)',
                arrowTransform: 'translate(-13, -13) scale(1.5)',
              },
            ];
            arrowIconsData.forEach(icon => {
              if (icon.conditional) {
                // arrow group
                const arrowIcon = mobileElement
                  .append('g')
                  .attr('class', 'mobile-icon')
                  .attr('transform', icon.position)
                  .on('click', function () {
                    const nextSliceData = piedata.find(e => e.index === d.index + (icon.name === 'next' ? 1 : -1));
                    d3.select(self[`${icon.name}Sibling`]).call(
                      onSliceClick,
                      nextSliceData,
                      self[`${icon.name}Sibling`]
                    );
                  });
                // arrow background
                arrowIcon
                  .append('circle')
                  .attr('cx', 0)
                  .attr('cy', 0)
                  .attr('r', 12)
                  .attr('fill', 'var(--colour-utility-white)');
                // just arrow
                arrowIcon
                  .append('path')
                  .attr(
                    'd',
                    'M5.87249994,8.25 L8.55750024,5.55749997 L7.5,4.5 L3,9 L7.5,13.5 L8.55749881,12.4425001 L5.87249994,9.75 L15,9.75000143 L15,8.25000143 L5.87249994,8.25 Z'
                  )
                  .attr('fill', 'var(--colour-utility-action)')
                  .attr('stroke-width', 1)
                  .attr('transform', icon.arrowTransform);
              }
            });
          }
        } else {
          d3.selectAll('path.pie-chart-slice').attr('selected', null);
          if (isMobile) d3.select('g.piechart-mobile-group').remove();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.pie-chart-container {
  display: flex;
  justify-content: center;

  text {
    font-size: var(--font-size-small);
  }
  .chart-label-value {
    font-weight: var(--font-weight-semibold);
  }
  .chip {
    stroke: var(--colour-panel-g-16);
    stroke-width: 1;
  }
  .summary-value {
    font-size: var(--font-size-2);
    font-weight: var(--font-weight-semibold);
  }
  .mobile-value-text {
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-semibold);
  }
  .mobile-label-text {
    fill: var(--colour-utility-white);
  }
  svg text {
    user-select: none;
  }
  svg text::selection {
    background: none;
  }
}
</style>
