<template>
  <div class="flow">
    <CustomerOverview
      v-if="dateParams"
      is-tenant-overview
      :dates="dates"
      :date-params="dateParams"
      @date-updated="updateDates"
    />
  </div>
</template>

<script setup>
import CustomerOverview from '@/crm/views/Customers/CustomerOverview.vue';
import { useDates } from '@/intelligence/composables/useDates';

const { dates, dateParams, updateDates } = useDates({ updateDatesOnMount: true });
</script>

<style lang="scss" scoped>
</style>
