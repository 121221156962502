<template>
  <CustomSelect
    :key="refreshKey"
    v-bind="$attrs"
    :value="reactiveModelValue.option"
    @input="handleSelectInput"
  />
  <DateRangeModal
    v-if="showCustomModal"
    :model-value="reactiveModelValue"
    @close-modal="handleCancel"
    @update:model-value="handleRangeUpdate"
  />
  <TagChip
    class="p-half"
    prefix=""
    :show-action="reactiveModelValue.option === 'custom'"
    @click="handleReset"
  >
    {{ dates.format(reactiveModelValue.start) }}..{{ dates.format(reactiveModelValue.end) }}
  </TagChip>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
/**
 * DateRangeFilter
 * example for :items prop: [{ text: 'Next 30 days', value: '30' }]
 * include option with 'custom' value
 */
import { computed, ref } from 'vue';
import { DateTime } from 'luxon';
import { CustomSelect, TagChip } from '@sales-i/dsv3';
import DateRangeModal from '@/shared/components/Form/DateRangeModal.vue';
import { dates } from '@sales-i/utils';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      option: 'custom',
      start: DateTime.local().startOf('day'),
      end: DateTime.local().startOf('day'),
    }),
  },
});

const refreshKey = ref(0);
const reactiveModelValue = computed(() => ({ ...props.modelValue }));
const showCustomModal = ref(false);

const handleSelectInput = optionValue => {
  if (optionValue === 'custom') {
    showCustomModal.value = true;
  } else {
    emit('update:modelValue', {
      option: optionValue,
      start: DateTime.local().startOf('day'),
      end: DateTime.local()
        .startOf('day')
        .plus({ days: parseInt(optionValue, 10) }),
    });
  }
};

const handleCancel = () => {
  showCustomModal.value = false;
  refreshKey.value++;
};

const handleRangeUpdate = range => {
  emit('update:modelValue', {
    option: 'custom',
    ...range,
  });
  showCustomModal.value = false;
};

const handleReset = () => {
  emit('update:modelValue', undefined);
};
</script>
