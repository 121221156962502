import {
  showSearchBar,
  setSearchBarType
} from '@sales-i/utils';

export function useSearchBar() {
  /**
   * 
   * @param {*} payload 
   * examples
   *  {
        category: 'opportunity',
        paramsJSON: {},
      }
      {
        category: 'contact',
        paramsJSON: {
          name: entityDetails.name,
          id: id.value,
        },
      }
  */
  function search(payload) {
    showSearchBar();
    setSearchBarType(payload.category);
  }
  
  return {
    search
  };
}