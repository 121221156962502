<template>
  <div>
    <EnquiryNav
      :active-area-prop="reportArea"
      :report-type="reportType"
      :is-date-filter-active="isDateFilterActive"
      :dates="dates"
      :disable-nav="disableNav"
      :nav-title="title"
      hide-filters
      hide-drill
      @toggle-date-filter="toggleDateFilter"
    />
    <DatePickerView
      v-show="isDateFilterActive"
      :report-type="reportType"
      @set-active-date="setDate"
    />
    <TrendsReport 
      v-if="isReportEnabled"
      v-show="!isDateFilterActive" 
      :hide-footer="hideFooter"
      :request-params="dates"
    />
  </div>
</template>

<script>
import TrendsReport from '@/intelligence/components/Trends/TrendsReport';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import DatePickerView from '@/intelligence/components/Date/DatePickerView';
import { REPORT_AREA_ENQUIRY } from '@/intelligence/store/data/areas';
import { TRENDS, TRENDS_TITLE } from '@/intelligence/store/data/reportTypes';
import { mapActions, mapState } from 'vuex';
import { APPLY_DATE_RANGE, CLEAR_REPORT_DATA, FETCH_CALENDAR, PROCESS_REQUEST_PARAMETERS, REFRESH_REPORT, FETCH_DATES } from '@/intelligence/store/actionType';
import { dashboardArea } from '@/crm/router/urlBits';

export default {
  name: 'TrendsReportView',
  components: {
    TrendsReport,
    EnquiryNav,
    DatePickerView,
  },
  props: {
    disableNav: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: TRENDS_TITLE
    }
  },
  data() {
    return {
      reportType: TRENDS,
      reportArea: REPORT_AREA_ENQUIRY,
      isDateFilterActive: false,
      dates: {},
      isReportEnabled: false,
    };
  },
  computed: {
    ...mapState({
      calendar: state => state.intelligence.calendar.calendar,
    }),
    isDashboard() {
      return this.$route.path.includes(dashboardArea);
    },
  },
  async mounted() {
    if (!this.calendar)
      await this.fetchCalendar();
    this.dates = await this.processFiscalDates();
    this.isReportEnabled = true;
  },
  beforeUnmount() {
    this.clearReportData();
  },
  methods: {
    ...mapActions({
      refreshReport: `intelligence/shared/${REFRESH_REPORT}`,
      fetchCalendar: `intelligence/calendar/${FETCH_CALENDAR}`,
      applyDateRange: `intelligence/shared/${APPLY_DATE_RANGE}`,
      clearReportData: `intelligence/shared/${CLEAR_REPORT_DATA}`,
      processFiscalDates: `intelligence/calendar/${PROCESS_REQUEST_PARAMETERS}`,
      fetchDates: `intelligence/calendar/${FETCH_DATES}`,
    }),
    async setDate(dateObject) {
      const { date_from, date_to, date_from2, date_to2 } = dateObject;

      this.dates = dateObject;
      if (this.isDashboard) {
        this.dates = await this.fetchDates({
          date_from,
          date_to,
          date_from2,
          date_to2,
        });
      }
      this.applyDateRange(this.dates);
      this.toggleDateFilter();
      this.refreshReport();
    },
    toggleDateFilter() {
      this.isDateFilterActive = !this.isDateFilterActive;
    },
  }
};
</script>
