<template>
  <CRMCard
    :key="`${item.id}-${mailOpen}`"
    :on-click="composing ? null : handleItemClicked"
    :title="item.header || item.full_name"
    :chips="chips"
    :links="contactLinks"
    :show-footer="mailOpen"
    @edit="handleEdit"
  >
    <template #info>
      <section class="list">
        <div>{{ item.subHeader }}</div>
        <div>{{ phone }}</div>
        <div>{{ item.email }}</div>
      </section>
    </template>
    <template
      v-if="item.email && authData.status === 'VALID'"
      #icons
    >
      <template v-if="!loadingMail">
        <CustomButton
          icon-name="email"
          icon-color="var(--colour-utility-action)"
          icon-only
          round
          small
          purpose="neutral"
          :icon-width="20"
          :icon-height="20"
          @on-click="handleViewMail($event, item.id)"
        />
      </template>
      <template v-else>
        <BufferImage
          :size="20"
          color="var(--colour-utility-black)"
        />
      </template>
    </template>
    <template #tags>
      <Tag
        :id="item.id"
        entity="contact"
      />
    </template>
    <template
      v-if="mailOpen"
      #footer
    >
      <MiniMail
        :name="item.header || item.full_name"
        :email-address="item.email"
        :items="loadedContent"
        :mode="mailMode"
        :loading="loadingMail"
        :show-view-more="viewMorePossible"
        @composing="setComposing"
        @view-more="loadMoreMail"
      />
    </template>
  </CRMCard>
</template>

<script setup>
// SimpleContactCard
import { ref, computed, onUnmounted } from 'vue';
import usePermissions from '@/shared/composables/usePermissions';
import { contacts } from '@/shared/store/data/policies';
import { CRMCard, CustomButton, BufferImage } from '@sales-i/dsv3';
import { t, mailState, getMail } from '@sales-i/utils';
import Tag from '@/shared/components/Tags/Tag.vue';
import MiniMail from '@/crm/components/Mail/MiniMail.vue';

const emit = defineEmits(['edit', 'itemClicked']);

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
});

const authData = ref({});

const rxm = mailState.subscribe(newMailState => {
  authData.value = newMailState.details;
});

const loadedContent = ref([]);
const composing = ref(false);
const mailMode = ref('view');
const loadingMail = ref(false);
const mailOpen = ref(false);
const viewMorePossible = ref(true);
const limit = ref(3); // this is never changed, just makes sense to scope it here
const offset = ref(0);

const { getPermissions } = usePermissions();
const permissions = getPermissions(contacts);
const chips = computed(() => []);
const phone = computed(() => (props.item || {}).mobile_phone || (props.item || {}).business_phone);

const contactLinks = computed(() => {
  let links = [];
  if (permissions.update && props.item && props.item.id)
    links.push({
      text: t('Edit'),
      id: props.item.id,
      emit: 'edit',
    });
  if (phone.value)
    links.push({
      text: phone.value ? 'Call' : '',
      type: 'a',
      href: getTelephoneLink(phone.value),
    });
  if (props.item && props.item.email)
    links.push({
      text: props.item.email ? 'Email' : '',
      type: 'a',
      href: getMailtoLink(props.item.email),
    });

  return links;
});

function handleItemClicked() {
  emit('itemClicked', props.item);
}
function handleEdit() {
  emit('edit', props.item);
}

/**
 * These functions were the subject of the review by SonarCloud, because a\
 * person could potentially inject something into the item.value and it would
 * be executed by the application, unescaped by Vue
 */
function getTelephoneLink(phone) {
  return `tel:${phone}`;
}

function getMailtoLink(email) {
  return `mailto:${email}`;
}

// TODO: move to a composable, in ContactCard & InteractionDetails
const handleViewMail = async(e) => {
  e.stopPropagation();
  if (mailOpen.value) {
    mailOpen.value = false;
    loadedContent.value = [];
    offset.value = 0;
    viewMorePossible.value = true;
  } else {
    await loadMail();
    mailOpen.value = true;
  }
};

const loadMail = async() => {
  loadingMail.value = true;
  let results = await getMail({
    limit: limit.value,
    // offset: offset.value, need to replace with v3 equivalent, non-working now
    any_email: props.item.email,
  }) ?? [];
  if (results.length < limit.value) {
    viewMorePossible.value = false;
  }
  loadedContent.value = [
    ...results,
  ];
  loadingMail.value = false;
  return loadedContent.value;
};

const loadMoreMail = () => {
  offset.value += 3;
  loadMail();
};
const setComposing = (isComposing) => {
  composing.value = isComposing;
  mailMode.value = isComposing ? 'message' : 'view';
};

onUnmounted(() => {
  rxm.unsubscribe();
});
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-flow: column;
}
</style>
