<template>
  <CustomCallout
    v-if="isLoaded"
    :title="title"
    class="crm-post"
    @click="clickFunction"
  >
    <template #header>
      <div class="author-details">
        <span>{{ author }}</span>
        <DateTimeSnippet :value="createdAt" />
      </div>
    </template>
    <div class="main-content">
      <Mention :content="content" />
    </div>
    <template #footer>
      <div class="post-analytics">
        <span>
          {{
            t(`Comments (${numOfComments})`, 'comments_count', {
              interpolations: {
                count: numOfComments,
              },
            })
          }}
        </span>
      </div>
      <div class="links">
        <div class="tag-container">
          <Tag
            :id="id"
            entity="post"
          />
        </div>
        <button
          v-if="enabledActions.includes('delete')"
          @click.stop="deletePost"
        >
          {{ t('Delete') }}
        </button>
        <button
          v-if="enabledActions.includes('edit')"
          @click.stop="editPost(id)"
        >
          {{ t('Edit') }}
        </button>
        <button
          v-if="enabledActions.includes('open')"
          @click.stop="itemClicked(id)"
        >
          {{ isOpen ? t('Close') : t('Open') }}
        </button>
      </div>
    </template>
  </CustomCallout>
</template>

<script>
import { CustomCallout } from '@sales-i/dsv3';
import DateTimeSnippet from '@/shared/components/DateTimeSnippet.vue';
import Mention from '@/shared/components/Mention/Mention.vue';
import store from '@/shared/store';
import { mapState, mapActions } from 'vuex';
import Tag from '@/shared/components/Tags/Tag.vue';
import { SET_CONFIRMATION_MODAL, DELETE_BY_ID } from '@/shared/store/actionType';
import { t } from '@sales-i/utils';

export default {
  name: 'Post',
  components: {
    CustomCallout,
    DateTimeSnippet,
    Mention,
    Tag,
  },
  props: {
    entityId: {
      type: [Number, String],
      default: '',
    },
    id: {
      type: [Number, String],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    createdBy: {
      type: String,
      default: '',
    },
    createdAt: {
      type: [Date, String],
      default: () => new Date(),
    },
    content: {
      type: String,
      default: '',
    },
    numOfComments: {
      type: [String, Number],
      default: 0,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    isLoaded: {
      type: Boolean,
      default: true,
    },
    enabledActions: {
      type: Array,
      default: () => ['open', 'edit', 'delete'],
    },
  },
  emits: ['itemClicked', 'editPost', 'deletedPost', 'closeModal'],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      entity: state => state.system.entity,
    }),
    author() {
      return this.createdBy;
    },
    clickFunction() {
      return this.isOpen && !this.enabledActions.includes('open') ? null : () => this.itemClicked(this.id);
    },
  },
  methods: {
    t,
    ...mapActions({
      deleteById: `posts/${DELETE_BY_ID}`,
    }),
    itemClicked(id) {
      this.$emit('itemClicked', id);
    },
    editPost(id) {
      this.$emit('editPost', id);
    },
    deletePost() {
      store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, {
        message: `Do you really want to delete ${this.title}`,
        updatedMessage: `You have deleted ${this.title}`,
        updateFunction: this.confirmDelete,
        finishFunction: this.finishDelete,
      });
    },
    async confirmDelete() {
      try {
        await this.deleteById({
          entity: this.entity,
          id: this.entityId,
          post_id: this.id,
        });
        this.$emit('deletedPost');
        return true;
      } catch (e) {
        return false;
      }
    },
    finishDelete() {
      this.$emit('closeModal');
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-post {
  text-align: left;
  height: auto;
  min-width: 200px;
}
.author-details {
  position: relative;
  margin-top: var(--spacing-half);
  font-size: var(--font-size-small);
  span {
    margin-right: var(--spacing-1);
  }
  .more-menu {
    display: inline-block;
    position: relative;
    top: -1px;
    left: calc(var(--spacing-2) * -1);
  }
}
.main-content {
  word-wrap: break-word;
  font-style: italic;
  font-weight: var(--font-weight-medium);
}
:deep(.callout-card--footer) {
  display: flex;
  flex-flow: column nowrap;
}
.post-analytics {
  display: flex;
  align-items: center;
  font-size: var(--font-size-small);
  > span {
    margin-right: var(--spacing-1);
  }
}

:deep(.tag > p) {
  margin-top: 0;
}
</style>
