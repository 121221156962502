import {
  GET_BY_ID,
  GET_BY_ID_LOADING,
  GET_BY_ID_ERROR,
  CLEAR_BY_ID,
  GET_CUSTOMER_ID
} from '@/shared/store/actionType';
import { httpRequest, updateBreadcrumb } from '@sales-i/utils';

// mutations
export const getByIdMutations = {
  [GET_BY_ID]: (state, data) => {
    state.selected.data = data;
    state.selected.loaded = true;
    state.selected.loading = false;
  },
  [GET_BY_ID_LOADING]: (state) => {
    state.selected.loaded = false;
    state.selected.loading = true;
  },
  [GET_BY_ID_ERROR]: (state) => {
    state.selected.loaded = false;
    state.selected.loading = false;
  },
  [CLEAR_BY_ID]: (state) => {
    state.selected.data = {};
    state.selected.loaded = false;
    state.selected.loading = false;
  },
};

// actions
export const getByIdActions = {
  [GET_BY_ID]: async ({ commit, }, parameters) => {
    try {
      const { id, options = {version: '2'} } = parameters;
      commit(GET_BY_ID_LOADING);
      const data = await httpRequest('get', `customers/${id}`, {}, options);
      commit(GET_BY_ID, data);
      updateBreadcrumb('customers', id, data.name);
      return data;
    } catch (error) {
      console.error(error);
      commit(GET_BY_ID_ERROR);
      return false;
    }
  },
  [CLEAR_BY_ID]: ({ commit, }) => {
    commit(CLEAR_BY_ID);
  },
  // GET_CUSTOMER_ID returns ID number of a customer, which can be used as an argument for GET_BY_ID action
  // eslint-disable-next-line no-empty-pattern
  [GET_CUSTOMER_ID]: async ({}, accountId) => {
    try {
      const data = await httpRequest('get', `customers/byacc/${accountId}`, {}, {version: '2'});
      return data && data.id;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
};
