export const productSearchFilters = [
  {
    text: 'Product Name',
    value: 'product_name',
    path: 'product_name',
  },
  {
    text: 'Product Code',
    value: 'product_code',
    path: 'product_code',
  },
  {
    text: 'Product Group Level 1 Description',
    value: 'prod_group_level_1_desc',
    path: 'prod_group_level_1_desc',
  },
  {
    text: 'Product Group Level 2 Description',
    value: 'prod_group_level_2_desc',
    path: 'prod_group_level_2_desc',
  },
  {
    text: 'Product Group Level 3 Description',
    value: 'prod_group_level_3_desc',
    path: 'prod_group_level_3_desc',
  },
  {
    text: 'Product Group Level 4 Description',
    value: 'prod_group_level_4_desc',
    path: 'prod_group_level_4_desc',
  },
  {
    text: 'Product Group Level 5 Description',
    value: 'prod_group_level_5_desc',
    path: 'prod_group_level_5_desc',
  },
];
