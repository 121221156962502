<template>
  <div class="edit-note-container">
    <IconButton
      class="note-button"
      icon-name="notes"
      icon-color="var(--colour-utility-white)"
      purpose="action"
      variant="round"
      icon-only
      :icon-size="18"
      @click.stop="toggleModal"
    />
    <CustomModal
      id="note-modal"
      class="note-modal"
      :show-modal="isModalVisible"
      position="top"
      @close-modal="toggleModal"
    >
      <BufferImage
        v-if="loading"
        class="loading-spinner"
        color="var(--colour-utility-black)"
        float="center"
      />
      <h2>{{ t('Update interaction note') }}</h2>
      <CustomTextarea
        v-model="note"
        class="note-area"
        :rows="10"
        :disabled="textAreaDisabled"
        :label="`${t('interaction note')}:`"
      />
      <template #footer>
        <CustomButton
          :label="t('Update')"
          purpose="action"
          small
          @click="updateNote"
        />
      </template>
    </CustomModal>
  </div>
</template>

<script>
import { IconButton, CustomModal, CustomButton, BufferImage, CustomTextarea } from '@sales-i/dsv3';
import { mapActions, mapState } from 'vuex';
import { GET_BY_ID, PUT_BY_ID } from '@/shared/store/actionType';
import { SET_FURTHER_DETAILS } from '@/crm/store/actionType';
import { t } from '@sales-i/utils';

export default {
  components: {
    IconButton,
    CustomModal,
    CustomButton,
    BufferImage,
    CustomTextarea,
  },
  props: {
    interactionNote: {
      type: String,
      default: '',
    },
    interactionId: {
      type: Number,
      default: null,
    },
  },
  emits: ['updateNote'],
  data() {
    return {
      isModalVisible: false,
      loading: false,
      note: '',
      textAreaDisabled: false,
    };
  },
  computed: {
    ...mapState({
      currentlyEditing: state => state.crm.interactions.currentlyEditing,
    }),
  },
  watch: {
    interactionNote() {
      this.refreshNote();
    },
  },
  methods: {
    t,
    ...mapActions({
      updateInteraction: `crm/interactions/${PUT_BY_ID}`,
      getInteraction: `crm/interactions/${GET_BY_ID}`,
      setFurtherDetails: `crm/interactions/${SET_FURTHER_DETAILS}`,
    }),
    toggleModal() {
      this.refreshNote();
      this.isModalVisible = !this.isModalVisible;
      this.textAreaDisabled = false;
    },
    async updateNote() {
      this.textAreaDisabled = true;
      this.loading = true;
      await this.getInteraction({ id: this.interactionId });
      const furtherDetails = { ...this.currentlyEditing.furtherDetails };
      furtherDetails.interactionNote = this.note;
      this.setFurtherDetails(furtherDetails);
      await this.updateInteraction({ id: this.interactionId });
      this.$emit('updateNote', this.note);
      this.loading = false;
      this.toggleModal();
    },
    refreshNote() {
      this.note = this.interactionNote;
    },
  },
};
</script>

<style lang="scss" scoped>
.note-footer {
  background: var(--colour-panel-action);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
}

h2 {
  text-align: center;
  margin-bottom: var(--spacing-2);
}

.note-area {
  padding: 0 var(--spacing-2);

  :deep(.form-group) {
    margin-bottom: 12px;
  }
}

#note-modal {
  :deep(.content-wrapper) {
    padding-bottom: 0;
  }
}

.loading-spinner {
  position: absolute;
  top: calc(50% - 50px);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}
</style>
