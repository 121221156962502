import {
  DELETE_BY_ID,
  DELETE_BY_ID_LOADING,
  DELETE_BY_ID_ERROR
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const deleteByIdMutations = {
  [DELETE_BY_ID]: (state, id) => {
    state.selected.data = {};
    state.all.data.splice(state.all.data.findIndex(x => x.id === id), 1);
    state.selected.loaded = true;
    state.selected.loading = false;
    state.selected.error = undefined;
  },
  [DELETE_BY_ID_LOADING]: (state) => {
    state.selected.data = {};
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [DELETE_BY_ID_ERROR]: (state, error) => {
    state.selected.loading = false;
    state.selected.loaded = false;
    state.selected.error = error;
  },
};

// actions
export const deleteByIdActions = {
  [DELETE_BY_ID]: async ({ commit, }, id) => {
    try {
      commit(DELETE_BY_ID_LOADING);
      await httpRequest('delete', `prospects/${id}`, {}, { version: '2', });
      commit(DELETE_BY_ID, id);
      return true;
    } catch (error) {
      commit(DELETE_BY_ID_ERROR);
      return false;
    }
  },
};

export default {
  namespaced: true,
  deleteByIdActions,
  deleteByIdMutations,
};
