<template>
  <AddEditOpportunityModal
    v-if="isOpen && permitted"
    :id="id"
    :entity-id="entity_id"
    :entity-type="type"
    :entity-name="entity_name"
    :entity-preselected="entity_preselected"
    :status="status"
    @saved="handleSaved"
    @close="handleClose"
  />
</template>

<script setup>
// AddEditOpportunityModalView
import { ref, computed } from 'vue';
import AddEditOpportunityModal from '@/crm/components/Opportunity/AddEditOpportunityModal.vue';
import { useStore } from 'vuex';
import { PUSH_ROUTE } from '@/shared/store/actionType';
import { baseUrl, opportunitiesArea } from '@/crm/router/urlBits';
import { useRoute, } from 'vue-router';
import usePermissions from '@/shared/composables/usePermissions';
import { opportunities } from '@/shared/store/data/policies';

const props = defineProps({
  id: {
    type: [String, Number],
    default: '',
  },
  entityId: {
    type: [String, Number],
    default: '',
  },
  entityType: {
    type: String,
    default: 'customer',
  },
  entityName: {
    type: String,
    default: undefined,
  },
  entityPreselected: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const vroute = useRoute();
const { getPermissions } = usePermissions();
const permissions = getPermissions(opportunities);
const permitted = computed(() => (!props.id && permissions.create) || (props.id && permissions.update));

const isOpen = ref(true);

const entity_id = computed(() => props.entityId || +vroute.query.entityId);
const entity_name = computed(() => props.entityName || vroute.query.entityName);
const type = computed(() => props.entityType || vroute.query.entityType);
const entity_preselected = computed(() => props.entityPreselected || !!vroute.query.entityPreselected);
const status = computed(() => vroute.query.status);

const pushRoute = params => store.dispatch(`system/${PUSH_ROUTE}`, params);

function handleSaved(id) {
  isOpen.value = false;
  pushRoute({
    defaultUrl: `${baseUrl}/${opportunitiesArea}/${id}`,
    success: true,
  });
}

function handleClose() {
  isOpen.value = false;
  pushRoute({
    defaultUrl: `${baseUrl}/${opportunitiesArea}?${status.value ? 'status=' + status.value : ''}`,
    success: false,
  });
}
</script>
