import Associations from '@/admin/views/Associations/Main.vue';
import Products from '@/admin/views/Associations/Products.vue';
import SetupAssociationsBlacklistView from '@/admin/views/Associations/SetupAssociationsBlacklistView.vue';
import { associationsSection as section, blacklistPath } from '@/admin/router/urlBits';

export default {
  path: section,
  component: Associations,
  children: [
    {
      path: '',
      component: Products,
    },
    {
      path: blacklistPath,
      component: SetupAssociationsBlacklistView,
    },
  ],
};
