import CRMSetupTabsView from '@/admin/views/CRMSetup/CRMSetupTabsView.vue';
import CustomersProspects from './CustomersProspects';
import Contacts from './Contacts';
import Opportunities from './Opportunities';
import Interactions from './Interactions';
import { baseUrl, crmArea as area, customersPropspectsSection as defaultSection } from '@/admin/router/urlBits';
import Main from '@/admin/views/Main.vue';

export default [
  {
    path: baseUrl,
    component: Main
  },
  {
    path: `${baseUrl}/${area}`,
    redirect: `${baseUrl}/${area}/${defaultSection}`
  },
  {
    path: `${baseUrl}/${area}`,
    component: CRMSetupTabsView,
    children: [
      CustomersProspects,
      Contacts,
      Opportunities,
      Interactions,
    ],
  }
];
