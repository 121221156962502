<template>
  <div
    v-if="isTenantOverview"
    class="enquiry-links-container bg-blue-light"
  >
    <h2>{{ t('Missed and Potential Opportunities') }}</h2>
    <h4>{{ t('Recommended products for your customer base') }}</h4>
  </div>
  <Opportunities
    class="opportunities-container"
    hidden-header
  />
</template>

<script setup>
import { t } from '@sales-i/utils';
import Opportunities from '@/intelligence/views/Opportunities/Opportunities.vue';
</script>

<style lang="scss" scoped>
</style>
