<template>
  <div
    class="table-container"
    :style="cssVars"
  >
    <template v-if="enablePagination">
      <PaginationButton
        v-for="button in paginationData"
        v-show="isPaginationButtonAvailable(button.position)"
        :key="button.position"
        :is-pagination-button-visible="button.show"
        :position="button.position"
        :label="button.label"
        :limit="getButtonLimit(button.position)"
        @hide-pagination-button="hidePaginationButton"
        @set-offset="setOffset(button.position)"
      />
    </template>
    <div
      ref="tableWrapper"
      class="table-wrapper"
      @scroll="debouncedScrollHandler"
    >
      <component
        :is="container"
        ref="table"
      >
        <slot />
      </component>
    </div>
    <BufferImage
      v-if="isLoading"
      color="var(--colour-utility-black)"
      float="center"
      class="loading-spinner"
    />
  </div>
</template>

<script>
import { debounce } from '@sales-i/utils';
import PaginationButton from '@/intelligence/components/Shared/PaginationButton';
import { mapState } from 'vuex';
import breakpoints from '@/shared/utils/breakpoints';
import { BufferImage } from '@sales-i/dsv3';

// FYI: it is required to place single table in slot, not multiple elements
export default {
  name: 'TableWrapper',
  components: {
    PaginationButton,
    BufferImage,
  },
  inject: ['mq'],
  props: {
    tableHeight: {
      type: String,
      default: '330px',
    },
    offsetX: {
      type: Number,
      default: 0,
    },
    offsetY: {
      type: Number,
      default: 0,
    },
    // TODO: delete below flag after lazy load, limit props also
    // enablePagination is temporary flag to disable it for other places.
    // If pagination will be used in more places we will need to twick that to have option to disable particular axis
    enablePagination: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 50,
    },
    limitX: {
      type: Number,
      default: 50,
    },
    enableLazyLoad: {
      type: Boolean,
      default: false,
    },
    noMoreResultsAvailable: {
      type: Boolean,
      default: false,
    },
    container: {
      type: String,
      default: 'table',
    },
    loading: {
      type: Boolean,
      default: undefined
    },
    xHeadings: {
      type: Array,
      default: () => []
    },
    yHeadings: {
      type: Array,
      default: () => []
    }
  },
  emits: ['setOffset'],
  data() {
    return {
      isYScrollVisible: false,
      paginationData: {
        right: {
          show: false,
          position: 'right',
          label: 'next',
        },
        left: {
          show: false,
          position: 'left',
          label: 'previous',
        },
        bottom: {
          show: false,
          position: 'bottom',
          label: 'next',
        },
        top: {
          show: false,
          position: 'top',
          label: 'previous',
        },
      },
      initialLoad: true,
      xPosition: null,
      yPosition: null,
    };
  },
  computed: {
    ...mapState({
      sharedLoading: state => state.intelligence.shared.loading,
    }),
    cssVars() {
      return {
        '--table-height': this.tableHeight,
        '--scrollbar-width': this.isYScrollVisible ? 'var(--spacing-1)' : '0',
      };
    },
    isLoading() {
      return (this.loading || 
        (this.loading === undefined && this.sharedLoading && this.enableLazyLoad && !this.initialLoad && (this.offsetY || this.offsetX)));
    },
    isDesktop() {
      return breakpoints.xlAndUp.includes(this.mq.current);
    },
    debouncedResizeHandler() {
      return debounce(this.setOverflowHandler, 250);
    },
    debouncedScrollHandler() {
      return debounce(this.isOverflowedInAnyDirectionCheck, 500);
    },
    pixelsBeforeLazyLoadTrigger() {
      return this.$refs.table.clientHeight / 2;
    },
  },
  mounted() {
    window.addEventListener('resize', this.debouncedResizeHandler);
    setTimeout(this.debouncedResizeHandler, 250);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.debouncedResizeHandler);
  },
  methods: {
    setOverflowHandler() {
      this.isYScrollVisibleCheck();
    },
    isYScrollVisibleCheck() {
      const wrapper = this.$refs.tableWrapper;
      if (wrapper) this.isYScrollVisible = wrapper.scrollHeight > wrapper.clientHeight;
    },

    // Can this method be deleted? 
    isOverflowedInAnyDirectionCheck() {
      const element = this.$refs.table;
      if (!element) {
        return;
      }

      const wrapper = this.$refs.tableWrapper;
      const elementClientRects = element.getBoundingClientRect();
      const wrapperClientRects = wrapper.getBoundingClientRect();

      const xSpaceToScroll = element.clientWidth - wrapper.clientWidth;
      const ySpaceToScroll = element.clientHeight - wrapper.clientHeight;
      const xShift = wrapperClientRects.x;
      const yShift = wrapperClientRects.y;

      const xMin = xShift;
      const xMax = -(xSpaceToScroll - xMin);
      const xPosition = elementClientRects.x;

      const yMin = yShift;
      const yMax = -(ySpaceToScroll - yMin);
      const yPosition = elementClientRects.y;

      this.isOverflowedInRightDirection = xSpaceToScroll > 0 && xPosition > xMax && xPosition <= xMin;

      if (this.initialLoad) {
        this.initialLoad = false;
        return;
      }

      if (this.enableLazyLoad && !this.isLoading && !this.noMoreResultsAvailable && yPosition - this.pixelsBeforeLazyLoadTrigger < yMax)
        this.$emit('setOffset');

      // TODO: Delete paginationData sections below after lazyload implementation
      if (!wrapper) return;

      // Calculate the distances from the bottom, top, right, and left of the scrollable area
      const scrollBottom = wrapper.scrollHeight - wrapper.scrollTop - wrapper.clientHeight;
      const scrollRight = wrapper.scrollWidth - wrapper.scrollLeft - wrapper.clientWidth;

      // Set a threshold for how close to the edges the user must be
      const threshold = 10; // pixels from the edges

      // Check if the user is near the edges of the table
      const isNearBottom = scrollBottom <= threshold;
      const isNearTop = wrapper.scrollTop <= threshold;
      const isNearRight = scrollRight <= threshold;
      const isNearLeft = wrapper.scrollLeft <= threshold;

      // Update pagination buttons based on the scroll position
      this.paginationData.bottom.show = isNearBottom;
      this.paginationData.top.show = isNearTop;
      this.paginationData.right.show = isNearRight;
      this.paginationData.left.show = isNearLeft;

      this.xPosition = xPosition;
      this.yPosition = yPosition;
    },
    hidePaginationButton(position) {
      switch (position) {
      case 'right':
        this.paginationData.right.show = false;
        break;
      case 'bottom':
        this.paginationData.bottom.show = false;
        break;
      case 'left':
        this.paginationData.left.show = false;
        break;
      case 'top':
        this.paginationData.top.show = false;
        break;
      default:
        break;
      }
    },
    setOffset(position) {
      this.$emit('setOffset', position);
    },
    isPaginationButtonAvailable(position) {
      switch (position) {
      case 'right':
        return this.xHeadings.length >= 100;
      case 'bottom':
        return this.yHeadings.length >= 100;
      case 'left':
        return this.offsetX > 0;
      case 'top':
        return this.offsetY > 0;
      default:
        break;
      }
    },
    getButtonLimit(position) {
      switch (position) {
      case 'right':
      case 'left':
        return this.limitX;
      case 'bottom':
      case 'top':
        return this.limit;
      default:
        return 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.table-container {
  --scrollbar-width: var(--spacing-1);

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    scrollbar-width: var(--spacing-2);
  }

  width: 100%;
  position: relative;

  table {
    width: 100%;
  }
}

.table-wrapper {
  overflow: auto;
  height: var(--table-height);
  width: 100%;
  position: relative;

  background:
    /* 'Hidden' left gradient */
    linear-gradient(
      to right,
      #fff 0%,
      transparent
    ),
    /* 'Hidden' right gradient */
    linear-gradient(
      to right,
      transparent,
      #fff 100%
    ) 0 100%,
    
    /* Left gradient */
    linear-gradient(
      to right,
      rgba(0, 0, 0, 0.3),
      transparent
    ),
    /* Right gradient */
    linear-gradient(
      to left,
      rgba(0, 0, 0, 0.3),
      transparent,
    ) 0 100%;  
  

    background-repeat: no-repeat;
    background-color: var(--colour-utility-white);

    background-size: 
      250px 100%, // Sets the width/height of the first 'hidden' gradient.
      250px 100%, // Sets the width/height of the second 'hidden' gradient.
      20px 100%,  // Sets the width/height of the left shading gradient.
      20px 100%;  // Sets the width/height of the right shading gradient.     
    
    background-position:
      0 0,   // Positions the first 'hidden' gradient horizonally/vertically.
      100%,  // Positions the second 'hidden' gradient at 100% of the width, effectively hiding it on the right side.
      0 0,   // Positions the left shading gradient horizonally/vertically
      right var(--scrollbar-width) bottom 0;   // Positions the right shading gradient at the rightmost edge of the table wrapper (taking into account scrollbar). 

    background-attachment:
      local, local,   // Sets the first two gradients to scroll with the content.
      scroll, scroll; // Sets the shading gradients to remain fixed as the content scrolls.
    
    transition: all 0.2s;
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    scrollbar-width: var(--scrollbar-width);

    &::-webkit-scrollbar {
      width: var(--scrollbar-width);
      padding: 0;
    }

    &::-webkit-scrollbar-track {
      border-radius: var(--border-radius-1);
      background-color: var(--colour-panel-g-16);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: var(--border-radius-1);
      background-color: var(--colour-panel-g-32);
    }
  }
}

.expanded {
  .table-container,
  .table-wrapper {
    height: 100%;
  }
  .footer-section {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.loading-spinner {
  position: absolute;
  z-index: 5;
  left: 0;
  bottom: var(--spacing-2);
  padding: var(--spacing-half);
  right: 0;
}
</style>
<style lang="scss">
.order-left-aligned {
  text-align: left;
}
</style>
