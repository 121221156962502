import { currentlyEditingGetters, currentlyEditingMutations, currentlyEditingActions } from './currentlyEditing';
import { deleteByIdMutations, deleteByIdActions } from './deleteById';
import { getByIdMutations, getByIdActions } from './getById';
import { getSummaryGetters, getSummaryMutations, getSummaryActions } from './getSummary';
import { getUsersGetters, getUsersMutations, getUsersActions } from './getUsers';
import { postMutations, postActions } from './post';
import { putByIdMutations, putByIdActions } from './putById';
import { restrictionsMutations, restrictionsActions } from './restrictions';

// initial state
const state = () => ({
  rep_codes: [],
  loading: false,
  loaded: false,
  restrictionsExist: false,
  all: [],
  currentlyEditing: {
    details: {},
    roles: [],
    restrictions: [],
    // restrictions: [{
    //   findId: 1,
    //   clause: 'or'
    // }, [{
    //   findId: 2,
    //   clause: 'and'
    // }, {
    //   findId: 3,
    //   entity: 'customer',
    //   id: 981,
    //   name: 'County',
    //   operator: 'equals',
    //   type: 'crm',
    //   value: 'West Midlands'
    // }, {
    //   findId: 4,
    //   entity: 'contact',
    //   id: 666,
    //   name: 'Surname',
    //   operator: 'equals',
    //   type: 'crm',
    //   value: 'Smith'
    // }, {
    //   findId: 5,
    //   entity: 'contact',
    //   id: 118,
    //   name: 'Post Code',
    //   operator: 'equals',
    //   type: 'crm',
    //   value: 'B98 0SB'
    // }], [{
    //   findId: 6,
    //   clause: 'and'
    // }, {
    //   findId: 7,
    //   entity: 'customer',
    //   id: 12345,
    //   name: 'County',
    //   operator: 'equals',
    //   type: 'crm',
    //   value: 'East Midlands'
    // }, {
    //   findId: 8,
    //   entity: 'contact',
    //   id: 666,
    //   name: 'Surname',
    //   operator: 'equals',
    //   type: 'crm',
    //   value: 'Smith'
    // }], [{
    //   findId: 9,
    //   clause: 'and'
    // }, {
    //   findId: 10,
    //   entity: 'contact',
    //   id: 666,
    //   name: 'Surname',
    //   operator: 'equals',
    //   type: 'crm',
    //   value: 'Smith'
    // }, {
    //   findId: 11,
    //   entity: 'contact',
    //   id: 118,
    //   name: 'Post Code',
    //   operator: 'equals',
    //   type: 'crm',
    //   value: 'B98 0SB'
    // }], [{
    //   findId: 12,
    //   clause: 'and'
    // }, {
    //   findId: 13,
    //   entity: 'contact',
    //   id: 666,
    //   name: 'Surname',
    //   operator: 'equals',
    //   type: 'crm',
    //   value: 'Smith'
    // }, {
    //   findId: 14,
    //   entity: 'customer',
    //   id: 12345,
    //   name: 'County',
    //   operator: 'equals',
    //   type: 'crm',
    //   value: 'East Midlands'
    // }, {
    //   findId: 15,
    //   entity: 'contact',
    //   id: 118,
    //   name: 'Post Code',
    //   operator: 'equals',
    //   type: 'crm',
    //   value: 'B98 0SB'
    // }], {
    //   findId: 16,
    //   entity: 'contact',
    //   id: 666,
    //   name: 'Surname',
    //   operator: 'equals',
    //   type: 'crm',
    //   value: 'Jackson'
    // }]
  },
  selected: {
    data: {},
    loading: false,
    loaded: false,
  },
  summary: {
    data: {},
    loaded: false,
    loading: false,
    error: undefined,
  },
  fields: {
    data: {},
    loading: false,
    loaded: false,
  }
});

// getters
const getters = {
  ...currentlyEditingGetters,
  ...getSummaryGetters,
  ...getUsersGetters
};

// mutations
const mutations = {
  ...currentlyEditingMutations,
  ...deleteByIdMutations,
  ...getByIdMutations,
  ...getSummaryMutations,
  ...getUsersMutations,
  ...postMutations,
  ...putByIdMutations,
  ...restrictionsMutations,
};

// actions
const actions = {
  ...currentlyEditingActions,
  ...deleteByIdActions,
  ...getByIdActions,
  ...getSummaryActions,
  ...getUsersActions,
  ...postActions,
  ...putByIdActions,
  ...restrictionsActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
