<template>
  <div class="intro-wrapper">
    <h2>{{ t('Name your alert') }}</h2>
    <p>{{ dateFilter }}</p>
  </div>

  <div class="container flow">
    <h3>Now give your alert a name and description</h3>
    <div class="grid">
      <Field
        id="alert-name"
        type="text"
        :value="alertName"
        :label="t('Alert name')"
        required
        @input="updateAlertName"
      />

      <Field
        id="promo-material"
        type="url"
        :external-error="promoMaterialError"
        :error-message="t('Please enter a valid link')"
        :placeholder="t('e.g. https://example.com/promo.pdf')"
        :value="promoMaterialLink"
        :label="t('Promo Material')"
        @input="updatePromoMaterialLink"
        @blur="checkPromoMaterialLink"
      />

      <Field
        id="description"
        type="textarea"
        :value="alertDescription"
        :label="t('Description')"
        @input="updatedAlertDescription"
      />
    </div>
  </div>
</template>

<script setup>
// Constants
import { ref, watchEffect } from 'vue';
import { t } from '@sales-i/utils';

// Components
import Field from '@/shared/components/Form/Field.vue';

defineProps({
  dateFilter: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['valid', 'updateAlertName', 'updatePromoMaterialLink']);

const valid = ref(false);
const alertName = ref('');
const alertDescription = ref('');
const promoMaterialLink = ref('');
const promoMaterialError = ref(false);

function validate() {
  // Check that both of the fields have a value. If they do, then set valid to true
  valid.value = alertName.value !== '' && promoMaterialError.value === false;
}

function updateAlertName(value) {
  alertName.value = value;
  emit('updateAlertName', alertName.value);
}

const updatePromoMaterialLink = (name) => {
  promoMaterialLink.value = name;
  emit('updatePromoMaterialLink', promoMaterialLink.value);
};

const checkPromoMaterialLink = () => {
  if (promoMaterialLink.value === '') {
    return promoMaterialError.value = false;
  }
  const linkRegexp = new RegExp('http[s]?:\/\/(www\.)?(.*)\.([a-z]{1,3})\.?([a-z]{1,3})?', 'i');
  const matches = linkRegexp.exec(promoMaterialLink.value);
  promoMaterialError.value = !linkRegexp.test(promoMaterialLink.value) || matches[2].length === 0;
};

function updatedAlertDescription(value) {
  alertDescription.value = value;
}

watchEffect(
  () => {
    validate();
    emit('valid', valid.value);
  },
);
</script>

<style lang="scss" scoped>
h3 {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-semibold);
}
</style>
