import {
  POST_BY_ENTITY,
  POST_BY_ENTITY_LOADING,
  POST_BY_ENTITY_ERROR
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const postMutations = {
  [POST_BY_ENTITY]: (state, data) => {
    state.selected.data = data;
    state.selected.loading = false;
    state.selected.loaded = true;
    state.selected.updated = true;
  },
  [POST_BY_ENTITY_LOADING]: (state) => {
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [POST_BY_ENTITY_ERROR]: (state) => {
    state.selected.loading = false;
    state.selected.loaded = false;
    state.selected.updated = false;
  },
};

// actions
export const postActions = {
  [POST_BY_ENTITY]: async ({ commit, }, params) => {
    const { id, payload, } = params;
    commit(POST_BY_ENTITY_LOADING);
    try {
      const data = await httpRequest('post', `posts/${id}/comments`, payload, {version: '2',});
      commit(POST_BY_ENTITY, {
        ...payload,
        ...data,
      });
    } catch (error) {
      commit(POST_BY_ENTITY_ERROR);
    }
  },
};
