<template>
  <div v-if="chartData?.length">
    <div>
      <h3 class="report-title">
        <slot name="title" />
      </h3>
    </div>
    <div class="chart-filters">
      <slot name="filters" />
    </div>
    <div
      id="sales-opportunity-report-chart-visualisation"
      ref="container"
    >
      <div
        :id="`chart-data-wrapper${chartId}`"
        class="chart-data-wrapper"
      >
        <svg
          :id="`chart-data${chartId}`"
          class="chart-data"
        />
        <div class="product-tooltip" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as d3 from 'd3';
import { abbr, currency, t } from '@sales-i/utils';
import { spacing } from '@/shared/constants/css/spacing';
import useCurrency from '@/shared/composables/useCurrency';

const colourSelection = [
  'var(--colour-data-turmeric)',
  'var(--colour-data-viking)',
  'var(--colour-data-de-york)',
  'var(--colour-data-deluge)',
  'var(--colour-data-jagged-ice)',
];

function getObjectColour(value, d) {
  const colour = colourSelection[d];
  if (colour !== undefined) {
    d3.select(this).attr('fill', colour);
  } else {
    d3.select(this).attr('fill', 'black');
  }
}

function getLabelColour(value, d) {
  return colourSelection[d].slice(0, -1) + '-label' || 'black';
}

function getColour(value, d) {
  return colourSelection[d] || 'black';
}

export default {
  name: 'BarChart',

  props: {
    chartId: {
      type: Number,
      required: true,
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      default: undefined,
    },
    filterValues: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    let { cs } = useCurrency();
    return { cs };
  },
  computed: {
    ...mapState({
      cs: state => state.userDetails.cs,
      loading: state => state.intelligence.performanceInsight.loading,
    }),
  },
  watch: {
    chartData() {
      this.generateChart(this.chartData, this.chartId);
    },
  },
  mounted() {
    this.generateChart(this.chartData, this.chartId);
  },
  methods: {
    t,
    formatPrice(value) {
      return currency.leftFormat(this.cs, abbr.float(value));
    },
    stackedBarChart(
      data,
      id,
      {
        width,
        height,
        zDomain,
        margin = {
          top: spacing[1] + spacing['half'],
          right: spacing[3],
          bottom: spacing[1] + spacing['half'],
          left: spacing[3],
        },
      } = {}
    ) {
      const container = document.getElementById('chart-data' + `${id}`);
      container.innerHTML = '';

      const stackedPrototype = [
        {
          type: 'product',
          data: Object.fromEntries(data.map((d, index) => [[d.name + index], d.percent_of_total])),
        },
      ];
      const stack = d3
        .stack()
        .keys(zDomain)
        .value((obj, key) => obj.data[key]);
      const stackedData = stack(stackedPrototype);
      const svg = d3
        .select('#chart-data' + `${id}`)
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .attr('viewBox', [0, 0, width + margin.left + margin.right, height + margin.top + margin.bottom])
        .attr('style', 'max-width: 100%; height: auto;')
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      let xAxisRange = 0;

      for (let i = 0; i < data.length; i++) {
        xAxisRange += data[i].percent_of_total;
      }
      const x = d3.scaleLinear().domain([0, xAxisRange]).range([margin.left, width]);
      const xAxisElement = svg.append('g').attr('transform', `translate(0, ${height})`).call(d3.axisBottom(x));
      xAxisElement.remove();

      const y = d3.scaleBand().domain(['percent_of_total']).range([height, 0]);
      const yAxis = d3.axisLeft().scale(y);

      const yAxisElement = svg
        .append('g')
        .attr('transform', `translate(${margin.left - 75}, 0)`)
        .call(yAxis);
      yAxisElement.remove();

      svg
        .append('g') // Chart tube
        .attr('transform', `translate(3, ${height / 2 - 5})`)
        .attr('class', 'bar-wrapper')
        .attr('stroke', '#D5D5D5')
        .attr('fill', 'var(--colour-utility-white)')
        .attr('stroke-width', 1)
        .insert('rect')
        .attr('width', width - margin.left + 15)
        .attr('height', 26)
        .attr('rx', 15)
        .attr('ry', 15);
      svg
        .append('g')
        .attr('transform', `translate(-10, ${height / 2})`)
        .selectAll('g')
        .data(stackedData)
        .join('g')
        .each(getObjectColour)
        .selectAll('rect')
        .data(d => d)
        .join('rect')
        .attr('y', () => y('product'))
        .attr('x', d => x(d[0]))
        .attr('rx', 8)
        .attr('ry', 8)
        .attr('height', 16)
        .attr('width', d => {
          return Math.abs(x(d[0]) - x(d[1]));
        });

      //  Limit long names to 8 letters
      const getProductName = (d, truncate) => {
        const product = data.find(o => o.name === d.key.slice(0, -1));
        if (product !== undefined) {
          const product_name = product.name;
          if (truncate && product_name.length > 6) {
            return `${product_name.substr(0, 8)}...`;
          }
          return product_name;
        }
        return d.key.slice(0, -1);
      };

      const tooltip = d3.select(`#chart-data-wrapper${id} .product-tooltip`);

      function labelMouseOverHandler(e, d) {
        tooltip
          .style('display', 'block')
          .style('opacity', 1)
          .style('left', e.target.getBoundingClientRect().x + 'px')
          .style('top', e.target.getBoundingClientRect().y + 'px')
          .html(getProductName(d));
      }

      let containerHeightToExpand = 0;
      const labelsYPositions = [];
      const labelsXPositions = [];
      svg
        .append('g')
        .attr('class', 'labels')
        .selectAll('g')
        .data(stackedData)
        .join('g')
        .attr('class', ({ key }) => `product product-${key}`)
        .attr('transform', (d, i) => {
          let translateY = i % 2 === 0 ? -20 : 0;
          const [x1, x2] = d[0];
          const midPoint = (x(x1) + x(x2) - margin.left) / 2;
          if (i < 2) {
            labelsYPositions.push(translateY);
            labelsXPositions.push(midPoint);
            return `translate(0, ${translateY})`;
          }

          labelsXPositions.push(midPoint);
          // check if right edge of previous chip in row is not overlapping current chip
          if (this.isChipOverlapped(midPoint, labelsXPositions, i)) {
            // move label on Y axis depends on position of previous chip in row
            translateY = i % 2 === 0 ? labelsYPositions[i - 2] + 100 : labelsYPositions[i - 2] - 100;
            containerHeightToExpand += 100;
          }
          labelsYPositions.push(translateY);
          return `translate(0, ${translateY})`;
        })
        .append('text')
        .text(d => getProductName(d, true))
        .attr('x', d => {
          const [x1, x2] = d[0];
          const midPoint = (x(x1) + x(x2) - margin.left) / 2;
          return midPoint;
        }) //bottom rep name     //top rep name
        .attr('y', (d, i) => (i % 2 === 0 ? height / 2 + 75 : height / 2 - 80))
        .attr('text-anchor', 'middle')
        .attr('class', 'product-name')
        .attr('data-name', d => getProductName(d, false))
        .on('mouseover', labelMouseOverHandler)
        .on('mouseout', () => tooltip.style('display', 'none'));

      // Adds chips to chart
      svg
        .selectAll('g.product')
        .data(stackedData)
        .append('rect')
        .attr('transform', 'translate(-30, 0)')
        .each(getObjectColour)
        .attr('width', 60)
        .attr('height', 24)
        .attr('rx', 12)
        .attr('ry', 12)
        .attr('x', d => {
          const [x1, x2] = d[0];
          const midPoint = (x(x1) + x(x2) - margin.left) / 2;
          return midPoint;
        }) //bottom chip           //top chip
        .attr('y', (d, i) => (i % 2 === 0 ? height / 2 + 85 : height / 2 - 70));

      // Adds value of small chip
      svg
        .selectAll('g.product')
        .data(stackedData)
        .append('text')
        .attr('class', 'sales-value-chip')
        .attr('x', d => {
          const [x1, x2] = d[0];
          const midPoint = (x(x1) + x(x2) - margin.left) / 2;
          return midPoint;
        }) //bottom chip value     //top chip value
        .attr('y', (d, i) => (i % 2 === 0 ? height / 2 + 102 : height / 2 - 54))
        .attr('text-anchor', 'middle')
        .text((d, i) => {
          const product = data[i];
          return currency.price(product.potential_value, this.cs);
        });

      // Percentage value
      svg
        .selectAll('g.product')
        .data(stackedData)
        .append('text')
        .attr('class', 'percentage-share-chip')
        .attr('fill', (d, i) => getLabelColour(d, i))
        .attr('x', d => {
          const [x1, x2] = d[0];
          const midPoint = (x(x1) + x(x2) - margin.left) / 2;
          return midPoint;
        }) //bottom percent         //top percent
        .attr('y', (d, i) => (i % 2 === 0 ? height / 2 + 123 : height / 2 - 30))
        .attr('text-anchor', 'middle')
        .text((d, i) => {
          const product = data[i];
          return `${product.percent_of_total}%`;
        });

      const linesYPositions = [];
      const linesXPositions = [];
      const linesY1PointPositions = [];
      // Adds lines from chart to chips
      svg
        .append('g')
        .attr('class', 'lines')
        .selectAll('g')
        .data(stackedData)
        .join('g')
        .append('line')
        .attr('stroke', (d, i) => getColour(d, i))
        .attr('stroke-width', 1.5)
        .attr('x1', d => {
          const [x1, x2] = d[0];
          return (x(x1) + x(x2) - margin.left) / 2;
        })
        .attr('y1', (d, i) => {
          const y1 = height / 2;
          if (i < 2) {
            linesY1PointPositions.push(y1);
            return y1;
          }
          const [x1, x2] = d[0];
          const midPoint = (x(x1) + x(x2) - margin.left) / 2;
          // check if right edge of previous chip in row is not overlapping current chip and if it is not same value as previous chip in row
          if (
            this.isChipOverlapped(midPoint, labelsXPositions, i) &&
            data[i].percent_of_total !== data[i - 2].percent_of_total
          ) {
            // expand line so it reach bar after moving chip up/down
            const positionY1 = i % 2 === 0 ? linesY1PointPositions[i - 2] - 100 : linesY1PointPositions[i - 2] + 100;
            linesY1PointPositions.push(positionY1);
            return positionY1;
          } else {
            linesY1PointPositions.push(y1);
            return y1;
          }
        })
        .attr('x2', d => {
          const [x1, x2] = d[0];
          return (x(x1) + x(x2) - margin.left) / 2;
        })
        .attr('y2', (d, i) => {
          //bottom lines length   //top lines length
          return i % 2 === 0 ? height / 2 + 40 : height / 2 - 24;
        })
        .attr('transform', (d, i) => {
          let translateY = 0;
          const [x1, x2] = d[0];
          const midPoint = (x(x1) + x(x2) - margin.left) / 2;
          if (i < 2) {
            linesYPositions.push(translateY);
            linesXPositions.push(midPoint);
            return `translate(0, ${translateY})`;
          }

          linesXPositions.push(midPoint);
          // check if right edge of previous chip in row is not overlapping current chip
          if (this.isChipOverlapped(midPoint, linesXPositions, i)) {
            // move label on Y axis depends on position of previous chip line in row
            translateY = i % 2 === 0 ? linesYPositions[i - 2] + 100 : linesYPositions[i - 2] - 100;
          }
          linesYPositions.push(translateY);
          return `translate(0, ${translateY})`;
        });

      if (containerHeightToExpand)
        d3.select('#chart-data' + `${id}`).attr(
          'height',
          height + margin.top + margin.bottom + containerHeightToExpand + 40
        );
    },
    generateChart(data, id) {
      let salesName = data.map((o, i) => o.name + i) || [];
      const width = document.getElementById('chart-data-wrapper' + `${id}`).clientWidth - 20;
      const height = 150;
      this.stackedBarChart(data, id, {
        zDomain: salesName,
        width,
        height: height + 100,
      });
    },
    isChipOverlapped(midPoint, arrayToCheck, index) {
      const maxLabelWidth = 70;
      return midPoint - maxLabelWidth / 2 <= arrayToCheck[index - 2] + maxLabelWidth / 2;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.report-title {
  text-align: center;
  margin: var(--spacing-1) 0;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin: var(--spacing-3) 0 0;
    padding-top: var(--spacing-3);
  }
}
</style>

<style lang="scss">
#sales-opportunity-report-chart-visualisation {
  .chart-data-wrapper {
    position: relative;

    svg {
      .tick-percent {
        text-anchor: middle;
        color: var(--colour-utility-white);
        font-weight: var(--font-weight-semibold);
      }
    }
  }

  .product-tooltip {
    position: fixed;
    opacity: 0;
    padding: var(--spacing-half) var(--spacing-1);
    border-radius: var(--spacing-2);
    text-align: center;
    color: var(--colour-utility-white);
    font-family: var(--font-family-primary);
    font-size: var(--font-size-small);
    letter-spacing: 0;
    line-height: var(--spacing-2);
    background-color: var(--colour-utility-black);
    width: auto;
    z-index: 10;
    font-weight: var(--font-weight-semibold);
    pointer-events: none;
  }

  .chart-data {
    .bar-wrapper {
      filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4));
    }

    .product-name {
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-small);
      fill: var(--colour-utility-checked);
    }

    .sales-value-chip {
      fill: var(--colour-utility-white);
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-small);
    }

    .percentage-share-chip {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-regular);
    }
  }
}
.chart-filters {
  margin: var(--spacing-3) 0 0 var(--spacing-4);
}
</style>
