<template>
  <article>
    <CustomCard
      purpose="reversed"
      footer-color="grey"
      class="interaction-card"
    >
      <header>
        <h2>{{ t('Find opportunities') }}</h2>
        <p>{{ t('sales intelligence to help you sell more') }}</p>
      </header>
      <div class="insight-wrapper">
        <div
          v-for="link in insightLinks"
          :key="link.name"
          class="insight-link"
        >
          <CustomButton
            :style="{ color: `var(${link.color})` }"
            purpose="text"
            @click="goToInsights(link.insight)"
          >
            <IconBase
              :icon-name="link.icon"
              :icon-color="link.color"
              :height="40"
              :width="40"
            />
            {{ link.name }} {{ t('Insights') }}
          </CustomButton>
        </div>
      </div>
      <template #footer>
        <ButtonGroup>
          <CustomButton
            small
            purpose="action"
            @click="goToInsights('')"
          >
            {{ t('Go To Insights') }}
          </CustomButton>
        </ButtonGroup>
      </template>
    </CustomCard>
  </article>
</template>

<script setup>
import { t } from '@sales-i/utils';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { IconBase, CustomButton, CustomCard } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { baseUrl as intelligenceUrl, insightsScope } from '@/intelligence/router/urlBits';
import { REPORT_AREA_CUSTOMER, REPORT_AREA_PEOPLE, REPORT_AREA_PERFORMANCE, REPORT_AREA_PRODUCT } from '@/intelligence/store/data/areas';
import usePermissions from '@/shared/composables/usePermissions';
import { customers } from '@/shared/store/data/policies';
import { navigateToUrl } from 'single-spa';

const store = useStore();
const isDemo = computed(() => store.getters['system/isDemo']);

const { hasAccessToPerformanceInsights, hasAccessToProductInsights, hasAccessToCustomerInsights } = usePermissions();

// Permissions
const { hasAccess } = usePermissions();
const canAccessCustomers = computed(() => hasAccess(customers) && hasAccessToCustomerInsights.value);

// Generate the list of insight links.
const insightLinks = computed(() => {
  let links = [];

  if (isDemo.value) {
    links.push({
      name: REPORT_AREA_PEOPLE,
      icon: 'dimensions',
      color: '--colour-data-puerto-rico-dark',
      insight: REPORT_AREA_PEOPLE,
    });
  }

  if (!isDemo.value && hasAccessToPerformanceInsights.value) {
    links.push({
      name: REPORT_AREA_PERFORMANCE,
      icon: 'dimensions',
      color: '--colour-data-puerto-rico-dark',
      insight: REPORT_AREA_PERFORMANCE,
    });
  }
  if (hasAccessToProductInsights.value) {
    links.push({
      name: REPORT_AREA_PRODUCT,
      icon: 'product',
      color: '--colour-data-viking-label',
      insight: REPORT_AREA_PRODUCT,
    });
  }
  if (canAccessCustomers.value) {
    links.push({
      name: REPORT_AREA_CUSTOMER,
      icon: 'user-circle',
      color: '--colour-data-turmeric-label',
      insight: REPORT_AREA_CUSTOMER,
    });
  }
  return links;
});

function goToInsights(insight) {
  navigateToUrl(`${intelligenceUrl}/${insightsScope}/${insight || REPORT_AREA_PERFORMANCE}`);
}
</script>

<style lang="scss" scoped>
.insight-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;

  .insight-link {
    font-size: var(--font-size-3);
    font-weight: var(--font-weight-semibold);
    margin-top: var(--spacing-4);

    :deep(.button-container) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

:deep(.interaction-card) > .c-card--body {
  min-height: 600px;
}
</style>
