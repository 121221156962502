<template>
  <CustomCard
    purpose="reversed"
    footer-color="grey"
    class="insights-wrapper"
  >
    <BufferImage
      v-if="loading"
      class="loading-spinner"
      color="var(--colour-utility-black)"
      float="center"
    />
    <template v-if="!loading">
      <header>
        <h2>
          {{ title }}
        </h2>
        <p v-if="isMobile">
          {{ t('View on') }}&nbsp;
          <CustomButton
            href="#"
            @click="openInteractionsMap"
          >
            {{ t('Map').toLowerCase() }}
          </CustomButton>
        </p>
        <p v-else-if="numberOfInteractions && canAccessInsights">
          {{ t('Sell smart with') }}
          <a
            :href="insightsLink"
            @click.prevent="navigateToUrl(insightsLink)"
          >
            {{ t('insights') }}
          </a>
        </p>
      </header>

      <section
        v-if="!numberOfInteractions"
        class="no-data-wrapper"
      >
        <h3>{{ t("Let's get you selling") }}.</h3>
        <p>
          {{ t('Set up interactions with your customers and prospects') }}.
          <a
            :href="interactionsLink"
            @click.prevent="navigateToUrl(interactionsLink)"
          >
            {{ t('Go to interactions') }}
          </a>
          {{ t('and start filling your calendar') }}.
        </p>
      </section>
      <CRMCard
        v-for="(item, index) in editedInteractions"
        :key="index"
        class="insights"
        :currency="currency"
        :chips="getChips(item)"
        :title="getTitle(item)"
        :icon-name="iconName(item)"
        icon-position="top"
        :links="getLinks(item)"
        :on-click="() => openInteraction(item)"
        @edit="editInteraction(item)"
        @snapshot="openSnapshot(item)"
      >
        <template #info>
          {{ item.contact }}<br>
          {{ t('Today') }}: {{ formatDate(item.date_start, 'timeSimple') }}
        </template>
      </CRMCard>
    </template>
    <template #footer>
      <ButtonGroup>
        <CustomButton
          v-if="numberOfInteractions && canAccessInsights"
          small
          purpose="action"
          @click="goToSnapshotSummary"
        >
          {{ t("Get today's snapshots") }}
        </CustomButton>
        <CustomButton
          v-if="(!numberOfInteractions || !canAccessInsights) && canCreateInteractions"
          small
          purpose="action"
          @click="goToNewInteraction"
        >
          {{ t('Add new interaction') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomCard>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { replaceSpaceWithNBSP } from '@/shared/utils/replaceSpaceWithNBSP';
import { BufferImage, CRMCard, CustomCard, CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { baseUrl as intelligenceUrl, insightsScope } from '@/intelligence/router/urlBits';
import { baseUrl as crmUrl, interactionsArea } from '@/crm/router/urlBits';
import { interactions, intelligence_insights } from '@/shared/store/data/policies';
import { SNAPSHOT_SUMMARY } from '@/intelligence/store/data/reportTypes';
import useCurrency from '@/shared/composables/useCurrency';
import { openInteractionsMap } from '@/shared/utils/mobile';
import { dates, pluralise, t } from '@sales-i/utils';
import usePermissions from '@/shared/composables/usePermissions';
import { navigateToUrl } from 'single-spa';
import { useInteraction } from '@/crm/composables/useInteraction';

const store = useStore();
const vrouter = useRouter();
const { cs, currency } = useCurrency();
const { hasAccess } = usePermissions();

const loading = computed(() => store.state.crm.interactions.loading);
const isMobile = computed(() => store.state.platform.data === 'mobile');

const { openInteractions, openInteraction, editInteraction } = useInteraction({ store });
const title = computed(() => {
  const text = pluralise.number(numberOfInteractions.value, 'Interaction', {
    includeNumber: true,
  });
  return t(`${text} today`, 'text_today', {
    interpolations: { text },
  });
});
const canAccessInsights = computed(() => hasAccess(intelligence_insights, 'read'));
const canCreateInteractions = computed(() => hasAccess(interactions, 'create'));
const editedInteractions = computed(() => {
  const mappedInteractions = openInteractions.value.map(item => {
    return item.is_personal
      ? {
        ...item,
        contact: '',
        // Defaults to 'personal' to allow personal interactions to be ordered alphabetically
        // when the 'customer/prospect' filter is chosen.
        // Will be overwritten by the customer/prospect name or 'No customer name' string.
        customer: t('Personal Interaction'),
        prospect: '',
        start: item.start ? item.start : t('No date'),
      }
      : {
        ...item,
        contact:
            item.relationships.contacts.length > 0
              ? item.relationships.contacts[0].full_name
              : t('No contact name'),
        customer:
            item.relationships.customers.length > 0
              ? item.relationships.customers.map(customer => customer.name)
              : t('No customer name'),
        prospect:
            item.relationships.prospects.length > 0
              ? item.relationships.prospects.map(prospect => prospect.name)
              : t('No prospect name'),
        start: item.start ? item.start : t('No date'),
        opportunity:
            item.relationships.opportunities.length !== ''
              ? item.relationships.opportunities.map(opportunity => opportunity.value)
              : '',
      };
  });
  return mappedInteractions;
});
const numberOfInteractions = computed(() => editedInteractions.value.length);
const insightsLink = computed(() => `${intelligenceUrl}/${insightsScope}`);
const interactionsLink = computed(() => `${crmUrl}/${interactionsArea}`);
const formatDate = dates.format;

function getChips(item) {
  if (item.opportunity > 0) {
    return [
      {
        value: item.opportunity,
        prefix: cs,
        abbr: true,
        green: 150000,
        amber: 75000,
      },
    ];
  }
}

function getLinks(item) {
  let links = [
    {
      text: t('Edit'),
      id: item.id,
      action: 'edit',
    },
  ];
  if (!item.is_personal && !item.relationships.prospects.length > 0 && canAccessInsights.value) {
    links.push( {
      text: t('Snapshot'),
      id: item.id,
    });
  }
  return links;
}

function iconName(item) {
  if (item.interaction_type === 312) {
    return 'phone';
  } else if (item.interaction_type === 313) {
    return 'user-circle';
  } else if (item.interaction_type === 314) {
    return 'email';
  } else {
    return 'user';
  }
}

function openSnapshot(item) {
  const route = vrouter.resolve({
    name: SNAPSHOT_SUMMARY,
    query: { customer: item.customer[0] },
  });
  navigateToUrl(route.href);
}

function goToSnapshotSummary() {
  const route = vrouter.resolve({ name: SNAPSHOT_SUMMARY });
  navigateToUrl(route.href);
}

function goToNewInteraction() {
  navigateToUrl(`${crmUrl}/${interactionsArea}/add`);
}

function getTitle(item) {
  return replaceSpaceWithNBSP(Array.isArray(item.prospect) ? item.prospect[0] : item.customer[0]);
}


</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
:deep(.c-card--body .flow) {
  display: flex;
  flex-direction: column;
  flex: initial;
}

:deep(.c-card.insights) {
  border-bottom: 2px solid var(--colour-panel-g-8);
  border-radius: 0;
  padding: 0 var(--spacing-2) var(--spacing-2);
}

.insights {
  margin-top: var(--spacing-4);

  :deep(.title-chip-container > .icon-container.top) {
    margin-right: var(--spacing-half);
    width: auto;
  }

  :deep(svg),
  :deep(.svg-container) {
    width: 20px !important;
    height: 20px !important;
  }

  :deep(.card-title) {
    line-height: 1.4;
  }

  :deep(.card-info) {
    margin-left: var(--spacing-3);
  }

  :deep(.links) {
    gap: var(--spacing-1);
  }
}

:deep(.c-card.insights:last-child) {
  border-bottom: none;
}

.insights-wrapper {
  position: relative;
}

.no-data-wrapper {
  display: grid;
  place-content: center;
  justify-items: center;
  margin: var(--spacing-4);
  height: 100%;

  h3 {
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--spacing-1);
  }

  a {
    font-weight: var(--font-weight-semibold);
  }
}

.loading-spinner {
  position: absolute;
  top: calc(50% - 50px);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}
</style>
