<template>
  <div class="show-gaps-container">
    <CustomCheckbox
      :label="t('Hide results with a 0 total')"
      :value="!showGaps"
      @input="handleShowGapsChange"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { t } from '@sales-i/utils';
import { CustomCheckbox } from '@sales-i/dsv3';
import { SET_SHOW_GAPS, REFRESH_REPORT } from '@/intelligence/store/actionType';

const store = useStore();

const showGaps = computed(() => store.state.intelligence.shared.requestParameters.showGaps);

const setShowGaps = showGaps => store.dispatch(`intelligence/enquiry/${SET_SHOW_GAPS}`, showGaps);
const refreshReport = () => store.dispatch(`intelligence/shared/${REFRESH_REPORT}`);

const handleShowGapsChange = value => {
  setShowGaps(!value);
  refreshReport();
};
</script>

<style lang="scss" scoped>
.show-gaps-container {
  padding: var(--spacing-2);
}
</style>