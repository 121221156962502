<template>
  <div
    id="modal-teleport-target"
    class="modal-teleport-target"
  />
</template>

<script>
export default {
  name: 'ModalTeleportTarget',
};
</script>

<style lang="scss">
@import '@/shared/assets/scss/_variables';
// This handles case where we have two modals opened at once,
// so we show only the one which is the last element in the container
.modal-teleport-target {
  & > div:not(:last-child) {
    display: none;
  }
}
</style>
