<template>
  <div>
    <CustomModal
      show-modal
      :max-width="1000"
      @close-modal="closeModal"
    >
      <template #title>
        <h1 class="fw-sbld text-2">
          {{ t('Confirm consolidation') }}
        </h1>
      </template>

      <main class="flow">
        <div class="confirmation">
          <div class="confirmation__original-tags">
            <CustomCard purpose="basic">
              <h2 class="fw-sbld text-3">
                {{
                  t(`Consolidate ${pluralise.number(tagCount, 'tag', { includeNumber: true })}`, `consolidate_count`, {
                    interpolations: {
                      count: pluralise.number(tagCount, 'tag', { includeNumber: true }),
                    },
                  })
                }}
              </h2>
              <TagChip
                v-for="item in originalTagList"
                :key="item"
                :show-action="tagCount > 1"
                @click="removeTag(item)"
              >
                <span>{{ item }}</span>
              </TagChip>
            </CustomCard>
          </div>
          <div class="confirmation__arrow">
            <IconBase
              icon-name="arrow-right"
              icon-color="var(--colour-utility-black)"
              :height="48"
              :width="48"
            />
          </div>
          <div class="confirmation__into">
            <CustomCard purpose="alert">
              <h2 class="fw-sbld text-3">
                {{ t('Into') }}
              </h2>
              <TagChip :show-action="false">
                <span>{{ toTagItem }}</span>
              </TagChip>
            </CustomCard>
          </div>
        </div>
        <p
          v-if="isDuplicateTag"
          class="error"
        >
          {{ t("You're trying to consolidate a tag into itself.") }}
          <template v-if="tagCount === 1">
            {{ t('Please cancel this consolidation and enter a different tag.') }}
          </template>
          <template v-else>
            {{ t('Please delete it from the first column to continue') }}
          </template>
        </p>
      </main>
      <template #footer>
        <CustomButton
          small
          @click="closeModal"
        >
          {{ t('Cancel') }}
        </CustomButton>
        <CustomButton
          small
          :disabled="!valid"
          @click="confirm"
        >
          {{ t('Yep, do it') }}
        </CustomButton>
      </template>
    </CustomModal>
  </div>
</template>
<script setup>
// TagConsolidationConfirmation
import { pluralise, t } from '@sales-i/utils';
import { computed } from 'vue';
import { CustomCard, CustomModal, CustomButton, IconBase, TagChip } from '@sales-i/dsv3';
import { useStore } from 'vuex';
import { PUT_TAG_CONSOLIDATION, DELETE_TAG_CONSOLIDATION } from '@/admin/store/actionType';
import { SET_CONFIRMATION_MODAL } from '@/shared/store/actionType';
import { baseUrl, companyArea, tagConsolidationSection, tagsSection } from '@/admin/router/urlBits';
import { navigateToUrl } from 'single-spa';

const store = useStore();

const emit = defineEmits(['closeModal']);
const originalTagList = computed(() => store.state.admin.tags.consolidateTags.original_tag);
const toTagItem = computed(() => store.state.admin.tags.consolidateTags.to_tag);

const tagCount = computed(() => {
  return originalTagList.value.length;
});

const isDuplicateTag = computed(() => {
  // Check if originalTagList and toTagItem contain any of the same items
  return originalTagList.value.some(tag => tag === toTagItem.value);
});

const valid = computed(() => !isDuplicateTag.value);

const deleteTag = params => store.dispatch(`admin/tags/${DELETE_TAG_CONSOLIDATION}`, params);
const putConsolidateTags = params => store.dispatch(`admin/tags/${PUT_TAG_CONSOLIDATION}`, params);
const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);

function closeModal() {
  emit('closeModal');
}

async function confirm() {
  const success = await putConsolidateTags({
    original_tag: originalTagList.value,
    to_tag: toTagItem.value,
  });
  if (success) {
    showConfirmationModal({
      heading: t('Tags successfully consolidated'),
      saveLabel: t('Consolidate more tags'),
      cancelLabel: '',
      message: '',
      iconName: 'check',
      iconColor: 'var(--colour-data-de-york)',
      closeOnUpdate: true,
      updateFunction: () => true,
      closeFunction: () => {
        closeModal();
        navigateToUrl(`${baseUrl}/${companyArea}/${tagsSection}/${tagConsolidationSection}`);
      },
    });
  } else closeModal();
}

function removeTag(item) {
  deleteTag(originalTagList.value.indexOf(item));
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
main {
  padding-right: var(--spacing-3);
  padding-left: var(--spacing-3);
  margin-bottom: var(--spacing-3);
}

.confirmation {
  display: flex;
  gap: var(--spacing-2);

  &__original-tags,
  &__into {
    flex: 0 0 46%;
  }

  &__arrow {
    align-self: center;
    flex: 1 1 auto;
  }
}

.error {
  color: var(--colour-utility-error);
}

:deep(.footer) {
  display: flex;
  gap: var(--spacing-2);
}

:deep(.tag) {
  background-color: var(--colour-utility-white);
}
</style>
