import { computed } from 'vue';
import { t } from '@sales-i/utils';
import {
  CLEAR_ALL,
  DELETE_BY_ID,
  GET_ALL,
  SET_CONFIRMATION_MODAL,
  SET_ROUTE,
} from '@/shared/store/actionType';
import { useRoute } from 'vue-router';
import { baseUrl, opportunitiesArea } from '@/crm/router/urlBits';
import { useStore } from 'vuex';
import { scrollIntoView } from '@/shared/directives';
import { opportunities as opps } from '@/shared/store/data/policies';
import usePermissions from '@/shared/composables/usePermissions';
import { navigateToUrl } from 'single-spa';

export const useOpportunity = ({ store = useStore(), emit, vroute = useRoute()} = {}) => {
  const { getPermissions } = usePermissions();
  const permissions = getPermissions(opps);
  const opportunities = computed(() => store.state.crm.opportunities.all);
  const selected = computed(() => store.state.crm.opportunities.selected.data);
  const selectedLoaded = computed(() => store.state.crm.opportunities.selected.loaded);
  const setRoute = (params) => store.dispatch(`system/${SET_ROUTE}`, params);
  const getOpportunities = (params) => store.dispatch(`crm/opportunities/${GET_ALL}`, params);
  const clearOpportunities = () => store.dispatch(`crm/opportunities/${CLEAR_ALL}`);
  const getOpportunitiesWithProducts = (params) => store.dispatch(`crm/opportunityProducts/${GET_ALL}`, params);
  const clearOpportunitiesWithProducts = () => store.dispatch(`crm/opportunityProducts/${CLEAR_ALL}`);
  const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);
  const deleteOpportunity = params => store.dispatch(`crm/opportunities/${DELETE_BY_ID}`, params);
    
  function showDeleteOpportunityModal(item) {
    showConfirmationModal({
      message: t(
        `Do you really want to delete Opportunity #${item.id}`,
        'do_you_really_want_to_delete_opportunity_id',
        { interpolations: {id: item.id}}
      ),
      updatedMessage: t(
        `You have deleted Opportunity #${item.id}`,
        'you_have_deleted_opportunity_id',
        { interpolations: {id: item.id} }
      ),
      updateFunction: () => confirmDelete(item),
      finishFunction: finishDelete,
    });
  }

  async function confirmDelete(item) {
    try {
      await deleteOpportunity(item.id);
      emit('opportunityDeleted', item);
      return selectedLoaded.value;
    }
    catch(e) {
      console.error(e);
      return false;
    }
  }

  function finishDelete() {
    emit('closeModal');
    return true;
  }

  function openOpportunityDetails(item, openInNewTab=false) {
    if (openInNewTab) {
      let path = `${baseUrl}/${opportunitiesArea}/${item.id}`;
      let query = new URLSearchParams(vroute.query);
      window.open(`${path}?${query.toString()}`, '_blank');
    } else {
      navigateToUrl(`${baseUrl}/${opportunitiesArea}/${item.id}`);
      scrollIntoView();
    }
  }

  function editOpportunity(
    item, 
    doSetRoute = false, 
    success = `${baseUrl}/${opportunitiesArea}/${item.id}`, 
    cancel = vroute.fullPath
  ) 
  {
    if (doSetRoute) setRoute({ success, cancel });
    navigateToUrl(`${baseUrl}/${opportunitiesArea}/${item.id}/edit`);
  }

  return {
    permissions,
    selected,
    opportunities,
    selectedLoaded,
    showConfirmationModal,
    deleteOpportunity,
    showDeleteOpportunityModal,
    openOpportunityDetails,
    editOpportunity,
    confirmDelete,
    finishDelete,
    getOpportunities,
    clearOpportunities,
    getOpportunitiesWithProducts,
    clearOpportunitiesWithProducts
  };
};
