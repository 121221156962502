<template>
  <div>
    <InteractionTimescaleForm
      is-generate
      :date-time="currentlyEditing.dateTime"
      :schedule="currentlyEditing.schedule"
      :start-hours="startHours"
      :start-minutes="startMinutes"
      :duration-hours="durationHours"
      :duration-minutes="durationMinutes"
      @set-date="setDate"
    />

    <div class="form-actions">
      <CustomButton
        v-scroll-to-top
        purpose="action"
        :disabled="!valid"
        @on-click="nextStep"
      >
        {{ t('Continue') }}
      </CustomButton>
    </div>
  </div>
</template>
<script>
import { CustomButton } from '@sales-i/dsv3';
import InteractionTimescaleForm from '@/crm/components/Calendar/InteractionTimescaleForm.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { CLEAR_GENERATE_INTERACTIONS, SET_DATE, SET_GENERATE_INTERACTIONS_REQUEST } from '@/crm/store/actionType';
import { dates, t } from '@sales-i/utils';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'GenerateInteractionTimescale',
  components: {
    CustomButton,
    InteractionTimescaleForm,
  },
  props: {
    entityType: {
      type: String,
      required: true,
    },
  },
  emits: ['stageUpdate'],
  computed: {
    ...mapState({
      currentlyEditing: state => state.crm.interactions.currentlyEditing,
    }),
    ...mapGetters({
      startHours: 'crm/interactions/startHours',
      startMinutes: 'crm/interactions/startMinutes',
      durationHours: 'crm/interactions/durationHours',
      durationMinutes: 'crm/interactions/durationMinutes',
    }),
    valid() {
      return this.currentlyEditing.dateTime;
    },
  },
  mounted() {
    // clear generate request values if date is not set yet
    if (!this.currentlyEditing.dateTime) {
      this.clearValues();
    }
    this.$emit('stageUpdate', 'Timescale');
  },
  methods: {
    t,
    ...mapActions({
      setDate: `crm/interactions/${SET_DATE}`,
      clearValues: `crm/interactions/${CLEAR_GENERATE_INTERACTIONS}`,
      saveValues: `crm/interactions/${SET_GENERATE_INTERACTIONS_REQUEST}`,
    }),
    nextStep() {
      if (this.currentlyEditing.dateTime) {
        this.saveValues({ date_start: dates.forApi(this.currentlyEditing.dateTime) });
        navigateToUrl(`${baseUrl}/${interactionsArea}/generate-${this.entityType}-interaction/settings`);
      }
    },
  },
};
</script>
