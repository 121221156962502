<template>
  <div
    class="selectable-item"
    :class="{ selected: isSelected }"
    role="checkbox"
    :aria-checked="isSelected"
    tabindex="0"
    @click="selected"
    @keyup.enter.prevent.stop="selected"
  >
    <slot />
    <div
      v-if="isSelected"
      class="check"
    >
      <span class="checkmark">
        <IconBase
          icon-name="check"
          height="32"
          width="32"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { IconBase } from '@sales-i/dsv3';
export default {
  name: 'SelectableItem',
  components: {
    IconBase,
  },
  props: {
    id: {
      type: Number,
      default: undefined,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['itemselected'],
  data() {
    return {};
  },
  methods: {
    selected() {
      this.$emit('itemselected');
    },
  },
};
</script>

<style lang="scss" scoped>
.selectable-item {
  position: relative;
  height: 100%;
}
// Apply the 'selected' style to the immediate child of the selected component but DON'T apply it to the checkmark
.selected {
  border: 2px solid var(--colour-utility-checked);
  border-radius: var(--border-radius-1);
  box-shadow: 0 0 0 4px var(--colour-utility-focus);
  
  :deep(.crm-card) {
    padding-left: var(--spacing-6);
  }
}

.check {
  position: absolute;
  top: calc(25% - var(--spacing-2));
  left: var(--spacing-1);
  z-index: 1;
}
</style>
