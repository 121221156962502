import {
  CLEAR_BY_ID,
  DELETE_BY_ID,
  DELETE_BY_ID_LOADING,
  DELETE_BY_ID_ERROR
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

// mutations
export const deleteByIdMutations = {
  [DELETE_BY_ID]: (state) => {
    state.selected.loaded = true;
    state.selected.loading = false;
    state.selected.error = undefined;
  },
  [DELETE_BY_ID_LOADING]: (state) => {
    state.selected.data = {};
    state.selected.loading = true;
    state.selected.loaded = false;
  },
  [DELETE_BY_ID_ERROR]: (state, error) => {
    state.selected.loading = false;
    state.selected.loaded = false; /** check what value should be if it's error */
    state.selected.error = error;
  },
  [CLEAR_BY_ID]: (state) => {
    state.selected.data = {};
    state.selected.loaded = false;
    state.selected.loading = false;
  },
};

// actions
export const deleteByIdActions = {
  [DELETE_BY_ID]: async ({ commit, }, payload) => {
    try {
      commit(DELETE_BY_ID_LOADING);
      await httpRequest('delete', `opportunities/${payload.id}/with-products`, payload.products, { version: '2', });
      commit(DELETE_BY_ID);
      return true;
    } catch (error) {
      commit(DELETE_BY_ID_ERROR);
      return false;
    }
  },
  [CLEAR_BY_ID]: ({ commit, }) => {
    commit(CLEAR_BY_ID);
  },
};

export default {
  namespaced: true,
  deleteByIdActions,
  deleteByIdMutations,
};
