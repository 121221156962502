<template>
  <div>
    <EnquiryNav
      :report-type="reportType"
      :dates="dateParams"
      :active-area-prop="defaultReportArea"
      :is-date-filter-active="isDateFilterActive"
      hide-drill
      @toggle-date-filter="toggleDateFilter"
    />
    <div class="container">
      <div class="description">
        <i>{{ inputData.description }}</i>
      </div>
      <DatePickerView
        v-if="isDateFilterActive"
        :report-type="reportType"
        @set-active-date="setDate"
      />
      <CustomerActivityTable
        v-if="isReportEnabled && reportData.length"
        v-show="!isDateFilterActive"
        :key="reportKey"
        :report-data="reportData"
        :current-sort="currentSort"
        :sort-direction="sortDirection"
        @apply-new-sort="handleNewSort"
        @load-more-data="loadMoreData"
      />
      <NoResults v-if="isReportEnabled && !reportData.length" />
      <BufferImage
        v-if="!isReportEnabled"
        color="var(--colour-utility-black)"
        float="center"
        class="loading-spinner"
      />
      <ReportFooter
        v-if="isReportFooterVisible"
        v-show="!isDateFilterActive"
        :report-type="reportType"
        :request-params="dateParams"
      />
    </div>
  </div>
</template>

<script setup>
// CustomerActivityView
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import { CUSTOMER_ACTIVITY } from '@/intelligence/store/data/reportTypes';
import { REPORT_AREA_CUSTOMER } from '@/intelligence/store/data/areas';
import EnquiryNav from '@/intelligence/components/EnquiryReport/EnquiryNav/EnquiryNav';
import DatePickerView from '@/intelligence/components/Date/DatePickerView';
import CustomerActivityTable from '@/intelligence/components/CustomerActivity/CustomerActivityTable';
import ReportFooter from '@/intelligence/components/ReportFooter/ReportFooter';
import NoResults from '@/intelligence/components/Shared/NoResults';
import { BufferImage } from '@sales-i/dsv3';
import {
  CLEAR_REPORT_DATA,
  FETCH_DATES,
  APPLY_DATE_RANGE,
  FETCH_REPORT_DATA,
  APPLY_OFFSET,
  SET_SORTING_DATA,
  APPLY_LIMIT
} from '@/intelligence/store/actionType';
import isRolldate from '@/intelligence/store/utils/isRolldate';
import { REPORTS_SORT_ASCENDING_KEY, REPORTS_SORT_DESCENDING_KEY } from '@/intelligence/store/data/apiInput';

// setup
const store = useStore();
// data
const isDateFilterActive = ref(false);
const defaultReportArea = ref(REPORT_AREA_CUSTOMER);
const reportType = ref(CUSTOMER_ACTIVITY);
const isReportEnabled = ref(false);
const currentSort = ref('');
const sortDescending = ref(true);
const reportId = ref(1);
const lazyLoadingData = ref([]);
const dateParams = ref({
  date_from: 'today/m',
  date_to: 'today',
  date_from2: 'today/m-1y',
  date_to2: 'today-1y',
});
// computed
const inputData = computed(() => getInputData(reportType.value));
const reportData = computed(() => {
  const { customers } = getReportData(reportId.value) || {};
  return [ ...(customers || []), ...lazyLoadingData.value ];
});
const isReportFooterVisible = computed(() => isReportEnabled.value && !isDateFilterActive.value && reportData.value.length);
const sortDirection = computed(() => sortDescending.value ? REPORTS_SORT_DESCENDING_KEY : REPORTS_SORT_ASCENDING_KEY);
// store state
const reportKey = computed(() => store.state.intelligence.shared.reportRefreshCounter);
// store getters
const getReportData = id => store.getters['intelligence/shared/getReportData'](id);
const getInputData = reportType => store.getters['intelligence/shared/getInputData'](reportType);
// store actions
const fetchDates = params => store.dispatch(`intelligence/calendar/${FETCH_DATES}`, params);
const applyDates = params => store.dispatch(`intelligence/shared/${APPLY_DATE_RANGE}`, params);
const clearReportData = () => store.dispatch(`intelligence/shared/${CLEAR_REPORT_DATA}`);
const applySort = arr => store.dispatch(`intelligence/enquiry/${SET_SORTING_DATA}`, arr);
const fetchReportData = type => store.dispatch(`intelligence/shared/${FETCH_REPORT_DATA}`, type);
const applyOffset = value => store.dispatch(`intelligence/shared/${APPLY_OFFSET}`, value);
const applyLimit = value => store.dispatch(`intelligence/shared/${APPLY_LIMIT}`, value);
// watchers
watch(() => reportKey.value, () => {
  fetchNewData();
});
// lifecycle hooks
onMounted(() => {
  applyLimit(50);
  currentSort.value = inputData.value.defaultSortHeader;
  setDate();
});
onUnmounted(clearReportData);
// methods
async function setDate(dates) {
  if (dates) dateParams.value = dates;

  if (isRolldate(dateParams.value)) {
    const fetchedDates = await fetchDates(dateParams.value);
    applyDates(fetchedDates);
  } else {
    applyDates(dateParams.value);
  }

  fetchNewData();
  isDateFilterActive.value = false;
}
function toggleDateFilter() {
  isDateFilterActive.value = !isDateFilterActive.value;
}
function handleNewSort(headerValue) {
  if (headerValue === currentSort.value) {
    sortDescending.value = !sortDescending.value;
  } else {
    sortDescending.value = true;
    currentSort.value = headerValue;
  }

  applySort([currentSort.value, sortDirection.value]);
  fetchNewData();
}
async function fetchNewData() {
  isReportEnabled.value = false;
  lazyLoadingData.value = [];
  applyOffset(0);
  reportId.value = await fetchReportData({ reportType: reportType.value });
  isReportEnabled.value = true;
}
async function loadMoreData() {
  const lazyLoadId = await fetchReportData({ reportType: reportType.value });
  const { customers } = getReportData(lazyLoadId);
  lazyLoadingData.value.push(...customers);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.container {
  padding: 0 0 var(--spacing-2);
  position: relative;
  background-image: linear-gradient(var(--colour-utility-white) 0 var(--spacing-3), var(--colour-panel-customer) var(--spacing-3) 100%);
  min-height: 250px;

   @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 0 var(--spacing-2) var(--spacing-2);
  }
}

.description {
  border-radius: var(--border-radius-1);
  display: grid;
  font-weight: var(--font-weight-semibold);
  place-content: center;
  padding: calc(var(--spacing-1) * 1.5);
  color: var(--colour-data-turmeric-label);
  background: var(--colour-data-turmeric-light);
  margin: 0 var(--spacing-2) var(--spacing-2);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin: 0 0 var(--spacing-2);
  }
}

.buffer {
  background: var(--colour-panel-g-2);
  padding: var(--spacing-3) 0;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}
</style>
