import {
  GET_FIELDS,
  CLEAR_FIELDS,
} from '@/shared/store/actionType';
import { CHECK_URL } from '@/crm/store/actionType';
import store from '@/shared/store';
import { prospectData } from '@/crm/store/data/prospect';
import { httpRequest } from '@sales-i/utils';

// mutations
export const fieldsMutations = {
  [GET_FIELDS]: async (state, { data, prospectSchema, contactSchema }) => {
    let dataTemp = data;
    let prospect = store.state.crm.prospects.selected.data;
    let contact = store.state.crm.contacts.selected.data;
    let prospectAddress = prospect.addresses && prospect.addresses.length > 0 ? prospect.addresses[0] : false;
    let contactAddress = contact.addresses && contact.addresses.length > 0 ? contact.addresses[0] : false;

    dataTemp = data.map(e => {
      let section = e.section.toLowerCase();
      const fields = e.fields.map(field => {
        if (section === 'customer') {
          let defaultFieldFromSchema = prospectSchema.default.fields.find(f => f.name === field.id);
          if (!defaultFieldFromSchema) {
            defaultFieldFromSchema = prospectSchema.address.fields.find(f => f.name === field.id);
          }

          field.required = defaultFieldFromSchema ? defaultFieldFromSchema.required : field.required;
          if (prospect[field.id]) {
            field.value = prospect[field.id];
          }
          if (field.id === 'customer' && prospect.name) {
            field.value = prospect.name;
          }
          if (prospectAddress && prospectAddress[field.id]) {
            field.value = prospectAddress[field.id];
          }
          field.id = `${section}_${field.id}`;
        }

        if (section === 'contact') {
          let defaultFieldFromSchema = contactSchema.default.fields.find(f => f.name === field.id);
          if (!defaultFieldFromSchema) {
            defaultFieldFromSchema = contactSchema.address.fields.find(f => f.name === field.id);
          }

          field.required = defaultFieldFromSchema ? defaultFieldFromSchema.required : field.required;
          if (contact[field.id]) {
            field.value = contact[field.id];
          }
          if (contactAddress && contactAddress[field.id]) {
            field.value = contactAddress[field.id];
          }
          field.id = `${section}_${field.id}`;
        }

        if (section === 'details') {
          if (prospect[field.id]) {
            field.value = prospect[field.id];
          }
          field.id = `${section}_${field.id}`;
        }

        return field;
      });
      e.fields = fields;
      return e;
    });
    state.fields = dataTemp;
  },
  [CLEAR_FIELDS]: (state) => {
    state.fields = [];
    state.selected.loading = false;
    state.selected.loaded = true;
  },
  [CHECK_URL]: () => {}
};

// actions
export const fieldsActions = {
  [GET_FIELDS]: async ({ commit, }) => {
    const data = JSON.parse(JSON.stringify(prospectData));
    const prospectFields = await httpRequest('get', 'config/schema/prospects', {}, { version: '2' });
    const contactFields = await httpRequest('get', 'config/schema/contacts', {}, { version: '2' });

    const defaultProspectFields = prospectFields.sections.find(sect => sect.name === 'Default');
    const addressProspectFields = prospectFields.sections.find(sect => sect.name === 'Addresses');
    const defaultContactFields = contactFields.sections.find(sect => sect.name === 'Default');
    const addressContactFields = contactFields.sections.find(sect => sect.name === 'Addresses');
    commit(GET_FIELDS, {
      data,
      prospectSchema: {
        default: defaultProspectFields,
        address: addressProspectFields,
      },
      contactSchema: {
        default: defaultContactFields,
        address: addressContactFields,
      }
    });
  },
  [CLEAR_FIELDS]: async ({ commit, }) => {
    commit(CLEAR_FIELDS);
  },
  [CHECK_URL]: async ({ commit, }, www) => {
    try {
      const data = await httpRequest('post', 'prospects/checkUrl', {
        www
      });
      commit(CHECK_URL);
      return data;
    } catch (e) {
      return false;
    }
  }
};
